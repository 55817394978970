import cn from '../lib/cn';
import { useAppSelector } from '../store/hooks';
import ScrollableChartArea from './ScrollableChartArea';
import FunnelChart from './FunnelChart';
import getChartData from '../lib/getChartData';
import Legend from './Legend';

const FunnelChartWrapper = ({
	reportId,
	dashboardId,
	onHover,
	onHoverEnd,
	onToggle,

	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	dashboardId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
	[key: string]: any;
}) => {
	const { data, subAnalysisFields, subAnalysisMapping, analysisName } = useAppSelector(state =>
		getChartData(state, reportId)
	);

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={data?.map((d: any) => ({
					...d,
					analysisName: analysisName,
				}))}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				overlayData={[]}
				selection={selection}
				status={status}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full')}>
						<div className="explore-chart__main">
							<FunnelChart
								data={data}
								subAnalysisFields={subAnalysisFields}
								subAnalysisMapping={subAnalysisMapping}
								onHover={onHover}
								onHoverEnd={onHoverEnd}
								onToggle={onToggle}
								status={status}
								hoveredItem={hoveredItem}
								selection={selection}
							/>
						</div>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default FunnelChartWrapper;
