import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import {
	CadenceRunType,
	ISendTestEmailsProps,
	ISnapshotSurveyArchiveProps,
	ISnapshotSurveyCreateProps,
	ISnapshotSurveyDeleteProps,
	ISnapshotSurveyPublishProps,
	ISnapshotSurveyUpdateProps,
	ISnapshotSurveyUpdateWithReminderProps,
	Privacy,
	Storage,
} from './types';
import { getEnterpriseSurveys } from '../list/actions';
import { Survey } from '../list/types';
import { cloneDeep, orderBy } from 'lodash';
import { reconstructCategories } from './utils';

export const createSnapshot = createAsyncThunk<any, void, AsyncThunkConfig>(
	'surveys/template/snapshot/create',
	async (_, { getState, dispatch }) => {
		const {
			audienceId,
			title,
			description,
			globalSurveyId,
			cadenceRunType,
			cadenceRunEachNDays,
			privacy,
			storage,
			welcomeSubject,
			welcomeBody,
			thankYouSubject,
			thankYouBody,
			emailSubject,
			emailTitle,
			emailBody,
			categories,
			endDate,
			distributionType,
			questionOrder,
		} = getState().snapshotSurveys.edit;
		const { enterpriseId, logoURL, logoLightURL } = getState().account;

		let emailMappingId: string | undefined;

		try {
			if (!!audienceId) {
				const emailMappings = await axios.post(`${Urls.SurveyStudioApi}mappings/enterprise`, {
					enterpriseId,
				});
				if (emailMappings.data.mappings) {
					emailMappingId = orderBy(
						emailMappings.data.mappings,
						mapping => new Date(mapping.syncStatus.stoppedAt),
						'desc'
					).find(mapping => mapping.syncStatus.status === 'COMPLETED')?.emailMappingId;
				}
			}
		} catch (e) {
			console.log(e);
		}

		const payload: ISnapshotSurveyCreateProps = {
			title,
			description: description || '',
			audienceId: audienceId,
			enterpriseId: enterpriseId as string,
			globalSurveyId: globalSurveyId as string,
			cadenceRunType: cadenceRunType as CadenceRunType,
			cadenceRunEachNDays: cadenceRunEachNDays as number | undefined,
			privacy: privacy as Privacy,
			storage: storage as Storage,
			endDate: endDate as string,
			emailMappingId,
			categories,
			isPublished: false,
			isPrivate: false,
			uiElements: [
				...(distributionType === 'email'
					? [
							{
								key: 'emailSubject',
								value: emailSubject ?? '',
							},
							{
								key: 'emailTitle',
								value: emailTitle ?? '',
							},
							{
								key: 'emailBody',
								value: emailBody ?? '',
							},
					  ]
					: []),
				{
					key: 'welcomeSubject',
					value: welcomeSubject ?? '',
				},
				{
					key: 'welcomeBody',
					value: welcomeBody ?? '',
				},
				{
					key: 'thankYouSubject',
					value: thankYouSubject ?? '',
				},
				{
					key: 'thankYouBody',
					value: thankYouBody ?? '',
				},
				{
					key: 'logoUrl',
					value: logoURL ?? '',
				},
				{
					key: 'logoLightURL',
					value: logoLightURL ?? '',
				},
				{
					key: 'questionOrder',
					value: questionOrder,
				},
			],
		};

		const response = await axios.put(`${Urls.SurveyStudioApi}surveys/template/snapshot/create`, payload);

		dispatch(getEnterpriseSurveys());
		return response.data;
	}
);

export const updateSnapshot = createAsyncThunk<any, void, AsyncThunkConfig>(
	'surveys/template/snapshot/update',
	async (_, { getState, dispatch }) => {
		const {
			title,
			description,
			surveyId,
			audienceId,
			cadenceRunType,
			cadenceRunEachNDays,
			privacy,
			storage,
			endDate,
			categories,
			emailSubject,
			emailTitle,
			emailBody,
			welcomeSubject,
			welcomeBody,
			thankYouSubject,
			thankYouBody,
			questionOrder,
		} = getState().snapshotSurveys.edit;
		const { enterpriseId, logoURL, logoLightURL } = getState().account;

		let emailMappingId: string | undefined;

		try {
			if (!!audienceId) {
				const emailMappings = await axios.post(`${Urls.SurveyStudioApi}mappings/enterprise`, {
					enterpriseId,
				});
				if (emailMappings.data.mappings) {
					emailMappingId = orderBy(
						emailMappings.data.mappings,
						mapping => new Date(mapping.syncStatus.stoppedAt),
						'desc'
					).find(mapping => mapping.syncStatus.status === 'COMPLETED')?.emailMappingId;
				}
			}
		} catch (e) {
			console.log(e);
		}
		const payload: ISnapshotSurveyUpdateProps = {
			title,
			description,
			enterpriseId,
			surveyIdOrGlobalSurveyId: surveyId as string,
			audienceId: audienceId,
			cadenceRunType: cadenceRunType as CadenceRunType,
			cadenceRunEachNDays: cadenceRunEachNDays as number | undefined,
			privacy: privacy as Privacy,
			storage: storage as Storage,
			endDate: endDate as string,
			categories,
			emailMappingId,
			isPublished: false,
			isPrivate: false,
			uiElements: [
				{
					key: 'emailSubject',
					value: emailSubject ?? '',
				},
				{
					key: 'emailTitle',
					value: emailTitle ?? '',
				},
				{
					key: 'emailBody',
					value: emailBody ?? '',
				},
				{
					key: 'welcomeSubject',
					value: welcomeSubject ?? '',
				},
				{
					key: 'welcomeBody',
					value: welcomeBody ?? '',
				},
				{
					key: 'thankYouSubject',
					value: thankYouSubject ?? '',
				},
				{
					key: 'thankYouBody',
					value: thankYouBody ?? '',
				},
				{
					key: 'logoUrl',
					value: logoURL ?? '',
				},
				{
					key: 'logoLightURL',
					value: logoLightURL ?? '',
				},
				{
					key: 'questionOrder',
					value: questionOrder,
				},
			],
		};

		const response = await axios.put(`${Urls.SurveyStudioApi}surveys/template/snapshot/update`, payload);

		dispatch(getEnterpriseSurveys());
		return response.data;
	}
);

export const deleteSnapshot = createAsyncThunk<any, string, AsyncThunkConfig>(
	'surveys/template/snapshot/delete',
	async (surveyIdOrGlobalSurveyId, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const surveys = getState().snapshotSurveys.list.data;
		const survey = surveys.find((survey: Survey) => survey.surveyId === surveyIdOrGlobalSurveyId);
		const payload: ISnapshotSurveyDeleteProps = {
			surveyIdOrGlobalSurveyId,
			enterpriseId,
		};
		const response = await axios.put(`${Urls.SurveyStudioApi}surveys/template/snapshot/delete`, payload);

		dispatch(getEnterpriseSurveys());
		return {
			...response.data,
			title: survey?.title,
		};
	}
);

export const publishSnapshot = createAsyncThunk<any, ISnapshotSurveyPublishProps, AsyncThunkConfig>(
	'surveys/template/snapshot/publish',
	async (payload, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const surveys = getState().snapshotSurveys.list.data;
		const survey = surveys.find((survey: Survey) => survey.surveyId === payload.surveyIdOrGlobalSurveyId);
		const response = await axios.put(`${Urls.SurveyStudioApi}surveys/template/snapshot/publish`, {
			...payload,
			enterpriseId,
		});
		dispatch(getEnterpriseSurveys());

		return {
			...response.data,
			title: survey?.title,
		};
	}
);

export const sendTestEmail = createAsyncThunk<any, ISendTestEmailsProps, AsyncThunkConfig>(
	'surveys/enterprise/testmail',
	async (payload, { getState, dispatch }) => {
		const response = await axios.put(`${Urls.SurveyStudioApi}surveys/enterprise/testmail`, payload);

		dispatch(getEnterpriseSurveys());
		return {
			...response.data,
			title: payload?.surveyId,
		};
	}
);

export const archiveSnapshot = createAsyncThunk<any, ISnapshotSurveyArchiveProps, AsyncThunkConfig>(
	'surveys/template/snapshot/archive',
	async (payload, { getState, dispatch }) => {
		const { shouldArchive, survey } = payload;

		const surveyUpdatePayload: ISnapshotSurveyUpdateProps = {
			title: survey.title,
			description: survey.description,
			enterpriseId: survey.enterpriseId,
			surveyIdOrGlobalSurveyId: survey.surveyId,
			audienceId: survey.audienceId,
			cadenceRunType: CadenceRunType[survey.cadence.type as keyof typeof CadenceRunType],
			cadenceRunEachNDays: survey.cadence.runEachNDays,
			privacy: Privacy[survey.privacy.type as keyof typeof Privacy],
			storage: Storage[survey.storage.type as keyof typeof Storage],
			endDate: survey.endDate,
			categories: reconstructCategories(survey.categories),
			emailMappingId: survey.emailMappingId,
			isPublished: survey.isPublished,
			isPrivate: survey.isPrivate,
			uiElements: cloneDeep(survey.meta.uiElements),
		};

		const uiElement = surveyUpdatePayload.uiElements.find(u => u.key === 'archive');
		if (!uiElement) {
			surveyUpdatePayload.uiElements.push({
				key: 'archive',
				value: shouldArchive ? 'true' : 'false',
			});
		} else {
			uiElement.value = shouldArchive ? 'true' : 'false';
		}

		const response = await axios.put(
			`${Urls.SurveyStudioApi}surveys/template/snapshot/update`,
			surveyUpdatePayload
		);

		dispatch(getEnterpriseSurveys());

		return {
			...response.data,
			title: survey?.title,
		};
	}
);

export const updateSnapshotWithReminder = createAsyncThunk<
	any,
	ISnapshotSurveyUpdateWithReminderProps,
	AsyncThunkConfig
>('surveys/template/snapshot/update/reminder', async ({ survey, reminderEmail }, { getState, dispatch }) => {
	const {
		title,
		description,
		surveyId,
		audienceId,
		privacy,
		storage,
		endDate,
		categories,
		meta: { uiElements },
		cadence: { runEachNDays: cadenceRunEachNDays, type: cadenceRunType },
	} = survey;
	const { enterpriseId } = getState().account;

	const newUiElements = uiElements.slice();
	let index = newUiElements.findIndex(e => e.key === 'remindEmailSubject');
	if (index === -1) {
		newUiElements.push({
			key: 'remindEmailSubject',
			value: reminderEmail.subject,
		});
		newUiElements.push({
			key: 'remindEmailTitle',
			value: reminderEmail.title,
		});
		newUiElements.push({
			key: 'remindEmailBody',
			value: reminderEmail.text,
		});
	} else {
		newUiElements[index] = {
			key: 'remindEmailSubject',
			value: reminderEmail.subject,
		};
		index = newUiElements.findIndex(e => e.key === 'remindEmailTitle')!;
		newUiElements[index] = {
			key: 'remindEmailTitle',
			value: reminderEmail.title,
		};
		index = newUiElements.findIndex(e => e.key === 'remindEmailBody')!;
		newUiElements[index] = {
			key: 'remindEmailBody',
			value: reminderEmail.text,
		};
	}

	let emailMappingId: string | undefined;

	try {
		if (!!audienceId) {
			const emailMappings = await axios.post(`${Urls.SurveyStudioApi}mappings/enterprise`, {
				enterpriseId,
			});
			if (emailMappings.data.mappings) {
				emailMappingId = orderBy(
					emailMappings.data.mappings,
					mapping => new Date(mapping.syncStatus.stoppedAt),
					'desc'
				).find(mapping => mapping.syncStatus.status === 'COMPLETED')?.emailMappingId;
			}
		}
	} catch (e) {
		console.log(e);
	}
	const payload: ISnapshotSurveyUpdateProps = {
		title,
		description,
		enterpriseId,
		surveyIdOrGlobalSurveyId: surveyId as string,
		audienceId: audienceId,
		cadenceRunType: cadenceRunType as CadenceRunType,
		cadenceRunEachNDays: cadenceRunEachNDays as number | undefined,
		privacy: privacy.type as Privacy,
		storage: storage.type as Storage,
		endDate: endDate as string,
		categories: reconstructCategories(categories),
		emailMappingId,
		isPublished: false,
		isPrivate: false,
		uiElements: newUiElements,
	};

	const response = await axios.put(`${Urls.SurveyStudioApi}surveys/template/snapshot/update`, payload);

	return response.data;
});
