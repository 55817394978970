import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncThunkConfig } from '../../store/store';
import Urls from '../../constants/Urls';
import ActionTypes from '../../constants/ActionTypes';
import { DashboardReportUrlState, setDashboardId, setDashboardName, setReportId, setTitle } from './reducer';

export const setDashboard = createAsyncThunk<
	any,
	{
		dashboardId: string;
		reportId?: string;
	},
	AsyncThunkConfig
>('explore/dashboardReport/setDashboard', async ({ dashboardId, reportId }, { getState, dispatch }) => {
	const { enterpriseId } = getState().account;
	const dashboards = getState().dashboards;

	let currentDashboard = dashboards[dashboardId];

	if (!currentDashboard) {
		const response = await axios.post(`${Urls.DashboardsApi}dashboards/ids`, {
			enterpriseId,
			dashboardIds: [dashboardId],
		});

		currentDashboard = response.data[0];
	}

	if (reportId) {
		const report = currentDashboard.reports.find((report: any) => report.reportId === reportId);

		dispatch(setReportId(reportId));
		dispatch(setTitle(report?.name));
	}

	dispatch(setDashboardId(dashboardId));
	dispatch(setDashboardName(currentDashboard?.name));
});

export function syncExploreDashboardReportState(dashboardReport: DashboardReportUrlState) {
	return (dispatch: any, getState: any) => {
		if (!!dashboardReport?.dashboardId) {
			dispatch(
				setDashboard({
					dashboardId: dashboardReport.dashboardId,
					reportId: dashboardReport.reportId,
				})
			);
		}

		return {
			type: ActionTypes.SyncExploreDashboardReportState,
			payload: {
				...dashboardReport,
			},
		};
	};
}
