import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdClose } from 'react-icons/md';
import { Template } from './model';

interface TemplateFragmentProps {
	template: Template;
	expandable?: boolean;
	removable?: boolean;
	onRemove?: () => void;
}

export function TemplateFragment({ template, expandable, removable, onRemove }: TemplateFragmentProps) {
	const [expanded, setExpanded] = useState(!expandable);

	function handleExpand() {
		setExpanded(true);
	}

	function handleCollapse() {
		setExpanded(false);
	}

	return (
		<div>
			{expanded ? (
				<>
					{expandable ? (
						<div className="ds-expander">
							{template?.templateName ? (
								<div className="ds-expander__text">
									<div className="ds-label">Template name</div>
									<div className="ds-value">{template?.templateName}</div>
								</div>
							) : (
								<div className="ds-expander__text">
									<div className="ds-label">Template id</div>
									<div className="ds-value">{template?.templateId}</div>
								</div>
							)}
							<div onClick={handleCollapse}>
								<MdKeyboardArrowUp className="icon" size={20} />
							</div>
							{removable && (
								<div className="ds-expander__close" onClick={onRemove}>
									<MdClose className="icon" size={16} />
								</div>
							)}
						</div>
					) : template?.templateName ? (
						<>
							<div className="ds-label">Template name</div>
							<div className="ds-value">{template?.templateName}</div>
						</>
					) : (
						<>
							<div className="ds-label">Template id</div>
							<div className="ds-value">{template?.templateId}</div>
						</>
					)}
					{template?.templateName && (
						<>
							<div className="ds-label">Template id</div>
							<div className="ds-value">{template?.templateId}</div>
						</>
					)}
					<div style={{ marginLeft: expandable ? 24 : 0 }}>
						<div className="ds-label">Analysis Group</div>
						<div className="ds-value">{template?.analysisGroup}</div>

						<div className="ds-label">Analysis name</div>
						<div className="ds-value">{template?.analysisName}</div>
						<div className="ds-label">Analysis Type</div>
						<div className="ds-value">{template?.analysisType}</div>
						<div className="ds-label">Engine</div>
						<div className="ds-value">{template?.engine}</div>
						<div className="ds-label">SQL file</div>
						<div className="ds-value">{template?.sqlFile}</div>
						{template?.analysisType === 'Percentage' && (
							<>
								<div className="ds-label">Main column</div>
								<div className="ds-value">{template?.mainColumn}</div>
							</>
						)}
						<div className="ds-label">DB table prefix</div>
						<div className="ds-value">{template?.uniqueName}</div>
						<div className="ds-label">Template bucket name</div>
						<div className="ds-value">{template?.templateBucketName}</div>
						<div className="ds-label">Template path</div>
						<div className="ds-value">{template?.templatePath}</div>
						<div className="ds-label">Template configuration id</div>
						<div className="ds-value">
							<NavLink
								className="link--bright"
								to={`/datastudio/config/${template?.templateConfigurationId}`}
							>
								{template?.templateConfigurationId}
							</NavLink>
						</div>
					</div>
				</>
			) : (
				<div className="ds-expander">
					{template?.templateName ? (
						<div className="ds-expander__text">
							<div className="ds-label">Template name</div>
							<div className="ds-value">{template?.templateName}</div>
						</div>
					) : (
						<div className="ds-expander__text">
							<div className="ds-label">Template id</div>
							<div className="ds-value">{template?.templateId}</div>
						</div>
					)}
					<div onClick={handleExpand}>
						<MdKeyboardArrowDown className="icon" size={20} />
					</div>
					{removable && (
						<div className="ds-expander__close" onClick={onRemove}>
							<MdClose className="icon" size={16} />
						</div>
					)}
				</div>
			)}
		</div>
	);
}
