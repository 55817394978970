import ActionTypes from '../../constants/ActionTypes';
import produce from 'immer';
import { Package } from './model';
import sortBy from 'lodash/sortBy';

interface ReducerState {
	pending?: boolean;
	error?: boolean;
	data?: Package[];
	loaded?: boolean;
}

export default function reducer(state: ReducerState = {}, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.GetPackagesPending: {
			return {
				...state,
				pending: true,
				error: false,
			};
		}
		case ActionTypes.GetPackagesFulfilled: {
			let { data, configData } = action;
			data.forEach((p: any) => {
				p.templates = p.templates.map((t: any) => {
					const config = configData.find((c: any) => c.templateConfigurationId === t.templateConfigurationId);
					const { templateName } = config || {};
					return {
						...t,
						templateName,
					};
				});
				p.templates = sortBy(p.templates, t => t.templateName);
			});
			data = sortBy(data, d => d.packageName);
			return {
				...state,
				pending: false,
				data,
				loaded: true,
			};
		}
		case ActionTypes.GetPackagesRejected: {
			return {
				...state,
				pending: false,
				error: true,
			};
		}
		case ActionTypes.SavePackageFulfilled: {
			const { packageObj } = action;
			return produce(state, draftState => {
				if (!draftState.data) {
					draftState.data = [];
				}
				const index = draftState.data.findIndex(d => d.packageId === packageObj.packageId);
				if (index >= 0) {
					draftState.data[index] = packageObj;
				} else {
					draftState.data.push(packageObj);
				}
			});
		}
		default:
			return state;
	}
}
