import { useEffect, useState } from 'react';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';

import BenchmarkMenu from '../benchmark/BenchmarkMenu';
import { openBenchmarkModal } from '../benchmark/actions';
import { Benchmark } from '../benchmark/model';
import Button from '../common/Button';
import Dropdown from '../common/Dropdown';
import Popover from '../common/Popover';
import Stack from '../common/Stack';
import Switch from '../common/Switch';
import WithPermissions from '../common/WithPermissions';
import AnalysisTypes from '../constants/AnalysisTypes';
import ButtonTypes from '../constants/ButtonTypes';
import ChartTypes from '../constants/ChartTypes';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import HorizontalBarIcon from '../icons/BarChartHorizontal';
import BarChartVertical from '../icons/BarChartVertical';
import BarSelected from '../icons/BarSelected';
import Close from '../icons/Close';
import Line from '../icons/Line';
import LineSelected from '../icons/LineSelected';
import Pie from '../icons/Pie';
import PieSelected from '../icons/PieSelected';
import StackedBar from '../icons/StackedBar';
import TableChartTypeIcon from '../icons/TableChartType';
import cn from '../lib/cn';
import getMainColumn from '../lib/getMainColumn';
import { track } from '../lib/segment';
import { capitalize } from '../measure/utils';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { TemplateGroup } from '../templates/models';
import MetricsOverlay from './MetricsOverlay';
import SegmentDropdown from './SegmentDropdown';
import {
	openEventsModal,
	removeBenchmark,
	setBenchmark,
	setChartEvents,
	setChartType,
	setHidePie,
	setIndexType,
	setYAxisType,
} from './chart/actions';
import IntervalSection from './dashboard/IntervalSection';
import { getAllowedSegments } from './filter/SegmentDropdown';
import { navigate, setShowSubtable, updateBreakdownSegment } from './filter/actions';
import { setSubtableChartType } from './subtable/actions';

const chartTypes = [
	{
		chartType: ChartTypes.Line,
		label: ChartTypes.Line,
		value: ChartTypes.Line,
		activeIcon: LineSelected,
		inactiveIcon: Line,
	},
	{
		chartType: ChartTypes.Bar,
		label: ChartTypes.Bar,
		value: ChartTypes.Bar,
		activeIcon: BarSelected,
		inactiveIcon: BarChartVertical,
	},
	{
		chartType: ChartTypes.Pie,
		label: ChartTypes.Pie,
		value: ChartTypes.Pie,
		activeIcon: PieSelected,
		inactiveIcon: Pie,
	},
	{
		chartType: ChartTypes.StackedBar,
		value: ChartTypes.StackedBar,
		label: 'Stacked bar',
		activeIcon: StackedBar,
		inactiveIcon: StackedBar,
	},
	{
		chartType: ChartTypes.Matrix,
		value: ChartTypes.Matrix,
		label: 'Table',
		activeIcon: TableChartTypeIcon,
		inactiveIcon: TableChartTypeIcon,
	},
	{
		chartType: ChartTypes.HorizontalBar,
		value: ChartTypes.HorizontalBar,
		label: 'Horizontal Bar',
		activeIcon: HorizontalBarIcon,
		inactiveIcon: HorizontalBarIcon,
	},
];

enum XAxisesTypes {
	Time = 'Time',
	Segments = 'Segments',
}

const xAxises = Object.values(XAxisesTypes);

interface ExploreSettingsPanelProps {
	isSettingsOpen: boolean;
	setBenchmarkEdit: (benchmarkEdit: boolean) => void;
	setBenchmarkToEdit: (benchmarkToEdit: any) => void;
	switchSettingsDisplay: () => void;
}

function ExploreSettingsPanel({
	isSettingsOpen,
	setBenchmarkEdit,
	setBenchmarkToEdit,
	switchSettingsDisplay,
}: ExploreSettingsPanelProps) {
	const [benchmarkMenuOpen, setBenchmarkMenuOpen] = useState(false);
	const { analysisType, showSubtable, attributes, breakdownSegment, breakdownNames, analysisName } = useAppSelector(
		state => state.explore.filter
	);
	const {
		allowIndexToggle,
		allowYAxisToggle,
		benchmark,
		chartType,
		eventIds,
		indexType,
		intersectionAttributes,
		showBenchmarkIcon,
		yAxisType,
	} = useAppSelector(state => state.explore.chart);
	const { dynamicGroups } = useAppSelector(state => state.templates);
	const { subtableChartType } = useAppSelector(state => state.explore.subtable);

	const { events } = useAppSelector(state => state.timeline);
	const mainColumn = useAppSelector(state => getMainColumn(state, analysisName));
	const dispatch = useAppDispatch();

	const currentTemplate = dynamicGroups?.find((t: TemplateGroup) => t.name === analysisName)?.templates?.[0];
	const breakdownSegments = getAllowedSegments(attributes, breakdownNames, currentTemplate?.breakdownNames as any);

	const breakdownSegmentList = [
		{
			label: 'Choose segment',
			value: 'Choose segment',
		},
	].concat(
		breakdownSegments.map((s: any) => ({
			label: s.attributeName,
			value: s.attributeName,
		}))
	);

	const allowChartTypeChange =
		chartType === ChartTypes.Stats || chartType === ChartTypes.ArithmeticTable || chartType === ChartTypes.Funnel
			? false
			: true;

	const [xAxis, setXAxis] = useState(xAxises[0]);
	const [segment, setSegment] = useState(breakdownSegmentList[0]);
	function handleBenchmarkClick() {
		setBenchmarkMenuOpen(!benchmarkMenuOpen);
	}

	function handleBenchmarkEdit(benchmarkToEdit: any) {
		track(MixPanel.Events.ExploreEditBenchmark, {
			'Benchmark Name': benchmarkToEdit.benchmarkName,
		});
		setBenchmarkToEdit(benchmarkToEdit);
		dispatch(openBenchmarkModal());
		setBenchmarkMenuOpen(false);
		setBenchmarkEdit(true);
	}

	function handleBenchmarkRemove() {
		track(MixPanel.Events.ExploreRemoveBenchmark);
		setBenchmarkMenuOpen(false);
		dispatch(removeBenchmark());
	}

	function handleBenchmarkSelect(benchmark: Benchmark) {
		track(MixPanel.Events.ExploreSelectBenchmark, {
			'Benchmark Name': benchmark.benchmarkName,
		});
		setBenchmarkMenuOpen(false);
		dispatch(setBenchmark(benchmark));
	}

	function handleBenchmarkCreate() {
		track(MixPanel.Events.ExploreCreateBenchmark);
		dispatch(openBenchmarkModal());
		setBenchmarkMenuOpen(false);
		setBenchmarkEdit(false);
	}

	function handleChartTypeChange(chartType: ChartTypes) {
		track(MixPanel.Events.ExploreSelectChartType, {
			'Chart Type': chartType,
		});
		if (
			(showSubtable && (chartType === ChartTypes.Bar || chartType === ChartTypes.Matrix)) ||
			chartType === ChartTypes.HorizontalBar
		) {
			dispatch(setSubtableChartType(chartType));
		} else {
			dispatch(setChartType(chartType));
		}
	}

	function handleCollapsedToggle() {
		switchSettingsDisplay();
	}

	function handleRemoveEvents() {
		track(MixPanel.Events.TimelineAddEventToGraph, []);
		dispatch(setChartEvents([]));
	}

	function handleShowPieChange(showPie: boolean) {
		dispatch(setHidePie(!showPie));
		setBenchmarkMenuOpen(false);
	}

	function handleChooseSegment(attributeName?: string) {
		if (!attributeName) setSegment(breakdownSegmentList[0]);
		const chosenSegment = breakdownSegments.find((s: any) => s.attributeName === attributeName);
		if (chosenSegment) {
			dispatch(
				updateBreakdownSegment([
					{
						name: chosenSegment.attributeName,
						values: chosenSegment.attributeValues?.map((av: any) => av.value),
					},
				])
			);

			setSegment({
				label: chosenSegment.attributeName,
				value: chosenSegment.attributeName,
			});

			dispatch(setShowSubtable(true));
			dispatch(navigate());
		}
	}

	useEffect(() => {
		if (showSubtable) {
			setXAxis(XAxisesTypes.Segments);
		} else {
			setXAxis(XAxisesTypes.Time);
		}
	}, [showSubtable]);

	useEffect(() => {
		if (breakdownSegment) {
			setSegment({
				label: breakdownSegment?.name,
				value: breakdownSegment?.name,
			});
		}
	}, [breakdownSegment]);

	function handleChooseXAxis(newXAxis: XAxisesTypes) {
		setXAxis(newXAxis);

		if (newXAxis === 'Time') {
			dispatch(updateBreakdownSegment([]));
			handleChooseSegment();
			if (showSubtable) {
				dispatch(navigate());
			}
		}
	}

	return (
		<div
			className={cn(
				'explore-settings-panel',
				'transition-transform duration-150 ease-in-out p-0 h-[calc(100vh - 12rem)] -translate-x-full', // default state (off-screen to the left)
				{
					'translate-x-0 w-[26rem] px-[2rem] border-solid border-0 border-r-[1px] border-shade-h5':
						isSettingsOpen,
				}
			)}
		>
			{isSettingsOpen && (
				<>
					<div className="explore-settings-panel__header">
						<div className="explore-settings-panel__header__title">
							<span>Settings</span>
							<MdKeyboardDoubleArrowLeft
								size={24}
								style={{
									cursor: 'pointer',
									alignSelf: 'center',
								}}
								onClick={handleCollapsedToggle}
							/>
						</div>
					</div>
					<div className="explore-settings-panel__content">
						{allowChartTypeChange && analysisType !== AnalysisTypes.Arithmetic && (
							<div className="explore-settings-panel__content__item">
								<span className="explore-settings-panel__header__subtitle">Visualization</span>
								<Dropdown
									buttonType={ButtonTypes.type.SECONDARY}
									selectedOption={
										showSubtable
											? subtableChartType === ChartTypes.Matrix
												? chartTypes[4]
												: subtableChartType === ChartTypes.HorizontalBar
												? chartTypes[5]
												: subtableChartType
											: chartType === 'Table'
											? chartTypes[4]
											: chartTypes.filter(ct => ct.chartType === chartType)[0]
									}
									onClick={(val: any) => handleChartTypeChange(val)}
									options={chartTypes.map((ct: any) => {
										const isPie = ct.chartType === ChartTypes.Pie;
										const isStackedBar = ct.chartType === ChartTypes.StackedBar;
										const isLinearOrIndexAnalysis =
											analysisType === AnalysisTypes.LinearRegression ||
											analysisType === AnalysisTypes.Index;

										const isTable = ct.chartType === ChartTypes.Matrix;
										const isHorizontalBar = ct.chartType === ChartTypes.HorizontalBar;
										const hasMultipleIntersectionAttributes =
											intersectionAttributes && intersectionAttributes.length > 1;

										const isLineOrBar =
											ct.chartType === ChartTypes.Line || ct.chartType === ChartTypes.Bar;

										let option = {
											disabled: false,
											icon: <></>,
											label: ct.label,
											value: ct.value,
										};

										if (isPie) {
											option.disabled =
												isLinearOrIndexAnalysis ||
												(analysisType === AnalysisTypes.Percentage &&
													mainColumn === 'excluded') ||
												hasMultipleIntersectionAttributes ||
												showSubtable;
										} else if (isStackedBar) {
											option.disabled =
												!(
													analysisType === AnalysisTypes.Percentage &&
													mainColumn !== 'excluded'
												) || !!showSubtable;
										} else if (benchmark && (isLineOrBar || isTable)) {
											option.disabled = true;
										} else if (isHorizontalBar) {
											option.disabled =
												!showSubtable ||
												!(
													analysisType === AnalysisTypes.Percentage &&
													mainColumn !== 'excluded'
												);
										} else if (ct.chartType === ChartTypes.Line) {
											option.disabled = !!showSubtable;
										}

										option.icon = ct.chartType === chartType ? ct.activeIcon : ct.inactiveIcon;

										return option;
									})}
									showIcon
								/>
							</div>
						)}
						{allowIndexToggle && (
							<div className="explore-settings-panel__content__item">
								<span className="explore-settings-panel__header__subtitle">Metric</span>
								<Dropdown
									buttonType={ButtonTypes.type.SECONDARY}
									selectedOption={capitalize(indexType)}
									onClick={(val: any) => {
										dispatch(setIndexType(val.toLowerCase()));
									}}
									options={[
										{ label: 'Indexed', value: 'indexed' },
										{
											label: 'Percentage',
											value: 'percentage',
										},
									]}
								/>
							</div>
						)}
						{allowYAxisToggle && analysisType !== AnalysisTypes.Arithmetic && (
							<div className="explore-settings-panel__content__item">
								<span className="explore-settings-panel__header__subtitle">Metric</span>
								<Dropdown
									disabled={!!benchmark}
									buttonType={ButtonTypes.type.SECONDARY}
									selectedOption={capitalize(yAxisType)}
									onClick={(val: any) => {
										dispatch(setYAxisType(val.toLowerCase()));
									}}
									options={[
										{ label: 'Count', value: 'count' },
										{
											label: 'Percentage',
											value: 'percentage',
										},
									]}
								/>
							</div>
						)}
						{analysisType !== AnalysisTypes.Arithmetic && (
							<div className="explore-settings-panel__content__item">
								<span className="explore-settings-panel__header__subtitle">X axis</span>
								<Dropdown
									buttonType={ButtonTypes.type.SECONDARY}
									onClick={handleChooseXAxis}
									options={xAxises.map((xa: any) => ({
										label: xa,
										value: xa,
									}))}
									selectedOption={xAxis}
									selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
								/>
								{xAxis === 'Segments' && (
									<SegmentDropdown
										onClick={(o: any) => handleChooseSegment(o)}
										options={breakdownSegmentList}
										selectedOption={segment}
									/>
								)}
							</div>
						)}

						<div className="explore-settings-panel__content__item">
							<span className="explore-settings-panel__header__subtitle">Time period</span>
							<IntervalSection isSegment={xAxis === 'Segments'} />
						</div>

						{!showSubtable && showBenchmarkIcon && analysisType !== AnalysisTypes.Arithmetic && (
							<WithPermissions actions={[RbacActions['Benchmarks/View']]}>
								<div className="explore-settings-panel__content__item">
									<span className="explore-settings-panel__header__subtitle">Benchmark</span>
									<div className={'mt-[8px]'}>
										<Popover
											placement="bottomRight"
											visible={benchmarkMenuOpen}
											onVisibleChange={handleBenchmarkClick}
											content={
												<BenchmarkMenu
													benchmark={benchmark}
													onBenchmarkCreate={handleBenchmarkCreate}
													onBenchmarkSelect={handleBenchmarkSelect}
													onBenchmarkRemove={handleBenchmarkRemove}
													onBenchmarkEdit={handleBenchmarkEdit}
													showPie={benchmark && !benchmark.hidePie}
													onShowPieChange={handleShowPieChange}
												/>
											}
											overlayClassName="popover--no-padding"
										>
											<Button
												componentType={ButtonTypes.type.SECONDARY}
												selected={!!benchmark}
												selectedStyle={ButtonTypes.selectedStyle.DEFAULT}
												style={{
													width: '100%',
													justifyContent: 'space-between',
												}}
											>
												{benchmark?.benchmarkName || 'Add Benchmark'}
												{!!benchmark && (
													<Close
														width={12}
														height={12}
														onClick={(e: any) => {
															e.stopPropagation();
															handleBenchmarkRemove();
														}}
													/>
												)}
											</Button>
										</Popover>
									</div>
								</div>
								{/*
<div className="explore-settings-panel__content__switch-item">
									<span className="explore-settings-panel__header__toggle">Benchmark</span>
									<Switch
										checked={!!benchmark}
										onChange={!!benchmark ? handleBenchmarkRemove : handleBenchmarkCreate}
										toggleStyle={{
											background: 'var(--color-ui-100)',
										}}
									/>
								</div>
                */}
							</WithPermissions>
						)}
						{!showSubtable && analysisType !== AnalysisTypes.Arithmetic && chartType === ChartTypes.Line && (
							<WithPermissions actions={[RbacActions['Events/View']]}>
								<div className="explore-settings-panel__content__switch-item">
									<Stack flexDirection="column" style={{ width: '100%' }} gap="0.8rem">
										<Stack flexDirection="row" justifyContent="space-between">
											<span className="explore-settings-panel__header__toggle">Events</span>
											<Switch
												checked={!!eventIds?.length}
												onChange={() =>
													!!eventIds?.length
														? handleRemoveEvents()
														: dispatch(openEventsModal())
												}
												toggleStyle={{
													background: 'var(--color-ui-100)',
												}}
											/>
										</Stack>
										{!!eventIds?.length && (
											<Button
												componentType={ButtonTypes.type.SECONDARY}
												selected={!!benchmark}
												onClick={() => dispatch(openEventsModal())}
												style={{
													width: '100%',
													justifyContent: 'space-between',
													display: 'inline-block',
													maxWidth: '100%',
													paddingRight: '2rem',
												}}
											>
												<div
													style={{
														width: '100%',
														justifyContent: 'space-between',
														display: 'inline-block',
														maxWidth: '100%',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{events
														.filter((event: any) => eventIds.includes(event.eventId))
														.map((event: any) => event.name)
														.join(', ')}
												</div>
												<Close
													width={12}
													height={12}
													onClick={(e: any) => {
														e.stopPropagation();
														handleRemoveEvents();
													}}
												/>
											</Button>
										)}
									</Stack>
								</div>
							</WithPermissions>
						)}
					</div>
					{[ChartTypes.Line, ChartTypes.Bar, ChartTypes.StackedBar].includes(chartType) &&
						!showSubtable &&
						analysisType !== AnalysisTypes.Arithmetic && <MetricsOverlay />}
				</>
			)}
		</div>
	);
}

export default ExploreSettingsPanel;
