import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';

import ActionTypes from '../../constants/ActionTypes';
import parseBoolean from '../../lib/parseBoolean';

export interface DashboardReportUrlState {
	editMode?: string | boolean;
	dashboardId?: string;
	reportId?: string;
	rowIndex?: number;
}

interface DashboardReportState extends DashboardReportUrlState {
	dashboardName?: string;
	title?: string;
}

const initialState: DashboardReportState = {};

const dashboardReportSlice = createSlice({
	name: 'explore/dashboardReport',
	initialState: initialState,
	reducers: {
		setEditMode(state, action) {
			state.editMode = action.payload;
		},
		setDashboardId(state, action) {
			state.dashboardId = action.payload;
		},
		setDashboardName(state, action) {
			state.dashboardName = action.payload;
		},
		setReportId(state, action) {
			state.reportId = action.payload;
		},
		setRowIndex(state, action) {
			state.rowIndex = action.payload;
		},
		setTitle(state, action) {
			state.title = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(ActionTypes.ChangeLocation, (state, action: any) => {
			const { location } = action.payload;

			if (location.pathname !== '/explore') {
				state.editMode = false;
				state.dashboardId = undefined;
				state.dashboardName = undefined;
				state.reportId = undefined;
				state.rowIndex = undefined;
				state.title = '';
			} else if (location.search) {
				const query: any = qs.parse(location.search, {
					ignoreQueryPrefix: true,
				});

				let editMode, dashboardId, reportId, rowIndex;

				editMode = query?.dashboard?.editMode;
				dashboardId = query?.dashboard?.dashboardId;
				reportId = query?.dashboard?.reportId;

				rowIndex = query?.dashboard?.rowIndex;

				state.editMode = editMode === 'true';
				state.dashboardId = dashboardId;
				state.reportId = reportId === '' ? undefined : reportId;
				state.rowIndex = rowIndex;
			}
		});
		builder.addCase(ActionTypes.SyncExploreDashboardReportState, (state, action: any) => {
			state.editMode = parseBoolean(action.payload.editMode);
			state.dashboardId = action.payload.dashboardId;
			state.reportId = action.payload.reportId;
			state.rowIndex = action.payload.rowIndex;
		});
	},
});

export const { setEditMode, setDashboardId, setReportId, setRowIndex, setTitle, setDashboardName } =
	dashboardReportSlice.actions;
export default dashboardReportSlice.reducer;
