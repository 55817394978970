import React, { useEffect } from 'react';
import { Configuration, FieldMap, Variable } from './model';
import { getConfigurations } from './actions';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

function ConfigurationDetail({ history, match }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const { loaded, data } = useAppSelector(state => state.datastudio.configurations);
	const id = match.params.id;
	const shouldFetch = !loaded;
	const configuration = data?.find((c: any) => c.templateConfigurationId === id);
	useEffect(() => {
		if (shouldFetch) {
			dispatch(getConfigurations());
		}
	}, [shouldFetch]);

	function displayVariables(variables: Variable[], type: string) {
		return variables.length ? (
			<div className="ds-blob-inner">
				{type} variables:
				{variables.map((v, i) => (
					<div className="ds-tab" key={i}>
						{v.originalName}, {v.columnName}
					</div>
				))}
			</div>
		) : null;
	}

	function displayFieldMap(fieldMap: FieldMap) {
		if (!fieldMap) return null;
		return Object.keys(fieldMap).map((k, i) => (
			<div key={i}>
				{k}: {fieldMap[k].source} {fieldMap[k].order} {fieldMap[k].calculated ? 'true' : 'false'}{' '}
				{fieldMap[k].unit}
			</div>
		));
	}

	function handleEdit() {
		history.push(`/datastudio/config/${match.params.id}/edit`);
	}

	return configuration ? (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-edit']} onClick={handleEdit}>
				Edit
			</Button>
			<div className="ds-label">Template name</div>
			<div className="ds-value">{configuration.templateName}</div>
			<div className="ds-label">Template configuration id</div>
			<div className="ds-value">{configuration?.templateConfigurationId}</div>
			<div className="ds-label">Analysis name</div>
			<div className="ds-value">{configuration.analysisName}</div>
			<div className="ds-label">Data source</div>
			<div className="ds-value">{configuration.dataSource}</div>
			<div className="ds-label">Engine name</div>
			<div className="ds-value">{configuration.engineName}</div>
			<div className="ds-label">Configuration variables</div>
			<div className="ds-blob">
				{configuration.configurationVariables.map((cv, i) => (
					<div className="ds-blob-inner ds-blob-inner--large" key={i}>
						<div className="ds-bold">Configuration item: {i + 1}</div>
						{displayVariables(cv.mainVariables, 'Main')}
						{displayVariables(cv.controlVariables, 'Control')}
						{displayVariables(cv.additionalVariables, 'Additional')}
						{cv.formulas.length ? (
							<div className="ds-blob-inner">
								Formulas:
								{cv.formulas.map((f, i) => (
									<div className="ds-tab" key={i}>
										{f}
									</div>
								))}
							</div>
						) : null}
						{cv.exclusionRules.map((e, i) => (
							<div className="ds-blob-inner" key={i}>
								Exclusion type: {e.exclusionType}, limit: {e.limitMain || e.limitControl}
							</div>
						))}
						<div>Generate type: {cv.generateType}</div>
					</div>
				))}
			</div>
			<div className="ds-label">Exclude when</div>
			<div className={!!configuration?.excludeWhen.length ? 'ds-blob' : 'ds-value'}>
				{configuration.excludeWhen.map((e, i) => (
					<div key={i}>{e.strings.join(', ')}</div>
				))}
			</div>
			<div className="ds-label">Analysis fields</div>
			<div className="ds-blob">{displayFieldMap(configuration.uiFieldMapping.analysisMetaFields)}</div>
			<div className="ds-label">Display fields</div>
			<div className="ds-blob">{displayFieldMap(configuration.uiFieldMapping.displayFields)}</div>
			<div className="ds-label">Subanalysis fields</div>
			<div className="ds-blob">{displayFieldMap(configuration.uiFieldMapping.subAnalysisFields)}</div>
			<div className="ds-label">Supported graphs</div>
			<div className="ds-value">{configuration.supportedGraphs?.join(', ') ?? ''}</div>
			<div className="ds-label">Subanalysis mapping</div>
			<div className="ds-blob" style={{ whiteSpace: 'pre' }}>
				{JSON.stringify(configuration.subAnalysisMapping ?? '', null, '\t')}
			</div>
		</div>
	) : null;
}

export default ConfigurationDetail;
