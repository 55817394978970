import React from 'react';
import HeaderNav from '../common/HeaderNav';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppDispatch } from '../../../store/hooks';
import { clearData } from './editReducer';

export default function QuestionsHeader({ history }: RouteComponentProps) {
	const dispatch = useAppDispatch();

	function handleNew() {
		dispatch(clearData());
		history.push('/datastudio/survey/question/edit');
	}

	return (
		<div className="survey-header">
			<HeaderNav />
			<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleNew}>
				New campaign question
			</Button>
		</div>
	);
}
