import BezierEasing from 'bezier-easing';

const constants = {
    DefaultDepartmentName: 'Company-wide',
    DefaultScope: 'company wide',
    DefaultCategory: 'All',
    DefaultCategoryUrl: 'all',
    DefaultPeriodUrl: 'month',
    SummaryMarginY: 24,
    DefaultInterval: 'Month',
    DefaultIntervalCount: 4,
    DateFormat: 'MM/DD/YYYY',
    ApiDateFormat: 'YYYY-MM-DD',
    CommentDateFormat: 'hh:mm A MMM DD',
    EqualityIndexValue: 'Equity Index Value',
    DefaultReportName: 'Untitled',
    MaxLabelWidth: 25,
    MaxRefLabelWidth: 15,
    AnimationDuration: 200,
    EasingFn: BezierEasing(0.3, 0.16, 0.17, 1),
    ShadowScroll: 0,
    InvalidValue: -999,
    MaxHeaderLength: 14,
    FirebaseApiKey:
        process.env.REACT_APP_ENV !== 'prod'
            ? 'AIzaSyD0oN0NmBmJN7vXMxCRD8J6F7EBcGfNVCQ'
            : 'AIzaSyD7W98mSCffOiqUbjQhkQkV-shWQ6mJB80',
    SlidesConfig: {
        ClientId:
            process.env.REACT_APP_ENV !== 'prod'
                ? '15133032338-hnkpbqpr9psf8cv4dl2mucll187k96va.apps.googleusercontent.com'
                : '941201438136-dssc290rmo05tpm4ncrfmkk662m6t5et.apps.googleusercontent.com',
        ApiKey:
            process.env.REACT_APP_ENV !== 'prod'
                ? 'AIzaSyA82ut0bMLPolLWQPJbKaPn6JlpyNBb-Bg'
                : 'AIzaSyB4dumTG91mBUGRhmzl26ewmtSzn1rS3GA',
        DiscoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
            'https://slides.googleapis.com/$discovery/rest?version=v1',
        ],
        Scopes: 'https://www.googleapis.com/auth/drive.file',
    },
    GCPConfig: {
        ClientId: '667507631616-gvolhson3ej57n9ih6l3s7c5cm4cfn50.apps.googleusercontent.com',
        ApiKey: 'AIzaSyBtG3dD2UejrGJ8WAzniTznV9MbBlF12js',
        AudiencesBucketName: 'dandi-audiences',
    },
    DefaultLogoUrls: {
        dark: 'https://storage.googleapis.com/dandi-prod-assets/dandi-logo2.png',
        light: 'https://storage.googleapis.com/dandi-prod-assets/dandi-logo2.png',
    },
};

export default constants;
