import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { getConfigurations } from '../configuration/actions';

export function getPackages() {
	return async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: ActionTypes.GetPackagesPending,
			});
			const response = await axios.post(`${Urls.DataStudioApi}packages`, {});
			const shouldFetchConfig = !getState().datastudio.configurations.loaded;
			if (shouldFetchConfig) {
				await dispatch(getConfigurations());
			}
			dispatch({
				type: ActionTypes.GetPackagesFulfilled,
				data: response.data,
				configData: getState().datastudio.configurations.data,
			});
		} catch (error: any) {
			errorHandler.report(error);
			dispatch({
				type: ActionTypes.GetPackagesRejected,
			});
		}
	};
}

export function savePackage(packageObj: any) {
	return async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: ActionTypes.SavePackagePending,
			});
			await axios.put(`${Urls.DataStudioApi}packages`, packageObj);
			dispatch({
				type: ActionTypes.SavePackageFulfilled,
				packageObj,
			});
		} catch (error: any) {
			errorHandler.report(error);
			dispatch({
				type: ActionTypes.SavePackageRejected,
			});
		}
	};
}
