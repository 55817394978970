import IEBar from '../common/IEBar';
import MessageBar from '../common/MessageBar';
import PermanentBar from '../common/PermanentBar';
import cn from '../lib/cn';
import Banner from './Banner';
import ContentArea from './ContentArea';
import TopBar from './TopBar';
import TopMenu from './TopMenu';
import './styles.scss';
import { useAppSelector } from '../store/hooks';
import AiModal from '../common/AiModal';

function Layout({ aiLayout }: { aiLayout: boolean }) {
	const { aiModalOpen } = useAppSelector(state => state.ai.modal);
	return (
		<div className="layout">
			<IEBar />
			<Banner />
			<TopBar TopMenu={TopMenu} aiLayout={aiLayout} />
			<ContentArea aiLayout={aiLayout} />
			<MessageBar />
			<PermanentBar />
			<canvas id="the-canvas" className={cn('hidden')} />
			{aiModalOpen && <AiModal />}
		</div>
	);
}

export default Layout;
