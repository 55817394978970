import React from 'react';
import PropTypes from 'prop-types';
import PieChart from './PieChart';
import GridConstants from '../constants/GridConstants';
import BenchmarkChartInner from './BenchmarkChartInner';
import Loader from '../lottie/graph-loader.json';
import LottieAnimation from './LottieAnimation';
import '../editor/chart/styles.scss';

export default function BenchmarkChart(props) {
	const {
		height,
		data,
		dates,
		interval,
		lifecycle,
		analysisType,
		currentIndex,
		length,
		colWidth,
		onHover,
		onHoverEnd,
		onToggle,
		selectCurrentTime,
		reportId,
		dashboardName,
		title,
		disableInteractions,
		benchmarkData,
		showPie,
		dataProp,
		columnProps,
		hoveredItem,
		selection,
		width,
	} = props;
	const halfLength = length / 2;
	const halfWidth = width / 2 - GridConstants.ColumnGap / 2;

	return (
		<div className="benchmark-chart" data-export-type="benchmark">
			<PieChart
				width={halfWidth}
				height={height}
				data={data}
				dates={dates}
				interval={interval}
				lifecycle={lifecycle}
				analysisType={analysisType}
				currentIndex={currentIndex}
				length={halfLength}
				colWidth={colWidth}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				hoveredItem={hoveredItem}
				selection={selection}
				selectCurrentTime={selectCurrentTime}
				reportId={reportId}
				dashboardName={dashboardName}
				title={title}
				dataProp={dataProp}
				columnProps={columnProps}
			/>
			<div style={{ width: GridConstants.ColumnGap }} />
			{(benchmarkData.pending || benchmarkData.error) && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: halfWidth + GridConstants.ColumnGap + GridConstants.Padding,
						width: halfWidth,
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						pointerEvents: 'none',
					}}
				>
					{benchmarkData.pending ? (
						<LottieAnimation
							autoplay={true}
							loop={true}
							width={100}
							height={100}
							animation={Loader}
						></LottieAnimation>
					) : (
						<div className="benchmark-chart__error">
							Sorry! We’ve encountered an issue displaying this graph.
						</div>
					)}
				</div>
			)}
			{!benchmarkData.pending && !benchmarkData.error ? (
				<BenchmarkChartInner
					benchmarkName={benchmarkData.benchmarkName}
					width={halfWidth}
					height={height}
					data={benchmarkData.data}
					disableInteractions={disableInteractions}
					showPie={showPie}
					reportId={reportId}
					columnProps={columnProps}
					interval={interval}
					onHover={onHover}
					onHoverEnd={onHoverEnd}
					onToggle={onToggle}
					hoveredItem={hoveredItem}
					selection={selection}
				/>
			) : (
				<div style={{ width: halfWidth, height }} />
			)}
		</div>
	);
}

BenchmarkChart.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	data: PropTypes.array,
	dates: PropTypes.array,
	interval: PropTypes.string,
	lifecycle: PropTypes.string,
	analysisType: PropTypes.string,
	currentIndex: PropTypes.number,
	length: PropTypes.number,
	colWidth: PropTypes.number,
	onHover: PropTypes.func,
	onHoverEnd: PropTypes.func,
	onToggle: PropTypes.func,
	selectCurrentTime: PropTypes.func,
	reportId: PropTypes.string,
	dashboardName: PropTypes.string,
	title: PropTypes.string,
	disableInteractions: PropTypes.bool,
	benchmarkData: PropTypes.object,
	showPie: PropTypes.bool,
	dataProp: PropTypes.string,
	columnProps: PropTypes.array,
	hoveredItem: PropTypes.string,
	selection: PropTypes.array,
};

BenchmarkChart.defaultProps = {
	benchmarkData: {
		data: [],
	},
};
