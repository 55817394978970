import ActionTypes from '../constants/ActionTypes';
import axios from 'axios';
import * as uuid from 'uuid';
import Urls from '../constants/Urls';
import errorHandler from '../lib/errorHandler';

export function openBenchmarkModal() {
	return {
		type: ActionTypes.OpenBenchmarkModal,
	};
}

export function closeBenchmarkModal() {
	return {
		type: ActionTypes.CloseBenchmarkModal,
	};
}

export function showPreviewInBenchmarkModal(showPreview: boolean) {
	return {
		type: ActionTypes.ShowPreviewInBenchmarkModal,
		showPreview,
	};
}

export function fetchBenchmarks() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		dispatch({
			type: ActionTypes.FetchBenchmarksPending,
		});
		return axios
			.post(`${Urls.BenchmarkApi}benchmark/all`, { enterpriseId })
			.then(response => {
				const { data } = response;
				dispatch({
					type: ActionTypes.FetchBenchmarksFulfilled,
					data,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.FetchBenchmarksRejected,
				});
			});
	};
}

export function queryBenchmark(attribute: any, query: any) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.QueryBenchmarkPending,
		});
		return axios
			.post(`${Urls.BenchmarkApi}benchmark/query`, query)
			.then(response => {
				const { data } = response;
				if (data.length === 0) {
					dispatch({
						type: ActionTypes.QueryBenchmarkNoData,
					});
				} else {
					dispatch({
						type: ActionTypes.QueryBenchmarkFulfilled,
						attribute,
						data: data[0].level1Results,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.QueryBenchmarkRejected,
				});
			});
	};
}

export function saveBenchmark(
	benchmarkId: string | undefined,
	benchmarkName: string,
	attribute: any,
	query: any,
	isOther: boolean,
	fromEditor?: boolean
) {
	return (dispatch: any, getState: any) => {
		dispatch({
			type: ActionTypes.SaveBenchmarkPending,
		});
		const { enterpriseId } = getState().account;
		const benchmark = {
			benchmarkId: benchmarkId || uuid.v4(),
			benchmarkName,
			enterpriseId,
			attributes: [attribute],
			query,
			data: undefined,
		};
		return axios
			.put(`${Urls.BenchmarkApi}benchmark/save`, benchmark)
			.then(() => {
				const { data } = getState().benchmarkModal;
				benchmark.data = data;
				dispatch({
					type: ActionTypes.SaveBenchmarkFulfilled,
					benchmark,
				});
				if (!isOther) {
					dispatch({
						type: fromEditor ? ActionTypes.SetBenchmarkInEditor : ActionTypes.SetBenchmark,
						benchmark,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveBenchmarkRejected,
				});
			});
	};
}

export function deleteBenchmark(benchmarkId: string) {
	return (dispatch: any, getState: any) => {
		dispatch({
			type: ActionTypes.DeleteBenchmarkPending,
		});
		const { enterpriseId } = getState().account;
		return axios
			.put(`${Urls.BenchmarkApi}benchmark/delete`, {
				enterpriseId,
				benchmarkId,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.DeleteBenchmarkFulfilled,
					benchmarkId,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.DeleteBenchmarkRejected,
				});
			});
	};
}
