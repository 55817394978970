import { StaticContext, useHistory } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from '../../../../common/Button';
import HeaderWrapper from '../../../../common/HeaderWrapper';
import Stack from '../../../../common/Stack';
import WithPermissions from '../../../../common/WithPermissions';
import ButtonTypes from '../../../../constants/ButtonTypes';
import RbacActions from '../../../../constants/RbacActions';
import TopLeftAction from '../../../../layout/TopLeftAction';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createSnapshot, updateSnapshot } from '../actions';
import { nextStep, previousStep } from '../slice';

interface HeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const Header = (props: HeaderProps) => {
	const {
		location: { state },
	} = props;
	const { isBeginning, isEnd, title, validated, mode, selectedIndex, steps, pending } = useAppSelector(
		state => state.snapshotSurveys.edit
	);
	const dispatch = useAppDispatch();
	const history = useHistory();

	return (
		<WithPermissions actions={[RbacActions['Campaign/Edit']]}>
			<HeaderWrapper>
				<Stack gap="2rem" flexDirection="row" alignItems="center">
					<TopLeftAction scrollPos={state?.scrollPos} />
					{title ? (
						<h3>
							{title} <span style={{ color: 'var(--color-ui-50)' }}>- {selectedIndex + 1}/4</span>
						</h3>
					) : (
						<span>Loading...</span>
					)}
				</Stack>
				<Stack gap=".8rem" flexDirection="row" alignItems="center">
					<Button
						onClick={() => dispatch(previousStep())}
						disabled={isBeginning}
						componentType={ButtonTypes.type.SECONDARY}
					>
						Previous
					</Button>
					<Button
						disabled={isEnd || !steps[selectedIndex].validated}
						componentType={ButtonTypes.type.PRIMARY}
						onClick={() => dispatch(nextStep())}
					>
						Next
					</Button>
					<Button
						onClick={() => {
							if (mode === 'edit') {
								dispatch(updateSnapshot()).then(() => {
									history.push('/self-id/campaigns');
								});
							} else if (mode === 'create') {
								dispatch(createSnapshot()).then(() => {
									history.push('/self-id/campaigns');
								});
							}
						}}
						disabled={!validated || pending}
					>
						{pending ? 'Saving...' : 'Save'}
					</Button>
				</Stack>
			</HeaderWrapper>
		</WithPermissions>
	);
};

export default withRouter(Header);
