import React, { useEffect, useState, useRef, useReducer, MouseEvent } from 'react';
import DropdownArrow from '../icons/DropdownArrow';
import classNames from 'classnames';
import Close from '../icons/Close';
import Button from './Button';

const DropdownWrapper = ({
	placeholder,
	selectedText,
	marginLeft,
	disabled,
	usePlaceholder,
	allowCancel,
	type,
	selectedStyle,
	buttonType,
	buttonLarge,
	onClear,
	selected,
	children,
}: any) => {
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);

	// on mount
	useEffect(() => {
		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (open) {
			window.addEventListener('mousedown', handleClickOutside);
			window.addEventListener('keydown', handleKeyDown, true);
		} else {
			window.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('keydown', handleKeyDown);
		}
	}, [open]);

	function handleKeyDown(e: any) {
		if (e.keyCode == 27) {
			if (open) {
				e.stopPropagation();
				setOpen(false);
			}
		}
	}

	function handleClickOutside(e: any) {
		if (open) {
			if (ref.current && !ref.current.contains(e.target)) {
				e.stopPropagation();
				setOpen(false);
			}
		}
	}

	function handleClose(option: any, e: any) {
		setOpen(false);
	}

	function toggleOpen() {
		setOpen(!open);
	}

	function handleClearSelection(e: MouseEvent) {
		e.stopPropagation();
		e.preventDefault();
		onClear();
	}

	return (
		<div
			className={classNames('dropdown', {
				'dropdown--disabled': disabled,
				'dropdown--placeholder': !selectedText,
			})}
			ref={ref}
		>
			<Button
				type={type}
				componentType={buttonType}
				large={buttonLarge}
				onClick={toggleOpen}
				activated={open}
				selected={selected}
				selectedStyle={selectedStyle}
				disabled={disabled}
			>
				<span>{usePlaceholder ? placeholder : selectedText || placeholder}</span>
				{allowCancel && selectedText ? (
					<Close
						width={18}
						height={12}
						onClick={(e: any) => {
							handleClearSelection(e);
							setOpen(true);
						}}
					/>
				) : (
					<DropdownArrow width={18} height={18} />
				)}
			</Button>

			{React.cloneElement(React.Children.only(children), {
				marginLeft,
				open,
				onClose: handleClose,
			})}
		</div>
	);
};

DropdownWrapper.defaultProps = {
	marginLeft: 0,
	buttonType: 'secondary',
	textFieldType: 'default',
	type: 'button',
	selectedStyle: 'default',
	buttonLarge: true,
	selected: false,
};

export default DropdownWrapper;
