import React from 'react';
import { Category } from '../category/types';
import { Question as QuestionModel } from '../question/types';
import { push } from 'connected-react-router';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { toggleQuestion } from './editReducer';

interface QuestionProps {
	categories: Category[];
	question: QuestionModel;
}

export default function Question({ categories, question }: QuestionProps) {
	const dispatch = useAppDispatch();
	const { questions } = useAppSelector(state => state.datastudio.survey.surveyEdit.surveyToEdit);

	function handleEdit() {
		dispatch(toggleQuestion(question));
	}

	function getCategoryName(globalCategoryId: string) {
		return categories.find(c => c.globalCategoryId === globalCategoryId)?.title ?? '';
	}

	const isSelected = questions.some(q => q.globalQuestionId === question.globalQuestionId);

	return (
		<div
			className={`questions-grid__question ${isSelected ? 'questions-grid__question--selected' : ''}`}
			key={question.title}
			onClick={() => handleEdit()}
		>
			<div className="questions-grid__question__title">{question.title}</div>
			<div className="questions-grid__question__category">{getCategoryName(question.globalCategoryId)}</div>
			<div className="questions-grid__question__field">{question.fieldName}</div>
		</div>
	);
}
