import { PropsWithChildren, useEffect, useRef } from 'react';

import Dropdown from '../../common/DropdownV2';
import cn from '../../lib/cn';

interface FilterDropdownProps extends PropsWithChildren {
	alignDropdownRight?: boolean;
	allowCancel?: boolean;
	children?: React.ReactNode;
	disabled?: boolean;
	isOpen?: boolean;
	isSecondary?: boolean;
	label?: string;
	limitHeight?: number;
	onClearSelection?: () => void;
	onMouseLeaveDropdown?: () => void;
	placeholder?: string;
	setIsOpen: (isOpen: boolean) => void;
	setShouldClose?: (shouldClose: boolean) => void;
	setShouldOpen?: (shouldOpen: boolean) => void;
	shouldClose?: boolean;
	shouldOpen?: boolean;
}

export default function FilterDropdown({
	alignDropdownRight,
	allowCancel,
	children,
	disabled,
	isOpen,
	isSecondary,
	label,
	limitHeight,
	onClearSelection,
	onMouseLeaveDropdown,
	placeholder,
	setIsOpen,
	setShouldClose,
	setShouldOpen,
	shouldClose,
	shouldOpen,
}: FilterDropdownProps) {
	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', handleKeyDown);
			window.addEventListener('mousedown', handleClickOutside);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				window.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [isOpen]);

	const containerRef = useRef<HTMLDivElement>(null);

	function handleKeyDown(e: any) {
		if (e.keyCode === 27) {
			setIsOpen(false);
		}
	}

	function handleClickOutside(e: any) {
		if (isOpen) {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				e.stopPropagation();
				setIsOpen(false);
			}
		}
	}

	return (
		<div className={cn('filter-dropdown text-[1.4rem]')}>
			<Dropdown
				allowCancel={allowCancel}
				isOpen={isOpen}
				disabled={disabled}
				limitHeight={limitHeight && '36vh'}
				buttonLarge={true}
				label={label}
				placeholder={placeholder}
				alignDropdownRight={alignDropdownRight}
				onMouseLeaveDropdown={onMouseLeaveDropdown}
				onClearSelection={onClearSelection}
				fullWidth
				setShouldClose={setShouldClose}
				setShouldOpen={setShouldOpen}
				shouldClose={shouldClose}
				shouldOpen={shouldOpen}
				variant={isSecondary ? 'secondary' : 'primary'}
			>
				<div className="filter-dropdown__inner">{children}</div>
			</Dropdown>
		</div>
	);
}
