import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import qs from 'qs';
import { clearFilters, generateInExplore, navigate } from './actions';
import { getAllSegments } from '../../store/actionUtils';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import { track } from '../../lib/segment';
import mixPanel from '../../constants/MixPanel';
import { syncExploreDashboardReportState } from '../dashboardReport/actions';
import { syncExploreFilterState } from '../../explore/filter/actions';
import { syncExploreChartState } from '../chart/actions';
import { syncExploreSubtableState } from '../subtable/actions';

export default function useUrlStateSync({ docked }: { docked?: boolean }) {
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const [valueParsed, setValueParsed] = useState(!search);

	const { analysisName, mainSegments, filterSegment, breakdownSegment, attributes } = useAppSelector(
		state => state.explore.filter
	);

	useEffect(() => {
		if (search) {
			const query: any = qs.parse(search, {
				ignoreQueryPrefix: true,
				arrayLimit: 10000,
			});

			const { filter, chart, dashboard, subtable } = query;

			if (chart) {
				dispatch(syncExploreChartState(chart));
			}
			if (subtable) {
				dispatch(syncExploreSubtableState(subtable));
			}
			if (filter) {
				dispatch(syncExploreFilterState(filter));
			}

			if (filter || chart || subtable) {
				dispatch(generateInExplore());
			}
			if (dashboard) {
				dispatch(syncExploreDashboardReportState(dashboard));
				if (!filter && !chart && !subtable) {
					dispatch(clearFilters());
				}
			}

			setValueParsed(true);
		} else if (!docked) {
			dispatch(clearFilters());
		}
	}, [search]);

	const handleGenerate = useCallback(() => {
		const segments = getAllSegments({
			mainSegments,
			filterSegment,
			breakdownSegment,
		});
		const mixpanelObj = getMixPanelObjectForFilters(analysisName, segments, attributes);
		track(mixPanel.Events.ExploreGenerate, mixpanelObj);
		dispatch(navigate());
	}, [analysisName, mainSegments, filterSegment, breakdownSegment, dispatch]);

	return {
		valueParsed,
		handleGenerate,
	};
}
