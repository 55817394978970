import { useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ScrollableChartArea from '../../common/ScrollableChartArea';
import ChartContainer from '../../common/ChartContainer';
import ChartHeader from '../../common/ChartHeaderV2';
import Events from '../../common/EventsV2';
import XAxis from '../../common/XAxisV2';
import Legend from '../../common/LegendV2';
import Empty from '../../common/Empty';
import AnalysisTypes from '../../constants/AnalysisTypes';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { fetchGoal } from './reducer';
import LineChart from '../../common/LineChartV2';
import { ChartContext } from '../../contexts/ChartContext';
import EventsModal from '../../common/EventsModal';
import { openEventsModalInGoal, closeEventsModalInGoal } from './reducer';

const Chart = () => {
	// Global State and Dispatch
	const { goal, chartData, goalId, generated, generateError, generatePending, generateNoData, analysisType } =
		useAppSelector(state => state.measure.goal);
	const dispatch = useAppDispatch();

	const { chartState, chartDispatch } = useContext(ChartContext);

	// initialize chart
	useEffect(() => {
		if (!chartData || (chartData.length === 0 && goalId)) {
			chartDispatch({
				type: 'setChartHeight',
				payload: 300,
			});
			dispatch(fetchGoal());
		}
	}, [chartData, dispatch, fetchGoal, goalId]);

	useEffect(() => {
		if (!chartState.mode) {
			chartDispatch({
				type: 'setMode',
				payload: 'measure',
			});
		}
	}, [chartState.mode, chartDispatch]);

	useEffect(() => {
		function onResize() {
			if (!containerRef.current) return;
			chartDispatch({
				type: 'setChartWidth',
				payload: containerRef.current.offsetWidth,
			});
		}

		const observer = new ResizeObserver(onResize);
		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (chartState.mode && chartState.chartHeight && chartState.chartWidth) {
			chartDispatch({
				type: 'setInitialized',
				payload: true,
			});
		}
	}, [chartState.mode, chartState.chartHeight, chartState.chartWidth, chartDispatch]);

	const containerRef = useRef<HTMLDivElement>(null);
	const chartHeaderRef = useRef<HTMLDivElement>(null);
	const scrollableChartAreaRef = useRef<HTMLDivElement>(null);
	const legendRef = useRef<HTMLDivElement>(null);

	const isEmpty = generatePending || generateError || generateNoData || !generated || !chartState.initialized;

	return (
		<div
			className="goals-chart"
			style={{
				borderRadius: '.8rem',
				overflow: 'hidden',
				marginTop: '1.6rem',
			}}
		>
			<ChartContainer ref={containerRef}>
				{isEmpty ? (
					<Empty
						pending={generatePending}
						error={generateError ?? ''}
						noData={generateNoData}
						generated={generated}
						invalidVariation={false}
						initialized={chartState.initialized}
						errorContent={
							<div>
								Sorry, this goal does not exist. View all of your goals{' '}
								<Link to="/measure">
									<span
										style={{
											textDecoration: 'underline',
										}}
									>
										here
									</span>
								</Link>
								.
							</div>
						}
					/>
				) : (
					<>
						<ChartHeader ref={chartHeaderRef}>
							<ChartHeader.Title>{goal?.goalName || ''} - Goal</ChartHeader.Title>
							<ChartHeader.Actions>
								<ChartHeader.MoreMenu>
									<ChartHeader.MoreMenuItem
										onClick={() => {
											dispatch(openEventsModalInGoal({}));
										}}
									>
										Events
									</ChartHeader.MoreMenuItem>
								</ChartHeader.MoreMenu>
							</ChartHeader.Actions>
						</ChartHeader>
						<ScrollableChartArea ref={scrollableChartAreaRef} disableOverflow={true}>
							<Legend ref={legendRef} />
							<LineChart reportId="goal-chart">
								{analysisType === AnalysisTypes.LinearRegression && (
									<LineChart.LinearRegressionBackground />
								)}
								{analysisType === AnalysisTypes.Index && <LineChart.IndexBackground />}
								<LineChart.GoalLine />
								<LineChart.Lines />
								<LineChart.GoalValues />
								<LineChart.Points />
								<LineChart.CurrentIndexLabel />
							</LineChart>
							<Events>
								<Events.RegularEvents />
							</Events>
						</ScrollableChartArea>
						<XAxis>
							<XAxis.GoalLabel />
							<XAxis.GuideLabels />
						</XAxis>
					</>
				)}
				<EventsModal onCancel={() => dispatch(closeEventsModalInGoal({}))} />
			</ChartContainer>
		</div>
	);
};

export default Chart;
