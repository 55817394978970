import React, { useState, useEffect, useRef } from 'react';
import cn from '../lib/cn';

const ResponsiveContainer = ({ children, className, ...rest }: any) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const containerRef = useRef<any>();

	useEffect(() => {
		const updateDimensions = () => {
			if (containerRef.current) {
				setDimensions({
					width: containerRef.current.offsetWidth,
					height: containerRef.current.offsetHeight,
				});
			}
		};

		updateDimensions();

		const resizeObserver = new ResizeObserver(updateDimensions);
		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		return () => {
			if (containerRef.current) {
				resizeObserver.unobserve(containerRef.current);
			}
		};
	}, []);

	return (
		<div ref={containerRef} className={cn('w-full h-full absolute inset-0', className)} {...rest}>
			{children(dimensions)}
		</div>
	);
};

export default ResponsiveContainer;
