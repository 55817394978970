import { useAppSelector } from '../store/hooks';
import hasPermission from '../lib/hasPermission';
import RbacActions from '../constants/RbacActions';

interface IUsePermissionsProps {
	actions: RbacActions[];
}

const usePermissions = (props: IUsePermissionsProps) => {
	const { actions } = props;
	const { staticPermissions } = useAppSelector<any>(state => state.auth);

	return hasPermission(staticPermissions, actions);
};

export default usePermissions;
