const SvgComponent = (props: any) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M8.80768 6.07672H10.6537V4.23047H8.80768V6.07672ZM12.4999 6.07672V4.23047H14.3462V6.07672H12.4999ZM8.80768 13.4612V11.6152H10.6537V13.4612H8.80768ZM16.1922 9.76897V7.92272H18.0384V9.76897H16.1922ZM16.1922 13.4612V11.6152H18.0384V13.4612H16.1922ZM12.4999 13.4612V11.6152H14.3462V13.4612H12.4999ZM16.1922 6.07672V4.23047H18.0384V6.07672H16.1922ZM10.6537 7.92272V6.07672H12.4999V7.92272H10.6537ZM5.96143 19.4612V4.23047H6.96143V6.07672H8.80768V7.92272H6.96143V9.76897H8.80768V11.6152H6.96143V19.4612H5.96143ZM14.3462 11.6152V9.76897H16.1922V11.6152H14.3462ZM10.6537 11.6152V9.76897H12.4999V11.6152H10.6537ZM8.80768 9.76897V7.92272H10.6537V9.76897H8.80768ZM12.4999 9.76897V7.92272H14.3462V9.76897H12.4999ZM14.3462 7.92272V6.07672H16.1922V7.92272H14.3462Z" />
	</svg>
);

export default SvgComponent;
