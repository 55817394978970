import { useEffect, useRef } from 'react';
import ButtonTypes from '../../constants/ButtonTypes';
import Button from '../Button';
import Close from '../../icons/Close';
import DropdownArrow from '../../icons/DropdownArrow';
import { CSSTransition } from 'react-transition-group';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
	onClick: (option: any) => void;
	placeholder?: string;
	buttonLarge?: boolean;
	allowCancel?: boolean;
	disabled?: boolean;
	isOpen?: boolean;
	label?: string;
	children?: React.ReactNode;
	onClearSelection?: () => void;
	onMouseLeaveDropdown?: () => void;
	limitHeight?: number;
	alignDropdownRight?: boolean;
	selected?: boolean;
}

export default function Dropdown({
	placeholder,
	allowCancel,
	disabled,
	isOpen,
	label,
	buttonLarge,
	children,
	limitHeight,
	alignDropdownRight,
	selected,
	onClick,
	onClearSelection,
	onMouseLeaveDropdown,
	...rest
}: DropdownProps) {
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				onMouseLeaveDropdown?.();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleClickOutside, false);
		return () => {
			document.removeEventListener('keydown', handleClickOutside, false);
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	return (
		<div className="filter-dropdown" style={{ zIndex: 10 }} {...rest}>
			<Button
				type="button"
				componentType={ButtonTypes.type.SECONDARY}
				large={buttonLarge}
				onClick={onClick}
				activated={isOpen}
				disabled={disabled}
				selected={selected}
			>
				<span className="long-text">{label || placeholder}</span>
				<div style={{ width: 4 }} />
				{allowCancel && label ? (
					<Close
						width={12}
						height={12}
						onClick={(e: any) => {
							e.stopPropagation();
							if (!disabled) {
								onClearSelection?.();
							}
						}}
					/>
				) : (
					<DropdownArrow width={18} height={18} />
				)}
			</Button>
			<CSSTransition in={isOpen} timeout={300} mountOnEnter unmountOnExit classNames="fade-scale">
				<div
					data-test={`dropdown-menu-${placeholder}`}
					onMouseLeave={onMouseLeaveDropdown}
					ref={dropdownRef}
					style={{
						position: 'absolute',
						top: '4.8rem',
						width: 'auto',
						borderRadius: '0.8rem',
						// overflow: 'hidden',
						background: 'var(--color-shade-h4)',
						...(alignDropdownRight && {
							right: '-3.2rem',
						}),
					}}
				>
					{children}
				</div>
			</CSSTransition>
		</div>
	);
}
