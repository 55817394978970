import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { getConfigurations } from '../configuration/actions';

export function getEnterprises() {
	return async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: ActionTypes.GetDSEnterprisesPending,
			});
			const response = await axios.post(`${Urls.DataStudioApi}enterprises/packages`, {});
			const shouldFetchConfig = !getState().datastudio.configurations.loaded;
			if (shouldFetchConfig) {
				await dispatch(getConfigurations());
			}
			dispatch({
				type: ActionTypes.GetDSEnterprisesFulfilled,
				data: response.data,
				configData: getState().datastudio.configurations.data,
			});
		} catch (error: any) {
			errorHandler.report(error);
			dispatch({
				type: ActionTypes.GetDSEnterprisesRejected,
			});
		}
	};
}

export function saveEnterprise(enterprise: any, enterpriseName: string) {
	return async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: ActionTypes.SaveEnterprisePending,
			});
			await axios.put(`${Urls.DataStudioApi}enterprises/packages`, enterprise);
			dispatch({
				type: ActionTypes.SaveEnterpriseFulfilled,
				enterprise,
				enterpriseName,
			});
		} catch (error: any) {
			errorHandler.report(error);
			dispatch({
				type: ActionTypes.SaveEnterpriseRejected,
			});
		}
	};
}
