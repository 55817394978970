import React from 'react';
import Popover from '../../../common/Popover';
import More from '../../../icons/More';
import RolesMoreItems from './RolesMoreItems';

interface RolesMoreProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	roleId: string;
}

const RolesMore = ({ open, setOpen, roleId }: RolesMoreProps) => {
	return (
		<Popover
			closeOnScroll={true}
			placement="bottom"
			overlayClassName="popover--no-padding"
			destroyTooltipOnHide
			showArrow={false}
			visible={open}
			onVisibleChange={() => {
				setOpen(!open);
			}}
			content={<RolesMoreItems open={open} setOpen={setOpen} roleId={roleId} />}
		>
			<div onClick={e => e.stopPropagation()}>
				<More width={20} height={20} className="icon"></More>
			</div>
		</Popover>
	);
};

export default RolesMore;
