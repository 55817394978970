import React from 'react';
import { useHistory } from 'react-router-dom';
import WithPermissions from '../../../common/WithPermissions';
import MixPanel from '../../../constants/MixPanel';
import RbacActions from '../../../constants/RbacActions';
import usePermissions from '../../../hooks/usePermissions';
import { track } from '../../../lib/segment';
import { useAppDispatch } from '../../../store/hooks';
import Table from '../../Table';
import RolesMore from './RolesMore';

interface RolesTableRowProps {
	role: any;
}

const RolesTableRow = ({ role }: RolesTableRowProps) => {
	const [open, setOpen] = React.useState(false);
	const dispatch = useAppDispatch();
	const roleEditPermission = usePermissions({
		actions: [RbacActions['Roles/Edit']],
	});
	const roleViewPermission = usePermissions({
		actions: [RbacActions['Roles/View']],
	});

	const history = useHistory();

	const handleClickRole = (e: any) => {
		if (!roleEditPermission && !roleViewPermission) return;
		e.stopPropagation();
		track(MixPanel.Events.AdminRolesPermissionsClickEditRole, {
			id: role.id,
			name: role.name,
		});
		history.push(`/admin/edit-role/${role.id}`);
	};

	return (
		<Table.Row base={10} key={role.id}>
			<Table.Cell
				span={4}
				onClick={handleClickRole}
				style={{ cursor: roleViewPermission ? 'pointer' : 'default' }}
			>
				<span className="long-text">{role.name}</span>
			</Table.Cell>
			<Table.Cell
				span={5}
				onClick={handleClickRole}
				style={{
					cursor: roleViewPermission ? 'pointer' : 'default',
				}}
			>
				<WithPermissions actions={[RbacActions['Users/View']]}>
					<span>{role.count ?? 0}</span>
				</WithPermissions>
			</Table.Cell>
			<Table.Cell span={1} justifyContent="flex-end">
				<div
					style={{
						paddingRight: '1.6rem',
					}}
				>
					<WithPermissions actions={[RbacActions['Roles/Edit']]}>
						<RolesMore open={open} setOpen={setOpen} roleId={role.id} />
					</WithPermissions>
				</div>
			</Table.Cell>
		</Table.Row>
	);
};

export default RolesTableRow;
