import React from 'react';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { saveQuestion } from './editReducer';
import qs from 'qs';

export default function QuestionEditHeader({ history, location }: RouteComponentProps) {
	const dispatch = useAppDispatch();
	const {
		title,
		fieldName,
		globalCategoryId,
		enterpriseId,
		values: answers,
		description,
		questionType,
		inputType,
	} = useAppSelector(state => state.datastudio.survey.questionEdit.questionToEdit);
	const disableSave = !title || !globalCategoryId || !fieldName || !questionType || !inputType || !answers.length;
	const { globalQuestionId } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});

	function handleCancel() {
		history.goBack();
	}

	async function handleSave() {
		const values = answers.map(a => ({
			value: a.value,
			score: a.score,
		}));
		const answerDescs: any[] = [];
		answers.forEach(answer => {
			if (answer.description) {
				answerDescs.push({
					value: answer.value,
					desc: answer.description,
				});
			}
		});

		await dispatch(
			saveQuestion({
				globalQuestionId: globalQuestionId as string,
				title,
				globalCategoryId,
				fieldName,
				enterpriseId,
				values,
				valueType: questionType === 'Single-select' ? 'RADIOBUTTON' : 'CHECKBOX',
				isPrivate: !!enterpriseId,
				uiElements: [
					{
						key: 'description',
						value: description,
					},
					{
						key: 'questionType',
						value: questionType,
					},
					{
						key: 'inputType',
						value: inputType,
					},
					{
						key: 'answerDescs',
						value: JSON.stringify(answerDescs),
					},
				],
			})
		);
	}

	return (
		<div className="survey-header">
			<div className="survey-header__title">Edit Question</div>
			<div className="survey-header__actions">
				<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
					Cancel
				</Button>
				<Button componentType={ButtonTypes.type.PRIMARY} disabled={disableSave} onClick={handleSave}>
					Save
				</Button>
			</div>
		</div>
	);
}
