import Modal from 'antd/lib/modal';
import { useEffect, useState } from 'react';

import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import TextField from '../common/TextField';
import ButtonTypes from '../constants/ButtonTypes';
import mixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { setChartEventsInEditor } from '../editor/chart/actions';
import { setChartEvents } from '../explore/chart/actions';
import { track } from '../lib/segment';
import { setChartEventsInGoal } from '../measure/goal/reducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { appModes } from '../types';
import WithPermissions from './WithPermissions';

interface IEventsModalProps {
	onCancel: () => void;
}

const EventsModal = (props: IEventsModalProps) => {
	const { eventIds, allEvents, mode, eventsModalOpen } = useAppSelector(state => {
		let returnState: any = {
			eventIds: [],
			allEvents: [],
			eventsModalOpen: false,
			mode: state.appState.mode,
		};

		switch (state.appState.mode) {
			case appModes.explore: {
				returnState = {
					...returnState,
					eventIds: state.explore.chart.eventIds || [],
					allEvents: state.timeline.events || [],
					eventsModalOpen: state.explore.chart.eventsModalOpen,
				};
				break;
			}

			case appModes.measure: {
				returnState = {
					...returnState,
					eventIds: state.measure.goal.eventIds || [],
					allEvents: state.timeline.events || [],
					eventsModalOpen: state.measure.goal.eventsModalOpen,
				};
				break;
			}
			default: {
				returnState = {
					...returnState,
					eventIds: state.editor.chart.eventIds || [],
					allEvents: state.timeline.events || [],
					eventsModalOpen: state.editor.chart.eventsModalOpen,
				};
			}
		}

		return returnState;
	});

	const dispatch = useAppDispatch();
	const { onCancel } = props;
	const [filteredEvents, setFilteredEvents] = useState(allEvents);
	const [internalEventIds, setInternalEventIds] = useState(eventIds);
	const [searchValue, setSearchValue] = useState('');

	function onSetChartEvents(eventIds: string[]) {
		track(mixPanel.Events.TimelineAddEventToGraph, { eventIds });
		switch (mode) {
			case appModes.explore: {
				return dispatch(setChartEvents(eventIds));
			}
			case appModes.editor: {
				return dispatch(setChartEventsInEditor(eventIds));
			}
			case appModes.measure: {
				return dispatch(setChartEventsInGoal(eventIds));
			}
			default: {
				return;
			}
		}
	}

	function toggleEvent(eventId: string) {
		setInternalEventIds(
			internalEventIds.includes(eventId)
				? internalEventIds.filter((id: string) => id !== eventId)
				: [...internalEventIds, eventId]
		);
	}

	function handleSearch(value: string) {
		setFilteredEvents(allEvents.filter((event: any) => event.name.toLowerCase().includes(value.toLowerCase())));
	}

	function removeEvents() {
		setInternalEventIds([]);
	}

	function addAllEvents() {
		setInternalEventIds(allEvents.map((event: any) => event.eventId));
	}

	function handleClose() {
		setFilteredEvents(allEvents);
		setSearchValue('');
		onCancel();
	}

	useEffect(() => {
		handleSearch(searchValue);
	}, [searchValue]);

	useEffect(() => {
		// only change if eventIds are different
		if (eventIds.join(',') !== internalEventIds.join(',')) {
			setInternalEventIds(eventIds);
		}
	}, [eventIds]);

	return (
		<WithPermissions actions={[RbacActions['Events/View']]}>
			<Modal
				open={eventsModalOpen}
				width={400}
				footer={null}
				closable={false}
				maskClosable={true}
				onCancel={handleClose}
				zIndex={3000}
			>
				<div className="modal events-modal">
					<div className="events-modal__header">
						<span>Events</span>
					</div>
					<div className="events-modal__content">
						<div className="events-modal__search-bar">
							<TextField
								value={searchValue}
								onChange={e => setSearchValue(e.target.value)}
								isSearch={true}
								placeholder="Search events"
							/>
						</div>
						<ul className="events-modal__list">
							<li key={'all'} className="events-modal__list-item">
								<Checkbox
									checked={internalEventIds.length === allEvents.length}
									onClick={() => {
										internalEventIds.length === allEvents.length ? removeEvents() : addAllEvents();
									}}
								>
									All
								</Checkbox>
							</li>
							{filteredEvents.map((event: any, index: number) => (
								<li key={index} className="events-modal__list-item">
									<Checkbox
										checked={internalEventIds.includes(event.eventId)}
										onClick={() => toggleEvent(event.eventId)}
										testId={event.name}
									>
										{event.name}
									</Checkbox>
								</li>
							))}
						</ul>
					</div>

					<div className="events-modal__actions">
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClose}>
							Cancel
						</Button>
						<div>
							<Button
								componentType={ButtonTypes.type.TERTIARY}
								onClick={() => {
									removeEvents();
									setFilteredEvents(allEvents);
									setSearchValue('');
								}}
							>
								Clear
							</Button>
							<Button
								componentType={ButtonTypes.type.PRIMARY}
								onClick={() => {
									onSetChartEvents(internalEventIds);
									handleClose();
								}}
							>
								Apply
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</WithPermissions>
	);
};

export default EventsModal;
