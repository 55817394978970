import classNames from 'classnames';
import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import Search from '../icons/Search';
import Button from './Button';

interface TextFieldProps {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value: string;
	type?: string;
	componentType?: string;
	placeholder?: string;
	danger?: boolean;
	onClose?: (e: MouseEvent) => void;
	showClose?: boolean;
	isSearch?: boolean;
	autoFocus?: boolean;
	onBlur?: (text: string) => void;
	onFocus?: () => void;
	onKeyDown?: (event: KeyboardEvent) => void;
	large?: boolean;
	readOnly?: boolean;
	tags?: string[];
	onRemoveTag?: (tag: string) => void;
	classes?: string[];
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
	(
		{
			onChange,
			onFocus,
			onKeyDown,
			onBlur,
			autoFocus,
			value,
			type,
			placeholder,
			showClose,
			onClose,
			danger,
			isSearch,
			large,
			readOnly,
			componentType,
			tags,
			onRemoveTag,
			classes,
		},
		forwardedRef
	) => {
		const mirrorRef = useRef<HTMLSpanElement>(null);
		const containerRef = useRef<HTMLDivElement>(null);
		const fallbackRef = useRef<HTMLInputElement>(null);
		const [inputWidth, setInputWidth] = useState(20);

		const ref = forwardedRef || fallbackRef;

		useEffect(() => {
			function onResize() {
				if (!containerRef.current) return;
				const { width } = containerRef.current.getBoundingClientRect();

				setInputWidth(width);
			}

			if (!mirrorRef.current) return;

			const resizeObserver = new ResizeObserver(onResize);
			resizeObserver.observe(mirrorRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}, [value]);

		function inputFieldOffsets({ containerRef, inputWidth, tags, showClose }: any) {
			let leftOffset = 16;
			let rightOffset = 0;
			if (tags.length > 0) {
				leftOffset = 8;
			}

			const adjustedContainerWidth = containerRef.current
				? containerRef.current.clientWidth - (leftOffset + (showClose ? 48 : 16))
				: 1000;

			if (inputWidth >= adjustedContainerWidth) {
				rightOffset = showClose ? 48 : 16;
			}

			return {
				'--left-offset': `${leftOffset}px`,
				'--right-offset': `${rightOffset}px`,
			};
		}

		function handleClick() {
			if (typeof ref !== 'function' && ref.current) {
				ref.current.focus();
				ref.current.selectionStart = value?.length;
				ref.current.selectionEnd = value?.length;
			}
		}

		function handleDoubleClick() {
			if (typeof ref !== 'function' && ref.current) {
				ref.current.focus();
				ref.current.selectionStart = 0;
				ref.current.selectionEnd = value.length;
			}
		}

		function handleTextClick(e: React.MouseEvent) {
			e.stopPropagation();
		}

		function handleTextDoubleClick(e: React.MouseEvent) {
			e.stopPropagation();
		}

		return (
			<div
				onClick={handleClick}
				onDoubleClick={handleDoubleClick}
				ref={containerRef}
				className={classNames(
					'text-field',
					`text-field--${componentType}`,
					{
						'text-field--large': large,
						'text-field--danger': danger,
						'text-field--search': isSearch,
					},
					...(classes as string[])
				)}
			>
				<div className={`text-field--${componentType}__selector`}>
					<div className={`text-field--${componentType}__overflow`}>
						{!!tags?.length &&
							tags.map((tag, i) => (
								<div className={`text-field--${componentType}__overflow-item`} key={i}>
									<div className={`text-field--${componentType}__tag`}>
										<Button large={false} activated={true}>
											<span>{tag}</span>
											<svg
												onClick={() => onRemoveTag?.(tag)}
												width="15"
												height="16"
												viewBox="0 0 15 16"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg"
												className="ml4"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M4.66804 4.46436L3.96094 5.17146L6.79102 8.00154L3.96416 10.8284L4.67127 11.5355L7.49812 8.70865L10.3249 11.5354L11.032 10.8283L8.20523 8.00154L11.0352 5.17154L10.3281 4.46443L7.49812 7.29443L4.66804 4.46436Z"
													fill="currentColor"
													fillOpacity="0.75"
												/>
											</svg>
										</Button>
									</div>
								</div>
							))}
						<div className={`text-field--${componentType}__overflow-item-text`}>
							<div
								style={{
									width: '100%',
									maxWidth: inputWidth + 'px',
									...inputFieldOffsets({
										containerRef,
										inputWidth,
										tags,
										showClose,
									}),
								}}
								className={`text-field--${componentType}__input-wrapper`}
							>
								<input
									ref={ref}
									autoFocus={autoFocus}
									onFocus={onFocus}
									onBlur={e => onBlur?.(e.target.value)}
									readOnly={readOnly}
									value={value}
									type={type}
									onChange={onChange}
									onKeyDown={onKeyDown}
									onClick={handleTextClick}
									onDoubleClick={handleTextDoubleClick}
									className={`text-field--${componentType}__input`}
								/>
								<span ref={mirrorRef} className={`text-field--${componentType}__input-mirror`}>
									{value}
								</span>
							</div>
						</div>
						{!value && tags?.length === 0 && (
							<span className={`text-field--${componentType}__placeholder`}>{placeholder}</span>
						)}
						{isSearch && !value && (
							<div className={`text-field__search-icon-wrapper`}>
								<Search className="text-field__search-icon" />
							</div>
						)}

						{showClose && value && value !== '' && (
							<button className="text-field__close" onClick={onClose} type="button">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.66804 4.46436L3.96094 5.17146L6.79102 8.00154L3.96416 10.8284L4.67127 11.5355L7.49812 8.70865L10.3249 11.5354L11.032 10.8283L8.20523 8.00154L11.0352 5.17154L10.3281 4.46443L7.49812 7.29443L4.66804 4.46436Z"
										fill="currentColor"
										fillOpacity="0.75"
									/>
								</svg>
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
);

TextField.defaultProps = {
	large: true,
	componentType: 'default',
	type: 'text',
	onBlur: () => {},
	onFocus: () => {},
	tags: [],
	classes: [],
};

TextField.displayName = 'TextField';

export default TextField;
