import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import ArrowIcon from '../icons/Arrow';
import Close from '../icons/Close';
import cn from '../lib/cn';
import { useAppSelector } from '../store/hooks';
import { useOnClickHandler } from './hooks';

const TopLeftAction = ({ scrollPos }: { scrollPos?: number }) => {
	const { topLeftAction } = useAppSelector(state => state.appState);
	const { type } = topLeftAction;
	const onClickHandler = useOnClickHandler();

	const handleClick = () => {
		onClickHandler(type, topLeftAction.navigateTo, scrollPos);
	};

	if (type !== 'back' && type !== 'exit') {
		return null;
	}

	return (
		<div onClick={handleClick} className={cn('cursor-pointer')}>
			<Button componentType={ButtonTypes.type.SECONDARY} classes={[cn('w-[3.5rem] h-[3.5rem] p-0')]}>
				{type === 'back' && <ArrowIcon size="24" className={cn('-rotate-180')} />}
				{type === 'exit' && <Close size="24" />}
			</Button>
		</div>
	);
};

export default TopLeftAction;
