import { useContext } from 'react';

import { AiModalContext } from '.';
import { resetChat } from '../../ai/chat/reducer';
import { setAiModalOpen, setView } from '../../ai/modal/reducer';
import MixPanel from '../../constants/MixPanel';
import ArrowIcon from '../../icons/Arrow';
import ChatConversationsIcon from '../../icons/ChatConversations';
import DragIndicatorIcon from '../../icons/DragIndicator';
import ModalCloseIcon from '../../icons/ModalClose';
import ModalExpandIcon from '../../icons/ModalExpand';
import ModalMinimizeIcon from '../../icons/ModalMinimize';
import cn from '../../lib/cn';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Button from '../Button';

export default function ModalHeader({ setIsMinimized, setActivatorNodeRef, ...rest }: any) {
	const dispatch = useAppDispatch();
	const { view } = useAppSelector(state => state.ai.modal);
	const { conversationId } = useAppSelector(state => state.ai.chat);
	const { isMinimized } = useContext(AiModalContext);

	return (
		<header className={cn('w-full flex justify-between text-ui-50 h-[32px] items-center')}>
			<div className={cn('flex items-center gap-[.4rem]')}>
				<div className={cn('flex items-center cursor-move')} ref={setActivatorNodeRef} {...rest}>
					<DragIndicatorIcon />
				</div>
				{view === 'conversations' ? (
					<button
						className={cn('flex items-center gap-[.8rem] group')}
						onClick={() => dispatch(setView('chat'))}
					>
						<div
							className={cn(
								'w-[3.2rem] h-[3.2rem] bg-shade-4 rounded-full flex items-center justify-center text-ui-100 group-hover:bg-shade-5 ',
								'transition-all duration-[175ms] ease-in-out'
							)}
						>
							<ArrowIcon size="24" className={cn('-rotate-180')} />
						</div>
						<div
							className={cn(
								'text-ui-75',
								'group-hover:text-ui-100 transition-all duration-[175ms] ease-in-out'
							)}
						>
							Back
						</div>
					</button>
				) : (
					<div className={cn('h-[32px] flex items-center')}>
						<div className={cn('text-ui-100 ')}>Dandi AI (Preview)</div>
					</div>
				)}
			</div>

			<div className={cn('flex items-center gap-[1.6rem] ')}>
				<Button
					large={false}
					disabled={view === 'chat' && !conversationId}
					onClick={e => {
						e.stopPropagation();
						dispatch(resetChat());
						dispatch(setView('chat'));
					}}
				>
					New chat
				</Button>
				<div
					onClick={e => {
						e.stopPropagation();
						dispatch(setView('conversations'));
					}}
					className={cn(
						'cursor-pointer flex items-center hover:text-ui-100 transition-all duration-[175ms] ease-in-out'
					)}
				>
					<ChatConversationsIcon />
				</div>

				<div className={cn('flex items-center gap-[.8rem]')}>
					{isMinimized ? (
						<div
							onClick={e => {
								e.stopPropagation();
								setIsMinimized(false);
							}}
							className={cn(
								'cursor-pointer flex items-center w-[24px] h-[24px] justify-center hover:text-ui-100',
								'transition-all duration-[175ms] ease-in-out'
							)}
						>
							<ModalExpandIcon className={cn('')} />
						</div>
					) : (
						<div
							onClick={e => {
								e.stopPropagation();
								track(MixPanel.Events.AIModalClickMinimizeModal);
								setIsMinimized(true);
							}}
							className={cn(
								'cursor-pointer flex items-center hover:text-ui-100',
								'transition-all duration-[175ms] ease-in-out'
							)}
						>
							<ModalMinimizeIcon className={cn('')} />
						</div>
					)}
					<div
						onClick={e => {
							e.stopPropagation();
							dispatch(setAiModalOpen(false));
							dispatch(resetChat());
						}}
						className={cn(
							'cursor-pointer flex items-center hover:text-ui-100 transition-all duration-[175ms] ease-in-out'
						)}
					>
						<ModalCloseIcon className={cn('w-[24px] h-[24px]')} />
					</div>
				</div>
			</div>
		</header>
	);
}
