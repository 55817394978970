import React, { ChangeEvent } from 'react';
import produce from 'immer';
import StringListEdit from './StringListEdit';

interface SupportedGraphsProps {
	supportedGraphs: string[];
	setSupportedGraphs: any;
}

export default function ExcludeWhen({ supportedGraphs, setSupportedGraphs }: SupportedGraphsProps) {
	function handleChange(supportedGraph: string, i: number) {
		setSupportedGraphs((supportedGraphs: string) =>
			produce(supportedGraphs, (draftState: any) => {
				draftState[i] = supportedGraph;
			})
		);
	}

	function handleNew() {
		setSupportedGraphs((supportedGraphs: string) =>
			produce(supportedGraphs, (draftState: any) => {
				draftState.push('');
			})
		);
	}

	function handleDelete(i: number) {
		setSupportedGraphs((index: number) =>
			produce(supportedGraphs, (draftState: any) => {
				draftState.splice(i, 1);
			})
		);
	}

	return (
		<StringListEdit
			label="Supported graphs"
			data={supportedGraphs}
			onNew={handleNew}
			onEdit={handleChange}
			onDelete={handleDelete}
		/>
	);
}
