interface Props {
	children?: any;
	style?: any;
	[key: string]: any;
}

const ContentWrapper = ({ children, style, ...rest }: Props) => {
	return (
		<div
			style={{
				margin: '1.6rem 2rem',
				...style,
			}}
			{...rest}
		>
			{children}
		</div>
	);
};

export default ContentWrapper;
