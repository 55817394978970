import axios from 'axios';
import store from '../store/store';
import { signOut } from '../layout/actions';
import getAccessToken from './getAccessToken';

axios.interceptors.request.use(
	function (options) {
		if (options.url?.includes('/sso/domain')) {
			return options;
		}
		if (options.url?.includes('storage.googleapis.com')) {
			return options;
		}
		return getAccessToken().then(accessToken => {
			if (accessToken === '') {
				store.dispatch(signOut());
				throw new axios.Cancel('Invalid access token.');
			} else {
				if (options.headers) {
					options.headers['Authorization'] = `Bearer ${accessToken}`;
				}

				let token = localStorage.getItem('rbacToken');

				const previewMode = sessionStorage.getItem('preview');

				if (previewMode) {
					token = localStorage.getItem('previewToken');
				}

				if (options.headers) {
					options.headers['X-Endpoint-RBAC-Token'] = token || '';
				}
			}
			return options;
		});
	},
	function (error) {
		return Promise.reject(error);
	}
);
