import { useReducer } from 'react';
import {
	clearActiveLabel,
	clearSelection,
	setActiveLabel,
	toggleLabel,
	toggleLockedSelection,
} from '../explore/chart/actions';
import { useAppDispatch, useAppSelector } from '../store/hooks';

interface IUseInteractiveChartProps {
	lockedSelection: boolean;
	mode: 'explore' | 'standalone' | 'dashboard-edit' | 'measure';
}

const internalStateMachine = (state: any, action: any) => {
	switch (state.status) {
		case 'idle': {
			switch (action.type) {
				case 'setActiveLabel': {
					return {
						...state,
						status: 'activated',
						hoveredItem: action.payload,
					};
				}
				default:
					return state;
			}
		}
		case 'activated': {
			switch (action.type) {
				case 'clearActiveLabel': {
					const status = state.selection.length === 0 ? 'idle' : 'activated';
					return {
						...state,
						hoveredItem: undefined,
						status,
					};
				}
				case 'setActiveLabel': {
					return {
						...state,
						status: 'activated',
						hoveredItem: action.payload,
					};
				}
				case 'toggleLabel': {
					let newSelection = [...state.selection];
					if (newSelection.includes(action.payload)) {
						newSelection = newSelection.filter(item => item !== action.payload);
					} else {
						newSelection.push(action.payload);
					}
					return {
						...state,
						selection: newSelection,
					};
				}
				case 'toggleLockedSelection': {
					return {
						...state,
						status: 'activatedLocked',
						selection: [action.payload],
						hoveredItem: action.payload,
					};
				}
				case 'clearSelection': {
					return {
						...state,
						status: 'idle',
						selection: [],
						hoveredItem: undefined,
					};
				}
				default:
					return state;
			}
		}
		case 'activatedLocked': {
			switch (action.type) {
				case 'clearSelection': {
					return {
						...state,
						status: 'idle',
						selection: [],
						hoveredItem: undefined,
					};
				}
				case 'toggleLockedSelection': {
					let newSelection = [...state.selection];
					let hoveredItem = state.hoveredItem;
					let status = 'activatedLocked';
					if (newSelection.includes(action.payload)) {
						newSelection = [];
						hoveredItem = undefined;
						status = 'idle';
					} else {
						newSelection = [action.payload];
						hoveredItem = action.payload;
					}
					return {
						...state,
						status,
						selection: newSelection,
						hoveredItem,
					};
				}
				default:
					return state;
			}
		}

		default:
			return state;
	}
};

const useInteractiveChart = ({ lockedSelection, mode }: IUseInteractiveChartProps) => {
	const appDispatch = useAppDispatch();
	const {
		hoveredItem: globalHoveredItem,
		selection: globalSelection,
		status: globalStatus,
	} = useAppSelector(state => state.explore.chart);

	const [internalState, dispatch] = useReducer(internalStateMachine, {
		status: 'idle',
		selection: [],
		hoveredItem: undefined,
	});

	function onHover(label: string) {
		switch (mode) {
			case 'explore': {
				return appDispatch(setActiveLabel(label));
			}
			default: {
				return dispatch({ type: 'setActiveLabel', payload: label });
			}
		}
	}

	function onHoverEnd() {
		switch (mode) {
			case 'explore': {
				return appDispatch(clearActiveLabel());
			}
			default: {
				return dispatch({ type: 'clearActiveLabel' });
			}
		}
	}

	function onToggle(label: string) {
		switch (mode) {
			case 'explore': {
				if (lockedSelection) {
					return appDispatch(toggleLockedSelection(label));
				}
				return appDispatch(toggleLabel(label));
			}
			default: {
				if (lockedSelection) {
					return dispatch({
						type: 'toggleLockedSelection',
						payload: label,
					});
				}
				return dispatch({ type: 'toggleLabel', payload: label });
			}
		}
	}

	function onClearSelection() {
		switch (mode) {
			case 'explore': {
				return appDispatch(clearSelection());
			}
			default: {
				return dispatch({
					type: 'clearSelection',
				});
			}
		}
	}

	return {
		onClearSelection,
		onHover,
		onHoverEnd,
		onToggle,
		hoveredItem: mode === 'explore' ? globalHoveredItem : internalState.hoveredItem,
		selection: mode === 'explore' ? globalSelection : internalState.selection,
		status: mode === 'explore' ? globalStatus : internalState.status,
	};
};

export default useInteractiveChart;
