import { Fragment, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import Button from '../../../common/Button';
import Grid from '../../../common/Grid';
import Stack from '../../../common/Stack';
import { Survey } from '../../../datastudio/survey/template/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchSurveyTemplates } from '../list/slice';

function getQuestions(survey: Survey) {
	const list: Survey['categories'][0]['questions'] = [];

	let ordering: any = survey.meta.uiElements.find(el => el.key === 'questionOrder');

	const questionsFlattened = survey.categories.reduce((questions, category) => {
		return questions.concat(category.questions);
	}, [] as Survey['categories'][0]['questions']);

	if (ordering) {
		ordering = JSON.parse(ordering.value);

		ordering.forEach((questionId: string) => {
			const question = questionsFlattened.find(question => question.globalQuestionId === questionId);
			if (question) {
				list.push(question);
			}
		});
	} else {
		questionsFlattened.forEach(question => {
			list.push(question);
		});
	}

	return list;
}

function getTimeToAnswer(numberOfQuestions: number) {
	let min = Math.ceil((numberOfQuestions * 10) / 60); // Convert seconds to minutes and round up
	let max = Math.ceil(min * 1.2); // Add 20% and round up

	return `${min} - ${max}min`;
}

const SurveyTemplate = () => {
	const { data, loaded } = useAppSelector(state => state.surveyTemplates.list);
	const dispatch = useAppDispatch();
	const params = useParams<any>();
	const history = useHistory();

	const survey = data.find((survey: Survey) => survey.globalSurveyId === params.id);

	if (!survey) {
		return null;
	}

	const questions = getQuestions(survey);
	const timeToAnswer = getTimeToAnswer(questions.length);

	useEffect(() => {
		// scroll to top on first render
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!loaded) {
			dispatch(fetchSurveyTemplates());
		}
	}, [loaded]);

	return (
		<div style={{ margin: '0 2rem' }}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '50rem auto',
					gap: '9.6rem',
				}}
			>
				<Stack gap="2.4rem">
					<Stack gap=".8rem">
						<p
							style={{
								color: 'var(--color-ui-50)',
							}}
						>
							{survey.description}
						</p>
					</Stack>
					<Grid base={2} gap="1.6rem">
						<InfoBox label="Questions" value={questions.length} />
						<InfoBox label="Time to answer" value={timeToAnswer} />
					</Grid>
					<div>
						<Button
							onClick={() =>
								history.push(`/self-id/campaigns/create?templateId=${survey.globalSurveyId}`)
							}
						>
							Use this template
						</Button>
					</div>
				</Stack>
				<Stack gap=".8rem">
					<TableGrid>
						<p
							style={{
								color: 'var(--color-ui-50)',
								marginBottom: 0,
							}}
							className="font-micro-text"
						>
							Field
						</p>
						<p
							style={{
								color: 'var(--color-ui-50)',
								marginBottom: 0,
							}}
							className="font-micro-text"
						>
							Question
						</p>
						<p
							style={{
								color: 'var(--color-ui-50)',
								marginBottom: 0,
							}}
							className="font-micro-text"
						>
							Values
						</p>
					</TableGrid>
					<Divider />
					{questions.map((question, index) => (
						<Fragment key={question.globalQuestionId}>
							<TableGrid>
								<div data-id={question.globalQuestionId}>{question.fieldName}</div>
								<div>{question.title}</div>
								<ul style={{}}>
									{question.values.map(value => (
										<li
											key={value.value}
											style={{
												listStylePosition: 'inside',
											}}
										>
											{value.value}
										</li>
									))}
								</ul>
							</TableGrid>
							{index !== questions.length - 1 && <Divider />}
						</Fragment>
					))}
				</Stack>
			</div>
		</div>
	);
};

const TableGrid = ({ children }: any) => {
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '1fr 3fr 2fr',
				gap: '1.6rem',
			}}
		>
			{children}
		</div>
	);
};

const Divider = () => {
	return (
		<div
			style={{
				background: 'var(--color-ui-10)',
				height: '1px',
				width: '100%',
			}}
		></div>
	);
};

const InfoBox = ({ label, value }: { label: string; value: string | number }) => {
	return (
		<Stack
			style={{
				padding: '1.6rem',
				color: 'var(--color-ui-50)',
				borderRadius: '.8rem',
				border: '1px solid var(--color-ui-10)',
			}}
		>
			<p className="font-micro-text">{label}</p>
			<h3 className="font-title">{value}</h3>
		</Stack>
	);
};

export default SurveyTemplate;
