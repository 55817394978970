import React from 'react';

const NoActiveGoals = () => {
	return (
		<div className="timeline__no-events" style={{ display: 'flex', flex: 1 }}>
			<div>{`You currently have no active goals. Click “Add Goal” to add another goal.`}</div>
		</div>
	);
};

export default NoActiveGoals;
