import { SVGProps } from 'react';

export interface DandiLogoProps extends SVGProps<SVGSVGElement> {
	fill?: string;
	width?: number;
	height?: number;
}

const DandiLogo = ({ fill = 'white', width = 24, height = 82, ...rest }: DandiLogoProps) => (
	<svg width={width} height={height} viewBox="0 0 24 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
		<path
			d="M12.083 23.8476C12.083 23.8476 16.6914 24.4208 16.9026 30.1078C17.0534 34.1581 14.7454 36.8658 10.8158 36.8658H0.339409V33.4868H2.02891C2.02891 33.4868 0 31.6314 0 28.5842C0 25.2052 2.36832 22.8369 4.7291 22.8369C7.43683 22.8369 9.39032 24.6018 9.79761 27.566C10.1219 29.957 10.484 33.5547 11.7586 33.5547C13.4029 33.5547 14.3608 32.4837 14.3608 30.319C14.3608 30.319 14.3834 27.5132 12.0754 26.8646V23.8476H12.083ZM7.77624 33.4868H8.78693C8.87743 33.4868 8.93023 33.3812 8.87744 33.3133C8.06285 32.2499 7.66311 29.7307 7.2709 28.2524C6.93149 26.9702 6.26021 26.2235 5.07605 26.2235C4.06537 26.2235 3.04714 27.2341 3.04714 29.0971C3.0396 31.7973 4.7291 33.4868 7.77624 33.4868Z"
			fill={fill}
		/>
		<path
			d="M14.5855 43.8878C14.4799 43.8878 14.4347 44.0236 14.5177 44.0914C15.2266 44.5968 16.8935 46.0751 16.8935 48.7904C16.8935 52.6747 14.1858 55.1562 9.79609 55.1562H0.337891V51.7772H9.80364C12.172 51.7772 13.8615 50.5327 13.8615 48.3378C13.8615 45.8036 12.172 43.8878 8.61947 43.8878H0.337891V40.5088H16.5616V43.8878H14.5855Z"
			fill={fill}
		/>
		<path
			d="M13.863 70.8072H24V74.1862H0.339409V70.8072H2.65493C2.76807 70.8072 2.80578 70.6639 2.71527 70.6036C1.93086 70.0681 0 68.454 0 65.5652C0 61.3415 3.71842 57.8418 8.44752 57.8418C13.1766 57.8418 16.895 61.3339 16.895 65.5652C16.895 68.7632 14.8209 70.1209 13.8102 70.5885C13.7046 70.6413 13.7423 70.8072 13.863 70.8072ZM8.44752 70.8072C11.4871 70.8072 13.8554 68.4389 13.8554 65.9047C13.8554 63.3704 11.4871 61.2284 8.44752 61.2284C5.40792 61.2284 3.0396 63.3704 3.0396 65.9047C3.0396 68.4389 5.40792 70.8072 8.44752 70.8072Z"
			fill={fill}
		/>
		<path
			d="M0.337891 82.0001V78.6211H16.5616V82.0001H0.337891ZM19.209 82.0001V78.6211H22.6182V82.0001H19.209Z"
			fill={fill}
		/>
		<path
			d="M23.9985 8.56696V6.45508H20.6195V8.56696C20.6195 13.1301 16.9011 16.8485 12.172 16.8485C7.44286 16.8485 3.72444 13.1301 3.72444 8.56696V6.45508H0.337891V8.56696C0.337891 15.159 5.74581 20.567 12.172 20.567C18.5906 20.567 23.9985 15.159 23.9985 8.56696Z"
			fill={fill}
		/>
		<path d="M18.7588 0.285156H15.0781V4.00357H18.7588V0.285156Z" fill={fill} />
		<path d="M9.47367 0.285156H5.79297V4.00357H9.47367V0.285156Z" fill={fill} />
	</svg>
);

export default DandiLogo;
