import * as React from 'react';
import { SVGProps } from 'react';
import cn from '../lib/cn';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={35} height={36} viewBox="0 0 35 36" fill="none" {...props}>
		<rect width={35} height={35} y={0.5} fill="currentColor" className={cn('text-graph-4')} rx={17.5} />
		<path
			fill="currentColor"
			className={cn('text-ui-100-inverted')}
			d="M9.38 20.892H8.5L11.045 14h.866l2.544 6.892h-.88l-2.07-5.802h-.054L9.38 20.892Zm.325-2.692h3.546v.74H9.705v-.74ZM15.535 20.892V14h.798v2.544h.068c.059-.09.14-.204.244-.343.106-.141.257-.267.453-.377.199-.112.467-.168.806-.168.437 0 .823.109 1.157.326.334.218.594.526.781.926.188.4.281.87.281 1.413 0 .548-.093 1.022-.28 1.424-.188.4-.447.709-.779.929a2.043 2.043 0 0 1-1.147.326c-.334 0-.601-.055-.802-.165a1.485 1.485 0 0 1-.463-.38 5.08 5.08 0 0 1-.25-.357h-.096v.794h-.771Zm.785-2.584c0 .39.057.735.172 1.033.116.296.284.528.505.697.22.165.491.248.812.248.334 0 .612-.087.835-.262.226-.177.395-.415.508-.713.115-.301.173-.635.173-1.003 0-.364-.057-.691-.17-.983a1.52 1.52 0 0 0-.504-.697c-.223-.172-.504-.259-.842-.259-.325 0-.598.082-.82.246a1.474 1.474 0 0 0-.5.68 2.78 2.78 0 0 0-.17 1.013ZM23.456 21c-.487 0-.907-.114-1.258-.343a2.24 2.24 0 0 1-.813-.946 3.19 3.19 0 0 1-.284-1.376c0-.525.097-.989.291-1.39.196-.404.47-.72.819-.946a2.209 2.209 0 0 1 1.232-.343c.365 0 .694.067.988.202.293.134.533.323.72.565.188.242.304.525.349.848h-.799a1.23 1.23 0 0 0-.406-.626c-.207-.184-.487-.276-.839-.276-.311 0-.584.081-.819.242a1.58 1.58 0 0 0-.544.677c-.129.29-.193.63-.193 1.02 0 .4.063.747.19 1.043.128.296.308.526.54.69.235.164.51.245.826.245.208 0 .396-.035.565-.107a1.164 1.164 0 0 0 .68-.794h.799a1.834 1.834 0 0 1-1.035 1.403 2.268 2.268 0 0 1-1.009.212Z"
		/>
	</svg>
);
export default SvgComponent;
