import moment from 'moment';
import React from 'react';
import Button from '../../../common/Button';
import Checkbox from '../../../common/Checkbox';
import OpenText from '../../../common/OpenText';
import TextArea from '../../../common/TextArea';
import ButtonTypes from '../../../constants/ButtonTypes';
import { TabProps } from '../types';
import ColorPicker from './ColorPicker';

interface EventDetailsProps extends TabProps {
	dateRange: string[];
}

const EventDetails = ({
	handleSave,
	handleCancel,
	handleClose,
	name,
	setName,
	description,
	setDescription,
	color,
	setColor,
	dateRange,
	setDateRange,
	open,
}: EventDetailsProps) => {
	const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
	const [inputStartDate, setInputStartDate] = React.useState(
		dateRange[0] ? moment(dateRange[0]).format('MM/DD/YYYY') : undefined
	);
	const [inputEndDate, setInputEndDate] = React.useState(
		dateRange[1] && dateRange[1] !== dateRange[0] ? moment(dateRange[1]).format('MM/DD/YYYY') : undefined
	);
	const [showEndDate, setShowEndDate] = React.useState(dateRange[1] && dateRange[1] !== dateRange[0] ? true : false);

	React.useEffect(() => {
		if (inputStartDate && inputEndDate && isValidDate(inputStartDate) && isValidDate(inputEndDate)) {
			setDateRange?.([
				moment(inputStartDate, 'MM/DD/YYYY').format(),
				moment(inputEndDate, 'MM/DD/YYYY').format(),
			]);
		} else if (inputStartDate && isValidDate(inputStartDate) && !showEndDate) {
			setDateRange?.([
				moment(inputStartDate, 'MM/DD/YYYY').format(),
				moment(inputStartDate, 'MM/DD/YYYY').format(),
			]);
			setInputEndDate(inputStartDate);
		}
	}, [inputStartDate, inputEndDate, showEndDate]);

	React.useEffect(() => {
		if (!showEndDate) {
			setInputEndDate(dateRange[0]);
		}
	}, [showEndDate]);

	React.useEffect(() => {
		if (showEndDate) return;

		setInputEndDate(inputStartDate);
	}, [inputStartDate]);

	const calculateNewValue = (newValue: string, oldValue: string) => {
		if (!/^\d{0,2}\/*\d{0,2}\/*\d{0,4}$/.test(newValue)) return oldValue;

		const characters = newValue.length;
		if (oldValue && oldValue.length > characters) {
			if (oldValue.charAt(oldValue.length - 1) === '/') {
				newValue = newValue.slice(0, -1);
			}

			return newValue;
		}

		if (
			(characters === 2 || characters === 5) &&
			newValue.charAt(newValue.length - 1) !== '/' &&
			countCharacters(newValue, '/') < 2
		) {
			newValue = newValue + '/';
		}

		return newValue;
	};

	return (
		<div className="add-event-modal" data-test="add-event-modal">
			<form onSubmit={handleSave}>
				<div className="modal__content add-event-modal__content">
					<div className="add-event-modal__content__section-title" style={{ marginTop: 0 }}>
						Event name
					</div>
					<OpenText value={name} onChange={setName} placeholder="Event name" autoFocus />
					<div className="add-event-modal__content__section-title">Description</div>
					<TextArea
						// @ts-ignore
						value={description}
						onChange={e => {
							setDescription?.(e.target.value);
						}}
						placeholder="Description"
					/>
					<div className="add-event-modal__content__section-title">Date or range</div>
					<div className="add-event-modal__content__dates">
						<OpenText
							value={inputStartDate}
							onChange={(newDate: any) => {
								setInputStartDate(calculateNewValue(newDate, inputStartDate ?? ''));
							}}
							placeholder="MM/DD/YYYY"
							style={{ flex: 1, maxWidth: '30%', marginRight: 8 }}
						/>
						{showEndDate ? (
							<>
								<span>to</span>
								<OpenText
									value={inputEndDate}
									onChange={(newDate: any) => {
										setInputEndDate(calculateNewValue(newDate, inputEndDate ?? ''));
									}}
									placeholder="MM/DD/YYYY"
									style={{
										flex: 1,
										maxWidth: '30%',
										marginLeft: 8,
									}}
								/>
							</>
						) : null}
						<Checkbox checked={showEndDate} onClick={() => setShowEndDate(!showEndDate)}>
							<span>Add end date</span>
						</Checkbox>
					</div>
					{/* Color picker we're removing for now. Uncomment to add the functionality */}
					{/* <div className="add-event-modal__content__section-title">
                        Choose color
                    </div>
                    <ColorPicker
                        open={colorPickerOpen}
                        setOpen={setColorPickerOpen}
                        selectedColor={color}
                        setSelectedColor={setColor}
                    /> */}
				</div>
				<div className="add-event-modal__footer modal__footer">
					<Button classes={['mr8']} onClick={handleCancel} componentType={ButtonTypes.type.TERTIARY}>
						Cancel
					</Button>
					<Button
						onClick={e => {
							handleSave(e);
						}}
						disabled={!name || dateRange[0] === ''}
						type="submit"
					>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
};

function countCharacters(str: string, character: any) {
	var letterCount = 0;
	for (var position = 0; position < str.length; position++) {
		if (str.charAt(position) == character) {
			letterCount += 1;
		}
	}
	return letterCount;
}

function isValidDate(dateString: any) {
	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

	var parts = dateString.split('/');
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

	return day > 0 && day <= monthLength[month - 1];
}

export default EventDetails;
