function objectifyArray(
	array: {
		name: string;
		value: string;
	}[]
): { [key: string]: string } {
	return array.reduce((obj: any, item) => {
		obj[item.name] = item.value;
		return obj;
	}, {});
}

export default objectifyArray;
