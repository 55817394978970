import React from 'react';
import decimalFormatter from '../../../lib/decimalFormatter';
import TrendIcon from '../../icons/TrendIcon';
import { Trend } from '../../types';
import Divider from './Divider';

interface MeasureCardProps extends React.HTMLAttributes<HTMLDivElement> {
	name: string;
	description?: string;
	unit?: string;
	goal?: number;
	status: number;
	change: number;
	color: string;
	icon: React.ReactNode;
	colorGoal?: boolean;
	highlighted?: boolean;
	menu?: React.ReactNode;
	goalInfo: string;
}

const MeasureCard = ({
	name,
	description,
	goalInfo,
	goal,
	status,
	change,
	color,
	icon,
	colorGoal,
	unit = '',
	highlighted = true,
	menu,
	...rest
}: MeasureCardProps) => {
	return (
		<div className="measure-card" style={{ opacity: highlighted ? 1 : 1 }} {...rest}>
			<div className="measure-card__header">
				<span className="text-reveal long-text">{name}</span>
				{menu}
			</div>
			<div className="measure-card__overview">
				<div className="description long-text">
					{description && (
						<>
							<Divider />
							<span className="text-reveal long-text">{description}</span>
						</>
					)}
					<Divider />
					<span className="text-reveal">{goalInfo}</span>
				</div>
				<div className="icon">{icon}</div>
			</div>
			<Divider />
			<div className="measure-card__metrics">
				{goal !== undefined && (
					<div style={{ color: colorGoal ? color : '' }}>
						<span className="text-reveal">Goal</span>
						<span className="text-reveal">
							{unit === ('%' || 'x') ? decimalFormatter(goal) : goal}
							{unit}
						</span>
					</div>
				)}
				<div>
					<span className="text-reveal">Current status</span>
					<span className="text-reveal">
						{unit === ('%' || 'x') ? decimalFormatter(status) : status}
						{unit}
					</span>
				</div>
				<div>
					<span className="text-reveal">Progress from last run</span>
					<span style={{ color }} className="text-reveal">
						{change > 0 ? '+' : ''}
						{unit === ('%' || 'x') ? decimalFormatter(change) : change}
						{unit}
					</span>
				</div>
			</div>
		</div>
	);
};

export default MeasureCard;
