import { ActionTypes, State } from './types';

export const initialState: State = {
	status: 'idle',
	segmentDropdownOpen: false,
	valuesDropdownOpen: false,
	segment: '',
	values: [],
	allValuesSelected: false,
};

export const stateMachine = (state: any, action: any) => {
	switch (state.status) {
		case 'idle':
			switch (action.type) {
				case ActionTypes.SELECT_SEGMENT:
					return {
						...state,
						status: 'segmentSelected',
						segment: action.payload,
						valuesDropdownOpen: true,
						segmentDropdownOpen: false,
						allValuesSelected: false,
						values: [],
					};
				case ActionTypes.TOGGLE_SEGMENT_DROPDOWN:
					return {
						...state,
						segmentDropdownOpen: !state.segmentDropdownOpen,
						valuesDropdownOpen: !state.segmentDropdownOpen && false,
					};

				case ActionTypes.SET_SEGMENT_DROPDOWN_OPEN:
					return {
						...state,
						segmentDropdownOpen: action.payload,
						valuesDropdownOpen: action.payload && false,
					};

				case ActionTypes.CLOSE_ALL:
					return {
						...state,
						segmentDropdownOpen: false,
						valuesDropdownOpen: false,
					};
				case ActionTypes.SET_STATE:
					return {
						...state,
						...action.payload,
					};
				case ActionTypes.RESET:
					return {
						...state,
						...initialState,
					};
				default:
					return state;
			}
		case 'segmentSelected':
			switch (action.type) {
				case ActionTypes.SET_STATE:
					return {
						...state,
						...action.payload,
					};
				case ActionTypes.SELECT_SEGMENT:
					return {
						...state,
						status: 'segmentSelected',
						segmentDropdownOpen: false,
						valuesDropdownOpen: true,
						allValuesSelected: false,
						segment: action.payload,
						values: [],
					};
				case ActionTypes.TOGGLE_SEGMENT_DROPDOWN:
					return {
						...state,
						segmentDropdownOpen: !state.segmentDropdownOpen,
						valuesDropdownOpen: !state.segmentDropdownOpen && false,
					};
				case ActionTypes.SET_SEGMENT_DROPDOWN_OPEN:
					return {
						...state,
						segmentDropdownOpen: action.payload,
						valuesDropdownOpen: action.payload && false,
					};
				case ActionTypes.TOGGLE_ALL_VALUES: {
					const newValues = state.options.find((o: any) => o.name === state.segment)?.values;

					return {
						...state,
						values: newValues,
						status: 'valuesSelected',
						allValuesSelected: true,
					};
				}

				case ActionTypes.TOGGLE_VALUE: {
					const shouldRemove = state.values.includes(action.payload);
					const newValues = shouldRemove
						? state.values.filter((v: any) => v !== action.payload)
						: [...state.values, action.payload];
					const allValuesSelected =
						newValues.length === state.options.find((o: any) => o.name === state.segment)?.values.length;
					return {
						...state,
						values: newValues,
						status: 'valuesSelected',
						allValuesSelected,
					};
				}
				case ActionTypes.SELECT_VALUE:
					return {
						...state,
						status: 'valuesSelected',
						allValuesSelected: false,
						values: [action.payload],
					};
				case ActionTypes.TOGGLE_VALUES_DROPDOWN:
					return {
						...state,
						valuesDropdownOpen: !state.valuesDropdownOpen,
						segmentDropdownOpen: !state.valuesDropdownOpen && false,
					};
				case ActionTypes.SET_VALUES_DROPDOWN_OPEN:
					return {
						...state,
						valuesDropdownOpen: action.payload,
						segmentDropdownOpen: action.payload && false,
					};
				case ActionTypes.CLOSE_ALL:
					return {
						...state,
						segmentDropdownOpen: false,
						valuesDropdownOpen: false,
					};
				case ActionTypes.RESET: {
					return {
						...state,
						...initialState,
					};
				}
				default: {
					return state;
				}
			}
		case 'valuesSelected':
			switch (action.type) {
				case ActionTypes.SET_STATE:
					return {
						...state,
						...action.payload,
					};
				case ActionTypes.SELECT_SEGMENT:
					return {
						...state,
						status: 'segmentSelected',
						segment: action.payload,
						valuesDropdownOpen: true,
						segmentDropdownOpen: false,
						values: [],
						allValuesSelected: false,
					};
				case ActionTypes.TOGGLE_SEGMENT_DROPDOWN:
					return {
						...state,
						segmentDropdownOpen: !state.segmentDropdownOpen,
						valuesDropdownOpen: !state.segmentDropdownOpen && false,
					};
				case ActionTypes.SET_SEGMENT_DROPDOWN_OPEN:
					return {
						...state,
						segmentDropdownOpen: action.payload,
						valuesDropdownOpen: action.payload && false,
					};
				case ActionTypes.TOGGLE_VALUES_DROPDOWN:
					return {
						...state,
						valuesDropdownOpen: !state.valuesDropdownOpen,
						segmentDropdownOpen: !state.valuesDropdownOpen && false,
					};

				case ActionTypes.SET_VALUES_DROPDOWN_OPEN:
					return {
						...state,
						valuesDropdownOpen: action.payload,
						segmentDropdownOpen: action.payload && false,
					};

				case ActionTypes.TOGGLE_ALL_VALUES:
					const newValues = state.allValuesSelected
						? []
						: state.options.find((o: any) => o.name === state.segment)?.values;

					return {
						...state,
						values: newValues,
						status: state.allValuesSelected ? 'segmentSelected' : 'valuesSelected',
						allValuesSelected: !state.allValuesSelected,
					};

				case ActionTypes.TOGGLE_VALUE: {
					const shouldRemove = state.values.includes(action.payload);
					const newValues = shouldRemove
						? state.values.filter((v: any) => v !== action.payload)
						: [...state.values, action.payload];
					const allValuesSelected =
						newValues.length === state.options.find((o: any) => o.name === state.segment)?.values.length;
					return {
						...state,
						values: newValues,
						status: 'valuesSelected',
						allValuesSelected,
					};
				}
				case ActionTypes.SELECT_VALUE:
					return {
						...state,
						status: 'valuesSelected',
						allValuesSelected: false,
						values: [action.payload],
					};
				case ActionTypes.CLOSE_ALL:
					return {
						...state,
						segmentDropdownOpen: false,
						valuesDropdownOpen: false,
					};
				case ActionTypes.RESET:
					return {
						...state,
						...initialState,
					};
				default: {
					return state;
				}
			}
		default:
			return state;
	}
};
