const SvgComponent = (props: any) => (
	<svg
		width={props.size || 20}
		height={props.size || 19}
		viewBox="0 0 20 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m14.95 9.5-.707.707L10 14.45l-.707-.707L13.036 10H4.5V9h8.536L9.293 5.257 10 4.55l4.243 4.243.707.707Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgComponent;
