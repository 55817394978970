const benchmarkColors = [
	'var(--color-benchmark-1)',
	'var(--color-benchmark-2)',
	'var(--color-benchmark-3)',
	'var(--color-benchmark-4)',
	'var(--color-benchmark-5)',
	'var(--color-benchmark-6)',
	'var(--color-benchmark-7)',
	'var(--color-benchmark-8)',
];

export default benchmarkColors;
