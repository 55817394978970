import { ISubAnalysisMapping } from '../../types';
import { generateFunnelTemplate, generateDefaultTemplate } from './transformations';
import { ITableData, IUseTableDataProps } from './types';

const defaultProps = {
	subAnalysisFields: [],
};

const useTableData = (props: IUseTableDataProps & typeof defaultProps): ITableData => {
	const { data, template, subAnalysisFields, subAnalysisMapping, hoveredItem, selection } = props;

	switch (template) {
		case 'funnel': {
			return generateFunnelTemplate(
				data,
				subAnalysisFields,
				subAnalysisMapping as ISubAnalysisMapping,
				hoveredItem as string,
				selection
			);
		}
		default: {
			return generateDefaultTemplate(data, selection, hoveredItem);
		}
	}
};

useTableData.defaultProps = defaultProps;

export default useTableData;
