import moment from 'moment';

import { Segment } from '../../types';

export function getDateByInterval(date: string | Date, interval: string) {
	// Note: adding 2 days to avoid issue with Javascript Date objects always converting to local time.
	// This gives enough margin.
	switch (interval) {
		case 'Month':
			return moment(date).add(2, 'days').format('MMMM YYYY');
		case 'Quarter':
			return moment(date).add(2, 'days').format('YYYY [Q]Q');
		case 'Year':
			return moment(date).add(2, 'days').format('YYYY');
		case 'Day':
			return moment(date).format('YYYY-MM-DD');
		default: {
			return date;
		}
	}
}

export function getHeaderRow(
	baseHeader: string[] = [],
	mainSegments: Segment[] = [],
	columnProps: any[] = [],
	additionalHeaders: string[] = []
) {
	const header = [...baseHeader];

	if (mainSegments.length > 0) {
		header.push(mainSegments.map((s: any) => s.name).join(' & ') || '');
	}
	columnProps?.forEach((c: any) => {
		header.push(c.header);
	});

	additionalHeaders?.forEach((c: any) => {
		header.push(c);
	});

	return header;
}
