import { units } from '../types';
import precisionRound from './precisionRound';

export default function getValueWithUnit(value: number | string, unit: units, verbose = false) {
	if (!value) return '';
	let typeOfValue = typeof value;
	switch (typeOfValue) {
		case 'string':
			return value + unit;
		case 'number':
			const newValue = precisionRound(value as number, 1);
			if (verbose) {
				return (newValue > 0 ? '+' : '') + newValue + unit;
			}
			return newValue + unit;
		default:
			return '';
	}
}
