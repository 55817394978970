export default function HelperGrid({ isDragging }: { isDragging: boolean }) {
	return (
		<div
			className={`absolute top-[-20px] left-0 h-[20px] w-avail mx-[10px] grid grid-cols-12 gap-[20px] pointer-events-none ${
				isDragging ? 'visible opacity-100' : 'invisible opacity-0'
			} transition-all duration-200`}
		>
			{Array.from({ length: 12 }).map((_, index) => (
				<div key={index} className={`relative ${index > 1 && index < 9 ? 'visible' : 'invisible'}`}>
					<div className="absolute top-1/2 right-[-13px] -translate-y-1/2 w-[6px] h-[6px] bg-ui-10 rounded-full" />
				</div>
			))}
		</div>
	);
}
