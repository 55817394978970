const SvgComponent = (props: any) => (
	<svg
		width={props.width || 28}
		height={props.height || 16}
		viewBox="0 0 28 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.93078 16C5.89999 16 4.12979 15.3369 2.62017 14.0106C1.11056 12.6843 0.237167 11.0141 0 9H1.01153C1.28332 10.7205 2.06698 12.1506 3.3625 13.2904C4.65802 14.4301 6.18078 15 7.93078 15C9.88078 15 11.5349 14.3208 12.8933 12.9625C14.2516 11.6042 14.9308 9.95 14.9308 8C14.9308 6.05 14.2516 4.39583 12.8933 3.0375C11.5349 1.67917 9.88078 1 7.93078 1C6.89616 1 5.92372 1.21859 5.01345 1.65578C4.1032 2.09296 3.3 2.69488 2.60385 3.46155H5.0846V4.46155H0.93075V0.3077H1.93075V2.69615C2.70383 1.84743 3.60992 1.18589 4.64902 0.711525C5.68812 0.237175 6.78204 0 7.93078 0C9.03974 0 10.0792 0.208651 11.049 0.625951C12.0189 1.04327 12.8664 1.61443 13.5913 2.33943C14.3163 3.06443 14.8875 3.91186 15.3048 4.88172C15.7221 5.85159 15.9308 6.89102 15.9308 8C15.9308 9.10898 15.7221 10.1484 15.3048 11.1183C14.8875 12.0881 14.3163 12.9356 13.5913 13.6606C12.8664 14.3856 12.0189 14.9567 11.049 15.3741C10.0792 15.7914 9.03974 16 7.93078 16ZM11.1346 11.8539L7.48845 8.2077V3H8.48845V7.7923L11.8423 11.1462L11.1346 11.8539Z"
			fill={props.fill}
			fill-opacity="0.5"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.9567 9C18.9864 8.67064 19.0016 8.33709 19.0016 8H28.0001V9H18.9567ZM18.2517 4C18.1182 3.65801 17.968 3.32435 17.8022 3H28.0001V4H18.2517ZM17.2227 14C17.4326 13.678 17.6262 13.3442 17.8022 13H28.0001V14H17.2227Z"
			fill={props.fill}
			fill-opacity="0.5"
		/>
	</svg>
);

export default SvgComponent;
