import GridConstants from '../constants/GridConstants';

export function getRow(report: any) {
	return parseInt(report.configuration.find((c: any) => c.name === 'row').value);
}

export function getColumn(report: any) {
	return parseInt(report.configuration.find((c: any) => c.name === 'column').value);
}

export function getPositionFromRC(row: any, column: any) {
	return (row - 1) * GridConstants.ColumnCount + column;
}

export function getPosition(report: any) {
	return getPositionFromRC(getRow(report), getColumn(report));
}

export function getLength(report: any) {
	return parseInt(report.configuration.find((c: any) => c.name === 'length').value);
}

export function setPosition(report: any, position: any) {
	const row = Math.floor((position - 1) / GridConstants.ColumnCount) + 1;
	const column = ((position - 1) % GridConstants.ColumnCount) + 1;
	setRow(report, row);
	setColumn(report, column);
}

export function setRow(report: any, row: any) {
	const rowConfig = report.configuration.find((c: any) => c.name === 'row');
	rowConfig.value = row.toString();
}

export function setColumn(report: any, column: any) {
	const columnConfig = report.configuration.find((c: any) => c.name === 'column');
	columnConfig.value = column.toString();
}

export function setLength(report: any, length: number) {
	const lengthConfig = report.configuration.find((c: any) => c.name === 'length');
	lengthConfig.value = length.toString();
}

export function getReportPosition(reports: any, length: number) {
	let reportRow = 1;
	let reportColumn = 1;

	if (reports.length) {
		const lastReport = reports[reports.length - 1];

		let lastReportRow = lastReport.configuration.find((c: any) => c.name === 'row')?.value;

		lastReportRow = lastReportRow ? parseInt(lastReportRow) : 0;

		let lastReportColumn = lastReport.configuration.find((c: any) => c.name === 'column')?.value;
		lastReportColumn = lastReportColumn ? parseInt(lastReportColumn) : 0;
		const lastReportLength = parseInt(lastReport.configuration.find((c: any) => c.name === 'length').value);
		reportRow = lastReportRow;
		reportColumn = lastReportColumn + lastReportLength;
		if (lastReportColumn + lastReportLength + length > 7) {
			reportRow += 1;
			reportColumn = 1;
		}
	}

	return {
		reportRow,
		reportColumn,
	};
}
