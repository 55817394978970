import React, { useEffect, useRef, useState } from 'react';
import cn from '../lib/cn';
import lottie from 'lottie-web';
import Button, { ButtonProps } from './ButtonV2';
import gradientAnimation from '../lottie/DA_AIGradient_Base_Square_v02_Lottie.json';

interface GradientButtonProps extends ButtonProps {
	children?: any;
}

const GradientButton = React.forwardRef(({ children, ...rest }: GradientButtonProps, ref?: any) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Button {...rest} ref={ref} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<GradientLayer paused={false} isHovered={isHovered} />
			<div className="relative z-10">{children}</div>
		</Button>
	);
});

export const GradientLayer = ({ autoplay = true, startingTime = 0, paused = false, isHovered, className }: any) => {
	const ref = useRef<any>(null);
	const playerRef = useRef<any>(null);

	useEffect(() => {
		if (ref.current && !playerRef.current) {
			playerRef.current = lottie.loadAnimation({
				container: ref.current,
				renderer: 'svg',
				autoplay,
				loop: true,
				animationData: gradientAnimation,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMid meet',
					viewBoxOnly: true,
				},
			});

			playerRef.current.addEventListener('DOMLoaded', () => {
				playerRef.current.setSpeed(0.75);

				ref.current.querySelector('svg').style = 'width: 100%; min-height: 100%;';

				const start = startingTime || Math.floor(Math.random() * 10);
				if (autoplay) {
					playerRef.current.goToAndPlay(start, true);
				} else {
					playerRef.current.goToAndStop(start, true);
				}
			});

			return () => {
				playerRef.current?.destroy();
				playerRef.current = null;
			};
		}
	}, [autoplay, startingTime]);

	useEffect(() => {
		if (playerRef.current) {
			playerRef.current.setSpeed(isHovered ? 1.4 : 0.75);
			if (paused) {
				playerRef.current.pause();
			} else {
				playerRef.current.play();
			}
		}
	}, [paused, isHovered]);

	return (
		<div
			className={cn(
				'absolute inset-0 w-[110%] h-[110%] flex items-center justify-center pointer-events-none',
				className
			)}
			ref={ref}
		></div>
	);
};

GradientButton.displayName = 'GradientButton';
export default GradientButton;
