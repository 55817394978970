import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import RadioButton from './RadioButton';

function ThemeCard({ lightMode, cardType, onClick }: any) {
	const theme = lightMode ? 'light-mode' : 'dark-mode';
	const isInverted = cardType !== theme;

	function getColorMode() {
		if (isInverted) {
			return lightMode ? 'cd' : 'cl';
		} else {
			return lightMode ? 'cl' : 'cd';
		}
	}

	return (
		<div
			className={classNames('theme-card', {
				'theme-card--inverted': isInverted,
			})}
			style={{
				'--bg': `var(--${getColorMode()}-color-shade-h3)`,
				'--c': `var(--${getColorMode()}-color-ui-100)`,
			}}
			onClick={onClick}
		>
			<div className="theme-card__top">
				<div className="theme-card__mode-title">{cardType === 'light-mode' ? 'Light' : 'Dark'}</div>
				<div>
					<RadioButton
						checked={theme === cardType}
						style={{
							'--c-50': `var(--${getColorMode()}-color-ui-50)`,
							'--c-75': `var(--${getColorMode()}-color-ui-75)`,
							'--c-100': `var(--${getColorMode()}-color-ui-100)`,
						}}
					/>
				</div>
			</div>
			<div className="theme-card__bottom">
				<div className="theme-card__graphic">
					<svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect
							x="18.439"
							y="10.5366"
							width="6.58537"
							height="16.4634"
							fill={`var(--${getColorMode()}-color-graph-4)`}
						/>
						<rect
							x="25.0244"
							y="20.4146"
							width="5.92683"
							height="6.58537"
							fill={`var(--${getColorMode()}-color-graph-5)`}
						/>
						<rect
							x="30.9512"
							y="6.58545"
							width="5.92683"
							height="20.4146"
							fill={`var(--${getColorMode()}-color-graph-6)`}
						/>
						<rect
							x="12.5122"
							y="5.92676"
							width="5.92683"
							height="21.0732"
							fill={`var(--${getColorMode()}-color-graph-3)`}
						/>
						<rect x="5.92676" width="6.58537" height="27" fill={`var(--${getColorMode()}-color-graph-2)`} />
						<rect
							y="11.8535"
							width="5.92683"
							height="15.1463"
							fill={`var(--${getColorMode()}-color-graph-1)`}
						/>
					</svg>
				</div>
				<div className="theme-card__graphic">
					<svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<ellipse
							cx="15.4819"
							cy="14.5"
							rx="15.0241"
							ry="14.5"
							fill={`var(--${getColorMode()}-color-graph-6)`}
						/>
						<path
							d="M15.4819 0C18.0924 3.00446e-08 20.6579 0.656487 22.9255 1.90477C25.1932 3.15304 27.0846 4.95003 28.4136 7.11863C29.7425 9.28723 30.463 11.7526 30.5041 14.2718C30.5452 16.7909 29.9054 19.277 28.6479 21.4849C27.3903 23.6928 25.5584 25.5463 23.3326 26.8629C21.1068 28.1794 18.564 28.9135 15.9547 28.9928C13.3455 29.0721 10.7598 28.4939 8.45264 27.3151C6.14543 26.1363 4.19628 24.3977 2.79729 22.2705L15.4819 14.5L15.4819 0Z"
							fill={`var(--${getColorMode()}-color-graph-5)`}
						/>
						<path
							d="M15.4819 0C17.9526 2.84353e-08 20.3852 0.588077 22.5642 1.71214C24.7432 2.8362 26.6013 4.46156 27.9739 6.44423C29.3466 8.4269 30.1914 10.7057 30.4336 13.0787C30.6758 15.4518 30.3078 17.8459 29.3623 20.0489C28.4168 22.2519 26.9229 24.1959 25.013 25.7086C23.1032 27.2214 20.8362 28.2562 18.4129 28.7214C15.9897 29.1866 13.4849 29.0678 11.1206 28.3756C8.75626 27.6834 6.60529 26.4392 4.85822 24.753L15.4819 14.5L15.4819 0Z"
							fill={`var(--${getColorMode()}-color-graph-4)`}
						/>
						<path
							d="M15.4819 0C17.6776 2.52708e-08 19.8467 0.464507 21.8364 1.36082C23.8261 2.25713 25.588 3.56347 26.9982 5.18786C28.4083 6.81225 29.4324 8.71521 29.9984 10.7628C30.5643 12.8103 30.6583 14.9528 30.2738 17.0392C29.8893 19.1256 29.0356 21.1053 27.7728 22.839C26.51 24.5726 24.8688 26.0181 22.9648 27.0735C21.0608 28.129 18.9402 28.7689 16.7523 28.9481C14.5644 29.1273 12.3624 28.8414 10.3013 28.1107L15.4819 14.5L15.4819 0Z"
							fill={`var(--${getColorMode()}-color-graph-3)`}
						/>
						<path
							d="M15.4819 0C17.728 2.58509e-08 19.9456 0.486073 21.9714 1.42245C23.9973 2.35883 25.7798 3.72168 27.1878 5.41072C28.5958 7.09975 29.5935 9.07199 30.1074 11.1823C30.6213 13.2926 30.6385 15.4873 30.1575 17.6048L15.4819 14.5L15.4819 0Z"
							fill={`var(--${getColorMode()}-color-graph-2)`}
						/>
						<path
							d="M15.4819 0C18.6676 3.66638e-08 21.7708 0.977294 24.3433 2.79073C26.9159 4.60416 28.8246 7.15985 29.7938 10.0887L15.4819 14.5L15.4819 0Z"
							fill={`var(--${getColorMode()}-color-graph-1)`}
						/>
						<ellipse
							cx="15.4828"
							cy="14.5"
							rx="10.5742"
							ry="10.9564"
							transform="rotate(-90 15.4828 14.5)"
							fill={`var(--${getColorMode()}-color-shade-h3)`}
						/>
					</svg>
				</div>
				<div className="theme-card__graphic">
					<svg width="40" height="27" viewBox="0 0 40 27" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1 26L8.10587 16.4632C11.2686 12.2184 17.1227 11.0409 21.6807 13.7325V13.7325C24.5961 15.4541 28.1688 15.6424 31.249 14.2369L39 10.7"
							stroke={`var(--${getColorMode()}-color-graph-1)`}
						/>
						<path
							d="M1 18.8242V18.8242C8.6132 20.733 16.61 17.7012 21.0442 11.2249L21.6489 10.3418C24.6514 5.95646 29.2541 2.92354 34.4682 1.89443L39 1"
							stroke={`var(--${getColorMode()}-color-graph-2)`}
						/>
					</svg>
				</div>
			</div>
		</div>
	);
}

ThemeCard.propTypes = {
	lightMode: propTypes.bool,
	onClick: propTypes.func,
	cardType: propTypes.string,
};

ThemeCard.defaultProps = {
	lightMode: false,
	onClick: () => {},
	label: '',
	cardType: 'dark-mode',
};

export default ThemeCard;
