import * as Tabs from '@radix-ui/react-tabs';
import StepIndicator from './StepIndicator';
import Stack from '../../../../../common/Stack';
import Divider from './Divider';

const Step = ({
	selectedIndex,
	index,
	label,
	validated,
	isLast,
	disabled,
}: {
	selectedIndex: number;
	label: string;
	validated: boolean;
	index: number;
	isLast: boolean;
	disabled: boolean;
}) => {
	const currentColor = selectedIndex === index ? 'var(--color-shade-h5)' : 'var(--color-shade-h4)';
	const nextColor = selectedIndex === index + 1 ? 'var(--color-shade-h5)' : 'var(--color-shade-h4)';
	const seeminglyDisabled = !validated && selectedIndex !== index;
	return (
		<Tabs.Trigger value={`tab${index}`} asChild disabled={disabled}>
			<Stack
				className="campaigns__tabs-step"
				flexDirection="row"
				alignItems="center"
				gap=".8rem"
				style={{
					cursor: disabled ? 'not-allowed' : 'pointer',
					height: '3.5rem',
					paddingLeft: '.8rem',
					paddingRight: isLast ? '1.6rem' : '0',
					background: currentColor,
				}}
			>
				<StepIndicator validated={validated} number={index + 1} seeminglyDisabled={seeminglyDisabled} />
				<span
					style={{
						opacity: disabled ? 0.5 : 1,
					}}
				>
					{label}
				</span>
				{!isLast && <Divider currentColor={currentColor} nextColor={nextColor} />}
			</Stack>
		</Tabs.Trigger>
	);
};

export default Step;
