import classNames from 'classnames';
import { useState } from 'react';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import hasHiddenEmployeeCount from '../../lib/hasHiddenEmployeeCount';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ChartEmptyState from './ChartEmptyState';
import ChartPicker from './ChartPicker';
import { showDeleteReportModal } from '../../dashboard/view/actions';
import { DashboardReport, Report } from '../../dashboard/models';

const rowHeights = [44, 40, 36, 28];
const LEGEND_WHITESPACE = 52;
const LABEL_WHITESPACE = 16;
const COLUMN_GAP = 16;
const BENCHMARK_BOTTOM_PADDING = 40;
const BENCHMARK_TOP_PADDING = 16;

interface ChartProps {
	dashboard: any;
	report: Report;
	dashboardReport: DashboardReport;
	isLocked?: boolean;
}

const defaultProps = {
	data: [],
};

export default function Chart({ dashboard, report, dashboardReport, isLocked }: ChartProps & typeof defaultProps) {
	const dispatch = useAppDispatch();

	const textChartType = dashboardReport?.configuration?.find((c: any) => c.name === 'chartType')?.value;
	const { dashboardName } = dashboard;

	const {
		chartType,
		generated,
		generateError,
		generateNoData,
		generatePending,
		generateInvalidVariation,
		generateNotAllowed,
	} = report;

	const { reportId, name: title } = dashboardReport;

	const isTextChart = textChartType === ChartTypes.Text;
	const isInsightsChart = textChartType === ChartTypes.InsightsText;

	const [data, setData] = useState<any[]>(report?.data);
	const [isHovered, setIsHovered] = useState(false);
	const [benchmarkRowHeight, setBenchmarkRowHeight] = useState(rowHeights[0]);
	// const [benchmarkContainerHeight, setBenchmarkContainerHeight] = useState(rowHeight);

	const { dynamicPermissions } = useAppSelector(state => state.auth);

	// const selectCurrentTime_ = disableInteractions
	//   ? undefined
	//   : source === 'dashboard'
	//     ? selectCurrentTime.bind(null, reportId)
	//     : selectCurrentTime;
	//
	//
	// useEffect(() => {
	//   if (!chartContainerRef.current) return;
	//
	//   function onResize() {
	//     if (!chartContainerRef.current) return;
	//     const { top, left, width, height } = chartContainerRef.current.getBoundingClientRect();
	//     setChartContainerBoundingBox({
	//       top: top + window.pageYOffset,
	//       left,
	//       width,
	//       height,
	//     });
	//   }
	//
	//   const observer = new ResizeObserver(onResize);
	//   observer.observe(chartContainerRef.current);
	//
	//   return () => {
	//     observer.disconnect();
	//   };
	// }, [chartContainerRef.current]);
	//

	//
	// useEffect(() => {
	//   const onResize = () => {
	//     const scrollableArea = scrollableAreaRef.current;
	//     if (!scrollableArea) return;
	//
	//     const { height: scrollableAreaHeight } = scrollableArea.getBoundingClientRect();
	//
	//     const legend = scrollableArea.querySelector('.legend')?.getBoundingClientRect().height || 0;
	//     const chartEvents = scrollableArea.querySelector('.chart-events')?.getBoundingClientRect().height || 0;
	//     const maxEventsVisible = 28;
	//     const events = Math.min(chartEvents, maxEventsVisible);
	//     setAdjustedChartHeight(scrollableAreaHeight - legend - events);
	//   };
	//
	//   if (!scrollableAreaRef.current) return;
	//   const observer = new ResizeObserver(onResize);
	//   observer.observe(scrollableAreaRef.current);
	//
	//   return () => {
	//     observer.disconnect();
	//   };
	// }, [scrollableAreaRef.current, chartType, eventIds]);
	//
	// function setChartHeight() {
	//   const htmlNode = htmlRef.current;
	//   if (!htmlNode) return;
	//   if (lineWidth) {
	//     let height = rowHeight - htmlNode.offsetHeight - (isTableChart ? 40 : 0);
	//     let extend = false;
	//     if (benchmark && benchmark.data && benchmark.data.length > 0) {
	//       const tableHeight = height - BENCHMARK_TOP_PADDING - BENCHMARK_BOTTOM_PADDING;
	//       let benchmarkRowHeight: number = 0;
	//       extend = true;
	//       for (let i = 0; i < rowHeights.length; i++) {
	//         benchmarkRowHeight = rowHeights[i];
	//         if (benchmarkRowHeight * (benchmark.data.length + 1) <= tableHeight) {
	//           extend = false;
	//           break;
	//         }
	//       }
	//       setBenchmarkRowHeight(benchmarkRowHeight);
	//       if (extend && !disableInteractions) {
	//         height =
	//           benchmarkRowHeight * (benchmark.data.length + 1) +
	//           BENCHMARK_TOP_PADDING +
	//           BENCHMARK_BOTTOM_PADDING;
	//         const benchmarkContainerHeight = height + htmlNode.offsetHeight;
	//         setBenchmarkContainerHeight(benchmarkContainerHeight);
	//       }
	//     }
	//     if (!extend || disableInteractions) {
	//       setBenchmarkContainerHeight(rowHeight);
	//     }
	//     setHeight(height);
	//   } else if (isTextChart) {
	//     // tmp
	//     setHeight(1);
	//   }
	// }
	//

	//
	// useEffect(() => {
	//   setChartHeight();
	// }, [length, rowHeight, colWidth, generatePending, lifecycle, resizing, chartType, data, lineWidth, benchmark]);
	//
	// useEffect(() => {
	//   setData(props.data);
	// }, [props.data, chartType]);
	//
	// useEffect(() => {
	//   if (isTableChart && props.data.length > 0 && !!height) {
	//     let tableRowHeight: number | undefined;
	//     for (let i = 0; i < rowHeights.length; i++) {
	//       tableRowHeight = rowHeights[i];
	//       if ((props.data.length + 2) * tableRowHeight < height) {
	//         break;
	//       }
	//     }
	//
	//     setRowHeightInReport(reportId, tableRowHeight);
	//   }
	// }, [height, chartType, props.data]);
	//
	// useEffect(() => {
	//   if (props.data.length === 0) return;
	//   let measuredWidth = 0;
	//   props.data.forEach(d => {
	//     if (!d.isEqualityIndex && !d.isAttritionIndex) {
	//       measuredWidth += measureText(d.label, CanvasFonts.Regular11).width + LABEL_WHITESPACE;
	//     }
	//   });
	//   const legendWidth = (resizeWidth || width) - LEGEND_WHITESPACE;
	//   const maxLineWidth = length > 2 ? 0.7 * legendWidth : legendWidth;
	//   setMultiLine(measuredWidth > maxLineWidth);
	//   setLineWidth(maxLineWidth);
	// }, [props.data, length, width, resizeWidth]);
	//
	// useEffect(() => {
	//   if (!interval) return;
	//   if (isTableChart && data.length > 0) {
	//     let tableData = produce(data, draftState => {
	//       draftState.forEach(dataItem => {
	//         // fix for a crash when switching from matrix report to table report
	//         if (!dataItem.series) return;
	//         dataItem.series.forEach((seriesItem: any) => {
	//           const label = getPeriod(seriesItem.date, interval?.toLowerCase());
	//           const prefix = label?.toLowerCase().replace(/ /g, '-');
	//           columnProps.forEach(columnProp => {
	//             dataItem[prefix + '-' + columnProp.source] = seriesItem[columnProp.source];
	//           });
	//         });
	//       });
	//     });
	//     let removedItems;
	//     if (analysisType === AnalysisTypes.Index || analysisType === AnalysisTypes.LinearRegression) {
	//       const index = tableData.findIndex(d => d.isEqualityIndex || d.isAttritionIndex);
	//       if (index !== -1) {
	//         tableData = tableData.slice();
	//         removedItems = tableData.splice(index, 1);
	//       }
	//     }
	//     tableData = orderBy(tableData, [sortCriteria.columnName], [sortCriteria.ascending ? 'asc' : 'desc']);
	//     if (removedItems) {
	//       tableData.unshift(...removedItems);
	//     }
	//     setTableData(tableData);
	//   }
	// }, [data, sortCriteria, chartType, columnProps, interval]);
	//
	// useEffect(() => {
	//   if (isMatrixChart && sortCriteria) {
	//     setData(prevData => {
	//       return orderBy(prevData, [sortCriteria.columnName], [sortCriteria.ascending ? 'asc' : 'desc']);
	//     });
	//   }
	// }, [props.data, sortCriteria, chartType]);
	//
	// function handleSort(columnName: string) {
	//   let ascending = true;
	//   if (columnName === sortCriteria.columnName) {
	//     ascending = !sortCriteria.ascending;
	//   }
	//
	//   setSortCriteriaInReport(reportId, {
	//     columnName,
	//     ascending,
	//   });
	// }
	//
	// function handleLabelActive(label: string) {
	//   if (disableInteractions) return;
	//   onHover(label);
	// }
	//
	// function handleLabelClear() {
	//   if (disableInteractions) return;
	//   onHoverEnd();
	// }
	//
	// function handleLabelToggle(label: string, e: any) {
	//   if (disableInteractions) return;
	//   e && e.stopPropagation();
	//
	//   let eventName = MixPanel.Events.DashboardReportLineGraphLegendSegmentClick;
	//   if (chartType === ChartTypes.Bar) {
	//     eventName = MixPanel.Events.DashboardReportColumnGraphLegendSegmentClick;
	//   }
	//   if (chartType === ChartTypes.Pie) {
	//     eventName = MixPanel.Events.DashboardReportPieChartLegendClick;
	//   }
	//   if (chartType === ChartTypes.StackedBar) {
	//     eventName = MixPanel.Events.DashboardReportStackedBarChartLegentClick;
	//   }
	//   track(eventName, {
	//     'Dashboard Name': dashboardName,
	//     'Report Name': title,
	//   });
	//
	//   onToggle(label);
	// }
	//
	// function handleDeselect() {
	//   if (disableInteractions) return;
	//
	//   onClearSelection();
	// }
	//
	// function handleToggleExpansion() {
	//   setExpandedInReport(reportId, !expanded);
	// }
	//

	//
	// function handleExplore() {
	//   track(MixPanel.Events.DashboardReportExplore, {
	//     'Dashboard Name': dashboardName,
	//     'Report Name': title,
	//   });
	//
	//   dispatch(setScrollPosition(window.scrollY));
	//   navigateToExplore({
	//     dashboardId,
	//     reportId,
	//     editMode: false,
	//   });
	// }
	//
	function handleDelete() {
		track(MixPanel.Events.EditDashboardDeleteReportClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		dispatch(showDeleteReportModal(reportId));
	}
	//

	//
	// function handleNavigateToDetail(...args: any) {
	//   navigateFromMatrix(reportId, ...args);
	// }
	//

	const isEmpty =
		!isTextChart &&
		!isInsightsChart &&
		(generatePending || generateError || generateNoData || generateInvalidVariation || !generated);

	return (
		<div
			className={classNames('editor__chart', {
				// 'editor__chart--empty':
				//   generatePending ||
				//   !(
				//     generated ||
				//     generateError ||
				//     generateNoData ||
				//     generateInvalidVariation ||
				//     isTextChart ||
				//     isInsightsChart
				//   ),
				// 'editor__chart--edit': editMode,
				// 'editor__chart--text-editor': isTextChart || isInsightsChart,
				// 'editor__chart--text-editor-activated':
				// (isTextChart || isInsightsChart) && textChartState.status === 'activated',
				// 'editor__chart--text-editor-selected':
				// (isTextChart || isInsightsChart) && textChartState.status === 'idle' && moreOpen,
				// 'editor__chart--text-editor-hovered':
				// (isTextChart || isInsightsChart) && textChartState.status === 'idle' && !moreOpen && isHovered,
			})}
			// onClick={handleDeselect}
			id={`chart-${dashboardReport?.reportId}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<ChartEmptyState
				generated={generated}
				generateError={generateError}
				generateInvalidVariation={generateInvalidVariation}
				generateNoData={generateNoData}
				generateNotAllowed={generateNotAllowed}
				generatePending={generatePending}
				isEmptyState={isEmpty}
				onDelete={handleDelete}
				title={title}
				isTextChart={isTextChart || isInsightsChart}
			>
				<ChartPicker
					dashboard={dashboard}
					report={report}
					dashboardReport={dashboardReport}
					isLocked={isLocked}
					isHovered={isHovered}
				/>
			</ChartEmptyState>
		</div>
	);
}

Chart.defaultProps = defaultProps;
