import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PackageFragment } from '../package/PackageFragment';
import { getEnterprises } from './actions';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function EnterpriseDetail({ history, match }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const { loaded, data } = useAppSelector(state => state.datastudio.enterprises);
	const id = match.params.id;
	const shouldFetch = !loaded;
	const enterprise = data?.find((c: any) => c.enterpriseId === id);

	useEffect(() => {
		if (shouldFetch) {
			dispatch(getEnterprises());
		}
	}, [shouldFetch]);

	function handleEdit() {
		history.push(`/datastudio/enterprises/${match.params.id}/edit`);
	}

	return enterprise ? (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-edit']} onClick={handleEdit}>
				Edit
			</Button>
			<div className="ds-label">Enterprise name</div>
			<div className="ds-value">{enterprise.enterpriseName}</div>
			<div className="ds-label">Enterprise id</div>
			<div className="ds-value">{enterprise.enterpriseId}</div>
			{enterprise.packages.map((p, i) => (
				<PackageFragment key={p.packageId} packageObj={p} expandable />
			))}
		</div>
	) : null;
}

export default EnterpriseDetail;
