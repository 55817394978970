import { Route, Switch } from 'react-router-dom';

import Admin from '../admin/Admin';
import CreateRole from '../admin/rolespermissions/CreateRole';
import EditRole from '../admin/rolespermissions/EditRole';
import Collect from '../collect/Collect';
import Dashboard from '../dashboard/view/Dashboard';
import Vault from '../dashboard/view/Vault';
import Dashboards from '../dashboards/Dashboards';
import Explore from '../explore/Explore';
import shouldShowBanner from '../lib/shouldShowBanner';
import Measure from '../measure/Measure';
import Goal from '../measure/goal/Goal';
import NotFound from '../routes/NotFound';
import Audiences from '../self-id/audiences/list';
import CampaignCreate from '../self-id/campaigns/edit/CampaignCreate';
import CampaignEdit from '../self-id/campaigns/edit/CampaignEdit';
import Campaigns from '../self-id/campaigns/list';
import Campaign from '../self-id/campaigns/view';
import SurveyTemplates from '../self-id/survey-templates/list';
import SurveyTemplate from '../self-id/survey-templates/view';
import Timeline from '../timeline';
import AiPage from '../ai/Page';
import cn from '../lib/cn';
import { useAppSelector } from '../store/hooks';

export default function ContentArea({ aiLayout }: { aiLayout: boolean }) {
	const { featureFlags } = useAppSelector(state => state.account);

	return (
		<div
			className={cn(
				'max-w-screen min-w-screen',
				'flex flex-col overflow-hidden',
				featureFlags?.aiChat && aiLayout ? 'pt-[0px] pb-0' : 'pt-[120px] pb-[16rem]'
			)}
			style={{
				top: shouldShowBanner() ? '4rem' : '0',
			}}
		>
			<Switch>
				<Route path="/" exact component={Dashboards} />
				<Route path="/dashboard/:dashboardId" component={Dashboard} />
				<Route path="/vault/:vaultId" component={Vault} />
				<Route path="/explore" component={Explore} />
				<Route path="/timeline" component={Timeline} />
				<Route path="/measure/:goalId" component={Goal} />
				<Route path="/measure" component={Measure} />
				<Route path="/admin/create-role" component={CreateRole} />
				<Route path="/admin/edit-role/:roleId" component={EditRole} />
				<Route path="/admin/duplicate-role" component={CreateRole} />
				<Route path="/admin" component={Admin} />
				<Route path="/collect" component={Collect} />
				<Route path="/self-id/campaigns/view/:id" component={Campaign} />
				<Route path="/self-id/campaigns/edit/:id" component={CampaignEdit} />
				<Route path="/self-id/campaigns/create" component={CampaignCreate} />

				<Route path="/self-id/audiences/view/:id" component={() => <div>View Audience</div>} />
				<Route path="/self-id/survey-templates/view/:id" component={SurveyTemplate} />
				<Route path="/self-id/survey-templates" component={SurveyTemplates} />

				<Route path="/self-id/audiences" component={Audiences} />
				<Route path="/self-id/campaigns" component={Campaigns} />
				{featureFlags?.aiChat && <Route path="/ai/:conversationId" component={AiPage} />}
				{featureFlags?.aiChat && <Route path="/ai" component={AiPage} />}
				<Route component={NotFound} />
			</Switch>
		</div>
	);
}
