import { useState } from 'react';

import BenchmarkModal from '../../benchmark/BenchmarkModal';
import '../../benchmark/styles.scss';
import ChartPicker from '../../common/ChartPicker';
import EventsModal from '../../common/EventsModal';
import InfoModal from '../../common/InfoModal';
import cn from '../../lib/cn';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeEventsModal } from './actions';
import { ChartContextProvider } from '../../contexts/ChartContextV2';
import DeleteBenchmarkModal from './DeleteBenchmarkModal';
import EmptyState from './EmptyState';

// const COLUMN_GAP = 16;
const LEGEND_WHITESPACE = 52;
const LABEL_WHITESPACE = 16;
// const BENCHMARK_BOTTOM_PADDING = 40;
// const BENCHMARK_TOP_PADDING = 16;
// const ROW_HEIGHTS = [44, 40, 36, 28];
// const MAX_HEIGHT = 400;
// const MIN_HEIGHT = 250;

function Chart({ benchmarkEdit, benchmarkToEdit, isSettingsOpen }: any) {
	const dispatch = useAppDispatch();
	const [lineWidth, setLineWidth] = useState<any>();
	// const [benchmarkRowHeight, setBenchmarkRowHeight] = useState(ROW_HEIGHTS[0]);
	const { showSubtable } = useAppSelector(state => state.explore.filter);
	const isEmptyState = useAppSelector(
		state => !state.explore.filter.analysisName && state.explore.filter.mainSegments.length === 0
	);
	let { lifecycle, generatePending, generateError, generateNoData, generateInvalidVariation, generated } =
		useAppSelector(state => state.explore.chart);
	const {
		lifecycle: lifecycleSubtable,
		generatePending: generatePendingSubtable,
		error,
		empty,
		invalidVariation,
		generated: generatedSubtable,
	} = useAppSelector(state => state.explore.subtable);
	if (showSubtable) {
		lifecycle = lifecycleSubtable || '';
		generatePending = generatePendingSubtable;
		generateNoData = empty;
		generateInvalidVariation = invalidVariation;
		generateError = error;
	}
	generated = showSubtable ? generatedSubtable : generated;

	const { benchmark } = useAppSelector(state => state.explore.chart);

	if (isEmptyState) {
		return null;
	}

	let isOther = false;
	if (!benchmark && benchmarkToEdit) {
		isOther = true;
	}
	if (benchmark && benchmarkToEdit) {
		isOther = benchmark.benchmarkId !== benchmarkToEdit.benchmarkId;
	}

	return (
		<ChartContextProvider mode="explore">
			<div className={cn('explore-chart', 'relative hide-scrollbar overflow-x-auto mx-[2rem] mb-[1.6rem]')}>
				<EmptyState
					generatePending={generatePending}
					generateError={generateError}
					generateInvalidVariation={generateInvalidVariation}
					generateNoData={generateNoData}
					generated={generated}
				>
					<ChartPicker />
				</EmptyState>
				<InfoModal />
				<BenchmarkModal benchmark={benchmarkEdit ? benchmarkToEdit : null} isOther={isOther} />
				<EventsModal onCancel={() => dispatch(closeEventsModal())} />
				<DeleteBenchmarkModal benchmarkToEdit={benchmarkToEdit} />
			</div>
		</ChartContextProvider>
	);
}

export default Chart;
