import { useHistory } from 'react-router';

import Button from '../../common/Button';
import Stack from '../../common/Stack';
import WithPermissions from '../../common/WithPermissions';
import ButtonTypes from '../../constants/ButtonTypes';
import RbacActions from '../../constants/RbacActions';
import { useAppDispatch } from '../../store/hooks';
import { setModalOpen } from '../audiences/edit/slice';
import HeaderWrapper from '../../common/HeaderWrapper';

const ROUTE_PREFIX = 'self-id/';

const SelfIdHeader = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const isCampaignsActive = isActivated('campaigns');
	const isAudiencesActive = isActivated('audiences');

	function navigate(routePart: string) {
		if (!isActivated(routePart)) {
			history.push(routePart);
		}
	}

	function isActivated(routePart: string) {
		return location.pathname.includes(ROUTE_PREFIX + routePart);
	}

	return (
		<WithPermissions actions={[RbacActions['Campaign/View']]}>
			<HeaderWrapper>
				<Stack flexDirection="row" gap=".8rem">
					<Button
						selected={isCampaignsActive}
						onClick={() => navigate('campaigns')}
						componentType={ButtonTypes.type.LIST_ITEM}
						selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
						navigation
						large={false}
					>
						Campaigns
					</Button>
					<Button
						selected={isAudiencesActive}
						onClick={() => navigate('audiences')}
						componentType={ButtonTypes.type.LIST_ITEM}
						selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
						navigation
						large={false}
					>
						Audiences
					</Button>
				</Stack>
				<WithPermissions actions={[RbacActions['Campaign/Edit']]}>
					{isAudiencesActive && <Button onClick={() => dispatch(setModalOpen(true))}>New audience</Button>}
					{isCampaignsActive && <Button onClick={() => navigate('survey-templates')}>Create campaign</Button>}
				</WithPermissions>
			</HeaderWrapper>
		</WithPermissions>
	);
};

export default SelfIdHeader;
