import React from 'react';
import { IconProps } from './types';

const ArrowUpRight = ({ size, color }: IconProps) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="22.8516" y="3.14844" width="19" height="4" transform="rotate(90 22.8516 3.14844)" fill={color} />
			<rect x="22.8516" y="22.1484" width="20" height="4" transform="rotate(-180 22.8516 22.1484)" fill={color} />
			<rect
				x="20.0234"
				y="21.7041"
				width="26.6936"
				height="4"
				transform="rotate(-135 20.0234 21.7041)"
				fill={color}
			/>
		</svg>
	);
};

export default ArrowUpRight;
