import FileSaver from 'file-saver';
import getFormattedValue from '../../lib/getFormattedValue';
import measureText from '../../lib/measureText';
import CanvasFonts from '../../constants/CanvasFonts';
import loadImage from '../../lib/loadImage';
import { getComputedCssValue, getCssVariable } from '../../lib/customColors';
import truncateHeader from '../../lib/truncateHeader';
import Constants from '../../constants/Constants';
import blankEmployeeCount from '../../lib/blankEmployeeCount';

const padding = 24;
const colWidth = 135;
const largeColWidth = 270;
const rowHeight = 28;
const yText = 18;
const subHeaderHeight = 18;
const ySubHeader = 10;
const xCellPadding = 16;

function getColumnCount(columnProps, expanded) {
	if (columnProps.length > 2) {
		return expanded ? columnProps.length : 1;
	}
	return columnProps.length;
}

function getFieldNames(columnProps, expanded) {
	if (columnProps.length > 2 && !expanded) {
		return [columnProps[0].source];
	} else {
		return columnProps.map(columnProp => columnProp.source);
	}
}

function getHeaderNames(columnProps, expanded) {
	if (columnProps.length > 2 && !expanded) {
		return [truncateHeader(columnProps[0].header, Constants.MaxHeaderLength)];
	} else {
		return columnProps.map(columnProp => truncateHeader(columnProp.header, Constants.MaxHeaderLength));
	}
}

function getPropName(label, prop = '') {
	let propName = label.replace(/ /g, '-').toLowerCase();
	if (prop) {
		propName += '-' + prop;
	}
	return propName;
}

function displayData(
	context,
	rowIndex,
	data,
	lifecycle,
	analysisType,
	columnProps,
	diversityAttributes,
	expanded,
	canvasWidth,
	columnCount
) {
	context.font = CanvasFonts.Regular14;
	data.forEach(d => {
		const odd = rowIndex % 2 === 0;
		if (odd) {
			context.fillStyle = getComputedCssValue('--color-shade-3');
			context.fillRect(0, padding + subHeaderHeight + rowHeight * (rowIndex + 3), canvasWidth, rowHeight);
		}

		context.fillStyle = getComputedCssValue('--color-ui-50');
		context.fillText(d.label, padding, padding + subHeaderHeight + rowHeight * (3 + rowIndex) + yText);

		diversityAttributes.forEach((da, index2) => {
			if (diversityAttributes.length !== 1 && index2 % 2 === 0) {
				context.fillStyle = getComputedCssValue('--color-shade-3');
				context.fillRect(
					padding + largeColWidth + index2 * colWidth * columnCount,
					padding + subHeaderHeight + rowHeight * (rowIndex + 3),
					colWidth * columnCount,
					rowHeight
				);
			}
			context.fillStyle = getComputedCssValue('--color-ui-50');
			getFieldNames(columnProps, expanded).forEach((c, index3) => {
				const cellCount = index2 * columnCount + index3;
				const propName = getPropName(da.label, c);
				let value = index3 ? d[propName] || 0 : getFormattedValue(analysisType, d[propName]);
				value = blankEmployeeCount(value);

				context.fillText(
					value,
					padding + largeColWidth + cellCount * colWidth + xCellPadding,
					padding + subHeaderHeight + rowHeight * (3 + rowIndex) + yText
				);
			});
		});

		rowIndex++;
	});
}

export default async function exportDetailData(
	enterpriseName,
	period,
	data,
	summary,
	groupSummary,
	title,
	lifecycle,
	analysisType,
	columnProps,
	employeeAttribute,
	diversityAttributes,
	sortCriteria,
	expanded,
	shouldReturnBlob,
	shouldReturnData
) {
	let rowIndex = 0;
	const hasGroupSummary = groupSummary && Object.keys(groupSummary).length > 0;
	const canvas = document.createElement('canvas');
	const columnCount = getColumnCount(columnProps, expanded);
	const canvasWidth = 2 * padding + largeColWidth + diversityAttributes.length * columnCount * colWidth;
	const dataWithExpandedChildren = data.filter(d => d.showChildren);
	let rowCount = data.length;
	if (dataWithExpandedChildren.length) {
		dataWithExpandedChildren.forEach(d => {
			rowCount += d.children.length;
		});
	}
	const canvasHeight = 2 * padding + rowHeight * (hasGroupSummary ? 5 : 4) + rowCount * rowHeight + subHeaderHeight;
	canvas.width = canvasWidth;
	canvas.height = canvasHeight;

	const context = canvas.getContext('2d');
	context.fillStyle = getComputedCssValue('--color-shade-h3');
	context.fillRect(0, 0, canvas.width, canvas.height);

	context.fillStyle = getComputedCssValue('--color-ui-50');
	context.font = CanvasFonts.Regular10;
	const subheader = `${enterpriseName} - ${period}`;
	context.fillText(subheader, padding, padding + ySubHeader);

	context.fillStyle = getComputedCssValue('--color-ui-100');
	context.font = CanvasFonts.Regular18;
	const header = title || `${lifecycle} / ${employeeAttribute}`;
	context.fillText(header, padding, padding + subHeaderHeight + 18);

	const maxTextWidth = colWidth * columnCount - 2 * xCellPadding;
	diversityAttributes.forEach((da, index) => {
		context.font = CanvasFonts.Regular14;
		const xColHeader = padding + largeColWidth + index * colWidth * columnCount + xCellPadding;
		let offset = 0;

		for (let i = 0; i < da.segment.tokens.length; i++) {
			const token = da.segment.tokens[i];
			let tokenText;

			if (typeof token === 'object') {
				context.beginPath();
				context.arc(
					xColHeader + offset,
					padding + subHeaderHeight + rowHeight + rowHeight / 2,
					2,
					0,
					2 * Math.PI
				);
				context.fillStyle = getComputedCssValue(getCssVariable(token.color));
				context.fill();

				context.fillStyle = getComputedCssValue('--color-ui-100');
				tokenText = token.text;
			} else {
				context.fillStyle = getComputedCssValue('--color-ui-100');
				tokenText = token;
			}
			let overflow = false;
			const tokenTextArray = tokenText.split(' ');
			for (let j = 0; j < tokenTextArray.length; j++) {
				const textWidth = measureText(tokenTextArray[j], context.font).width;
				if (offset + textWidth < maxTextWidth) {
					context.fillText(
						tokenTextArray[j],
						xColHeader + offset + 16,
						padding + subHeaderHeight + rowHeight + yText
					);
					offset += measureText(tokenTextArray[j] + ' ', context.font).width;
				} else {
					context.fillStyle = getComputedCssValue('--color-ui-100');
					context.fillText(' ...', xColHeader + offset, padding + subHeaderHeight + rowHeight + yText);
					overflow = true;
					break;
				}
			}
			if (overflow) break;
		}
	});

	context.fillStyle =
		sortCriteria.columnName === 'label'
			? getComputedCssValue('--color-ui-100')
			: getComputedCssValue('--color-ui-50');
	context.fillText(employeeAttribute, padding, padding + subHeaderHeight + rowHeight * 2 + yText);
	const textWidth = context.measureText(employeeAttribute).width;
	context.font = CanvasFonts.Regular8;
	const arrow = sortCriteria.columnName === 'label' && !sortCriteria.ascending ? '\u{25b2}' : '\u{25bc}';
	context.fillText(arrow, padding + textWidth + 8, padding + subHeaderHeight + rowHeight * 2 + yText);

	const headerNames = getHeaderNames(columnProps, expanded);

	diversityAttributes.forEach((da, index) => {
		getFieldNames(columnProps, expanded).forEach((c, index2) => {
			const cellCount = index * columnCount + index2;
			context.font = CanvasFonts.Regular14;
			const propName = getPropName(da.label, c);
			context.fillStyle =
				sortCriteria.columnName === propName
					? getComputedCssValue('--color-ui-100')
					: getComputedCssValue('--color-ui-50');

			context.fillText(
				headerNames[index2],
				padding + largeColWidth + cellCount * colWidth + xCellPadding,
				padding + subHeaderHeight + rowHeight * 2 + yText
			);

			const textWidth = context.measureText(headerNames[index2]).width;
			context.font = CanvasFonts.Regular8;
			const arrow = sortCriteria.columnName === propName && !sortCriteria.ascending ? '\u{25b2}' : '\u{25bc}';
			context.fillText(
				arrow,
				padding + largeColWidth + cellCount * colWidth + xCellPadding + textWidth + 8,
				padding + subHeaderHeight + rowHeight * 2 + yText
			);
		});
	});

	displayData(
		context,
		rowIndex,
		data,
		lifecycle,
		analysisType,
		columnProps,
		diversityAttributes,
		expanded,
		canvasWidth,
		columnCount
	);

	rowIndex += data.length;

	if (hasGroupSummary) {
		context.font = CanvasFonts.Medium14;
		context.fillStyle = getComputedCssValue('--color-ui-100');
		const odd = rowCount % 2 === 0;
		if (odd) {
			context.fillStyle = getComputedCssValue('--color-shade-h3');
			context.fillRect(0, padding + subHeaderHeight + rowHeight * (rowCount + 3), canvasWidth, rowHeight);
		}
		context.fillStyle = getComputedCssValue('--color-ui-100');
		context.fillText('Filtered Average', padding, padding + subHeaderHeight + rowHeight * (3 + rowCount) + yText);
		diversityAttributes.forEach((da, index2) => {
			if (diversityAttributes.length !== 1 && index2 % 2 === 0) {
				context.fillStyle = getComputedCssValue('--color-shade-3');
				context.fillRect(
					padding + largeColWidth + index2 * colWidth * columnCount,
					padding + subHeaderHeight + rowHeight * (rowCount + 3),
					colWidth * columnCount,
					rowHeight
				);
			}
			context.fillStyle = getComputedCssValue('--color-ui-100');
			getFieldNames(columnProps, expanded).forEach((p, index3) => {
				const cellCount = index2 * columnCount + index3;
				const propName = getPropName(da.label);
				let value = index3
					? groupSummary[propName][p] || ''
					: getFormattedValue(analysisType, groupSummary[propName][p]);

				if (typeof value === 'number' && value < 0) {
					value = '';
				}

				context.fillText(
					value,
					padding + largeColWidth + cellCount * colWidth + xCellPadding,
					padding + subHeaderHeight + rowHeight * (3 + rowCount) + yText
				);
			});
		});
	}

	context.font = CanvasFonts.Medium14;
	context.fillStyle = getComputedCssValue('--color-ui-100');
	const odd = hasGroupSummary ? (rowCount + 1) % 2 === 0 : rowCount % 2 === 0;
	const ySummary = hasGroupSummary
		? padding + subHeaderHeight + rowHeight * (rowCount + 4)
		: padding + subHeaderHeight + rowHeight * (rowCount + 3);
	if (odd) {
		context.fillStyle = getComputedCssValue('--color-shade-h3');
		context.fillRect(0, ySummary, canvasWidth, rowHeight);
	}
	context.fillStyle = getComputedCssValue('--color-ui-100');
	context.fillText('Average', padding, ySummary + yText);
	diversityAttributes.forEach((da, index2) => {
		if (diversityAttributes.length !== 1 && index2 % 2 === 0) {
			context.fillStyle = getComputedCssValue('--color-shade-3');
			context.fillRect(
				padding + largeColWidth + index2 * colWidth * columnCount,
				ySummary,
				colWidth * columnCount,
				rowHeight
			);
		}
		context.fillStyle = getComputedCssValue('--color-ui-100');
		getFieldNames(columnProps, expanded).forEach((p, index3) => {
			const cellCount = index2 * columnCount + index3;
			const propName = getPropName(da.label);
			let value = index3 ? summary[propName][p] || '' : getFormattedValue(analysisType, summary[propName][p]);

			value = blankEmployeeCount(value);

			context.fillText(value, padding + largeColWidth + cellCount * colWidth + xCellPadding, ySummary + yText);
		});
	});

	context.strokeStyle = getComputedCssValue('--color-ui-30');
	diversityAttributes.forEach((_, index) => {
		getFieldNames(columnProps, expanded).forEach((_, index2) => {
			context.beginPath();
			if (index2 === 0) {
				context.moveTo(
					padding + largeColWidth + index * colWidth * columnCount,
					padding + subHeaderHeight + rowHeight
				);
			} else {
				context.moveTo(
					padding + largeColWidth + (index * columnCount + index2) * colWidth,
					padding + subHeaderHeight + rowHeight * 2
				);
			}
			context.lineTo(padding + largeColWidth + (index * columnCount + index2) * colWidth, canvasHeight - padding);
			context.stroke();
		});
	});

	let image = await loadImage('/images/watermark.svg');
	context.drawImage(image, canvas.width - padding - image.width, padding);

	if (shouldReturnData) {
		return {
			dataURL: canvas.toDataURL(),
			width: canvas.width,
			height: canvas.height,
		};
	} else {
		const blob = await new Promise(resolve => canvas.toBlob(resolve));
		if (shouldReturnBlob) {
			return {
				blob,
				width: canvas.width,
				height: canvas.height,
			};
		} else {
			FileSaver.saveAs(blob, `Dandi Detail by ${employeeAttribute} ${period}.png`);
		}
	}
}
