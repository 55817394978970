import { Option } from './useSegmentPicker/types';

export function generateSegmentsState(state1: any, state2: any, allOptions: Option[]) {
	const segments = [];
	if (!!state1.segment) {
		segments.push({
			...allOptions.find((option: Option) => option.name === state1.segment),
			values: state1.values,
		});
	}

	if (!!state2.segment) {
		segments.push({
			...allOptions.find((option: Option) => option.name === state2.segment),
			values: state2.values,
		});
	}

	return segments;
}

export function generateReadableValues(segments: any[], accountFields: any[], mappings: any) {
	const options: Option[] = [];

	segments.forEach(segment => {
		if (segment.name === 'Department' || segment.name === 'Business Unit') {
			options.push({
				...segment,
				values: accountFields
					.find((field: any) => field.field === segment.name)
					?.values?.map(
						(value: any) =>
							mappings.departments.find((dep: any) => dep.departmentId === value).departmentName
					),
			});
		} else if (segment.name === 'Job Title') {
			options.push({
				...segment,
				values: accountFields
					.find((field: any) => field.field === segment.name)
					?.values?.map(
						(value: any) => mappings.jobTitles.find((dep: any) => dep.jobTitleId === value).jobTitleName
					),
			});
		} else {
			options.push({
				...segment,
				values: accountFields.find(field => field.field === segment.name)?.values,
			});
		}
	});

	// Sort options alphabetically
	options.sort((a, b) => a.name.localeCompare(b.name));

	options.push({
		column: 'primary.hireDate',
		name: 'Hire Date',
		values: ['Last week', 'Last month', 'Last quarter', 'Last year'],
	});

	return options;
}

export function mapHireDateValue(value?: string) {
	if (!value) {
		return '-10000';
	}
	const lowerValue = value.toLowerCase();

	switch (lowerValue) {
		case 'last week':
			return '-7';
		case 'last month':
			return '-31';
		case 'last quarter':
			return '-92';
		case 'last year':
			return '-365';
		default:
			return '-10000';
	}
}

export function subtractDaysFromToday(days: number) {
	const today = new Date();
	today.setDate(today.getDate() - days);
	return today;
}
