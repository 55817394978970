import Modal from 'antd/lib/modal';
import { useRef, useState } from 'react';

import Button from '../common/Button';
import OpenText from '../common/OpenText';
import ButtonTypes from '../constants/ButtonTypes';
import { useAppDispatch } from '../store/hooks';
import { setChatConversationMetadata } from './conversations/actions';
import { addConversationMetadata } from './conversations/reducer';

interface EditConversationNameModalProps {
	conversationId: string;
	isOpen: boolean;
	conversationName: string;
	onClose: () => void;
}

export default function EditConversationNameModal({
	conversationId,
	isOpen,
	conversationName,
	onClose,
}: EditConversationNameModalProps) {
	const dispatch = useAppDispatch();
	const [name, setName] = useState(conversationName || '');
	const inputRef = useRef<any>();

	function handleNameChange(name: string) {
		setName(name);
	}

	function handleKeyDown(e: any) {
		if (e.key === 'Tab') {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current?.focus();
				}
			}, 0);
		}
	}

	function handleSave() {
		dispatch(
			setChatConversationMetadata({
				conversationId: conversationId,
				metadata: {
					name: name,
				},
			})
		);
		dispatch(
			addConversationMetadata({
				conversationId: conversationId,
				metadata: {
					name: name,
					updated_at: new Date().toISOString(),
				},
			})
		);
		onClose();
	}

	return (
		<Modal
			open={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onClose}
			zIndex={3000}
			destroyOnClose
		>
			<h3 className="mb40 font-subtitle">Name your Conversation</h3>
			<div>
				<OpenText
					value={name}
					onChange={handleNameChange}
					onKeyDown={handleKeyDown}
					inputRef={inputRef}
					placeholder="Enter name here"
					tabIndex={2}
				/>
				<div className="mt-[24px] flex justify-end gap-[8px]">
					<Button tabIndex={3} componentType={ButtonTypes.type.TERTIARY} onClick={onClose}>
						Cancel
					</Button>
					<Button type="submit" tabIndex={4} disabled={!name} onClick={handleSave}>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
}
