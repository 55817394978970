import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const config =
	process.env.REACT_APP_ENV !== 'prod'
		? {
				apiKey: 'AIzaSyBtG3dD2UejrGJ8WAzniTznV9MbBlF12js',
				authDomain: 'dandi-dev.firebaseapp.com',
				storageBucket: 'dandi-dev.appspot.com',
				databaseURL: 'https://dandi-dev.firebaseio.com',
				projectId: 'dandi-dev',
		  }
		: {
				apiKey: 'AIzaSyDQmaVVbMRTeFbslqJzfa0SnAEdVe5tfc4',
				authDomain: 'dandi-184419.firebaseapp.com',
				storageBucket: 'dandi-184419.appspot.com',
				databaseURL: 'https://dandi-184419.firebaseio.com',
				projectId: 'dandi-184419',
		  };

firebase.initializeApp(config);

export const fireDb = firebase.firestore();

export default firebase;
