import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

import ActionTypes from '../../constants/ActionTypes';
import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import parseBoolean from '../../lib/parseBoolean';
import { getStateForPrimaryData, getStateForSubtable } from '../../store/reducerUtils';
import { TimeInterval } from '../../types';

const defaultSortCriteria = {
	columnName: 'label',
	ascending: true,
};

export function getAttributeName(variationName: string, initialAttributes: any) {
	const initialAttribute = initialAttributes.find((ia: any) => {
		return ia.attributeName.replace(/ /g, '').replace(/-/g, '').toLowerCase() === variationName.toLowerCase();
	});
	return initialAttribute ? initialAttribute.attributeName : variationName;
}

export function getSegments(filters: any, variations: any) {
	const segments: any = {};
	let filterTypes = filters.map((f: any) => f.type.replace(/ /g, '').replace(/-/g, '').toLowerCase());
	filterTypes = uniq(filterTypes);
	variations.forEach((v: string[]) => {
		const variation = v.map((vi: string) => vi.toLowerCase()).filter((vi: string) => !!vi);
		let shouldInclude = variation.length === filterTypes.length + 1;
		if (shouldInclude) {
			filterTypes.forEach((ft: any) => {
				shouldInclude = shouldInclude && variation.includes(ft);
			});
		}
		if (shouldInclude) {
			v.forEach((vi, index) => {
				if (vi && index >= 3 && !filterTypes.includes(vi.toLowerCase())) {
					segments[vi] = true;
				}
			});
		}
	});
	return Object.keys(segments);
}

export interface ExploreSubtableUrlState {
	analysisType?: analysisTypes;
	date?: string;
	columnProps?: any[];
	interval?: TimeInterval;
	subtableChartType?: string;
	allowChartToggle?: boolean;
}

export interface ISubtableChartState extends ExploreSubtableUrlState {
	sortCriteria: any;
	expanded?: boolean;
	generatePending?: boolean;
	generated?: boolean;
	empty?: boolean;
	error?: boolean;
	pending?: boolean;
	invalidVariation?: boolean;
	data?: any[];
	lifecycle?: string;
	attributeName?: string;
	employeeAttributes?: any[];
	employeeAttribute?: any;
	unfilteredData?: any[];
	summary?: any;
	showGroupSummary?: boolean;
	groupSummary?: any;
	diversityAttributes?: any[];
	unfilteredDiversityAttributes?: any[];
	mainSegments?: any[];
	breakdownSegment?: any;
	shouldGetPrimaryData?: boolean;
	total?: number;
	currentSegmentIndex: number;
}

export const getInitialState = () => ({
	sortCriteria: defaultSortCriteria,
	subtableChartType: ChartTypes.Bar,
	allowChartToggle: false,
	currentSegmentIndex: 0,
});

export default function subtableReducer(
	state: ISubtableChartState = getInitialState(),
	action: any
): ISubtableChartState {
	switch (action.type) {
		case ActionTypes.GenerateSubtablePending: {
			return {
				...state,
				generatePending: true,
				empty: false,
				error: false,
				invalidVariation: false,
			};
		}
		case ActionTypes.GenerateSubtableFulfilled: {
			return getStateForSubtable(state, action);
		}
		case ActionTypes.GenerateSubtableRejected: {
			return {
				...state,
				generatePending: false,
				error: true,
				data: [],
			};
		}
		case ActionTypes.GenerateNoData: {
			if (action.isSubtable) {
				return {
					...state,
					generatePending: false,
					empty: true,
					data: [],
				};
			}
			return state;
		}
		case ActionTypes.GenerateInvalidVariation:
			if (action.isSubtable) {
				return {
					...state,
					generatePending: false,
					invalidVariation: true,
					data: [],
				};
			}
			return state;
		case ActionTypes.ChangeLocation: {
			if (action.payload.location.pathname !== '/explore') {
				// clear the state when we leave explore
				return {
					...state,
					generatePending: false,
					empty: false,
				};
			}
			return state;
		}
		case ActionTypes.ClearFilters:
			return getInitialState();
		case ActionTypes.ToggleExpandedInSubtable:
			return {
				...state,
				expanded: !state.expanded,
			};
		case ActionTypes.SortInSubtable: {
			let ascending = true;
			const { sortCriteria, data } = state;
			const { columnName } = action;
			if (columnName === sortCriteria.columnName) {
				ascending = !sortCriteria.ascending;
			}
			const sortedData = orderBy(data, [columnName], [ascending ? 'asc' : 'desc']);
			return {
				...state,
				data: sortedData,
				sortCriteria: {
					columnName,
					ascending,
				},
			};
		}
		case ActionTypes.GeneratePrimaryDataFulfilledInExplore:
			return getStateForPrimaryData(state, action);
		case ActionTypes.SetSubtableChartType:
			return {
				...state,
				subtableChartType: action.chartType,
			};
		case ActionTypes.SetCurrentSegmentIndex:
			return {
				...state,
				currentSegmentIndex: action.index,
			};
		case ActionTypes.SyncExploreSubtableState: {
			return {
				...state,
				...action.payload,
				allowChartToggle: parseBoolean(action.payload?.allowChartToggle),
			};
		}
		case ActionTypes.ChangeLocation: {
			const pathname = window.location.pathname;
			if (pathname !== '/explore') {
				return getInitialState();
			}
			return state;
		}

		default:
			return state;
	}
}
