import React from 'react';
import Button from './Button';
import ButtonTypes from '../constants/ButtonTypes';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

interface MoveVerticalProps {
	hideUp?: boolean;
	hideDown?: boolean;
	moveUp?: () => void;
	moveDown?: () => void;
}

export default function MoveVertical({ moveUp, moveDown, hideUp, hideDown }: MoveVerticalProps) {
	return (
		<div className="move-vertical">
			<Button
				componentType={ButtonTypes.type.TERTIARY}
				onClick={moveUp}
				style={{ visibility: hideUp ? 'hidden' : 'none' }}
			>
				<MdKeyboardArrowUp size={16} />
			</Button>
			<Button
				componentType={ButtonTypes.type.TERTIARY}
				onClick={moveDown}
				style={{ visibility: hideDown ? 'hidden' : 'none' }}
			>
				<MdKeyboardArrowDown size={16} />
			</Button>
		</div>
	);
}
