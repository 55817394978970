import analysisTypes from '../constants/AnalysisTypes';
import { selectCurrentTime } from '../explore/actions';
import { setCurrentSegmentIndex } from '../explore/subtable/actions';
import { getFormattedDate } from '../lib/chartUtils';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import getFormattedValue from '../lib/getFormattedValue';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { IndexType } from '../types';
import BarChart from './BarChart';
import { ChartTooltipProps } from './ChartTooltip/ChartTooltip';
import Events from './Events';
import Legend from './Legend';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';

export interface BarData {
	xValue: any;
	formattedXValue: string;
	bars: {
		isActive: boolean;
		yValue: number;
		formattedYValue: string;
		color: string;
		label: string;
		internal_id: string;
		tooltip: ChartTooltipProps;
		isOverlay: boolean;
	}[];
}

const BarChartWrapper = ({
	reportId,
	events,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	events?: any;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
}) => {
	const dispatch = useAppDispatch();
	const { dynamicPermissions } = useAppSelector(state => state.auth);

	const {
		analysisName,
		analysisType,
		chartType,
		columnProps,
		currentIndex,
		data,
		dates,
		indexType,
		interval,
		lifecycle,
		metricOverlay,
		metricOverlayLifecycle,
		overlayChartType,
		overlayIndexType,
		overlayYAxisType,
		showSubtable,
		subtable,
		yAxisType,
	} = useAppSelector(state => getChartData(state, reportId));

	const overlayColumnProps = metricOverlay?.columnProps ?? [];
	const overlayData = metricOverlay?.data ?? [];
	const overlayAnalysisName = metricOverlay?.analysisName;
	const overlayAnalysisType = metricOverlay?.analysisType;

	const dataProp = getDynamicDataProp(yAxisType, indexType, showSubtable ? subtable?.columnProps : columnProps);
	const dataOverlayProp = getDynamicDataProp(overlayYAxisType, overlayIndexType, overlayColumnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);
	const mergedData = overlayChartType === chartType && !!overlayData ? [...data, ...overlayData] : data;

	function createBarData(isCount: boolean, isCountOverlay: boolean): BarData[] {
		const newData = dates.map((date: any, groupIndex: number) => {
			return {
				xValue: date,
				formattedXValue: getFormattedDate(date, interval) || '',
				bars: mergedData
					.map((d: any) => {
						const seriesItem = d.series.find(
							(seriesItem: any) => seriesItem.date.toString() === date.toString()
						);

						if (!seriesItem) return null;

						const isActive = d.label === hoveredItem;
						const isSelected = selection.includes(d.label);
						const formattedYValue = d.isOverlay
							? isCountOverlay
								? seriesItem?.totalCount || seriesItem?.includedCount
								: getFormattedValue(
										overlayIndexType === IndexType.Percentage
											? analysisTypes.Percentage
											: overlayAnalysisType,
										seriesItem[dataOverlayProp]
								  )
							: isCount
							? seriesItem?.totalCount || seriesItem?.includedCount
							: getFormattedValue(
									indexType === IndexType.Percentage ? analysisTypes.Percentage : analysisType,
									seriesItem[dataProp]
							  );
						return {
							yValue: seriesItem[d.isOverlay ? dataOverlayProp : dataProp],
							isActive: isActive || isSelected,
							formattedYValue,
							label: d.label,
							color: d.color,
							internal_id: `${d.label}-${groupIndex}`,
							tooltip: {
								dataPoint: d,
								seriesItem,
								columnProps: d.isOverlay ? overlayColumnProps : columnProps,
								interval,
								isBenchmark: false,
							},
							isOverlay: d.isOverlay,
							...(d.isEqualityIndex && { isEqualityIndex: d.isEqualityIndex }),
							...(d.isAttritionIndex && { isAttritionIndex: d.isAttritionIndex }),
						};
					})
					.filter(Boolean),
			};
		});
		return newData;
	}

	function createSubtableData(data: any, diversityAttributes: any): BarData[] {
		return data?.map((d: any, groupIndex: number): BarData => {
			return {
				xValue: d.id,
				formattedXValue: d.label,
				bars: diversityAttributes?.map((attr: any): BarData['bars'][0] => {
					let seriesItem: any = {
						date: new Date(subtable?.date),
					};

					subtable?.columnProps.forEach((cp: any) => {
						const lookupKey = attr.label.replace(/ /g, '-').toLowerCase() + '-' + cp.source;
						seriesItem[cp.source] = d[lookupKey];
					});

					let dataPropAdjusted =
						subtable?.analysisType === analysisTypes.Index && indexType === 'percentage'
							? 'calc(percentage*100)'
							: dataProp;

					const lookupKey = attr.label.replace(/ /g, '-').toLowerCase() + '-' + dataPropAdjusted;

					return {
						label: attr.label,
						yValue: d[lookupKey],
						formattedYValue:
							yAxisType === 'count'
								? d[lookupKey]
								: getFormattedValue(
										indexType === IndexType.Percentage ? analysisTypes.Percentage : analysisType,
										d[lookupKey]
								  ),
						color: attr.segment.tokens[0].color,
						internal_id: `${attr.label}-${groupIndex}`,
						tooltip: {
							dataPoint: {
								...attr,
								color: attr.segment.tokens[0].color,
							},
							seriesItem,
							columnProps: subtable?.columnProps,
							interval: subtable?.interval,
							isBenchmark: false,
						},
						isOverlay: d.isOverlay,
						...(d.isEqualityIndex && { isEqualityIndex: d.isEqualityIndex }),
						...(d.isAttritionIndex && { isAttritionIndex: d.isAttritionIndex }),
					};
				}),
			};
		});
	}

	let standardizedData: BarData[] = [];
	if (showSubtable) {
		standardizedData = createSubtableData(subtable?.data, subtable?.diversityAttributes);
	} else {
		standardizedData = createBarData(yAxisType === 'count', overlayYAxisType === 'count');
	}

	const selectXAxisGroup = (index: number) => {
		if (showSubtable) {
			dispatch(setCurrentSegmentIndex(index));
		} else {
			dispatch(selectCurrentTime(index));
		}
	};

	const xAxisSelection = showSubtable
		? subtable?.data?.[subtable?.currentSegmentIndex || 0]?.label
		: getFormattedDate(dates[currentIndex], interval);

	const selectedDate = typeof currentIndex !== 'undefined' && getFormattedDate(dates[currentIndex], interval);

	if (!xAxisSelection) return null;

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={
					showSubtable
						? subtable?.diversityAttributes?.map((d: any) => ({
								...d,
								color: d.segment.tokens[0].color,
								analysisName,
						  }))
						: data?.map((d: any) => ({
								...d,
								analysisName,
						  }))
				}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				overlayData={overlayData?.map((d: any) => ({
					...d,
					analysisName: overlayAnalysisName,
				}))}
				selection={selection}
				status={status}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]', reportId && '!h-full')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<BarChart
									analysisType={analysisType}
									dataOverlayProp={dataOverlayProp}
									dataProp={dataProp}
									employeeCountHidden={employeeCountHidden}
									height={height}
									hoveredItem={hoveredItem}
									indexType={indexType}
									lifecycle={lifecycle}
									metricOverlayLifecycle={metricOverlayLifecycle}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									overlayAnalysisType={overlayAnalysisType}
									overlayData={overlayData}
									overlayIndexType={overlayIndexType}
									overlayYAxisType={overlayYAxisType}
									reportId="explore-chart"
									selection={selection}
									selectXAxisGroup={selectXAxisGroup}
									standardizedData={standardizedData}
									width={width}
									xAxisSelection={xAxisSelection}
									yAxisType={yAxisType}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{events && <Events {...events} />}
			</ScrollableChartArea>
		</div>
	);
};

export default BarChartWrapper;
