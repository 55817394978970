import React, { PropsWithChildren } from 'react';
import { getMainColumn } from '../lib/chartUtils';
import { useAppSelector } from '../store/hooks';

export const ChartContext = React.createContext<any>({});

export const ChartContextProvider = ({ children, mode }: PropsWithChildren<any>) => {
	const { enterpriseName } = useAppSelector(state => state.account);
	const subtable = useAppSelector(state => state.explore.subtable);
	const { interval, showSubtable } = useAppSelector(state => state.explore.filter);
	const allEvents = useAppSelector(state => state.timeline.events);
	const isBenchmarkModalOpen = useAppSelector(state => state.benchmarkModal.isOpen);
	const { subtableChartType } = useAppSelector(state => state.explore.subtable);
	const isEmptyState = useAppSelector(
		state => !state.explore.filter.analysisName && state.explore.filter.mainSegments.length === 0
	);
	// @ts-ignore
	const deleteBenchmarkModalOpen = useAppSelector(state => state.deleteModal.isOpen);
	// @ts-ignore
	const deleteBenchmarkPending = useAppSelector(state => state.deleteModal.pending);
	let { lifecycle, generatePending, generateError, generateNoData, generateInvalidVariation, generated } =
		useAppSelector(state => state.explore.chart);
	const {
		lifecycle: lifecycleSubtable,
		generatePending: generatePendingSubtable,
		error,
		empty,
		invalidVariation,
		generated: generatedSubtable,
	} = useAppSelector(state => state.explore.subtable);
	if (showSubtable) {
		lifecycle = lifecycleSubtable || '';
		generatePending = generatePendingSubtable;
		generateNoData = empty;
		generateInvalidVariation = invalidVariation;
		generateError = error;
	}
	generated = generated || generatedSubtable;

	const mainColumn = useAppSelector(getMainColumn);
	const {
		indexType,
		data,
		dates,
		analysisType,
		chartType,
		currentIndex,
		confidence,
		columnProps,
		benchmark,
		analysisDisplayName,
		date,
		hoveredItem: globalHoveredItem,
		selection: globalSelection,
		status: globalStatus,
		subAnalysisFields,
		subAnalysisMapping,
		eventIds,
		yAxisType,
		allowIndexToggle,
		allowYAxisToggle,
		eventsModalOpen,
		showBenchmarkIcon,
		mainSegments,
		metricOverlay,
		intersectionAttributes,
	} = useAppSelector(state => state.explore.chart);

	return <ChartContext.Provider value={{}}>{children}</ChartContext.Provider>;
};
