import moment from 'moment';

export default function getDateFromPeriod(period: any, interval: string) {
	let detectedFormat;
	let format = 'YYYY MM';

	// This logic need in case of Dashboard Filter Interval change
	if (period.includes('-')) {
		detectedFormat = 'YYYY-MM';
	} else if (period.startsWith('Q')) {
		detectedFormat = '[Q]Q YYYY';
	} else if (period.length === 4) {
		detectedFormat = 'YYYY';
	} else {
		detectedFormat = 'MMM YYYY';
	}
	const date = moment.utc(period, detectedFormat);
	switch (interval) {
		case 'Year':
			format = 'YYYY';
			break;
		case 'Month':
			format = 'MMM YYYY';
			break;
		case 'Quarter':
			format = '[Q]Q YYYY';
			break;
	}
	return moment.utc(date, format);
}
