import InfoSection from '../../common/InfoSection';
import { getValue, getChangeValue, getColor, determineTrend } from '../utils';
import decimalFormatter from '../../lib/decimalFormatter';
import moment from 'moment';
import { useAppSelector } from '../../store/hooks';

const GoalInfo = () => {
	const {
		goal: { metricRegistrationRequest, metricTrajectoryValues, goalDescription, goalEndDate },
		chartData,
		unit,
	} = useAppSelector(state => state.measure.goal);

	const { desiredValue, currentValue, initialValue, previousValue } = metricTrajectoryValues[0];
	const trend = determineTrend({
		desiredValue,
		currentValue,
		initialValue,
		previousValue,
		trajectory: metricRegistrationRequest.metrics[0].trajectories[0].trajectory,
	});

	return (
		<>
			<InfoSection>
				<InfoSection.LeftBlock>Description</InfoSection.LeftBlock>
				<InfoSection.RightBlock>{goalDescription}</InfoSection.RightBlock>
			</InfoSection>
			<InfoSection>
				<InfoSection.LeftBlock>Goal</InfoSection.LeftBlock>
				<InfoSection.RightBlock>
					<InfoSection.Columns>
						<InfoSection.Column>
							{metricRegistrationRequest.metrics[0].analysisName} - {chartData[0].label}
						</InfoSection.Column>
						<InfoSection.Column>
							<InfoSection.Stat>
								<div>Goal</div>
								<div>
									{decimalFormatter(getValue(desiredValue))}
									{unit}
								</div>
							</InfoSection.Stat>
							<InfoSection.Stat>
								<div>Current status</div>
								<div>
									{decimalFormatter(getValue(currentValue))}
									{unit}
								</div>
							</InfoSection.Stat>

							<InfoSection.Stat>
								<div>Progress from last run</div>
								<div
									style={{
										color: getColor(trend),
									}}
								>
									{decimalFormatter(getChangeValue(previousValue, currentValue))}
									{unit}
								</div>
							</InfoSection.Stat>
							<InfoSection.Stat>
								<div>Start date</div>
								<div>
									{moment
										.utc(metricRegistrationRequest.metrics[0].trajectories[0].startDate)
										.format('MM/DD/YYYY')}
								</div>
							</InfoSection.Stat>
							<InfoSection.Stat>
								<div>End date</div>
								<div>{moment.utc(goalEndDate).format('MM/DD/YYYY')}</div>
							</InfoSection.Stat>
						</InfoSection.Column>
					</InfoSection.Columns>
				</InfoSection.RightBlock>
			</InfoSection>
		</>
	);
};

export default GoalInfo;
