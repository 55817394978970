import { useDispatch } from 'react-redux';
import { StaticContext, useHistory } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from '../../../common/Button';
import { addMessage } from '../../../common/actions';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import RbacActions from '../../../constants/RbacActions';
import usePermissions from '../../../hooks/usePermissions';
import TopLeftAction from '../../../layout/TopLeftAction';
import { track } from '../../../lib/segment';
import { previewRole, saveRole } from '../../../roles/createRole/actions';
import { clearNewRole } from '../../../roles/createRole/reducer';
import { useAppSelector } from '../../../store/hooks';

interface CreateRoleHeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const CreateRoleHeader = (props: CreateRoleHeaderProps) => {
	const {
		location: { state },
	} = props;
	const history = useHistory();
	const { newRole } = useAppSelector(state => state.roles);
	const { allRoles } = useAppSelector(state => state.admin.roles);
	const dispatch = useDispatch();

	const backToAdmin = () => {
		history.push('/admin/roles');
		dispatch(clearNewRole());
	};

	const handleSave = () => {
		if (newRole.id) {
			dispatch(addMessage(`${newRole.name} role has been updated`));
		} else {
			dispatch(addMessage(`"${newRole.name}" role has been saved`));
		}
		dispatch(saveRole());
		track(MixPanel.Events.AdminCreateRoleClickSaveRole, {
			role: { ...newRole },
		});
		backToAdmin();
	};
	const handlePreview = () => {
		dispatch(previewRole());
		track(MixPanel.Events.AdminCreateRoleClickPreviewRole, {
			role: { ...newRole },
		});
	};

	const handleCancel = () => {
		backToAdmin();
		track(MixPanel.Events.AdminCreateRoleClickCancel);
	};

	const nameExists = allRoles.some(r => r.name === newRole.name);

	const canSave =
		!!newRole.name &&
		!!newRole.featurePermissions.every(fp => fp.actions.length > 0 || fp.featureName === '*') &&
		(!nameExists || newRole.id);

	const canEdit = usePermissions({ actions: [RbacActions['Roles/Edit']] });

	return (
		<div className="create-role-header">
			<span className="create-role-header__title">
				<TopLeftAction scrollPos={state?.scrollPos} />
				{canEdit ? (newRole.id ? 'Edit Role' : 'Create Role') : newRole.name}
			</span>
			{canEdit && (
				<div className="create-role-header__buttons">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
						Cancel
					</Button>
					<Button onClick={handlePreview} disabled={!canSave} componentType={ButtonTypes.type.SECONDARY}>
						Preview
					</Button>
					<Button onClick={handleSave} disabled={!canSave} componentType={ButtonTypes.type.PRIMARY}>
						{newRole.id ? 'Update' : 'Save'}
					</Button>
				</div>
			)}
		</div>
	);
};

export default withRouter(CreateRoleHeader);
