import Menu from 'antd/lib/menu';
import { forwardRef, ForwardRefExoticComponent, PropsWithChildren, RefAttributes, useState } from 'react';

import Popover from '../../common/Popover';
import HMore from '../../icons/HMoreVertical';

interface IChartHeaderProps {
	children: any;
}

interface IChartHeader extends ForwardRefExoticComponent<IChartHeaderProps & RefAttributes<HTMLDivElement>> {
	Title: typeof Title;
	Actions: typeof Actions;
	Benchmark: typeof Benchmark;
	Comments: typeof Comments;
	ChartTypes: typeof ChartTypes;
	MoreMenu: typeof MoreMenu;
	MoreMenuItem: typeof MoreMenuItem;
	TrackingLive: typeof TrackingLive;
	Confidence: typeof Confidence;
}

const forwardRf = forwardRef<HTMLDivElement, IChartHeaderProps>(({ children }, ref) => {
	return (
		<div className="chart-header-wrapper" ref={ref}>
			<div className="chart-header">{children}</div>
		</div>
	);
});

const Title = ({ children }: PropsWithChildren) => {
	return <div className="chart-header__title">{children}</div>;
};

const Actions = ({ children }: PropsWithChildren) => {
	return <div className="chart-header__actions">{children}</div>;
};

const Confidence = ({ children }: PropsWithChildren) => {
	return <div className="explore-chart__header__confidence">{children}</div>;
};

const Benchmark = () => {
	//const {
	//showSubtable,
	//showBenchmarkIcon,
	//role ,
	//handleBenchmarkClick
	//} = useSelector((state) => state.benchmark);
	return <div className="chart-header__benchmark">Benchmark</div>;
};

const ChartTypes = () => {
	//const {
	//showSubtable,
	//allowChartTypeChange,
	//chartTypes,
	//handleChartTypeChange
	//} = useSelector((state: RootState) => state.chart);
	return <div className="chart-header__chart-types">Chart types</div>;
};

const Comments = () => {
	return <div className="chart-header__comments">Comments</div>;
};

const MoreMenu = ({ children }: PropsWithChildren) => {
	const [moreKeys, setMoreKeys] = useState([]);
	const [moreOpen, setMoreOpen] = useState(false);

	function handleMoreMenuSelect({ selectedKeys }: any) {
		setMoreKeys(selectedKeys);
	}

	function handleMoreClick() {
		setMoreOpen(!moreOpen);
		if (!moreOpen) {
			setMoreKeys([]);
		}
	}

	return (
		<Popover
			placement="bottomRight"
			visible={moreOpen}
			onVisibleChange={handleMoreClick}
			content={
				<Menu className="widget-menu" selectedKeys={moreKeys} onSelect={handleMoreMenuSelect}>
					{children}
				</Menu>
			}
			overlayClassName="popover--no-padding"
		>
			<HMore className="icon" width={24} height={24} />
		</Popover>
	);
};

const MoreMenuItem = ({ onClick, children }: any) => {
	return <Menu.Item onClick={onClick}>{children}</Menu.Item>;
};

const TrackingLive = () => {
	return <div className="chart-header__tracking-live"></div>;
};

const ChartHeader = {
	...forwardRf,
	Title,
	Actions,
	Benchmark,
	Comments,
	ChartTypes,
	MoreMenu,
	MoreMenuItem,
	TrackingLive,
	Confidence,
} as IChartHeader;

export default ChartHeader;
