import { Modal } from 'antd';
import axios from 'axios';
import { filesize } from 'filesize';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import Alert from '../../../common/Alert';
import Button from '../../../common/Button';
import Stack from '../../../common/Stack';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';
import Urls from '../../../constants/Urls';
import CSVDocument from '../../../icons/CSVDocument';
import UploadIcon from '../../../icons/UploadIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createStaticAudience, overwriteStaticAudience, updateStaticAudience } from '../edit/actions';
import { reset, setDescription, setFilepath, setTitle } from '../edit/slice';
import { SaveStaticAudienceRequest } from '../edit/types';

async function validateCSV(file: any): Promise<string> {
	if (!file) return '';

	const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();

	if (fileExtension && fileExtension !== 'csv') {
		return 'Please upload a CSV file';
	}

	return await new Promise((resolve, reject) => {
		Papa.parse(file, {
			complete: (results: any) => {
				results.data.forEach((d: string[], i: number) => {
					if (d.length !== 1 && d.length !== 2) {
						resolve(`Wrong number of columns in row ${i}`);
					}
				});
				resolve('');
			},
		});
	});
}

const StaticAudienceModal = () => {
	const dispatch = useAppDispatch();
	const ref = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<any>(null);
	const [fileUploadError, setFileUploadError] = useState<string>();
	const { modalOpen, audienceId, title, description, filePath, mode } = useAppSelector(state => state.audiences.edit);
	const tokenRef = useRef();

	function onReplace() {
		setFile(null);
		dispatch(setFilepath(''));
	}

	async function handleFileSelected(file: any) {
		if (file) {
			const error = await validateCSV(file);
			if (!error) {
				setFile(file);
				setFileUploadError('');
			} else {
				setFileUploadError(error);
			}
		}
	}

	function openFileUpload() {
		if (!ref.current) return;

		const fileUpload: HTMLInputElement | null = ref.current.querySelector('.csv-upload');
		fileUpload?.click();

		return;
	}

	function onDrop(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files.length) {
			handleFileSelected(files[0]);
		}
	}

	useEffect(() => {
		if (!ref.current) return;

		const fileUpload: HTMLInputElement | null = ref.current.querySelector('.csv-upload');

		if (!fileUpload) return;

		const onChangeHandler = (event: Event) => {
			const input = event.target as HTMLInputElement;
			if (input.files && input.files.length > 0) {
				handleFileSelected(input.files[0]);
			}
		};

		fileUpload.addEventListener('change', onChangeHandler);

		return () => {
			fileUpload.removeEventListener('change', onChangeHandler);
		};
	}, []);

	async function handleSave() {
		if (file) {
			const response = await axios.put(`${Urls.SurveyStudioApi}storage/create/url`);
			const reader = new FileReader();
			reader.onloadend = function (e) {
				const blob = new Blob([this.result as string], {
					type: 'text/csv',
				});
				const config = {
					headers: {
						'Content-Type': 'text/csv',
						Accept: '*/*',
					},
				};
				axios.put(response.data.url, blob, config).then(() => {
					const audience: SaveStaticAudienceRequest = {
						audienceId,
						title: title ?? '',
						description: description ?? '',
						uiElements: [],
						fileName: response.data.fileName,
					};
					if (mode === 'edit') {
						dispatch(overwriteStaticAudience(audience));
					} else {
						dispatch(createStaticAudience(audience));
					}
				});
			};
			reader.readAsText(file);
		} else if (mode === 'edit') {
			const audience: SaveStaticAudienceRequest = {
				title: title ?? '',
				description: description ?? '',
				uiElements: [],
				audienceId,
			};
			dispatch(updateStaticAudience(audience));
		}
	}

	return (
		<Modal
			open={modalOpen}
			width={800}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={() => dispatch(reset())}
			zIndex={3000}
			bodyStyle={{
				backgroundColor: 'var(--color-shade-h3)',
				borderRadius: '.8rem',
			}}
		>
			<Stack gap="3.2rem" ref={ref}>
				<Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
					<Stack gap="0.8rem">
						<h3 className="font-subtitle">Static Audience</h3>
						<span
							style={{
								color: 'var(--color-ui-50)',
							}}
						>
							Static audiences are created via CSV upload. To create your audience, prepare a CSV file
							with two columns. The first column must contain the employee ID. The second column can
							optionally include the employee's full name.
						</span>
					</Stack>
					<Stack gap=".8rem" flexDirection="row" alignItems="center">
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={() => dispatch(reset())}>
							Cancel
						</Button>
						<Button disabled={!!fileUploadError || !title} onClick={handleSave}>
							Save
						</Button>
					</Stack>
				</Stack>
				<Stack gap="1.6rem">
					<div>Upload CSV file</div>
					<input className="csv-upload" type="file" style={{ display: 'none' }} />
					{file || filePath ? (
						<FileItem file={file} onReplace={onReplace} />
					) : (
						<DragAndDrop onClick={openFileUpload} onDrop={onDrop} />
					)}
					{fileUploadError && <Alert message={fileUploadError} />}
				</Stack>
				<Stack gap="1.6rem">
					<div>Information</div>
					<MessageBox
						label="Name"
						value={title || ''}
						placeholder="Type name here"
						onChange={e => dispatch(setTitle(e.target.value))}
					/>
					<MessageBox
						label="Description (optional)"
						placeholder="Type description here"
						value={description || ''}
						onChange={e => dispatch(setDescription(e.target.value))}
					/>
				</Stack>
			</Stack>
		</Modal>
	);
};

const FileItem = ({ filePath, file, onReplace }: { filePath?: string; file: any; onReplace: () => void }) => {
	return (
		<Stack
			justifyContent="center"
			alignItems="flex-end"
			gap="1.6rem"
			style={{
				margin: '3.2rem 8rem',
			}}
		>
			<Stack
				flexDirection="row"
				alignItems="center"
				gap="1.6rem"
				style={{
					padding: '1.6rem',
					borderRadius: '.8rem',
					background: 'var(--color-shade-4)',
					width: '100%',
				}}
			>
				<CSVDocument />
				<Stack gap=".4rem">
					<div>{file?.name || filePath}</div>
					<div
						style={{
							color: 'var(--color-ui-50)',
						}}
					>
						{file?.size && filesize(file.size)}
					</div>
				</Stack>
			</Stack>
			<Button componentType={ButtonTypes.type.SECONDARY} onClick={onReplace}>
				Replace CSV
			</Button>
		</Stack>
	);
};
const MessageBox = ({
	label,
	value,
	placeholder,
	onChange,
}: {
	label: string;
	value: string;
	placeholder?: string;
	onChange: (e: any) => void;
}) => {
	return (
		<Stack
			gap=".8rem"
			style={{
				padding: '0 2px',
			}}
		>
			<div className="ds-label">{label}</div>
			<div className="ds-input">
				<TextField value={value} onChange={onChange} placeholder={placeholder} />
			</div>
		</Stack>
	);
};

const DragAndDrop = ({ onClick, onDrop }: any) => {
	const [isDragOver, setIsDragOver] = useState(false);

	return (
		<Stack
			style={{
				border: '1px solid var(--color-ui-10)',
				borderRadius: '.8rem',
				padding: '3.2rem',
				cursor: isDragOver ? 'move' : 'initial',
				background: isDragOver ? 'var(--color-shade-4)' : 'initial',
			}}
			onDragOver={(e: any) => {
				e.preventDefault();
				setIsDragOver(true);
			}}
			onDragLeave={(e: any) => {
				e.preventDefault();
				setIsDragOver(false);
			}}
			onDrop={onDrop}
			alignItems="center"
			justifyContent="center"
			gap=".8rem"
		>
			<span
				style={{
					color: 'var(--color-ui-75)',
				}}
			>
				<UploadIcon />
			</span>
			<div
				style={{
					color: 'var(--color-ui-75)',
				}}
			>
				Drag and drop CSV file here
			</div>
			<Divider />
			<Button onClick={onClick} componentType={ButtonTypes.type.SECONDARY}>
				Browse files
			</Button>
		</Stack>
	);
};

const Divider = () => {
	return (
		<Stack
			flexDirection="row"
			alignItems="center"
			gap=".8rem"
			justifyContent="center"
			style={{
				color: 'var(--color-ui-50)',
			}}
		>
			<div
				style={{
					height: '1px',
					minWidth: '100%',
					background: 'var(--color-ui-50)',
				}}
			/>
			<div>OR</div>
			<div
				style={{
					height: '1px',
					minWidth: '100%',
					background: 'var(--color-ui-50)',
				}}
			/>
		</Stack>
	);
};

export default StaticAudienceModal;
