import { History } from 'history';
import React from 'react';
import WithPermissions from '../../common/WithPermissions';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { clearFilters } from '../../editor/filter/actions';
import { track } from '../../lib/segment';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { getGoals } from '../actions';
import AddGoal from '../modals/AddGoal';
import AddTracker from '../modals/AddTracker';
import DeleteGoal from '../modals/DeleteGoal';
import InfoModal from '../modals/InfoModal';
import Goals from './Goals';
import Trackers from './Trackers';

const Measure = () => {
	const dispatch = useAppDispatch();
	const { activeTab, addGoalModalOpen, addTrackerModalOpen, measureInfoModalOpen } = useAppSelector(
		state => state.measure.settings
	);

	const { data: goals, toEdit, toDelete } = useAppSelector(state => state.measure.goals);

	React.useEffect(() => {
		if (!goals || goals.length === 0) {
			dispatch(getGoals());
		}
		dispatch(clearFilters());

		return () => {
			dispatch(clearFilters());
		};
	}, []);

	const getActiveTab = () => {
		switch (activeTab) {
			case 'tracker':
				return <Trackers />;
			default:
				return <Goals />;
		}
	};

	const ActiveTab = getActiveTab();

	React.useEffect(() => {
		track(MixPanel.Events.MeasureGoalsOverviewPageView);
	}, []);

	return (
		<WithPermissions actions={[RbacActions['Goals/View']]} showMessage={true}>
			<div className="measure">{ActiveTab}</div>
			<WithPermissions actions={[RbacActions['Goals/Edit']]}>
				{addGoalModalOpen && <AddGoal />}
				{addTrackerModalOpen && <AddTracker />}
				{toEdit && <AddGoal />}
				{toDelete && <DeleteGoal />}
			</WithPermissions>
			{measureInfoModalOpen && <InfoModal activeTab={activeTab} />}
		</WithPermissions>
	);
};

export default Measure;
