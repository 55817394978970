import { ISegmentData, ISubAnalysisField, ISubAnalysisMapping } from '../../types';
import { ITableData } from './types';
import formatValue from '../../lib/formatValue';

export const generateDefaultTemplate = (
	data: ISegmentData[],
	selection: string[],
	hoveredItem?: string
): ITableData => {
	const subAnalyses = data
		.map(item => item.pointInTime.subAnalyses.map(stat => stat.label))
		.flat()
		.filter((item, index, self) => self.indexOf(item) === index);

	function getSelectedState(
		side: 'left' | 'right',
		part: 'head' | 'body',
		subAnalysis?: string,
		segment?: string
	): boolean {
		const parsedSelection = selection.map(item => JSON.parse(item));

		switch (side) {
			case 'left': {
				switch (part) {
					case 'head':
						return false;
					case 'body':
						return parsedSelection.some(s => s.subAnalysis === subAnalysis);
					default:
						return false;
				}
			}
			case 'right': {
				switch (part) {
					case 'head': {
						return parsedSelection.some(s => s.segment === segment);
					}
					case 'body': {
						if (parsedSelection.some(s => s.subAnalysis) && !parsedSelection.some(s => s.segment)) {
							return parsedSelection.some(s => s.subAnalysis === subAnalysis);
						} else if (parsedSelection.some(s => s.subAnalysis) && parsedSelection.some(s => s.segment)) {
							return parsedSelection.some(s => s.subAnalysis === subAnalysis && s.segment && segment);
						} else if (!parsedSelection.some(s => s.subAnalysis) && parsedSelection.some(s => s.segment)) {
							return parsedSelection.some(s => s.segment === segment);
						}

						return false;
					}
					default: {
						return false;
					}
				}
			}
			default: {
				return false;
			}
		}
	}

	function getHoverState(
		side: 'left' | 'right',
		part: 'head' | 'body',
		subAnalysis?: string,
		segment?: string
	): boolean {
		const parsedHoveredItem = hoveredItem && JSON.parse(hoveredItem);

		switch (side) {
			case 'left': {
				switch (part) {
					case 'head':
						return false;
					case 'body':
						return parsedHoveredItem?.subAnalysis === subAnalysis;
					default:
						return false;
				}
			}
			case 'right': {
				switch (part) {
					case 'head': {
						return parsedHoveredItem?.segment === segment;
					}
					case 'body': {
						if (parsedHoveredItem?.subAnalysis && !parsedHoveredItem?.segment) {
							return parsedHoveredItem?.subAnalysis === subAnalysis;
						} else if (parsedHoveredItem?.subAnalysis && parsedHoveredItem?.segment) {
							return (
								parsedHoveredItem.subAnalysis === subAnalysis && parsedHoveredItem.segment === segment
							);
						} else if (!parsedHoveredItem?.subAnalysis && parsedHoveredItem?.segment) {
							return parsedHoveredItem.segment === segment;
						}

						return false;
					}
					default: {
						return false;
					}
				}
			}
			default: {
				return false;
			}
		}
	}

	const bodyData = subAnalyses.map((item, index) => {
		return [
			...data.map(d => {
				const subAnalysis = d.pointInTime.subAnalyses.find(s => s.label === item);
				return {
					segment: d.segment.tokens[0]?.text,
					hovered: getHoverState('right', 'body', subAnalyses[index], d.segment.tokens[0]?.text),
					selected: getSelectedState('right', 'body', subAnalyses[index], d.segment.tokens[0]?.text),
					label: formatValue({
						value: subAnalysis?.value as string,
						unit: subAnalysis?.unit,
					}),
					color: d.segment.tokens[0].color,
				};
			}),
		];
	});

	return {
		leftTable: {
			body: subAnalyses.map(item => ({
				columns: [
					{
						label: item,
						hovered: getHoverState('left', 'body', item, undefined),
						selected: getSelectedState('left', 'body', item, undefined),
					},
				],
			})),
		},
		rightTable: {
			head: data.map(item => ({
				segment: item.segment.tokens[0]?.text,
				hovered: getHoverState('right', 'head', undefined, item.segment.tokens[0]?.text),
				selected: getSelectedState('right', 'head', undefined, item.segment.tokens[0]?.text),
				label: item.segment.text,
				color: item.segment.tokens[0]?.color,
			})),
			body: bodyData.map(row => ({
				columns: row,
			})),
		},
		configuration: {
			segments: data.length,
			columns: data.length,
		},
	};
};

export const generateFunnelTemplate = (
	data: ISegmentData[],
	subAnalysisFields: ISubAnalysisField[],
	subAnalysisMapping: ISubAnalysisMapping,
	hoveredItem: string,
	selection: string[]
): ITableData => {
	const stages = Object.keys(subAnalysisFields)
		.map((name: any) => {
			return {
				name,
				...subAnalysisFields[name],
			};
		})
		.sort((a: any, b: any) => a.order - b.order)
		.filter((s: any) => s.unit === 'integer');

	return {
		leftTable: {
			body: stages.map(stage => ({
				columns: [
					{
						label: stage.name,
						hovered: !!hoveredItem,
						selected: selection.length > 0,
					},
				],
			})),
		},
		rightTable: {
			head: data.map(segment => ({
				segment: segment.segment.tokens[0]?.text,
				label: segment.segment.text,
				color: segment.segment.tokens[0]?.color,
				hovered: segment.segment.tokens[0]?.text === hoveredItem,
				selected: selection.includes(segment.segment.tokens[0]?.text),
			})),
			subHead: data
				.map(segment => {
					return [
						{
							segment: segment.segment.tokens[0]?.text,
							label: 'Count',
							hovered: segment.segment.tokens[0]?.text === hoveredItem,
							selected: selection.includes(segment.segment.tokens[0]?.text),
						},
						{
							segment: segment.segment.tokens[0]?.text,
							label: 'Rate',
							hovered: segment.segment.tokens[0]?.text === hoveredItem,
							selected: selection.includes(segment.segment.tokens[0]?.text),
						},
					];
				})
				.flat(),
			body: stages.map((stage, index) => ({
				columns: data
					.map(segment => {
						const countSubAnalysis = segment.pointInTime.subAnalyses.find(s => s.label === stage.name);

						const mapping = Object.keys(subAnalysisMapping).find(s => {
							return (
								stage.name === subAnalysisMapping[s].config.first &&
								stages[index + 1].name === subAnalysisMapping[s].config.second
							);
						});

						const rateSubAnalysisName = subAnalysisMapping[mapping as string]?.config.rate;

						const rateSubAnalysis = segment.pointInTime.subAnalyses.find(
							s => s.label === rateSubAnalysisName
						);

						return [
							{
								segment: segment.segment.tokens[0]?.text,
								label: formatValue({
									value: countSubAnalysis?.value as string,
									unit: countSubAnalysis?.unit,
								}),
								hovered: segment.segment.tokens[0]?.text === hoveredItem,
								selected: selection.includes(segment.segment.tokens[0]?.text),
							},
							{
								segment: segment.segment.tokens[0]?.text,
								label: formatValue({
									value: rateSubAnalysis?.value as string,
									unit: rateSubAnalysis?.unit,
								}),
								hovered: segment.segment.tokens[0]?.text === hoveredItem,
								selected: selection.includes(segment.segment.tokens[0]?.text),
							},
						];
					})
					.flat(),
			})),
		},
		configuration: {
			segments: data.length,
			columns: data.length * 2,
		},
	};
};
