import firebase from './firebase';

export default function getAccessToken() {
	const { currentUser } = firebase.auth();

	if (currentUser) {
		return currentUser.getIdToken();
	}
	return Promise.resolve('');
}
