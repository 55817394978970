import { Tooltip } from 'antd';
import classNames from 'classnames';
import { CSSProperties, MouseEvent, ReactNode, useState } from 'react';

interface SwitchProps {
	checked?: boolean;
	onChange?: (e: MouseEvent<HTMLInputElement>) => void;

	toggleSize?: number;
	toggleStyle?: CSSProperties;
	toggleIcon?: ReactNode;

	collapsed?: boolean;
	collapsedTooltip?: string;
}

const Switch = ({
	checked = false,
	onChange,
	toggleSize = 24,
	toggleStyle,
	toggleIcon,
	collapsed,
	collapsedTooltip,
}: SwitchProps) => {
	const [mouseIn, setMouseIn] = useState(false);

	if (collapsed) {
		return (
			<div onClick={onChange} className="switch__collapsed">
				<Tooltip title={collapsedTooltip} placement="right">
					<div
						className="circle circle__collapsed"
						style={{
							width: toggleSize,
							height: toggleSize,
							borderRadius: toggleSize / 2,
						}}
					>
						{toggleIcon}
					</div>
				</Tooltip>
			</div>
		);
	}

	return (
		<div
			className={classNames('switch', {
				'switch--checked': checked,
			})}
			onClick={onChange}
			style={{
				width: toggleSize * 2 + 4,
				height: toggleSize,
				borderRadius: toggleSize / 2,
			}}
			onMouseEnter={() => setMouseIn(true)}
			onMouseLeave={() => setMouseIn(false)}
		>
			<div
				className={classNames('circle', {
					circle__scale: mouseIn,
				})}
				style={{
					width: toggleSize,
					height: toggleSize,
					marginRight: checked ? 0 : 4,
					marginLeft: checked ? 4 : 0,
					borderRadius: toggleSize / 2,
					...(checked && toggleStyle),
				}}
			>
				{toggleIcon}
			</div>
		</div>
	);
};

export default Switch;
