import { combineReducers } from 'redux';
import categoriesReducer from './category/reducer';
import categoryEditReducer from './category/editReducer';
import questionsReducer from './question/reducer';
import questionEditReducer from './question/editReducer';
import surveysReducer from './template/reducer';
import surveyEditReducer from './template/editReducer';

const surveyReducer = combineReducers({
	categories: categoriesReducer,
	categoryEdit: categoryEditReducer,
	questions: questionsReducer,
	questionEdit: questionEditReducer,
	surveys: surveysReducer,
	surveyEdit: surveyEditReducer,
});

export default surveyReducer;
