import { setRole, previewRole, saveRole, copyRole } from './actions';

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ICreateRoleState } from './models';

const initialState: ICreateRoleState = {
	name: '',
	featurePermissions: [],
	analysisPermissions: [],
	hideEmployeeCount: false,
	pending: false,
	previewMode: false,
	id: undefined,
};

const createRoleSlice = createSlice({
	name: 'newRole',
	initialState: initialState,
	reducers: {
		setName: (state, action) => {
			state.name = action.payload;
		},

		setHideEmployeeCount: (state, action) => {
			state.hideEmployeeCount = action.payload;
		},
		setIsDuplicated: (state, action) => {
			state.isDuplicated = action.payload;
		},
		// sets feature permission to a blank permission object with the given id
		clearFeaturePermission: (state, action) => {
			state.featurePermissions = state.featurePermissions.map(feature => {
				if (feature.id === action.payload) {
					return {
						featureName: '',
						scopes: [],
						actions: [],
						resources: [],
						id: action.payload,
					};
				} else {
					return feature;
				}
			});
		},
		setAnalysisPermissions: (state, action) => {
			state.analysisPermissions = action.payload;
		},
		setFeaturePermissions: (state, action) => {
			state.featurePermissions = action.payload;
		},
		appendAnalysisPermission: (state, action) => {
			state.analysisPermissions.push(action.payload);
		},
		deleteAnalysisPermission: (state, action) => {
			state.analysisPermissions = state.analysisPermissions.filter(analysis => analysis.id !== action.payload);
		},
		appendFeaturePermission: (state, action) => {
			state.featurePermissions.push(action.payload);
		},
		removeFeaturePermission: (state, action) => {
			state.featurePermissions = state.featurePermissions.filter(feature => {
				return feature.id !== action.payload.id;
				// return feature.id !== action.payload.id;
			});
		},
		addFeaturePermission: (state, action) => {
			const targetFeature = state.featurePermissions.find(feature => feature.id === action.payload.id);

			if (!targetFeature) state.featurePermissions.push(action.payload);
			else {
				state.featurePermissions = state.featurePermissions.map(feature => {
					if (feature.id === action.payload.id) {
						return {
							...feature,
							...action.payload,
						};
					} else {
						return feature;
					}
				});
			}
		},

		removeAnalysisPermission: (state, action) => {
			state.analysisPermissions = state.analysisPermissions.filter(analysis => analysis.id !== action.payload);
		},
		removeFilters: (state, action) => {
			state.analysisPermissions = state.analysisPermissions.map(analysis => {
				if (analysis.id === action.payload) {
					return {
						...analysis,
						filters: [],
					};
				} else {
					return analysis;
				}
			});
		},
		removeSegments: (state, action) => {
			state.analysisPermissions = state.analysisPermissions.map(analysis => {
				if (analysis.id === action.payload) {
					return {
						...analysis,
						segments: [],
					};
				} else {
					return analysis;
				}
			});
		},
		removeBreakdowns: (state, action) => {
			state.analysisPermissions = state.analysisPermissions.map(analysis => {
				if (analysis.id === action.payload) {
					return {
						...analysis,
						breakdowns: [],
					};
				} else {
					return analysis;
				}
			});
		},
		createAnalysisPermission: (state, action) => {
			const existingAnalysis = state.analysisPermissions.find(analysis => analysis.id === action.payload.id);

			if (!existingAnalysis) {
				state.analysisPermissions.push(action.payload);
			} else {
				console.warn(`Analysis already exists, use update instead for id: ${action.payload.id}`);
			}
		},
		updateAnalysises: (state, action) => {
			const analysisPermissionIndex = state.analysisPermissions.findIndex(
				analysis => analysis.id === action.payload.id
			);

			if (analysisPermissionIndex === undefined) {
				console.warn(`Analysis not found for id: ${action.payload.id}`);
			} else {
				state.analysisPermissions[analysisPermissionIndex].analysises = action.payload.analysises;
			}
		},
		updateSegments: (state, action) => {
			const analysisPermissionIndex = state.analysisPermissions.findIndex(
				analysis => analysis.id === action.payload.id
			);

			if (analysisPermissionIndex === undefined) {
				console.warn(`Analysis not found for id: ${action.payload.id}`);
			} else {
				state.analysisPermissions[analysisPermissionIndex].segments = action.payload.segments;
			}
		},
		updateFilters: (state, action) => {
			const analysisPermissionIndex = state.analysisPermissions.findIndex(
				analysis => analysis.id === action.payload.id
			);

			if (analysisPermissionIndex === undefined) {
				console.warn(`Analysis not found for id: ${action.payload.id}`);
			} else {
				state.analysisPermissions[analysisPermissionIndex].filters = action.payload.filters;
			}
		},
		updateBreakdowns: (state, action) => {
			const analysisPermissionIndex = state.analysisPermissions.findIndex(
				analysis => analysis.id === action.payload.id
			);

			if (analysisPermissionIndex === undefined) {
				console.warn(`Analysis not found for id: ${action.payload.id}`);
			} else {
				state.analysisPermissions[analysisPermissionIndex].breakdowns = action.payload.breakdowns;
			}
		},
		toggleAnalysisPermissionEmployeeCount: (state, action) => {},

		clearNewRole: state => {
			state.name = '';
			state.featurePermissions = [];
			state.analysisPermissions = [];
			state.id = undefined;
		},
	},
	extraReducers: builder => {
		builder.addCase(copyRole.pending, (state, action) => {
			state.pending = true;
		});
		builder.addCase(copyRole.fulfilled, (state, action) => {
			state.pending = false;
			state.name = action.payload.name;
			state.analysisPermissions = action.payload.analysisPermissions;
			state.featurePermissions = action.payload.featurePermissions;
			state.hideEmployeeCount = action.payload.hideEmployeeCount;
		});
		builder.addCase(copyRole.rejected, (state, action) => {
			state.error = action.payload;
			state.pending = false;
		});
		builder.addCase(setRole.pending, (state, action) => {
			state.pending = true;
		});
		builder.addCase(setRole.fulfilled, (state, action) => {
			state.pending = false;
			state.name = action.payload.name;
			state.id = action.payload.id;
			state.hideEmployeeCount = action.payload.hideEmployeeCount;
			state.featurePermissions = action.payload.featurePermissions;
			state.analysisPermissions = action.payload.analysisPermissions;
		});
		builder.addCase(setRole.rejected, (state, action) => {
			state.error = action.payload;
			state.pending = false;
		});
		builder.addCase(saveRole.pending, (state, action) => {
			state.pending = true;
		});
		builder.addCase(saveRole.fulfilled, (state, action) => {
			state.pending = false;
		});
		builder.addCase(saveRole.rejected, (state, action) => {
			state.error = action.payload;
			state.pending = false;
		});
		builder.addCase(previewRole.fulfilled, (state, action) => {
			state.previewMode = true;
		});
	},
});

export const {
	setName,
	clearNewRole,
	setHideEmployeeCount,
	setFeaturePermissions,
	appendFeaturePermission,
	removeFeaturePermission,
	addFeaturePermission,
	setAnalysisPermissions,
	appendAnalysisPermission,
	removeAnalysisPermission,
	createAnalysisPermission,
	updateAnalysises,
	updateSegments,
	updateFilters,
	updateBreakdowns,
	deleteAnalysisPermission,
	removeSegments,
	removeFilters,
	removeBreakdowns,
	clearFeaturePermission,
	setIsDuplicated,
} = createRoleSlice.actions;

export default createRoleSlice.reducer;
