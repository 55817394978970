import React, { useState } from 'react';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { saveSurveyTemplate, setActiveTab } from './editReducer';
import qs from 'qs';
import uploadImage from '../../../lib/uploadImage';
import * as uuid from 'uuid';

export default function TemplateEditHeader({ history, location }: RouteComponentProps) {
	const dispatch = useAppDispatch();
	let {
		title,
		enterpriseId,
		description,
		image,
		emailSubject,
		emailTitle,
		emailBody,
		welcomeTitle,
		welcomeDesc,
		thankTitle,
		thankDesc,
		thankImage,
		imageId,
		imageUrl,
		thankImageUrl,
		questions,
		questionOrder,
	} = useAppSelector(state => state.datastudio.survey.surveyEdit.surveyToEdit);
	const { activeTab } = useAppSelector(state => state.datastudio.survey.surveyEdit);
	const disableSave = !title || questions.length === 0;

	const { globalSurveyId } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});

	function handleCancel() {
		history.goBack();
	}

	async function handleSave() {
		const categories: any = [];
		questions.forEach(q => {
			const category = categories.find((c: any) => c.globalCategoryId === q.globalCategoryId);
			if (category) {
				category.globalQuestionIds.push(q.globalQuestionId);
			} else {
				categories.push({
					globalCategoryId: q.globalCategoryId,
					globalQuestionIds: [q.globalQuestionId],
				});
			}
		});

		// Have to create a image ID because before we create a survey, we don't have an ID to use for the image
		imageId = imageId || uuid.v4();
		if (image) {
			imageUrl = await uploadImage('survey-images', imageId, image);
		}
		if (thankImage) {
			thankImageUrl = await uploadImage('survey-thank-images', imageId, thankImage);
		}

		let enterpriseIdObj = enterpriseId ? { enterpriseId } : {};

		await dispatch(
			saveSurveyTemplate({
				globalSurveyId: globalSurveyId as string,
				title,
				description,
				isPublished: false,
				isPrivate: !!enterpriseId,
				categories,
				uiElements: [
					{
						key: 'imageUrl',
						value: imageUrl || '',
					},
					{
						key: 'emailSubject',
						value: emailSubject,
					},
					{
						key: 'emailTitle',
						value: emailTitle,
					},
					{
						key: 'emailBody',
						value: emailBody,
					},
					{
						key: 'welcomeTitle',
						value: welcomeTitle,
					},
					{
						key: 'welcomeDesc',
						value: welcomeDesc,
					},
					{
						key: 'thankTitle',
						value: thankTitle,
					},
					{
						key: 'thankDesc',
						value: thankDesc,
					},
					{
						key: 'thankImageUrl',
						value: thankImageUrl ?? '',
					},
					{
						key: 'imageId',
						value: imageId,
					},
					{
						key: 'questionOrder',
						value: JSON.stringify(questionOrder),
					},
				],
				...enterpriseIdObj,
			})
		);
	}

	return (
		<div className="survey-header">
			<div className="survey-header__actions-left">
				<Button
					componentType={ButtonTypes.type.SECONDARY}
					activated={activeTab === 'surveydetails'}
					onClick={() => dispatch(setActiveTab('surveydetails'))}
				>
					Campaign details
				</Button>
				<Button
					componentType={ButtonTypes.type.SECONDARY}
					activated={activeTab === 'questionnaire'}
					onClick={() => dispatch(setActiveTab('questionnaire'))}
				>
					Questionnaire
				</Button>
			</div>
			<div className="survey-header__actions">
				<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
					Cancel
				</Button>
				<Button componentType={ButtonTypes.type.PRIMARY} disabled={disableSave} onClick={handleSave}>
					Save
				</Button>
			</div>
		</div>
	);
}
