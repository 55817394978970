import { useHistory } from 'react-router';

import { back, setScrollPosition } from '../common/actions';
import { useAppDispatch } from '../store/hooks';

export const useOnClickHandler = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	return (type: 'default' | 'back' | 'exit', to?: string, scrollPos?: number) => {
		if (scrollPos) {
			dispatch(setScrollPosition(scrollPos));
		}

		if (scrollPos) {
			dispatch(setScrollPosition(scrollPos));
		}

		if (type === 'back') {
			dispatch(back());
		} else if (type === 'exit') {
			history.push(to || '/');
		} else {
			if (location.pathname === '/') {
				window.scrollTo({
					left: 0,
					top: 0,
					behavior: 'smooth',
				});
			} else {
				history.push('/');
			}
		}
	};
};
