const List2 = ({ fill = 'white', size = 19 }: { fill?: string; size?: number }) => (
	<svg width={size} height={size} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			id="List2"
			d="M0.5 16V14.2857H20.5V16H0.5ZM0.5 11.2286V9.51429H20.5V11.2286H0.5ZM0.5 6.48571V4.77143H20.5V6.48571H0.5ZM0.5 1.71429V0H20.5V1.71429H0.5Z"
			fill={fill}
		/>
	</svg>
);

export default List2;
