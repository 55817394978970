import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
	const fill = props.fill || '#FF9133';
	const width = props.width || '20';
	const height = props.height || '20';

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<path
				fill={fill}
				d="M9.01273 15.4375C8.8138 15.4375 8.64734 15.3705 8.51337 15.2365C8.37939 15.1026 8.3124 14.9361 8.3124 14.7372V10.1546L3.88061 4.5247C3.72837 4.32171 3.7063 4.1106 3.81439 3.89137C3.92248 3.67214 4.10491 3.56253 4.36169 3.56253H14.6381C14.8949 3.56253 15.0773 3.67214 15.1854 3.89137C15.2935 4.1106 15.2714 4.32171 15.1192 4.5247L10.6874 10.1546V14.7372C10.6874 14.9361 10.6204 15.1026 10.4864 15.2365C10.3524 15.3705 10.186 15.4375 9.98703 15.4375H9.01273Z"
			/>
		</svg>
	);
};
export default SvgComponent;
