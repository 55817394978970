import { useRef } from 'react';
import cn from '../../lib/cn';
import DashboardOptions from './DashboardOptions';
import Insights from './Insights';
import MessageBubble from './MessageBubble';
import RegenerateActions from './RegenerateActions';
import ReportSelect from './ReportSelect';
import ScrollBar from './ScrollBar';
import { Message } from './types';

const MessageComponents: any = {
	MessageBubble: MessageBubble,
	DashboardOptions: DashboardOptions,
	ReportSelect: ReportSelect,
	Insights: Insights,
	RegenerateActions: RegenerateActions,
};

export default function Chat({ messages, ...rest }: { messages: Message[]; [key: string]: any }) {
	const ref = useRef<any>();
	const renderMessage = (message: Message) => {
		switch (message.componentType) {
			case 'div': {
				return (
					<div key={message.id} {...message.props}>
						{message.children}
					</div>
				);
			}
			case 'component': {
				const Component = MessageComponents[message.component];

				return (
					<Component key={message.id} {...message.props} {...rest}>
						{message.children}
					</Component>
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<div
			className={cn(
				'flex-1 overflow-auto hide-scrollbar flex flex-col gap-[.8rem] pt-[3.2rem] w-full relative pr-[3.2rem] group'
			)}
			ref={ref}
		>
			{messages.map(message => renderMessage(message))}
			<ScrollBar containerRef={ref} />
		</div>
	);
}
