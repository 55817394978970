import classNames from 'classnames';
import { createRef, useLayoutEffect, useRef, useState } from 'react';

import DropdownArrow from '../../icons/DropdownArrow';
import { Attribute } from '../../types';

export function isSegmentChecked(filter: Attribute) {
	return filter.attributeValues.some(a => a.checked);
}

interface DashboardFiltersListProps {
	onChange: (segmentName: string, clicked?: boolean) => void;
	filters: Attribute[];
}

interface SegmentProps {
	onChange: (segmentName: string, clicked?: boolean) => void;
	filter: Attribute;
}

export default function DashboardFiltersList({ onChange, filters }: DashboardFiltersListProps) {
	let listHeight = window.screen.availHeight * 0.75 - 160;
	const maxRowCount = Math.floor(listHeight / 28);
	listHeight = maxRowCount * 28;

	const listRef = useRef<HTMLUListElement>(null);

	const [elRefs, setElRefs] = useState([]);

	useLayoutEffect(() => {
		setElRefs(elRefs =>
			Array(filters.length)
				.fill(createRef())
				.map((_, i) => elRefs[i] || createRef())
		);
	}, [filters]);

	useLayoutEffect(() => {
		if (!elRefs || !elRefs.length) return;

		const checkedIndexes = filters.filter(filter => filter.checked).map(filter => filters.indexOf(filter));

		let needsToScroll = true;
		checkedIndexes.forEach(index => {
			if (!listRef.current) return;
			if (index * 28 < listRef.current?.clientHeight) {
				needsToScroll = false;
			}
		});

		if (checkedIndexes.length > 0) {
			if (needsToScroll) {
				elRefs[
					checkedIndexes[checkedIndexes.length - 1]
					// @ts-ignore
				].current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'center',
				});
			}
		}
	}, [filters, elRefs]);

	return (
		<div className="filter-dropdown__list">
			<ul style={{ maxHeight: listHeight }} ref={listRef}>
				{filters.map(filter => !filter.removed && <Segment onChange={onChange} filter={filter} />)}
			</ul>
		</div>
	);
}

const Segment = ({ onChange, filter }: SegmentProps) => (
	<li
		key={filter.attributeName}
		onClick={() => {
			if (!filter.disabled) {
				onChange(filter.attributeName);
			}
		}}
		className={classNames({
			'l-active': isSegmentChecked(filter) || filter.active,
			'l-disabled': filter.disabled,
		})}
	>
		<span>{filter.attributeName}</span>
		<span className="arrow">
			<DropdownArrow
				width={18}
				height={18}
				style={
					filter.active
						? {
								transform: 'rotate(-90deg)',
						  }
						: {}
				}
			/>
		</span>
	</li>
);
