import React, { useState, useEffect } from 'react';
import TextField from '../../../common/TextField';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { fetchQuestions } from '../question/reducer';
import { fetchCategories } from '../category/reducer';
import LottieAnimation from '../../../common/LottieAnimation';
import Loader from '../../../lottie/graph-loader.json';
import Question from './Question';

export default function Questions() {
	const dispatch = useAppDispatch();
	const [search, setSearch] = useState<string>('');
	const { data, pending, loaded } = useAppSelector(state => state.datastudio.survey.questions);
	const { data: categories, loaded: categoriesLoaded } = useAppSelector(state => state.datastudio.survey.categories);

	const filteredData = data.filter(d => d.title.toLowerCase().includes(search.toLowerCase()));

	useEffect(() => {
		if (!loaded) {
			dispatch(fetchQuestions());
		}
		if (!categoriesLoaded) {
			dispatch(fetchCategories());
		}
	}, []);

	return (
		<div className="survey-content">
			<div className="search-container">
				<TextField
					placeholder="Search question"
					isSearch
					value={search}
					onChange={e => setSearch(e.target.value)}
					showClose
					onClose={() => setSearch('')}
				/>
			</div>
			{!!search && !filteredData.length && (
				<div className="empty-search-container">Sorry, no questions match your search query</div>
			)}
			{pending ? (
				<div className="ds-loader">
					<LottieAnimation
						animation={Loader}
						autoplay={true}
						loop={true}
						width={100}
						height={100}
					></LottieAnimation>
				</div>
			) : (
				<div className="questions-grid">
					{filteredData.map(d => (
						<Question categories={categories} question={d} />
					))}
				</div>
			)}
		</div>
	);
}
