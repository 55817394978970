import PropTypes from 'prop-types';
import classNames from 'classnames';
import SpinnerDefault from './SpinnerDefault';

export default function Button({
	activated,
	children,
	circle,
	classes,
	componentType,
	disabled,
	large,
	loading,
	navigation,
	onClick,
	selected,
	selectedStyle,
	style,
	tabIndex,
	testId,
	type,
	...rest
}: any) {
	return (
		<button
			style={{
				...style,
			}}
			type={type}
			tabIndex={tabIndex}
			data-test={testId}
			className={classNames('button', `button--${componentType}`, ...classes, {
				'button--pending': loading,
				'button--large': large,
				'button--navigation': navigation,
				'button--circle': circle,
				[`button--${selectedStyle}-selected`]: selected && !activated,
				[`button--activated`]: activated,
			})}
			onClick={e => {
				e.preventDefault();
				onClick(e);
			}}
			disabled={disabled}
			{...rest}
		>
			{loading ? (
				<div className="button__spinner">
					<SpinnerDefault isWhite size={16} />
				</div>
			) : null}
			{children}
		</button>
	);
}

Button.propTypes = {
	activated: PropTypes.bool,
	children: PropTypes.node,
	circle: PropTypes.bool,
	classes: PropTypes.array,
	componentType: PropTypes.string,
	danger: PropTypes.any,
	disabled: PropTypes.bool,
	large: PropTypes.bool,
	loading: PropTypes.bool,
	navigation: PropTypes.bool,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
	selectedStyle: PropTypes.string,
	style: PropTypes.object,
	tabIndex: PropTypes.number,
	type: PropTypes.string,
};

Button.defaultProps = {
	classes: [],
	componentType: 'primary',
	large: true,
	navigation: false,
	onClick: () => {},
	selected: false,
	selectedStyle: 'default',
	testId: 'button',
	type: 'button',
};
