import React from 'react';

interface CaretLeftProps {
	size?: number;
}

const CaretLeft = ({ size = 6 }: CaretLeftProps) => {
	const ratio = 4 / 6;
	const width = size * ratio;
	const height = size;
	return (
		<svg width={width} height={height} viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4 0L4 6L-1.31134e-07 3L4 0Z" fill="currentColor" fillOpacity="0.5" />
		</svg>
	);
};

export default CaretLeft;
