import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreatePasswordForm from './CreatePasswordForm';
import UserInfoForm from './UserInfoForm';
import { completeSetup } from './actions';
import { addMessage } from '../common/actions';
import MessageBar from '../common/MessageBar';
import MixPanel from '../constants/MixPanel';
import { page, track } from '../lib/segment';

class Welcome extends Component {
	constructor() {
		super();
		this.state = {
			showUserInfo: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.loginError !== prevState.loginError) {
			return {
				showUserInfo: !nextProps.loginError && prevState.showUserInfo,
				loginError: nextProps.loginError,
			};
		}
		return null;
	}

	componentDidMount() {
		page(MixPanel.Pages.Welcome);
	}

	handleNext(email, tempPassword, newPassword) {
		track(MixPanel.Events.WelcomeNext);
		this.setState({ showUserInfo: true, email, tempPassword, newPassword });
	}

	handleDone(firstName, lastName, image, lightMode) {
		track(MixPanel.Events.WelcomeDone);
		const { email, tempPassword, newPassword } = this.state;
		this.props.onSetupComplete(email, tempPassword, newPassword, firstName, lastName, image, lightMode);
	}

	render() {
		const { pending, addMessage } = this.props;
		return (
			<div className="welcome-container">
				{this.state.showUserInfo ? (
					<UserInfoForm pending={pending} onDone={this.handleDone.bind(this)} addMessage={addMessage} />
				) : (
					<CreatePasswordForm onNext={this.handleNext.bind(this)} />
				)}
				<MessageBar />
			</div>
		);
	}
}

Welcome.propTypes = {
	onSetupComplete: PropTypes.func,
	history: PropTypes.object,
	pending: PropTypes.bool,
	loginError: PropTypes.bool,
	addMessage: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		pending: state.auth.setupPending,
		loginError: state.auth.loginError,
	};
}

const dispatchProps = {
	onSetupComplete: completeSetup,
	addMessage,
};

export default connect(mapStateToProps, dispatchProps)(Welcome);
