import { MouseEvent, useEffect, useReducer, useRef } from 'react';

import Close from '../icons/Close';
import DropdownArrow from '../icons/DropdownArrow';
import cn from '../lib/cn';
import Button from '../common/Button';
import DropdownMachine from '../common/DropdownMachine';
import DropdownMenu from '../common/DropdownMenu';
import ButtonTypes from '../constants/ButtonTypes';

interface DropdownProps {
	placeholder?: string;
	options: any[];
	selectedOption: any;
	showIcon?: boolean;
	onClick: ([key]: any) => void;
	disabled?: boolean;
	usePlaceholder?: boolean;
	perfectScrollbar?: boolean;
	type?: string;
	displayTags?: boolean;
	selected?: boolean;
}

const SegmentDropdown = ({
	placeholder,
	options,
	selectedOption,
	showIcon,
	selected,
	onClick,
	disabled,
	usePlaceholder,
	perfectScrollbar,
	type,
}: DropdownProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [state, dispatch] = useReducer(DropdownMachine, {
		status: 'closed',
		context: {
			openUp: false,
			filteredOptions: options,
			searchValue: getOption(selectedOption, 'label'),
			scrollTop: 0,
		},
	});
	const {
		context: { openUp, filteredOptions, searchValue, scrollTop },
	} = state;

	const menuScrollTop = useRef(null);

	// TODO: This may not be required!
	const matchingOption = options.find(o => getOption(o, 'label') === getOption(selectedOption, 'label'));
	const IconComponent = matchingOption && showIcon ? matchingOption.icon : null;

	// on mount
	useEffect(() => {
		shouldOpenUp();

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		dispatch({
			type: 'SET_CONTEXT',
			payload: {
				filteredOptions: options,
				searchValue: getOption(selectedOption, 'label'),
				scrollTop: 0,
			},
		});
	}, [options]);

	useEffect(() => {
		dispatch({
			type: 'SET_CONTEXT',
			payload: {
				searchValue: getOption(selectedOption, 'label'),
			},
		});
	}, [selectedOption]);

	useEffect(() => {
		if (state.status === 'opened') {
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('mousedown', handleClickOutside);
			window.addEventListener('keydown', handleKeyDown, true);
			if (ref.current) {
				ref.current.scrollTop = menuScrollTop.current || 0;
			}
		} else {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('keydown', handleKeyDown);
		}
	}, [state.status]);

	function autoComplete(value: string) {
		if (value.length > 0) {
			dispatch({
				type: 'SET_CONTEXT',
				payload: {
					filteredOptions: options.filter((option: any) => {
						return getOption(option, 'label').toLowerCase().includes(value.toLowerCase());
					}),
				},
			});
		} else {
			dispatch({
				type: 'SET_CONTEXT',
				payload: {
					filteredOptions: [...options],
				},
			});
		}
	}

	function handleKeyDown(e: any) {
		if (e.keyCode == 27) {
			if (state.status === 'opened') {
				e.stopPropagation();
				dispatch({
					type: 'CLOSE',
				});
			}
		}
	}

	function handleClickOutside(e: any) {
		if (state.status === 'opened') {
			if (ref.current && !ref.current.contains(e.target)) {
				e.stopPropagation();
				dispatch({
					type: 'CLOSE',
				});
			}
		}
	}

	function handleSelect(option: any, e: any) {
		e.stopPropagation();
		e.preventDefault();

		const validOption = options.find((o: any) => getOption(o, 'label') === getOption(option, 'label'));

		if (validOption) {
			onClick(getOption(validOption, 'value'));
		}
		dispatch({
			type: 'CLOSE',
		});
	}

	function handleScroll() {
		const scrollTopLatest = window.pageYOffset || document.documentElement.scrollTop;
		if (Math.abs(scrollTopLatest - scrollTop) > 5) {
			setOpen(false);
		}
	}

	function setOpen(isOpen: boolean) {
		if (isOpen) {
			dispatch({
				type: 'OPEN',
				payload: {
					scrollTop: window.pageYOffset || document.documentElement.scrollTop,
					openUp: shouldOpenUp(),
				},
			});
		} else {
			dispatch({
				type: 'CLOSE',
				payload: {
					scrollTop: 0,
				},
			});
		}
	}

	function shouldOpenUp() {
		if (!ref.current) return;
		let offsetParent = ref.current.offsetParent as HTMLElement;
		let top = ref.current.offsetTop;
		while (offsetParent) {
			top += offsetParent.offsetTop;
			offsetParent = offsetParent.offsetParent as HTMLElement;
		}
		const maxHeight = 1000;
		const dropdownHeight = Math.min(options.length * 40 + 16, maxHeight + 24);

		const dropdownPadding = 32;
		const openUp = 1 * (top + dropdownHeight + dropdownPadding) > window.innerHeight + window.scrollY;
		return openUp;
	}

	function handleClearSelection(e: MouseEvent) {
		e.stopPropagation();
		e.preventDefault();
		onClick(undefined);
		dispatch({
			type: 'CLEAR',
			payload: {
				searchValue: '',
				filteredOptions: options,
			},
		});
	}

	function getOption(option: any, type: string) {
		if (typeof option === 'string') {
			return option;
		} else if (typeof option === 'object') {
			return option[type];
		} else {
			return placeholder;
		}
	}

	return options.length ? (
		<div
			className={cn('dropdown', {
				'dropdown--disabled': disabled,
				'dropdown--placeholder': !selectedOption,
			})}
			ref={ref}
		>
			<Button
				type={type}
				componentType={ButtonTypes.type.SECONDARY}
				large={true}
				onClick={() => setOpen(state.status === 'closed' ? true : false)}
				activated={state.status === 'opened'}
				selected={selected}
				selectedStyle={ButtonTypes.selectedStyle.DEFAULT}
				disabled={disabled}
			>
				{IconComponent && (
					<IconComponent
						className={cn(
							'w-[3.5rem] h-[3.5rem] rounded-[1.8rem] bg-ui-100 text-shade-h1 ml-[-1.6rem] p-[4px]'
						)}
					/>
				)}
				<span>{usePlaceholder ? placeholder : getOption(selectedOption, 'label') || placeholder}</span>
				<DropdownArrow width={18} height={18} />
			</Button>
			<DropdownMenu
				options={filteredOptions}
				marginLeft={0}
				isOpen={state.status === 'opened'}
				height={0}
				selectedOption={selectedOption}
				openUp={openUp}
				perfectScrollbar={perfectScrollbar}
				getOption={getOption}
				onClick={handleSelect}
			/>
		</div>
	) : null;
};

// const DropdownMenu = ({}) =>{
//
// }

export default SegmentDropdown;
