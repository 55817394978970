import React from 'react';

const Info = (props: any) => (
	<svg viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
		<path d="M8.49 6.5V11.3H7.5V6.5H8.49ZM7.5 5.5V4.5H8.49V5.5H7.5Z" fill="currentColor" />
		<circle cx="8" cy="8" r="5.5" stroke="currentColor" />
	</svg>
);

export default Info;
