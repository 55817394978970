import { useContext, Fragment } from 'react';
import { LineChartContext } from './LineChart';
import { NodeGroup } from 'react-move';
import { ChartContext } from '../../contexts/ChartContext';
import Constants from '../../constants/Constants';
import getInterpolator from '../../lib/getInterpolator';
import FontFamilies from '../../constants/FontFamilies';
import measureText from '../../lib/measureText';
import { useAppSelector } from '../../store/hooks';
import CanvasFonts from '../../constants/CanvasFonts';
import { getChangeValue, getColor, determineTrend } from '../../measure/utils';

const GoalValues = () => {
	const {
		chartState: { mode },
		onHover,
		onHoverEnd,
		onToggle,
	} = useContext(ChartContext);

	const { chartData, currentIndex, dataProp, goal, unit } = useAppSelector(state => {
		switch (mode) {
			case 'measure': {
				return state.measure.goal;
			}
			default:
				return {} as any;
		}
	});

	const { valueArray, isFaded, xScale, yScale } = useContext(LineChartContext);
	const baseClass = 'linechartv2';

	function getIntersectingRange(): [number, number] {
		const goalLineY = yScale(goal.metricTrajectoryValues[0].desiredValue);
		return [goalLineY - 4, goalLineY + 8];
	}

	return (
		<NodeGroup
			keyAccessor={d => d.label}
			data={valueArray}
			start={() => {
				return {
					opacity: 0,
				};
			}}
			enter={() => {
				return {
					opacity: [1],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				};
			}}
			update={d => {
				return {
					opacity: isFaded(d) ? [0.24] : [1],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				};
			}}
			interpolation={getInterpolator}
		>
			{nodes => (
				<g className={`${baseClass}__values`}>
					{nodes.map(({ state, data, key }) => {
						const previousValue =
							currentIndex === 0 ? undefined : chartData[0].series[currentIndex - 1][dataProp];
						const currentValue = chartData[0].series[currentIndex][dataProp];
						const changeValue = getChangeValue(previousValue, currentValue);

						const trend = determineTrend({
							initialValue: chartData[0].series[0][dataProp],
							previousValue: previousValue,
							currentValue: currentValue,
							desiredValue: goal.metricRegistrationRequest.metrics[0].trajectories[0].desiredValue,
							trajectory: goal.metricRegistrationRequest.metrics[0].trajectories[0].trajectory,
						});

						let translateX = 0;
						let translateY = 0;

						if (state.opacity === 1) {
							const range = getIntersectingRange();
							if (data.valueY >= range[0] && data.valueY <= range[1]) {
								translateY = -16;
								translateX = -12;
							}
						}

						return (
							<Fragment key={key}>
								<g
									className={`${baseClass}__text-group`}
									style={{
										transform: `translate(${translateX}px, ${translateY}px)`,
									}}
								>
									<text
										className={`${baseClass}__text`}
										x={xScale(data.date) + 8}
										y={data.valueY}
										textAnchor="start"
										opacity={state.opacity}
										fill={data.color}
										cursor="pointer"
										style={{
											fontFamily: FontFamilies.Medium,
											fontSize: 12,
										}}
										onMouseOver={onHover.bind(null, data.label)}
										onMouseOut={onHoverEnd.bind(null, data.label)}
										onClick={onToggle.bind(null, data.label)}
									>
										{data.valueText}
									</text>
									{data.date === chartData[0].series[currentIndex].date && currentIndex > 0 && (
										<text
											className={`${baseClass}__text`}
											x={
												xScale(data.date) +
												measureText(data.valueText, CanvasFonts.Medium12).width +
												12
											}
											y={data.valueY}
											style={{
												fontFamily: FontFamilies.Medium,
												fontSize: 12,
											}}
											textAnchor="start"
											fill={getColor(trend)}
										>
											{changeValue >= 0 ? `+${changeValue}` : `-${changeValue}`}
											{unit}
										</text>
									)}
								</g>
							</Fragment>
						);
					})}
				</g>
			)}
		</NodeGroup>
	);
};

export default GoalValues;
