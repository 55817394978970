import { Fragment } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useAppSelector } from '../store/hooks';

function ScrollArrows({ showLeft, showRight, paddingTop, scrollRef, scrollWidth, onScrollLeft, onScrollRight }: any) {
	const lightMode = useAppSelector(state => state.auth.userInfo.lightMode);

	function handleScrollLeft(e: React.MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		if (scrollRef.current) {
			onScrollLeft();
			scrollRef.current.scrollBy({
				top: 0,
				left: -scrollWidth,
				behavior: 'smooth',
			});
		}
	}

	function handleScrollRight(e: React.MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		if (scrollRef.current) {
			onScrollRight();
			scrollRef.current.scrollBy({
				top: 0,
				left: scrollWidth,
				behavior: 'smooth',
			});
		}
	}

	return (
		<Fragment>
			{showLeft && (
				<div
					className={paddingTop ? 'scroll scroll--left' : 'scroll scroll--center scroll--left'}
					onClick={handleScrollLeft}
					style={{
						paddingTop,
						'--h': `var(--c${lightMode ? 'l' : 'd'}-shade-h)`,
						'--s': `var(--c${lightMode ? 'l' : 'd'}-shade-h3-s)`,
						'--l': `var(--c${lightMode ? 'l' : 'd'}-shade-h3-l)`,
					}}
				>
					<MdKeyboardArrowLeft size={28} />
				</div>
			)}
			{showRight && (
				<div
					className={paddingTop ? 'scroll scroll--right' : 'scroll scroll--center scroll--right'}
					onClick={handleScrollRight}
					style={{
						paddingTop,
						'--h': `var(--c${lightMode ? 'l' : 'd'}-shade-h)`,
						'--s': `var(--c${lightMode ? 'l' : 'd'}-shade-h3-s)`,
						'--l': `var(--c${lightMode ? 'l' : 'd'}-shade-h3-l)`,
					}}
				>
					<MdKeyboardArrowRight size={28} />
				</div>
			)}
		</Fragment>
	);
}

ScrollArrows.defaultProps = {
	onScrollLeft: () => {},
	onScrollRight: () => {},
};

export default ScrollArrows;
