import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Events from './Events';
import Legend from './Legend';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';
import SingleTimeChart from './SingleTimeChart';

const SingleTimeChartWrapper = ({
	reportId,
	events,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	dashboardId?: string;
	events?: any;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
	[key: string]: any;
}) => {
	const dispatch = useAppDispatch();
	const { dynamicPermissions } = useAppSelector(state => state.auth);

	const { data, lifecycle, dates, interval, yAxisType, indexType, columnProps, analysisType } = useAppSelector(
		state => getChartData(state, reportId)
	);

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				hoveredItem={hoveredItem}
				selection={selection}
				status={status}
				data={data}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<SingleTimeChart
									reportId="explore-chart"
									width={width}
									height={height}
									data={data}
									date={dates[0]}
									interval={interval}
									lifecycle={lifecycle}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									analysisType={analysisType}
									dataProp={dataProp}
									yAxisType={yAxisType}
									indexType={indexType}
									employeeCountHidden={employeeCountHidden}
									hoveredItem={hoveredItem}
									selection={selection}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{events && <Events {...events} />}
			</ScrollableChartArea>
			{/*
				<div>X AXIS</div>
        */}
		</div>
	);
};

export default SingleTimeChartWrapper;
