import React, { PropsWithChildren } from 'react';
import CreateUserModal from '../users/CreateUserModal';
import { AdminHeaderContext } from './AdminHeader';

const Header = ({ children }: PropsWithChildren) => {
	const { showCreateUser, handleUserAction, handleClose, sso, addUsersPending } =
		React.useContext(AdminHeaderContext);

	return (
		<div className="admin-header">
			{children}
			<CreateUserModal
				isOpen={showCreateUser}
				onAction={handleUserAction}
				onCancel={handleClose}
				sso={sso}
				addUsersPending={addUsersPending}
			/>
		</div>
	);
};

const Title = ({ children }: PropsWithChildren) => {
	return <span>{children}</span>;
};

const Buttons = ({ children }: PropsWithChildren) => {
	return <div className="admin-header__buttons">{children}</div>;
};

Header.Title = Title;
Header.Buttons = Buttons;

export default Header;
