import ActionTypes from '../../constants/ActionTypes';
import { FilterSegment, Segment } from '../../types';

interface ReducerState {
	analysisName?: string;
	mainSegments?: Segment[];
	filterSegment?: FilterSegment;
	analysisType?: string;
}

export function filterReducer(state: ReducerState = {}, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.GenerateFulfilled:
			return {
				analysisName: action.analysisName,
				mainSegments: action.mainSegments,
				filterSegment: action.filterSegment,
				analysisType: action.analysisType,
			};
		case ActionTypes.ChangeLocation: {
			if (action.payload.location.pathname !== '/explore') {
				// clear the state when we leave explore
				return {};
			}
			return state;
		}
		case ActionTypes.ClearFilters:
			return {};
		default:
			return state;
	}
}
