import memoize from 'lodash/memoize';

function getOptions(config) {
	const options = {};
	const standardConfigs = ['row', 'column', 'chartType', 'length', 'interval'];
	const optionConfigs = config.filter(c => !standardConfigs.includes(c.name));
	optionConfigs.forEach(oc => {
		options[oc.name] = oc.value;
	});
	return options;
}

export default memoize(getOptions);
