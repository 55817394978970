import ActionTypes from '../../constants/ActionTypes';
import { FilterSegment, SegmentFilter, SegmentName, TimeInterval } from '../../types';

function getInitialState() {
	const lSFilters = localStorage.getItem('dashboardFilters');
	return {
		filters: lSFilters
			? JSON.parse(lSFilters).map((filter: DashboardFilter) => ({
					...filter,
					appliedFilter: [],
					isApplyButtonDisabled: false,
			  }))
			: [],
	};
}

export interface DashboardFilter {
	appliedFilter: string[];
	dashboardId: string;
	dynamicFilterPermissions: SegmentFilter[];
	filterNames: SegmentName[];
	filterSegment?: FilterSegment;
	interval?: TimeInterval;
	isApplyButtonDisabled: boolean;
	startDate?: string;
	endDate?: string;
	reports?: any[];
}

interface FilterReducerState {
	filters: DashboardFilter[];
}

export default function filterReducer(state: FilterReducerState = getInitialState(), action: any): FilterReducerState {
	switch (action.type) {
		case ActionTypes.UpdateAllReportsFulfilled:
		case ActionTypes.ClearFiltersInDashboard: {
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								appliedFilter: [],
								filterSegment: undefined,
								interval: undefined,
								isApplyButtonDisabled: false,
								startDate: undefined,
								endDate: undefined,
								reports: [],
						  }
						: filter
				),
			};
		}
		case ActionTypes.ClearIntervalRangeInDashboard: {
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								startDate: undefined,
								endDate: undefined,
						  }
						: filter
				),
			};
		}
		case ActionTypes.SelectIntervalInDashboard:
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								interval: action.interval,
								startDate: undefined,
								endDate: undefined,
						  }
						: filter
				),
			};
		case ActionTypes.SelectIntervalRangeInDashboard:
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								startDate: action.startDate,
								endDate: action.endDate,
						  }
						: filter
				),
			};
		case ActionTypes.SetFilterNamesInDashboard: {
			const { dashboardId } = action;

			const dashboardIndex = state.filters.findIndex(filter => filter.dashboardId === dashboardId);
			if (dashboardIndex !== -1) {
				return {
					...state,
					filters: state.filters.map(filter =>
						filter.dashboardId === action.dashboardId
							? {
									...filter,
									dynamicFilterPermissions: action.dynamicFilterPermissions,
									filterNames: action.filterNames,
							  }
							: filter
					),
				};
			} else {
				return {
					...state,
					filters: [
						...state.filters,
						{
							dashboardId: dashboardId,
							appliedFilter: [],
							dynamicFilterPermissions: action.dynamicFilterPermissions,
							filterNames: action.filterNames,
							isApplyButtonDisabled: false,
						},
					],
				};
			}
		}
		case ActionTypes.UpdateFilterApplyState:
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								isApplyButtonDisabled: action.isApplyButtonDisabled,
						  }
						: filter
				),
			};
		case ActionTypes.UpdateFilterSegmentInDashboard:
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								filterSegment: action.filterSegment,
						  }
						: filter
				),
			};
		case ActionTypes.UpdateReportsWithFilters:
			return {
				...state,
				filters: state.filters.map(filter =>
					filter.dashboardId === action.dashboardId
						? {
								...filter,
								reports: action.reports,
								appliedFilter: action.appliedFilter,
						  }
						: filter
				),
			};
		case ActionTypes.SetInvalidAppliedFilterStatus:
			return {
				...state,
				filters: state.filters.map(filter => ({
					...filter,
					reports: filter.reports?.map(report =>
						report.reportId === action.reportId
							? {
									...report,
									isFilterApplied: false,
							  }
							: report
					),
				})),
			};
		case ActionTypes.DeleteDashboardFulfilled: {
			const updatedFilters = state.filters.filter(filter => filter.dashboardId !== action.dashboardId);
			localStorage.setItem('dashboardFilters', JSON.stringify(updatedFilters));

			return {
				...state,
				filters: updatedFilters,
			};
		}
		default:
			return state;
	}
}
