import React from 'react';

const SvgComponent = (props: any) => (
	<svg id="Layer_1" viewBox="0 0 283.1 249" fill="currentColor" {...props}>
		<path
			className="st0"
			d="M51.7 249c5.9-43.9 43.9-77.9 89.9-77.9s84.1 34 89.9 77.9h51.6C277 177 216 120.4 141.6 120.4S6.2 177 .1 249h51.6zM71.6 65.9c-18.2 0-32.9-14.7-32.9-32.9S53.4.1 71.6.1s32.9 14.7 32.9 32.9c.1 18.2-14.7 32.9-32.9 32.9M211.6 65.9c-18.2 0-32.9-14.7-32.9-32.9S193.4.1 211.6.1s32.9 14.7 32.9 32.9c.1 18.2-14.7 32.9-32.9 32.9"
		/>
	</svg>
);

export default SvgComponent;
