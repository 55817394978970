import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../../common/Button';
import Popover from '../../common/Popover';
import ButtonTypes from '../../constants/ButtonTypes';
import Filter from './Filter';

export default function FilterPopover({ employeeAttribute, rowData, columnData, rowFilter, columnFilter, onFilter }) {
	const [visible, setVisible] = useState(false);

	function handleVisibleChange(visible) {
		setVisible(visible);
	}

	function handleFilter(rowFilter, columnFilter) {
		onFilter(rowFilter, columnFilter);
		setVisible(false);
	}

	function handleClose() {
		setVisible(false);
	}

	return (
		<Popover
			placement="bottomRight"
			visible={visible}
			onVisibleChange={handleVisibleChange}
			overlayClassName="attr-section__filter__overlay"
			closeOnScroll={false}
			content={
				<Filter
					key={visible}
					employeeAttribute={employeeAttribute}
					rowData={rowData}
					columnData={columnData}
					rowFilter={rowFilter}
					columnFilter={columnFilter}
					onFilter={handleFilter}
					onClose={handleClose}
				/>
			}
		>
			<Button
				componentType={ButtonTypes.type.SECONDARY}
				onClick={() => null}
				activated={rowFilter.length !== 0 || columnFilter.length !== 0}
				selectedStyle={ButtonTypes.selectedStyle.DEFAULT}
				classes={['ml8']}
			>
				Filter Table
			</Button>
		</Popover>
	);
}

FilterPopover.propTypes = {
	text: PropTypes.string,
	rowData: PropTypes.array,
	columnData: PropTypes.array,
	rowFilter: PropTypes.array,
	columnFilter: PropTypes.array,
	onFilter: PropTypes.func,
	employeeAttribute: PropTypes.string,
};
