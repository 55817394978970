import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.629 6.863v-.856H6.493V.87h-.856v5.136H.5v.856h5.136v5.136h.856V6.863h5.136Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgComponent;
