import React, { useRef, useEffect, useState, useContext } from 'react';
import getPeriod from '../../lib/getPeriod';
import moment from 'moment';
import { scaleLinear } from 'd3-scale';
import classNames from 'classnames';
import { ChartContext } from '../../contexts/ChartContext';
import MixPanel from '../../constants/MixPanel';
import { track } from '../../lib/segment';
import { useAppSelector } from '../../store/hooks';
import GuideLabels from './GuideLabels';
import { IGoalState } from '../../measure/goal/reducer';

interface IChartXAxisProps {
	children?: React.ReactNode;
}

const XAxis = (props: IChartXAxisProps) => {
	const {
		chartState: { innerChartOffsets, mode },
	} = useContext(ChartContext);

	const { analysisType } = useAppSelector(state => {
		switch (mode) {
			case 'measure': {
				return state.measure.goal;
			}
		}
		return {} as IGoalState;
	});

	return (
		<div
			className={classNames('x-axis', `x-axis--${analysisType?.toLowerCase().split(' ').join('-')}`)}
			style={
				{
					'--right-offset': innerChartOffsets.right + 'px',
				} as React.CSSProperties
			}
		>
			<div className="x-axis__inner">{props.children}</div>
		</div>
	);
};

const RegularAxis = () => {
	const {
		chartState: { mode, chartWidth, innerChartOffsets },
	} = useContext(ChartContext);

	const [hiddenLabelIndexes, setHiddenLabelIndexes] = useState<number[]>([]);
	const selectCurrentTime = (v: any) => {};

	const { dates, currentIndex, interval } = useAppSelector(state => {
		switch (mode) {
			case 'measure': {
				return state.measure.goal;
			}
		}
		return {} as IGoalState;
	});

	function handleTimeSelect(index: number, e: React.MouseEvent) {
		e.stopPropagation();
		if (index !== currentIndex) {
			//setHoverIndex(undefined);
			selectCurrentTime(index);
		}
	}

	function getTextAlignment(index: number) {
		if (index === dates.length - 1) {
			return '-100%';
		} else if (index > 0) {
			return '-50%';
		}
		return '0%';
	}

	const xScale = scaleLinear<string>()
		.domain([dates[0], dates[dates.length - 1]])
		.range(['0%', '100%']);

	useEffect(() => {
		function recursivelyCalculateSkippedIndexes(
			dates: Date[],
			width: number,
			minimumLabelWidth = 100,
			skips = 0
		): any {
			if (!dates || dates.length === 0 || skips >= 20) return;

			const newDates = dates.filter((_, index) => index % (skips + 1) === 0);
			if (width / newDates.length >= minimumLabelWidth) return skips;

			return recursivelyCalculateSkippedIndexes(dates, width, minimumLabelWidth, skips + 1);
		}

		if (!dates || !chartWidth || !innerChartOffsets) return;
		const skips = recursivelyCalculateSkippedIndexes(
			dates,
			chartWidth - innerChartOffsets.left - innerChartOffsets.right
		);

		const hiddenArray: any[] = [];

		if (skips > 0) {
			Array.from({ length: dates.length }, (_, i) => ((i + 1) % (skips + 1) === 1 ? 1 : 0))
				.reverse()
				.forEach((visible, index) => {
					if (!visible) hiddenArray.push(index);
				});

			setHiddenLabelIndexes(hiddenArray);
		}
	}, [chartWidth, innerChartOffsets, dates]);

	return (
		<>
			{dates.map((date, index) => (
				<div
					key={index}
					onClick={e => handleTimeSelect(index, e)}
					className={classNames('x-axis__label', {
						'x-axis__label--active': index === currentIndex,
					})}
					style={
						{
							'--translateX': getTextAlignment(index),
							'--x': xScale(date),
						} as React.CSSProperties
					}
					data-is-hidden={hiddenLabelIndexes.includes(index) && currentIndex !== index}
				>
					<svg
						fill="currentColor"
						viewBox="0 0 18 18"
						width="18"
						height="18"
						className="x-axis__arrow"
						style={
							{
								'--translateX': getTextAlignment(index),
							} as React.CSSProperties
						}
					>
						<polygon points="9,12 6,7 12,7"></polygon>
					</svg>
					<div className="x-axis__text">
						{getPeriod(date, interval.toLowerCase(), hiddenLabelIndexes.length > 0)}
					</div>
				</div>
			))}
		</>
	);
};

const GoalLabel = () => {
	const {
		chartState: { mode },
	} = useContext(ChartContext);

	const { dates, currentIndex, chartData, analysisType } = useAppSelector(state => {
		switch (mode) {
			case 'measure': {
				return state.measure.goal;
			}
		}
		return {} as IGoalState;
	});

	const xScale = scaleLinear<string>()
		.domain([dates[0], dates[dates.length - 1]])
		.range(['0%', '100%']);

	const currentDataPoint = chartData[0].series[currentIndex];

	return (
		<div
			className={classNames(
				'x-axis__goal-label',
				`x-axis__goal-label--${analysisType?.toLowerCase().split(' ').join('-')}`
			)}
			style={
				{
					'--x': xScale(currentDataPoint.date),
				} as React.CSSProperties
			}
			data-is-hidden={false}
		>
			<svg
				fill="currentColor"
				viewBox="0 0 18 18"
				width="18"
				height="18"
				className="x-axis__arrow"
				style={{} as React.CSSProperties}
			>
				<polygon points="9,12 6,7 12,7"></polygon>
			</svg>
			<div className="x-axis__text">{moment.utc(currentDataPoint.date).format('MMM DD-YYYY')}</div>
		</div>
	);
};

XAxis.RegularAxis = RegularAxis;
XAxis.GoalLabel = GoalLabel;
XAxis.GuideLabels = GuideLabels;

export default XAxis;
