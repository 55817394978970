import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Loader from '../../lottie/graph-loader.json';
import LottieAnimation from '../../common/LottieAnimation';
import Table from '../Table';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getGroupByIdSelector } from './utils';
import User, { Card, getActorName, UserCardInfo } from '../users/UserList/User';
import { Group } from './models';
import Stack from '../../common/Stack';

interface GroupUserListProps {
	group: Group;
}

function GroupUserList({ group }: GroupUserListProps) {
	const { actorId: currentActorId } = useAppSelector(state => state.auth.userInfo);
	const { allUsers } = useAppSelector(state => state.admin.users);

	const users =
		group?.users.map(user => ({
			...allUsers.find(u => u.actorId === user.userId),
		})) || [];

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [userName, setUserName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [sortConfig, setSortConfig] = useState({
		key: '',
		direction: 'asc',
	});

	const loaded = true;

	return loaded ? (
		<Stack flexDirection="column" gap=".8rem">
			{users.map((actor, index) => (
				<>
					<UserCardInfo
						actor={actor}
						key={actor.actorId}
						actorName={getActorName(actor, currentActorId!)}
						avatarSize={48}
					/>
					{index < users.length - 1 && (
						<div
							style={{
								display: 'block',
								width: '100%',
								height: '1px',
								backgroundColor: 'var(--color-ui-10)',
								margin: '0.4rem 0',
							}}
						/>
					)}
				</>
			))}
		</Stack>
	) : (
		<div className="user-table--loading">
			<LottieAnimation autoplay={true} loop={true} width={30} height={30} animation={Loader}></LottieAnimation>
		</div>
	);
}

export default GroupUserList;
