function SvgComponent(props: any) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect x="6" y="4" width="12" height="6" fill="currentColor" />
			<rect x="6" y="18" width="12" height="2" fill="currentColor" />
			<rect x="6" y="12" width="12" height="4" fill="currentColor" />
		</svg>
	);
}

export default SvgComponent;
