import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import produce from 'immer';
import { getEnterprises, saveEnterprise } from './actions';
import { getPackages } from '../package/actions';
import Dropdown from '../../common/Dropdown';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { PackageFragment } from '../package/PackageFragment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function EnterpriseEdit({ history, match }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const { loaded, data } = useAppSelector(state => state.datastudio.enterprises);
	const id = match.params.id;
	const shouldFetch = !loaded;
	const enterprise = data?.find((c: any) => c.enterpriseId === id);

	const { loaded: packagesLoaded, data: packages } = useAppSelector(state => state.datastudio.packages);
	const shouldFetchPackages = !packagesLoaded;

	const [enterprisePackages, setEnterprisePackages] = useState(enterprise?.packages.slice() || []);
	const [packageId, setPackageId] = useState();

	useEffect(() => {
		if (shouldFetch) {
			dispatch(getEnterprises());
		}
		if (shouldFetchPackages) {
			dispatch(getPackages());
		}
	}, [shouldFetch, shouldFetchPackages]);

	useEffect(() => {
		if (enterprise) {
			setEnterprisePackages(enterprise.packages.slice());
		}
	}, [enterprise]);

	const packageOptions =
		packages?.map(p => ({
			value: p.packageId,
			label: p.packageName,
		})) || [];

	const selectedPackage = packageOptions.find(p => p.value === packageId);

	function handleSave() {
		const enterpriseObj = {
			enterpriseId: enterprise?.enterpriseId,
			packages: enterprisePackages,
		};
		if (enterprise?.enterpriseName) {
			dispatch(saveEnterprise(enterpriseObj, enterprise?.enterpriseName));
			history.push(`/datastudio/enterprises/${enterpriseObj.enterpriseId}`);
		}
	}

	function handleAddPackage() {
		const enterprisePackage = enterprisePackages?.find(p => p.packageId === packageId);
		if (!enterprisePackage) {
			const packageObj = packages?.find(p => p.packageId === packageId);
			if (packageObj) {
				setEnterprisePackages(
					produce(enterprisePackages, draftState => {
						draftState.push(packageObj);
					})
				);
			}
		}
		setPackageId(undefined);
	}

	function handleRemovePackage(packageId: string) {
		setEnterprisePackages(
			produce(enterprisePackages, draftState => {
				const packageIndex = draftState.findIndex(t => t.packageId === packageId);
				if (packageIndex >= 0) {
					draftState.splice(packageIndex, 1);
				}
			})
		);
	}

	function handleCancel() {
		history.goBack();
	}

	return (
		<div className="ds-container">
			<div className="ds-flex">
				<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleSave}>
					Save
				</Button>
				<Button componentType={ButtonTypes.type.SECONDARY} style={{ marginLeft: 8 }} onClick={handleCancel}>
					Cancel
				</Button>
			</div>
			<div className="ds-label">Enterprise id</div>
			<div className="ds-value">{enterprise?.enterpriseId || 'New'}</div>
			<div className="ds-label">Packages</div>
			<div className="ds-input">
				<Dropdown
					placeholder="Select"
					options={packageOptions}
					selectedOption={selectedPackage}
					onClick={(value: any) => setPackageId(value)}
				/>
				<Button
					componentType={ButtonTypes.type.SECONDARY}
					onClick={handleAddPackage}
					classes={['ds-add-package']}
					disabled={!packageId}
				>
					Add Package
				</Button>
			</div>
			{enterprisePackages?.map(p => (
				<PackageFragment
					key={p.packageId}
					packageObj={p}
					expandable
					removable
					onRemove={() => handleRemovePackage(p.packageId)}
				/>
			))}
		</div>
	);
}

export default EnterpriseEdit;
