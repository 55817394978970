import React from 'react';

interface MoonProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
}

const Moon = ({ width = 12, height = 12, color = 'var(--color-ui-100)', ...props }: MoonProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 12"
			fill={color ?? 'currentColor'}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6 12C4.33333 12 2.91667 11.4167 1.75 10.25C0.583333 9.08333 0 7.66667 0 6C0 4.33333 0.583333 2.91667 1.75 1.75C2.91667 0.583333 4.33333 0 6 0C6.08889 0 6.18333 0.00277781 6.28333 0.00833337C6.38333 0.0138889 6.51111 0.0222222 6.66667 0.0333333C6.26667 0.388889 5.95556 0.827778 5.73333 1.35C5.51111 1.87222 5.4 2.42222 5.4 3C5.4 4 5.75 4.85 6.45 5.55C7.15 6.25 8 6.6 9 6.6C9.57778 6.6 10.1278 6.49722 10.65 6.29167C11.1722 6.08611 11.6111 5.8 11.9667 5.43333C11.9778 5.56667 11.9861 5.675 11.9917 5.75833C11.9972 5.84167 12 5.92222 12 6C12 7.66667 11.4167 9.08333 10.25 10.25C9.08333 11.4167 7.66667 12 6 12ZM6 11C7.21111 11 8.26667 10.625 9.16667 9.875C10.0667 9.125 10.6278 8.24444 10.85 7.23333C10.5722 7.35556 10.275 7.44722 9.95833 7.50833C9.64167 7.56944 9.32222 7.6 9 7.6C7.72222 7.6 6.63611 7.15278 5.74167 6.25833C4.84722 5.36389 4.4 4.27778 4.4 3C4.4 2.73333 4.42778 2.44722 4.48333 2.14167C4.53889 1.83611 4.63889 1.48889 4.78333 1.1C3.69444 1.4 2.79167 2.00833 2.075 2.925C1.35833 3.84167 1 4.86667 1 6C1 7.38889 1.48611 8.56944 2.45833 9.54167C3.43056 10.5139 4.61111 11 6 11Z"
				fill={color ?? 'currentColor'}
			/>
		</svg>
	);
};

export default Moon;
