import startCase from 'lodash/startCase';

import precisionRound from '../lib/precisionRound';
import { GoalCategory, GoalStatus, IGoal, Trend } from './types';

export const goalCategoryFromStatus = (status: GoalStatus): GoalCategory => {
	switch (status) {
		case 'MATCHED':
		case 'BEHIND':
			return 'completed';
		default:
			return 'active';
	}
};

export function getSymbolFromValueType(valueType: string): string {
	if (!valueType) return '';

	switch (valueType.toLowerCase()) {
		case 'percentage':
		case 'percent':
		case '%':
			return '%';
		case 'number':
		case 'linear regression':
			return '%';
		case 'attrition':
			return 'x';
		case 'index':
			return 'x';
		default:
			return '';
	}
}

export function getIntervalDescription(interval: string): string {
	switch (interval.toLowerCase()) {
		case 'month':
			return 'Monthly';
		case 'quarter':
			return 'Quarterly';
		default:
			return 'Yearly';
	}
}

export function getChangeValue(previousValue: number, currentValue: number): number {
	if (!previousValue && !currentValue) return 0;
	const change = previousValue ? currentValue - previousValue : 0;
	return precisionRound(change, 1);
}

export function getValue(value: number): number {
	return precisionRound(value || 0, 1);
}

export function getColor(goalTrend: string): string {
	switch (goalTrend) {
		case 'up':
		case 'complete':
			return 'var(--cd-color-success)';
		case 'incomplete':
		case 'down':
			return 'var(--cd-color-fail)';
		default:
			return 'var(--cd-color-warning)';
	}
}

interface IDetermineTrendProps {
	previousValue: number;
	currentValue: number;
	desiredValue: number;
	initialValue: number;
	trajectory: string;
	goalStatus?: GoalStatus;
}
export function determineTrend(props: IDetermineTrendProps): Trend {
	const { previousValue, currentValue, desiredValue, initialValue, trajectory, goalStatus } = props;

	if (goalStatus === 'MATCHED') {
		return 'complete';
	}
	if (goalStatus === 'BEHIND') {
		return 'incomplete';
	}

	function trend() {
		const trend = Math.abs(previousValue - desiredValue) - Math.abs(currentValue - desiredValue);

		if (trend > 0) {
			return 'up';
		} else if (trend < 0) {
			return 'down';
		}
		return 'flat';
	}

	if (
		(!previousValue &&
			previousValue !== 0 &&
			!currentValue &&
			currentValue !== 0 &&
			!desiredValue &&
			desiredValue !== 0 &&
			!initialValue &&
			initialValue !== 0) ||
		(!previousValue && previousValue !== 0)
	) {
		return 'flat';
	}

	switch (trajectory) {
		case 'MATCH':
			if (currentValue === desiredValue) {
				return 'complete';
			}
			return trend();
		case 'LESS_EQUAL':
			if (currentValue <= desiredValue) {
				return 'complete';
			}
			return trend();
		case 'GREATER_EQUAL':
			if (currentValue >= desiredValue) {
				return 'complete';
			}
			return trend();
		default:
			return 'flat';
	}
}

export function getDescriptionFromSegment(segment: { name: string; values: string[] }, attributes: any[]): string {
	let text = '';
	attributes.forEach(attribute => {
		if (attribute.attributeName === segment.name) {
			attribute.attributeValues.forEach((v: any) => {
				if (v.value === segment.values[0]) {
					text += v.text;
				}
			});
		}
	});
	return text;
}

export function generateStatusFromGoal(goal: IGoal, attributes: any, currentValue: number, unit: string) {
	let text = '';

	const mainVariables = goal.metricRegistrationRequest.metrics[0].mainVariables;
	const controlVariables = goal.metricRegistrationRequest.metrics[0].controlVariables ?? {};

	text += goal.metricRegistrationRequest.metrics[0].analysisName;

	Object.keys(mainVariables).forEach(key => {
		const segment = {
			name: startCase((mainVariables as any)[key].originalName),
			values: [(mainVariables as any)[key].value],
		};
		const value = getDescriptionFromSegment(segment, attributes);
		if (value) {
			text += ', ' + value;
		}
	});

	Object.keys(controlVariables).forEach(key => {
		const segment = {
			name: startCase((controlVariables as any)[key].originalName),
			values: [(controlVariables as any)[key].value],
		};
		const value = getDescriptionFromSegment(segment, attributes);
		if (value) {
			text += ', ' + value;
		}
	});

	const roundedValue = Math.round((currentValue ?? 0) * 10) / 10;
	text += ' (' + roundedValue + unit + ')';

	return text;
}

export function capitalize(str: string) {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
}
