let noTrack: boolean;

declare global {
	interface Window {
		analytics: any;
	}
}

export function identify(id: string, props: any) {
	noTrack = false;
	if (process.env.REACT_APP_ENV === 'prod' && props.email && props.email.includes('itsdandi.com')) {
		noTrack = true;
	}
	if (window.analytics && !noTrack) {
		window.analytics.identify(id, props);
	}
}

export function page(pageName: string) {
	if (window.analytics && !noTrack) {
		window.analytics.page(pageName);
	}
}

export function track(eventName: string, props?: any) {
	if (window.analytics && !noTrack) {
		window.analytics.track(eventName, props);
	}
}

export function reset() {
	if (window.analytics) {
		window.analytics.reset();
	}
}
