import ActionTypes from '../../constants/ActionTypes';
import moment from 'moment';

interface ReducerState {
	data: any[];
	pending?: boolean;
}

export default function hrIntegrationsReducer(state: ReducerState = { data: [] }, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.FetchIntegrationsPending:
			return {
				...state,
				pending: true,
			};
		case ActionTypes.FetchIntegrationsFulfilled: {
			const { data, latestDataUpdate } = action;
			data.forEach((d: any) => {
				d.dateConnected = moment.utc(d.dateConnected).format('MM/DD/YYYY');
				d.lastDataSync = moment.utc(latestDataUpdate).format('MM/DD/YYYY');
			});
			return {
				...state,
				pending: false,
				data,
			};
		}
		case ActionTypes.FetchIntegrationsRejected:
			return {
				...state,
				pending: false,
			};
		default:
			return state;
	}
}
