import { ChartProps } from '../types';
import { getSVG } from './utils';

const HorizontalBar = ({ type, count = 2, maxHeight }: ChartProps) => {
	const HorizontalBar = getSVG(type, count);

	return (
		<svg
			width="100%"
			height={maxHeight ? maxHeight : '100%'}
			viewBox="0 0 304 86"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="10"
				y="10"
				width="82"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__horizontal-bar"
			/>
			<svg width="100%" height="100%" viewBox="-10 -10 284 41" fill="none" xmlns="http://www.w3.org/2000/svg">
				{HorizontalBar}
			</svg>
		</svg>
	);
};

export default HorizontalBar;
