const intervalOptions = [
    {
        value: 'Month',
        label: 'Monthly',
    },
    {
        value: 'Quarter',
        label: 'Quarterly',
    },
    {
        value: 'Year',
        label: 'Yearly',
    },
];

export default intervalOptions;
