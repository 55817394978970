const SvgComponent = (props: any) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M5.6155 20C5.168 20 4.78683 19.8427 4.472 19.528C4.15733 19.2132 4 18.832 4 18.3845V5.6155C4 5.168 4.15733 4.78683 4.472 4.472C4.78683 4.15733 5.168 4 5.6155 4H18.3845C18.832 4 19.2132 4.15733 19.528 4.472C19.8427 4.78683 20 5.168 20 5.6155V18.3845C20 18.832 19.8427 19.2132 19.528 19.528C19.2132 19.8427 18.832 20 18.3845 20H5.6155ZM10.5 19V12.5H5V18.3845C5 18.564 5.05767 18.7115 5.173 18.827C5.2885 18.9423 5.436 19 5.6155 19H10.5ZM11.5 19H18.3845C18.564 19 18.7115 18.9423 18.827 18.827C18.9423 18.7115 19 18.564 19 18.3845V12.5H11.5V19ZM5 11.5H19V5.6155C19 5.436 18.9423 5.2885 18.827 5.173C18.7115 5.05767 18.564 5 18.3845 5H5.6155C5.436 5 5.2885 5.05767 5.173 5.173C5.05767 5.2885 5 5.436 5 5.6155V11.5Z" />
	</svg>
);

export default SvgComponent;
