import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import OpenText from '../../common/OpenText';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import { closeDuplicateModal, duplicateDashboard } from '../../dashboards/actions';
import FilterApplied from '../../icons/FilterApplied';
import FilterNotApplied from '../../icons/FilterNotApplied';
import sanitize from '../../lib/sanitize';
import { track } from '../../lib/segment';
import { useAppSelector } from '../../store/hooks';

enum SelectorState {
	APPLY = 'Apply',
	NOT_APPLY = 'NotApply',
	NOT_SELECTED = 'NotSelected',
}

interface DuplicateDashboardModalProps {
	dashboardId: string;
	dashboardName: string;
	isFilterApplied?: boolean;
	isOpen: boolean;
	duplicateDashboard: (dashboardId: string, name: any, updatedReports?: any[]) => void;
	closeDuplicateModal: () => void;
}

function DuplicateDashboardModal({
	closeDuplicateModal,
	dashboardId,
	dashboardName,
	duplicateDashboard,
	isFilterApplied,
	isOpen,
}: DuplicateDashboardModalProps) {
	const [name, setName] = useState(`Copy of ${dashboardName}`);
	const [showSelection, setShowSelection] = useState(false);
	const [shouldFilterApply, setShouldFilterApply] = useState<SelectorState>(SelectorState.NOT_SELECTED);
	const inputRef = useRef();
	const { reports } =
		useAppSelector(state => state.dashboard.filter.filters.find(filter => filter.dashboardId === dashboardId)) ||
		{};

	useEffect(() => {
		if (dashboardName) {
			setName(`Copy of ${dashboardName}`);
		}
	}, [dashboardName]);

	useEffect(() => {
		if (!isFilterApplied) {
			setShowSelection(false);
		} else {
			setShouldFilterApply(SelectorState.NOT_SELECTED);
			setShowSelection(true);
		}
	}, [isFilterApplied, isOpen]);

	function handleNameChange(name: string) {
		setName(name);
	}

	function handleCancel() {
		track(MixPanel.Events.DashboardDuplicateModalCancelClick, {
			'Dashboard Name': dashboardName,
		});
		closeDuplicateModal();
	}

	const handleSave = (e: FormEvent) => {
		e.preventDefault();
		track(MixPanel.Events.DashboardDuplicateModalSaveClick, {
			'Dashboard Name': name,
			duplicateWithFilter: shouldFilterApply === SelectorState.APPLY,
		});
		let dashboardName = name.trim();
		dashboardName = sanitize(dashboardName);
		duplicateDashboard(dashboardId, dashboardName, shouldFilterApply === SelectorState.APPLY ? reports : undefined);
	};

	const handleProceed = () => {
		setShowSelection(false);
	};

	const handleSelectClick = (selectorState: SelectorState) => {
		setShouldFilterApply(selectorState);
	};

	return (
		<Modal
			open={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={handleCancel}
			zIndex={3000}
			destroyOnClose
		>
			<div className="duplicate-modal modal">
				{showSelection ? (
					<>
						<div className="modal__header">
							<div className="modal__title">Duplicate dashboard</div>
						</div>
						<div className="modal__content">
							<div className="modal__desc">
								Choose whether or not you want to create the new dashboard with the current filter
								permanently applied to all reports.
							</div>
							<div className="modal__switcher">
								<div
									className={classNames('modal__switcher__button', {
										'modal__switcher__button--first-active':
											shouldFilterApply === SelectorState.APPLY,
									})}
									onClick={() => handleSelectClick(SelectorState.APPLY)}
								>
									<div className="modal__switcher__icon">
										<FilterApplied
											className="icon"
											width={48}
											height={48}
											fill={
												shouldFilterApply === SelectorState.APPLY
													? 'var(--color-ui-100-inverted)'
													: 'var(--color-ui-75)'
											}
										/>
									</div>
									Duplicate with filter permanently applied
								</div>
								<div
									className={classNames('modal__switcher__button', {
										'modal__switcher__button--second-active':
											shouldFilterApply === SelectorState.NOT_APPLY,
									})}
									onClick={() => handleSelectClick(SelectorState.NOT_APPLY)}
								>
									<div className="modal__switcher__icon">
										<FilterNotApplied
											className="icon"
											width={48}
											height={48}
											fill={
												shouldFilterApply === SelectorState.NOT_APPLY
													? 'var(--color-ui-100-inverted)'
													: 'var(--color-ui-75)'
											}
										/>
									</div>
									Duplicate without filter applied
								</div>
							</div>
						</div>
						<div className="modal__footer">
							<div className="modal__buttons">
								<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel} tabIndex={3}>
									Cancel
								</Button>
								<Button
									onClick={handleProceed}
									disabled={shouldFilterApply === SelectorState.NOT_SELECTED}
									tabIndex={2}
								>
									Duplicate
								</Button>
							</div>
						</div>
					</>
				) : (
					<form onSubmit={(e: FormEvent) => handleSave(e)}>
						<div className="modal__header">
							<div className="modal__title">Duplicate dashboard</div>
							<div className="modal__buttons">
								<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel} tabIndex={3}>
									Cancel
								</Button>
								<Button onClick={handleSave} disabled={!name} tabIndex={2}>
									Done
								</Button>
							</div>
						</div>
						<div className="modal__content">
							<div className="modal__desc">
								Enter the name of your new dashboard below. A copy of the current dashboard will be
								created that you can then edit. After you click Done, you will be taken directly into
								your newly created dashboard.
							</div>
							<div className="modal__label">Dashboard name</div>
							<OpenText
								value={name}
								onChange={handleNameChange}
								placeholder="Enter name here"
								tabIndex={1}
								inputRef={inputRef}
							/>
						</div>
					</form>
				)}
			</div>
		</Modal>
	);
}

function mapState(state: any) {
	const { duplicateDashboardId: dashboardId, showDuplicate: isOpen } = state.dashboards;
	const dashboard = state.dashboards[dashboardId];
	const dashboardName = dashboard ? dashboard.name : '';
	return {
		dashboard,
		dashboardId,
		dashboardName,
		isOpen,
	};
}

const dispatchProps = {
	closeDuplicateModal,
	duplicateDashboard,
};

export default connect(mapState, dispatchProps)(DuplicateDashboardModal);
