import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
	currentColor: string;
	nextColor: string;
	rest?: SVGProps<SVGSVGElement>;
}

const SvgComponent = (props: Props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={15} height={35} viewBox="0 0 15 35" fill="none" {...props.rest}>
		<path fill="var(--color-shade-h2)" d="M0 0h15v35H0z" />
		<path fill={props.currentColor} fillRule="evenodd" d="M2 0H0v35h2l10-17.5L2 0Z" clipRule="evenodd" />
		<path fill={props.nextColor} fillRule="evenodd" d="M3 0h12v35H3l10-17.5L3 0Z" clipRule="evenodd" />
	</svg>
);
export default SvgComponent;
