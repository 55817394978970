import { combineReducers } from 'redux';

import aiInsightsReducer from '../aiInsights/reducer';
import filterReducer from '../filter/filterReducer';
import dashboardReducer from './reducer';

export default combineReducers({
	filter: filterReducer,
	view: dashboardReducer,
	aiInsights: aiInsightsReducer,
});
