import Tooltip from 'antd/lib/tooltip';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import DatePicker from '../../common/DatePicker';
import Dropdown from '../../common/Dropdown';
import ButtonTypes from '../../constants/ButtonTypes';
import intervalOptions from '../../constants/IntervalOptions';
import Error from '../../icons/Error';
import getDateFromPeriod from '../../lib/getDateFromPeriod';
import { getMomentIntervalKey } from '../../lib/getMomentIntervalKey';

const PERIODS = {
	Month: [
		{
			value: 4,
			label: '4M',
		},
		{
			value: 12,
			label: '12M',
		},
	],
	Quarter: [
		{
			value: 4,
			label: '4Q',
		},
		{
			value: 8,
			label: '8Q',
		},
	],
	Year: [
		{
			value: 4,
			label: '4Y',
		},
		{
			value: 8,
			label: '8Y',
		},
	],
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
	month: 'short',
});

const quarterFormat = date => {
	const month = date.getMonth();
	const quarter = Math.floor(month / 3);
	const year = date.getFullYear();
	return `Q${quarter + 1} ${year}`;
};

const yearFormat = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
});

export const formatDateByIntervalType = (interval, date) => {
	if (!date) return null;
	switch (interval) {
		case 'Month': {
			return dateFormat.format(date);
		}
		case 'Quarter': {
			return quarterFormat(date);
		}
		case 'Year': {
			return yearFormat.format(date);
		}
	}
};

export default function DateRangeSelection({
	interval,
	selectInterval,
	intervalCount,
	selectIntervalCount,
	startDate,
	endDate,
	selectIntervalRange,
	role,
	dates,
	fromEditor,
	isLarge = false,
}) {
	const abbr = interval.slice(0, 1);
	const [showPicker, setShowPicker] = useState(false);
	const rangeContainer = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	function handleClickOutside(e) {
		if (rangeContainer.current && !rangeContainer.current.contains(e.target)) {
			setShowPicker(false);
		}
	}

	function handleRangeChange(dates) {
		const startDate = formatDateByIntervalType(interval, dates[0]);

		const endDate = formatDateByIntervalType(interval, dates[1]);

		selectIntervalRange(startDate, endDate);
		setShowPicker(false);
	}

	function handleCustomClick() {
		setShowPicker(!showPicker);
	}

	let rangeLength = intervalCount;
	const intervalKey = getMomentIntervalKey(interval);
	if (startDate) {
		const start = getDateFromPeriod(startDate, interval);
		const end = getDateFromPeriod(endDate, interval);
		rangeLength = end.diff(start, intervalKey) + 1;
	}
	const showError = !fromEditor && dates.length !== rangeLength;

	return (
		<div className="date-range-selection">
			<div className="date-range-selection__interval">
				<Dropdown
					selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
					options={intervalOptions}
					selectedOption={interval}
					onClick={selectInterval}
					disabled={role === 'Viewer'}
					buttonLarge={isLarge}
					buttonType={ButtonTypes.type.SECONDARY}
				/>
			</div>
			<div className="date-range-selection__last">
				{PERIODS[interval]?.length && (
					<Dropdown
						selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
						options={PERIODS[interval]}
						selectedOption={startDate && endDate ? 'Custom' : `${intervalCount}${abbr} `}
						onClick={selectIntervalCount}
						buttonLarge={isLarge}
						buttonType={ButtonTypes.type.SECONDARY}
					/>
				)}
			</div>
			<div className="date-range-selection__custom" id="customRangeContainer" ref={rangeContainer}>
				<DatePicker
					placeholder="Custom"
					buttonLarge={isLarge}
					onClick={handleCustomClick}
					label={startDate ? `${startDate} - ${endDate}` : 'Custom'}
					isOpen={showPicker}
					startDate={startDate}
					endDate={endDate}
					formatType={interval}
					onDateSelect={dates => handleRangeChange(dates)}
					disabled={false}
				/>
			</div>
			{showError && (
				<Tooltip
					title="Data not included for all time periods in the selection"
					placement="right"
					color="transparent"
					overlayClassName="date-range-selection__error"
				>
					<Error className="icon" />
				</Tooltip>
			)}
		</div>
	);
}

DateRangeSelection.propTypes = {
	interval: PropTypes.string,
	intervalCount: PropTypes.number,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	selectInterval: PropTypes.func,
	selectIntervalCount: PropTypes.func,
	selectIntervalRange: PropTypes.func,
	role: PropTypes.string,
	latestDataUpdate: PropTypes.string,
	dates: PropTypes.array,
	fromEditor: PropTypes.bool,
	isLarge: PropTypes.bool,
};
