import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
	const fill = props.fill || '#F2F2FB';
	const width = props.width || '20';
	const height = props.height || '20';

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<path
				fill={fill}
				d="M6.01285 12.4375C5.81392 12.4375 5.64746 12.3705 5.51349 12.2365C5.37951 12.1026 5.31252 11.9361 5.31252 11.7372V7.15464L0.880734 1.5247C0.728496 1.32171 0.706422 1.1106 0.814511 0.89137C0.9226 0.672144 1.10503 0.562531 1.36181 0.562531H11.6382C11.895 0.562531 12.0774 0.672144 12.1855 0.89137C12.2936 1.1106 12.2715 1.32171 12.1193 1.5247L7.68748 7.15464V11.7372C7.68748 11.9361 7.6205 12.1026 7.48652 12.2365C7.35254 12.3705 7.18609 12.4375 6.98716 12.4375H6.01285Z"
			/>
		</svg>
	);
};
export default SvgComponent;
