import Stack from '../../../../common/Stack';
import DisclaimerShield from '../../../../icons/DisclaimerShield';

const Disclaimer = () => {
	return (
		<Stack
			gap=".8rem"
			style={{
				padding: '1.6rem',
				backgroundColor: 'var(--color-shade-3)',
				borderRadius: '.8rem',
				overflow: 'hidden',
				position: 'relative',
			}}
		>
			<Stack flexDirection="row" alignItems="center" gap=".8rem">
				<Stack
					justifyContent="center"
					style={{
						color: 'var(--color-fail)',
					}}
				>
					<DisclaimerShield />
				</Stack>
				<span>Important</span>
			</Stack>
			<span
				style={{
					color: 'var(--color-ui-75)',
				}}
			>
				Review all messaging to ensure it aligns with the campaign's goal and complies with your company's
				guidelines.
			</span>
		</Stack>
	);
};

export default Disclaimer;
