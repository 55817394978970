import * as Tooltip from '@radix-ui/react-tooltip';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import produce from 'immer';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import {
	ARROW_CIRCLE_RADIUS,
	AXIS_FONT_SIZE,
	barTypes,
	LARGE_TEXT_FONT_SIZE,
	PADDING,
	TEXT_FONT_SIZE,
	X_AXIS_PADDING,
	Y_SMALL_PADDING,
} from '../editor/chart/constants';
import {
	getBarOpacity,
	getCenterY,
	getFormattedDate,
	getIsActive,
	getIsFaded,
	getLeftArrowPoints,
	getLeftCenterX,
	getRightArrowPoints,
	getRightCenterX,
	getStackedTextOpacity,
	getStackedYAxisValues,
	getTextOpacity,
	getTopBarHeight,
	getTopBarOpacity,
	getYAxisRangeValues,
	getYAxisValues,
	transformStackedData,
} from '../lib/chartUtils';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { useAppSelector } from '../store/hooks';
import { IndexType, ISegmentData, YAxisType } from '../types';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 18;
const RADIUS = 4;
const MIN_GROUP_SPACING = 48;

const pageSizes = [0, 6, 4, 2, 1];

interface ILineChartOverlayProps {
	analysisType?: any;
	columnProps?: any;
	currentIndex?: number;
	data?: ISegmentData[];
	dataOverlayProp?: any;
	dataProp?: any;
	dates?: Date[];
	employeeCountHidden?: any;
	height?: any;
	hoveredItem?: string;
	indexType?: IndexType;
	interval?: any;
	lifecycle: string;
	lightMode: any;
	metricOverlayLifecycle: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	overlayAnalysisType: AnalysisTypes;
	overlayColumnProps: any[];
	overlayData?: ISegmentData[];
	overlayIndexType: IndexType;
	overlayYAxisType: YAxisType;
	reportId?: any;
	selectCurrentTime?: any;
	selection: string[];
	setInnerChartOffsets?: any;
	title?: any;
	width?: any;
	yAxisType: YAxisType;
}

function LineChartOverlay(props: ILineChartOverlayProps) {
	const {
		analysisType,
		columnProps,
		currentIndex,
		dataOverlayProp,
		dataProp,
		dates,
		employeeCountHidden,
		height,
		hoveredItem,
		indexType,
		interval,
		lifecycle,
		lightMode,
		metricOverlayLifecycle,
		onHover,
		onHoverEnd,
		onToggle,
		overlayAnalysisType,
		overlayColumnProps,
		overlayData = [],
		overlayIndexType,
		overlayYAxisType,
		reportId,
		selectCurrentTime,
		selection,
		setInnerChartOffsets,
		title,
		width,
		yAxisType,
	} = props;
	let { data = [] } = props;
	const bodyRef = useRef<SVGGElement>(null);
	const [hoverIndex, setHoverIndex] = useState<number>();
	const [hoverLabel, setHoverLabel] = useState<string>();
	const [startIndex, setStartIndex] = useState<number>(0);
	const [stackedMaxValue, setStackedMaxValue] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [barType, setBarType] = useState<string>();
	const [barWidth, setBarWidth] = useState<number>(0);
	const [barHovered, setBarHovered] = useState(false);

	const { overlayChartType } = useAppSelector(state => state.explore.filter);

	if (!data?.length || !overlayData.length || !dates?.length) {
		return null;
	}

	const range = [height - PADDING - AXIS_PADDING, barType === 'small' ? Y_SMALL_PADDING : PADDING];

	const overlayData2 = useMemo(() => {
		return overlayData
			.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex)
			.map(d => ({
				...d,
				series: d.series.filter(s =>
					dates.some(date => new Date(date).toDateString() === new Date(s.date).toDateString())
				),
			}));
	}, [overlayData]);

	const isOverlayBar = !!overlayData?.length && overlayChartType === ChartTypes.Bar;
	const isOverlayStackedBar = !!overlayData?.length && overlayChartType === ChartTypes.StackedBar;
	const isLinearRegression =
		analysisType === AnalysisTypes.LinearRegression || overlayAnalysisType === AnalysisTypes.LinearRegression;

	const data2 = useMemo(() => {
		return data.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex);
	}, [data]);
	const mergedData = useMemo(() => [...data2, ...overlayData2], [data2, overlayData2]);
	const isSomeActive = getIsActive([...data, ...overlayData], selection, hoveredItem);

	const isNonIndexActive = getIsActive([...data2, ...overlayData2], selection, hoveredItem);
	const isIndexed = analysisType === AnalysisTypes.Index || overlayAnalysisType === AnalysisTypes.Index;
	const isIndexType = indexType === IndexType.Indexed || overlayIndexType === IndexType.Indexed;
	const showIndexLine = isSomeActive && (isLinearRegression || (isIndexed && isIndexType));
	const isChartActive = isNonIndexActive && (hoveredItem || selection?.length);

	const paginatedOverlayData = useMemo(() => {
		let pOverlayData = overlayData2;
		if (pageSize !== 0) {
			pOverlayData = produce(paginatedData, (draftState: any) => {
				draftState.forEach((item: any) => {
					item.series = item.series.slice(startIndex, startIndex + pageSize);
				});
			});
		}
		if (isOverlayStackedBar) {
			if (overlayYAxisType === YAxisType.Count) {
				pOverlayData = produce(pOverlayData, (draftState: any) => {
					draftState.forEach((item: any, i: number) => {
						item.series.forEach((s: any, j: number) => {
							const total = pOverlayData.reduce((sum: number, curr: any) => {
								sum += curr.series[j][dataOverlayProp];
								return sum;
							}, 0);

							if (i > 0) {
								s.previous =
									draftState[i - 1].series[j].previous + draftState[i - 1].series[j][dataOverlayProp];
								s.total = total;
							} else {
								s.previous = 0;
								s.total = total;
							}
							s.color = item.color;
						});
					});
				});
			} else {
				pOverlayData = produce(pOverlayData, (draftState: any) => {
					draftState.forEach((item: any, i: number) => {
						item.series.forEach((s: any, j: number) => {
							if (i > 0) {
								s.previous =
									draftState[i - 1].series[j].previous + draftState[i - 1].series[j].percentage;
								s.total = s.previous + s.percentage;
							} else {
								s.previous = 0;
								s.total = s.percentage;
							}
							s.color = item.color;
						});
					});
				});
			}

			pOverlayData = transformStackedData(pOverlayData);
		}

		return pOverlayData;
	}, [dataOverlayProp, overlayData2, pageSize, startIndex]);

	useEffect(() => {
		const barCount = overlayData2.length;
		const availableWidth = width - 2 * PADDING;
		let configFound = false;
		pageSizes.forEach(ps => {
			if (configFound) return;
			if (ps > dates.length) return;
			barTypes.forEach((bt, j) => {
				if (configFound) return;
				const groupCount = ps === 0 ? dates.length : ps;
				const maxGraphWidth = bt.max * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				const minGraphWidth = bt.min * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				let barWidth;
				if (
					j === 0 &&
					AnalysisTypes.LinearRegression === overlayAnalysisType &&
					maxGraphWidth * 2 < availableWidth
				) {
					barWidth = (availableWidth - (groupCount - 1) * (MIN_GROUP_SPACING * 2)) / (barCount * groupCount);
					if (groupCount === 1) {
						barWidth = barWidth / 2;
					}

					configFound = true;
				} else if (j === 0 && maxGraphWidth < availableWidth) {
					barWidth = bt.max;
					configFound = true;
				} else if (minGraphWidth <= width && maxGraphWidth > availableWidth) {
					barWidth = (availableWidth - (groupCount - 1) * MIN_GROUP_SPACING) / (barCount * groupCount);
					configFound = true;
				}
				if (configFound) {
					barWidth && setBarWidth(barWidth);
					setBarType(bt.type);
					setPageSize(ps);
				}
			});
		});
	}, [width, dates]);

	// Compute maxValue and valueWidth
	const { valueWidth } = useMemo(() => {
		let max = '';
		data.forEach((d: any) => {
			const seriesItem = d.series[dates.length - 1];
			if (seriesItem) {
				const value = getFormattedValue(analysisType, [seriesItem[dataProp]] as any as number);
				if (value.length > max.length) {
					max = value;
				}
			}
		});
		const maxTextSize = measureText(max, CanvasFonts.Medium13);
		return { valueWidth: maxTextSize.width + 8 };
	}, [data, dates, analysisType, dataProp]);

	const paginatedDates = useMemo(() => {
		if (pageSize === 0) return dates;
		return dates.slice(startIndex, startIndex + pageSize);
	}, [dates, startIndex, pageSize]);

	const paginatedData = useMemo(() => {
		if (pageSize === 0) return data2;
		return produce(data2, (draftState: any) => {
			draftState.forEach((item: any) => {
				item.series = item.series.slice(startIndex, startIndex + pageSize);
			});
		});
	}, [data2, startIndex, pageSize]);

	const { overlayXScale, overlayYAxisRangeValues, overlayYScale, yAxisRangeValues, yScale } = useMemo(() => {
		const overlayXScale = scaleBand()
			.domain(paginatedDates as any as string[])
			.range([PADDING * 2.5, width - PADDING * 2.5]);

		const minLineArray = data.map((d: any) => min(d.series, (s: any) => precisionRound(s[dataProp], 1)));
		const maxLineArray = data.map((d: any) => max(d.series, (s: any) => precisionRound(s[dataProp], 1)));
		const minLineValue = min(minLineArray as any as number[]) as number;
		const maxLineValue = max(maxLineArray as any as number[]) as number;

		let minOverlayArray: any[] = [];
		let maxOverlayArray: any[] = [];
		let minOverlayValue: number;
		let maxOverlayValue: number;

		let yAxisValues: [number, number] = [0, 0];
		let overlayYAxisValues: [number, number] = [0, 0];

		if (isOverlayBar) {
			overlayData.forEach((d: any) => {
				minOverlayArray.push(min(d.series, (s: any) => s[dataOverlayProp]));
				maxOverlayArray.push(max(d.series, (s: any) => s[dataOverlayProp]));
			});
			minOverlayArray = minOverlayArray.filter((d: any) => d !== Constants.InvalidValue);
			maxOverlayArray = maxOverlayArray.filter((d: any) => d !== Constants.InvalidValue);
			minOverlayValue = Math.floor(min(minOverlayArray));
			maxOverlayValue = Math.ceil(max(maxOverlayArray));
		} else {
			if (overlayYAxisType === YAxisType.Count) {
				maxOverlayArray = Array.from({ length: data[0].series.length }).map((_, i) => {
					let countByDate = 0;

					data.forEach((d: any) => {
						countByDate += d.series[i][dataOverlayProp];
					});

					return countByDate;
				});
			} else {
				data.forEach((d: any) => {
					maxOverlayArray.push(max(d.series, (s: any) => s[dataOverlayProp]));
				});
			}
			maxOverlayArray = maxOverlayArray.filter((d: any) => d !== Constants.InvalidValue);
			maxOverlayValue = max(maxOverlayArray);
			setStackedMaxValue(maxOverlayValue);
		}

		if (
			analysisType === overlayAnalysisType &&
			((overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType === yAxisType) ||
				(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === indexType))
		) {
			let minValue: number;
			let maxValue: number;
			maxValue = max([maxLineValue, maxOverlayValue])!;

			if (isOverlayBar) {
				minValue = min([minLineValue, minOverlayValue!])!;

				overlayYAxisValues = getYAxisValues({
					analysisType: overlayAnalysisType,
					indexType: overlayIndexType,
					lifecycle: metricOverlayLifecycle,
					maxValue,
					minValue,
					yAxisType: overlayYAxisType,
				});
			} else {
				minValue = min([minLineValue, 0])!;
				overlayYAxisValues = getStackedYAxisValues(maxValue, overlayYAxisType);
			}

			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue,
				minValue,
				yAxisType,
			});
		} else {
			if (isOverlayBar) {
				overlayYAxisValues = getYAxisValues({
					analysisType: overlayAnalysisType,
					indexType: overlayIndexType,
					lifecycle: metricOverlayLifecycle,
					maxValue: maxOverlayValue,
					minValue: minOverlayValue!,
					yAxisType: overlayYAxisType,
				});
			} else {
				overlayYAxisValues = getStackedYAxisValues(maxOverlayValue, overlayYAxisType);
			}

			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue: maxLineValue,
				minValue: minLineValue,
				yAxisType,
			});
		}

		const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType);

		const yScale = scaleLinear().domain(yAxisValues).range(range);

		const overlayYAxisRangeValues = getYAxisRangeValues(overlayYAxisValues, overlayYAxisType);

		const overlayYScale = scaleLinear().domain(overlayYAxisValues).range(range);

		return { overlayXScale, overlayYAxisRangeValues, overlayYScale, yAxisRangeValues, yScale };
	}, [
		data,
		dataOverlayProp,
		dataProp,
		height,
		lifecycle,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayData,
		overlayIndexType,
		overlayYAxisType,
		paginatedDates,
		width,
	]);

	useEffect(() => {
		function onResize() {
			if (bodyRef?.current) {
				setInnerChartOffsets({
					left: 0,
					right: width - bodyRef.current.getBoundingClientRect().width - PADDING,
				});
			}
		}

		const observer = new ResizeObserver(onResize);

		if (bodyRef?.current) {
			observer.observe(bodyRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [width]);

	const isFaded = (item: any) => getIsFaded(item, isNonIndexActive, selection, hoveredItem);

	function handlePrevious(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (startIndex === 0) return;
		setStartIndex(startIndex - 1);
		track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
			'Report Name': title,
		});
	}

	function handleNext(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (!dates?.length) return;
		if (startIndex + pageSize >= dates.length) return;
		setStartIndex(startIndex + 1);
		track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
			'Report Name': title,
		});
	}

	function handleTimeClick(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		selectCurrentTime(index);
		track(MixPanel.Events.DashboardReportColumnGraphXAxisClick, {
			'Report Name': title,
		});
	}

	const arrowColor = lightMode ? '#111112' : '#f2f2f2';
	const arrowColorDisabled = lightMode ? 'rgba(17,17,18,0.24)' : 'rgba(242,242,242,0.24)';

	function handleToggle(label: string) {
		track(MixPanel.Events.DashboardReportLineGraphLineSegmentClick, {
			'Report Name': title,
		});
		onToggle(label);
	}

	function handleMouseOverOnPoint(label: string, index?: number) {
		setHoverIndex(index);
		setHoverLabel(label);
	}

	function handleMouseOutOnPoint() {
		setHoverIndex(undefined);
		setHoverLabel(undefined);
	}

	function handleClickOnPoint(_: any, index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (index !== currentIndex) {
			track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
				'Report Name': title,
			});

			setHoverIndex(undefined);
			setHoverLabel(undefined);
			selectCurrentTime(index);
		}
	}

	const minArray: any[] = [];
	const maxArray: any[] = [];
	data.forEach((d: any) => {
		minArray.push(min(d.series, (s: any) => precisionRound(s[dataProp], 1)));
		maxArray.push(max(d.series, (s: any) => precisionRound(s[dataProp], 1)));
	});
	const minValue = min(minArray);
	const maxValue = max(maxArray);

	const avgValue = (minValue + maxValue) / 2;

	const lineFn =
		line()
			.x((d: any) => overlayXScale(d.date)! + overlayXScale.bandwidth() / 2)
			.y((d: any) => yScale(precisionRound(d[dataProp], 1)))
			.curve(curveMonotoneX) || undefined;

	const getYScale = (scale: number) =>
		analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index
			? yScale(scale)
			: overlayYScale(scale);

	const selectedDate = typeof currentIndex !== 'undefined' && getFormattedDate(dates[currentIndex], interval);

	return (
		<Tooltip.Provider>
			<svg
				className="linechart barchart"
				data-export-type="linechart"
				width={width}
				height={height}
				id={reportId}
			>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				{isLinearRegression && (
					<rect
						className="linechart__linear-regression-background"
						x={0}
						y={getYScale(0)}
						width={width}
						height={height - getYScale(0)}
					/>
				)}
				{isIndexed && indexType === IndexType.Indexed && (
					<rect
						className="linechart__index-background"
						x={0}
						y={getYScale(1)}
						width={width}
						height={height - getYScale(1)}
					/>
				)}
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={paginatedDates.slice()}
					start={data => {
						let x = overlayXScale(data)! + overlayXScale.bandwidth() / 2;
						return {
							opacity: 0,
							x,
						};
					}}
					enter={data => {
						const formattedDate = getFormattedDate(data, interval);
						const selected = selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							timing: {
								delay: Constants.AnimationDuration,
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={data => {
						const formattedDate = getFormattedDate(data, interval);
						const selected = selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							x: [overlayXScale(data)! + overlayXScale.bandwidth() / 2],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="barchart__x-labels">
							{nodes.map(({ state, data, key }, index) => {
								const formattedDate = getFormattedDate(data, interval);
								const selected = selectedDate === formattedDate;

								return (
									<text
										className={cn(
											'barchart__x-labels__text',
											selected && 'barchart__x-labels__text--selected'
										)}
										key={key}
										x={state.x}
										y={height - PADDING - 0.5 * AXIS_FONT_SIZE}
										textAnchor="middle"
										opacity={state.opacity}
										onClick={!selected ? e => handleTimeClick(index, e) : e => e.stopPropagation()}
									>
										{formattedDate}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<Animate
					show={showIndexLine}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={() => ({
						opacity: [0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
				>
					{state => {
						const indexText = isLinearRegression ? 'Index (0%)' : 'Index (1x)';
						const yIndex = isLinearRegression ? getYScale(0) : getYScale(1);
						const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;
						return (
							<Fragment>
								<text
									className="linechart__index-text"
									x={PADDING}
									y={yIndex + 3}
									opacity={state.opacity}
								>
									{indexText}
								</text>
								<line
									className="linechart__index-line"
									x1={xIndex}
									y1={yIndex}
									x2={width - PADDING - valueWidth}
									y2={yIndex}
									opacity={state.opacity}
								/>
							</Fragment>
						);
					}}
				</Animate>
				<NodeGroup
					keyAccessor={d => `${d.label}`}
					data={paginatedData}
					start={(d: any) => {
						const initial = d.series.map(({ date }: { date: Date }) => ({
							[dataProp]: avgValue,
							date,
						}));
						return {
							opacity: 1,
							series: initial,
						};
					}}
					enter={(d: any) => {
						return {
							series: [d.series],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={(d: any) => {
						return {
							series: [d.series],
							opacity: [isFaded(d) ? 0.05 : 1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__lines">
							{nodes.map(
								({ state, data, key }) =>
									!data.isEqualityIndex &&
									!data.isAttritionIndex && (
										<g data-label="line" key={key}>
											<path
												className="linechart__lines__line"
												d={lineFn(state.series) as string | undefined}
												strokeOpacity={
													hoveredItem === data.label ||
													selection?.includes(data.label) ||
													!isChartActive
														? 1
														: 0.2
												}
												stroke={data.color}
												clipPath={`url(#${reportId}_main)`}
												onMouseOver={() => onHover(data.label)}
												onMouseOut={() => onHoverEnd()}
												onClick={() => handleToggle(data.label)}
												data-label="line-primary"
											/>
											<path
												className="linechart__lines__line"
												d={lineFn(state.series) as string | undefined}
												strokeOpacity={0.05}
												stroke={data.color}
												clipPath={`url(#${reportId}_fade)`}
												data-label="line-secondary"
											/>
										</g>
									)
							)}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={d => `${d.label}`}
					data={paginatedData}
					start={(d: any) => {
						const initial = d.series.map(({ date }: { date: Date }) => ({
							[dataProp]: avgValue,
							date,
						}));
						return {
							opacityCircle: 0,
							opacityText: 0,
							series: initial,
						};
					}}
					enter={(d: any) => {
						return {
							opacityCircle: [1],
							opacityText: [1],
							series: [d.series],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={(d: any) => {
						return {
							opacityCircle: isFaded(d) ? [0.05] : [1],
							opacityText: isFaded(d) ? [0.24] : [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__circles">
							{nodes.map(({ state, data, key }) => {
								if (data.isEqualityIndex || data.isAttritionIndex) {
									return null;
								}
								const currentDate =
									typeof currentIndex !== 'undefined' &&
									getFormattedDate(dates[currentIndex], interval);
								const item = state.series.find(
									(s: any) => getFormattedDate(s.date, interval) === currentDate
								);
								let anotherItem;
								if (currentIndex !== dates.length - 1) {
									const lastDate = getFormattedDate(
										paginatedDates[paginatedDates.length - 1],
										interval
									);
									anotherItem = state.series.find(
										(s: any) => getFormattedDate(s.date, interval) === lastDate
									);
								}
								const cx = overlayXScale(item.date)! + overlayXScale.bandwidth() / 2;

								return (
									<Fragment key={key}>
										{item && (
											<g data-label="data-point-current-index">
												<circle
													className="linechart__circles__circle"
													stroke="var(--color-shade-h4)"
													fill="var(--color-shade-h4)"
													style={{
														pointerEvents: 'none',
													}}
													cx={cx}
													cy={yScale(precisionRound(item[dataProp], 1))}
													r={RADIUS}
													data-label="data-point-mask"
												/>
												<circle
													className="linechart__circles__circle"
													fill={data.color}
													cx={cx}
													cy={yScale(precisionRound(item[dataProp], 1))}
													r={RADIUS}
													// cursor="pointer"
													opacity={state.opacityCircle}
													style={{
														pointerEvents: 'none',
													}}
													data-label="data-point-primary"
												/>
												<Tooltip.Root
													delayDuration={0}
													open={data.label === hoverLabel && hoverIndex === currentIndex}
												>
													<Tooltip.Trigger asChild>
														<circle
															className="linechart__circles__circle"
															fill={data.color}
															cx={cx}
															cy={yScale(precisionRound(item[dataProp], 1))}
															r={RADIUS * 2}
															cursor="pointer"
															opacity={0}
															onMouseOver={() => {
																handleMouseOverOnPoint(data.label, currentIndex);
																onHover(data.label);
															}}
															onMouseOut={() => {
																handleMouseOutOnPoint();
																onHoverEnd();
															}}
															onClick={() => {
																handleToggle(data.label);
															}}
															data-label="data-point-hover-trigger"
														/>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="bottom" sideOffset={16}>
															<ChartTooltip
																dataPoint={data}
																seriesItem={item}
																columnProps={columnProps}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>
											</g>
										)}
										{anotherItem && (
											<g data-label="data-point-another-item">
												<circle
													className="linechart__circles__circle"
													fill="var(--color-shade-h4)"
													cx={overlayXScale(anotherItem.date)}
													cy={yScale(precisionRound(anotherItem[dataProp], 1))}
													r={RADIUS}
												/>
												<circle
													className="linechart__circles__circle"
													stroke={data.color}
													strokeWidth={1}
													fill="var(--color-shade-h4)"
													cx={overlayXScale(anotherItem.date)}
													cy={yScale(precisionRound(anotherItem[dataProp], 1))}
													strokeOpacity={0.05}
													r={RADIUS}
													cursor="pointer"
													onClick={handleToggle.bind(null, data.label)}
												/>
											</g>
										)}
										{state.series.map((s: any, i: number) =>
											i !== currentIndex ? (
												<Tooltip.Root
													open={data.label === hoverLabel && hoverIndex === i}
													key={i}
												>
													<Tooltip.Trigger asChild>
														<g data-label="data-point-tooltip-trigger">
															<circle
																className="linechart__circles__circle"
																data-label="tooltip-item-big"
																stroke={data.color}
																strokeWidth={1}
																fill="var(--color-shade-h4)"
																cx={
																	overlayXScale(s.date)! +
																	overlayXScale.bandwidth() / 2
																}
																cy={yScale(precisionRound(s[dataProp], 1))}
																r={RADIUS * 2}
																cursor="pointer"
																opacity={0}
																onMouseOver={() => {
																	handleMouseOverOnPoint(data.label, i);
																	onHover(data.label);
																}}
																onMouseOut={() => {
																	handleMouseOutOnPoint();
																	onHoverEnd(data.label);
																}}
																onClick={e => handleClickOnPoint(data.label, i, e)}
															/>
															<circle
																className="linechart__circles__circle"
																data-label="tooltip-item"
																stroke={data.color}
																strokeWidth={1}
																fill="var(--color-shade-h4)"
																cx={
																	overlayXScale(s.date)! +
																	overlayXScale.bandwidth() / 2
																}
																cy={yScale(precisionRound(s[dataProp], 1))}
																r={RADIUS}
																cursor="pointer"
																opacity={
																	!isChartActive ||
																	hoveredItem === data.label ||
																	selection.includes(data.label)
																		? 1
																		: 0
																}
																style={{
																	pointerEvents: 'none',
																}}
															/>
														</g>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="bottom" sideOffset={16}>
															<ChartTooltip
																dataPoint={data}
																seriesItem={s}
																columnProps={columnProps}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>
											) : null
										)}
									</Fragment>
								);
							})}
						</g>
					)}
				</NodeGroup>
				{isOverlayStackedBar &&
					paginatedOverlayData.map((d: any) => (
						<g key={d.label} className="barchart__bar">
							<NodeGroup
								keyAccessor={d2 => `${d.label}_${d2.date}`}
								data={d.series.slice()}
								start={d2 => {
									let x =
										overlayXScale(d2.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2;
									if (isNaN(x)) {
										x = -100;
									}
									return {
										x,
										y: height - PADDING - AXIS_PADDING,
										height: 0,
										topBarHeight: 0,
										barOpacity: 1,
										topBarOpacity: 0,
										textOpacity: 0,
									};
								}}
								enter={(d2, i) => {
									const delay = Constants.AnimationDuration;
									let x =
										overlayXScale(d2.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2;
									if (isNaN(x)) {
										x = -100;
									}

									const barValue = precisionRound(d2[dataOverlayProp], 1);
									let barHeight;
									let startY;
									const isValueZero = barValue === 0;

									if (overlayYAxisType === YAxisType.Count) {
										startY = overlayYScale(
											stackedMaxValue - d2.previous - (stackedMaxValue - d2.total)
										);
										barHeight = isValueZero
											? 1
											: height - overlayYScale(barValue) - PADDING - AXIS_PADDING;
									} else {
										startY = overlayYScale(100 - d2.previous);
										barHeight = overlayYScale(100 - barValue) - PADDING;
										if (barType === 'small') {
											barHeight = barHeight - AXIS_PADDING;
										}
									}

									return [
										{
											x: [x],
											y: [startY],
											height: [barHeight],
											topBarHeight: [getTopBarHeight(d, barHeight)],
											topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
											timing: {
												delay: delay,
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
										{
											textOpacity: [
												getStackedTextOpacity({
													item: d,
													currentIndex: i,
													data: overlayData,
													dataProp: dataOverlayProp,
													height,
													yAxisType: overlayYAxisType,
													yScale: overlayYScale,
													barType,
													hoveredItem,
													selection,
												}),
											],
											timing: {
												delay: Constants.AnimationDuration,
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
									];
								}}
								update={(d2, i) => {
									let x =
										overlayXScale(d2.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2;
									if (isNaN(x)) {
										x = -100;
									}

									const barValue = precisionRound(d2[dataOverlayProp], 1);
									let barHeight;
									let startY;
									const isValueZero = barValue === 0;

									if (overlayYAxisType === YAxisType.Count) {
										startY = overlayYScale(
											stackedMaxValue - d2.previous - (stackedMaxValue - d2.total)
										);
										barHeight = isValueZero
											? 1
											: height - overlayYScale(barValue) - PADDING - AXIS_PADDING;
									} else {
										startY = overlayYScale(100 - d2.previous);
										barHeight = overlayYScale(100 - barValue) - PADDING;
										if (barType === 'small') {
											barHeight = barHeight - AXIS_PADDING;
										}
									}

									return [
										{
											x: [x],
											y: [startY],
											height: [barHeight],
											topBarHeight: [getTopBarHeight(d, barHeight)],
											barOpacity: [getBarOpacity(d, mergedData, selection, hoveredItem)],
											topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
											textOpacity: [
												getStackedTextOpacity({
													item: d,
													currentIndex: i,
													data: overlayData,
													dataProp: dataOverlayProp,
													height,
													yAxisType: overlayYAxisType,
													yScale: overlayYScale,
													barType,
													hoveredItem,
													selection,
												}),
											],
											timing: {
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
									];
								}}
								interpolation={getInterpolator}
							>
								{nodes => (
									<Fragment>
										{nodes
											.slice(0)
											.reverse()
											.map(({ state, data, key }) => {
												if (
													d.isEqualityIndex ||
													d.isAttritionIndex ||
													data[dataOverlayProp] === Constants.InvalidValue
												) {
													return null;
												}

												let formattedText;
												let xText;
												let yText;
												let translateX;
												let showText = true;
												const isLinearRegression =
													overlayAnalysisType === AnalysisTypes.LinearRegression;
												const isValueNegative = data[dataOverlayProp] < 0;

												formattedText =
													overlayYAxisType === YAxisType.Count
														? data.totalCount || data.includedCount
														: getFormattedValue(
																overlayAnalysisType!,
																data[dataOverlayProp]
														  );

												xText =
													isLinearRegression && isValueNegative
														? state.x + barWidth - 2
														: state.x + 2;

												let startY;
												let barHeight;
												const barValue = precisionRound(data[dataOverlayProp], 1);

												if (overlayYAxisType === YAxisType.Count) {
													startY = overlayYScale(
														stackedMaxValue - data.previous - (stackedMaxValue - data.total)
													);

													barHeight = height - overlayYScale(barValue);

													yText = startY + barHeight / 2 - PADDING;
												} else {
													startY = overlayYScale(100 - data.previous);

													barHeight = overlayYScale(100 - barValue);

													yText =
														barType === 'small'
															? startY + barHeight / 2 - PADDING
															: startY + barHeight / 2 - 4;
												}

												if (yText > height - 12 - PADDING - AXIS_PADDING) {
													yText = height - 12 - PADDING - AXIS_PADDING;
												}
												translateX = (barWidth + TEXT_FONT_SIZE) / 2 - 4;

												const barCount = overlayData.length;
												const groupWidth = (barWidth - PADDING + 8) * barCount;

												return (
													<Fragment key={key}>
														{state.height > 0 && (
															<rect
																x={state.x}
																y={state.y}
																width={groupWidth}
																height={state.height}
																fill={data.color}
																opacity={state.barOpacity}
															/>
														)}

														<Tooltip.Root
															delayDuration={0}
															open={barHovered && d.label === hoveredItem && state.active}
														>
															<Tooltip.Trigger asChild>
																<g>
																	<rect
																		width={groupWidth}
																		x={state.x}
																		y={state.y}
																		height={state.topBarHeight}
																		fill={data.color}
																		opacity={state.topBarOpacity}
																	/>
																	{showText && !employeeCountHidden && (
																		<circle
																			cx={xText + groupWidth + 10}
																			r={3}
																			cy={yText - 4}
																			height={10}
																			fill={data.color}
																			opacity={state.textOpacity}
																			cursor="pointer"
																			onMouseOver={() => {
																				onHover(d.label);
																				setBarHovered(true);
																			}}
																			onMouseOut={() => {
																				onHoverEnd(null, d.label);
																				setBarHovered(false);
																			}}
																			onClick={handleToggle.bind(null, d.label)}
																		/>
																	)}

																	<rect
																		width={groupWidth}
																		x={state.x}
																		y={state.y}
																		height={state.height}
																		fill="transparent"
																		cursor="pointer"
																		onMouseOver={() => {
																			onHover(d.label);
																			setBarHovered(true);
																		}}
																		onMouseOut={() => {
																			onHoverEnd(d.label);
																			setBarHovered(false);
																		}}
																		onClick={handleToggle.bind(null, d.label)}
																	/>
																</g>
															</Tooltip.Trigger>
															<Tooltip.Portal>
																<Tooltip.Content side="bottom" sideOffset={16}>
																	<ChartTooltip
																		dataPoint={
																			d.label === 'OtherXYZ' ? undefined : d
																		}
																		seriesItem={data}
																		columnProps={overlayColumnProps}
																		interval={interval}
																	/>
																</Tooltip.Content>
															</Tooltip.Portal>
														</Tooltip.Root>

														{showText && !employeeCountHidden && (
															<text
																width={barWidth}
																x={xText + groupWidth + 20}
																y={yText}
																textAnchor={'start'}
																height={state.height}
																// transform={
																//     barType === 'small'
																//         ? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
																//         : ''
																// }
																style={{
																	fontFamily: FontFamilies.Regular,
																	fontSize:
																		barType === 'large'
																			? LARGE_TEXT_FONT_SIZE
																			: TEXT_FONT_SIZE,
																}}
																fill="var(--color-ui-100)"
																opacity={state.textOpacity}
																cursor="pointer"
																onMouseOver={() => onHover(d.label)}
																onMouseOut={() => onHoverEnd()}
																onClick={() => handleToggle(d.label)}
															>
																{formattedText}
															</text>
														)}
													</Fragment>
												);
											})}
									</Fragment>
								)}
							</NodeGroup>
						</g>
					))}
				{isOverlayBar &&
					paginatedOverlayData.map((d: any, i: number) => (
						<g key={d.label} className="barchart__bar">
							<NodeGroup
								keyAccessor={d2 => `${d.label}_${d2.date}`}
								data={d.series.slice()}
								start={d2 => {
									let x =
										overlayXScale(d2?.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2 +
										i * barWidth;
									if (isNaN(x)) {
										x = -100;
									}
									return {
										x,
										y: isLinearRegression ? overlayYScale(0) : height - PADDING - AXIS_PADDING,
										height: 0,
										topBarHeight: 0,
										barOpacity: 1,
										topBarOpacity: 0,
										textOpacity: 0,
									};
								}}
								enter={d2 => {
									let x =
										overlayXScale(d2.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2 +
										i * barWidth;
									if (isNaN(x)) {
										x = -100;
									}
									const barValue = precisionRound(d2[dataOverlayProp], 1);

									const isValueNegative = barValue < 0;
									const isValueZero = barValue === 0;

									const startY = isValueZero
										? overlayYScale(0) - 1
										: isLinearRegression
										? isValueNegative
											? overlayYScale(0)
											: overlayYScale(barValue)
										: overlayYScale(barValue);

									const barHeight = isValueZero
										? 1
										: isLinearRegression
										? isValueNegative
											? overlayYScale(barValue) - overlayYScale(0)
											: overlayYScale(0) - overlayYScale(barValue)
										: height - PADDING - AXIS_PADDING - startY;

									return [
										{
											x: [x],
											y: [startY],
											height: [barHeight],
											topBarHeight: [getTopBarHeight(d, barHeight)],
											topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
											timing: {
												delay: Constants.AnimationDuration,
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
										{
											textOpacity: [getTextOpacity(d, mergedData, selection, hoveredItem)],
											timing: {
												delay: Constants.AnimationDuration,
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
									];
								}}
								update={d2 => {
									let x =
										overlayXScale(d2.date)! +
										overlayXScale.bandwidth() / 2 -
										(overlayData.length * barWidth) / 2 +
										i * barWidth;
									if (isNaN(x)) {
										x = -100;
									}
									const barValue = precisionRound(d2[dataOverlayProp], 1);

									const isValueNegative = barValue < 0;
									const isValueZero = barValue === 0;

									const startY = isValueZero
										? overlayYScale(0) - 1
										: isLinearRegression
										? isValueNegative
											? overlayYScale(0)
											: overlayYScale(barValue)
										: overlayYScale(barValue);

									const barHeight = isValueZero
										? 1
										: isLinearRegression
										? isValueNegative
											? overlayYScale(barValue) - overlayYScale(0)
											: overlayYScale(0) - overlayYScale(barValue)
										: height - PADDING - AXIS_PADDING - startY;

									return [
										{
											x: [x],
											y: [startY],
											height: [barHeight],
											topBarHeight: [getTopBarHeight(d, barHeight)],
											barOpacity: [getBarOpacity(d, mergedData, selection, hoveredItem)],
											topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
											textOpacity: [getTextOpacity(d, mergedData, selection, hoveredItem)],
											timing: {
												duration: Constants.AnimationDuration,
												ease: Constants.EasingFn,
											},
										},
									];
								}}
								interpolation={getInterpolator}
							>
								{nodes => (
									<Fragment>
										{nodes.map(({ state, data, key }) => {
											if (
												d.isEqualityIndex ||
												d.isAttritionIndex ||
												data[dataOverlayProp] === Constants.InvalidValue
											) {
												return null;
											}

											let formattedText;
											let xText;
											let yText;
											let translateX;
											let showText = false;
											const isValueNegative = data[dataOverlayProp] < 0;
											if (
												barType !== 'small' ||
												d.label === hoveredItem ||
												selection.includes(d.label)
											) {
												showText = true;
												formattedText =
													overlayYAxisType === YAxisType.Count
														? employeeCountHidden
															? ''
															: data.totalCount || data.includedCount
														: getFormattedValue(
																overlayIndexType === IndexType.Percentage
																	? AnalysisTypes.Percentage
																	: overlayAnalysisType!,
																data[dataOverlayProp]
														  );

												xText =
													isLinearRegression && isValueNegative
														? state.x + barWidth - 2
														: state.x + 2;
												yText =
													overlayYScale(precisionRound(data[dataOverlayProp], 1)) +
													(isLinearRegression && isValueNegative ? 12 : -4);
												translateX = (barWidth + TEXT_FONT_SIZE) / 2 - 4;
											}

											const { height } = state;

											return (
												<Fragment key={key}>
													{height > 0 && (
														<rect
															width={barWidth - 2}
															x={state.x}
															y={state.y}
															height={height}
															fill={d.color}
															opacity={state.barOpacity}
														/>
													)}
													<Tooltip.Root
														delayDuration={0}
														open={d.label === hoveredItem && state.active}
													>
														<Tooltip.Trigger asChild>
															<g>
																<rect
																	width={barWidth - 2}
																	x={state.x}
																	y={state.y}
																	height={state.topBarHeight}
																	fill={d.color}
																	opacity={state.topBarOpacity}
																/>

																<rect
																	data-label="tooltip-trigger"
																	width={barWidth - 2}
																	x={state.x}
																	y={state.y}
																	height={height}
																	fill="transparent"
																	cursor="pointer"
																	onMouseOver={() => onHover(d.label)}
																	onMouseOut={() => onHoverEnd()}
																	onClick={() => handleToggle(d.label)}
																/>
															</g>
														</Tooltip.Trigger>
														<Tooltip.Portal>
															<Tooltip.Content side="bottom" sideOffset={16}>
																<ChartTooltip
																	dataPoint={d}
																	seriesItem={data}
																	columnProps={overlayColumnProps}
																	interval={interval}
																/>
															</Tooltip.Content>
														</Tooltip.Portal>
													</Tooltip.Root>

													{showText && (
														<text
															width={barWidth}
															x={xText}
															y={yText}
															textAnchor={
																isLinearRegression && isValueNegative ? 'end' : 'start'
															}
															height={height}
															transform={
																barType === 'small'
																	? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
																	: ''
															}
															style={{
																fontFamily: FontFamilies.Regular,
																fontSize:
																	barType === 'large'
																		? LARGE_TEXT_FONT_SIZE
																		: TEXT_FONT_SIZE,
															}}
															fill="var(--color-ui-100)"
															opacity={state.textOpacity}
															cursor="pointer"
															onMouseOver={() => onHover(d.label)}
															onMouseOut={() => onHoverEnd()}
															onClick={() => handleToggle(d.label)}
														>
															{formattedText}
														</text>
													)}
												</Fragment>
											);
										})}
									</Fragment>
								)}
							</NodeGroup>
						</g>
					))}
				<YAxisBar
					analysisType={analysisType}
					indexType={indexType}
					isFaded={getIsActive(overlayData2, selection, hoveredItem)}
					xScale={PADDING}
					yAxisRangeValues={yAxisRangeValues}
					yAxisType={yAxisType}
					yScale={yScale}
				/>
				{(analysisType !== overlayAnalysisType ||
					(overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType !== yAxisType) ||
					(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType !== indexType)) && (
					<YAxisBar
						analysisType={overlayAnalysisType!}
						indexType={overlayIndexType}
						isFaded={getIsActive(data2, selection, hoveredItem)}
						xScale={width - PADDING - X_AXIS_PADDING}
						yAxisRangeValues={overlayYAxisRangeValues}
						yAxisType={
							overlayAnalysisType !== AnalysisTypes.Index ? overlayYAxisType : YAxisType.Percentage
						}
						yScale={overlayYScale}
					/>
				)}
				<line
					className="linechart__outline"
					x1={PADDING}
					y1={height - AXIS_PADDING - PADDING}
					x2={width - PADDING - valueWidth}
					y2={height - AXIS_PADDING - PADDING}
				/>
				{pageSize > 0 && (
					<Fragment>
						<polygon
							points={getLeftArrowPoints(pageSize, height, width)}
							fill={startIndex === 0 ? arrowColorDisabled : arrowColor}
						/>
						<circle
							cx={getLeftCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex === 0 ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handlePrevious}
						/>
						<polygon
							points={getRightArrowPoints(pageSize, height, width)}
							fill={startIndex + pageSize === dates.length ? arrowColorDisabled : arrowColor}
						/>
						<circle
							cx={getRightCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex + pageSize === dates.length ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handleNext}
						/>
					</Fragment>
				)}
			</svg>
		</Tooltip.Provider>
	);
}

export default LineChartOverlay;
