import * as Tooltip from '@radix-ui/react-tooltip';
import { arc, pie } from 'd3-shape';
import sortBy from 'lodash/sortBy';
import { Fragment, useEffect, useState } from 'react';
import { NodeGroup } from 'react-move';

import ChartTooltip from './ChartTooltip';
import analysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { getFormattedDate } from '../lib/chartUtils';
import { ARROW_CIRCLE_RADIUS, ARROW_WIDTH, PADDING } from '../constants/ChartSettings';

const BOTTOM_PADDING = 40;
const TOP_PADDING = 16;
const GRID_GAP = 16;
const TABLE_MARGIN = 32;
const FONT_SIZE = 13;
const ROW_HEIGHTS = [44, 40, 36, 28];
const VALUE_FONT_SIZE = 13;
const ARROW_HEIGHT = 8;
const DATE_LENGTH = 100;
const PAGE_SIZE = 2;
const LARGE_PAGE_SIZE = 4;
// There can be accounts like Braze that may have an "Other"
// This messes with the animation and causes a crash
const OTHER_LABEL = 'OtherXYZ';
const PIE_RADIUS = 26;
const ARROW_Y_FIX = 5;

function transformData(
	data: any,
	dataProp: string,
	currentIndex: number,
	yAxisType: string,
	employeeCountHidden: boolean
) {
	let total = 0;
	let data2 = data.filter((d: any) => !!d.series[currentIndex]);
	data2 = data2.map((d: any) => {
		const value = d.series[currentIndex][dataProp];
		total += value;
		const valueLabel =
			yAxisType === 'count'
				? employeeCountHidden
					? ''
					: d.series[currentIndex].totalCount || d.series[currentIndex].includedCount
				: value;
		return { ...d, value, valueLabel };
	});
	data2 = sortBy(data2, d => -d.value);
	let others = 100 - total;
	others = precisionRound(others, 1);
	if (others > 0) {
		data2.push({
			label: OTHER_LABEL,
			value: others,
			valueLabel: others,
			color: 'var(--color-shade-h6)',
		});
	}
	return data2;
}

interface PieChartProps {
	dashboardName?: string;
	title?: string;
	reportId: string;
	width: number;
	height: number;
	data: any;
	dates: any[];
	interval: any;
	lifecycle: string;
	analysisType: analysisTypes;
	currentIndex: number;
	onHover: any;
	onToggle: any;
	onHoverEnd: any;
	length: number;
	colWidth: number;
	selectCurrentTime: any;
	dataProp: string;
	yAxisType: string;
	employeeCountHidden: boolean;
	columnProps: any[];
	benchmarkRowHeight?: number;
	indexType?: string;
	hoveredItem?: string;
	selection: string[];
	status: string;
}

export default function PieChart(props: PieChartProps) {
	const {
		width,
		height,
		colWidth,
		length,
		onHover,
		onHoverEnd,
		onToggle,
		analysisType,
		dates,
		currentIndex,
		interval,
		selectCurrentTime,
		reportId,
		dashboardName,
		title,
		benchmarkRowHeight = 42,
		dataProp,
		yAxisType,
		employeeCountHidden,
		columnProps,
		hoveredItem,
		selection,
	} = props;
	let { data } = props;
	const [rowHeight, setRowHeight] = useState<number | undefined>(ROW_HEIGHTS[0]);

	const [labelWidth, setLabelWidth] = useState(100);
	const [scroll, setScroll] = useState(false);
	const [startIndex, setStartIndex] = useState(getInitialStartIndex());
	const [pieHovered, setPieHovered] = useState(false);
	const singleSegment = data.length === 1;
	const radius = (Math.min(width, height) - TOP_PADDING - BOTTOM_PADDING) / 2;
	const pageSize = length === 3 || length === 4 ? PAGE_SIZE : LARGE_PAGE_SIZE;
	const isPaginated = length > 2 && dates.length > pageSize;
	const [refreshKey, setRefreshKey] = useState(Date.now());
	const isSmallSize = width < 650;

	const pieData = transformData(data, dataProp, currentIndex, yAxisType, employeeCountHidden);

	let activeData = pieData.filter((td: any) => {
		const isActive = td.label === hoveredItem;
		const isSelected = selection.includes(td.label);

		return isActive || isSelected;
	});
	activeData = sortBy(activeData, d => -d.value);

	useEffect(() => {
		let tableHeight = height - TOP_PADDING - BOTTOM_PADDING;
		let rowHeight;
		let scroll = true;
		for (let i = 0; i < ROW_HEIGHTS.length; i++) {
			rowHeight = ROW_HEIGHTS[i];
			const expectedTableHeight = benchmarkRowHeight
				? pieData.length * rowHeight + benchmarkRowHeight
				: pieData.length * rowHeight;
			if (expectedTableHeight <= tableHeight) {
				scroll = false;
				break;
			}
		}
		setRowHeight(rowHeight);
		setScroll(scroll);
	}, [pieData.length, height]);

	useEffect(() => {
		// 47.8% (just some random percentage)
		const percentageWidth = measureText('47.8%', CanvasFonts.Medium13).width;
		let labelWidth = width - 2 * radius - TABLE_MARGIN - 3 * PADDING - percentageWidth;
		if (length > 2) {
			let maxLength = 0;
			data.forEach((d: any) => {
				const currentLength = measureText(d.segment.text, CanvasFonts.Regular14).width;
				if (d.label !== OTHER_LABEL && currentLength > maxLength) {
					maxLength = currentLength;
				}
			});
			maxLength += PADDING + 13;
			labelWidth = Math.min(maxLength, labelWidth);
		}
		setLabelWidth(labelWidth);
	}, [data, width, length]);

	function getInitialStartIndex() {
		let startIndex = 0;
		const pageSize = length === 3 || length === 4 ? PAGE_SIZE : LARGE_PAGE_SIZE;
		const isPaginated = length > 2 && dates.length > pageSize;
		if (isPaginated) {
			startIndex = currentIndex;
			if (currentIndex === dates.length - 1) {
				startIndex = currentIndex - pageSize + 1;
			}
		}
		return startIndex;
	}

	function getOpacity(item: any) {
		const someActive = data.some((d: any) => {
			const isActive = d.label === hoveredItem;
			const isSelected = selection.includes(d.label);
			return isActive || isSelected;
		});
		const isActive = item.label === hoveredItem;
		const isSelected = selection.includes(item.label);
		if (someActive && !(isActive || isSelected)) {
			return 0.24;
		}
		return 1;
	}

	function getFillRadius(item: any) {
		const isActive = item.label === hoveredItem;
		const isSelected = selection.includes(item.label);
		if (isActive || isSelected) {
			return 0;
		}
		return radius - PIE_RADIUS;
	}

	function getBorderOpacity(item: any) {
		const isActive = item.label === hoveredItem;
		const isSelected = selection.includes(item.label);
		if (activeData.length > 0) {
			if (isActive || isSelected) {
				return 1;
			}
			return 1;
		}
		return 1;
	}

	function getRingOpacity(item: any) {
		const isActive = item.label === hoveredItem;
		const isSelected = selection.includes(item.label);
		if (activeData.length > 0) {
			if (isActive || isSelected) {
				return 1;
			}
			return 0.2;
		}
		return 1;
	}

	function getLeftArrowPoints() {
		let x = length === 2 ? width - PADDING - DATE_LENGTH : xTable;
		if (isSmallSize) {
			x = PADDING;
		}
		return getPoints(x);
	}

	function getRightArrowPoints() {
		const x = width - PADDING;
		return getPoints(x, true);
	}

	function getPoints(x: any, isRight?: boolean) {
		const y = height - PADDING - ARROW_Y_FIX;
		const x2 = isRight ? x - ARROW_WIDTH : x + ARROW_WIDTH;
		return `${x},${y} ${x2},${y - ARROW_HEIGHT / 2} ${x2},${y + ARROW_HEIGHT / 2}`;
	}

	function getLeftCenterX() {
		let x = length === 2 ? width - PADDING - DATE_LENGTH : xTable;

		return x + ARROW_WIDTH / 2;
	}

	function getRightCenterX() {
		const x = width - PADDING;
		return x - ARROW_WIDTH / 2;
	}

	function getCenterY() {
		return height - PADDING - ARROW_Y_FIX;
	}

	function handleTimeClick(index: number, e: any) {
		e.stopPropagation();
		track(MixPanel.Events.DashboardReportPieChartTimePeriodClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		selectCurrentTime(index);
		setRefreshKey(Date.now());
	}

	function handlePrev(e: any) {
		e.stopPropagation();
		track(MixPanel.Events.DashboardReportPieChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		selectCurrentTime(currentIndex - 1);
		if (isPaginated) {
			setStartIndex(index => index - 1);
		}
		setRefreshKey(Date.now());
	}

	function handleNext(e: any) {
		e.stopPropagation();
		track(MixPanel.Events.DashboardReportPieChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		selectCurrentTime(currentIndex + 1);
		if (isPaginated) {
			setStartIndex(index => index + 1);
		}
		setRefreshKey(Date.now());
	}

	function handleToggle(label: string, e: any) {
		track(MixPanel.Events.DashboardReportPieChartSegmentClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		onToggle(label, e);
	}

	let x = colWidth + GRID_GAP / 2;
	if (length > 2) {
		x = PADDING + radius;
	}
	let y = TOP_PADDING + radius;

	if (isSmallSize) {
		x = width / 2;
		y = height / 2 - TOP_PADDING;
	}
	const xTable = PADDING + 2 * radius + TABLE_MARGIN;
	const yTable = benchmarkRowHeight ? TOP_PADDING + benchmarkRowHeight : TOP_PADDING;

	const pieLayout = pie()
		.value((d: any) => d.value)
		.sort((a: any, b: any) => {
			if (a.label === OTHER_LABEL) {
				return 1;
			} else if (b.label === OTHER_LABEL) {
				return -1;
			} else {
				return b.value - a.value;
			}
		});

	const arcPath = arc()
		.innerRadius(radius - 2)
		.outerRadius(radius);

	const fillPathWithoutInnerRadius = arc().outerRadius(radius - 2);

	const arcData = pieLayout(pieData);

	return (
		<Tooltip.Provider>
			<svg
				className={cn('pie-chart', dashboardName && 'absolute inset-0')}
				data-export-type="piechart"
				width={width}
				height={height}
				id={reportId}
			>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				<g className="pie-chart__chart" transform={`translate(${x},${y})`}>
					<NodeGroup
						data={arcData}
						keyAccessor={d => `${d.data.label}_${refreshKey}`}
						start={({ startAngle }) => ({
							startAngle,
							endAngle: startAngle,
							borderOpacity: 1,
							ringOpacity: 1,
							radius: radius - PIE_RADIUS,
						})}
						enter={({ startAngle, endAngle }) => {
							const duration =
								(2 * Constants.AnimationDuration * (endAngle - startAngle)) / (2 * Math.PI);
							const delay = (2 * Constants.AnimationDuration * startAngle) / (2 * Math.PI);
							return {
								startAngle: [startAngle],
								endAngle: [endAngle],
								timing: {
									duration,
									delay,
								},
							};
						}}
						update={({ startAngle, endAngle, data }) => {
							const duration =
								(2 * Constants.AnimationDuration * (endAngle - startAngle)) / (2 * Math.PI);
							const delay = (2 * Constants.AnimationDuration * startAngle) / (2 * Math.PI);
							return [
								{
									startAngle: [startAngle],
									endAngle: [endAngle],
									timing: {
										duration,
										delay,
									},
								},
								{
									radius: [getFillRadius(data)],
									borderOpacity: [getBorderOpacity(data)],
									ringOpacity: [getRingOpacity(data)],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								},
							];
						}}
					>
						{nodes => {
							return (
								<g className="pies">
									{nodes.map(({ key, data, state }: any) => {
										return (
											<Fragment key={key}>
												<path
													className={cn('cursor-pointer hidden')}
													// @ts-ignore
													d={arcPath(state)}
													fill={data.data.color}
													opacity={state.borderOpacity}
													onMouseOver={() => {
														data.data.label === OTHER_LABEL
															? undefined
															: onHover(data.data.label);
													}}
													onMouseOut={() => {
														data.data.label === OTHER_LABEL
															? undefined
															: onHoverEnd(data.data.label);
													}}
												/>
												<Tooltip.Root open={data.data.active && pieHovered} delayDuration={0}>
													<Tooltip.Trigger asChild>
														<path
															className={cn('cursor-pointer')}
															// @ts-ignore
															d={fillPathWithoutInnerRadius.innerRadius(state.radius)(
																state
															)}
															fill={data.data.color}
															opacity={state.ringOpacity}
															onMouseOver={() => {
																setPieHovered(true);
																data.data.label === OTHER_LABEL
																	? undefined
																	: onHover(data.data.label);
															}}
															onMouseOut={() => {
																setPieHovered(false);
																data.data.label === OTHER_LABEL
																	? undefined
																	: onHoverEnd(data.data.label);
															}}
															onClick={
																data.data.label === OTHER_LABEL
																	? undefined
																	: handleToggle.bind(null, data.data.label)
															}
														/>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="right" sideOffset={8}>
															<ChartTooltip
																dataPoint={data?.data}
																seriesItem={
																	data?.data?.series
																		? data?.data.series[currentIndex]
																		: undefined
																}
																columnProps={columnProps}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>
											</Fragment>
										);
									})}
								</g>
							);
						}}
					</NodeGroup>
				</g>
				{length === 2 && (
					<NodeGroup
						data={activeData}
						keyAccessor={d => d.label}
						start={() => ({
							opacity: 0,
						})}
						enter={() => ({
							opacity: [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						})}
					>
						{nodes => {
							return (
								<g className="pie-chart_unknown">
									{nodes.map(({ key, data, state }, index) => {
										return (
											<text
												className="pie-chart_unknown__label"
												key={key}
												opacity={state.opacity}
												textAnchor="end"
												fill={data.color}
												x={width - PADDING}
												y={(VALUE_FONT_SIZE + 4) * (index + 1)}
												style={{
													fontFamily: FontFamilies.Regular,
													fontSize: VALUE_FONT_SIZE,
												}}
											>
												{yAxisType === 'count'
													? employeeCountHidden
														? ''
														: data.valueLabel
													: getFormattedValue(analysisType, data.value)}
											</text>
										);
									})}
								</g>
							);
						}}
					</NodeGroup>
				)}
				{length > 2 && (
					<foreignObject
						x={xTable}
						y={yTable}
						width={width - xTable}
						height={height - yTable - BOTTOM_PADDING}
						className={cn(isSmallSize && 'hidden')}
					>
						<table
							className="pie-chart__table"
							style={{
								maxHeight: height - yTable - BOTTOM_PADDING,
							}}
						>
							<tbody className="pie-chart__table__body">
								{pieData.map((pd: any, i: number) => (
									<tr
										className={cn('pie-chart__table__body__row', {
											'pie-chart__table__body__row--odd': i % 2 === 0,
											'pie-chart__table__body__row--hovered': pd.label === OTHER_LABEL,
										})}
										key={pd.label}
										style={{
											height: rowHeight ? rowHeight - 2 : 0,
											opacity: getOpacity(pd),
										}}
										onMouseOver={
											pd.label === OTHER_LABEL || singleSegment
												? undefined
												: () => onHover(pd.label)
										}
										onMouseOut={
											pd.label === OTHER_LABEL || singleSegment
												? undefined
												: () => onHoverEnd(pd.label)
										}
										onClick={
											pd.label === OTHER_LABEL || singleSegment
												? undefined
												: () => onToggle(pd.label)
										}
									>
										<td
											className={cn('pie-chart__table__body__row__cell-left', {
												'pie-chart__table__body__row__cell-left--other':
													pd.label === OTHER_LABEL,
											})}
											style={{
												width: labelWidth + 16,
											}}
										>
											<div className="pie-chart__table__body__row__cell-left__label">
												<svg
													style={{
														marginRight: '8px',
													}}
													width="5px"
													height="5px"
													viewBox="0 0 10 10"
												>
													<circle fill={pd.color} r="5" cx="5" cy="5"></circle>
												</svg>
												<div
													style={{
														maxWidth: labelWidth - 16,
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{pd.label === OTHER_LABEL ? 'Other' : pd.segment.text}
												</div>
											</div>
										</td>
										<td
											className="pie-chart__table__body__row__cell-right"
											style={{
												width: width - xTable - labelWidth,
											}}
										>
											{yAxisType === 'count'
												? employeeCountHidden
													? ''
													: pd.valueLabel
												: `${precisionRound(pd.valueLabel, 1)}%`}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</foreignObject>
				)}
				{length > 2 && scroll && (
					<line
						className="pie-chart__outline"
						x1={xTable}
						x2={width}
						y1={height - BOTTOM_PADDING}
						y2={height - BOTTOM_PADDING}
					/>
				)}
				{length === 2 ? (
					<g className="pie-chart__unknown">
						<polygon
							points={getLeftArrowPoints()}
							fill={currentIndex === 0 ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getLeftCenterX()}
							cy={getCenterY()}
							r={ARROW_CIRCLE_RADIUS}
							fill="transparent"
							cursor={currentIndex === 0 ? 'default' : 'pointer'}
							onClick={currentIndex === 0 ? e => e.stopPropagation() : e => handlePrev(e)}
						/>
						<text
							x={width - PADDING - DATE_LENGTH / 2}
							y={height - PADDING}
							textAnchor="middle"
							fill="rgba(242, 242, 242, 1)"
							style={{
								fontSize: FONT_SIZE,
								fontFamily: FontFamilies.Medium,
							}}
						>
							{getFormattedDate(dates[currentIndex], interval ?? 'Month')}
						</text>
						<polygon
							points={getRightArrowPoints()}
							fill={currentIndex === dates.length - 1 ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getRightCenterX()}
							cy={getCenterY()}
							r={ARROW_CIRCLE_RADIUS}
							fill="transparent"
							cursor={currentIndex === dates.length - 1 ? 'default' : 'pointer'}
							onClick={currentIndex === dates.length - 1 ? e => e.stopPropagation() : e => handleNext(e)}
						/>
					</g>
				) : (
					<g className="pie-chart__x-labels">
						{isPaginated && (
							<Fragment>
								<polygon
									fill="currentColor"
									data-active={startIndex !== 0}
									className={cn(startIndex !== 0 ? 'text-ui-100' : 'text-ui-50')}
									points={getLeftArrowPoints()}
								/>
								<circle
									fill="transparent"
									cx={getLeftCenterX()}
									cy={getCenterY()}
									r={ARROW_CIRCLE_RADIUS}
									cursor={startIndex === 0 ? 'default' : 'pointer'}
									onClick={startIndex === 0 ? e => e.stopPropagation() : e => handlePrev(e)}
								/>
							</Fragment>
						)}

						<NodeGroup
							data={dates.slice()}
							// @ts-ignore
							keyAccessor={d => getFormattedDate(d, interval ?? 'Month')}
							start={(_, index) => {
								const visibleIndex = isPaginated ? index - startIndex : index;
								const visibleLength = isPaginated ? pageSize : dates.length;
								let x =
									xTable +
									((width - PADDING - xTable) * (2 * visibleIndex + 1)) / (visibleLength * 2);

								if (isSmallSize) {
									x = 0;
								}

								return {
									opacity: 0,
									x,
								};
							}}
							enter={(_, index) => ({
								opacity: [index === currentIndex ? 1 : 0.7],
								timing: {
									duration: Constants.AnimationDuration,
									ease: Constants.EasingFn,
								},
							})}
							update={(_, index) => {
								const visibleIndex = isPaginated ? index - startIndex : index;
								const visibleLength = isPaginated ? pageSize : dates.length;
								let x = [
									xTable +
										((width - PADDING - xTable) * (2 * visibleIndex + 1)) / (visibleLength * 2),
								];

								if (isSmallSize) {
									x = [width / 2];
								}
								return {
									opacity: [index === currentIndex ? 1 : 0.7],
									x,
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
						>
							{nodes => {
								return (
									<g>
										{nodes.map(({ key, state }, index) => {
											if (index < startIndex || index >= startIndex + pageSize) {
												return null;
											}
											return (
												<text
													className={cn(
														'pie-chart__x-labels__labels__label',
														index === currentIndex &&
															'pie-chart__x-labels__labels__label--active',
														index !== currentIndex && isSmallSize && 'hidden'
													)}
													key={key}
													x={state.x}
													y={height - PADDING}
													opacity={state.opacity}
													onClick={
														index === currentIndex
															? e => e.stopPropagation()
															: e => handleTimeClick(index, e)
													}
												>
													{key}
												</text>
											);
										})}
									</g>
								);
							}}
						</NodeGroup>
						{(isPaginated || isSmallSize) && (
							<Fragment>
								<polygon
									className={cn(
										!isSmallSize && 'hidden',
										currentIndex > 0 ? 'text-ui-100' : 'text-ui-50'
									)}
									points={getLeftArrowPoints()}
									fill="currentColor"
								/>
								<circle
									cx={PADDING + 6}
									cy={getCenterY()}
									r={ARROW_CIRCLE_RADIUS}
									fill="transparent"
									className={cn(!isSmallSize && 'hidden', currentIndex > 0 && 'cursor-pointer')}
									onClick={currentIndex === 0 ? e => e.stopPropagation() : e => handlePrev(e)}
								/>
								<polygon
									className={cn(
										(
											isSmallSize
												? currentIndex < dates.length - 1
												: startIndex + pageSize < dates.length
										)
											? 'text-ui-100'
											: 'text-ui-50'
									)}
									points={getRightArrowPoints()}
									fill="currentColor"
								/>
								<circle
									cx={getRightCenterX()}
									cy={getCenterY()}
									r={ARROW_CIRCLE_RADIUS}
									fill="transparent"
									className={cn(
										(
											isSmallSize
												? currentIndex === dates.length
												: startIndex + pageSize === dates.length
										)
											? ''
											: 'cursor-pointer'
									)}
									onClick={
										(
											isSmallSize
												? currentIndex === dates.length - 1
												: startIndex + pageSize === dates.length
										)
											? e => e.stopPropagation()
											: e => handleNext(e)
									}
								/>
							</Fragment>
						)}
					</g>
				)}
			</svg>
		</Tooltip.Provider>
	);
}

PieChart.defaultProps = {
	width: 600,
	height: 256,
	selectCurrentTime: () => {},
	benchmarkRowHeight: 0,
};
