import { useRef } from 'react';
import { Animate } from 'react-move';

import Constants from '../constants/Constants';
import cn from '../lib/cn';
import getInterpolator from '../lib/getInterpolator';

interface IDropdownMenuProps {
	marginLeft: number;
	height: number;
	options: any[];
	selectedOption: any;
	perfectScrollbar?: boolean;
	isOpen: boolean;
	openUp: boolean;
	getOption: (option: any, key: string) => any;
	onClick: (option: any, e: React.MouseEvent) => void;
}

const DropdownMenu = ({
	marginLeft,
	height,
	options,
	selectedOption,
	perfectScrollbar,
	isOpen,
	openUp,
	getOption,
	onClick,
}: IDropdownMenuProps) => {
	const ref = useRef(null);
	const maxHeight = height || 368;
	const dropdownHeight = Math.min(options.length * 40 + 16, maxHeight);

	return (
		<Animate
			show={isOpen}
			start={() => ({
				opacity: 0.01,
				scale: 0.8,
			})}
			enter={() => ({
				opacity: [1],
				scale: [1],
				timing: {
					duration: Constants.AnimationDuration,
					ease: Constants.EasingFn,
				},
			})}
			leave={{
				opacity: [0.01],
				scale: [0.8],
				timing: {
					duration: Constants.AnimationDuration,
					ease: Constants.EasingFn,
				},
			}}
			interpolation={getInterpolator}
		>
			{({ opacity, scale }) => {
				return (
					<div
						className="menu-outer"
						style={{
							marginLeft,
							opacity,
							transform: openUp ? `translateY(-${dropdownHeight}px)` : undefined,
						}}
					>
						<div
							className="menu"
							style={{
								maxHeight,
								overflowY: opacity == 1 && !perfectScrollbar ? 'auto' : 'hidden',
								opacity,
								transform: `scale(${scale})`,
							}}
							ref={ref}
						>
							{options.map((option: any) => (
								<div
									className={cn(
										getOption(option, 'value') === getOption(selectedOption, 'value')
											? 'menu-item selected'
											: 'menu-item',
										{ 'menu-item disabled': option.disabled }
									)}
									key={getOption(option, 'value')}
								>
									<a
										href="#"
										onClick={e => {
											!option.disabled && onClick(option, e);
										}}
										className={cn(
											'flex items-center gap-[1rem]',
											getOption(option, 'label').length === 1 ? 'circle' : ''
										)}
									>
										{option.icon && <option.icon />}
										{getOption(option, 'label')}
									</a>
								</div>
							))}
						</div>
					</div>
				);
			}}
		</Animate>
	);
};

export default DropdownMenu;
