import React from 'react';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import ButtonTypes from '../../constants/ButtonTypes';

interface DeleteConfirmationProps {
	handleDelete: () => void;
	handleCancel: () => void;
	isOpen: boolean;
}

const DeleteConfirmation = ({ handleCancel, handleDelete, isOpen }: DeleteConfirmationProps) => {
	return (
		<Modal
			visible={isOpen}
			width={500}
			footer={null}
			onCancel={handleCancel}
			maskClosable={true}
			zIndex={1000}
			destroyOnClose
		>
			<div className="modal">
				<div className="modal__header">
					<div className="modal__title">Delete Event</div>
				</div>
				<div className="modal__content">
					<p>Are you sure you want to delete this event?</p>
					<p>IMPORTANT: This action cannot be undone</p>
				</div>
				<div className="modal__footer">
					<Button onClick={handleCancel} componentType={ButtonTypes.type.TERTIARY}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Delete</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteConfirmation;
