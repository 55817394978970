import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { enableMapSet } from 'immer';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://bf070b9e307a0a39793b51e031d04682@o4505760424525824.ingest.sentry.io/4505829843468288',
	environment:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_ENV === 'prod'
				? 'production'
				: 'dev'
			: 'local-development',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/app\.itsdandi\.com/, /^https:\/\/app\.dandi\.tech/],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// allow Map with immer
enableMapSet();

if ('scrollRestoration' in history) {
	// Back off, browser, I got this...
	history.scrollRestoration = 'manual';
}

ReactDOM.render(<App />, document.getElementById('root'));
