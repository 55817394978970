import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import Constants from '../../constants/Constants';
import { Account } from './models';
import firebase from '../../lib/firebase';

export function uploadPhoto(name: string, image: any) {
	return async (dispatch: any) => {
		dispatch({
			type: ActionTypes.UploadCompanyPhotoPending,
		});

		//image = await resizePhoto(image, 130, 44);
		const photosRef = firebase.storage().ref().child('company-photos').child(name);
		return photosRef.putString(image, 'data_url').then(() => {
			return photosRef.getDownloadURL().then(downloadURL => {
				const imageURL = downloadURL;
				dispatch({
					type: ActionTypes.UploadCompanyPhotoFulfilled,
					imageURL,
				});
				return imageURL;
			});
		});
	};
}

export function updateAccount({ newEnterpriseName, updatedColors, newLogoURL, newLogoLightURL }: Account) {
	return async (dispatch: any, getState: any) => {
		const { enterpriseId, logoURL, logoLightURL, customColors, enterpriseName } = getState().account;

		dispatch({ type: ActionTypes.UpdateAccountPending });

		return axios
			.put(`${Urls.AccountApi}account/settings`, {
				enterpriseId,
				enterpriseName: newEnterpriseName || enterpriseName,
				logoURL: newLogoURL || logoURL,
				logoLightURL: newLogoLightURL || logoLightURL,
				customColors: updatedColors || customColors || [],
			})
			.then(() => {
				dispatch({
					type: ActionTypes.UpdateAccountFulfilled,
					enterpriseId,
					enterpriseName: newEnterpriseName || enterpriseName,
					logoURL: newLogoURL || logoURL || Constants.DefaultLogoUrls.dark,
					logoLightURL: newLogoLightURL || logoLightURL || Constants.DefaultLogoUrls.light,
					customColors: updatedColors || customColors || [],
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateAccountRejected,
				});
			});
	};
}

export function editLogo({ logoImage, lightMode }: any) {
	return async (dispatch: any, getState: any) => {
		const { enterpriseName } = getState().account;
		let newLogoURL;

		if (logoImage) {
			newLogoURL = await dispatch(
				uploadPhoto(`${enterpriseName}-${lightMode ? 'logo-light-mode' : 'logo-dark-mode'}`, logoImage)
			);
		} else {
			newLogoURL = lightMode ? Constants.DefaultLogoUrls.light : Constants.DefaultLogoUrls.dark;
		}

		return dispatch(
			updateAccount({
				newLogoURL: lightMode ? undefined : newLogoURL,
				newLogoLightURL: lightMode ? newLogoURL : undefined,
			})
		);
	};
}
