import cn from '../../lib/cn';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Button from '../ButtonV2';
import ButtonOld from '../Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { InsightsContext } from '.';
import { useContext, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { setAiInsightsModelOpen } from '../../dashboard/aiInsights/reducer';

export default function RegenerateActions({ delay, ...rest }: any) {
	const dispatch = useAppDispatch();
	const scrollToRef = useRef<any>(null);
	const { handleGenerateAiInsights, regenerateMode, pending, loaded } = useContext(InsightsContext);

	function handleCancel() {
		dispatch(setAiInsightsModelOpen(false));
	}

	useEffect(() => {
		const ctx = gsap.context(() => {
			const tl = gsap.timeline({});
			tl.fromTo(
				'.animate-button',
				{
					autoAlpha: 0,
					y: 25,
				},
				{
					autoAlpha: 1,
					y: 0,
					duration: 0.6,
					stagger: 0.175,
					ease: 'power2.out',
					delay,
				}
			);
		});

		return () => {
			ctx.revert();
		};
	}, [delay]);

	if (!regenerateMode || pending || loaded) {
		return null;
	}

	return (
		<div className={cn('flex flex-col w-avail mt-[1.6rem]')} ref={scrollToRef}>
			<div className={cn('flex items-center gap-[.8rem] justify-end w-full')}>
				<div className={cn('animate-button invisible')}>
					<ButtonOld onClick={handleCancel} componentType={ButtonTypes.type.SECONDARY}>
						Cancel
					</ButtonOld>
				</div>
				<div className={cn('animate-button invisible')}>
					<Button disabled={false} onClick={handleGenerateAiInsights}>
						Regenerate insights
					</Button>
				</div>
			</div>
		</div>
	);
}
