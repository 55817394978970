import { useEffect, useState } from 'react';
import { IUseFunnelDataProps, IFunnelStage } from './types';
import { ISegmentData } from '../../types';
import formatValue from '../../lib/formatValue';

const useFunnelData = (props: IUseFunnelDataProps) => {
	const { data, subAnalysisFields, subAnalysisMapping, hoveredItem, selection } = props;
	const [funnelData, setFunnelData] = useState<IFunnelStage[]>([]);

	function getSelectedState(segment: string) {
		return !!selection.find(item => item === segment);
	}

	function getHoverState(segment: string) {
		return hoveredItem === segment;
	}

	function getSegmentXPosition(
		segmentIndex: number,
		stageName: string,
		segments: ISegmentData[],
		totalCount: number
	) {
		if (segmentIndex === 0) {
			return 0;
		}

		const prevSegments = segments.slice(0, segmentIndex);

		return prevSegments.reduce((acc, segment) => {
			const count = Number(segment.pointInTime.subAnalyses.find(s => s.label === stageName)?.value);
			if (count <= 0) return acc;
			return acc + (count / totalCount) * 100;
		}, 0);
	}

	useEffect(() => {
		if (!data) return;

		const stages = Object.keys(subAnalysisFields)
			.map((name: any) => {
				return {
					name,
					...subAnalysisFields[name],
				};
			})
			.sort((a: any, b: any) => a.order - b.order)
			.filter((s: any) => s.unit === 'integer');

		const stagesWithRates = stages.map((stage, index) => {
			if (!stages[index + 1]) {
				return {
					...stage,
					rate: undefined,
				};
			}

			const mapping =
				subAnalysisMapping &&
				Object.keys(subAnalysisMapping).find(s => {
					return (
						stage.name === subAnalysisMapping[s].config.first &&
						stages[index + 1].name === subAnalysisMapping[s].config.second
					);
				});

			return {
				...stage,
				rate: mapping ? subAnalysisMapping[mapping].config.rate : undefined,
			};
		});

		const startCount = data.reduce((acc, segment) => {
			return (acc += Number(
				segment.pointInTime.subAnalyses.find((s: any) => s.label === stagesWithRates[0].name)?.value
			));
		}, 0);

		const transformedData = stagesWithRates.map(stage => {
			const totalCount = data.reduce((acc, segment) => {
				return (acc += Number(segment.pointInTime.subAnalyses.find((s: any) => s.label === stage.name)?.value));
			}, 0);

			const barWidth = (totalCount / startCount) * 100;

			return {
				name: stage.name,
				totalCount,
				barWidth,
				segments: data.map((segment, index) => {
					const countSubAnalysis = segment.pointInTime.subAnalyses.find((s: any) => s.label === stage.name);
					const rateSubAnalysis = segment.pointInTime.subAnalyses.find((s: any) => s.label === stage.rate);

					const countFormatted = formatValue({
						value: countSubAnalysis?.value as string,
						unit: countSubAnalysis?.unit,
					});

					const rateFormatted = formatValue({
						value: rateSubAnalysis?.value as string,
						unit: rateSubAnalysis?.unit,
					});

					const segmentXStart = getSegmentXPosition(index, stage.name, data, totalCount);

					const segmentWidth = (Number(countFormatted) / totalCount) * 100;

					return {
						segment: {
							...segment.segment,
							active: getHoverState(segment.segment.text),
							selected: getSelectedState(segment.segment.text),
						},
						count: countFormatted,
						rate: rateFormatted,
						segmentXStart,
						segmentWidth,
					};
				}),
			};
		});

		setFunnelData(transformedData);
	}, [data]);

	return funnelData;
};

export default useFunnelData;
