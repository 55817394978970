import jwtDecode from 'jwt-decode';

export default function decodeJwtToken() {
	const previewMode = sessionStorage.getItem('preview');
	let token = '';
	if (previewMode) {
		token = localStorage.getItem('previewToken') ?? '';
	} else {
		token = localStorage.getItem('rbacToken') ?? '';
	}

	return JSON.parse(jwtDecode<any>(token).content);
}
