import Header from './Header';
import Sync from './Sync';

const GroupsHeader = () => {
	return (
		<Header>
			<Header.Title></Header.Title>
			<Header.Buttons>
				<Sync />
			</Header.Buttons>
		</Header>
	);
};

export default GroupsHeader;
