import Menu from 'antd/lib/menu';
import { useState } from 'react';
import { resetChat } from '../../ai/chat/reducer';
import { setAiModalOpen } from '../../ai/modal/reducer';

import Button from '../../common/Button';
import GradientButton from '../../common/GradientButton';
import Popover from '../../common/Popover';
import WithPermissions from '../../common/WithPermissions';
import ButtonTypes from '../../constants/ButtonTypes';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { showApplyFilterModal } from '../../dashboards/actions';
import { addDefaultTextReport } from '../../editor/buttons/actions';
import DropdownArrow from '../../icons/DropdownArrow';
import Filter from '../../icons/Filter';
import InsightsReportIcon from '../../icons/InsightsReport';
import TextReportIcon from '../../icons/TextReport';
import cn from '../../lib/cn';
import getPeriod from '../../lib/getPeriod';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { generateAiInsights } from '../aiInsights/actions';
import { selectReports, setAiInsightsModelOpen, setIsSummary, setReportSelectMode } from '../aiInsights/reducer';
import { navigateToExplore, showDashboardFilters } from './actions';
import EditDashboardNameModal from './EditDashboardNameModal';
import exportCsv from './exportCsv';
import exportPpt from './exportPpt';
import exportSlides from './exportSlides';
import SaveIndicator from './SaveIndicator';

function getRowIndex(reports: any[]) {
	let rowIndex = 0;

	reports.forEach(report => {
		const currentRowIndex = report.configuration.find((c: any) => c.name === 'rowIndex')?.value;

		if (currentRowIndex && parseInt(currentRowIndex) > rowIndex) {
			rowIndex = parseInt(currentRowIndex);
		}
	});

	return rowIndex + 1;
}

type SecondaryHeaderProps = {
	showShareModal: any;
	showDuplicateModal: any;
	dashboard: any;
	searchText: any;
	reports: any;
	enterpriseName: any;
	tokenClientRef: any;
};

export default function SecondaryHeader({
	showShareModal,
	showDuplicateModal,
	dashboard,
	searchText,
	reports,
	enterpriseName,
	tokenClientRef,
}: SecondaryHeaderProps) {
	const dispatch = useAppDispatch();
	const [editNameModalOpen, setEditNameModalOpen] = useState(false);
	const isDashboardFiltersShown = useAppSelector(state => state.dashboard.view.showDashboardFilters);
	const { featureFlags } = useAppSelector(state => state.account);

	const { dashboardId, name: dashboardName } = dashboard;
	const allReportIds = Object.keys(reports).filter((id: string) => !!reports[id]?.data);

	const { filterSegment, interval, isApplyButtonDisabled, startDate } =
		useAppSelector(state => state.dashboard.filter.filters.find(filter => filter.dashboardId === dashboardId)) ||
		{};
	const [actionsOpen, setActionsOpen] = useState(false);
	const [reportsMenuOpen, setReportsMenuOpen] = useState(false);
	const [insightsMenuOpen, setInsightsMenuOpen] = useState(false);
	const rowIndex = getRowIndex(dashboard.reports);

	const filterButtonText =
		!!filterSegment || interval
			? `Show Filter (${[filterSegment?.name, interval, startDate].filter(str => str).length})`
			: 'Add Filter';

	function renderReportsMenu() {
		return (
			<Menu className={cn('widget-menu', 'p-0')} selectedKeys={[]}>
				<Menu.Item onClick={handleAdd} className="!h-auto !p-0">
					<div
						className={cn(
							'flex gap-4 items-center w-full',
							'py-[1rem] px-[1.6rem]',
							'cursor-pointer',
							'hover:bg-shade-h5'
						)}
					>
						<InsightsReportIcon />
						<div className={cn('flex flex-col gap-0 items-start')}>
							<div className="text-ui-100">Report</div>
						</div>
					</div>
				</Menu.Item>
				<Menu.Item onClick={handleAddText} className="!h-auto !p-0">
					<div
						className={cn(
							'flex gap-4 items-center w-full',
							'py-[1rem] px-[1.6rem]',
							'cursor-pointer',
							'hover:bg-shade-h5'
						)}
					>
						<TextReportIcon />
						<div className={cn('flex flex-col gap-0 items-start')}>
							<div className="text-ui-100">Text</div>
						</div>
					</div>
				</Menu.Item>
			</Menu>
		);
	}

	function handleAdd() {
		track(MixPanel.Events.EditDashboardAddReportClick, {
			'Dashboard Name': dashboard?.name,
		});
		setReportsMenuOpen(false);
		dispatch(
			navigateToExplore({
				dashboardId: dashboard?.dashboardId,
				reportId: null,
				editMode: true,
				rowIndex,
			})
		);
	}

	function handleAddText() {
		track(MixPanel.Events.DashboardEditDashboard, {
			'Dashboard Name': dashboard?.name,
		});
		dispatch(addDefaultTextReport(dashboard?.dashboardId, rowIndex));
		setReportsMenuOpen(false);
	}

	function handleShare() {
		track(MixPanel.Events.DashboardShareDashboard, {
			'Dashboard Name': dashboardName,
		});
		showShareModal(dashboardId);
		setActionsOpen(false);
	}

	function handleDuplicate() {
		track(MixPanel.Events.DashboardDuplicateDashboard, {
			'Dashboard Name': dashboardName,
		});
		showDuplicateModal(dashboardId);
		setActionsOpen(false);
	}

	function handleEdit() {
		track(MixPanel.Events.DashboardEditDashboard, {
			'Dashboard Name': dashboardName,
		});
		setActionsOpen(false);
		setEditNameModalOpen(true);
	}

	function handleActionsClick() {
		if (!searchText) {
			setActionsOpen(!actionsOpen);
		}
	}

	function handlePPTExport() {
		track(MixPanel.Events.DashboardPPTExport, {
			'Dashboard Name': dashboardName,
		});
		exportPpt(dashboard, reports, enterpriseName);
		setActionsOpen(false);
	}

	function handleSlidesExport() {
		track(MixPanel.Events.DashboardSlidesExport, {
			'Dashboard Name': dashboardName,
		});
		const tokenClient = tokenClientRef.current;

		if (!tokenClient) {
			console.warn('missing token client');
			setActionsOpen(false);

			return;
		}

		tokenClient.callback = () => {
			exportSlides(dashboard, reports, enterpriseName);
		};
		if (gapi.client.getToken() === null) {
			tokenClient.requestAccessToken({ prompt: 'consent' });
		} else {
			tokenClient.requestAccessToken({ prompt: '' });
		}
		setActionsOpen(false);
	}

	function handleInsightsMenuOpen(isOpen: boolean) {
		track(MixPanel.Events.AIChatPageClickHistoryChat);
		setInsightsMenuOpen(isOpen);
	}

	function handleCSVExport() {
		track(MixPanel.Events.DashboardCSVExport, {
			'Dashboard Name': dashboardName,
		});
		const params: any[] = [];
		dashboard.reports.forEach((r: { configuration: any[]; reportId: string | number; name: any }) => {
			const chartType = r.configuration.find(c => c.name === 'chartType').value;
			const report = reports[r.reportId];
			if (!report) return;
			if (chartType === ChartTypes.Matrix || chartType === ChartTypes.HorizontalBar || report?.showSubtable) {
				const {
					generated,
					data,
					summary,
					lifecycle,
					analysisType,
					columnProps,
					attributeName,
					diversityAttributes,
					date,
					interval,
					groupSummary,
					subAnalysisFields,
					subAnalysisMapping,
				} = report;
				if (generated) {
					const period = getPeriod(date, interval);
					params.push({
						reportName: r.name,
						lifecycle,
						analysisType,
						columnProps,
						data,
						summary,
						groupSummary,
						attributeName,
						diversityAttributes,
						period,
						chartType,
						subAnalysisFields,
						subAnalysisMapping,
						showSubtable: report?.showSubtable,
					});
				}
			} else {
				const {
					generated,
					lifecycle,
					analysisType,
					columnProps,
					data,
					dates,
					interval,
					benchmark,
					subAnalysisFields,
					subAnalysisMapping,
					metricOverlay,
					showSubtable,
					subtable,
				} = report;
				if (generated) {
					params.push({
						reportName: r.name,
						lifecycle,
						analysisType,
						columnProps,
						data,
						dates,
						interval,
						chartType,
						benchmark,
						subAnalysisFields,
						subAnalysisMapping,
						metricOverlay,
						showSubtable,
						subtable,
					});
				}
			}
		});
		exportCsv(dashboardName, params);
		setActionsOpen(false);
	}

	function handleApplyFilter() {
		track(MixPanel.Events.DashboardApplyFilter, {
			'Dashboard Name': dashboardName,
		});
		dispatch(showApplyFilterModal(dashboardId));
		setActionsOpen(false);
	}

	function handleShowDashboardFilters() {
		track(isDashboardFiltersShown ? MixPanel.Events.DashboardHideFilter : MixPanel.Events.DashboardShowFilter, {
			'Dashboard Name': dashboardName,
		});
		dispatch(showDashboardFilters());
	}

	function renderInsightsMenu() {
		return (
			<Menu className="widget-menu" selectedKeys={[]}>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<Menu.Item
						onClick={() => {
							dispatch(selectReports(allReportIds));
							dispatch(setIsSummary(true));
							dispatch(generateAiInsights());
							dispatch(setAiInsightsModelOpen(true));
							dispatch(resetChat());
							dispatch(setAiModalOpen(false));
							track(MixPanel.Events.AIInsightsDashboardClickSummarizeDashboard);
							setInsightsMenuOpen(false);
						}}
					>
						Summarize entire dashboard
					</Menu.Item>
				</WithPermissions>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<Menu.Item
						onClick={() => {
							dispatch(setReportSelectMode(true));
							dispatch(resetChat());
							dispatch(setAiModalOpen(false));
							dispatch(setAiInsightsModelOpen(true));
							track(MixPanel.Events.AIInsightsDashboardClickGetInsightsOnSpecificReports);
							setInsightsMenuOpen(false);
						}}
					>
						Get insights on specific reports
					</Menu.Item>
				</WithPermissions>
			</Menu>
		);
	}

	function renderActionsMenu() {
		return (
			<Menu className="widget-menu" selectedKeys={[]}>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<Menu.Item
						onClick={() => {
							dispatch(setReportSelectMode(true));
							setActionsOpen(false);
						}}
					>
						Select reports
					</Menu.Item>
				</WithPermissions>

				<WithPermissions actions={[RbacActions['Dashboards/Share']]}>
					<WithPermissions actions={[RbacActions['Users/View']]}>
						<WithPermissions actions={[RbacActions['Users/Edit']]}>
							<Menu.Item onClick={handleShare}>Share</Menu.Item>
						</WithPermissions>
					</WithPermissions>
				</WithPermissions>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<Menu.Item onClick={handleDuplicate}>Duplicate</Menu.Item>
					<Menu.Item onClick={handleEdit}>Rename</Menu.Item>
				</WithPermissions>
				{(!!filterSegment || interval) && isApplyButtonDisabled && (
					<WithPermissions actions={[RbacActions['Explore/Generate'], RbacActions['Dashboards/Explore']]}>
						<Menu.Item onClick={handleApplyFilter}>Apply filter permanently</Menu.Item>
					</WithPermissions>
				)}
				<WithPermissions actions={[RbacActions['Dashboards/ViewAll']]}>
					<Menu.Item>Export</Menu.Item>
					<Menu.Item onClick={handleCSVExport}>
						<span className="ml8">&#x2022;</span>
						<span className="ml8">CSV</span>
					</Menu.Item>
					<Menu.Item onClick={handleSlidesExport}>
						<span className="ml8">&#x2022;</span>
						<span className="ml8">Google Slides</span>
					</Menu.Item>
					<Menu.Item onClick={handlePPTExport}>
						<span className="ml8">&#x2022;</span>
						<span className="ml8">Powerpoint</span>
					</Menu.Item>
				</WithPermissions>
			</Menu>
		);
	}

	return (
		<div className="dashboard__header">
			<div className="dashboard__buttons">
				<SaveIndicator />
				{featureFlags.aiInsights && (
					<Popover
						showArrow={false}
						placement="bottomLeft"
						visible={insightsMenuOpen}
						content={renderInsightsMenu()}
						overlayClassName="popover--no-padding"
						onVisibleChange={(open: boolean) => handleInsightsMenuOpen(open)}
					>
						<span className="ml-6">
							<GradientButton onClick={() => {}} activated={insightsMenuOpen}>
								<div className="flex items-center justify-between gap-[.4rem]">
									<span className={cn('font-text leading-[1]')}>Get Insights</span>
									<DropdownArrow width={20} height={20} />
								</div>
							</GradientButton>
						</span>
					</Popover>
				)}
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<Popover
						showArrow={false}
						placement="bottomLeft"
						visible={reportsMenuOpen}
						content={renderReportsMenu()}
						overlayClassName="popover--no-padding"
						onVisibleChange={(open: boolean) => setReportsMenuOpen(open)}
					>
						<span className="ml-6">
							<Button onClick={() => {}} activated={reportsMenuOpen}>
								<div className="centered-page">
									<span>Add Content</span>
									<DropdownArrow width={20} height={20} />
								</div>
							</Button>
						</span>
					</Popover>
				</WithPermissions>
				<WithPermissions actions={[RbacActions['Explore/Generate'], RbacActions['Dashboards/Explore']]}>
					<span className="ml-6">
						<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleShowDashboardFilters}>
							<Filter className="icon" fill={'var(--color-ui-75)'} />
							{isDashboardFiltersShown ? 'Hide Filter' : filterButtonText}
						</Button>
					</span>
				</WithPermissions>
				<WithPermissions
					actions={[
						RbacActions['Dashboards/Edit'],
						RbacActions['Dashboards/Share'],
						RbacActions['Dashboards/ViewAll'],
						RbacActions['Dashboards/ViewShared'],
					]}
				>
					<Popover
						showArrow={false}
						placement="bottomLeft"
						visible={actionsOpen}
						content={renderActionsMenu()}
						overlayClassName="popover--no-padding"
						onVisibleChange={handleActionsClick}
					>
						<span className="ml-6">
							<Button
								componentType={ButtonTypes.type.SECONDARY}
								onClick={() => {}}
								disabled={!!searchText}
								activated={actionsOpen}
							>
								<div className="centered-page">
									<span>Dashboard Actions</span>
									<DropdownArrow width={20} height={20} />
								</div>
							</Button>
						</span>
					</Popover>
					<EditDashboardNameModal
						isOpen={editNameModalOpen}
						onClose={() => setEditNameModalOpen(false)}
						dashboardId={dashboardId}
					/>
				</WithPermissions>
			</div>
		</div>
	);
}
