const SvgComponent = (props: any) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M18 19.2885L17.3115 18.6L19.3673 16.5H14.5V15.5H19.3673L17.3115 13.4L18 12.7115L21.2885 16L18 19.2885ZM4.1155 16.5C3.67117 16.5 3.29083 16.3418 2.9745 16.0255C2.65817 15.7092 2.5 15.3288 2.5 14.8845V5.1155C2.5 4.67117 2.65817 4.29083 2.9745 3.9745C3.29083 3.65817 3.67117 3.5 4.1155 3.5H16.8845C17.3288 3.5 17.7092 3.65817 18.0255 3.9745C18.3418 4.29083 18.5 4.67117 18.5 5.1155V10.523C18.4167 10.5153 18.3333 10.5096 18.25 10.5057C18.1667 10.5019 18.0833 10.5 18 10.5C17.9167 10.5 17.8333 10.501 17.75 10.503C17.6667 10.5048 17.5833 10.5096 17.5 10.5173V5.24625L10.4 10.3845L3.5 5.252V14.8845C3.5 15.064 3.55767 15.2115 3.673 15.327C3.7885 15.4423 3.936 15.5 4.1155 15.5H12.5173C12.5096 15.5833 12.5048 15.6667 12.503 15.75C12.501 15.8333 12.5 15.9167 12.5 16C12.5 16.0833 12.5019 16.1667 12.5058 16.25C12.5096 16.3333 12.5153 16.4167 12.523 16.5H4.1155ZM4.14225 4.5L10.4 9.1655L16.8462 4.5H4.14225Z" />
	</svg>
);

export default SvgComponent;
