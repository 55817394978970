import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import Dropdown from '../../common/Dropdown';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

export default function ImportModal(props: any) {
	const { isOpen, pending, onAction, onCancel } = props;
	const [templateConfigurationId, setTemplateConfigurationId] = useState('');
	const { data = [] } = useAppSelector(state => state.datastudio.configurations);
	const options = data.map(d => ({
		value: d.templateConfigurationId,
		label: d.templateName,
	}));
	const selectedOption = options.find(o => o.value === templateConfigurationId);

	useEffect(() => {
		if (isOpen) {
			setTemplateConfigurationId('');
		}
	}, [isOpen]);

	return (
		<Modal
			open={isOpen}
			width={400}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
		>
			<div className="modal" onClick={e => e.stopPropagation()}>
				<div className="modal__header">
					<div className="modal__title">Import from</div>
				</div>
				<div className="modal__content">
					<Dropdown
						placeholder="Select"
						options={options}
						selectedOption={selectedOption}
						onClick={(value: any) => setTemplateConfigurationId(value)}
					/>
				</div>
				<div className="modal__footer">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
						Cancel
					</Button>
					<Button
						disabled={!templateConfigurationId}
						loading={pending}
						onClick={() => onAction(templateConfigurationId)}
						danger
					>
						Import
					</Button>
				</div>
			</div>
		</Modal>
	);
}
