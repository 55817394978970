import { useContext } from 'react';
import { LineChartContext } from './LineChart';
import { NodeGroup } from 'react-move';
import { ChartContext } from '../../contexts/ChartContext';
import Constants from '../../constants/Constants';
import getInterpolator from '../../lib/getInterpolator';
import FontFamilies from '../../constants/FontFamilies';

const Values = () => {
	const { onHover, onHoverEnd, onToggle } = useContext(ChartContext);
	const { valueArray, isFaded, xScale } = useContext(LineChartContext);

	const baseClass = 'linechartv2';

	return (
		<NodeGroup
			keyAccessor={d => d.label}
			data={valueArray}
			start={() => {
				return {
					opacity: 0,
				};
			}}
			enter={() => {
				return {
					opacity: [1],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				};
			}}
			update={d => {
				return {
					opacity: isFaded(d) ? [0.24] : [1],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				};
			}}
			interpolation={getInterpolator}
		>
			{nodes => (
				<g className={`${baseClass}__values`}>
					{nodes.map(({ state, data, key }) => {
						return (
							<text
								className={`${baseClass}__text`}
								key={key}
								x={xScale(data.date) + 8}
								y={data.valueY}
								textAnchor="start"
								opacity={state.opacity}
								fill={data.color}
								cursor="pointer"
								style={{
									fontFamily: FontFamilies.Medium,
									fontSize: 12,
								}}
								onMouseOver={onHover.bind(null, data.label)}
								onMouseOut={onHoverEnd.bind(null, data.label)}
								onClick={onToggle.bind(null, data.label)}
							>
								{data.valueText}
							</text>
						);
					})}
				</g>
			)}
		</NodeGroup>
	);
};

export default Values;
