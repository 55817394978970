import axios from 'axios';
import { push } from 'connected-react-router';
import moment from 'moment';
import qs from 'qs';
import * as uuid from 'uuid';
import ActionTypes from '../constants/ActionTypes';
import ChartTypes from '../constants/ChartTypes';
import constants from '../constants/Constants';
import Urls from '../constants/Urls';
import arrifyObject from '../lib/arrifyObject';
import errorHandler from '../lib/errorHandler';
import objectifyArray from '../lib/objectifyArray';
import { IndexType, YAxisType } from '../types';
import { fetchBySegment } from './detail/actions';
import { ExploreUrlState } from './filter/actions';

export function selectCurrentTime(currentIndex: number) {
	return (dispatch: any, getState: any) => {
		dispatch({
			type: ActionTypes.SelectCurrentTime,
			currentIndex,
		});

		getState().explore.detail.dynamic.sections.forEach((section: any) => {
			const { open, employeeAttribute } = section;
			if (open) {
				dispatch(fetchBySegment(employeeAttribute));
			}
		});
	};
}

export function getSearches(searchId?: string) {
	return async (dispatch: any, getState: any) => {
		if (searchId) {
			return dispatch(getSearch(searchId));
		}
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetSearchesPending,
		});

		return axios
			.post(`${Urls.DashboardsApi}searches`, { enterpriseId })
			.then(response => {
				const searches = response.data;
				dispatch({
					type: ActionTypes.GetSearchesFulfilled,
					payload: {
						searches,
					},
				});
				if (searchId) {
					dispatch(getSearch(searchId));
				}
			})
			.catch(error => {
				errorHandler.report(error);
				console.error('error when getting searches', error);
				dispatch({
					type: ActionTypes.GetSearchesRejected,
				});
			});
	};
}

function getSearch(searchId: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetSearchPending,
		});

		return axios
			.post(`${Urls.DashboardsApi}search`, { enterpriseId })
			.then(response => {
				const searches = response.data;
				const search = searches.find((search: any) => search.reportId === searchId);
				dispatch({
					type: ActionTypes.GetSearchFulfilled,
					payload: {
						search,
					},
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetSearchRejected,
				});
			});
	};
}

export function createSearch(searchData: any) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const { filterSegment, breakdownSegment, subAnalyses } = getState().explore.filter;
		const { breakdownSegment: subtableBreakdownSegment } = getState().explore.subtable;
		const { eventIds } = getState().explore.chart;

		let filter;
		if (filterSegment) {
			filter = {
				name: filterSegment.name,
				value: filterSegment.values[0],
			};
		}
		let breakdown;
		if (breakdownSegment && subtableBreakdownSegment) {
			breakdown = {
				name: subtableBreakdownSegment.name,
				value: subtableBreakdownSegment.values,
			};
		}

		dispatch({
			type: ActionTypes.CreateSearchPending,
		});

		const reportId = uuid.v4();
		const dateAdded = moment().utc().toISOString();
		const subAnalysesConverted = subAnalyses
			.map((s: any) => ({
				name: s.name,
				source: s.source,
			}))
			.map((subAnalysis: any) => ({
				configuration: arrifyObject(subAnalysis),
			}));

		const newSearch = {
			enterpriseId,
			report: {
				dateAdded,
				reportId,
				filter,
				breakdown,
				...searchData,
				analysis: subAnalysesConverted,
				eventIds: eventIds || [],
			},
		};

		return axios
			.put(`${Urls.DashboardsApi}search`, newSearch)
			.then(() => {
				dispatch({
					type: ActionTypes.CreateSearchFulfilled,
					payload: {
						search: newSearch.report,
					},
				});
			})
			.catch(error => {
				errorHandler.report(error);
				console.error('error when saving a search', error);
				dispatch({
					type: ActionTypes.CreateSearchRejected,
				});
			});
	};
}

export function removeSearch(reportId: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.RemoveSearchPending,
		});

		return axios
			.put(`${Urls.DashboardsApi}search/remove`, {
				enterpriseId,
				reportId,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.RemoveSearchFulfilled,
					payload: {
						reportId,
					},
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.RemoveSearchRejected,
				});
			});
	};
}

export function showDeleteSearchModal(reportId: string) {
	return {
		type: ActionTypes.ShowDeleteSearchModal,
		payload: {
			reportId,
		},
	};
}

export function closeDeleteSearchModal() {
	return {
		type: ActionTypes.CloseDeleteSearchModal,
	};
}
export function showEditSearchModal(reportId: string) {
	return {
		type: ActionTypes.ShowEditSearchModal,
		payload: {
			reportId,
		},
	};
}

export function closeEditSearchModal() {
	return {
		type: ActionTypes.CloseEditSearchModal,
	};
}

export function navigateToReport(reportData: any) {
	return (dispatch: any, getState: any) => {
		const { analysisName, analysisType, attributes, name, analysis } = reportData;

		if (!reportData) return;

		let interval = undefined;
		let intervalCount = undefined;
		let chartType = 'Bar';
		let startDate = undefined;
		let endDate = undefined;
		let benchmark = undefined;
		let yAxisType = YAxisType.Percentage;
		let indexType = IndexType.Indexed;
		reportData.configuration.forEach((config: { name: string; value: string }) => {
			if (config.name === 'interval') {
				interval = config.value;
			}
			if (config.name === 'intervalCount') {
				intervalCount = config.value;
			}
			if (config.name === 'chartType') {
				chartType = config.value === 'Matrix' || config.value === 'Table' ? 'Bar' : config.value;
			}
			if (config.name === 'startDate') {
				startDate = config.value;
			}
			if (config.name === 'endDate') {
				endDate = config.value;
			}
			if (config.name === 'benchmark') {
				if (config.value) {
					benchmark = JSON.parse(config.value);
				}
			}
			if (config.name === 'yAxisType') {
				yAxisType = config.value as YAxisType;
			}
			if (config.name === 'indexType') {
				indexType = config.value as IndexType;
			}
		});

		const mainSegments = attributes.map((attribute: any) => {
			return {
				name: attribute.name,
				values: attribute.value,
			};
		});

		const { filter, breakdown } = reportData;

		let filterSegment;
		if (filter) {
			filterSegment = {
				name: filter.name,
				values: [filter.value],
			};
		}
		let breakdownSegment;
		if (breakdown) {
			breakdownSegment = {
				name: breakdown.name,
				values: breakdown.value,
			};
		}

		const subAnalyses = analysis.map((a: any) => ({
			...objectifyArray(a.configuration),
		}));

		interface SavedSearchUrlState extends ExploreUrlState {
			benchmark?: any;
		}

		const queryObject: SavedSearchUrlState = {
			filter: {
				analysisName,
				analysisDisplayName: analysisName,
				mainSegments,
				filterSegment,
				breakdownSegment,
				interval: constants.DefaultInterval,
				intervalCount,
				subAnalyses,
				isSavedSearch: true,
				overlayAnalysisName: '',
				overlayDisplay: false,
				overlayMainNames: [],
				overlayMainSegments: [],
				overlayFilterNames: [],
				overlayColumnProps: [],
				overlayChartType: ChartTypes.Line,
				overlayYAxisType: YAxisType.Percentage,
				overlayIndexType: IndexType.Indexed,
			},
			chart: {
				lifecycle: analysisName,
				analysisType,
				chartType: chartType as ChartTypes,
				eventIds: reportData.eventIds || [],
				yAxisType,
				indexType,
				allowIndexToggle: true,
				allowYAxisToggle: true,
				columnProps: [],
				currentIndex: 0,
			},
			subtable: {},
			dashboard: {},
		};

		if (startDate) {
			queryObject.filter.startDate = startDate;
			queryObject.filter.endDate = endDate;
		} else {
			queryObject.filter.intervalCount = intervalCount;
		}

		let url = '/explore?';
		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});

		url += queryString;

		dispatch(push(url, { source: 'explore' }));
	};
}
