import React from 'react';
import HeaderNav from '../common/HeaderNav';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { setInitialised } from './editReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export default function TemplatesHeader({ history }: RouteComponentProps) {
	const dispatch = useAppDispatch();

	function handleEdit() {
		dispatch(setInitialised(false));
		history.push('/datastudio/survey/template/edit');
	}

	return (
		<div className="survey-header">
			<HeaderNav />
			<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleEdit}>
				New campaign template
			</Button>
		</div>
	);
}
