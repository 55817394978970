import React from 'react';
import { Trend, TrendIconType } from '../types';
import ArrowDownRight from './ArrowDownRight';
import ArrowUpRight from './ArrowUpRight';
import Neutral from './Neutral';
import Smiley from './Smiley';
import { IconProps } from './types';

interface TrendIconProps extends IconProps {
	trend: Trend;
}

const TrendIcon = ({ trend, ...rest }: TrendIconProps) => {
	const determineIcon = () => {
		switch (trend) {
			case 'up':
				return <ArrowUpRight {...rest} />;
			case 'down':
				return <ArrowDownRight {...rest} />;
			case 'flat':
				return <Neutral {...rest} />;
			case 'incomplete':
				return <Neutral {...rest} />;
			default:
				return <Smiley {...rest} />;
		}
	};

	const Icon = determineIcon();

	return Icon;
};

export default TrendIcon;
