import ThemeColorCard from '../../constants/ThemeColorCard';
import DefaultColors from '../../constants/DefaultColors';

const resetColorPalette = (context: any) => {
	const { customColors } = context;

	return customColors.map((color: any, i: number) => ({
		name: color.name,
		colors: {
			light: color.colors.lightDefault || DefaultColors.graph[i].colors.light,
			dark: color.colors.darkDefault || DefaultColors.graph[i].colors.dark,
			lightDefault: color.colors.lightDefault || DefaultColors.graph[i].colors.light,
			darkDefault: color.colors.darkDefault || DefaultColors.graph[i].colors.dark,
		},
	}));
};

const updateColor = (context: any, payload: any) => {
	const { customColors } = context;

	const updatedColors = customColors.map((obj: any) => {
		if (obj.name === payload.name) {
			return {
				name: payload.name,
				colors: payload.colors,
			};
		} else {
			return obj;
		}
	});

	return {
		...context,
		customColors: updatedColors,
	};
};

const idleState = (state: any, event: any) => {
	switch (event.type) {
		case ThemeColorCard.events.EDIT: {
			const newContext = updateColor(state.context, event.payload);

			return {
				...state,
				context: newContext,
				status: ThemeColorCard.states.EDITING,
			};
		}
		case ThemeColorCard.events.RESET: {
			const { action } = event.payload;
			const newPalette = resetColorPalette(state.context);

			action({
				updatedColors: newPalette,
			});

			return {
				...state,
				context: {
					customColors: newPalette,
				},
			};
		}
		case ThemeColorCard.events.SAVE_DEFAULT: {
			const { action } = event.payload;
			const newPalette = state.context.customColors.map((color: any) => ({
				name: color.name,
				colors: {
					light: color.colors.light,
					dark: color.colors.dark,
					lightDefault: color.colors.light,
					darkDefault: color.colors.dark,
				},
			}));
			action({
				updatedColors: newPalette,
			});

			return {
				...state,
				status: ThemeColorCard.states.IDLE,
				context: {
					...state.context,
					customColors: newPalette,
				},
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

const editingState = (state: any, event: any) => {
	switch (event.type) {
		case ThemeColorCard.events.EDIT: {
			const newContext = updateColor(state.context, event.payload);
			return {
				...state,
				context: newContext,
			};
		}
		case ThemeColorCard.events.SAVE: {
			const { action } = event.payload;
			action({
				updatedColors: state.context.customColors,
			});

			return {
				...state,
				status: ThemeColorCard.states.IDLE,
			};
		}

		case ThemeColorCard.events.RESET: {
			const { action } = event.payload;
			const palette = resetColorPalette(state.context);

			action({
				updatedColors: palette,
			});

			return {
				...state,
				context: {
					customColors: palette,
				},
				status: ThemeColorCard.states.IDLE,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

const customColorsMachine = (state: any, event: any) => {
	switch (state.status) {
		case ThemeColorCard.states.IDLE: {
			return idleState(state, event);
		}
		case ThemeColorCard.states.EDITING: {
			return editingState(state, event);
		}
		default: {
			return { ...state };
		}
	}
};

export default customColorsMachine;
