interface Props {
	segments: string[];
	analysisName: string;
}

export default function benchmarkAllowed({ segments, analysisName }: Props) {
	const genderRegex = /gender/;
	const ethnicityRegex = /ethnicity/;
	const representationRegex = /representation/;
	const hiringRegex = /hiring/;
	const specialCase = 'sex assigned at birth';

	if (
		segments &&
		segments.length &&
		segments
			.map(s => s.toLowerCase())
			.some(s => {
				return genderRegex.test(s) || ethnicityRegex.test(s) || s === specialCase;
			}) &&
		(representationRegex.test(analysisName.toLowerCase()) || hiringRegex.test(analysisName.toLowerCase()))
	) {
		return true;
	}

	return false;
}
