import React from 'react';
import { ChartProps } from '../types';

const Table = (props: ChartProps) => {
	return (
		<svg width="100%" height="100%" viewBox="0 0 304 86" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="10"
				y="16"
				width="66"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="10"
				y="33"
				width="42"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="10"
				y="50"
				width="54"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="10"
				y="67"
				width="54"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="109"
				y="16"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="109"
				y="33"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="109"
				y="50"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="109"
				y="67"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="171"
				y="16"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="171"
				y="33"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="171"
				y="50"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="171"
				y="67"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="231"
				y="16"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="231"
				y="33"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="231"
				y="50"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
			<rect
				x="231"
				y="67"
				width="28"
				height="3"
				fill="var(--color-ui-50)"
				fill-opacity="0.2"
				className="animated__table-cell"
			/>
		</svg>
	);
};

export default Table;
