import React, { useState, useRef } from 'react';
import TextField from '../common/TextField';

interface TextFieldStateWrapperProps {
	inputValue?: string;
	onChange?: (value: string) => void;
	placeholder?: string;
	showClose?: boolean;
	type?: string;
	danger?: boolean;
	isSearch?: boolean;
	large?: boolean;
	maxWidth?: number;
	componentType?: string;
}

const TextFieldStateWrapper = ({
	inputValue,
	danger,
	placeholder,
	showClose,
	type,
	isSearch,
	large,
	maxWidth,
	componentType,
}: TextFieldStateWrapperProps) => {
	const [value, setValue] = useState(inputValue || '');
	const ref = useRef(null);
	const [tags, setTags] = useState<string[]>([]);
	const onChange = (e: any) => {
		setValue(e.target.value);
	};

	const onClose = () => {
		setValue('');
	};
	const onAddTag = (tag: string) => {
		if (value !== '' && componentType === 'tags') {
			setTags([...tags, tag]);
			setValue('');
		}
	};
	const onRemoveTag = (tag: string) => {
		setTags([...tags.filter(t => t !== tag)]);
	};

	const onKeyDown = (e: any) => {
		if (e.key === 'Backspace' && value === '' && tags.length > 0) {
			setTags([...tags.slice(0, tags.length - 1)]);
		}
	};

	return (
		<div style={{ maxWidth }}>
			<TextField
				ref={ref}
				componentType={componentType}
				value={value}
				onChange={onChange}
				danger={danger}
				placeholder={placeholder}
				showClose={showClose}
				onClose={onClose}
				type={type}
				isSearch={isSearch}
				large={large}
				tags={tags}
				onBlur={onAddTag}
				onRemoveTag={onRemoveTag}
				onKeyDown={onKeyDown}
			></TextField>
		</div>
	);
};

export default TextFieldStateWrapper;
