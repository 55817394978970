import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
	const fill = props.fill || 'currentColor';
	const width = props.width || '20';
	const height = props.height || '20';

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<path
				fill={fill}
				d="M12.504 13.388 10 15.893c-.815.815-1.797 1.222-2.946 1.222-1.15 0-2.131-.407-2.947-1.222-.815-.816-1.222-1.798-1.222-2.947s.407-2.13 1.222-2.946l2.505-2.504.884.884-2.505 2.504a2.815 2.815 0 0 0-.86 2.061c0 .802.285 1.488.857 2.06.572.572 1.26.859 2.063.86a2.807 2.807 0 0 0 2.065-.856l2.504-2.505.884.884Zm-4.345-.663-.884-.884 4.566-4.566.884.884-4.566 4.566Zm5.23-.22-.885-.885 2.505-2.504c.572-.573.86-1.26.86-2.061 0-.802-.285-1.488-.857-2.06a2.822 2.822 0 0 0-2.063-.86 2.807 2.807 0 0 0-2.065.856L8.38 7.496l-.884-.884L10 4.107c.815-.815 1.797-1.222 2.946-1.222 1.15 0 2.131.407 2.947 1.222.815.816 1.222 1.798 1.222 2.947s-.407 2.13-1.222 2.946l-2.505 2.504Z"
			/>
		</svg>
	);
};
export default SvgComponent;
