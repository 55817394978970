import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { SurveyEditState, SavableSurvey, UiSurvey, PublishSurvey, SurveyArchiveState } from './types';
import { goBack } from 'connected-react-router';
import { cloneDeep } from 'lodash';

const EMAIL_TITLE = 'We’d like to know more about you';
const EMAIL_BODY =
	'Our goal is to make our company a workplace where all employees can be their true selves and contribute to our diverse culture. To achieve this, we’re seeking information about you so we can better measure the overall employee experience.';
const WELCOME_TITLE = 'We’d like to know more about you';
const WELCOME_DESC =
	'Our goal is to make this a workplace where all employees can be their true selves and contribute to our diverse culture. To achieve this, we’re seeking information about you so we can better measure the overall employee experience.';
const THANK_TITLE = 'Thanks for sharing your responses';
const THANK_DESC =
	'Thank you for taking the time to complete the questionnaire. Your participation is invaluable in helping us create a more inclusive and diverse workplace. We appreciate your commitment to making our company a better and more welcoming environment for everyone.';

const initialSurvey: UiSurvey = {
	title: '',
	description: '',
	enterpriseId: '',
	image: '',
	emailSubject: EMAIL_TITLE,
	emailTitle: EMAIL_TITLE,
	emailBody: EMAIL_BODY,
	welcomeTitle: WELCOME_TITLE,
	welcomeDesc: WELCOME_DESC,
	thankTitle: THANK_TITLE,
	thankDesc: THANK_DESC,
	thankImage: '',
	imageId: '',
	questions: [],
	questionOrder: [],
};

export const saveSurveyTemplate = createAsyncThunk<void, SavableSurvey, AsyncThunkConfig>(
	'survey/saveSurveyTemplate',
	async (survey, { getState, dispatch }) => {
		if (survey.globalSurveyId) {
			await axios.put(`${Urls.SurveyStudioApi}surveys/template/update`, survey);
		} else {
			await axios.put(`${Urls.SurveyStudioApi}surveys/template/create`, survey);
		}
		dispatch(goBack());
	}
);

export const duplicateSurveyTemplate = createAsyncThunk<void, SavableSurvey, AsyncThunkConfig>(
	'survey/duplicateSurveyTemplate',
	async (survey, { getState, dispatch }) => {
		await axios.put(`${Urls.SurveyStudioApi}surveys/template/create`, {
			...survey,
			title: `Copy of ${survey.title}`,
		});
	}
);

export const archiveSurveyTemplate = createAsyncThunk<void, SurveyArchiveState, AsyncThunkConfig>(
	'survey/archiveSurveyTemplate',
	async ({ survey, shouldArchive }, { getState, dispatch }) => {
		const categories = survey.categories.map(c => {
			return {
				globalCategoryId: c.globalCategoryId,
				globalQuestionIds: c.questions.map(q => q.globalQuestionId),
			};
		});
		const surveyToSave: SavableSurvey = {
			globalSurveyId: survey.globalSurveyId,
			enterpriseId: survey.enterpriseId,
			title: survey.title,
			description: survey.description,
			isPublished: survey.isPublished,
			isPrivate: survey.isPrivate,
			categories,
			uiElements: cloneDeep(survey.meta.uiElements),
		};

		const archiveValue = shouldArchive ? 'true' : 'false';
		const uiElem = surveyToSave.uiElements.find(u => u.key === 'archive');
		if (uiElem) {
			uiElem.value = archiveValue;
		} else {
			surveyToSave.uiElements.push({
				key: 'archive',
				value: archiveValue,
			});
		}

		await axios.put(`${Urls.SurveyStudioApi}surveys/template/update`, surveyToSave);
	}
);

export const publishSurveyTemplate = createAsyncThunk<void, PublishSurvey, AsyncThunkConfig>(
	'survey/publishSurveyTemplate',
	async (survey, { getState, dispatch }) => {
		try {
			await axios.put(`${Urls.SurveyStudioApi}surveys/template/publish`, survey);
		} catch (error: any) {
			throw new Error(error.response.data);
		}
	}
);

export const deleteSurveyTemplate = createAsyncThunk<void, string, AsyncThunkConfig>(
	'survey/deleteSurveyTemplate',
	async (globalSurveyId, { getState, dispatch }) => {
		try {
			await axios.put(`${Urls.SurveyStudioApi}surveys/template/delete`, {
				globalSurveyId,
			});
		} catch (error: any) {
			throw new Error(error.response.data);
		}
	}
);

export const surveyEditSlice = createSlice({
	name: 'editSurvey',
	initialState: {
		surveyToEdit: initialSurvey,
		questions: [],
		activeTab: 'surveydetails',
	} as SurveyEditState,
	reducers: {
		setActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
		setTitle: (state, action) => {
			state.surveyToEdit.title = action.payload;
		},
		setDescription: (state, action) => {
			state.surveyToEdit.description = action.payload;
		},
		setEnterpriseId: (state, action) => {
			state.surveyToEdit.enterpriseId = action.payload;
		},
		setImage: (state, action) => {
			state.surveyToEdit.image = action.payload;
		},
		setEmailSubject: (state, action) => {
			state.surveyToEdit.emailSubject = action.payload;
		},
		setEmailTitle: (state, action) => {
			state.surveyToEdit.emailTitle = action.payload;
		},
		setEmailBody: (state, action) => {
			state.surveyToEdit.emailBody = action.payload;
		},
		setWelcomeTitle: (state, action) => {
			state.surveyToEdit.welcomeTitle = action.payload;
		},
		setWelcomeDesc: (state, action) => {
			state.surveyToEdit.welcomeDesc = action.payload;
		},
		setThankTitle: (state, action) => {
			state.surveyToEdit.thankTitle = action.payload;
		},
		setThankDesc: (state, action) => {
			state.surveyToEdit.thankDesc = action.payload;
		},
		setThankImage: (state, action) => {
			state.surveyToEdit.thankImage = action.payload;
		},
		setImageId: (state, action) => {
			state.surveyToEdit.imageId = action.payload;
		},
		toggleQuestion: (state, action) => {
			const selectedQuestion = action.payload;
			if (typeof selectedQuestion === 'string') {
				// remove the questionId from questionOrder
				const orderIndex = state.surveyToEdit.questionOrder.findIndex(qid => qid === selectedQuestion);
				if (orderIndex !== -1) {
					state.surveyToEdit.questionOrder.splice(orderIndex, 1);
				}
				return;
			}
			const index = state.surveyToEdit.questions.findIndex(
				q => q.globalQuestionId === selectedQuestion.globalQuestionId
			);
			if (index !== -1) {
				state.surveyToEdit.questions.splice(index, 1);
				const orderIndex = state.surveyToEdit.questionOrder.findIndex(
					qid => qid === selectedQuestion.globalQuestionId
				);
				if (orderIndex !== -1) {
					state.surveyToEdit.questionOrder.splice(orderIndex, 1);
				}
			} else {
				state.surveyToEdit.questions.push(selectedQuestion);
				state.surveyToEdit.questionOrder.push(selectedQuestion.globalQuestionId);
			}
		},
		clearSelectedQuestions: state => {
			state.surveyToEdit.questions = [];
		},
		moveUp: (state, action) => {
			const i = action.payload;
			const values = state.surveyToEdit.questionOrder;
			[values[i - 1], values[i]] = [values[i], values[i - 1]];
		},
		moveDown: (state, action) => {
			const values = state.surveyToEdit.questionOrder;
			const i = action.payload;
			[values[i], values[i + 1]] = [values[i + 1], values[i]];
		},
		clearData: state => {
			state.surveyToEdit = initialSurvey;
			state.activeTab = 'surveydetails';
		},
		setQuestions: (state, action) => {
			state.surveyToEdit.questions = action.payload;
		},
		setInitialised: (state, action) => {
			state.initialised = action.payload;
		},
		setImageUrl: (state, action) => {
			state.surveyToEdit.imageUrl = action.payload;
		},
		setThankImageUrl: (state, action) => {
			state.surveyToEdit.thankImageUrl = action.payload;
		},
		setQuestionOrder: (state, action) => {
			state.surveyToEdit.questionOrder = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(saveSurveyTemplate.fulfilled, (state, action) => {
			state.pending = false;
		});
		builder.addCase(saveSurveyTemplate.pending, (state, action) => {
			state.error = false;
			state.pending = true;
		});
		builder.addCase(saveSurveyTemplate.rejected, (state, action) => {
			state.error = true;
			state.pending = false;
		});
	},
});

export const {
	setTitle,
	setDescription,
	setEnterpriseId,
	setImage,
	setEmailSubject,
	setEmailTitle,
	setEmailBody,
	setWelcomeTitle,
	setWelcomeDesc,
	setThankTitle,
	setThankDesc,
	setThankImage,
	setActiveTab,
	toggleQuestion,
	clearSelectedQuestions,
	moveUp,
	moveDown,
	clearData,
	setImageId,
	setQuestions,
	setInitialised,
	setImageUrl,
	setThankImageUrl,
	setQuestionOrder,
} = surveyEditSlice.actions;

export default surveyEditSlice.reducer;
