import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import sentryMiddleware from './middleware/sentryMiddleware';
import createRootReducer from './rootReducer';
import history from '../routes/history';
import exploreMiddleware from '../explore/exploreMiddleware';

const store = configureStore({
	reducer: createRootReducer(history),
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware().concat(routerMiddleware(history));

		middlewares.push(sentryMiddleware);
		middlewares.push(exploreMiddleware);

		return middlewares;
	},
	devTools:
		process.env.REACT_APP_ENV !== 'prod'
			? {
					name: 'Dandi App devTools',
					trace: true,
					traceLimit: 25,
			  }
			: false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AsyncThunkConfig = {
	state: RootState;
	dispatch: AppDispatch;
};
export default store;
