import AnalysisTypes from '../constants/AnalysisTypes';
import { units } from '../types';
import decimalFormatter from './decimalFormatter';
import precisionRound from './precisionRound';

export default function getFormattedValue(analysisType: string, value: number | undefined, unit?: units) {
	if (typeof value === 'undefined') {
		return '---';
	}

	value = precisionRound(value, 1);

	if (analysisType === AnalysisTypes.Index) {
		return decimalFormatter(value) + 'x';
	}
	if (analysisType === AnalysisTypes.LinearRegression) {
		if (value > 0) {
			return '+' + decimalFormatter(value) + '%';
		}
	}

	if (!unit) {
		return decimalFormatter(value) + '%';
	}

	return value + unit;
}
