import React from 'react';
import Modal from 'antd/lib/modal';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';

interface DeleteModalProps {
	isOpen: boolean;
	pending: boolean;
	onAction: () => void;
	onCancel: () => void;
}

function DeleteModal(props: DeleteModalProps) {
	const { isOpen, pending, onAction, onCancel } = props;
	return (
		<Modal open={isOpen} width={400} footer={null} closable={false} maskClosable={true} onCancel={onCancel}>
			<div className="modal modal--delete">
				<div className="modal__header">
					<div className="modal__title">Delete Payment Method</div>
				</div>
				<div className="modal__content">
					By clicking delete, you permanently remove the payment method from Dandi.
				</div>
				<div className="modal__footer">
					<Button onClick={onCancel} componentType={ButtonTypes.type.TERTIARY}>
						Cancel
					</Button>
					<Button loading={pending} onClick={onAction}>
						Delete
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default DeleteModal;
