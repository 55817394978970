import { forwardRef } from 'react';
import cn from '../lib/cn';

interface IChartContainerProps {
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}

export type Ref = HTMLDivElement;

const ChartContainer = forwardRef<Ref, IChartContainerProps>(({ children, className, ...rest }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				'chart-container',
				'w-full max-h-full min-h-full flex flex-col justify-center relative bg-shade-h3',
				'rounded-[20px]',
				className
			)}
			{...rest}
		>
			{children}
		</div>
	);
});

export default ChartContainer;
