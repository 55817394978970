import React from 'react';
import moment from 'moment';
import Avatar from '../icons/Avatar';
import { Notification } from './models';

interface NotificationsPopoverProps {
	notifications: Notification[];
	onRead: (notificationId: string) => void;
}

export default function NotificationsPopover(props: NotificationsPopoverProps) {
	const { notifications, onRead } = props;
	return (
		<div className="notifications">
			{!notifications || !notifications.length ? (
				<div className="notifications__empty">
					<h3 className="font-subtitle">No unread notifications</h3>
				</div>
			) : (
				<div className="notifications__content">
					{notifications.map(n => (
						<div
							className={n.status === 'New' ? 'notification notification--unread' : 'notification'}
							key={n.notificationId}
							onClick={() => onRead(n.notificationId)}
						>
							<div className="notification__left">
								{n.pictureUrl ? <img src={n.pictureUrl} alt="User Photo" /> : <Avatar />}
							</div>
							<div className="notification__right">
								<div className="notification__text">{n.notificationText}</div>
								<div>{moment(n.createdAt).calendar()}</div>
							</div>
						</div>
					))}
				</div>
			)}
			<div className="cover-bar" />
		</div>
	);
}
