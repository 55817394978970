import ActionTypes from '../../constants/ActionTypes';
import { push, replace } from 'connected-react-router';
import qs from 'qs';
import moment from 'moment';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { generateInEditor } from '../filter/actions';
import objectifyArray from '../../lib/objectifyArray';
import ChartTypes from '../../constants/ChartTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';

export function selectCurrentTime(currentIndex) {
	return {
		type: ActionTypes.SelectCurrentTimeInEditor,
		currentIndex,
	};
}

export function setChartData(reportData) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SetChartDataInEditor,
			reportData,
		});
	};
}

export function setRowHeightInEditor(tableRowHeight) {
	return {
		type: ActionTypes.SetRowHeightInEditor,
		tableRowHeight,
	};
}

export function setSortCriteriaInEditor(sortCriteria) {
	return {
		type: ActionTypes.SetSortCriteriaInEditor,
		sortCriteria,
	};
}

export function setExpandedInEditor(expanded) {
	return {
		type: ActionTypes.SetExpandedInEditor,
		expanded,
	};
}

export function setDateInEditor(date) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SetDateInEditor,
			date,
		});
		dispatch(generateInEditor());
	};
}

export function fetchBenchmarkData(benchmarkId, query) {
	return dispatch => {
		dispatch({
			type: ActionTypes.FetchBenchmarkDataPendingInEditor,
			benchmarkId,
		});
		return axios
			.post(`${Urls.BenchmarkApi}benchmark/query`, query)
			.then(response => {
				const { data } = response;
				if (data.length === 0) {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataNoDataInEditor,
						benchmarkId,
					});
				} else {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataFulfilledInEditor,
						benchmarkId,
						data: data[0].level1Results,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.FetchBenchmarkDataRejectedInEditor,
					benchmarkId,
				});
			});
	};
}

export function setBenchmark(benchmark) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SetBenchmarkInEditor,
			benchmark,
		});
		const { benchmarkId, data, query } = benchmark;
		if (!data) {
			dispatch(fetchBenchmarkData(benchmarkId, query));
		}
	};
}

export function removeBenchmark() {
	return {
		type: ActionTypes.RemoveBenchmarkInEditor,
	};
}

export function setHidePie(hidePie) {
	return {
		type: ActionTypes.SetHidePieInEditor,
		hidePie,
	};
}

export function setChartTypeInEditor(chartType) {
	return (dispatch, getState) => {
		const { search } = getState().router.location;
		const queryObject = qs.parse(search, {
			ignoreQueryPrefix: true,
			arrayFormat: 'repeat',
			comma: false,
		});

		queryObject.chartType = chartType;
		let url = '/editor?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: 'editor' }));

		dispatch({
			type: ActionTypes.SetChartTypeInEditor,
			chartType,
		});
	};
}

export function openEventsModalInEditor() {
	return dispatch => {
		dispatch({
			type: ActionTypes.OpenEventsModal,
		});
	};
}

export function closeEventsModalInEditor() {
	return dispatch => {
		dispatch({
			type: ActionTypes.CloseEventsModal,
		});
	};
}

export function toggleChartEventInEditor(eventId) {
	return dispatch => {
		dispatch({
			type: ActionTypes.ToggleChartEvent,
			eventId,
		});
	};
}

export function addAllChartEventsInEditor(allEvents) {
	return dispatch => {
		dispatch({
			type: ActionTypes.AddAllChartEvents,
			allEvents,
		});
	};
}

export function setChartEventsInEditor(eventIds) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SetChartEvents,
			eventIds,
		});
	};
}

export function removeAllChartEventsInEditor() {
	return dispatch => {
		dispatch({
			type: ActionTypes.RemoveAllChartEvents,
		});
	};
}

export function setYAxisTypeInEditor(yAxisType) {
	return {
		type: ActionTypes.SetYAxisTypeInEditor,
		payload: yAxisType,
	};
}

export function setIndexTypeInEditor(indexType) {
	return {
		type: ActionTypes.SetIndexTypeInEditor,
		payload: indexType,
	};
}
