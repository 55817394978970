import { combineReducers } from 'redux';
import templatesReducer from './template/reducer';
import configurationsReducer from './configuration/reducer';
import packagesReducer from './package/reducer';
import enterprisesReducer from './enterprise/reducer';
import accountReducer from './account/slice';
import surveyReducer from './survey/reducer';

export default combineReducers({
	configurations: configurationsReducer,
	templates: templatesReducer,
	packages: packagesReducer,
	enterprises: enterprisesReducer,
	account: accountReducer,
	survey: surveyReducer,
});
