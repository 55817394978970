import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import WithPermissions from '../../common/WithPermissions';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { getDashboards } from '../../dashboards/actions';
import { track } from '../../lib/segment';
import CreateDashboardModal from './CreateDashboardModal';
import ReportNameModal from './ReportNameModal';
import SelectDashboardModal from './SelectDashboardModal';
import { addReportToDashboard, createDashboardAndAddReport } from './actions';
import './styles.scss';

function AddToDashboardButton({
	dashboards,
	getDashboards,
	addReportToDashboard,
	createDashboardAndAddReport,
	lifecycle,
	data,
	mainSegments,
}) {
	const [reportNameModalIsOpen, setReportNameModalIsOpen] = useState(false);
	const [selectDashboardModalIsOpen, setSelectDashboardModalIsOpen] = useState(false);
	const [createDashboardModalIsOpen, setCreateDashboardModalIsOpen] = useState(false);
	const [reportName, setReportName] = useState('');

	useEffect(() => {
		if (dashboards.length === 0) {
			getDashboards();
		}
	}, []);

	function handleAddToDashboardClick() {
		track(MixPanel.Events.ExplorePrimaryGraphAddToDashboardClick);
		setReportNameModalIsOpen(true);
	}

	function handleReportNameSave(reportName) {
		setReportName(reportName);
		setReportNameModalIsOpen(false);
		setSelectDashboardModalIsOpen(true);
	}

	function handleDashboardAdd(dashboardIds) {
		dashboardIds.forEach(dashboardId => {
			addReportToDashboard(reportName, dashboardId);
		});
		setSelectDashboardModalIsOpen(false);
	}

	function handleDashboardCreate() {
		setSelectDashboardModalIsOpen(false);
		setCreateDashboardModalIsOpen(true);
	}

	function handleDashboardSave(dashboardName) {
		createDashboardAndAddReport(dashboardName, reportName);
		setCreateDashboardModalIsOpen(false);
	}

	let defaultReportName = lifecycle;
	const data2 = data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
	if (data2.length === 1) {
		defaultReportName += ' - ' + data2[0].segment.text;
	}
	if (mainSegments && mainSegments.length > 0) {
		defaultReportName += ' - ' + mainSegments.map(s => s.name).join(', ');
	}

	return (
		<WithPermissions actions={[RbacActions['Explore/SaveReport']]}>
			<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleAddToDashboardClick}>
				Add to dashboard
			</Button>
			<ReportNameModal
				defaultReportName={defaultReportName}
				isOpen={reportNameModalIsOpen}
				onSave={handleReportNameSave}
				onCancel={() => setReportNameModalIsOpen(false)}
			/>
			<SelectDashboardModal
				isOpen={selectDashboardModalIsOpen}
				dashboards={dashboards}
				onAdd={handleDashboardAdd}
				onCreate={handleDashboardCreate}
				onCancel={() => setSelectDashboardModalIsOpen(false)}
			/>
			<CreateDashboardModal
				isOpen={createDashboardModalIsOpen}
				onSave={handleDashboardSave}
				onCancel={() => setCreateDashboardModalIsOpen(false)}
			/>
		</WithPermissions>
	);
}

AddToDashboardButton.propTypes = {
	dashboards: PropTypes.array,
	getDashboards: PropTypes.func,
	addReportToDashboard: PropTypes.func,
	createDashboardAndAddReport: PropTypes.func,
	lifecycle: PropTypes.string,
	data: PropTypes.array,
};

function mapState(state) {
	const dashboards = state.myDashboards.data.map(d => state.dashboards[d]);
	const { lifecycle, data, mainSegments } = state.explore.chart;
	return {
		dashboards,
		lifecycle,
		data,
		mainSegments,
	};
}

const dispatchProps = {
	getDashboards,
	addReportToDashboard,
	createDashboardAndAddReport,
};

export default connect(mapState, dispatchProps)(AddToDashboardButton);
