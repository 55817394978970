import moment from 'moment';
import getBenchmarkData from '../../benchmark/getBenchmarkData';
import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import benchmarkAllowed from '../../lib/benchmarkAllowed';
import { getStateForGenerateReport, getStateForPrimaryData, getStateForSubtable } from '../../store/reducerUtils';
import { ISegmentData } from '../../types';

const DATE_FORMAT = 'DD/MM/YYYY';

const defaultSortCriteria = {
	columnName: 'text',
	ascending: true,
};

interface IChartState {
	lifecycle: string;
	data: ISegmentData[];
	dates: Date[];
	tableRowHeight: number;
	sortCriteria: {
		columnName: string;
		ascending: boolean;
	};
	expanded: boolean;
	chartType: ChartTypes;
	rowFilter: any[];
	columnFilter: any[];
	analysisType?: string;
	benchmark?: any;
	confidence?: any;
	eventIds: string[];
	eventsModalOpen: boolean;
	interval?: string;
	generatePending?: boolean;
	generateError?: boolean;
	generateNoData?: boolean;
	generateInvalidVariation?: boolean;
	showBenchmarkIcon?: boolean;
	generateNotAllowed?: boolean;
	currentIndex?: number;
	currentDate?: any;
	yAxisType: 'count' | 'percentage';
	indexType: 'indexed' | 'percentage';
	allowYAxisToggle: boolean;
	allowIndexToggle: boolean;
	intersectionAttributes?: any[];
}

function getInitialState(): IChartState {
	return {
		lifecycle: '',
		data: [],
		dates: [],
		tableRowHeight: 44,
		sortCriteria: defaultSortCriteria,
		expanded: false,
		chartType: ChartTypes.Bar,
		rowFilter: [],
		columnFilter: [],
		eventIds: [],
		eventsModalOpen: false,
		yAxisType: 'percentage',
		allowYAxisToggle: false,
		allowIndexToggle: false,
		indexType: 'indexed',
	};
}

export default function chartReducer(state: IChartState = getInitialState(), action: any): IChartState {
	switch (action.type) {
		case ActionTypes.SetAnalysisNameInEditor: {
			const { lifecycle } = action;
			if (lifecycle !== state.lifecycle || !lifecycle) {
				return getInitialState();
			} else {
				return state;
			}
		}
		case ActionTypes.GenerateMatrixPendingInEditor:
		case ActionTypes.GeneratePendingInEditor: {
			const { category, filters } = action;
			const showBenchmarkIcon = benchmarkAllowed({
				segments: filters?.map((f: any) => f.type),
				analysisName: category,
			});

			const benchmark = showBenchmarkIcon ? state.benchmark : undefined;
			return {
				...state,
				lifecycle: category,
				data: [],
				generatePending: true,
				generateError: false,
				generateNoData: false,
				generateInvalidVariation: false,
				showBenchmarkIcon,
				benchmark,
			};
		}
		case ActionTypes.GenerateMatrixNoDataInEditor:
		case ActionTypes.GenerateNoDataInEditor:
			return {
				...state,
				generatePending: false,
				generateNoData: true,
				chartType: action.isSubtable ? ChartTypes.Matrix : state.chartType,
			};
		case ActionTypes.GenerateMatrixInvalidVariationInEditor:
		case ActionTypes.GenerateInvalidVariationInEditor:
			return {
				...state,
				generatePending: false,
				generateInvalidVariation: true,
			};
		case ActionTypes.GenerateMatrixRejectedInEditor:
		case ActionTypes.GenerateRejectedInEditor: {
			return {
				...state,
				generatePending: false,
				generateError: true,
				generateNotAllowed: action.status === 400,
			};
		}
		case ActionTypes.GenerateFulfilledInEditor: {
			return getStateForGenerateReport(state, action);
		}
		case ActionTypes.GenerateMatrixFulfilledInEditor: {
			return getStateForSubtable(state, action);
		}
		case ActionTypes.GeneratePrimaryDataFulfilledInEditor: {
			return getStateForPrimaryData(state, action);
		}
		case ActionTypes.SelectCurrentTimeInEditor: {
			const { currentIndex } = action;
			const { dates, analysisType, data } = state;
			let { confidence } = state;
			if (analysisType === AnalysisTypes.LinearRegression && data.length > 1 && currentIndex >= 0) {
				confidence = data[1].series[currentIndex].confidenceScore;
			}
			let currentDate;
			if (currentIndex !== dates.length - 1) {
				currentDate = moment.utc(dates[currentIndex]).format(DATE_FORMAT);
			}
			return {
				...state,
				currentIndex,
				currentDate,
				confidence,
			};
		}
		case ActionTypes.SetYAxisTypeInEditor:
			return {
				...state,
				yAxisType: action.payload,
			};
		case ActionTypes.SetIndexTypeInEditor:
			return {
				...state,
				indexType: action.payload,
			};
		case ActionTypes.ClearFiltersInEditor:
			return getInitialState();
		case ActionTypes.SetChartDataInEditor: {
			return {
				...state,
				...action.reportData,
			};
		}
		case ActionTypes.SetRowHeightInEditor: {
			const { tableRowHeight } = action;
			return {
				...state,
				tableRowHeight,
			};
		}
		case ActionTypes.SetSortCriteriaInEditor: {
			const { sortCriteria } = action;
			return {
				...state,
				sortCriteria,
			};
		}
		case ActionTypes.SetExpandedInEditor: {
			const { expanded } = action;
			return {
				...state,
				expanded,
			};
		}
		case ActionTypes.SetBenchmarkInEditor:
			return {
				...state,
				yAxisType: 'percentage',
				benchmark: action.benchmark,
			};
		case ActionTypes.RemoveBenchmarkInEditor:
			return {
				...state,
				benchmark: undefined,
			};
		case ActionTypes.FetchBenchmarkDataPendingInEditor:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: true,
					error: false,
					noData: false,
				},
			};
		case ActionTypes.FetchBenchmarkDataFulfilledInEditor: {
			let attribute = 'Gender';
			if (state.benchmark.attributes && state.benchmark.attributes.length > 0) {
				attribute = state.benchmark.attributes[0];
			}
			const data = getBenchmarkData(attribute, action.data);

			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					data,
				},
			};
		}
		case ActionTypes.FetchBenchmarkDataRejectedInEditor:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					error: true,
				},
			};
		case ActionTypes.FetchBenchmarkDataNoDataInEditor:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					noData: true,
				},
			};
		case ActionTypes.SetHidePieInEditor:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					hidePie: action.hidePie,
				},
			};
		case ActionTypes.SetChartTypeInEditor:
			return {
				...state,
				chartType: action.chartType,
			};
		case ActionTypes.OpenEventsModal: {
			return {
				...state,
				eventsModalOpen: true,
			};
		}
		case ActionTypes.CloseEventsModal: {
			return {
				...state,
				eventsModalOpen: false,
			};
		}
		case ActionTypes.ToggleChartEvent: {
			const { eventIds } = state;
			const { eventId } = action;

			return {
				...state,
				eventIds: eventIds.includes(eventId) ? eventIds.filter(id => id !== eventId) : [...eventIds, eventId],
			};
		}
		case ActionTypes.AddAllChartEvents: {
			const { allEvents } = action;

			return {
				...state,
				eventIds: [...allEvents.map((event: any) => event.eventId)],
			};
		}
		case ActionTypes.RemoveAllChartEvents: {
			return {
				...state,
				eventIds: [],
			};
		}
		case ActionTypes.SetChartEvents: {
			const { eventIds } = action;
			return {
				...state,
				eventIds,
			};
		}

		default:
			return state;
	}
}
