import produce from 'immer';
import { useEffect, useState } from 'react';

import RadioButton from '../../common/RadioButton';
import AnalysisTypes from '../../constants/AnalysisTypes';
import DropdownArrow from '../../icons/DropdownArrow';
import { AnalysisName } from '../../types';
import FilterDropdown from './FilterDropdown';
import SegmentValues from './SegmentValues';

function getAnalysisNames(node: AnalysisName[], parent?: AnalysisName): AnalysisName[] {
	return node.map(a => ({
		checked: a.checked,
		name: a.name,
		isSubAnalysis: !!a.isSubAnalysis,
		isFunnel: !!a.isFunnel,
		parent,
		source: a.source,
		leaf: !a.children,
	}));
}

function search(level: AnalysisName[], analysisName: string, nodes: number[]) {
	for (let i = 0; i < level.length; i++) {
		if (level[i].name === analysisName && !level[i].children) {
			nodes.push(i);
			return true;
		}
		if (level[i].children) {
			const found = search(level[i].children!, analysisName, nodes);
			if (found) {
				nodes.push(i);
				return true;
			}
		}
	}
	return false;
}

interface AnalysisListProps {
	analysisNames: AnalysisName[];
	onChange: (name: string) => void;
}

function AnalysisList({ analysisNames, onChange }: AnalysisListProps) {
	return (
		<div className="filter-dropdown__list">
			<ul>
				{analysisNames.map(analysisName => (
					<li key={analysisName.name}>
						<RadioButton
							checked={analysisName.checked}
							onClick={() => onChange(analysisName.name)}
							data-test={analysisName.name}
						>
							{analysisName.name}
							{!analysisName.leaf && !analysisName.isFunnel && (
								<DropdownArrow
									width={18}
									height={18}
									style={
										analysisName.checked
											? {
													transform: 'rotate(-90deg)',
											  }
											: {}
									}
								/>
							)}
						</RadioButton>
					</li>
				))}
			</ul>
		</div>
	);
}

interface AnalysisDropdownProps {
	alignDropdownRight?: boolean;
	analysisDisplayName: string;
	analysisName: string;
	analysisType?: AnalysisTypes;
	disabled?: boolean;
	initialAnalysisNames: AnalysisName[];
	isSecondary?: boolean;
	onValueChange?: any;
	setAnalysisName: any;
	setShouldOpen?: any;
	shouldOpen?: boolean;
	useRadio?: boolean;
}

export default function AnalysisDropdown({
	alignDropdownRight,
	analysisDisplayName,
	analysisName,
	analysisType,
	disabled,
	initialAnalysisNames,
	isSecondary,
	onValueChange,
	setAnalysisName,
	setShouldOpen,
	shouldOpen,
	useRadio,
}: AnalysisDropdownProps) {
	const [analysisNamesArray, setAnalysisNamesArray] = useState(getInitialState());
	const [isOpen, setIsOpen] = useState(false);
	const [shouldClose, setShouldClose] = useState(false);
	const [subAnalysisSearch, setSubAnalysisSearch] = useState('');

	useEffect(() => {
		if (shouldOpen) {
			setIsOpen(true);
		}
	}, [shouldOpen]);

	useEffect(() => {
		if (analysisType !== AnalysisTypes.Arithmetic) {
			setAnalysisNamesArray(getInitialState());
		}
	}, [analysisType, analysisName]);

	function getInitialState() {
		let nodes: number[] = [];
		if (analysisName) {
			search(initialAnalysisNames, analysisName, nodes);
		}
		nodes.reverse();
		if (nodes.length === 0) {
			nodes.push(-1);
		}
		const analysisNamesArray: AnalysisName[][] = [];
		let nextLevelNodes = initialAnalysisNames;

		nodes.forEach(node => {
			const analysisNames = getAnalysisNames(nextLevelNodes);

			if (node > -1) {
				analysisNames[node].checked = true;
				nextLevelNodes = nextLevelNodes[node].children ?? [];
			}
			analysisNamesArray.push(analysisNames);
		});
		return analysisNamesArray;
	}

	function updateAnalysis(analysis: AnalysisName | undefined, subAnalyses: any) {
		onValueChange?.();
		if (subAnalyses.length === 0 || !analysis) {
			setAnalysisName({
				analysisDisplayName: '',
				analysisName: '',
				subAnalyses: [],
			});
		} else {
			setAnalysisName({
				analysisDisplayName:
					subAnalyses.length === 1 ? subAnalyses[0].name : `${analysis.name} (${subAnalyses.length})`,
				analysisName: analysis.name,
				subAnalyses,
			});
		}
		return;
	}

	function getSubAnalyses(analysisName: AnalysisName, initialAnalysisNames: AnalysisName[]): AnalysisName[] {
		for (const analysis of initialAnalysisNames) {
			if (analysis.name === analysisName.name) {
				return analysis.children ?? [];
			}
			if (analysis.children) {
				const result = getSubAnalyses(analysisName, analysis.children);
				if (result.length > 0) {
					return result;
				}
			}
		}
		return [];
	}

	function handleChange(level: number, value: string) {
		const currentLevel = analysisNamesArray[level];
		const currentSelection = currentLevel?.find(a => a.name === value);

		if (currentSelection?.leaf) {
			onValueChange?.();
			setShouldOpen?.(true);
		}

		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const analysisNames = analysisNamesArray[level];
				const analysisName = analysisNames.find(a => a.name === value);

				if (analysisName) {
					const checkedAnalysis = analysisNames.find(a => a.checked);
					if (checkedAnalysis) {
						checkedAnalysis.checked = false;
						while (analysisNamesArray.length > level + 1) {
							analysisNamesArray.pop();
						}
					}
					analysisName.checked = true;

					if (!analysisName.leaf && !analysisName.isFunnel) {
						let children: AnalysisName[] | undefined = initialAnalysisNames;
						for (let i = 0; i <= level; i++) {
							const analysisName = analysisNamesArray[i].find(a => a.checked);
							if (analysisName && children) {
								const initialAnalysisName: AnalysisName | undefined = children.find(
									a => a.name === analysisName.name
								);
								if (initialAnalysisName) {
									children = initialAnalysisName.children;
								}
							}
						}
						if (children) {
							if (children.every(a => a.isSubAnalysis)) {
								children = children.map(a => ({
									...a,
								}));
							}
							analysisNamesArray.push(getAnalysisNames(children, analysisName));
						}
					}
				}
			});
		});

		const analysisNames = analysisNamesArray[level];
		const analysisName = analysisNames.find(a => a.name === value);
		if (analysisName) {
			if (analysisName.isFunnel) {
				const subAnalyses = getSubAnalyses(analysisName, initialAnalysisNames);

				setAnalysisName({
					analysisDisplayName: analysisName.name,
					analysisName: analysisName.name,
					isFunnel: true,
					subAnalyses,
				});

				setIsOpen(false);
				setShouldClose(true);
			}

			if (analysisName.leaf) {
				if (analysisName.isSubAnalysis && analysisName.parent) {
					setAnalysisName({
						analysisDisplayName: analysisName.name,
						analysisName: analysisName.parent.name,
						subAnalyses: [analysisName],
					});
				} else {
					setAnalysisName({
						analysisDisplayName: analysisName.name,
						analysisName: analysisName.name,
						subAnalyses: [],
					});
				}
				setIsOpen(false);
				setShouldClose(true);
			}
		}
	}

	function handleClearSelection() {
		updateAnalysis(undefined, []);
	}

	function onSelectAll(checked: boolean, level: number) {
		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const subAnalyses = analysisNamesArray[level];

				if (subAnalyses) {
					subAnalyses.forEach(subAnalysis => {
						subAnalysis.checked = checked;
					});
				}
			});
		});

		const analysisName = analysisNamesArray[level - 1].find(a => a.checked);
		const subAnalyses = analysisNamesArray[level];
		const updatedSubAnalyses = subAnalyses
			.map(subAnalysis => ({
				...subAnalysis,
				checked,
			}))
			.filter(subAnalysis => subAnalysis.checked);

		updateAnalysis(analysisName, updatedSubAnalyses);
	}

	function handleSubAnalysisChange(level: number, subAnalysis: any) {
		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const subAnalyses = analysisNamesArray[level];

				if (subAnalyses) {
					const subAnalysis_ = subAnalyses.find(a => a.source === subAnalysis);
					if (subAnalysis_) {
						subAnalysis_.checked = !subAnalysis_.checked;
					}
				}
			});
		});

		const analysisName = analysisNamesArray[level - 1].find(a => a.checked);
		const subAnalyses = analysisNamesArray[level];
		const updatedSubAnalyses = subAnalyses
			.map(s => {
				if (s.source === subAnalysis) {
					return {
						...s,
						checked: !s.checked,
					};
				} else {
					return s;
				}
			})
			.filter(s => s.checked);

		updateAnalysis(analysisName, updatedSubAnalyses);
	}

	return (
		<FilterDropdown
			alignDropdownRight={alignDropdownRight}
			allowCancel={true}
			disabled={disabled}
			isOpen={isOpen}
			isSecondary={isSecondary}
			label={analysisDisplayName}
			onClearSelection={handleClearSelection}
			placeholder="Analysis"
			setIsOpen={setIsOpen}
			setShouldClose={setShouldClose}
			shouldClose={shouldClose}
		>
			{analysisNamesArray.map((a, i) => {
				if (a.every(a => a.isSubAnalysis)) {
					return (
						<SegmentValues
							values={a.map(subAnalysis => ({
								value: subAnalysis.source!,
								text: subAnalysis.name,
								checked: subAnalysis.checked,
							}))}
							key={i}
							onChange={subAnalysis => handleSubAnalysisChange(i, subAnalysis)}
							onSelectAll={checked => onSelectAll(checked, i)}
							onSearch={setSubAnalysisSearch}
							search={subAnalysisSearch}
							useRadio={useRadio}
						/>
					);
				}

				return <AnalysisList key={i} analysisNames={a} onChange={handleChange.bind(null, i)} />;
			})}
		</FilterDropdown>
	);
}
