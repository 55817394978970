function getContrastedBenchmarkColors(BenchmarkColors: any, length: number) {
	if (!length) return BenchmarkColors;

	if (length % 2 !== 0) {
		length = length + 1;
	}

	return [
		...BenchmarkColors.slice(0, length / 2),
		...BenchmarkColors.slice(BenchmarkColors.length - length / 2, BenchmarkColors.length),
	];
}

export default getContrastedBenchmarkColors;
