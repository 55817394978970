export default function getDynamicDataProp(
	yAxisType: 'count' | 'percentage',
	indexType: 'indexed' | 'percentage',
	columnProps?: any[]
) {
	if (!columnProps || !columnProps.length) {
		return null;
	}

	if (yAxisType === 'count') {
		return columnProps.filter(c => c.source === 'totalCount' || c.source === 'includedCount')[0].source;
	}

	if (indexType === 'percentage') {
		return 'percentage';
	}

	return columnProps[0].source;
}
