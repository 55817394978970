import { Segment } from './types';
import { Criteria } from '../types';
import SEGMENTS from '../list/segment_list';

function mapToHireDateValue(interval?: string) {
	if (!interval) {
		return '';
	}
	switch (interval) {
		case '-7':
			return 'Last week';
		case '-31':
			return 'Last month';
		case '-92':
			return 'Last quarter';
		case '-365':
			return 'Last year';
		default:
			return '';
	}
}

export function getSegmentsFromCriteria(criteria: Criteria): Segment[] {
	const segments: Segment[] = [];

	criteria.predicates.forEach((predicate: any) => {
		segments.push({
			column: predicate.column,
			name: mapColumnToName(predicate.column),
			values: predicate.terms,
		});
	});

	if (criteria.dateColumn === 'primary.hireDate') {
		segments.push({
			column: 'primary.hireDate',
			name: 'Hire Date',
			values: [mapToHireDateValue(criteria.startDate)],
		});
	}

	return segments;
}

function mapColumnToName(column: string) {
	if (column === 'primary.hireDate') {
		return 'Hire Date';
	}
	return SEGMENTS.find((seg: Segment) => seg.column === column)?.name || column;
}
