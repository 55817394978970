import { memo, useEffect } from 'react';
import gsap from 'gsap';
import Markdown from 'react-markdown';
import cn from '../../lib/cn';
import remarkGfm from 'remark-gfm';

const PlainText: React.FC<{ children: any }> = memo(({ children }) => {
	useEffect(() => {
		const ctx = gsap.context(() => {
			const tl = gsap.timeline({});

			tl.fromTo(
				'.animate-insights',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 1,
					stagger: 0.175,
					ease: 'power1.out',
				}
			);
		});

		return () => {
			ctx.revert();
		};
	}, []);

	return (
		<Markdown
			remarkPlugins={[remarkGfm]}
			components={{
				h2: ({ node, children, ...props }) => (
					<p {...props} className={cn('mb-[.8rem] animate-insights invisible leading-snug last:mb-0')}>
						{children}
					</p>
				),
				p: ({ node, ...props }) => (
					<p className={cn('mb-[.8rem] animate-insights invisible leading-snug last:mb-0')} {...props} />
				),
				strong: ({ node, ...props }) => <span className={cn('')} {...props} />,
				ol: ({ node, ...props }) => <span className={cn('mb-[.8rem] ')}>{props.children}</span>,
				li: ({ node, ...props }) => (
					<p className={cn('mb-[.8rem] max-w-prose animate-insights invisible leading-snug')}>
						{props.children}
					</p>
				),
				table: ({ node, ...props }) => (
					<table className={cn('my-[3.2rem] ml-[-1.6rem] divide-y divide-solid divide-shade-6')} {...props} />
				),
				thead: ({ node, ...props }) => (
					<thead
						// className={cn('border-b border-b-shade-6')}
						style={
							{
								// borderBottomStyle: 'solid'
							}
						}
						{...props}
					/>
				),
				tbody: ({ node, ...props }) => (
					<tbody
						className={cn('divide-y divide-solid divide-shade-3')}
						style={{
							borderLeft: 'none',
							borderRight: 'none',
						}}
						{...props}
					/>
				),
				tr: ({ node, ...props }) => (
					<tr
						className={cn('divide-x divide-solid divide-shade-3')}
						style={{
							borderLeft: 'none',
							borderRight: 'none',
						}}
						{...props}
					/>
				),
				th: ({ node, ...props }) => (
					<th
						className={cn('px-[1.6rem] py-[.8rem] text-left text-ui-75')}
						{...props}
						style={{
							borderTop: 'none',
							borderBottom: 'none',
						}}
					/>
				),
				td: ({ node, ...props }) => (
					<td
						className={cn('px-[1.6rem] py-[.8rem] text-left text-ui-75')}
						{...props}
						style={{
							borderTop: 'none',
							borderBottom: 'none',
						}}
					/>
				),
			}}
		>
			{children}
		</Markdown>
	);
});

export default PlainText;
