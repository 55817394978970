import { combineReducers } from 'redux';
import usersReducer from './users/reducer';
import groupsReducer from './groups/reducer';
import rolesReducer from './roles/reducer';
import permissionsReducer from './permissions/reducer';
import billingReducer from './billing/reducer';
import integrationsReducer from './integrations/reducer';
import settingsReducer from './settings/reducer';
import adminConfigReducer from './config/reducer';
import identityReducer from './identity/reducer';

export default combineReducers({
	users: usersReducer,
	groups: groupsReducer,
	roles: rolesReducer,
	permissions: permissionsReducer,
	billing: billingReducer,
	integrations: integrationsReducer,
	settings: settingsReducer,
	adminConfig: adminConfigReducer,
	identity: identityReducer,
});
