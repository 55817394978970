const ChevronLeft = (props: any) => {
	return (
		<svg
			width={props?.size || 20}
			height={props?.size || 20}
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M12.2068 4.01485L6 10.2217L12.2068 16.4285" stroke="currentColor" />
		</svg>
	);
};

export default ChevronLeft;
