import { Children, cloneElement, Component, ReactNode } from 'react';
import errorHandler from '../lib/errorHandler';

interface Props {
	children?: ReactNode;
	className?: string;
}

interface State {
	hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(): State {
		return { hasError: true };
	}

	public componentDidCatch(error: Error) {
		errorHandler.report(error);
	}

	public render() {
		const { hasError } = this.state;
		const { children, className, ...moreProps } = this.props;
		if (hasError) {
			return (
				<div className={className}>
					<div className="error-boundary">Sorry! We’ve encountered an issue displaying this graph.</div>
				</div>
			);
		}

		const child = Children.only(children) as React.ReactElement;

		return cloneElement(child, moreProps);
	}
}
