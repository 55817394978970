import cn from '../lib/cn';
import { useAppSelector } from '../store/hooks';
import ScrollableChartArea from './ScrollableChartArea';
import getChartData from '../lib/getChartData';
import HorizontalBarChart from './HorizontalBarChart/HorizontalBarChart';
import Legend from './Legend';

const HorizontalBarChartWrapper = ({
	reportId,
	dashboardId,
	onHover,
	onHoverEnd,
	onToggle,

	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	dashboardId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
	[key: string]: any;
}) => {
	const { subtable, analysisName } = useAppSelector(state => getChartData(state, reportId));

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={subtable?.diversityAttributes?.map((d: any) => ({
					...d,
					color: d.segment.tokens[0].color,
					analysisName,
				}))}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				overlayData={[]}
				selection={selection}
				status={status}
			/>

			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full')}>
						<div className="explore-chart__main">
							<HorizontalBarChart
								data={subtable?.data}
								diversityAttributes={subtable?.diversityAttributes}
								columnProps={subtable?.columnProps}
								onHover={onHover}
								onHoverEnd={onHoverEnd}
								onToggle={onToggle}
								status={status}
								hoveredItem={hoveredItem}
								selection={selection}
							/>
						</div>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default HorizontalBarChartWrapper;
