import Button from '../../../../common/Button';
import Stack from '../../../../common/Stack';
import ButtonTypes from '../../../../constants/ButtonTypes';
import { HeadScratchProps } from '../../../../icons/HeadScratch';
import QuestionMark from '../../../../icons/QuestionMark';

interface IllustrationCardProps extends React.HTMLAttributes<HTMLDivElement> {
	illustrationConfig?: HeadScratchProps;
	title?: string;
	description?: string;
	buttonCopy?: string;
	onClick?: () => void;
}

const IllustrationCard = ({ illustrationConfig, ...rest }: IllustrationCardProps) => {
	const title = rest.title || 'Wondering what to choose?';
	const description =
		rest.description ||
		'Read the Dandi Self-ID Handbook for detailed answers on creating and running campaigns in Dandi.';
	const buttonCopy = rest.buttonCopy || 'Read the Self-ID handbook';

	const handleButton = () => {
		if (rest.onClick) {
			rest.onClick();
		}
	};

	return (
		<Stack
			flexDirection="row"
			gap="2.4rem"
			alignItems="center"
			justiyContent="flex-start"
			{...rest}
			style={{
				marginTop: '5rem',
				backgroundColor: 'var(--color-shade-3)',
				borderRadius: '.8rem',
				overflow: 'hidden',
				width: '100%',
				...rest.style,
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 200,
					height: 200,
					backgroundColor: 'var(--color-shade-3)',
				}}
			>
				<QuestionMark />
			</div>
			<Stack
				flexDirection="column"
				justifyContent="center"
				alignItems="flex-start"
				style={{
					paddingRight: '2em',
				}}
			>
				<h2>{title}</h2>
				<p style={{ color: 'var(--color-ui-50)' }}>{description}</p>

				<Button onClick={handleButton} componentType={ButtonTypes.type.SECONDARY}>
					{buttonCopy}
				</Button>
			</Stack>
		</Stack>
	);
};

export default IllustrationCard;
