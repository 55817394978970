import { createSlice, createAction } from '@reduxjs/toolkit';
import { getAudiences } from './actions';
import { IAudienceState } from './types';
import { createStaticAudience, updateStaticAudience, overwriteStaticAudience } from '../edit/actions';
import actionTypes from '../../../constants/ActionTypes';

const initialState: IAudienceState = {
	data: [],
	archived: [],
};

const resetAction = createAction(actionTypes.SetEnterpriseIdComplete);

const slice = createSlice({
	name: 'audiences',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAudiences.pending, (state, action) => {
			state.pending = true;
		});
		builder.addCase(getAudiences.fulfilled, (state, action) => {
			state.pending = false;
			state.loaded = true;
			state.data = action.payload.data;
			state.archived = action.payload.archived;
		});
		builder.addCase(getAudiences.rejected, (state, action) => {
			state.pending = false;
			state.error = action.error.message;
		});
		builder.addCase(createStaticAudience.fulfilled, (state, action) => {
			state.data.push(action.payload);
		});
		builder.addCase(updateStaticAudience.fulfilled, (state, action) => {
			const index = state.data.findIndex(a => action.payload.audienceId === a.audienceId);
			if (index !== -1) {
				state.data[index] = action.payload;
			}
		});
		builder.addCase(overwriteStaticAudience.fulfilled, (state, action) => {
			const index = state.data.findIndex(a => action.payload.audienceId === a.audienceId);
			if (index !== -1) {
				state.data[index] = action.payload;
			}
		});
		builder.addCase(resetAction, (state, action) => {
			state.data = [];
			state.archived = [];
			state.loaded = false;
		});
	},
});

export default slice.reducer;
