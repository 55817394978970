import React, { useState, useEffect } from 'react';
import Table from '../detail/Table';
import { toggleExpandedInSubtable, sortInSubtable } from './actions';
import { navigateToDetail } from '../detail/actions';
import ChartTypes from '../../constants/ChartTypes';
import HorizontalBarChart from '../../common/HorizontalBarChart';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function Subtable({ onHover, onHoverEnd, onToggle, hoveredItem, selection, status, ...props }: any) {
	const dispatch = useAppDispatch();
	const { breakdownSegment } = useAppSelector(state => state.explore.filter);
	const {
		lifecycle,
		analysisType,
		employeeAttributes,
		employeeAttribute,
		attributeName,
		sortCriteria,
		columnProps,
		summary,
		groupSummary,
		showGroupSummary,
		expanded,
		subtableChartType,
		data,
		diversityAttributes,
	} = useAppSelector(state => state.explore.subtable);

	function handleNavigateToDetail(...args: any) {
		// @ts-ignore
		dispatch(navigateToDetail(...args));
	}

	if (subtableChartType === ChartTypes.Matrix) {
		return (
			<Table
				lifecycle={lifecycle}
				analysisType={analysisType}
				employeeAttribute={employeeAttribute}
				attributeName={attributeName}
				employeeAttributes={employeeAttributes}
				diversityAttributes={diversityAttributes}
				data={data}
				summary={summary}
				groupSummary={groupSummary}
				showGroupSummary={showGroupSummary}
				expanded={expanded}
				toggleExpansion={() => dispatch(toggleExpandedInSubtable())}
				onSort={props => dispatch(sortInSubtable(props))}
				sortCriteria={sortCriteria}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				hoveredItem={hoveredItem}
				selection={selection}
				columnProps={columnProps}
				navigateToDetail={handleNavigateToDetail}
			/>
		);
	} else if (subtableChartType === ChartTypes.HorizontalBar) {
		return (
			<HorizontalBarChart
				data={data}
				diversityAttributes={diversityAttributes}
				columnProps={columnProps}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				selection={selection}
				status={status}
				hoveredItem={hoveredItem}
			/>
		);
	}

	return null;
}

Subtable.defaultProps = {
	data: [],
	diversityAttributes: [],
	employeeAttributes: [],
};

export default Subtable;
