import Stack from './Stack';
import TextField from './TextField';
import TextArea from './TextArea';
import classNames from 'classnames';

export const MessageBox = ({
	label,
	value,
	onChange,
	noPad,
}: {
	label: string;
	value: string;
	onChange: (e: any) => void;
	noPad?: boolean;
}) => {
	return (
		<Stack
			gap=".8rem"
			style={{
				padding: '0 2px',
			}}
		>
			<div className={classNames('ds-label', { 'ds-label--nopad': noPad })}>{label}</div>
			<div className="ds-input">
				<TextField value={value} onChange={onChange} />
			</div>
		</Stack>
	);
};

export const MessageBoxArea = ({
	label,
	value,
	onChange,
	noPad,
}: {
	label: string;
	value: string;
	onChange: (e: any) => void;
	noPad?: boolean;
}) => {
	return (
		<Stack
			gap=".8rem"
			style={{
				padding: '0 2px',
			}}
		>
			<div
				className={classNames('ds-label', {
					'ds-label--nopad': noPad,
				})}
			>
				{label}
			</div>
			<div className="ds-input">
				<TextArea value={value} onChange={onChange} />
			</div>
		</Stack>
	);
};
