import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import LottieAnimation from '../../../common/LottieAnimation';
import Modal from '../../../common/Modal';
import TextArea from '../../../common/TextArea';
import TextField from '../../../common/TextField';
import { addMessage } from '../../../common/actions';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import { clearFilters } from '../../../editor/filter/actions';
import { track } from '../../../lib/segment';
import Loader from '../../../lottie/graph-loader.json';
import { useAppDispatch } from '../../../store/hooks';
import { TimeInterval } from '../../../types';
import { InputGoal, InputMetric, addGoal, editGoal, setToEdit } from '../../actions';
import Filters from '../../components/Filters';
import Divider from '../../components/MeasureCard/Divider';
import { closeModal } from '../../settings/reducer';
import { IGoal, IVariable } from '../../types';
import { generateStatusFromGoal, getDescriptionFromSegment, getSymbolFromValueType } from '../../utils';
import DesiredValueInput from './DesiredValueInput';
import QuarterMonthPicker from './QuarterMonthPicker';
import { TemplateGroup } from '../../../templates/models';

interface AddGoalProps {
	addGoal: (goal: InputGoal, metric: InputMetric) => Promise<void>;
	editGoal: (id: string, goal: IGoal) => Promise<void>;
	addMessage: typeof addMessage;
	addGoalModalOpen: boolean;
	analysisName: string;
	analysisType: string;
	segmentValue: string;
	filterValue: string;
	currentValue: number | null;
	unit: string;
	metric: IStateMetric;
	valueType: string;
	canBeNegative: boolean;
	lockYearlyInterval: boolean;
	generatePending: boolean;
	attributes: any;
	clearFilters: any;
	filterFunnel: boolean;
	filterNames: string[];
	generateNotAllowed: boolean;
	toEdit?: IGoal | null;
	setToEdit: any;
}

const AddGoal = ({
	addGoal,
	editGoal,
	addGoalModalOpen,
	analysisName,
	segmentValue,
	filterValue,
	currentValue,
	unit,
	metric,
	valueType,
	toEdit,
	setToEdit,
	addMessage,
	canBeNegative,
	lockYearlyInterval,
	analysisType,
	generatePending,
	clearFilters,
	attributes,
	filterFunnel,
	filterNames,
	generateNotAllowed,
}: AddGoalProps) => {
	const dispatch = useAppDispatch();
	const [name, setName] = useState(toEdit?.goalName || '');
	const [description, setDescription] = useState(toEdit?.goalDescription || '');
	const [saveAllowed, setSaveAllowed] = useState(false);
	const [generated, setGenerated] = useState(false);
	const [goalValue, setGoalValue] = useState<number>(currentValue || 0);

	const [endMonth, setEndMonth] = useState<string | undefined>(toEdit?.goalEndPeriod?.month);
	const [endQuarter, setEndQuarter] = useState<string | undefined>(toEdit?.goalEndPeriod?.quarter);
	const [endYear, setEndYear] = useState<string | undefined>(
		toEdit?.goalEndPeriod?.year ?? moment().utc().year().toString()
	);

	const [status, setStatus] = useState<string | undefined>(undefined);

	const [interval, setInterval] = useState<TimeInterval>('Year');

	const generateYearRange = (yearsInFuture: number = 10) => {
		const currentYear = moment().utc().year();
		let years: string[] = [];
		for (let i = 0; i <= yearsInFuture; i++) {
			years.push((currentYear + i).toString());
		}
		return years;
	};

	const calculateStartDate = () => {
		if (interval === 'Year') {
			if (Number(endYear) > moment().utc().year()) {
				return moment(endYear, 'YYYY').startOf('year').format('MM/DD/YYYY');
			}
			return moment().utc().format('MM/DD/YYYY');
		}

		if (interval === 'Quarter') {
			if (endMonth) {
				return moment(endYear + endMonth, 'YYYYM')
					.startOf('quarter')
					.format('MM/DD/YYYY');
			} else {
				return moment(endYear! + endQuarter, 'YYYYQ')
					.startOf('quarter')
					.format('MM/DD/YYYY');
			}
		}

		if (interval === 'Month') {
			return moment(endYear! + endMonth, 'YYYYM')
				.startOf('month')
				.format('MM/DD/YYYY');
		}
	};

	const availableTimeIntervals = () => {
		let intervals: TimeInterval[] = ['Year'];

		if (endQuarter || endMonth) intervals.push('Quarter');
		if (endMonth) intervals.push('Month');

		if (interval === 'Quarter' && !endQuarter && !endMonth) {
			setInterval('Year');
		} else if (interval === 'Month' && !endMonth) {
			setInterval('Quarter');
		}

		return intervals;
	};

	const handleSave = async () => {
		const newGoal: InputGoal = {
			goalName: name,
			goalDescription: description,
			goalStatus: 'ACTIVE',
			goalEndDate: calculateEndDate(),
			goalEndPeriod: {
				month: endMonth ? moment(endMonth, 'M').format('MMM') : '',
				quarter: endQuarter ?? '',
				year: endYear ?? '',
			},
		};

		const newMetric: InputMetric = {
			...metric,
			interval,
			registrationDate: moment().utc().format('MM/DD/YYYY'),
			trajectories: [
				{
					desiredValue: goalValue,
					initialValue: {
						value: currentValue || 0,
						valueType,
					},
					startDate: calculateStartDate()!,
					endDate: calculateEndDate(),
					trajectory: 'GREATER_EQUAL',
				},
			],
		};
		handleCloseModal();

		if (toEdit) {
			const metricToEdit = toEdit?.metricRegistrationRequest?.metrics[0];
			await editGoal(toEdit.goalId, {
				...toEdit,
				goalName: name,
				goalDescription: description,
				metricRegistrationRequest: {
					...toEdit.metricRegistrationRequest,
					metrics: [
						{
							...metricToEdit,
							trajectories: [
								{
									...metricToEdit.trajectories[0],
									desiredValue: goalValue,
								},
							],
						},
					],
				},
			});

			setToEdit();
			addMessage(`Goal "${name}" edited successfully`);

			track(MixPanel.Events.GoalEdit);
		} else {
			await addGoal(newGoal, newMetric);
			addMessage(`Goal "${name}" added successfully`);

			track(MixPanel.Events.GoalSave);
		}
	};

	const generateStatus = () => {
		let generated = '';

		if (toEdit) {
			const unit = getSymbolFromValueType(
				toEdit?.metricRegistrationRequest?.metrics[0].trajectories[0].initialValue.valueType
			);
			return generateStatusFromGoal(toEdit, attributes, toEdit?.metricTrajectoryValues[0]?.currentValue, unit);
		}

		if (analysisName) {
			generated += analysisName;
		}
		if (segmentValue) {
			generated += ', ' + segmentValue;
		}
		if (filterValue) {
			generated += ', ' + filterValue;
		}
		const roundedValue = Math.round((currentValue ?? 0) * 10) / 10;
		generated += ' (' + roundedValue + unit + ')';

		return generated;
	};

	const handleCloseModal = () => {
		clearFilters();
		dispatch(closeModal('goal'));
		setToEdit();
	};

	const handleCancel = () => {
		track(MixPanel.Events.GoalCancelClick);
		handleCloseModal();
	};

	useEffect(() => {
		setStatus(generateStatus());
	}, [toEdit, analysisName, segmentValue, filterValue, currentValue, unit]);

	useEffect(() => {
		setEndYear(moment().utc().year().toString());
		setEndMonth(undefined);
		setEndQuarter(undefined);
	}, [generated]);

	const calculateEndDate = () => {
		if (!endYear) {
			return moment().utc().endOf('year').format('MM/DD/YYYY');
		}

		if (endMonth && endYear) {
			const endOfMonth = moment(`${endMonth}/01/${endYear}`).endOf('month').format('DD');
			return moment(`${endMonth}/${endOfMonth}/${endYear}`).format('MM/DD/YYYY');
		}

		if (endQuarter && endYear) {
			const quarter = endQuarter.split('Q')[1];
			const month = Number(quarter) * 3;
			const endOfMonth = moment(`${month}/01/${endYear}`).endOf('month').format('DD');
			return moment(`${month}/${endOfMonth}/${endYear}`).format('MM/DD/YYYY');
		}

		return moment(endYear, 'YYYY').endOf('year').format('MM/DD/YYYY');
	};

	useEffect(() => {
		setGoalValue(
			Number(toEdit?.metricRegistrationRequest?.metrics[0]?.trajectories[0].desiredValue) || currentValue || 0
		);
	}, [currentValue]);

	useEffect(() => {
		if (analysisName && segmentValue && filterValue) {
			return;
		}

		setGenerated(false);
	}, [analysisName, segmentValue, filterValue]);

	useEffect(() => {
		if (!name || name.length === 0 || !endYear) {
			setSaveAllowed(false);
			return;
		}

		setSaveAllowed(true);
	}, [name, endYear]);

	return (
		<Modal
			visible={addGoalModalOpen}
			width={800}
			footer={null}
			closable={false}
			maskClosable
			destroyOnClose
			zIndex={2000}
			onCancel={handleCancel}
		>
			<div className="measure-modal modal" data-test="add-measure-modal">
				<form onSubmit={handleSave}>
					<div className="modal__header">
						<div className="modal__title">{toEdit ? 'Edit' : 'Add'} Goal</div>
					</div>
					<div className="modal__content">
						{!toEdit && (
							<>
								<div className="modal__label">Select Analysis</div>
								<Filters
									useRadio={[true, true, true]}
									onGenerate={() => {
										setGenerated(true);
									}}
									filteredAnalysisNames={
										filterNames ? filterNames : filterFunnel ? ['Recruiting Funnel'] : undefined
									}
								/>
								<Divider />
							</>
						)}
						{generatePending && (
							<div>
								<LottieAnimation
									autoplay={true}
									loop={true}
									width={30}
									height={30}
									animation={Loader}
								></LottieAnimation>
							</div>
						)}
						{generateNotAllowed && (
							<div
								style={{
									paddingTop: '32px',
									paddingBottom: '32px',
								}}
							>
								You will need the Explore:View feature permission and Analysis Permissions configured to
								create this goal. Please reach out to your administrator.
							</div>
						)}
						{((generated && !generatePending && !generateNotAllowed) || toEdit) && (
							<>
								<div className="modal__content__grid">
									<div className="modal__content__grid__item">
										<div className="modal__label">Current status</div>
										<div>
											<span>{status}</span>
										</div>
									</div>
									<div className="modal__content__grid__item">
										<div className="modal__label">Goal</div>
										<DesiredValueInput
											value={goalValue}
											onChange={setGoalValue}
											unit={
												unit ||
												getSymbolFromValueType(
													toEdit?.metricRegistrationRequest?.metrics[0].trajectories[0]
														.initialValue.valueType || ''
												)
											}
											canBeNegative={canBeNegative}
											maxValue={analysisType === 'Percentage' ? 100 : undefined}
										/>
									</div>
									{!toEdit && (
										<>
											<div className="modal__content__grid__item">
												<div className="modal__label">Set goal for</div>
												<div className="add-goal__dates-container">
													<Dropdown
														options={generateYearRange()}
														onClick={(option: any) => setEndYear(option)}
														selectedOption={endYear}
														placeholder="Select Year"
													></Dropdown>
													<QuarterMonthPicker
														quarter={endQuarter}
														setQuarter={quarter => setEndQuarter(quarter)}
														month={endMonth}
														setMonth={month => setEndMonth(month)}
														selectedCurrentYear={
															endYear === moment().utc().year().toString()
														}
													/>
												</div>
											</div>
											<div className="modal__content__grid__item">
												<div className="modal__label">Time interval</div>
												<div>
													<Dropdown
														options={availableTimeIntervals()}
														disabled={lockYearlyInterval || (!endQuarter && !endMonth)}
														onClick={(option: any) => setInterval(option)}
														selectedOption={interval}
														placeholder="Select Interval"
													></Dropdown>
													{lockYearlyInterval ||
														(!endQuarter && !endMonth ? (
															<div />
														) : (
															<div className="included-data">
																Includes data starting from {calculateStartDate()}
															</div>
														))}
												</div>
											</div>
										</>
									)}
								</div>
								<div className="modal__label">Name</div>
								<TextField
									value={name}
									onChange={e => setName(e.target.value)}
									placeholder="Enter name"
									data-test="measure-modal-name"
								></TextField>
								<div className="modal__label">Description</div>
								<TextArea
									value={description}
									onChange={e => setDescription(e.target.value)}
									placeholder="Enter description"
								/>
							</>
						)}
					</div>
					<div className="modal__footer">
						<Button classes={['mr8']} onClick={handleCancel} componentType={ButtonTypes.type.SECONDARY}>
							Cancel
						</Button>
						<Button
							data-test="measure-modal-save"
							type="submit"
							onClick={handleSave}
							componentType={ButtonTypes.type.PRIMARY}
							disabled={!saveAllowed}
						>
							Save
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

const dispatchProps = {
	addGoal,
	editGoal,
	setToEdit,
	addMessage,
	clearFilters,
};

interface IStateMetric {
	analysisName: string;
	mainVariables: {
		mv1?: IVariable;
		mv2?: IVariable;
		mv3?: IVariable;
	};
	controlVariables: {
		cv1?: IVariable;
		cv2?: IVariable;
		cv3?: IVariable;
	};
	subAnalysisName?: string;
}

function mapState(state: any) {
	const { measure } = state;
	const { filter, chart } = state.editor;

	const mainSegments = chart.mainSegments;
	const filterSegment = chart.filterSegment;

	const attributes = filter.attributes;

	let mainVariables: any = {};
	let controlVariables: any = {};

	if (mainSegments && mainSegments.length > 0) {
		mainVariables['mv1'] = {
			analysisName: filter.analysisName,
			columnName: '',
			originalName: mainSegments[0].name.replace(/\s+/g, ''),
			value: mainSegments[0].values[0],
		};
	}
	if (mainSegments && mainSegments.length > 1) {
		mainVariables['mv2'] = {
			analysisName: filter.analysisName,
			columnName: '',
			originalName: mainSegments[1].name.replace(/\s+/g, ''),
			value: mainSegments[1].values[0],
		};
	}
	if (mainSegments && mainSegments.length > 2) {
		mainVariables['mv3'] = {
			analysisName: filter.analysisName,
			columnName: '',
			originalName: mainSegments[2].name.replace(/\s+/g, ''),
			value: mainSegments[2].values[0],
		};
	}

	if (filterSegment) {
		controlVariables['cv1'] = {
			analysisName: filter.analysisName,
			columnName: '',
			originalName: filterSegment.name.replace(/\s+/g, ''),
			value: filterSegment.values[0],
		};
	}

	let segmentValue = '';
	filter.mainSegments.forEach((segment: any, index: number) => {
		if (index > 0) {
			segmentValue += ', ';
		}
		segmentValue += getDescriptionFromSegment(segment, attributes);
	});

	let filterValue: string = '';

	if (filter.filterSegment) {
		filterValue = getDescriptionFromSegment(filter.filterSegment, attributes);
	}

	let currentValue = null;
	const columnProps = chart?.columnProps;
	let propName = '';
	let unit = '';
	if (columnProps && columnProps.length > 0) {
		propName = chart?.columnProps[0]?.source;
		unit = chart?.columnProps[0]?.unit;
	}

	if (chart.data && chart.data.length > 0) {
		currentValue =
			chart.data[chart.data.length - 1].series[chart.data[chart.data.length - 1].series.length - 1][propName];
	}

	const metric: IStateMetric = {
		analysisName: filter.analysisName,
		mainVariables,
		controlVariables,
		subAnalysisName: filter.subAnalyses?.length > 0 ? filter.subAnalyses[0]?.name : undefined,
	};

	const { groups } = state.templates;

	let mainColumnIsTotal = false;
	groups.forEach((templateGroup: TemplateGroup) => {
		if (templateGroup?.name === filter?.analysisName) {
			const template = templateGroup?.templates?.[0];
			if (template?.mainColumn?.toLowerCase() === 'total') {
				mainColumnIsTotal = true;
			}
		}
	});

	let filterFunnel = false;
	let filterNames: any[] = [];

	groups.forEach((templateGroup: TemplateGroup) => {
		const template = templateGroup?.templates?.[0];
		if (template?.supportedGraphs?.includes('funnel')) {
			filterFunnel = true;
			filterNames.push(template.analysisName);
		}
	});

	const valueType = chart.analysisType || '';

	return {
		generateNotAllowed: chart.generateNotAllowed,
		addGoalModalOpen: measure.settings.addGoalModalOpen,
		analysisName: filter.analysisName,
		attributes,
		segmentValue,
		filterValue,
		currentValue,
		unit,
		metric,
		valueType,
		toEdit: measure.goals.toEdit,
		generatePending: chart.generatePending,
		analysisType: chart.analysisType,
		canBeNegative: chart.analysisType === 'Linear Regression',
		lockYearlyInterval:
			chart.analysisType === 'Linear Regression' || chart.analysisType === 'Arithmetic' || mainColumnIsTotal,
		filterFunnel,
		filterNames,
	};
}

export default connect(mapState, dispatchProps)(AddGoal);
