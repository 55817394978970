import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import isEmpty from 'lodash/isEmpty';
import AnalysisTypes from '../../constants/AnalysisTypes';
import getFormattedValue from '../../lib/getFormattedValue';
import blankEmployeeCount from '../../lib/blankEmployeeCount';

function getFields(columnProps, employeeAttribute, diversityAttributes) {
	let fields = [employeeAttribute];
	diversityAttributes.forEach(da => {
		const daText = da.segment.text;
		columnProps.forEach(columnProp => {
			fields.push(`${daText} ${columnProp.header}`);
		});
	});
	return fields;
}

function getRowData(data, analysisType, columnProps, diversityAttributes) {
	let rowData = [];
	diversityAttributes.forEach(da => {
		const daPrefix = da.label.toLowerCase().replace(/ /g, '-');
		columnProps.forEach((columnProp, i) => {
			let val = data[`${daPrefix}-${columnProp.source}`];
			if (i === 0) {
				val = getFormattedValue(analysisType, val);
			}
			val = blankEmployeeCount(val);
			rowData.push(val);
		});
	});
	return rowData;
}

function getSummaryRow(summary, analysisType, columnProps, diversityAttributes, isGroupSummary) {
	let row = [];
	if (isGroupSummary) {
		row.push('Filtered Average');
	} else {
		row.push('Average');
	}
	diversityAttributes.forEach(da => {
		const daProp = da.label.toLowerCase().replace(/ /g, '-');
		const data = summary[daProp];
		columnProps.forEach((columnProp, i) => {
			let val = data[columnProp.source];
			if (i === 0) {
				val = getFormattedValue(analysisType, val);
			}
			val = blankEmployeeCount(val);
			row.push(val);
		});
	});
	return row;
}

export default function exportCsv(
	data,
	summary,
	groupSummary,
	lifecycle,
	analysisType,
	columnProps,
	employeeAttribute,
	diversityAttributes,
	period,
	shouldReturnCsv
) {
	const fields = getFields(columnProps, employeeAttribute, diversityAttributes);

	const dataArray = data.map(d => {
		let dataRow = [d.label];
		dataRow = dataRow.concat(getRowData(d, analysisType, columnProps, diversityAttributes));
		return dataRow;
	});

	if (!isEmpty(groupSummary)) {
		const groupSummaryRow = getSummaryRow(groupSummary, analysisType, columnProps, diversityAttributes, true);
		dataArray.push(groupSummaryRow);
	}

	const summaryRow = getSummaryRow(summary, analysisType, columnProps, diversityAttributes);
	dataArray.push(summaryRow);

	const csv = Papa.unparse({
		fields,
		data: dataArray.map(blankEmployeeCount),
	});

	if (shouldReturnCsv) {
		return csv;
	}

	let filename = `Dandi ${lifecycle} ${employeeAttribute} ${period}.csv`;
	filename = filename.replace(/ /g, '_');

	const blob = new Blob([csv]);
	saveAs(blob, filename);
}
