import * as React from 'react';

function SvgComponent(props: any) {
	return (
		<svg
			width={19}
			height={20}
			viewBox="0 0 19 20"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.23438 9.11348C4.23438 6.69632 6.19388 4.73682 8.61104 4.73682C11.0282 4.73682 12.9877 6.69632 12.9877 9.11348C12.9877 11.5306 11.0282 13.4901 8.61104 13.4901C6.19388 13.4901 4.23438 11.5306 4.23438 9.11348ZM8.61104 3.73682C5.64159 3.73682 3.23438 6.14403 3.23438 9.11348C3.23438 12.0829 5.64159 14.4901 8.61104 14.4901C9.91572 14.4901 11.1119 14.0255 12.043 13.2525L14.9075 16.117C15.1027 16.3123 15.4193 16.3123 15.6146 16.117C15.8099 15.9218 15.8099 15.6052 15.6146 15.4099L12.7501 12.5454C13.523 11.6143 13.9877 10.4182 13.9877 9.11348C13.9877 6.14403 11.5805 3.73682 8.61104 3.73682Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgComponent;
