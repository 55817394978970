import { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import { ReminderEmail, Survey } from './types';

import ButtonTypes from '../../../constants/ButtonTypes';
import Button from '../../../common/Button';
import Stack from '../../../common/Stack';
import { MessageBox } from '../../../common/MessageBox';
import { MessageBoxArea } from '../../../common/MessageBox';
import moment from 'moment';
import { sortBy } from 'lodash';

function getReminderProps(survey: Survey) {
	let reminderSubject = survey.meta.uiElements.find(u => u.key === 'remindEmailSubject')?.value;
	if (!reminderSubject) {
		reminderSubject = survey.meta.uiElements.find(u => u.key === 'emailSubject')?.value;
	}
	let reminderTitle = survey.meta.uiElements.find(u => u.key === 'remindEmailTitle')?.value;
	if (!reminderTitle) {
		reminderTitle = survey.meta.uiElements.find(u => u.key === 'emailTitle')?.value;
	}
	let reminderText = survey.meta.uiElements.find(u => u.key === 'remindEmailBody')?.value;
	if (!reminderText) {
		reminderText = survey.meta.uiElements.find(u => u.key === 'emailBody')?.value;
	}
	return {
		reminderSubject,
		reminderTitle,
		reminderText,
	};
}

interface SendReminderModalProps {
	isOpen: boolean;
	onAction: (reminderProps: ReminderEmail) => void;
	onCancel: (e: any) => void;
	survey: Survey;
}

export default function SendReminderModal({ isOpen, onAction, onCancel, survey }: SendReminderModalProps) {
	const reminder = getReminderProps(survey);
	const [subject, setSubject] = useState<string>(reminder.reminderSubject);
	const [title, setTitle] = useState(reminder.reminderTitle);
	const [text, setText] = useState(reminder.reminderText);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isOpen) {
			const reminder = getReminderProps(survey);
			setSubject(reminder.reminderSubject);
			setTitle(reminder.reminderTitle);
			setText(reminder.reminderText);
		}
	}, [isOpen]);

	const handleClear = (): void => {
		setSubject('');
		setTitle('');
		setText('');
	};

	const reminders = sortBy(survey.meta.mailRemindersHistory, r => -moment(r.startedAt).valueOf());

	const handleSubmit = async () => {
		setLoading(true);
		await onAction({ subject, title, text });
		setLoading(false);
	};

	return (
		<Modal
			open={isOpen}
			width={600}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			destroyOnClose={true}
			zIndex={3000}
		>
			<div className="modal send-reminder-modal" onClick={e => e.stopPropagation()}>
				<div className="modal__header">
					<div className="modal__title">Send reminder email</div>
				</div>
				<div className="modal__content">
					<Stack gap="1.6rem">
						<p>
							Sending a reminder will email all members of the original audience that have not submitted
							the campaign. You can customize the reminder email below.
						</p>
						<MessageBox
							label="Subject"
							value={subject}
							onChange={(e: any) => setSubject(e.target.value)}
							noPad
						/>
						<MessageBoxArea
							label="Title"
							value={title}
							onChange={(e: any) => setTitle(e.target.value)}
							noPad
						/>
						<MessageBoxArea
							label="Text"
							value={text}
							onChange={(e: any) => setText(e.target.value)}
							noPad
						/>
						{reminders.length ? (
							<div
								style={{
									paddingTop: '0.8rem',
									borderTop: '1px solid var(--color-ui-10)',
								}}
							>
								<div className="ds-label ds-label--nopad" style={{ marginBottom: '0.8rem' }}>
									Previous reminders
								</div>
								<div className="vertical-scrollbar">
									{reminders.map(r => (
										<div
											className="font-text"
											style={{
												color: 'var(--color-ui-100)',
											}}
										>
											{moment(r.startedAt).format('MMM DD, YYYY')}
										</div>
									))}
								</div>
							</div>
						) : null}
					</Stack>
				</div>
				<div className="modal__footer">
					<Stack
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						style={{ width: '100%' }}
					>
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
							Cancel
						</Button>
						<Stack flexDirection="row" gap=".8rem">
							<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear}>
								Clear
							</Button>
							<Button onClick={handleSubmit} disabled={!subject || !title || !text} loading={loading}>
								Send reminder
							</Button>
						</Stack>
					</Stack>
				</div>
			</div>
		</Modal>
	);
}
