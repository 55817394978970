import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={71} height={238} viewBox="0 0 71 238" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M35.907 68.78s13.514 1.675 14.132 18.206c.44 11.78-6.319 19.648-17.839 19.648H1.478v-9.823h4.956S.488 91.407.488 82.565c0-9.825 6.938-16.7 13.875-16.7 7.928 0 13.676 5.123 14.865 13.753.958 6.955 2.011 17.401 5.741 17.401 4.827 0 7.63-3.12 7.63-9.406 0 0 .067-8.157-6.69-10.041V68.78h-.002ZM23.281 96.81h2.969c.267 0 .426-.303.263-.513-2.386-3.094-3.557-10.403-4.718-14.715-1.004-3.728-2.974-5.894-6.443-5.894-2.973 0-5.946 2.947-5.946 8.35 0 7.86 4.955 12.773 13.875 12.773ZM43.258 127.045c-.317 0-.45.403-.191.586 2.08 1.466 6.972 5.756 6.972 13.659 0 11.297-7.93 18.509-20.812 18.509H1.478v-9.824h27.749c6.937 0 11.892-3.618 11.892-10.003 0-7.367-4.956-12.927-15.361-12.927H1.478v-9.825h47.57v9.825h-5.79ZM41.122 205.266H70.85v9.825H1.478v-9.825h6.785c.322 0 .45-.41.186-.59-2.3-1.558-7.96-6.245-7.96-14.636 0-12.28 10.9-22.44 24.774-22.44 13.875 0 24.775 10.158 24.775 22.44 0 9.293-6.086 13.24-9.053 14.611-.318.145-.212.615.137.615Zm-15.86 0c8.919 0 15.856-6.878 15.856-14.245 0-7.368-6.937-13.598-15.856-13.598s-15.856 6.23-15.856 13.598c0 7.369 6.937 14.245 15.856 14.245ZM1.479 237.802v-9.823h47.57v9.823H1.478Zm55.333 0v-9.823h10.004v9.823H56.812ZM70.85 24.374v-6.135h-9.91v6.135c0 13.262-10.902 24.07-24.776 24.07s-24.776-10.806-24.776-24.07v-6.135h-9.91v6.135c0 19.157 15.857 34.876 34.686 34.876 18.83 0 34.686-15.719 34.686-34.876ZM55.489 11.11V.303H44.704V11.11h10.785ZM28.257 11.11V.303H17.472V11.11h10.785Z"
		/>
	</svg>
);
export default SvgComponent;
