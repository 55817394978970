import ActionTypes from '../constants/ActionTypes';
import produce from 'immer';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

interface ReducerState {
	data: any[];
	error?: boolean;
	pending?: boolean;
	searchText?: string;
}

export default function myDashboardsReducer(state: ReducerState = { data: [] }, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.GetDashboardsPending:
		case ActionTypes.GetSharedDashboardsPending: {
			return {
				...state,
				error: false,
				pending: true,
				data: [],
			};
		}
		case ActionTypes.GetDashboardsRejected:
		case ActionTypes.GetSharedDashboardsRejected: {
			return {
				...state,
				pending: false,
				error: true,
			};
		}
		case ActionTypes.GetDashboardsFulfilled:
		case ActionTypes.GetSharedDashboardsFulfilled: {
			let { dashboards } = action;
			dashboards = orderBy(dashboards, [d => moment.utc(d.dateAdded).toDate()], ['desc']);
			return {
				...state,
				pending: false,
				data: dashboards.map((d: any) => d.dashboardId),
			};
		}
		case ActionTypes.DuplicateDashboardFulfilled:
		case ActionTypes.CreateDashboardFulfilled: {
			const { dashboard } = action;
			return produce(state, draftState => {
				draftState.data.unshift(dashboard.dashboardId);
			});
		}
		case ActionTypes.DeleteDashboardFulfilled: {
			const { dashboardId } = action;
			return produce(state, draftState => {
				draftState.data = draftState.data.filter(d => d !== dashboardId);
			});
		}
		case ActionTypes.SetSearchTextInMyDashboards:
			return {
				...state,
				searchText: action.searchText,
			};
		case ActionTypes.SignOutFulfilled:
			return { data: [] };
		default:
			return state;
	}
}
