import { push } from 'connected-react-router';
import { useState } from 'react';

import Button from '../common/Button';
import Popover from '../common/Popover';
import ButtonTypes from '../constants/ButtonTypes';
import MixPanel from '../constants/MixPanel';
import DropdownArrow from '../icons/DropdownArrow';
import HMore from '../icons/HMoreVertical';
import PlusIcon from '../icons/Plus';
import cn from '../lib/cn';
import { track } from '../lib/segment';
import { useAppDispatch } from '../store/hooks';
import { resetChat } from './chat/reducer';
import ConversationMenu from './ConversationMenu';
import { Conversation } from './conversations/reducer';
import useConversations from './useConversations';

const MAX_VISIBLE_ITEMS = 5;
// Set a default date if updated_at is empty or missing
const PLACEHOLDER_DATE = '2023-01-01T00:00:00.000Z';

export const getSortedConversations = (conversations: Conversation) =>
	Object.entries(conversations)
		.map(([key, value]) => ({
			id: key,
			...value,
			updated_at: value.updated_at || PLACEHOLDER_DATE,
		}))
		.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

export type ConversationElement = {
	id: string;
	[key: string]: any;
};

const ConversationsList: React.FC<{ conversation: ConversationElement }> = ({ conversation }) => {
	const dispatch = useAppDispatch();
	const [moreOpen, setMoreOpen] = useState(false);

	const handleNavigateToConversation = (conversationId: string) => {
		track(MixPanel.Events.AIChatPageClickHistoryChat);
		dispatch(resetChat());
		dispatch(push(`/ai/${conversationId}`));
	};

	const conversationName = conversation?.name || conversation.id;

	return (
		<div className={cn('flex')}>
			<button
				key={conversation.id}
				onClick={() => handleNavigateToConversation(conversation.id)}
				className={cn(
					'w-avail pl-[1.6rem] pr-[1.6rem] h-[40px] flex items-center justify-between',
					'bg-shade-3 text-ui-75 rounded-[1rem]',
					'hover:bg-shade-4 hover:text-ui-100'
				)}
			>
				<span className={cn('truncate mr-[1.6rem]')}>{conversationName}</span>
			</button>
			<Popover
				key={`${conversation.id}-popover`}
				placement="bottomRight"
				visible={moreOpen}
				onVisibleChange={() => {
					setMoreOpen(!moreOpen);
				}}
				content={
					<ConversationMenu
						conversationId={conversation.id}
						conversationName={conversationName}
						onClose={() => setMoreOpen(false)}
					/>
				}
				overlayClassName="popover--no-padding"
			>
				<div className={cn('absolute right-[1.6rem] mt-[1rem] rounded-full')}>
					<HMore className="icon" />
				</div>
			</Popover>
		</div>
	);
};

interface LeftPanelProps {
	className?: string;
	styles?: { [key: string]: string };
}

const LeftPanel: React.FC<LeftPanelProps> = ({ className, styles }) => {
	const dispatch = useAppDispatch();
	const { conversations } = useConversations();
	const [showAll, setShowAll] = useState(false);

	const classNames =
		className ||
		cn(
			'absolute h-full left-0 w-[320px] h-full pt-[120px] px-[2rem] pb-[1.6rem] vertical gap-10',
			'hidden sm:hidden lg:flex'
		);

	const handleNewChat = () => {
		track(MixPanel.Events.AIChatPageClickNewChat);
		dispatch(resetChat());
		dispatch(push('/ai'));
	};

	const handleShowMore = () => {
		setShowAll(!showAll);
	};

	const sortedConversations = getSortedConversations(conversations);

	const visibleConversations = showAll ? sortedConversations : sortedConversations.slice(0, MAX_VISIBLE_ITEMS);
	const containerHeight = showAll ? 'auto' : `${Math.min(sortedConversations.length, MAX_VISIBLE_ITEMS) * 4.6}rem`;

	return (
		<div className={classNames} style={styles || { background: 'var(--color-shade-h2)' }}>
			<Button onClick={handleNewChat} componentType={ButtonTypes.type.SECONDARY} classes={[cn('justify-start')]}>
				<div className={cn('flex items-center gap-[.8rem]')}>
					<PlusIcon className={cn('flex-shrink-0')} />
					<span>New chat</span>
				</div>
			</Button>
			<div className={cn('font-micro-text font-medium text-ui-75')}>Recent</div>
			<div className={cn('overflow-scroll hide-scrollbar relative')}>
				<div
					className={cn('vertical gap-[.8rem] transition-height ease-in-out delay-150')}
					style={{ height: containerHeight }}
				>
					{visibleConversations.map(conversation => (
						<ConversationsList key={conversation.id} conversation={conversation} />
					))}
				</div>
			</div>
			{sortedConversations.length > MAX_VISIBLE_ITEMS && (
				<div
					className={cn(
						'h-[3.6rem] font-text font-medium text-ui-100 gap-[0.8rem] flex px-[1rem] cursor-pointer'
					)}
					onClick={handleShowMore}
				>
					<DropdownArrow
						width={20}
						height={20}
						className={cn('transition-all transform delay-300', showAll ? 'rotate-180' : 'rotate-0')}
					/>
					{showAll ? 'Show less' : 'Show more'}
				</div>
			)}
		</div>
	);
};

export default LeftPanel;
