import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getChatMessagesList } from './chat/actions';
import { resetChat, setConversationId } from './chat/reducer';

export default function useChat() {
	const { conversationId } = useParams<any>();
	const dispatch = useAppDispatch();
	const {
		pending: chatPending,
		loaded: chatLoaded,
		error: chatError,
		messages,
		chatResponsePending,
		chatResponseLoaded,
		chatResponseError,
	} = useAppSelector(state => state.ai.chat);

	// Get chat messages when loading an existing conversation or switching to a new one
	useEffect(() => {
		if (
			!chatPending &&
			!chatLoaded &&
			!chatError &&
			!chatResponsePending &&
			!chatResponseLoaded &&
			!chatResponseError &&
			conversationId
		) {
			dispatch(setConversationId(conversationId));
			dispatch(
				getChatMessagesList({
					conversationId,
				})
			);
		}
	}, [
		chatPending,
		chatLoaded,
		chatError,
		messages,
		conversationId,
		chatResponsePending,
		chatResponseLoaded,
		chatResponseError,
	]);

	return {
		messages,
		pending: chatPending,
		error: chatError,
		loaded: chatLoaded,
	};
}
