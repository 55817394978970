import React from 'react';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { saveCategory } from './editReducer';
import qs from 'qs';

export default function CategoryEditHeader({ history, location }: RouteComponentProps) {
	const dispatch = useAppDispatch();
	const { title, description } = useAppSelector(state => state.datastudio.survey.categoryEdit.categoryToEdit);
	const disableSave = !title;
	const { globalCategoryId } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});

	function handleCancel() {
		history.goBack();
	}

	async function handleSave() {
		await dispatch(
			saveCategory({
				globalCategoryId: globalCategoryId as string,
				title,
				description,
			})
		);
	}

	return (
		<div className="survey-header">
			<div className="survey-header__title">Edit Category</div>
			<div className="survey-header__actions">
				<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
					Cancel
				</Button>
				<Button componentType={ButtonTypes.type.PRIMARY} disabled={disableSave} onClick={handleSave}>
					Save
				</Button>
			</div>
		</div>
	);
}
