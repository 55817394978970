import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';
import DropdownArrow from '../../../icons/DropdownArrow';
import Close from '../../../icons/Close';
import ButtonTypes from '../../../constants/ButtonTypes';

export default function FilterButton(props) {
	const { label, placeholder, onClick, disabled, isOpen, isSecondary, allowCancel, onClearSelection } = props;

	return (
		<Button
			type="button"
			componentType={isSecondary ? ButtonTypes.type.SECONDARY : ButtonTypes.type.PRIMARY}
			onClick={onClick}
			activated={isOpen}
			disabled={disabled}
			selected={!!label}
		>
			{label || placeholder}
			{allowCancel && label ? (
				<Close width={18} height={12} onClick={onClearSelection} />
			) : (
				<DropdownArrow width={18} height={18} />
			)}
		</Button>
	);
}

FilterButton.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	isOpen: PropTypes.bool,
	isSecondary: PropTypes.bool,
	allowCancel: PropTypes.bool,
	onClearSelection: PropTypes.func,
};
