import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import RadioButton from '../../../common/RadioButton';
import DropdownArrow from '../../../icons/DropdownArrow';
import FilterDropdown from './FilterDropdown';
import SegmentValues from './SegmentValues';
import AnalysisTypes from '../../../constants/AnalysisTypes';
import _ from 'lodash';

function getAnalysisNames(node, parent) {
	return node.map(a => ({
		checked: a.checked,
		name: a.name,
		isSubAnalysis: !!a.isSubAnalysis,
		isFunnel: !!a.isFunnel,
		parent,
		source: a.source,
		leaf: !a.children,
	}));
}

function search(level, analysisName, nodes) {
	for (let i = 0; i < level.length; i++) {
		if (level[i].name === analysisName && !level[i].children) {
			nodes.push(i);
			return true;
		}
		if (level[i].children) {
			const found = search(level[i].children, analysisName, nodes);
			if (found) {
				nodes.push(i);
				return true;
			}
		}
	}
	return false;
}

function AnalysisList({ analysisNames, onChange }) {
	return (
		<div className="filter-dropdown__list">
			<ul>
				{analysisNames.map(analysisName => (
					<li key={analysisName.name}>
						<RadioButton checked={analysisName.checked} onClick={() => onChange(analysisName.name)}>
							{analysisName.name}
							{!analysisName.leaf && !analysisName.isFunnel && (
								<DropdownArrow
									width={18}
									height={18}
									style={
										analysisName.checked
											? {
													transform: 'rotate(-90deg)',
											  }
											: {}
									}
								/>
							)}
						</RadioButton>
					</li>
				))}
			</ul>
		</div>
	);
}

AnalysisList.propTypes = {
	analysisNames: PropTypes.array,
	onChange: PropTypes.func,
};

export default function AnalysisDropdown({
	initialAnalysisNames,
	analysisDisplayName,
	analysisName,
	analysisType,
	setAnalysisName,
	disabled,
	shouldOpen,
	isSavedSearch = false,
	useRadio,
	filterNames,
}) {
	const [analysisNamesArray, setAnalysisNamesArray] = useState(getInitialState());
	const [isOpen, setIsOpen] = useState(false);
	const [subAnalysisSearch, setSubAnalysisSearch] = useState('');

	useEffect(() => {
		if (shouldOpen) {
			setIsOpen(true);
		}
	}, [shouldOpen]);

	useEffect(() => {
		if (analysisType !== AnalysisTypes.Arithmetic) {
			setAnalysisNamesArray(getInitialState());
		}
	}, [analysisType]);

	useEffect(() => {
		if (isSavedSearch) {
			console.log('MANUALL run onChange handler for each level and value...?');
		}
	}, [isSavedSearch]);

	function getInitialState() {
		let nodes = [];
		if (analysisName) {
			search(initialAnalysisNames, analysisName, nodes);
		}
		nodes.reverse();
		if (nodes.length === 0) {
			nodes.push(-1);
		}
		const analysisNamesArray = [];
		let nextLevelNodes = initialAnalysisNames;

		nodes.forEach(node => {
			const analysisNames = getAnalysisNames(nextLevelNodes);

			if (node > -1) {
				analysisNames[node].checked = true;
				nextLevelNodes = nextLevelNodes[node].children;
			}
			analysisNamesArray.push(analysisNames);
		});
		return analysisNamesArray;
	}

	function updateAnalysis(analysis, subAnalyses) {
		if (subAnalyses.length === 0 || !analysis) {
			setAnalysisName({
				analysisDisplayName: '',
				analysisName: '',
				subAnalyses: [],
			});
		} else {
			setAnalysisName({
				analysisDisplayName:
					subAnalyses.length === 1 ? subAnalyses[0].name : `${analysis.name} (${subAnalyses.length})`,
				analysisName: analysis.name,
				subAnalyses,
			});
		}

		return;
	}

	function getSubAnalyses(analysisName, initialAnalysisNames) {
		for (const analysis of initialAnalysisNames) {
			if (analysis.name === analysisName.name) {
				return analysis.children;
			}
			if (analysis.children) {
				const result = getSubAnalyses(analysisName, analysis.children);
				if (result.length > 0) {
					return result;
				}
			}
		}
		return [];
	}

	function handleChange(level, value) {
		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const analysisNames = analysisNamesArray[level];
				const analysisName = analysisNames.find(a => a.name === value);

				if (analysisName) {
					const checkedAnalysis = analysisNames.find(a => a.checked);
					if (checkedAnalysis) {
						checkedAnalysis.checked = false;
						while (analysisNamesArray.length > level + 1) {
							analysisNamesArray.pop();
						}
					}
					analysisName.checked = true;

					if (!analysisName.leaf && !analysisName.isFunnel) {
						let children = initialAnalysisNames;
						for (let i = 0; i <= level; i++) {
							const analysisName = analysisNamesArray[i].find(a => a.checked);
							if (analysisName && children) {
								const initialAnalysisName = children.find(a => a.name === analysisName.name);
								if (initialAnalysisName) {
									children = initialAnalysisName.children;
								}
							}
						}
						if (children) {
							if (children.every(a => a.isSubAnalysis)) {
								children = children.map(a => ({
									...a,
								}));
							}
							analysisNamesArray.push(getAnalysisNames(children, analysisName));
						}
					}
				}
			});
		});

		const analysisNames = analysisNamesArray[level];
		const analysisName = analysisNames.find(a => a.name === value);
		if (analysisName) {
			if (analysisName.isFunnel) {
				const subAnalyses = getSubAnalyses(analysisName, initialAnalysisNames);
				setAnalysisName({
					analysisDisplayName: analysisName.name,
					analysisName: analysisName.name,
					isFunnel: true,
					subAnalyses,
				});

				setIsOpen(false);
			}

			if (analysisName.leaf) {
				if (analysisName.isSubAnalysis && analysisName.parent) {
					setAnalysisName({
						analysisDisplayName: analysisName.name,
						analysisName: analysisName.parent.name,
						subAnalyses: [analysisName],
					});
				} else {
					setAnalysisName({
						analysisDisplayName: analysisName.name,
						analysisName: analysisName.name,
						subAnalyses: [],
					});
				}
				setIsOpen(false);
			}
		}
	}

	function handleClearSelection() {
		updateAnalysis(null, []);
	}

	function onSelectAll(checked, level) {
		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const subAnalyses = analysisNamesArray[level];

				if (subAnalyses) {
					subAnalyses.forEach(subAnalysis => {
						subAnalysis.checked = checked;
					});
				}
			});
		});

		const analysisName = analysisNamesArray[level - 1].find(a => a.checked);
		const subAnalyses = analysisNamesArray[level];
		const updatedSubAnalyses = subAnalyses
			.map(subAnalysis => ({
				...subAnalysis,
				checked,
			}))
			.filter(subAnalysis => subAnalysis.checked);

		updateAnalysis(analysisName, updatedSubAnalyses);
	}

	function handleSubAnalysisChange(level, subAnalysis) {
		setAnalysisNamesArray(analysisNamesArray => {
			return produce(analysisNamesArray, analysisNamesArray => {
				const subAnalyses = analysisNamesArray[level];

				if (subAnalyses) {
					const subAnalysis_ = subAnalyses.find(a => a.source === subAnalysis);
					if (subAnalysis_) {
						subAnalysis_.checked = !subAnalysis_.checked;
					}
				}
			});
		});

		const analysisName = analysisNamesArray[level - 1].find(a => a.checked);
		const subAnalyses = analysisNamesArray[level];
		const updatedSubAnalyses = subAnalyses
			.map(s => {
				if (s.source === subAnalysis) {
					return {
						...s,
						checked: !s.checked,
					};
				} else {
					return s;
				}
			})
			.filter(s => s.checked);

		updateAnalysis(analysisName, updatedSubAnalyses);
	}

	return (
		<FilterDropdown
			label={analysisDisplayName}
			placeholder="Analysis"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			disabled={disabled}
			allowCancel={true}
			onClearSelection={handleClearSelection}
		>
			{analysisNamesArray.map((a, i) => {
				if (a.every(a => a.isSubAnalysis)) {
					return (
						<SegmentValues
							values={a.map(subAnalysis => ({
								...subAnalysis,
								value: subAnalysis.source,
								text: subAnalysis.name,
							}))}
							key={i}
							analysisNames={a}
							onChange={subAnalysis => handleSubAnalysisChange(i, subAnalysis)}
							onSelectAll={checked => onSelectAll(checked, i)}
							onSearch={setSubAnalysisSearch}
							search={subAnalysisSearch}
							useRadio={useRadio}
						/>
					);
				}
				let filteredAnalysisNames = filterNames
					? _.cloneDeep(a).filter(analysis => !filterNames.includes(analysis.name))
					: a;
				return (
					<AnalysisList key={i} analysisNames={filteredAnalysisNames} onChange={handleChange.bind(null, i)} />
				);
			})}
		</FilterDropdown>
	);
}

AnalysisDropdown.propTypes = {
	initialAnalysisNames: PropTypes.array,
	analysisDisplayName: PropTypes.string,
	analysisName: PropTypes.string,
	analysisType: PropTypes.string,
	setAnalysisName: PropTypes.func,
	disabled: PropTypes.bool,
	shouldOpen: PropTypes.bool,
	filterNames: PropTypes.array,
};
