import { useContext } from 'react';
import { ChartContext } from '../../contexts/ChartContext';
import { LineChartContext } from './LineChart';

const IndexBackground = () => {
	const {
		chartState: { chartWidth, chartHeight },
	} = useContext(ChartContext);

	const { yScale } = useContext(LineChartContext);

	return (
		<rect
			className="linechartv2__index-background"
			x={0}
			y={yScale(1)}
			width={chartWidth}
			height={chartHeight - yScale(0)}
		/>
	);
};

export default IndexBackground;
