function SvgComponent(props: any) {
	return (
		<svg
			width={props.size || 40}
			height={props.size || 40}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M31.83 20.284v-2.112h-3.378v2.112c0 4.563-3.719 8.281-8.448 8.281-4.73 0-8.448-3.718-8.448-8.281v-2.112H8.17v2.112c0 6.592 5.408 12 11.834 12 6.419 0 11.826-5.408 11.826-12zM26.59 12.004h-3.68v3.718h3.68v-3.718zM17.306 12.004h-3.681v3.718h3.68v-3.718z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgComponent;
