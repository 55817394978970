const states = [
	{
		name: 'Connecticut',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'Maine',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'Massachusetts',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'New Hampshire',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'Rhode Island',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'Vermont',
		region: 'Northeast',
		division: 'New England',
	},
	{
		name: 'New Jersey',
		region: 'Northeast',
		division: 'Middle Atlantic',
	},
	{
		name: 'New York',
		region: 'Northeast',
		division: 'Middle Atlantic',
	},
	{
		name: 'Pennsylvania',
		region: 'Northeast',
		division: 'Middle Atlantic',
	},
	{
		name: 'Illinois',
		region: 'Midwest',
		division: 'Eact North Central',
	},
	{
		name: 'Indiana',
		region: 'Midwest',
		division: 'Eact North Central',
	},
	{
		name: 'Michigan',
		region: 'Midwest',
		division: 'Eact North Central',
	},
	{
		name: 'Ohio',
		region: 'Midwest',
		division: 'Eact North Central',
	},
	{
		name: 'Wisconsin',
		region: 'Midwest',
		division: 'Eact North Central',
	},
	{
		name: 'Iowa',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'Kansas',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'Minnesota',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'Missouri',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'Nebraska',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'North Dakota',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'South Dakota',
		region: 'Midwest',
		division: 'West North Central',
	},
	{
		name: 'Delaware',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'District of Columbia',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'Florida',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'Georgia',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'Maryland',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'North Carolina',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'South Carolina',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'Virginia',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'West Virginia',
		region: 'South',
		division: 'South Atlantic',
	},
	{
		name: 'Alabama',
		region: 'South',
		division: 'Eact Southe Central',
	},
	{
		name: 'Kentucky',
		region: 'South',
		division: 'Eact Southe Central',
	},
	{
		name: 'Mississippi',
		region: 'South',
		division: 'Eact Southe Central',
	},
	{
		name: 'Tennessee',
		region: 'South',
		division: 'Eact Southe Central',
	},
	{
		name: 'Arkansas',
		region: 'South',
		division: 'West Southe Central',
	},
	{
		name: 'Louisiana',
		region: 'South',
		division: 'West Southe Central',
	},
	{
		name: 'Oklahoma',
		region: 'South',
		division: 'West Southe Central',
	},
	{
		name: 'Texas',
		region: 'South',
		division: 'West Southe Central',
	},
	{
		name: 'Arizona',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Colorado',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Idaho',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Montana',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Nevada',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'New Mexico',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Utah',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Wyoming',
		region: 'West',
		division: 'Mountain',
	},
	{
		name: 'Alaska',
		region: 'West',
		division: 'Pacific',
	},
	{
		name: 'California',
		region: 'West',
		division: 'Pacific',
	},
	{
		name: 'Hawaii',
		region: 'West',
		division: 'Pacific',
	},
	{
		name: 'Oregon',
		region: 'West',
		division: 'Pacific',
	},
	{
		name: 'Washington',
		region: 'West',
		division: 'Pacific',
	},
];

export default states;
