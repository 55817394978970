import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import sanitize from '../../lib/sanitize';
import OpenText from '../../common/OpenText';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setTitle } from '../dashboardReport/reducer';

export default function EditReportNameModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
	const dispatch = useAppDispatch();
	const { title } = useAppSelector(state => state.explore.dashboardReport);
	const [name, setName] = useState<string>('');

	useEffect(() => {
		if (isOpen && title) {
			setName(title);
		}
	}, [isOpen, title]);

	function handleSave(e: any) {
		e.preventDefault();
		if (!name) return;
		let reportName = sanitize(name?.trim());
		dispatch(setTitle(reportName));
		onClose();
	}

	return (
		<Modal
			visible={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onClose}
			zIndex={3000}
			destroyOnClose
		>
			<div className="report-modal modal">
				<form onSubmit={handleSave}>
					<div className="modal__header">
						<div className="modal__title">Name your report</div>
						<div className="modal__buttons">
							<Button componentType={ButtonTypes.type.TERTIARY} onClick={onClose} classes={['mr8']}>
								Cancel
							</Button>
							<Button onClick={handleSave} disabled={!title && !name}>
								Save
							</Button>
						</div>
					</div>
					<div className="modal__content">
						<OpenText value={name} onChange={(v: string) => setName(v)} placeholder="Enter name here" />
					</div>
				</form>
			</div>
		</Modal>
	);
}
