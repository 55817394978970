import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from '../../store/hooks';

interface EmptyStateImageProps {
	queryString: string;
}

function EmptyStateImage({ queryString }: EmptyStateImageProps) {
	const isEmptyState = useAppSelector(
		state => !state.explore.filter.analysisName && state.explore.filter.mainSegments.length === 0
	);

	const show = !queryString && isEmptyState;
	return (
		<div className="explore__empty-state-image">
			<CSSTransition in={show} timeout={200} classNames="fade" mountOnEnter unmountOnExit>
				<img src="/images/empty-state-search.svg" />
			</CSSTransition>
		</div>
	);
}

export default EmptyStateImage;
