import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';

export function getActors() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const { allRoles } = getState().admin.roles;

		dispatch({
			type: ActionTypes.GetActorsPending,
		});

		return axios
			.post(`${Urls.AccountApi}actors`, {
				enterpriseId,
			})
			.then(response => {
				const actors = response.data.data;
				dispatch({
					type: ActionTypes.GetActorsFulfilled,
					actors,
					allRoles,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetActorsRejected,
				});
			});
	};
}

export function getRoles() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetRolesPending,
		});
		axios
			.post(`${Urls.AccountApi}roles`, {
				enterpriseId,
			})
			.then(response => {
				const { roles } = response.data;
				dispatch({
					type: ActionTypes.GetRolesFulfilled,
					roles,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetRolesRejected,
				});
			});
	};
}

export function addUsers(emails: string[], roleIds: string[], note: string, sendNotification: boolean) {
	return (dispatch: any) => {
		dispatch({ type: ActionTypes.AddUsersPending });

		if (emails && emails.length) {
			const promises: any[] = [];
			emails.forEach((email: string) => {
				let newActorId: string;
				const promise = dispatch(createActor(email, note, sendNotification))
					.then((actorId: string) => {
						newActorId = actorId;
						return dispatch(assignRoles(newActorId, roleIds));
					})
					.then(() => {
						return newActorId;
					});
				promises.push(promise);
			});
			return Promise.all(promises)
				.then(actorIds => {
					dispatch({ type: ActionTypes.AddUsersFulfilled });
					return actorIds;
				})
				.catch(error => {
					errorHandler.report(error);
					dispatch({ type: ActionTypes.AddUsersRejected });
					return [];
				});
		}
	};
}

export function createActor(email: string, note: string, sendNotification: any) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.CreateActorPending,
		});
		return axios
			.put(`${Urls.AccountApi}link`, {
				email,
				enterpriseId,
				note,
				sendNotification,
			})
			.then(response => {
				const { actorId } = response.data;
				dispatch({
					type: ActionTypes.CreateActorFulfilled,
					email,
					actorId,
					sendNotification,
				});
				return actorId;
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.CreateActorRejected,
				});
			});
	};
}

export function deleteActor(actorId: string, email: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const actor = getState().admin.users.allUsers.find((a: any) => a.actorId === actorId);

		dispatch({
			type: ActionTypes.DeleteActorPending,
		});
		return axios
			.put(`${Urls.AccountApi}unlink`, {
				actorId,
				email,
				enterpriseId,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.DeleteActorFulfilled,
					actorId,
					firstName: actor.firstName,
					lastName: actor.lastName,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.DeleteActorRejected,
				});
			});
	};
}

export function grant(actorId: string, role: string, noMessage: boolean) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const actor = getState().admin.users.allUsers.find((a: any) => a.actorId === actorId);

		dispatch({
			type: ActionTypes.GrantPending,
		});
		return axios
			.put(`${Urls.AccountApi}grant`, {
				actorId,
				role,
				enterpriseId,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.GrantFulfilled,
					actorId,
					role,
					firstName: actor && actor.firstName,
					lastName: actor && actor.lastName,
					noMessage,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GrantRejected,
				});
			});
	};
}

export function searchUsers(query: any) {
	return {
		type: ActionTypes.SearchUsers,
		query,
	};
}

export function assignRole(roleId: string, actorId: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const { allRoles } = getState().admin.roles;
		const actor = getState().admin.users.allUsers.find((a: any) => a.actorId === actorId);

		dispatch({
			type: ActionTypes.AssignRoleOptimistic,
			roleId,
			actorId,
			allRoles,
		});
		dispatch({
			type: ActionTypes.AssignRolePending,
		});

		return axios
			.put(`${Urls.RbacApi}users/role/assign`, {
				enterpriseId,
				roleId,
				entityId: actorId,
			})
			.then(response => {
				dispatch({
					type: ActionTypes.AssignRoleFulfilled,
					roleId: response.data.roleId,
					actorId: response.data.entityId,
					allRoles,
					roleName: allRoles.find((role: any) => role?.id === response.data.roleId).name,
					...actor,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.AssignRoleRejected,
				});
				dispatch({
					type: ActionTypes.UnassignRoleOptimistic,
					roleId,
					actorId,
				});
			});
	};
}

export function unassignRole(roleId: string, actorId: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const { allRoles } = getState().admin.roles;
		const actor = getState().admin.users.allUsers.find((a: any) => a.actorId === actorId);

		dispatch({
			type: ActionTypes.UnassignRoleOptimistic,
			roleId,
			actorId,
		});
		dispatch({
			type: ActionTypes.UnassignRolePending,
		});
		return axios
			.put(`${Urls.RbacApi}users/role/unassign`, {
				enterpriseId,
				roleId,
				entityId: actorId,
			})
			.then(response => {
				dispatch({
					type: ActionTypes.UnassignRoleFulfilled,
					roleId: response.data.roleId,
					actorId: response.data.entityId,
					roleName: allRoles.find((role: any) => role?.id === response.data.roleId).name,
					...actor,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UnassignRoleRejected,
				});
				dispatch({
					type: ActionTypes.AssignRoleOptimistic,
					roleId,
					actorId,
					allRoles,
				});
			});
	};
}

export function assignRoles(actorId: string, roleIds: string[]) {
	return (dispatch: any) => {
		const promises: any[] = [];
		roleIds.forEach((roleId: string) => {
			promises.push(dispatch(assignRole(roleId, actorId)));
		});
		return Promise.all(promises);
	};
}

export function switchRoles(oldRoleId: string, newRoleId: string, actorId: string) {
	return (dispatch: any, getState: any) => {
		dispatch(unassignRole(oldRoleId, actorId));
		dispatch(assignRole(newRoleId, actorId));
	};
}
