export enum BarTypes {
	Large = 'large',
	Medium = 'medium',
	Small = 'small',
}

export const ARROW_CIRCLE_RADIUS = 12;
export const ARROW_WIDTH = 6;
export const AXIS_FONT_SIZE = 11;
export const LARGE_TEXT_FONT_SIZE = 13;
export const PADDING = 16;
export const TEXT_FONT_SIZE = 11;
export const Y_SMALL_PADDING = 40;
export const X_AXIS_PADDING = 30;

export const OTHER_LABEL = 'OtherXYZ';

export const barTypes = [
	{
		type: BarTypes.Large,
		min: 48,
		max: 80,
	},
	{
		type: BarTypes.Medium,
		min: 34,
		max: 47,
	},
	{
		type: BarTypes.Small,
		min: 11,
		max: 33,
	},
];
