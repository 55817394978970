import { max, min } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { NodeGroup } from 'react-move';
import AnalysisTypes from '../constants/AnalysisTypes';
import Constants from '../constants/Constants';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import { track } from '../lib/segment';
import { getFormattedDate } from '../lib/chartUtils';

const AXIS_PADDING = 24;
const PADDING = 16;
const RADIUS = 5;
const FONT_SIZE = 11;
const ARROW_HEIGHT = 6;
const ARROW_WIDTH = 8;

export default function SingleTimeChart(props) {
	const {
		width,
		height,
		data,
		date,
		interval,
		analysisType,
		reportId,
		dashboardName,
		title,
		dataProp,
		yAxisType,
		indexType,
		employeeCountHidden,
		hoveredItem,
		selection,
		onHover,
		onHoverEnd,
		onToggle,
	} = props;

	function getOpacity(item) {
		const isActive = data.some(d => {
			const isActive = d.label === hovereItem;
			const isSelected = selection.includes(d.label);
			return isActive || isSelected;
		});
		if (isActive) {
			return item.label === hoveredItem || selection.includes(item.label) ? 1 : 0.24;
		}
		return 1;
	}

	function getPoints() {
		let x = width - PADDING;
		const text = getFormattedDate(date, interval);
		const textWidth = measureText(text).width;
		x -= textWidth / 2;
		const y = height - AXIS_PADDING - PADDING;
		return `${x} ${y + ARROW_HEIGHT}, ${x - ARROW_WIDTH / 2} ${y}, ${x + ARROW_WIDTH / 2} ${y}`;
	}

	function getYAxisValues(minValue, maxValue) {
		let dev = maxValue - minValue;
		if (dev === 0) {
			if (minValue === 0) {
				dev = 0.1;
			} else {
				dev = minValue * 0.1;
			}
		} else {
			dev = dev * 0.3;
		}
		const adjMinValue = minValue - dev;
		const adjMaxValue = maxValue + dev;
		return [adjMinValue, adjMaxValue];
	}

	function handleToggle(label, e) {
		track(MixPanel.Events.DashboardReportLineGraphLineSegmentClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		props.onToggle(label, e);
	}

	const minArray = [];
	const maxArray = [];
	data.forEach(d => {
		minArray.push(min(d.series, s => s[dataProp]));
		maxArray.push(max(d.series, s => s[dataProp]));
	});

	const minValue = min(minArray);
	const maxValue = max(maxArray);

	const yAxisValues = getYAxisValues(minValue, maxValue);
	const yScale = scaleLinear()
		.domain(yAxisValues)
		.range([height - 2 * PADDING - AXIS_PADDING, PADDING]);
	const avgValue = (minValue + maxValue) / 2;
	const data2 = data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
	const singleSegment = data2.length === 1;
	const twoSegments = data2.length === 2;

	return (
		<svg
			className={cn('single-time-chart', dashboardName && 'absolute inset-0')}
			data-export-type="linechart"
			id={reportId || 'explore-chart'}
			width={width}
			height={height}
		>
			<defs>
				<style type="text/css">{inlineFont}</style>
			</defs>
			{analysisType === AnalysisTypes.LinearRegression && (
				<rect
					className="single-time-chart__linear-regression-background"
					x={0}
					y={yScale(0)}
					width={width}
					height={height - yScale(0)}
					fill="currentColor"
				/>
			)}
			{analysisType === AnalysisTypes.Index && indexType === 'indexed' && (
				<rect
					className="single-time-chart__index-background"
					x={0}
					y={yScale(1)}
					width={width}
					height={height - yScale(1)}
					fill="currentColor"
				/>
			)}
			<NodeGroup
				keyAccessor={d => d.label}
				data={data}
				start={() => {
					return {
						opacity: 1,
						y: avgValue,
					};
				}}
				enter={d => {
					return {
						y: [d.series[0][dataProp] || 0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					};
				}}
				update={d => {
					return {
						y: [d.series[0][dataProp] || 0],
						opacity: [getOpacity(d)],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					};
				}}
				interpolation={getInterpolator}
			>
				{nodes => (
					<g className="single-time-chart__lines">
						{nodes.map(
							({ state, data, key }) =>
								!data.isEqualityIndex &&
								!data.isAttritionIndex && (
									<line
										className="single-time-chart__lines__line"
										key={key}
										x1={0}
										x2={width - PADDING}
										y1={yScale(state.y)}
										y2={yScale(state.y)}
										stroke={data.color}
										strokeOpacity={state.opacity}
									/>
								)
						)}
					</g>
				)}
			</NodeGroup>
			<NodeGroup
				keyAccessor={d => d.label}
				data={data}
				start={() => {
					return {
						opacity: 1,
						y: avgValue,
					};
				}}
				enter={d => {
					return {
						y: [d.series[0][dataProp] || 0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					};
				}}
				update={d => {
					return {
						y: [d.series[0][dataProp] || 0],
						opacity: [getOpacity(d)],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					};
				}}
				interpolation={getInterpolator}
			>
				{nodes => (
					<g className="single-time-chart__circles">
						{nodes.map(
							({ state, data, key }) =>
								!data.isEqualityIndex &&
								!data.isAttritionIndex && (
									<Fragment key={key}>
										<circle
											className="single-time-chart__circles__circle"
											cx={width - PADDING}
											cy={yScale(state.y)}
											r={RADIUS}
											fill={data.color}
											opacity={state.opacity}
											onMouseOver={props.onHover.bind(null, data.label)}
											onMouseOut={props.onHoverEnd.bind(null, data.label)}
											onClick={handleToggle.bind(null, data.label)}
										/>
										{singleSegment && (
											<text
												className="single-time-chart__circles__label"
												x={width - PADDING}
												y={yScale(state.y) - 10}
												textAnchor="end"
												opacity={state.opacity}
												fill={data.color}
											>
												{yAxisType === 'count'
													? employeeCountHidden
														? ''
														: data.series[0].totalCount || data.series[0].includedCount
													: getFormattedValue(
															indexType === 'percentage'
																? AnalysisTypes.Percentage
																: analysisType,
															data.series[0][dataProp]
													  )}
											</text>
										)}
										{twoSegments && (
											<text
												className="single-time-chart__circles__label--two-segments"
												x={width - PADDING}
												y={yScale(state.y) - 10}
												textAnchor="end"
												opacity={state.opacity}
												fill={data.color}
											>
												{yAxisType === 'count'
													? employeeCountHidden
														? ''
														: data.series[0].totalCount || data.series[0].includedCount
													: getFormattedValue(
															indexType === 'percentage'
																? AnalysisTypes.Percentage
																: analysisType,
															data.series[0][dataProp]
													  )}
											</text>
										)}
										{!singleSegment &&
											!twoSegments &&
											(data.label === hoveredItem || selection.includes(data.label)) && (
												<text
													className="single-time-chart__circles__label--activated"
													x={width - PADDING}
													y={yScale(state.y) - 10}
													textAnchor="end"
													opacity={state.opacity}
													fill={data.color}
												>
													{yAxisType === 'count'
														? employeeCountHidden
															? ''
															: data.series[0].totalCount || data.series[0].includedCount
														: getFormattedValue(
																indexType === 'percentage'
																	? AnalysisTypes.Percentage
																	: analysisType,
																data.series[0][dataProp]
														  )}
												</text>
											)}
									</Fragment>
								)
						)}
					</g>
				)}
			</NodeGroup>
			<line
				className="single-time-chart__body__outline"
				x1="0"
				y1={height - AXIS_PADDING - PADDING}
				x2={width}
				y2={height - AXIS_PADDING - PADDING}
			/>
			<text
				className="single-time-chart__x-labels__text--selected"
				x={width - PADDING}
				y={height - PADDING - FONT_SIZE * 0.5}
				textAnchor="end"
			>
				{getFormattedDate(date, interval)}
			</text>
			<polygon className="single-time-chart__x-labels__arrow" points={getPoints()} />
		</svg>
	);
}

SingleTimeChart.propTypes = {
	reportId: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	data: PropTypes.array,
	lifecycle: PropTypes.string,
	analysisType: PropTypes.string,
	date: PropTypes.object,
	interval: PropTypes.string,
	onHover: PropTypes.func,
	onHoverEnd: PropTypes.func,
	onToggle: PropTypes.func,
	dashboardName: PropTypes.string,
	title: PropTypes.string,
	dataProp: PropTypes.string,
	yAxisType: PropTypes.string,
	indexType: PropTypes.string,
	employeeCountHidden: PropTypes.bool,
	hoveredItem: PropTypes.string,
	selection: PropTypes.array,
};

SingleTimeChart.defaultProps = {
	width: 600,
	height: 256,
};
