import { combineReducers } from 'redux';
import chatReducer from './chat/reducer';
import conversationsReducer from './conversations/reducer';
import modalReducer from './modal/reducer';
import insightsReducer from './insights/reducer';

export default combineReducers({
	conversations: conversationsReducer,
	chat: chatReducer,
	insights: insightsReducer,
	modal: modalReducer,
});
