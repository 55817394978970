import { useEffect, useRef } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import {
	clearFilters,
	generateInEditor,
	setAnalysisName,
	setFiltersFromReport,
	updateFilterSegment,
	updateMainSegments,
} from '../../../editor/filter/actions';
import { getInitialAnalysisNames } from '../../../explore/filter/Filters';
import TopLeftAction from '../../../layout/TopLeftAction';
import getMixPanelObjectForFilters from '../../../lib/getMixPanelObjectForFilters';
import { track } from '../../../lib/segment';
import { getAllSegments } from '../../../store/actionUtils';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import AnalysisDropdown from './AnalysisDropdown';
import SegmentDropdown from './SegmentDropdown';

interface FiltersProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {
	// props
	onGenerate: () => void;
	useRadio: boolean[];

	// state
	report?: any;
	filteredAnalysisNames?: string[];
}

const Filters = (props: FiltersProps) => {
	const { onGenerate, useRadio = [true, true, true], report, filteredAnalysisNames } = props;
	const {
		location: { state },
	} = props;
	const dispatch = useAppDispatch();
	const {
		analysisDisplayName,
		analysisName,
		analysisType,
		attributes,
		breakdownSegment,
		filterNames,
		filterSegment,
		generateDisabled,
		mainNames,
		mainSegments,
	} = useAppSelector(state => state.editor.filter);
	const { analysisNamesTree } = useAppSelector(state => state.account);
	const { groups } = useAppSelector(state => state.templates);
	const { generatePending } = useAppSelector(state => state.explore.chart);
	const { completed } = useAppSelector(state => state.editor.save);
	const containerRef = useRef<HTMLDivElement>(null);

	const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree || [], groups);

	useEffect(() => {
		// prevents this effect from firing when we set report data from editor
		if (completed) return;
		if (report) {
			dispatch(setFiltersFromReport(report));
		} else {
			dispatch(clearFilters());
		}

		() => {
			dispatch(clearFilters());
		};
	}, [report, completed]);

	function handleGenerate() {
		if (!mainSegments) return;

		const segments = getAllSegments({
			mainSegments,
			filterSegment,
			breakdownSegment,
		});

		const mixpanelObj = getMixPanelObjectForFilters(analysisName, segments, attributes);
		track(MixPanel.Events.ReportEditorGenerate, mixpanelObj);
		dispatch(generateInEditor());
		if (onGenerate) {
			onGenerate();
		}
	}

	function handleClear() {
		track(MixPanel.Events.ReportEditorClear);
		dispatch(clearFilters());
	}

	return (
		<div className="explore__filters__section" ref={containerRef}>
			<TopLeftAction scrollPos={state?.scrollPos} />
			<div className="filters">
				<div className="filters__dropdowns">
					<AnalysisDropdown
						initialAnalysisNames={initialAnalysisNames}
						analysisName={analysisName}
						analysisDisplayName={analysisDisplayName}
						analysisType={analysisType}
						setAnalysisName={(props: any) => dispatch(setAnalysisName(props))}
						useRadio={useRadio[0]}
						filterNames={filteredAnalysisNames}
					/>
					<SegmentDropdown
						placeholder="Segment"
						attributes={attributes}
						segmentNames={mainNames}
						selection={mainSegments}
						update={(props: any) => dispatch(updateMainSegments(props))}
						disabled={!analysisName}
						useRadioOnValue={useRadio[1]}
					/>
					<SegmentDropdown
						placeholder="Filter"
						attributes={attributes}
						segmentNames={filterNames}
						selection={filterSegment ? [filterSegment] : []}
						update={(props: any) => dispatch(updateFilterSegment(props))}
						disabled={!filterSegment && (!analysisName || mainSegments?.length === 0)}
						useRadioOnSegment={useRadio[2]}
						useRadioOnValue={useRadio[2]}
						isSecondary
					/>
				</div>
				<div className="filters__buttons" data-test="filters-actions">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
						Clear
					</Button>
					<Button
						disabled={!analysisName || !mainSegments || !mainSegments.length || generateDisabled}
						onClick={handleGenerate}
						loading={generatePending}
					>
						Generate
					</Button>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Filters);
