import ActionTypes from '../../constants/ActionTypes';

interface ReducerState {
	data: any[];
}

export default function ssoIntegrationsReducer(state: ReducerState = { data: [] }, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.SetSSOOptions:
			return {
				...state,
				data: action.sso,
			};
		default:
			return state;
	}
}
