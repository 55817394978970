import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getTemplates } from './actions';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { TemplateFragment } from './TemplateFragment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export function TemplateDetail({ history, match }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const id = match.params.id;
	const { loaded, data } = useAppSelector(state => state.datastudio.templates);
	const shouldFetch = !loaded;
	const template = data?.find((c: any) => c.templateId === id);

	useEffect(() => {
		if (shouldFetch) {
			dispatch(getTemplates());
		}
	}, [shouldFetch]);

	function handleEdit() {
		history.push(`/datastudio/templates/${match.params.id}/edit`);
	}

	return template ? (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-edit']} onClick={handleEdit}>
				Edit
			</Button>
			<TemplateFragment template={template} />
		</div>
	) : null;
}

export default TemplateDetail;
