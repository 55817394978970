import { connect } from 'react-redux';
import Chart from '../../editor/chart/Chart';
import {
	navigate,
	navigateFromMatrix,
	selectCurrentTime,
	setExpandedInReport,
	setRowHeightInReport,
	setSortCriteriaInReport,
	navigateToExplore,
} from './actions';

function mapState(state, ownProps) {
	const { dashboardReport, dashboard, disableInteractions, isResizing, isLocked } = ownProps;
	const report = state.reports[dashboardReport?.reportId] || {};
	return {
		report,
		dashboardReport,
		dashboard,
		disableLinks: disableInteractions,
		allEvents: state.timeline.events,
		isResizing,
		isLocked,
	};
}

const dispatchProps = {
	selectCurrentTime,
	navigate,
	navigateToExplore,
	navigateFromMatrix,
	setRowHeightInReport,
	setSortCriteriaInReport,
	setExpandedInReport,
};

export default connect(mapState, dispatchProps)(Chart);
