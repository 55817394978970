import { MouseEventHandler } from 'react';

import Button from '../common/Button';
import Stack from '../common/Stack';
import WithPermissions from '../common/WithPermissions';
import ButtonTypes from '../constants/ButtonTypes';
import RbacActions from '../constants/RbacActions';
import List2 from '../icons/List2';
import Tiles from '../icons/Tiles';
import { useAppSelector } from '../store/hooks';

type SecondaryHeaderProps = {
	showCreateDashboardModal: MouseEventHandler<HTMLButtonElement>;
	showDashboardListView: MouseEventHandler<HTMLButtonElement>;
	showDashboardTilesView: MouseEventHandler<HTMLButtonElement>;
};

const SecondaryHeader = ({
	showCreateDashboardModal,
	showDashboardListView,
	showDashboardTilesView,
}: SecondaryHeaderProps) => {
	const showTiles = useAppSelector(state => state.dashboards.showTiles);

	return (
		<div className="dashboards__header">
			<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
				<Stack flexDirection="row" gap="4rem">
					<Stack className={'dashboard__header__switcher'} flexDirection="row" gap="0.8rem">
						<Button
							componentType={showTiles ? ButtonTypes.type.PRIMARY : ButtonTypes.type.TERTIARY}
							onClick={showDashboardTilesView}
						>
							<Tiles size={19} fill={showTiles ? 'var(--color-ui-100-inverted)' : 'var(--color-ui-50)'} />
						</Button>
						<Button
							componentType={!showTiles ? ButtonTypes.type.PRIMARY : ButtonTypes.type.TERTIARY}
							onClick={showDashboardListView}
						>
							<List2
								size={19}
								fill={!showTiles ? 'var(--color-ui-100-inverted)' : 'var(--color-ui-50)'}
							/>
						</Button>
					</Stack>
					<Button componentType={ButtonTypes.type.PRIMARY} onClick={showCreateDashboardModal}>
						<span>Create Dashboard</span>
					</Button>
				</Stack>
			</WithPermissions>
		</div>
	);
};

export default SecondaryHeader;
