import produce from 'immer';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import ActionTypes from '../../constants/ActionTypes';
import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import {
	getAttributeName,
	getStateForDynamicSection,
	getStateForPrimaryData,
	getSummary,
} from '../../store/reducerUtils';
import { FilterSegment, Segment, TimeInterval } from '../../types';

const defaultSortCriteria = {
	columnName: 'label',
	ascending: true,
};

export interface DynamicSection {
	open: boolean;
	pending: boolean;
	sortCriteria: any;
	employeeAttribute?: string;
	attributeName?: string;
	empty?: boolean;
	lifecycle?: string;
	analysisType?: analysisTypes;
	columnProps?: any[];
	date?: string;
	interval?: TimeInterval;
	data?: any[];
	unfilteredData?: any[];
	showGroupSummary?: boolean;
	diversityAttributes?: any[];
	unfilteredDiversityAttributes?: any[];
	employeeAttributes?: any[];
	intersectionAttributes?: any[];
	columnFilter?: any[];
	shouldGetPrimaryData?: boolean;
	total?: number;
	generatePending?: boolean;
	chartType?: ChartTypes;
	allowChartToggle?: boolean;
}

function getInitialState(): DynamicSection {
	return {
		open: false,
		pending: true,
		sortCriteria: defaultSortCriteria,
		chartType: ChartTypes.Matrix,
		allowChartToggle: false,
	};
}

function getSections(mainSegments: Segment[], filterSegment: FilterSegment, variations: any) {
	const sectionSet = new Set();
	const filterLength = mainSegments.length + (filterSegment ? 1 : 0);

	variations.forEach((v: any) => {
		const variationItems = v.map((vi: any) => vi.toLowerCase());
		const variationLength = variationItems.filter((vi: any) => !!vi).length;
		let shouldInclude = variationLength === filterLength + 1;
		if (shouldInclude && filterSegment) {
			shouldInclude = filterSegment.name.replace(/ /g, '').toLowerCase() === variationItems[3];
		}
		if (shouldInclude) {
			const mainVariationItems = variationItems.slice(0, 3).filter((v: any) => !!v);
			shouldInclude = mainVariationItems.length === mainSegments.length;
			if (shouldInclude) {
				mainSegments.forEach((s: any) => {
					shouldInclude =
						shouldInclude && mainVariationItems.includes(s.name.replace(/ /g, '').toLowerCase());
				});
			}
		}
		if (shouldInclude) {
			if (filterSegment) {
				sectionSet.add(v[4]);
			} else {
				sectionSet.add(v[3]);
			}
		}
	});
	return [...sectionSet];
}

function getDiversityAttributes(unfilteredDiversityAttributes: any, columnFilter: any) {
	if (columnFilter.length) {
		return unfilteredDiversityAttributes.filter((d: any) => columnFilter.includes(d.label));
	} else {
		return unfilteredDiversityAttributes.slice();
	}
}

function getData(unfilteredData: any, rowFilter: any, sortCriteria: any, selection: any) {
	let data = unfilteredData.slice();
	if (rowFilter.length) {
		data = data.filter((d: any) => rowFilter.includes(d.id));
	}
	data = orderBy(data, [sortCriteria.columnName], [sortCriteria.ascending ? 'asc' : 'desc']);
	selection?.forEach((s: any) => {
		const item = data.find((d: any) => d.label === s);
		if (item) {
			item.selected = true;
		}
	});
	return data;
}

function shouldAllowChartToggle(mainColumn: string, analysisType: analysisTypes) {
	return (mainColumn === 'total' || mainColumn === 'included') && analysisType === analysisTypes.Percentage;
}

interface ReducerState {
	sections: DynamicSection[];
}

export function dynamicReducer(state: ReducerState = { sections: [] }, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.SetAnalysisName: {
			return {
				...state,
				sections: [],
			};
		}
		case ActionTypes.SetDynamicSections: {
			const { mainSegments, filterSegment, variations, attributes, mainColumn, analysisType } = action;
			const allowChartToggle = shouldAllowChartToggle(mainColumn, analysisType);
			let sections = getSections(mainSegments, filterSegment, variations).map(s => {
				let sectionState: any = state.sections.find((s2: any) => s2.employeeAttribute === s);
				if (!sectionState) {
					sectionState = getInitialState();
				}
				return {
					...sectionState,
					employeeAttribute: s,
					attributeName: getAttributeName(s, attributes),
					allowChartToggle,
				};
			});
			sections = sortBy(sections, s => s.employeeAttribute);
			return {
				...state,
				sections,
			};
		}
		case ActionTypes.FetchBySegmentPending: {
			const { employeeAttribute } = action;
			const index = state.sections.findIndex((s: any) => s.employeeAttribute === employeeAttribute);
			if (index > -1) {
				let section: any = state.sections[index];
				section = {
					...section,
					open: true,
					pending: true,
					empty: false,
				};
				const sections: any[] = state.sections.slice();
				sections[index] = section;
				return {
					...state,
					sections,
				};
			}
			return state;
		}
		case ActionTypes.FetchBySegmentFulfilled: {
			const { employeeAttribute } = action;
			const index = state.sections.findIndex((s: any) => s.employeeAttribute === employeeAttribute);
			if (index > -1) {
				const sections: any[] = state.sections.slice();
				const section = getStateForDynamicSection(sections[index], action);
				sections[index] = section;
				return {
					...state,
					sections,
				};
			}
			return state;
		}
		case ActionTypes.FetchBySegmentRejected: {
			const { employeeAttribute } = action;
			const index = state.sections.findIndex((s: any) => s.employeeAttribute === employeeAttribute);
			if (index > -1) {
				let section: any = state.sections[index];
				section = {
					...section,
					open: true,
					pending: false,
					error: true,
				};
				const sections: any[] = state.sections.slice();
				sections[index] = section;
				return {
					...state,
					sections,
				};
			}
			return state;
		}
		case ActionTypes.GeneratePrimaryDataFulfilledInDetailSection: {
			const { employeeAttribute } = action;
			return produce(state, (draftState: any) => {
				const sectionIndex = draftState.sections.findIndex(
					(s: any) => s.employeeAttribute === employeeAttribute
				);
				if (sectionIndex >= 0) {
					const section = draftState.sections[sectionIndex];
					const newSection = getStateForPrimaryData(section, action);
					draftState.sections[sectionIndex] = newSection;
				}
			});
		}
		case ActionTypes.FetchBySegmentNoData: {
			const { employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				if (section) {
					section.open = true;
					section.pending = false;
					section.empty = true;
				}
			});
		}
		case ActionTypes.CloseSegment: {
			const { employeeAttribute } = action;
			const index = state.sections.findIndex((s: any) => s.employeeAttribute === employeeAttribute);
			if (index > -1) {
				let section: any = state.sections[index];
				section = {
					...getInitialState(),
					employeeAttribute,
					attributeName: section.attributeName,
					generated: section.generated,
					allowChartToggle: section.allowChartToggle,
					chartType: section.chartType,
					open: false,
				};
				const sections: any[] = state.sections.slice();
				sections[index] = section;
				return {
					...state,
					sections,
				};
			}
			return state;
		}
		case ActionTypes.ToggleExpansion: {
			const { employeeAttribute } = action;
			const index = state.sections.findIndex((s: any) => s.employeeAttribute === employeeAttribute);
			if (index > -1) {
				let section: any = state.sections[index];
				section = {
					...section,
					expanded: !section.expanded,
				};
				const sections: any[] = state.sections.slice();
				sections[index] = section;
				return {
					...state,
					sections,
				};
			}
			return state;
		}
		case ActionTypes.ChangeLocation: {
			if (action.payload.location.pathname !== '/explore') {
				// clear the state when we leave explore
				return {
					...state,
					sections: [],
				};
			}
			return state;
		}
		case ActionTypes.ClearDynamicSections:
		case ActionTypes.ClearFilters:
			return {
				...state,
				sections: [],
			};
		case ActionTypes.SetRowFilter: {
			const { rowFilter, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				section.rowFilter = rowFilter;
				const { unfilteredData, sortCriteria, selection = [], shouldGetPrimaryData, total } = section;
				section.data = getData(unfilteredData, rowFilter, sortCriteria, selection);
				section.showGroupSummary = rowFilter.length > 0 && total > 0;
				if (section.showGroupSummary) {
					const { diversityAttributes, columnProps } = section;
					section.groupSummary = getSummary(
						diversityAttributes,
						columnProps,
						section.data,
						shouldGetPrimaryData,
						total
					);
				}
			});
		}
		case ActionTypes.SetColumnFilter: {
			const { columnFilter, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				section.columnFilter = columnFilter;
				section.diversityAttributes = getDiversityAttributes(
					section.unfilteredDiversityAttributes,
					columnFilter
				);
				const {
					unfilteredDiversityAttributes,
					columnProps,
					data,
					showGroupSummary,
					shouldGetPrimaryData,
					total,
				} = section;
				let diversityAttributes = unfilteredDiversityAttributes.slice();
				if (columnFilter.length) {
					diversityAttributes = unfilteredDiversityAttributes.filter((d: any) =>
						columnFilter.includes(d.label)
					);
				}
				section.diversityAttributes = diversityAttributes;
				if (showGroupSummary) {
					section.groupSummary = getSummary(
						diversityAttributes,
						columnProps,
						data,
						shouldGetPrimaryData,
						total
					);
				}
			});
		}
		case ActionTypes.ClearFilterInDetailSection: {
			const { employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				section.rowFilter = [];
				section.columnFilter = [];
				const { unfilteredData, sortCriteria, selection } = section;
				section.data = getData(unfilteredData, section.rowFilter, sortCriteria, selection);
				section.diversityAttributes = section.unfilteredDiversityAttributes.slice();
				section.showGroupSummary = false;
			});
		}
		case ActionTypes.SetSortCriteriaInDetailSection: {
			const { columnName, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				const { sortCriteria, data } = section;
				let ascending = true;
				if (columnName === sortCriteria.columnName) {
					ascending = !sortCriteria.ascending;
				}
				section.sortCriteria = {
					columnName,
					ascending,
				};
				section.data = orderBy(data, [columnName], [ascending ? 'asc' : 'desc']);
			});
		}
		case ActionTypes.HoverLabelInDetailSection: {
			const { label, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				const item = section.data.find((d: any) => d.label === label);
				if (item) {
					item.active = true;
				}
			});
		}
		case ActionTypes.UnhoverLabelInDetailSection: {
			const { label, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				const item = section.data.find((d: any) => d.label === label);
				if (item) {
					item.active = false;
				}
			});
		}
		case ActionTypes.ToggleLabelInDetailSection: {
			const { label, employeeAttribute } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				const item = section.data.find((d: any) => d.label === label);
				if (section.selection) {
					const index = section.selection.findIndex((s: any) => s === label);
					if (index !== -1) {
						section.selection.splice(index, 1);
						item.selected = false;
					} else {
						section.selection.push(label);
						item.selected = true;
					}
				} else {
					section.selection = [label];
					item.selected = true;
				}
			});
		}
		case ActionTypes.SetChartTypeInDetailSection: {
			const { employeeAttribute, chartType } = action;
			return produce(state, draftState => {
				const section: any = draftState.sections.find((s: any) => s.employeeAttribute === employeeAttribute);
				section.chartType = chartType;
			});
		}
		default:
			return state;
	}
}
