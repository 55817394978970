import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={15} viewBox="0 0 16 16" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M12.134 15.164H3.961V5.997L9.186.837l.369.368c.075.076.14.174.191.292a.824.824 0 0 1 .078.327v.131L8.97 5.997h5.6c.348 0 .66.137.934.412.274.275.412.586.412.934V8.37a1.467 1.467 0 0 1-.093.497l-2.276 5.394a1.481 1.481 0 0 1-.577.644c-.27.173-.55.26-.837.26ZM3.128 5.997v9.167H.083V5.997h3.045Z"
		/>
	</svg>
);
export default SvgComponent;
