import FileSaver from 'file-saver';
import { Base64 } from 'js-base64';
import CanvasFonts from '../../constants/CanvasFonts';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import drawRoundedRect from '../../lib/drawRoundedRect';
import loadImage from '../../lib/loadImage';
import { getComputedCssValue, getCssVariable, addStyle } from '../../lib/customColors';
import { toPng, toCanvas } from 'html-to-image';
import removeAnimations from '../../lib/removeAnimations';
import setupExportViewport from '../../lib/setupExportViewport';

const xPadding = 16;
const yPadding = 16;
const legendPadding = 16;
const legendItemHeight = 20;
const legendRadius = 2;

export default async function exportChart({
	reportId,
	title,
	analysisType,
	chartType,
	confidence,
	length,
	data,
	shouldReturnBlob,
	shouldReturnData,
}) {
	if (
		chartType === ChartTypes.ArithmeticTable ||
		chartType === ChartTypes.Funnel ||
		chartType === ChartTypes.Stats ||
		chartType === ChartTypes.Line ||
		chartType === ChartTypes.Bar ||
		chartType === ChartTypes.Pie ||
		chartType === ChartTypes.HorizontalBar ||
		chartType == ChartTypes.StackedBar
	) {
		const { node, viewport } = setupExportViewport(`#chart-${reportId}`);

		removeAnimations(node);

		switch (chartType) {
			case ChartTypes.ArithmeticTable: {
				let overflow = 0;

				const rightTable = node.querySelector('.generic-table__right-table');

				if (rightTable.scrollWidth > rightTable.clientWidth) {
					overflow = rightTable.scrollWidth - rightTable.clientWidth;
				}

				node.style.width = node.offsetWidth + overflow + 'px';
				node.style.flex = '0 0 auto';

				break;
			}
			case ChartTypes.Funnel: {
				let overflow = 0;
				const graphContainer = node.querySelector('[data-export-type="funnel"]');
				const startingHeight =
					getComputedStyle(graphContainer).getPropertyValue('--chartHeight').replace('px', '') * 1;

				if (graphContainer.scrollHeight > graphContainer.clientHeight) {
					overflow = graphContainer.scrollHeight - graphContainer.clientHeight;
				}

				node.style.width = '1200px';
				graphContainer.style = `--chartWidth: ${1200 - 32}px;--chartHeight: ${
					startingHeight + overflow
				}px;height: ${startingHeight + overflow}px;`;

				break;
			}
			case ChartTypes.Line: {
				node.style.width = node.querySelector('[data-export-type="linechart"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.Bar: {
				node.style.width = node.querySelector('[data-export-type="barchart"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.StackedBar: {
				node.style.width = node.querySelector('[data-export-type="stackedbar"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.Pie: {
				const benchmarkElement = node.querySelector('[data-export-type="benchmark"]');

				if (benchmarkElement) {
					node.style.width =
						node.querySelector('[data-export-type="piechart"]').width.baseVal.value * 2 + 'px';
				} else {
					node.style.width = node.querySelector('[data-export-type="piechart"]').width.baseVal.value + 'px';
				}
				break;
			}
			case ChartTypes.HorizontalBar: {
				node.style.width = '1200px';
				break;
			}
			default: {
				break;
			}
		}

		const exludeClasses = ['editor__chart__header__actions', 'scroll scroll--center scroll--right'];

		if (shouldReturnData) {
			const canvas = await toCanvas(node, {
				filter: node => {
					if (exludeClasses.includes(node.className)) {
						return false;
					}
					return true;
				},
			});

			viewport.removeChild(node);

			return {
				dataURL: canvas.toDataURL(),
				width: canvas.width,
				height: canvas.height,
			};
		} else if (shouldReturnBlob) {
			const canvas = await toCanvas(node, {
				filter: node => {
					if (exludeClasses.includes(node.className)) {
						return false;
					}
					return true;
				},
			});
			viewport.removeChild(node);
			const blob = await new Promise(resolve => canvas.toBlob(resolve));
			return {
				blob,
				width: canvas.width,
				height: canvas.height,
			};
		} else {
			const blob = await toPng(node, {
				filter: node => {
					if (exludeClasses.includes(node.className)) {
						return false;
					}
					return true;
				},
			});
			FileSaver.saveAs(blob, 'chart.png');
			viewport.removeChild(node);
		}
	} else {
		// Cloned node to keep the original DOM intact
		const svgElem = document.getElementById(reportId);
		let clonedSvg = svgElem.cloneNode(true);

		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const svgWidth = parseInt(svgElem.getAttribute('width'));
		const benchmarkElem = document.getElementById(reportId + '-benchmark');
		let clonedBenchmark;

		let benchmarkWidth;
		if (benchmarkElem) {
			clonedBenchmark = benchmarkElem.cloneNode(true);
			benchmarkWidth = parseInt(benchmarkElem.getAttribute('width'));
		}
		const canvasWidth = benchmarkElem ? svgWidth + xPadding + benchmarkWidth : svgWidth;
		canvas.width = canvasWidth;
		const svgHeight = parseInt(svgElem.getAttribute('height'));
		let yChart = yPadding + 42;
		let yLegend = yPadding + 42;
		let canvasHeight = yChart + svgHeight;
		if (confidence && length === 2) {
			canvasHeight += 17;
			yChart += 17;
			yLegend += 17;
		}

		let measuredWidth = 0;
		let maxLineWidth = canvasWidth - 2 * xPadding - legendItemHeight;
		if (length > 2) {
			maxLineWidth = 0.7 * maxLineWidth;
		}
		let lineCount = 1;
		let multiLine = false;

		data.forEach(d => {
			if (!d.isEqualityIndex && !d.isAttritionIndex) {
				const legendItemWidth =
					context.measureText(d.segment.text, CanvasFonts.Regular11).width + legendPadding;
				measuredWidth += legendItemWidth;

				if (measuredWidth > maxLineWidth) {
					multiLine = true;
					lineCount++;
					measuredWidth = legendItemWidth;
				}
			}
		});

		if (lineCount === 1) {
			maxLineWidth = measuredWidth;
		}

		let drawLegend = false;
		if (length === 2 || chartType !== ChartTypes.Pie) {
			drawLegend = true;
			canvasHeight += lineCount * legendItemHeight;
			yChart += lineCount * legendItemHeight;
		}

		canvas.height = canvasHeight;

		context.fillStyle = getComputedCssValue('--color-shade-h3');
		context.fillRect(0, 0, canvas.width, canvas.height);

		if (
			(analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index) &&
			(chartType === ChartTypes.Line || chartType === ChartTypes.Bar)
		) {
			context.fillStyle = 'red';
			context.fillRect(0, canvas.height - yPadding, canvas.width, yPadding);
		}

		context.fillStyle = getComputedCssValue('--color-ui-100');
		context.font = CanvasFonts.Medium18;
		context.fillText(title, xPadding, yPadding + 18);

		if (confidence) {
			const confidenceText = `Confidence ${confidence}%`;
			context.fillStyle = getComputedCssValue('--color-ui-100');
			context.font = CanvasFonts.Regular11;
			if (length === 2) {
				context.fillText(confidenceText, xPadding, yPadding + 35);
			} else {
				const xText = canvas.width - xPadding - context.measureText(confidenceText).width - 100;
				context.fillText(confidenceText, xText, yPadding + 18);
			}
		}

		if (drawLegend) {
			context.fillStyle = getComputedCssValue('--color-shade-h1');
			let xStart;
			if (multiLine) {
				if (length === 2) {
					xStart = xPadding + legendItemHeight / 2;
					drawRoundedRect(
						context,
						xPadding,
						yLegend,
						canvasWidth - 2 * xPadding,
						lineCount * legendItemHeight,
						4
					);
				} else {
					xStart = canvasWidth - xPadding - maxLineWidth - legendItemHeight / 2;
					drawRoundedRect(
						context,
						canvasWidth - xPadding - maxLineWidth - legendItemHeight,
						yLegend,
						maxLineWidth + legendItemHeight,
						lineCount * legendItemHeight,
						4
					);
				}
			} else {
				xStart = canvasWidth - xPadding - maxLineWidth - legendItemHeight / 2;
				drawRoundedRect(
					context,
					canvasWidth - xPadding - maxLineWidth - legendItemHeight,
					yLegend,
					maxLineWidth + legendItemHeight,
					lineCount * legendItemHeight,
					legendItemHeight / 2
				);
			}

			let xCurrent = xStart;
			let yCurrent = yLegend;
			const hasSelection = data.some(d => d.selected);
			data.forEach(d => {
				if (!d.isEqualityIndex && !d.isAttritionIndex) {
					context.fillStyle = getComputedCssValue(getCssVariable(d.color));
					if (hasSelection && !d.selected) {
						context.fillStyle = getComputedCssValue(getCssVariable(d.color)) + '3d';
					}
					context.font = CanvasFonts.Regular11;

					if (xCurrent + context.measureText(d.segment.text).width + legendPadding > canvasWidth - xPadding) {
						xCurrent = xStart;
						yCurrent += legendItemHeight;
					}

					context.beginPath();
					context.arc(xCurrent + legendRadius, yCurrent + legendItemHeight / 2, legendRadius, 0, 2 * Math.PI);
					context.fill();
					context.fillStyle = getComputedCssValue('--color-ui-100');
					context.fillText(d.segment.text, xCurrent + legendPadding / 2, yCurrent + legendItemHeight / 2 + 3);
					xCurrent = xCurrent + context.measureText(d.segment.text).width + legendPadding;
				}
			});
		}

		addStyle(svgElem.childNodes, clonedSvg.childNodes);

		const serializer = new XMLSerializer();
		const svgString = serializer.serializeToString(clonedSvg);

		let image = await loadImage('data:image/svg+xml;base64,' + Base64.encode(svgString));

		context.drawImage(image, 0, yChart, svgWidth, svgHeight);

		if (benchmarkElem) {
			addStyle(benchmarkElem.childNodes, clonedBenchmark.childNodes);
			const benchmarkString = serializer.serializeToString(clonedBenchmark);
			image = await loadImage('data:image/svg+xml;base64,' + Base64.encode(benchmarkString));
			context.drawImage(image, svgWidth + xPadding, yChart, benchmarkWidth, svgHeight);
		}

		image = await loadImage('/images/watermark.svg');

		context.drawImage(image, canvas.width - xPadding - image.width, yPadding + 20 - image.height);

		if (shouldReturnData) {
			return {
				dataURL: canvas.toDataURL(),
				width: canvas.width,
				height: canvas.height,
			};
		} else {
			const blob = await new Promise(resolve => canvas.toBlob(resolve));

			if (shouldReturnBlob) {
				return {
					blob,
					width: canvas.width,
					height: canvas.height,
				};
			} else {
				FileSaver.saveAs(blob, `${title}.png`);
			}
		}
	}
}
