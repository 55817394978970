import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../constants/Urls';
import { AsyncThunkConfig } from '../../store/store';
import { FeedbackCreateRequestTokenBased, FeedbackData, InsightsInvokeRequest, InsightsResponse } from '../aiTypes';

export const getInputSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
	'ai/insights/getInputSchema',
	async (_, {}) => {
		const response = await axios.get(`${Urls.AiInsightsApi}insights/input_schema`);
		return response.data;
	}
);

export const getOutputSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
	'ai/insights/getOutputSchema',
	async () => {
		const response = await axios.get(`${Urls.AiInsightsApi}insights/output_schema`);
		return response.data;
	}
);

export const getConfigSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
	'ai/insights/getConfigSchema',
	async () => {
		const response = await axios.get(`${Urls.AiInsightsApi}insights/config_schema`);
		return response.data;
	}
);

export const createFeedbackFromToken = createAsyncThunk<any, FeedbackCreateRequestTokenBased>(
	'ai/insights/createFeedbackFromToken',
	async feedback => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/token_feedback`, feedback);
		return response.data;
	}
);

export const invokeInsights = createAsyncThunk<InsightsResponse, InsightsInvokeRequest>(
	'ai/insights/invoke',
	async ({ input }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/invoke`, { input });
		return response.data;
	}
);

export const batchInvokeInsights = createAsyncThunk<any, { inputs: any[] }, AsyncThunkConfig>(
	'ai/insights/batchInvoke',
	async ({ inputs }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/batch`, { inputs });
		return response.data;
	}
);

export const streamInvokeInsights = createAsyncThunk<any, { input: any }, AsyncThunkConfig>(
	'ai/insights/streamInvoke',
	async ({ input }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/stream`, { input });
		return response.data;
	}
);

export const streamLogInsights = createAsyncThunk<any, { input: any }>('ai/insights/streamLog', async ({ input }) => {
	const response = await axios.post(`${Urls.AiInsightsApi}insights/stream_log`, { input });
	return response.data;
});

export const streamEventsInsights = createAsyncThunk<any, { input: any }>(
	'ai/insights/streamEvents',
	async ({ input }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/stream_events`, { input });
		return response.data;
	}
);

export const getConversationsList = createAsyncThunk<any, void>('ai/insights/getConversationsList', async () => {
	const response = await axios.post(`${Urls.AiInsightsApi}insights/conversations`);
	return response.data;
});

export const deleteConversation = createAsyncThunk<any, string>(
	'ai/insights/deleteConversation',
	async conversationId => {
		const response = await axios.delete(`${Urls.AiInsightsApi}insights/conversations`, {
			data: { conversation_id: conversationId },
		});
		return response.data;
	}
);

export const getMessagesList = createAsyncThunk<any, { conversationId: string }>(
	'ai/insights/getMessagesList',
	async ({ conversationId }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/messages`, {
			conversation_id: conversationId,
		});
		return response.data;
	}
);

export const deleteMessage = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
	'ai/insights/deleteMessage',
	async ({ conversationId, messageIdx }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}insights/messages`, {
			data: { conversation_id: conversationId, message_idx: messageIdx },
		});
		return response.data;
	}
);

export const provideInsightFeedbackMessage = createAsyncThunk<
	any,
	{ conversationId: string; messageIdx: number; feedback: FeedbackData }
>('ai/insights/provideFeedbackMessage', async ({ conversationId, messageIdx, feedback }) => {
	const response = await axios.put(`${Urls.AiInsightsApi}insights/feedback`, {
		conversation_id: conversationId,
		message_idx: messageIdx,
		feedback,
	});
	return response.data;
});

export const deleteFeedbackMessage = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
	'ai/insights/deleteFeedbackMessage',
	async ({ conversationId, messageIdx }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}insights/feedback`, {
			data: { conversation_id: conversationId, message_idx: messageIdx },
		});
		return response.data;
	}
);

export const addMessageMetadata = createAsyncThunk<any, { conversationId: string; messageIdx: number; metadata: any }>(
	'ai/insights/addMessageMetadata',
	async ({ conversationId, messageIdx, metadata }) => {
		const response = await axios.put(`${Urls.AiInsightsApi}insights/message/metadata`, {
			conversation_id: conversationId,
			message_idx: messageIdx,
			metadata,
		});
		return response.data;
	}
);

export const removeMessageMetadata = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
	'ai/insights/removeMessageMetadata',
	async ({ conversationId, messageIdx }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}insights/message/metadata`, {
			data: { conversation_id: conversationId, message_idx: messageIdx },
		});
		return response.data;
	}
);

export const setConversationMetadata = createAsyncThunk<any, { conversationId: string; metadata: any }>(
	'ai/insights/setConversationMetadata',
	async ({ conversationId, metadata }) => {
		const response = await axios.put(`${Urls.AiInsightsApi}insights/conversations/metadata`, {
			conversation_id: conversationId,
			metadata,
		});
		return response.data;
	}
);

export const getConversationMetadata = createAsyncThunk<any, { conversationId: string }>(
	'ai/insights/getConversationMetadata',
	async ({ conversationId }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}insights/conversations/metadata`, {
			conversation_id: conversationId,
		});
		return response.data;
	}
);

export const removeConversationMetadata = createAsyncThunk<any, { conversationId: string }>(
	'ai/insights/removeConversationMetadata',
	async ({ conversationId }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}insights/conversations/metadata`, {
			data: { conversation_id: conversationId },
		});
		return response.data;
	}
);
