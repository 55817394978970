import React from 'react';
import Modal from 'antd/lib/modal';
import Button from './Button';
import ButtonTypes from '../constants/ButtonTypes';

interface DeleteModalProps {
	isOpen: boolean;
	pending?: boolean;
	onAction: any;
	onCancel: any;
	title: string;
	confirmationText?: string;
	children: React.ReactNode;
}

export default function ConfirmationModal(props: DeleteModalProps) {
	const { isOpen, pending, onAction, onCancel, confirmationText, title, children } = props;
	return (
		<Modal
			visible={isOpen}
			width={400}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
		>
			<div className="modal modal--delete" onClick={e => e.stopPropagation()}>
				<div className="modal__header">
					<div className="modal__title">{title}</div>
				</div>
				<div className="modal__content">{children}</div>
				<div className="modal__footer">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
						Cancel
					</Button>
					<Button loading={pending} onClick={onAction} danger>
						{confirmationText || 'Confirm'}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
