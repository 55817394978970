import { Message, MessageComponentTypes, MessageTypes } from './types';

interface CreateInitialMessagesProps {
	onSave: any;
	reportIds: string[];
	firstName?: string;
	pending?: boolean;
	regenerateMode: boolean;
	reportSelectMode?: boolean;
}

export const createInitialMessages = ({
	onSave,
	reportIds,
	firstName,
	pending,
	regenerateMode,
	reportSelectMode,
}: CreateInitialMessagesProps) => {
	const messages: Message[] = [];

	if (regenerateMode) {
		messages.push({
			id: 'regenerate-intro-body',
			props: {
				showAvatar: true,
				type: MessageTypes.assistant,
			},
			componentType: MessageComponentTypes.component,
			component: 'MessageBubble',
			children: <span>{`Notice: New data is available.`}</span>,
		});
		messages.push({
			id: 'regenerate-intro-body-disclaimer',
			props: {
				type: MessageTypes.assistant,
				delay: 0.6,
			},
			componentType: MessageComponentTypes.component,
			component: 'MessageBubble',
			children: (
				<span>
					{`
It looks like the data has been refreshed, and this insight is now out of date. Please click the "Regenerate" button to update the report with the latest information.
`}
				</span>
			),
		});
		messages.push({
			id: 'regenerate-intro-body-disclaimer-2',
			props: {
				type: MessageTypes.assistant,
				delay: 0.9,
			},
			componentType: MessageComponentTypes.component,
			component: 'MessageBubble',
			children: (
				<span>
					{`Please be aware that any edits you've made to the text will be overwritten if the insight is regenerated and saved.`}
				</span>
			),
		});
		messages.push({
			id: 'regenerate-actions',
			props: {
				type: MessageTypes.actions,
				delay: 1.2,
			},
			componentType: MessageComponentTypes.component,
			component: 'RegenerateActions',
			children: null,
		});
	} else {
		if (pending) {
			messages.push({
				id: 'final-insights-ui',
				componentType: MessageComponentTypes.component,
				component: 'Insights',
				props: {
					type: MessageTypes.assistant,
				},
				children: null,
			});
		} else if (reportSelectMode) {
			messages.push({
				id: 'report-select-mode-description',
				componentType: MessageComponentTypes.component,
				component: 'MessageBubble',
				props: {
					type: MessageTypes.assistant,
					showAvatar: true,
				},
				children: (
					<span>
						Great choice, {firstName}! Please select the reports for which you'd like to generate insights.
						Once you've made your selection, click "Generate Insights" to proceed.
					</span>
				),
			});
			messages.push({
				id: 'report-select-mode-ui',
				componentType: MessageComponentTypes.component,
				component: 'ReportSelect',
				props: {
					type: MessageTypes.user,
					animate: false,
					showAvatar: true,
				},
				children: null,
			});
		} else if (onSave && !reportIds.length) {
			messages.push({
				id: 'intro',
				props: {
					showAvatar: true,
					type: MessageTypes.assistant,
				},
				componentType: MessageComponentTypes.component,
				component: 'MessageBubble',
				children: (
					<span>
						{`Hi ${
							firstName || ''
						}, welcome to Dandi AI! Please note that Dandi AI is currently in preview mode.
`}
					</span>
				),
			});
			messages.push({
				id: 'mode-selection',
				componentType: MessageComponentTypes.component,
				component: 'MessageBubble',
				props: {
					type: MessageTypes.assistant,
					delay: 0.6,
				},
				children: (
					<span>
						{`
To proceed, select one of the following options:
`}
					</span>
				),
			});
			messages.push({
				id: 'suggestions',
				componentType: MessageComponentTypes.component,
				component: 'DashboardOptions',
				props: {
					type: MessageTypes.actions,
					delay: 0.9,
				},
				children: null,
			});
		}
	}

	return messages;
};
