import Tooltip from 'antd/lib/tooltip';
import Avatar from '../../common/Avatar';
import AvatarTypes from '../../constants/AvatarTypes';
import cn from '../../lib/cn';

interface ChartTitleProps {
	title: string;
	isInsightsChart: boolean;
	showTooltip: boolean;
	disableExploreAccess: boolean;
	handleExplore: () => void;
}

const ChartTitle = ({ disableExploreAccess, isInsightsChart, title, showTooltip, handleExplore }: ChartTitleProps) => {
	if (isInsightsChart) {
		return (
			<div className={cn('flex items-center gap-[8px]')}>
				<Avatar type={AvatarTypes.type.DEFAULT} colorCode={1} size={24} />
				<span className={cn('text-ui-75')}>{title}</span>
			</div>
		);
	}

	if (showTooltip) {
		return (
			<Tooltip title={title} placement="topLeft" overlayClassName="editor__chart__tooltip">
				<span
					className={disableExploreAccess ? '' : 'editor__chart__header__title__clickable'}
					onClick={disableExploreAccess ? undefined : handleExplore}
				>
					{title}
				</span>
			</Tooltip>
		);
	}

	return (
		<span
			className={disableExploreAccess ? '' : 'editor__chart__header__title__clickable'}
			onClick={disableExploreAccess ? undefined : handleExplore}
		>
			{title}
		</span>
	);
};

export default ChartTitle;
