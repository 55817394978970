import { useState } from 'react';

import { getChatMessagesList } from '../../ai/chat/actions';
import { resetChat, setConversationId } from '../../ai/chat/reducer';
import ConversationMenu from '../../ai/ConversationMenu';
import { ConversationElement, getSortedConversations } from '../../ai/ConversationsList';
import { setView } from '../../ai/modal/reducer';
import useConversations from '../../ai/useConversations';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import HMore from '../../icons/HMoreVertical';
import PlusIcon from '../../icons/Plus';
import cn from '../../lib/cn';
import { track } from '../../lib/segment';
import { useAppDispatch } from '../../store/hooks';
import Button from '../Button';
import Popover from '../Popover';

export default function Conversations() {
	const dispatch = useAppDispatch();
	const { conversations } = useConversations();

	const sortedConversations = getSortedConversations(conversations);

	const handleNewChat = () => {
		track(MixPanel.Events.AIChatModalClickNewChat);
		dispatch(resetChat());
		dispatch(setView('chat'));
	};

	return (
		<div className={cn('flex flex-col gap-[3.2rem] pt-[6.2rem]')}>
			<Button onClick={handleNewChat} componentType={ButtonTypes.type.SECONDARY} classes={[cn('')]}>
				<div className={cn('flex items-center gap-[.8rem]')}>
					<PlusIcon className={cn('flex-shrink-0')} />
					<span>New chat</span>
				</div>
			</Button>
			<div className={cn('font-micro-text font-medium text-ui-75')}>Recent</div>
			<div className={cn('h-full overflow-scroll hide-scrollbar relative')}>
				<div className={cn('vertical gap-[.8rem]')}>
					{sortedConversations.map(conversation => (
						<ConversationsList key={conversation.id} conversation={conversation} />
					))}
				</div>
			</div>
		</div>
	);
}

const ConversationsList: React.FC<{ conversation: ConversationElement }> = ({ conversation }) => {
	const dispatch = useAppDispatch();
	const [moreOpen, setMoreOpen] = useState(false);

	const handleNavigateToConversation = (conversationId: string) => {
		track(MixPanel.Events.AIChatModalClickHistoryChat);
		dispatch(resetChat());
		dispatch(
			getChatMessagesList({
				conversationId,
			})
		);
		dispatch(setConversationId(conversationId));
		dispatch(setView('chat'));
	};

	const conversationName = conversation?.name || conversation.id;

	return (
		<div className={cn('flex')}>
			<button
				key={conversation.id}
				onClick={() => handleNavigateToConversation(conversation.id)}
				className={cn(
					'w-avail pl-[1.6rem] pr-[1.6rem] h-[40px] flex items-center justify-between',
					'bg-shade-3 text-ui-75 rounded-[1rem]',
					'hover:bg-shade-4 hover:text-ui-100'
				)}
			>
				<span className={cn('truncate mr-[1.6rem]')}>{conversationName}</span>
			</button>
			<Popover
				key={`${conversation.id}-popover`}
				placement="bottomRight"
				visible={moreOpen}
				onVisibleChange={() => {
					setMoreOpen(!moreOpen);
				}}
				content={
					<ConversationMenu
						conversationId={conversation.id}
						conversationName={conversationName}
						onClose={() => setMoreOpen(false)}
						isModal
					/>
				}
				overlayClassName="popover--no-padding"
			>
				<div className={cn('absolute right-[1.6rem] mt-[1rem] rounded-full')}>
					<HMore className="icon" />
				</div>
			</Popover>
		</div>
	);
};
