import produce from 'immer';
import orderBy from 'lodash/orderBy';

import ActionTypes from '../../constants/ActionTypes';
import GridConstants from '../../constants/GridConstants';
import {
	getColumn,
	getLength,
	getPosition,
	getPositionFromRC,
	getRow,
	setColumn,
	setLength,
	setPosition,
	setRow,
} from '../../lib/gridUtils';

interface ReducerState {
	dashboardId?: string;
	dirty?: boolean;
	name?: string;
	reports?: any;
	reportsByRow?: any;
	showDashboardFilters: boolean;
	hasHiddenReports: boolean;
	notifyAboutHiddenReports: boolean;
	pending?: boolean;
}

function getInitialState(): ReducerState {
	const dashboardId = window.location.pathname.split('/')[2];
	let notifyAboutHiddenReports = true;

	if (dashboardId) {
		const storedPreference = localStorage.getItem(`dashboard-${dashboardId}-notify-hidden-reports`);

		if (storedPreference) {
			notifyAboutHiddenReports = storedPreference === 'true';
		}
	}

	return {
		dashboardId,
		showDashboardFilters: false,
		hasHiddenReports: false,
		notifyAboutHiddenReports,
	};
}

export default function dashboardReducer(state: ReducerState = getInitialState(), action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.EditDashboard: {
			const { dashboard } = action;
			return {
				...dashboard,
				reportsByRow: produce([], draftState => {
					setReportsByRow(draftState, dashboard.reports);
				}),
			};
		}
		case ActionTypes.SetViewDashboard: {
			const { dashboard } = action;
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, dashboard.reports);
			});
			return {
				...state,
				...dashboard,
				reportsByRow,
			};
		}
		case ActionTypes.SetDashboardName: {
			const { name } = action;

			return {
				...state,
				name,
				dirty: true,
			};
		}
		case ActionTypes.MoveGraph: {
			const reports = produce(state.reports, (draftState: any) => {
				moveGraph(draftState, action.from, action.to);
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.MoveGraphInNewRow: {
			const { item, newRow } = action;
			const reports = produce(state.reports, (draftState: any) => {
				moveGraphInNewRow(draftState, item, newRow);
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.IncrementLength: {
			const { reportId } = action;
			const reports = produce(state.reports, (draftState: any) => {
				incrementLength(draftState, reportId);
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.RemoveRow: {
			const { row } = action;
			const reports = produce(state.reports, (draftState: any) => {
				const filteredReports = draftState.filter((r: any) => getRow(r) > row);
				filteredReports.forEach((r: any) => {
					setRow(r, getRow(r) - 1);
				});
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.Resize: {
			const { reportId, length } = action;
			const reports = produce(state.reports, (draftState: any) => {
				resize(draftState, reportId, length);
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.SaveReportFulfilled: {
			const { dashboardId, report } = action;
			if (dashboardId === state.dashboardId) {
				const reports = produce(state.reports, (draftState: any) => {
					draftState.push(report);
				});
				const reportsByRow = produce([], (draftState: any) => {
					setReportsByRow(draftState, reports);
				});
				return {
					...state,
					reports,
					reportsByRow,
					dirty: false,
				};
			}
			return state;
		}
		case ActionTypes.InsertReportFulfilled:
		case ActionTypes.UpdateAllReportsFulfilled:
		case ActionTypes.UpdateReportFulfilled: {
			const { dashboardId, dashboard } = action;
			if (dashboardId === state.dashboardId) {
				const { reports } = dashboard;
				const reportsByRow = produce([], (draftState: any) => {
					setReportsByRow(draftState, reports);
				});
				return {
					...state,
					reports,
					reportsByRow,
					dirty: false,
				};
			}
			return state;
		}
		case ActionTypes.DeleteReportFulfilled: {
			const { dashboardId, reportId } = action;
			if (dashboardId === state.dashboardId) {
				const reports = produce(state.reports, (draftState: any) => {
					const index = draftState.findIndex((r: any) => r.reportId === reportId);
					if (index !== -1) {
						draftState.splice(index, 1);
					}
				});
				const reportsByRow = produce([], draftState => {
					setReportsByRow(draftState, reports);
				});
				return {
					...state,
					reports,
					reportsByRow,
					dirty: false,
				};
			}
			return state;
		}
		case ActionTypes.DeleteReportLocal: {
			const { reportId } = action;
			const reports = produce(state.reports, (draftState: any) => {
				const index = draftState.findIndex((r: any) => r.reportId === reportId);
				if (index !== -1) {
					draftState.splice(index, 1);
				}
			});
			const reportsByRow = produce([], draftState => {
				setReportsByRow(draftState, reports);
			});
			return {
				...state,
				reports,
				reportsByRow,
				dirty: true,
			};
		}
		case ActionTypes.ShowDashboardFilters:
			return {
				...state,
				showDashboardFilters: !state.showDashboardFilters,
			};
		case ActionTypes.SetHasHiddenReports: {
			return {
				...state,
				hasHiddenReports: action.hasHiddenReports,
			};
		}
		case ActionTypes.SetNotifyAboutHiddenReports: {
			const dashboardId = window.location.pathname.split('/')[2];

			if (dashboardId) {
				localStorage.setItem(
					`dashboard-${dashboardId}-notify-hidden-reports`,
					action.notifyAboutHiddenReports.toString()
				);
			}

			return {
				...state,
				notifyAboutHiddenReports: action.notifyAboutHiddenReports,
			};
		}
		case ActionTypes.ChangeLocation: {
			return getInitialState();
		}

		case ActionTypes.UpdateDashboardPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.UpdateDashboardFulfilled: {
			return {
				...state,
				pending: false,
			};
		}
		default:
			return state;
	}
}

function fixReportsForMove(draftState: any, to: any, movedReport: any, reportIndex: number, freeSpace?: any) {
	setRow(movedReport, to.row);
	setColumn(movedReport, to.column);
	const reportLength = getLength(movedReport);
	if (freeSpace) {
		const nextReport = draftState.find((r: any) => getRow(r) === to.row && getColumn(r) > to.column);
		if (nextReport) {
			const length = Math.max(2, getColumn(nextReport) - to.column);
			setLength(movedReport, length);
		} else {
			if (to.column + reportLength - 1 > GridConstants.ColumnCount) {
				setLength(movedReport, GridConstants.ColumnCount - to.column + 1);
			}
		}
	} else {
		if (to.column + reportLength - 1 > GridConstants.ColumnCount) {
			setRow(movedReport, to.row + 1);
			setColumn(movedReport, 1);
		}
	}
	let toIndex = reportIndex + 1;
	let fixReports = true;
	while (toIndex < draftState.length && fixReports) {
		const prevReport = draftState[toIndex - 1];
		const curReport = draftState[toIndex];
		let expectedNextPosition = getPosition(prevReport) + getLength(prevReport);
		if (expectedNextPosition > getPosition(curReport)) {
			const diff = expectedNextPosition - getPosition(curReport);
			setPosition(curReport, expectedNextPosition);
			const row = getRow(curReport);
			const column = getColumn(curReport);
			let length = getLength(curReport);
			if (column + length - 1 > GridConstants.ColumnCount) {
				const chartType = curReport.configuration.find((c: any) => c.name === 'chartType').value;
				const isBenchmark = curReport.configuration.some((c: any) => c.name === 'benchmark');
				if (row === to.row && chartType !== 'Table' && chartType !== 'Matrix' && !isBenchmark) {
					length = Math.max(2, length - diff);
					setLength(curReport, length);
				}
				if (column + length - 1 > GridConstants.ColumnCount) {
					expectedNextPosition = getPositionFromRC(row + 1, 1);
					setPosition(curReport, expectedNextPosition);
				}
			}
		} else {
			fixReports = false;
		}
		toIndex++;
	}
}

function moveGraph(draftState: any, from: any, to: any) {
	const fromIndex = draftState.findIndex((r: any) => r.reportId === from.id);
	let movedReport;
	if (fromIndex !== -1) {
		movedReport = draftState[fromIndex];
		draftState.splice(fromIndex, 1);
	}
	if (to.id) {
		let toIndex = draftState.findIndex((r: any) => r.reportId === to.id);
		if (fromIndex !== -1 && toIndex !== -1) {
			draftState.splice(toIndex, 0, movedReport);
			fixReportsForMove(draftState, to, movedReport, toIndex);
		}
	} else {
		const emptyPosition = getPositionFromRC(to.row, to.column);
		let prevReports = draftState.filter((r: any) => getPosition(r) <= emptyPosition);
		prevReports = orderBy(prevReports, r => getPosition(r), 'desc');
		const prevReport = prevReports.length > 0 && prevReports[0];
		if (prevReport) {
			const toIndex = draftState.findIndex((r: any) => r.reportId === prevReport.reportId);
			if (toIndex !== -1) {
				draftState.splice(toIndex + 1, 0, movedReport);
				fixReportsForMove(draftState, to, movedReport, toIndex + 1, true);
			}
		} else {
			draftState.unshift(movedReport);
			fixReportsForMove(draftState, to, movedReport, 0, true);
		}
	}
}

function getEmptyCols(length: number, row: number, column: number, showRemove?: boolean) {
	const emptyCols = [];
	let emptyLength = length;
	let startCol = column;
	if (showRemove) {
		// When there are no reports in a row / or empty row
		emptyCols.push({
			removeRow: true,
			length: GridConstants.AddGraphLength,
			row,
			column,
		});
		emptyLength -= GridConstants.AddGraphLength;
		startCol += GridConstants.AddGraphLength;
	}
	for (let i = 0; i < emptyLength; i++) {
		emptyCols.push({
			empty: true,
			length: 1,
			row,
			column: startCol,
		});
		startCol++;
	}
	return emptyCols;
}

function setReportsByRow(reportsByRow: any, reports: any) {
	if (reports.length === 0) {
		const reportRow = getEmptyCols(GridConstants.ColumnCount, 1, 1);
		reportsByRow.push(reportRow);
		return;
	}
	const lastReport = reports[reports.length - 1];
	const lastReportRow = parseInt(lastReport.configuration.find((c: any) => c.name === 'row')?.value);
	for (let i = 1; i <= lastReportRow; i++) {
		const reportsInRow = reports.filter((r: any) => {
			return parseInt(r.configuration.find((c: any) => c.name === 'row')?.value) === i;
		});
		if (reportsInRow.length === 0) {
			reportsInRow.push(...getEmptyCols(GridConstants.ColumnCount, i, 1, i !== lastReportRow));
			reportsByRow.push(reportsInRow);
			continue;
		}
		let startCol = 1;
		for (let j = 0; j < reportsInRow.length; j++) {
			const report = reportsInRow[j];
			const reportCol = parseInt(report.configuration.find((c: any) => c.name === 'column').value);
			const reportLength = parseInt(report.configuration.find((c: any) => c.name === 'length').value);
			if (startCol < reportCol) {
				const columns = getEmptyCols(reportCol - startCol, i, startCol);
				reportsInRow.splice(j, 0, ...columns);
				j += columns.length;
			}
			startCol = reportCol + reportLength;
		}
		if (startCol < GridConstants.ColumnCount + 1) {
			reportsInRow.push(...getEmptyCols(GridConstants.ColumnCount + 1 - startCol, i, startCol));
		}
		reportsByRow.push(reportsInRow);
	}
}

function moveGraphInNewRow(draftState: any, item: any, newRow: any) {
	const fromIndex = draftState.findIndex((r: any) => r.reportId === item.id);
	if (fromIndex === -1) return;
	const movedReport = draftState[fromIndex];
	draftState.splice(fromIndex, 1);
	const filteredReports = draftState.filter((r: any) => getRow(r) >= newRow);
	let nextReportId: any;
	let minPosition = 1000;
	filteredReports.forEach((r: any) => {
		setRow(r, getRow(r) + 1);
		const position = getPosition(r);
		if (position < minPosition) {
			minPosition = position;
			nextReportId = r.reportId;
		}
	});
	setRow(movedReport, newRow);
	setColumn(movedReport, 1);
	if (nextReportId) {
		const toIndex = draftState.findIndex((r: any) => r.reportId === nextReportId);
		if (toIndex === -1) return;
		draftState.splice(toIndex, 0, movedReport);
	} else {
		draftState.push(movedReport);
	}
}

function fixReportsForResize(draftState: any, reportIndex: any) {
	let fixReports = true;
	const reportRow = getRow(draftState[reportIndex]);
	while (reportIndex < draftState.length - 1 && fixReports) {
		const prevReport = draftState[reportIndex];
		let expectedNextPosition = getPosition(prevReport) + getLength(prevReport);
		reportIndex++;
		const nextReport = draftState[reportIndex];
		const nextPosition = getPosition(nextReport);
		if (nextPosition < expectedNextPosition) {
			setPosition(nextReport, expectedNextPosition);
			const row = getRow(nextReport);
			const column = getColumn(nextReport);
			let length = getLength(nextReport);
			if (row === reportRow) {
				length = Math.max(2, length - expectedNextPosition + nextPosition);
				setLength(nextReport, length);
			}
			if (column + length - 1 > GridConstants.ColumnCount) {
				expectedNextPosition = getPositionFromRC(row + 1, 1);
				setPosition(nextReport, expectedNextPosition);
			}
		} else {
			fixReports = false;
		}
	}
}

function incrementLength(draftState: any, reportId: string) {
	let reportIndex = draftState.findIndex((r: any) => r.reportId === reportId);
	if (reportIndex === -1) return;
	const report = draftState[reportIndex];
	setLength(report, getLength(report) + 1);
	const otherReports = draftState.filter(
		(r: any, i: number) => getRow(r) === getRow(report) && r.reportId !== reportId && i > reportIndex
	);
	otherReports.forEach((or: any) => {
		if (!or.originalConfiguration) {
			or.originalConfiguration = {
				length: getLength(or),
				row: getRow(or),
				column: getColumn(or),
			};
		}
	});
	let fixReports = true;
	if (otherReports.length === 1) {
		const nextReport = otherReports[0];
		const expectedNextPosition = getPosition(report) + getLength(report);
		const nextPosition = getPosition(nextReport);
		const nextReportLength = getLength(nextReport);
		if (nextPosition < expectedNextPosition && nextReportLength > GridConstants.MinGraphLength) {
			setPosition(nextReport, expectedNextPosition);
			setLength(nextReport, nextReportLength - 1);
			fixReports = false;
		}
	}
	if (fixReports) {
		fixReportsForResize(draftState, reportIndex);
	}
}

function resize(draftState: any, reportId: string, length: number) {
	let reportIndex = draftState.findIndex((r: any) => r.reportId === reportId);
	if (reportIndex === -1) return;
	const report = draftState[reportIndex];
	setLength(report, length);
	const otherReports = draftState.filter((r: any) => !!r.originalConfiguration);
	const fixReports = otherReports.length > 0;
	otherReports.forEach((or: any) => {
		const { row, column, length } = or.originalConfiguration;
		setRow(or, row);
		setColumn(or, column);
		setLength(or, length);
		or.originalConfiguration = undefined;
	});
	if (fixReports) {
		fixReportsForResize(draftState, reportIndex);
	}
}
