import { Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import Button from '../../../common/Button';
import Stack from '../../../common/Stack';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';
import Trash from '../../../icons/Trash';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createDynamicAudience, updateDynamicAudience } from '../edit/actions';
import { reset, setDescription, setSegments, setTitle } from '../edit/slice';
import { Segment } from '../edit/types';
import SegmentPicker from './SegmentPicker';
import SEGMENTS_LIST from './segment_list';
import useSegmentPicker from './useSegmentPicker';
import { ActionTypes } from './useSegmentPicker/types';
import { generateReadableValues, generateSegmentsState } from './utils';

const DynamicAudienceModal = () => {
	const dispatch = useAppDispatch();
	const { modalOpen, title, description, validated, pending, mode, segments, error } = useAppSelector(
		state => state.audiences.edit
	);
	const accountFields = useAppSelector(state => state.enterpriseFields.fields?.accountFields || []);

	const [showSecondSegment, setShowSecondSegment] = useState(segments?.length > 1);
	const mappings = useAppSelector(state => state.enterpriseFields.mappings);

	const dropdownOptions = useMemo(() => {
		return generateReadableValues(SEGMENTS_LIST, accountFields, mappings);
	}, [accountFields]);

	function getEditState(mode: 'create' | 'edit', segments: Segment[], index: number) {
		if (mode === 'edit') {
			const segment = segments[index];
			if (!segment) return {};
			return {
				segment: segment.name,
				values: segment.values,
				status: 'valuesSelected',
			};
		}

		return {};
	}

	const [state1, dispatch1] = useSegmentPicker({
		options: dropdownOptions,
		editState: getEditState(mode, segments, 0),
	});
	const [state2, dispatch2] = useSegmentPicker({
		options: dropdownOptions,
		editState: getEditState(mode, segments, 1),
	});

	useEffect(() => {
		dispatch(setSegments(generateSegmentsState(state1, state2, dropdownOptions)));
	}, [state1.segment, state1.values, state2.segment, state2.values, dropdownOptions]);

	return (
		<Modal
			visible={modalOpen}
			width={800}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={() => dispatch(reset())}
			zIndex={3000}
			bodyStyle={{
				backgroundColor: 'var(--color-shade-h3)',
				borderRadius: '.8rem',
			}}
		>
			<Stack gap="3.2rem">
				<Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
					<Stack gap=".8rem">
						<h3 className="font-subtitle">Dynamic Audience</h3>
						<span
							//className="font-micro-text"
							style={{
								color: 'var(--color-ui-50)',
							}}
						>
							Dynamic audiences use the latest employee data from your HRIS to automatically refresh. By
							default, all dynamic audiences are set to include all currently active employees. You can
							further refine the audience by applying the filters below.
						</span>
					</Stack>
					<Stack gap=".8rem" flexDirection="row" alignItems="center">
						<Button
							componentType={ButtonTypes.type.TERTIARY}
							onClick={() => dispatch(reset())}
							disabled={pending}
						>
							Cancel
						</Button>
						<Button
							disabled={!validated || pending}
							onClick={() => {
								if (mode === 'create') {
									dispatch(createDynamicAudience());
								} else if (mode === 'edit') {
									dispatch(updateDynamicAudience());
								}
							}}
						>
							{pending ? 'Saving...' : 'Save'}
						</Button>
					</Stack>
				</Stack>
				<Stack gap="1.6rem">
					<div>Define audience</div>
					<Stack gap="1.6rem">
						<Stack flexDirection="row" alignItems="center">
							<SegmentPicker
								state={state1}
								dispatch={dispatch1}
								onActivate={() => dispatch2({ type: ActionTypes.CLOSE_ALL })}
							/>
							<Stack
								justifyContent="flex-end"
								style={{
									alignSelf: 'flex-end',
									cursor: !state1.segment ? 'not-allowed' : 'pointer',
									color: 'var(--color-ui-50)',
									opacity: !state1.segment ? 0.5 : 1,
								}}
								onClick={() => {
									if (!state1.segment) return;
									if (state2.segment) {
										dispatch1({
											type: ActionTypes.SET_STATE,
											payload: {
												...state2,
											},
										});
										dispatch2({
											type: ActionTypes.RESET,
										});
									} else {
										dispatch1({
											type: ActionTypes.RESET,
										});
									}
									setShowSecondSegment(false);
								}}
							>
								<Trash />
							</Stack>
						</Stack>
						{showSecondSegment ? (
							<Stack flexDirection="row" alignItems="center">
								<SegmentPicker
									state={state2}
									dispatch={dispatch2}
									onActivate={() =>
										dispatch1({
											type: ActionTypes.CLOSE_ALL,
										})
									}
								/>
								<Stack
									justifyContent="flex-end"
									style={{
										alignSelf: 'flex-end',
										cursor: 'pointer',
										color: 'var(--color-ui-50)',
									}}
									onClick={() => {
										dispatch2({
											type: ActionTypes.RESET,
										});
										setShowSecondSegment(false);
									}}
								>
									<Trash />
								</Stack>
							</Stack>
						) : (
							<Stack flexDirection="row" alignItems="center">
								<Button
									style={{ minWidth: 64 }}
									onClick={() => setShowSecondSegment(true)}
									componentType={ButtonTypes.type.SECONDARY}
									disabled={state1.status !== 'valuesSelected'}
								>
									<MdAdd />
								</Button>
							</Stack>
						)}
					</Stack>
				</Stack>
				<Stack gap="1.6rem">
					<div>Information</div>
					<MessageBox
						label="Name"
						value={title || ''}
						placeholder="Type name here"
						onChange={e => dispatch(setTitle(e.target.value))}
					/>
					<MessageBox
						label="Description (optional)"
						placeholder="Type description here"
						value={description || ''}
						onChange={e => dispatch(setDescription(e.target.value))}
					/>
				</Stack>
			</Stack>
		</Modal>
	);
};

const MessageBox = ({
	label,
	value,
	placeholder,
	onChange,
}: {
	label: string;
	value: string;
	placeholder?: string;
	onChange: (e: any) => void;
}) => {
	return (
		<Stack
			gap=".8rem"
			style={{
				padding: '0 2px',
			}}
		>
			<div className="ds-label">{label}</div>
			<div className="ds-input">
				<TextField value={value} onChange={onChange} placeholder={placeholder} />
			</div>
		</Stack>
	);
};

export default DynamicAudienceModal;
