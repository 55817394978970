import ChartColors from '../constants/ChartColors';

let colorMap: any = {};

export function getChartColor(label: string, skipColors: number = 0) {
	if (!colorMap[label]) {
		colorMap[label] = ChartColors[(Object.keys(colorMap).length % ChartColors.length) + skipColors];
	}
	return colorMap[label];
}

export function clear() {
	colorMap = {};
}
