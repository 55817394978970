import { Modal } from 'antd';
import React from 'react';
import sanitize from '../../lib/sanitize';
import { addEvent, closeAddEventModal, editEvent } from '../actions';
import EventDetails from './EventDetails';
import allColors from './EventDetails/ColorPicker/colors';
import { IColor } from './EventDetails/ColorPicker/types';
import KPIs from './KPIs';
import Tabs from './Tabs';
import { TabName } from './types';
import { addMessage } from '../../common/actions';
import { getColorFromName } from '../utils';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface AddEventProps {
	activeTab: TabName;
}

const tabs: TabName[] = ['Event Details'];

/**
 *  Add tab components in this switch/case statement to render them
 * @param activeTab Tab component to render
 * @returns
 */
const getTab = (activeTab?: TabName) => {
	if (!activeTab) return EventDetails;

	switch (activeTab) {
		case 'KPIs':
			return KPIs;
		default:
			return EventDetails;
	}
};

const AddEvent = ({ activeTab }: AddEventProps) => {
	const dispatch = useAppDispatch();
	const { toEdit: event, showAddEventModal: isOpen } = useAppSelector(state => state.timeline);

	const [name, setName] = React.useState(event?.name ?? '');
	const [description, setDescription] = React.useState(event?.description ?? '');
	const [startDate, setStartDate] = React.useState(event?.dateStart ?? '');
	const [endDate, setEndDate] = React.useState(event?.dateEnd ?? '');
	const [color, setColor] = React.useState(
		event ? { name: event.color, color: getColorFromName(event.color) } : allColors[0]
	);

	React.useEffect(() => {
		if (!event) return;
		setName(event.name);
		setDescription(event.description);
		setStartDate(event.dateStart);
		setEndDate(event.dateEnd ?? '');
		setColor({ name: event.color, color: getColorFromName(event.color) });
	}, [event]);

	React.useEffect(() => {
		if (!isOpen) emptyState();
	}, [isOpen]);

	const TabComponent = getTab(activeTab);

	const handleNameChange = (name: string) => {
		setName(name);
	};

	const handleDescriptionChange = (description: string) => {
		setDescription(description);
	};

	const handleDateRangeChange = (dates: string[]) => {
		if (dates.length > 0) {
			setStartDate(dates[0]);
		}
		if (dates.length > 1 && dates[1] !== '') {
			setEndDate(dates[1]);
		}
	};

	const handleColorChange = (color: IColor) => {
		setColor(color);
	};

	const handleClose = () => {
		dispatch(closeAddEventModal());
	};

	const handleCancel = () => {
		handleClose();
		emptyState();

		track(MixPanel.Events.TimelineEventCancelClick, {
			'Event Name': name || 'Name not set',
		});
	};

	const handleSave = (e: any) => {
		e?.preventDefault();
		const cleanedName = sanitize(name.trim());

		const dateRange = endDate && endDate !== '' ? [startDate, endDate] : [startDate];

		if (event) {
			dispatch(
				editEvent({
					eventId: event.eventId,
					name: cleanedName,
					description,
					color: color.name,
					dateStart: dateRange[0],
					dateEnd: dateRange[1],
				})
			);
			dispatch(addMessage(`Event "${cleanedName}" edited successfully`));
			track(MixPanel.Events.TimelineEventEdit, {
				'Event Name': cleanedName || 'Name not set',
				'Start Date': moment(dateRange[0]).format('MM/DD/YYYY'),
				'End Date': moment(dateRange[1]).format('MM/DD/YYYY'),
				Description: description || 'Description not set',
				'Old Event Name': event.name,
				'Old Start Date': event.dateStart,
				'Old End Date': event.dateEnd,
				'Old Description': event.description || 'Description not set',
			});
		} else {
			dispatch(
				addEvent({
					eventId: '',
					name: cleanedName,
					description,
					color: color.name,
					dateStart: dateRange[0],
					dateEnd: dateRange[1],
				})
			);
			dispatch(addMessage(`Event "${cleanedName}" added successfully`));
			track(MixPanel.Events.TimelineEventCreate, {
				'Event Name': cleanedName || 'Name not set',
				'Start Date': dateRange[0] || 'Start date not set',
				'End Date': dateRange[1] || 'End date not set',
				Description: description || 'Description not set',
			});
		}
		dispatch(closeAddEventModal());
		emptyState();
	};

	const emptyState = () => {
		setName('');
		setDescription('');
		setStartDate('');
		setEndDate('');
		setColor(allColors[0]);
	};

	return (
		<Modal
			open={isOpen}
			width={700}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={handleClose}
			zIndex={2000}
			destroyOnClose
		>
			{tabs.length > 1 && <Tabs tabs={tabs} />}
			<TabComponent
				handleSave={handleSave}
				handleCancel={handleCancel}
				handleClose={event ? handleCancel : handleClose}
				name={name}
				setName={handleNameChange}
				description={description}
				setDescription={handleDescriptionChange}
				dateRange={[startDate, endDate]}
				setDateRange={handleDateRangeChange}
				color={color as IColor}
				setColor={handleColorChange}
				open={isOpen}
			/>
		</Modal>
	);
};

export default AddEvent;
