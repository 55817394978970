import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { updateAccount } from '../admin/settings/actions';
import Button from './Button';
import ButtonTypes from '../constants/ButtonTypes';
import classNames from 'classnames';
import customColorsMachine from '../admin/settings/customColorsMachine';
import DefaultColors from '../constants/DefaultColors';
import ThemeColorCardTypes from '../constants/ThemeColorCard';
import WithPermissions from './WithPermissions';
import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';

function ThemeColorCard({ colors, updateAccount, cardType, ...props }: any) {
	const editAllowed = usePermissions({
		actions: [RbacActions['Settings/Edit']],
	});
	const [state, send] = useReducer(customColorsMachine, {
		context: {
			customColors: colors,
		},
		status: ThemeColorCardTypes.states.IDLE,
	});

	return (
		<div
			className={classNames('theme-color-card', {
				'theme-color-card--light': cardType === 'light',
			})}
			{...props}
		>
			<div className="theme-color-card__top">
				<div className="theme-color-card__title">{`Colors – ${
					cardType === 'light' ? 'Light' : 'Dark'
				} mode`}</div>
				<WithPermissions actions={[RbacActions['Settings/Edit']]}>
					<div className="theme-color-card__actions">
						<Button
							onClick={() => {
								send({
									type: ThemeColorCardTypes.events.RESET,
									payload: {
										action: updateAccount,
									},
								});
							}}
							componentType={ButtonTypes.type.TERTIARY}
						>
							Reset
						</Button>
						<Button
							componentType={ButtonTypes.type.TERTIARY}
							onClick={() => {
								send({
									type: ThemeColorCardTypes.events.SAVE_DEFAULT,
									payload: {
										action: updateAccount,
									},
								});
							}}
						>
							Set as default
						</Button>
						<Button
							onClick={() => {
								send({
									type: ThemeColorCardTypes.events.SAVE,
									payload: {
										action: updateAccount,
									},
								});
							}}
							disabled={state.status === ThemeColorCardTypes.states.IDLE}
							classes={['ml8']}
						>
							Save
						</Button>
					</div>
				</WithPermissions>
			</div>
			<div className="theme-color-card__palette">
				{state.context.customColors.map((obj: any, index: number) => (
					<div
						key={index}
						style={{
							'--hex': obj.colors[cardType],
						}}
						className={classNames('theme-color-card__color', {
							'theme-color-card__color--primary': obj.name === 'graph-1',
						})}
					>
						<div className="theme-color-card__circle">
							<div>{`${index + 1}${obj.name === 'graph-1' ? '*' : ''}`}</div>
						</div>
						<input
							className="theme-color-card__input"
							type="text"
							value={obj.colors[cardType]}
							readOnly={!editAllowed}
							onChange={e => {
								if (!editAllowed) return;

								const hex = e.target.value.replace(/ /g, '');
								send({
									type: ThemeColorCardTypes.events.EDIT,
									payload: {
										name: obj.name,
										colors: {
											light: cardType === 'light' ? hex : obj.colors.light,
											dark: cardType === 'dark' ? hex : obj.colors.dark,
											lightDefault: obj.colors.lightDefault,
											darkDefault: obj.colors.darkDefault,
										},
									},
								});
							}}
						/>
					</div>
				))}
				<div className="theme-color-card__note">*Primary UI color</div>
			</div>
		</div>
	);
}

ThemeColorCard.defaultProps = {
	onReset: () => {},
	updateAccount: () => {},
	cardType: 'dark',
	colors: DefaultColors.graph,
};

function mapState(state: any) {
	return {
		colors: state.account.customColors,
	};
}

const dispatchProps = {
	updateAccount,
};

export default connect(mapState, dispatchProps)(ThemeColorCard);
