import { forwardRef, useMemo } from 'react';

import cn from '../lib/cn';
import { IChartLegendProps } from '../lib/getChartDeclaration';

type Ref = HTMLDivElement;

const Legend = forwardRef<Ref, IChartLegendProps>((props, ref) => {
	const {
		data,
		hoveredItem,
		multiLine,
		onHover,
		onHoverEnd,
		onToggle,
		overlayData = [],
		selection = [],
		status,
	} = props;

	const filteredData = useMemo(() => {
		return [
			...data.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex),
			...overlayData.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex),
		];
	}, [data, overlayData]);

	if (filteredData.length <= 0) return null;

	return (
		<div
			className={cn('legend', `legend--default`, {
				[`legend--default--multi-line`]: multiLine,
			})}
			ref={ref}
		>
			<div className="legend__inner">
				{filteredData.map((item: any) => (
					<div
						className={cn(`legend--default__item`, {
							[`legend--default__item--inactive`]:
								status !== 'idle' && !selection.includes(item.label) && hoveredItem !== item.label,
						})}
						key={item.label}
						onMouseOver={() => onHover(item.label)}
						onMouseOut={() => onHoverEnd(item.label)}
						onClick={e => {
							e.stopPropagation();
							onToggle(item.label);
						}}
					>
						<div
							className={`legend--default__${item.isOverlay ? 'diamond' : 'circle'}`}
							style={{ backgroundColor: item.color }}
						/>
						<div
							className={cn(`legend--default__label`, {
								[`legend--default__label--active`]:
									status !== 'idle' && (hoveredItem === item.label || selection.includes(item.label)),
							})}
						>
							{!!overlayData.length && !!item.analysisName && `${item.analysisName} `}
							{item.segment && item.segment.text}
						</div>
					</div>
				))}
			</div>
		</div>
	);
});

export default Legend;
