import moment from 'moment';

import { useAppSelector } from '../store/hooks';

export default function Footer() {
	let { latestDataUpdate, isDataReady } = useAppSelector(state => state.account);

	latestDataUpdate = moment.utc(latestDataUpdate).format('MM/DD/YY');

	return (
		<div className="nav__footer">
			<div className="nav__footer__content">
				{isDataReady && <div>Latest Data Update: {latestDataUpdate}</div>}
				<div>
					<a href="https://www.itsdandi.com/terms" className="link" target="_blank" rel="noopener noreferrer">
						Terms
					</a>
					&nbsp;&amp;&nbsp;
					<a
						href="https://www.itsdandi.com/privacy"
						className="link"
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy
					</a>
				</div>
			</div>
		</div>
	);
}
