import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';
import cn from '../lib/cn';

interface Props {
	id: UniqueIdentifier;
	direction?: 'horizontal' | 'vertical';
	disabled?: boolean;
}

export default function Droppable({
	id,
	children,
	disabled = false,
	direction = 'vertical',
}: PropsWithChildren<Props>) {
	const { setNodeRef, isOver } = useDroppable({
		id,
		data: {
			isDisabled: disabled,
		},
	});

	return (
		<div data-name="dnd-droppable-item" ref={setNodeRef} className={cn('h-full relative')}>
			<div
				data-name="dnd-droppable-indicator"
				className={cn(
					'absolute  bg-ui-30 rounded-full ',
					direction === 'horizontal'
						? 'left-0 top-[8px] h-[4px] mx-16 w-avail'
						: 'top-0 left-[-2px] h-auto my-16 h-avail w-[4px]',
					isOver && !disabled ? 'visible opacity-100' : 'invisible opacity-0'
				)}
			></div>
			{children}
		</div>
	);
}
