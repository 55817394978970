import { combineReducers } from 'redux';
import saveReducer from './buttons/saveReducer';
import chartReducer from './chart/reducer';
import filterReducer from './filter/reducer';
import titleReducer from './titleSlice';

export default combineReducers({
	filter: filterReducer,
	chart: chartReducer,
	save: saveReducer,
	title: titleReducer,
});
