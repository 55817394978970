import { useContext, useRef, useState, useEffect, Fragment, PropsWithChildren } from 'react';
import { FunnelChartContext } from './index';
import DownArrow from '../../icons/DownArrow';
import Person from '../../icons/Person';
import formatValue from '../../lib/formatValue';

const Graph = ({ children }: PropsWithChildren) => {
	const { chartWidth, chartHeight } = useContext(FunnelChartContext);
	return (
		<div
			style={
				{
					'--chartWidth': chartWidth + 'px',
					'--chartHeight': chartHeight + 'px',
				} as React.CSSProperties
			}
			className="funnel__graph"
			data-export-type="funnel"
		>
			{children}
		</div>
	);
};

const Rows = ({ children }: PropsWithChildren) => {
	return <div className="funnel__rows">{children}</div>;
};

const Row = ({ children }: PropsWithChildren) => {
	const { gridRowHeight } = useContext(FunnelChartContext);
	return (
		<div
			className="funnel__row"
			style={
				{
					'--gridRowHeight': `${gridRowHeight}px`,
				} as React.CSSProperties
			}
		>
			{children}
		</div>
	);
};

const StageTitle = ({ children }: PropsWithChildren) => {
	return <div className="funnel__stage-title">{children}</div>;
};

const Bar = ({ width, children, showEmptyState }: any) => {
	const { chartWidth } = useContext(FunnelChartContext);

	return (
		<div
			className="funnel__bar"
			style={
				{
					'--barWidth': showEmptyState ? 'auto' : width,
					'--barWidthPx': (width / 100) * chartWidth + 'px',
				} as React.CSSProperties
			}
		>
			{showEmptyState ? <EmptyState /> : <Fragment>{children}</Fragment>}
		</div>
	);
};

const EmptyState = () => {
	return <div className="funnel__empty-state">N/A</div>;
};

const BarLine = () => {
	const { multiSegment, labelColumnWidth } = useContext(FunnelChartContext);
	if (multiSegment) return null;
	return (
		<svg
			style={
				{
					'--labelColumnWidth': labelColumnWidth + 'px',
				} as React.CSSProperties
			}
			className="funnel__bar-line"
			height="100%"
		>
			<line x1="0" y1="50%" x2="100%" y2="50%" />
			<line x1="100%" y1="0%" x2="100%" y2="100%" />
		</svg>
	);
};

const Segment = ({ segment, labelLeft, totalCount }: any) => {
	const { onHover, onHoverEnd, onToggle, multiSegment, hoveredItem, selection } = useContext(FunnelChartContext);

	const itemActive =
		selection.includes(segment.segment.tokens[0].text) || hoveredItem === segment.segment.tokens[0].text;

	return (
		<div
			style={
				{
					'--width': segment.segmentWidth + '%',
					'--x': segment.segmentXStart + '%',
					'--color': segment.segment.tokens[0]?.color,
				} as React.CSSProperties
			}
			data-active={itemActive}
			className="funnel__segment-wrapper"
		>
			<svg
				className="funnel__segment"
				onMouseOver={() => {
					onHover(segment.segment.tokens[0].text);
				}}
				onMouseOut={() => {
					onHoverEnd();
				}}
				onClick={e => {
					e.stopPropagation();
					onToggle(segment.segment.tokens[0].text);
				}}
				width="100%"
				height="100%"
			>
				<rect width="100%" height="100%" />
			</svg>

			{multiSegment && (
				<SegmentLabel segment={segment} labelLeft={labelLeft} totalCount={totalCount} visible={itemActive} />
			)}
		</div>
	);
};

const SegmentLabel = ({ segment, labelLeft, totalCount, visible }: any) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [labelWidth, setLabelWidth] = useState<number>(0);
	const { count, rate } = segment;

	useEffect(() => {
		if (!ref.current) return;
		const resizeObserver = new ResizeObserver(entries => {
			const { width } = entries[0].contentRect;
			setLabelWidth(width);
		});

		resizeObserver.observe(ref.current);

		return () => resizeObserver.disconnect();
	}, []);

	const leftSide = labelLeft && count >= 1;

	return (
		<div
			className="funnel__segment-label"
			style={
				{
					'--labelWidth': leftSide ? -labelWidth + 'px' : labelWidth + 'px',
				} as React.CSSProperties
			}
			data-label-left={leftSide}
			data-center-aligned={count < 1}
			data-visible={visible}
			ref={ref}
		>
			<div>
				<Person width={12} />
				<div data-test="segment-label">
					{formatValue({
						value: (count / totalCount) * 100,
						unit: '%',
					})}{' '}
					({count})
				</div>
			</div>
			{rate && (
				<div>
					<DownArrow width={12} />
					<div>{rate}</div>
				</div>
			)}
		</div>
	);
};

const RightLabel = ({ count, rate }: any) => {
	return (
		<div className="funnel__right-label">
			<div>
				<Person width={12} />
				<div>{count}</div>
			</div>
			{rate && (
				<div>
					<DownArrow width={12} />
					<div>{rate}</div>
				</div>
			)}
		</div>
	);
};

const RightColumn = ({ children }: PropsWithChildren) => {
	const { multiSegment, labelColumnWidth } = useContext(FunnelChartContext);
	if (multiSegment) return null;
	return (
		<div
			style={
				{
					'--rightColumnWidth': labelColumnWidth + 'px',
				} as React.CSSProperties
			}
			className="funnel__right-column"
		>
			{children}
		</div>
	);
};

const Label = ({ x, y, count, rate }: any) => {
	const { gridRowHeight } = useContext(FunnelChartContext);
	return (
		<svg className="funnel__label" x={x} y={y + gridRowHeight / 2} width="100%" height={gridRowHeight / 2}>
			<g>
				<Person />
				<text x="20%" y="25%">
					{count}
				</text>
			</g>
			<g>
				<DownArrow y="55%" />
				<text x="20%" y="75%">
					{rate}
				</text>
			</g>
		</svg>
	);
};

Graph.Rows = Rows;
Graph.Bar = Bar;
Graph.Row = Row;
Graph.StageTitle = StageTitle;
Graph.Segment = Segment;
Graph.BarLine = BarLine;
Graph.RightColumn = RightColumn;
Graph.Label = Label;
Graph.RightLabel = RightLabel;

export default Graph;
