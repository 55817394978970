const themeColorCard = {
	states: {
		IDLE: 'idle',
		EDITING: 'editing',
	},
	events: {
		EDIT: 'edit',
		SAVE: 'save',
		RESET: 'reset',
		SAVE_DEFAULT: 'saveDefault',
	},
};

export default themeColorCard;
