import moment from 'moment';
import { Intervals, TimeInterval } from '../types';

export default function getDatePickerLabel(selectedDate: string, interval: TimeInterval) {
	if (!selectedDate) {
		return interval;
	}

	if (interval === Intervals.Month) {
		return moment(selectedDate).format('MMMM yyyy');
	}

	if (interval === Intervals.Quarter) {
		return moment(selectedDate).format('YYYY [Q]Q');
	}

	if (interval === Intervals.Year) {
		return moment(selectedDate).format('YYYY');
	}

	return interval;
}
