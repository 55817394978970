import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import Button from '../../common/Button';
import RadioButton from '../../common/RadioButton';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ActionDropdown from '../Dropdown';
import { Role } from '../roles/model';
import { assignRole, switchRoles, unassignRole } from './actions';
import { getGroupByIdSelector } from './utils';

const GroupRoleHandler = ({ groupId }: any) => {
	const [addNew, setAddNew] = useState(false);
	const [remainingRoles, setRemainingRoles] = useState<Role[]>([]);
	const [openDropdownId, setOpenDropdownId] = useState<string>();
	const { allRoles } = useAppSelector(state => state.admin.roles);
	const group = useAppSelector(getGroupByIdSelector(groupId));
	const editPermissions = usePermissions({
		actions: [RbacActions['Roles/Edit']],
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		setRemainingRoles(allRoles.filter((role: Role) => !group.roles.map((role: Role) => role.id).includes(role.id)));
	}, [allRoles, group]);

	//function handleRoleSelect(actorId, role) {
	//    const actor = actors.find(a => a.actorId === actorId);
	//    const userName = `${actor.firstName} ${actor.lastName}`;
	//    track(MixPanel.Events.AdminSelectRole, { userName, role });
	//    grant(actorId, role);
	//}

	//function handleExploreAccessChange(actor, exploreAccess) {
	//    track(MixPanel.Events.AdminExploreAccessChange, {
	//        'User Name': `${actor.firstName} ${actor.lastName}`,
	//        'Explore Access': exploreAccess ? 'Yes' : 'No'
	//    });
	//    updateActor(
	//        actor.email,
	//        actor.firstName,
	//        actor.lastName,
	//        actor.profilePictureURL,
	//        actor.lightMode,
	//        !exploreAccess,
	//        actor.actorId
	//    );
	//}
	function handleDropdownClick(id: string) {
		if (id === 'tmp-role') {
			track(MixPanel.Events.AdminGroupsClickAddRole, {
				groupId: groupId,
			});
		} else {
			track(MixPanel.Events.AdminGroupsClickRole, {
				roleId: id,
				groupId: groupId,
			});
		}
		if (openDropdownId === id) {
			setOpenDropdownId(undefined);
		} else {
			setOpenDropdownId(id);
		}
	}

	return (
		<div className="role-handler">
			{group.roles.map((role: Role) => (
				<RoleDropdown
					key={role.id}
					selected={role.name}
					disabled={!editPermissions}
					onClearSelection={() => {
						dispatch(unassignRole(role.id, group.groupId));
					}}
					onClick={() => {
						handleDropdownClick(role.id);
					}}
					isOpen={openDropdownId === role.id}
					onMouseLeaveDropdown={() => setOpenDropdownId(undefined)}
				>
					<RoleDropdown.List
						options={[role, ...remainingRoles]}
						onClick={(newRole: Role) => {
							dispatch(switchRoles(role.id, newRole.id, group.groupId));
						}}
					/>
				</RoleDropdown>
			))}
			{addNew && remainingRoles.length >= 1 && (
				<RoleDropdown
					key={'tmp-role'}
					selected="Add Role"
					disabled={!editPermissions}
					onClearSelection={() => {
						setAddNew(false);
					}}
					onClick={() => handleDropdownClick('tmp-role')}
					onMouseLeaveDropdown={() => setOpenDropdownId(undefined)}
					isOpen={openDropdownId === `tmp-role`}
				>
					<RoleDropdown.List
						options={[...remainingRoles]}
						onClick={(role: Role) => {
							dispatch(assignRole(role.id, group.groupId));
							setAddNew(false);
						}}
					/>
				</RoleDropdown>
			)}
			{!addNew && editPermissions && (
				<Button
					componentType={ButtonTypes.type.SECONDARY}
					disabled={!editPermissions}
					onClick={() => {
						setAddNew(true);
						setOpenDropdownId('tmp-role');
					}}
				>
					<MdAdd />
				</Button>
			)}
		</div>
	);
};

const RoleDropdown = ({
	selected,
	onClearSelection,
	disabled,
	onClick,
	isOpen,
	placeholder = 'Role',
	children,
	onMouseLeaveDropdown,
}: any) => {
	return (
		<ActionDropdown
			onClick={onClick}
			onClearSelection={onClearSelection}
			disabled={disabled}
			placeholder={placeholder}
			buttonLarge={true}
			allowCancel={true}
			isOpen={isOpen}
			label={selected}
			onMouseLeaveDropdown={onMouseLeaveDropdown}
		>
			{children}
		</ActionDropdown>
	);
};

const RoleDropdownList = ({ options, onClick }: any) => {
	return (
		<div className="filter-dropdown__list">
			<ul>
				{options
					.sort((a: any, b: any) => a.name.localeCompare(b.name))
					.map((role: Role) => (
						<li key={role.id}>
							<RadioButton
								onClick={() => {
									onClick(role);
								}}
							>
								{role.name}
							</RadioButton>
						</li>
					))}
			</ul>
		</div>
	);
};

RoleDropdown.List = RoleDropdownList;

export default GroupRoleHandler;
