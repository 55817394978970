import AvatarIcon from '../icons/Avatar';
import cn from '../lib/cn';
import { GradientLayer } from './GradientButton';

interface AiAvatarProps {
	classNames?: string;
	isGray?: boolean;
	size?: number;
	[key: string]: any;
}

export default function AiAvatar({ classNames = '', isGray, size = 40, ...rest }: AiAvatarProps) {
	return (
		<div
			className={cn('relative rounded-full overflow-hidden aspect-square', classNames)}
			style={{
				width: size,
				height: size,
			}}
		>
			<AvatarIcon
				className={cn('relative z-10', isGray ? 'text-ui-50 group-hover:text-ui-100' : 'text-dark-ui-100')}
				size={size}
				{...rest}
			></AvatarIcon>
			{!isGray && <GradientLayer paused={false} />}
		</div>
	);
}
