import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';

import cn from '../../lib/cn';

interface AiInputProps {
	onBlur?: () => void;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onFocus?: () => void;
	onKeyDown?: (event: KeyboardEvent) => void;
	onSubmit?: (e: KeyboardEvent<Element>) => void;
	autoFocus?: boolean;
	placeholder?: string;
	readOnly?: boolean;
	rows?: number;
	value: string;
}

const AiInput = ({
	onBlur = () => {},
	onChange,
	onFocus = () => {},
	onKeyDown = () => {},
	onSubmit = () => {},
	autoFocus,
	placeholder,
	readOnly,
	rows = 4,
	value,
}: AiInputProps): JSX.Element => {
	const ref = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (autoFocus && ref.current) {
			ref.current.focus();
		}
	}, [autoFocus]);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.height = 'auto';
			const scrollHeight = ref.current.scrollHeight;
			ref.current.style.height = `${Math.min(scrollHeight, rows * 24)}px`;
		}
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		onChange?.(e);
	};

	const handleKeyDown = (e: KeyboardEvent<Element>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			if (value.trim() !== '') {
				onSubmit?.(e);
			}
		} else {
			onKeyDown?.(e);
		}
	};

	return (
		<div
			className={cn(
				'pl-[20px] pr-[48px] py-[12px] rounded-[27px] overflow-hidden w-full m-0 ring-1 ring-ui-100',
				'bg-transparent   hide-scrollbar'
			)}
		>
			<textarea
				autoFocus={autoFocus}
				ref={ref}
				className={
					cn('caret-graph-1 text-[16px] w-avail hide-scrollbar block', '')
					// 'text-area text-[16px]',
					// `text-area--${componentType}`,
				}
				onBlur={onBlur}
				onChange={handleChange}
				onFocus={onFocus}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
				readOnly={readOnly}
				rows={1}
				value={value}
				style={
					{
						// border: '1px solid var(--color-ui-100)',
						// outline: 'none',
					}
				}
			/>
		</div>
	);
};

AiInput.displayName = 'TextArea';

export default AiInput;
