import { useAppSelector } from '../../store/hooks';
import Chat from './Chat';
import Conversations from './Conversations';

export default function Views() {
	const { view } = useAppSelector(state => state.ai.modal);

	if (view === 'conversations') {
		return <Conversations />;
	}

	return <Chat />;
}
