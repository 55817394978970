import { createSlice } from '@reduxjs/toolkit';
import ActionTypes from '../../constants/ActionTypes';
import MixPanel from '../../constants/MixPanel';
import { track } from '../../lib/segment';

interface ModalState {
	aiModalOpen: boolean;
	view: 'chat' | 'conversations';
}

const initialState: ModalState = {
	aiModalOpen: false,
	view: 'chat',
};

const modalSlice = createSlice({
	name: 'ai/modal',
	initialState,
	reducers: {
		setAiModalOpen(state, action) {
			if (action.payload) {
				track(MixPanel.Events.AIModalClickCloseModal);
			}
			state.aiModalOpen = action.payload;
		},
		setView(state, action) {
			state.view = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(ActionTypes.ChangeLocation, (state, action) => {
			if (window.location.pathname.includes('/ai')) {
				state.aiModalOpen = false;
			}
		});
	},
});

export const { setAiModalOpen, setView } = modalSlice.actions;
export default modalSlice.reducer;
