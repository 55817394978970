import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import HeaderWrapper from '../../../../common/HeaderWrapper';
import Stack from '../../../../common/Stack';
import WithPermissions from '../../../../common/WithPermissions';
import RbacActions from '../../../../constants/RbacActions';
import TopLeftAction from '../../../../layout/TopLeftAction';

interface HeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const Header = (props: HeaderProps) => {
	const {
		location: { state },
	} = props;
	return (
		<WithPermissions actions={[RbacActions['Campaign/Edit']]}>
			<Stack flexDirection="row" alignItems="center" gap="2rem">
				<TopLeftAction scrollPos={state?.scrollPos} />
				<HeaderWrapper>
					<h3 className="font-title">Select Self ID campaign template</h3>
				</HeaderWrapper>
			</Stack>
		</WithPermissions>
	);
};

export default withRouter(Header);
