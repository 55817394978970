import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Constants from '../../constants/Constants';
import getFormattedValue from '../../lib/getFormattedValue';
import ChartTypes from '../../constants/ChartTypes';
import { generateDefaultTemplate, generateFunnelTemplate } from '../../common/GenericTable/transformations';
import { uniq } from 'lodash';

function getFields(columnProps) {
	const fields = ['Segment'];
	columnProps.forEach(columnProp => {
		fields.push(columnProp.header);
	});
	return fields;
}

function getRowData(analysisType, columnProps, dataItem, currentIndex) {
	const seriesItem = dataItem.series[currentIndex];
	const primaryField = columnProps[0].source;
	if (!seriesItem || seriesItem[primaryField] === Constants.InvalidValue) {
		return columnProps.map(() => '---');
	}
	const rowData = [getFormattedValue(analysisType, seriesItem[primaryField])];
	columnProps.slice(1).forEach(columnProp => {
		rowData.push(seriesItem[columnProp.source]);
	});
	return rowData;
}

export default function exportAsCSV({
	lifecycle,
	analysisType,
	columnProps,
	data,
	currentIndex,
	period,
	benchmark,
	chartType,
	subAnalysisFields,
	subAnalysisMapping,
	metricOverlay,
}) {
	let fields = [];
	let dataArray = [];
	let csv;

	if (chartType === ChartTypes.ArithmeticTable || chartType === ChartTypes.Stats) {
		const tableData = generateDefaultTemplate(data, [], null);
		fields.push('SubAnalysis');
		fields.push(...tableData.rightTable.head.map(column => column.label));

		tableData.leftTable.body.forEach((row, index) => {
			let rowData = [];
			rowData.push(row.columns[0].label);
			rowData.push(...tableData.rightTable.body[index].columns.map(column => column.label));
			dataArray.push(rowData);
		});

		csv = Papa.unparse({
			fields,
			data: dataArray,
		});
	} else if (chartType === ChartTypes.Funnel) {
		const tableData = generateFunnelTemplate(data, subAnalysisFields, subAnalysisMapping, null, []);
		fields.push('Stages');
		tableData.rightTable.head.forEach(column => {
			fields.push(column.label);
			fields.push('');
		});
		let subHeadRow = [''];
		tableData.rightTable.subHead.forEach(column => {
			subHeadRow.push(column.label);
		});
		dataArray.push(subHeadRow);

		tableData.leftTable.body.forEach((row, index) => {
			let rowData = [];
			rowData.push(row.columns[0].label);
			rowData.push(...tableData.rightTable.body[index].columns.map(c => c.label));

			dataArray.push(rowData);
		});

		csv = Papa.unparse({
			fields,
			data: dataArray,
		});
	} else {
		if (metricOverlay?.data?.length > 0) {
			const mainFields = getFields(columnProps);
			const overlayFields = getFields(metricOverlay.columnProps).slice(1);

			fields = uniq([...mainFields, ...overlayFields]);

			const mainDataArray = data.map(d => [
				d.segment.text,
				...getRowData(analysisType, columnProps, d, currentIndex),
			]);

			const overlayColumnStartIndex = fields.indexOf(metricOverlay.columnProps[0].header);

			const overlayDataArray = metricOverlay.data
				.map(d => [
					d.segment.text,
					...getRowData(metricOverlay.analysisType, metricOverlay.columnProps, d, currentIndex),
				])
				.map(row => {
					const adjustedRow = [];

					row.forEach((cell, i) => {
						if (i === 0) {
							adjustedRow[i] = cell;
						} else {
							adjustedRow[i + overlayColumnStartIndex - 1] = cell;
						}
					});

					return adjustedRow;
				});

			dataArray = mainDataArray.concat(overlayDataArray);

			csv = Papa.unparse({
				data: dataArray,
				fields,
			});
		} else {
			fields = getFields(columnProps);

			dataArray = data.map(d => [d.segment.text, ...getRowData(analysisType, columnProps, d, currentIndex)]);
			if (benchmark) {
				let data = [fields];
				data = data.concat(dataArray);
				data.push([]);
				data.push(['Benchmark']);
				data.push(['Segment', 'Representation']);
				const benchmarkData = benchmark.data.map(d => {
					return [d.label, d.value + '%'];
				});
				data = data.concat(benchmarkData);
				csv = Papa.unparse(data);
			} else {
				csv = Papa.unparse({
					data: dataArray,
					fields,
				});
			}
		}
	}

	let filename = `Dandi ${lifecycle} ${period}.csv`;
	filename = filename.replace(/ /g, '_');

	const blob = new Blob([csv]);
	saveAs(blob, filename);
}
