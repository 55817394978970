import { useState } from 'react';

import Dropdown from '../common/Dropdown';

interface DropdownStateWrapperProps {
	type?: string;
	options: { value: string; label: string }[];
	placeholder?: string;
	height?: number;
	marginLeft?: number;
	disabled?: boolean;
	usePlaceholder?: boolean;
	scale?: number;
	perfectScrollbar?: boolean;
	allowCancel?: boolean;
	selectedStyle?: string;
	enableSearch?: boolean;
	buttonType?: string;
	textFieldType?: string;
}

const DropdownStateWrapper = ({
	options,
	placeholder,
	height,
	marginLeft,
	disabled,
	usePlaceholder,
	scale,
	perfectScrollbar,
	allowCancel,
	type,
	selectedStyle,
	enableSearch,
	buttonType,
	textFieldType,
}: DropdownStateWrapperProps) => {
	const [selectedOption, setSelectedOption] = useState<any>(options[0]);
	const [selected, setSelected] = useState(false);

	function onClick(value: string) {
		setSelectedOption(options.find(o => o.value === value));

		if (!selected) {
			setSelected(true);
		}
	}

	return (
		<Dropdown
			buttonType={buttonType}
			textFieldType={textFieldType}
			type={type}
			selectedStyle={selectedStyle ? selectedStyle : 'default'}
			onClick={onClick}
			selected={selected}
			options={options}
			placeholder={placeholder}
			height={height}
			marginLeft={marginLeft}
			disabled={disabled}
			usePlaceholder={usePlaceholder}
			scale={scale}
			perfectScrollbar={perfectScrollbar}
			allowCancel={allowCancel}
			selectedOption={selectedOption}
			enableSearch={enableSearch}
		></Dropdown>
	);
};

export default DropdownStateWrapper;
