import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../store/store';
import { Template } from './models';
import errorHandler from '../lib/errorHandler';
import Urls from '../constants/Urls';
import axios from 'axios';
import { getAllowedTemplates, getDynamicPermissionsByName, getTemplateFilter } from './utils';
import ActionTypes from '../constants/ActionTypes';
import { DynamicPermission } from '../admin/permissions/model';

function combineVariationsAndTemplates(items: any[], templates: Template[]) {
	if (items.length !== templates.length) {
		throw new Error('Mismatched variations array and templates array');
	}

	const newArray: any[] = [];
	items.forEach((item: any, index: number) => {
		let { variations } = item;
		variations = variations.map((v: any) => v.strings);

		const mainNames = new Set();
		const filterNames = new Set();
		const breakdownNames = new Set();

		variations.forEach((variation: any) => {
			if (variation.length === 5) {
				mainNames.add(variation[0]);
				mainNames.add(variation[1]);
				mainNames.add(variation[2]);
				filterNames.add(variation[3]);
				breakdownNames.add(variation[3]);
				breakdownNames.add(variation[4]);
			} else {
				console.log('Bad variation', variation);
			}
		});

		mainNames.delete('');
		filterNames.delete('');
		breakdownNames.delete('');

		newArray.push({
			...templates[index],
			variations,
			mainNames: Array.from(mainNames),
			filterNames: Array.from(filterNames),
			breakdownNames: Array.from(breakdownNames),
		});
	});

	return newArray;
}

export function getTemplates() {
	return async (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetTemplatesPending,
		});
		return axios
			.post(`${Urls.DataStudioApi}templates/configuration/enterprise`, {
				enterpriseId,
			})
			.then(async templatesResponse => {
				return axios
					.post(`${Urls.DataStudioApi}variation/ids`, {
						values: templatesResponse.data.map((t: Template) => ({
							id: t.templateConfigurationId,
						})),
					})
					.then(variationsResponse => {
						const combineVariationsAndTemplatesArray = combineVariationsAndTemplates(
							variationsResponse.data,
							templatesResponse.data
						);
						dispatch({
							type: ActionTypes.GetTemplatesFulfilled,
							payload: combineVariationsAndTemplatesArray,
						});
						dispatch(setDynamicTemplates());
					});
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetTemplatesRejected,
				});
			});
	};
}

export const setDynamicTemplates = createAsyncThunk<any, void, AsyncThunkConfig>(
	'templates/setDynamicTemplates',
	async (_, { getState }) => {
		const { templates } = getState().templates;
		const { dynamicPermissions } = getState().auth as {
			dynamicPermissions: DynamicPermission[];
		};

		const allowedTemplates = getAllowedTemplates(dynamicPermissions);

		const filteredTemplates = templates.filter(
			(t: Template) =>
				allowedTemplates.includes((t.analysisGroup !== '' && t?.analysisGroup) || t.analysisName) ||
				allowedTemplates.includes('*')
		);

		return filteredTemplates.map((t: Template) => {
			const currentPermissions = getDynamicPermissionsByName(
				(t.analysisGroup !== '' && t?.analysisGroup) || t!.analysisName,
				dynamicPermissions
			);

			const filterTemplate = getTemplateFilter(currentPermissions);

			const mainNames = filterTemplate.mainVariableFields;
			const filterNames = filterTemplate.filterVariableFields;
			const breakdownNames = filterTemplate.breakdownVariableFields;

			return {
				...t,
				mainNames,
				filterNames,
				breakdownNames,
			};
		});
	}
);
