import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import TopLeftAction from '../layout/TopLeftAction';
import Filters from './filter/Filters';

function ExploreHeader(props) {
	const {
		location: { search, state },
	} = props;

	return (
		<WithPermissions actions={[RbacActions['Explore/Generate'], RbacActions['Dashboards/Explore']]}>
			<div className="explore-header">
				<div className="explore__filters">
					{search && <TopLeftAction scrollPos={state?.scrollPos} />}
					<Filters show value={search} />
				</div>
			</div>
		</WithPermissions>
	);
}

ExploreHeader.propTypes = {
	back: PropTypes.func,
	setScrollPosition: PropTypes.func,
	location: PropTypes.object,
};

const dispatchProps = {};

export default connect(null, dispatchProps)(ExploreHeader);
