import Tooltip from 'antd/lib/tooltip';
import { ReactNode } from 'react';

import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import Trash from '../../icons/Trash';
import cn from '../../lib/cn';

interface ChartEmptyReportProps {
	title: string;
	text?: string;
	onDelete: any;
	html?: ReactNode;
}

const ChartEmptyReport = ({ title, text, onDelete, html }: ChartEmptyReportProps) => (
	<div className={cn('editor__chart__empty', 'h-full w-full ')}>
		<div className="editor__chart__header editor__chart__header--empty">
			<div className="editor__chart__header__title">
				<span>{title}</span>
			</div>
			<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
				<div className="editor__chart__header__actions z-20">
					<Tooltip title="Delete" placement="top">
						<Trash width={24} className="icon" onClick={onDelete} />
					</Tooltip>
				</div>
			</WithPermissions>
		</div>
		<div className="editor__chart__main editor__chart__main--empty">
			{html ? (
				<div className="editor__chart__text">{html}</div>
			) : (
				<div className="editor__chart__text">{text}</div>
			)}
		</div>
	</div>
);

export default ChartEmptyReport;
