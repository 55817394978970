const idleState = (state, event) => {
	if (event.readOnly) return { ...state };

	switch (event.type) {
		case 'ACTIVATE': {
			return {
				...state,
				status: 'activated',
			};
		}
		default: {
			return { ...state };
		}
	}
};

const activatedState = (state, event) => {
	switch (event.type) {
		case 'EXIT': {
			return {
				...state,
				status: 'idle',
			};
		}
		case 'UPDATE_OPTION': {
			return {
				...state,
				status: 'updating',
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

const updatingState = (state, event) => {
	switch (event.type) {
		case 'EXIT': {
			return {
				...state,
				status: 'activated',
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

export const textChartMachine = (state, event) => {
	switch (state.status) {
		case 'idle': {
			return idleState(state, event);
		}
		case 'activated': {
			return activatedState(state, event);
		}
		case 'updating': {
			return updatingState(state, event);
		}
		default: {
			return { ...state };
		}
	}
};
