import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as uuid from 'uuid';

import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import Urls from '../../constants/Urls';
import { setConversationId } from '../../dashboard/aiInsights/reducer';
import formatValue from '../../lib/formatValue';
import { AsyncThunkConfig } from '../../store/store';
import { getDateByInterval, getHeaderRow } from './utils';

function getAiInsightsContext({ chart, isSubtable }: { chart: any; isSubtable: boolean }) {
	const { data, analysisType, columnProps, interval, mainSegments, subtableChartType, attributeName, date, summary } =
		chart;

	const context: any[] = [];

	switch (analysisType) {
		case analysisTypes.Index: {
			if (subtableChartType === ChartTypes.Matrix) {
				const header = getHeaderRow(['Date', attributeName], mainSegments as any[], columnProps as any);

				context.push(header);

				data.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(date, interval));
						row.push(d.label);
						row.push(segment);

						columnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							row.push(
								formatValue({
									unit,
									value,
								})
							);
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(date, 'Day'));
					row.push('Average');
					row.push(segment);

					columnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const avgValue = summary[segmentKey][source];

						row.push(
							formatValue({
								unit,
								value: avgValue,
							})
						);
					});

					context.push(row);
				});
			} else {
				const header = getHeaderRow(['Time period', 'Interval'], mainSegments as any[], columnProps as any, [
					data[0]?.label,
				]);
				context.push(header);

				data.slice(1, data.length).forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									unit: c.unit,
									value: s[c.source],
								})
							);
						});

						row.push('1x');

						context.push(row);
					});
				});
			}

			return context;
		}
		case analysisTypes.Arithmetic: {
			const header = getHeaderRow(
				['Date', 'Analysises'],
				[],
				[],
				data.map((d: any) => d.text)
			);

			context.push(header);

			const subAnalysises = data[0]?.pointInTime.subAnalyses.map((s: any) => s.label);

			subAnalysises?.forEach((subAnalysis: string) => {
				const row = [];
				const date = getDateByInterval(data[0]?.pointInTime.date, 'Day');

				row.push(date);
				row.push(subAnalysis);

				data.forEach((d: any) => {
					const currentSubAnalysis = d.pointInTime.subAnalyses.find((s: any) => s.label === subAnalysis);
					row.push(
						formatValue({
							value: currentSubAnalysis.value,
							unit: currentSubAnalysis.unit,
						})
					);
				});

				context.push(row);
			});

			return context;
		}
		case analysisTypes.Percentage: {
			if (isSubtable) {
				// TODO: handle subtable
				const header = getHeaderRow([''], [], [], []);
			} else {
				const header = getHeaderRow(['Time period', 'Interval'], mainSegments as any[], columnProps as any);
				context.push(header);

				data.forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: s[c.source],
									unit: c.unit,
								})
							);
						});

						context.push(row);
					});
				});
			}

			return context;
		}
		case analysisTypes.LinearRegression: {
			const header = getHeaderRow(['Time period', 'Interval'], mainSegments as any[], columnProps as any[], [
				data[0]?.label,
			]);
			context.push(header);

			data.slice(1, data.length).forEach((d: any) => {
				d.series.forEach((s: any) => {
					const row = [];
					row.push(getDateByInterval(s.date, interval as string));
					row.push(interval);
					row.push(d.text);

					columnProps?.forEach((c: any) => {
						row.push(
							formatValue({
								value: s[c.source],
								unit: c.unit,
							})
						);
					});

					row.push('0%');

					context.push(row);
				});
			});

			return context;
		}
		case analysisTypes['Linear Regression']: {
			return;
		}
		default:
			return;
	}
}

export const generateAiInsightsInExplore = createAsyncThunk<any, void, AsyncThunkConfig>(
	'aiInsights/generate-in-explore',
	async (_, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { uid } = getState().auth.userInfo;
		const chart = getState().explore.chart;
		const { breakdownSegment, employeeAttribute } = getState().explore.filter as any;
		const subtableChart = getState().explore.subtable;

		if (!chart) {
			throw new Error('No explore data generated.');
		}

		const { interval, analysisDisplayName, mainSegments, analysisType } = chart;

		const title = `${analysisDisplayName} by (${mainSegments?.map((s: any) => s.name).join(', ')})`;

		const isSubtable = (breakdownSegment && breakdownSegment.values.length > 1) || employeeAttribute;

		const aiContext = getAiInsightsContext({
			chart: isSubtable ? subtableChart : chart,
			isSubtable,
		});

		const conversation_id = uuid.v4();
		dispatch(setConversationId(conversation_id));

		const response: any = await axios.post(`${Urls.AiInsightsApi}insights/invoke`, {
			input: {
				model: 'gemini_pro_15',
				question: '',
				context: [
					{
						title,
						interval,
						analysis_type: analysisType,
						data: JSON.stringify(aiContext),
						user_context: '',
					},
				],
			},
			config: {
				configurable: {
					conversation_id,
					enterprise_id: enterpriseId,
					user_id: uid,
				},
			},
		});

		return {
			insights: response?.data?.output,
		};
	}
);
