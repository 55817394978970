import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import { Animate } from 'react-move';
import Constants from '../constants/Constants';
import getInterpolator from '../lib/getInterpolator';

export interface DropdownMenuWrapperProps extends PropsWithChildren {
	marginLeft?: number;
	maxHeight?: number;
	open?: boolean;
	onClose?: () => void;
}

const DropdownMenuWrapper = ({ marginLeft, maxHeight, open, onClose, children }: DropdownMenuWrapperProps) => {
	const ref = useRef(null);
	return (
		<Animate
			show={open}
			start={() => ({
				opacity: 0.01,
			})}
			enter={() => ({
				opacity: [1],
				timing: {
					duration: Constants.AnimationDuration,
					ease: Constants.EasingFn,
				},
			})}
			leave={{
				opacity: [0.01],
				timing: {
					duration: Constants.AnimationDuration,
					ease: Constants.EasingFn,
				},
			}}
			interpolation={getInterpolator}
		>
			{({ opacity }) => {
				return (
					<div
						className="menu-outer"
						style={{
							marginLeft,
							opacity,
						}}
					>
						<div
							className="menu"
							style={{
								maxHeight,
								overflowY: opacity == 1 ? 'auto' : 'hidden',
								opacity,
							}}
							ref={ref}
						>
							{React.cloneElement(React.Children.only(children) as ReactElement, {
								onClose,
							})}
						</div>
					</div>
				);
			}}
		</Animate>
	);
};

export default DropdownMenuWrapper;
