import { timeMonth } from 'd3-time';
import AnalysisTypes from '../../constants/AnalysisTypes';

export function getMonthRange(
	startDate: Date,
	endDate: Date,
	minimumMonths: number = 4
	//endMonthPadding: number = 2
) {
	const start = new Date(startDate);
	start.setDate(1);
	const end = new Date(endDate);
	end.setDate(1);

	let months = Math.max(timeMonth.count(start, end) + 2, minimumMonths);

	const range = timeMonth.range(start, timeMonth.offset(start, months));

	return [startDate, ...range.splice(1, range.length - 1)];
}

export function mapDataProp(valueType: string): 'percentage' | 'gap' {
	switch (valueType) {
		case 'Percentage':
			return 'percentage';
		case 'Linear Regression':
			return 'gap';
		default:
			return 'percentage';
	}
}

export function mapAnalysisType(valueType: string): AnalysisTypes {
	switch (valueType) {
		case 'Percentage':
			return AnalysisTypes.Percentage;
		case 'Linear Regression':
			return AnalysisTypes.LinearRegression;
		default:
			return AnalysisTypes.Percentage;
	}
}
