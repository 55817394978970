import React from 'react';
import { TabName } from './types';
import { showAddEventTab } from '../actions';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface TabsProps {
	tabs: TabName[];
}

const Tabs = ({ tabs }: TabsProps) => {
	const dispatch = useAppDispatch();
	const activeTab = useAppSelector(state => state.timeline.addEventModalTab);
	return (
		<div className="tabs">
			{tabs.map(tab => (
				<Button
					key={tab}
					componentType={ButtonTypes.type.TERTIARY}
					activated={activeTab === tab}
					onClick={() => dispatch(showAddEventTab(tab))}
				>
					{tab}
				</Button>
			))}
		</div>
	);
};

export default Tabs;
