import React from 'react';

const NoActiveGoals = () => {
	return (
		<div className="timeline__no-events" style={{ display: 'flex', flex: 1 }}>
			<div>{`Your goals will appear here once they have reached their deadlines.`}</div>
		</div>
	);
};

export default NoActiveGoals;
