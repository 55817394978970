import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getEnterprises } from './actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function Enterprises() {
	const dispatch = useAppDispatch();
	const { data = [] } = useAppSelector(state => state.datastudio.enterprises);

	useEffect(() => {
		dispatch(getEnterprises());
	}, []);

	return (
		<div className="ds-container">
			<table className="ds-table">
				<tbody>
					{data.map((c, i) => (
						<tr key={i}>
							<td>
								<NavLink className="link--bright" to={`/datastudio/enterprises/${c.enterpriseId}`}>
									{c.enterpriseName}
								</NavLink>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Enterprises;
