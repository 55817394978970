import * as React from 'react';

function SvgComponent(props: any) {
	return (
		<svg
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M15.5 17.1667V8.83334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.5 17.1667V3.83334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.5 17.1667V12.1667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}

export default SvgComponent;
