import { useEffect, useRef } from 'react';

import Dropdown from '../../../../common/DropdownV2';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
	alignDropdownRight?: boolean;
	allowCancel?: boolean;
	buttonLarge?: boolean;
	children?: React.ReactNode;
	disabled?: boolean;
	isOpen?: boolean;
	label?: string;
	limitHeight?: number;
	onClearSelection?: () => void;
	onClick: (option: any) => void;
	onMouseLeaveDropdown?: () => void;
	placeholder?: string;
	setIsOpen: (isOpen: boolean) => void;
}

export default function AnalysisDropdown({
	alignDropdownRight,
	allowCancel,
	buttonLarge,
	children,
	disabled,
	isOpen,
	label,
	limitHeight,
	onClearSelection,
	onClick,
	onMouseLeaveDropdown,
	placeholder,
	setIsOpen,
	...rest
}: DropdownProps) {
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', handleKeyDown);
			window.addEventListener('mousedown', handleClickOutside);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				window.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [isOpen]);

	function handleKeyDown(e: any) {
		if (e.keyCode === 27) {
			setIsOpen(false);
		}
	}

	function handleClickOutside(e: any) {
		if (isOpen) {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				e.stopPropagation();
				setIsOpen(false);
			}
		}
	}

	return (
		<div className="filter-dropdown" {...rest}>
			<Dropdown
				onClick={onClick}
				isOpen={isOpen}
				disabled={disabled}
				limitHeight={limitHeight && '36vh'}
				buttonLarge={true}
				label={label}
				placeholder={placeholder}
				alignDropdownRight={alignDropdownRight}
				onMouseLeaveDropdown={onMouseLeaveDropdown}
				onClearSelection={onClearSelection}
			>
				{children}
			</Dropdown>
		</div>
	);
}
