import Info from '../../icons/Info';

interface InfoTextProps {}

const InfoText = (props: InfoTextProps) => {
	return (
		<div className="textbar">
			<div className="textbar__title">
				<Info width={16} style={{ marginRight: 8 }} />
				<span>Roles</span>
			</div>
			<div>
				<p>
					A role is a set of permissions that can be assigned to a user to perform operation(s) on the
					platform. For more information about roles and best practices in setting up your organization, visit
					our knowledge base.
				</p>
			</div>
			<div className="textbar__title">
				<Info width={16} style={{ marginRight: 8 }} />
				<span>Permissions</span>
			</div>
			<div>
				A permission provides access to a feature or analysis within the platform. Feature Permissions control
				access to specific product features and their actions. Analysis Permissions control access to the
				reporting - including the analysis type and data fields.
			</div>
		</div>
	);
};

export default InfoText;
