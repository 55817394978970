import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';

import WithPermissions from '../../common/WithPermissions';
import mixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { track } from '../../lib/segment';
import { identitySyncStatus } from '../identity/reducer';
import { AdminHeaderContext } from './AdminHeader';

const Sync = () => {
	const { status } = useAppSelector(state => state.admin.identity);
	const { allGroups } = useAppSelector(state => state.admin.groups);
	const dispatch = useAppDispatch();
	const { sync } = React.useContext(AdminHeaderContext);

	const handleSync = () => {
		track(mixPanel.Events.AdminGroupsClickSyncUsersGroups);
		sync();
	};

	useEffect(() => {
		if (status === 'STARTED' || status === 'PENDING') {
			const timer = setInterval(() => dispatch(identitySyncStatus()), 2500);
			return () => clearInterval(timer);
		}
	}, [status]);

	if (!allGroups || allGroups.length === 0) {
		return null;
	}

	return (
		<WithPermissions actions={[RbacActions['Users/Edit']]}>
			<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleSync}>
				{status !== 'STARTED' && status !== 'PENDING' ? 'Sync users & groups' : 'Syncing...'}
			</Button>
		</WithPermissions>
	);
};

export default Sync;
