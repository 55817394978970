import { KeyValuePair } from '../types';

export const analysisTypeOptions = [
	'fields_meta_hris',
	'fields_meta_ats',
	'Missing Data',
	'Percentage',
	'Index',
	'Linear Regression',
	'Arithmetic',
];

export const engineOptions = [
	'enterprise-meta-generator-batch.jar',
	'linear-regression-engine-batch-json.jar',
	'index-segmentation-engine-batch-json.jar',
	'percentage-segmentation-engine-batch-json.jar',
	'missing-data-engine-batch.jar',
	'arithmetic-segmentation-engine-batch-json.jar',
	'dandi-pita-engine.jar',
];

export const engineMap: KeyValuePair = {
	fields_meta_hris: 'enterprise-meta-generator-batch.jar',
	fields_meta_ats: 'enterprise-meta-generator-batch.jar',
	'Missing Data': 'missing-data-engine-batch.jar',
	Percentage: 'percentage-segmentation-engine-batch-json.jar',
	Index: 'index-segmentation-engine-batch-json.jar',
	'Linear Regression': 'linear-regression-engine-batch-json.jar',
	Arithmetic: 'arithmetic-segmentation-engine-batch-json.jar',
};

export const bucketOptions = ['dandi-dev-analytics-templates', 'dandi-prod-analytics-templates'];

export const datasourceOptions = ['hris', 'ats'];

export const engineNameOptions = ['Percentage', 'Index', 'Linear Regression', 'Arithmetic'];

export const generateTypeOptions = ['ALL', 'EXACT'];

export const exclusionTypeOptions = ['LIMIT_MAIN', 'LIMIT_CONTROL'];

export const mainColumnOptions = ['total', 'included', 'excluded'];
