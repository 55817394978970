import { parseInt } from 'lodash';
import * as uuid from 'uuid';
import { LayoutRow } from './DashboardLayout';

const DEFAULT_ROW_HEIGHT = 500;

function getConfig(config: { name: string; value: string }[], name: string) {
	return config.find((c: any) => c.name === name)?.value;
}

export function getGlobalLayoutState(reports: any[], reportsData: any, layoutVersion2: boolean): LayoutRow[] {
	if (layoutVersion2) {
		const byRow: LayoutRow[] = [];

		reports.forEach((report: any) => {
			const rowIndex = getConfig(report.configuration, 'rowIndex');
			const columnIndex = getConfig(report.configuration, 'columnIndex');
			const rowSize = getConfig(report.configuration, 'rowHeightPx');
			const columnWidthPerc = getConfig(report.configuration, 'columnWidthPerc');

			const isHidden = reportsData[report?.reportId]?.generateNotAllowed;

			if (!rowIndex || !rowSize || !columnIndex) {
				return;
			}

			const rowIndexInt = parseInt(rowIndex);
			const columnIndexInt = parseInt(columnIndex);
			const rowSizeInt = parseInt(rowSize);
			const columnWidthPercInt = columnWidthPerc ? parseInt(columnWidthPerc) : 100;

			if (byRow.length <= rowIndexInt) {
				byRow.length = rowIndexInt + 1;
			}

			if (!byRow[rowIndexInt]) {
				byRow[rowIndexInt] = {
					rowId: `row-${uuid.v4()}`,
					rowSize: rowSizeInt,
					columns: [],
				};
			} else {
				// Use the largest rowSize for all columns of that row
				if (byRow[rowIndexInt].rowSize < rowSizeInt) {
					byRow[rowIndexInt].rowSize = rowSizeInt;
				}
			}

			const row = byRow[rowIndexInt];

			if (row.columns.length <= columnIndexInt) {
				row.columns.length = columnIndexInt + 1;
			}

			row.columns[columnIndexInt] = {
				id: report.reportId,
				columnWidthPerc: columnWidthPercInt,
				isHidden,
			};
		});

		const rowsWithoutEmptyColumns = byRow.map((row: LayoutRow) => {
			return {
				...row,
				columns: row.columns.filter((col: any) => !!col),
			};
		});

		const filteredRows = rowsWithoutEmptyColumns.filter((row: LayoutRow) => row.columns.length > 0);

		return filteredRows.filter((row: LayoutRow) => !!row);
	} else {
		const byRow: LayoutRow[] = [];

		reports.forEach((report: any) => {
			const rowIndex = getConfig(report.configuration, 'row');
			const columnIndex = getConfig(report.configuration, 'column');
			const length = getConfig(report.configuration, 'length');

			const isHidden = reportsData[report?.reportId]?.generateNotAllowed;

			if (!rowIndex || !columnIndex || !length) {
				return;
			}

			// Convert to 1-based
			const rowIndexInt = parseInt(rowIndex) - 1;
			const columnIndexInt = parseInt(columnIndex) - 1;

			const lengthInt = parseInt(length);

			if (byRow.length <= rowIndexInt) {
				byRow.length = rowIndexInt + 1;
			}

			if (!byRow[rowIndexInt]) {
				byRow[rowIndexInt] = {
					rowId: `row-${uuid.v4()}`,
					rowSize: DEFAULT_ROW_HEIGHT,
					columns: [],
				};
			}
			const row = {
				...byRow[rowIndexInt],
			};

			if (row.columns.length <= columnIndexInt) {
				row.columns.length = columnIndexInt + 1;
			}

			row.columns[columnIndexInt] = {
				id: report.reportId,
				columnWidthPerc: lengthInt * 2,
				isHidden,
			};
		});

		const rowsWithoutEmptyColumns = byRow.map((row: LayoutRow) => {
			return {
				...row,
				columns: row.columns.filter((col: any) => !!col),
			};
		});

		const filteredRows = rowsWithoutEmptyColumns.filter((row: LayoutRow) => row.columns.length > 0);

		return filteredRows.filter((row: LayoutRow) => !!row);
	}
}

export function globalLayoutIsEqual(prevLayout: LayoutRow[], newLayout: LayoutRow[]) {
	if (prevLayout.length !== newLayout.length) {
		return false;
	}

	return prevLayout.every((row: LayoutRow, index: number) => {
		const newRow = newLayout[index];

		if (row.rowSize !== newRow.rowSize || row.columns.length !== newRow.columns.length) {
			return false;
		}

		return row.columns.every((col: LayoutRow['columns'][0], colIndex: number) => {
			const newCol = newRow.columns[colIndex];

			return (
				col.id === newCol.id &&
				Math.floor(col.columnWidthPerc) === Math.floor(newCol.columnWidthPerc) &&
				col.isHidden === newCol.isHidden
			);
		});
	});
}

export function getDashboardForAutosave(globalLayoutState: LayoutRow[], dashboard: any) {
	const newReports: any[] = [];

	globalLayoutState.forEach((row: LayoutRow, rowIndex: number) => {
		row.columns.forEach((report: LayoutRow['columns'][0], columnIndex: number) => {
			const newConfigurations = [
				{ name: 'layoutVersion', value: 'v2' },
				{
					name: 'rowHeightPx',
					value: row.rowSize.toString(),
				},
				{
					name: 'columnWidthPerc',
					value: report.columnWidthPerc.toString(),
				},
				{
					name: 'rowIndex',
					value: rowIndex.toString(),
				},
				{
					name: 'columnIndex',
					value: columnIndex.toString(),
				},
			];

			const currentReport = dashboard.reports.find((r: any) => r.reportId === report.id);

			const updatedConfiguration = currentReport.configuration.map((config: { name: string; value: string }) => {
				const newConfig = newConfigurations.find(nc => nc.name === config.name);
				return newConfig || config;
			});

			newConfigurations.forEach(newConfig => {
				if (
					!updatedConfiguration.some(
						(config: { name: string; value: string }) => config.name === newConfig.name
					)
				) {
					updatedConfiguration.push(newConfig);
				}
			});

			newReports.push({
				...currentReport,
				configuration: updatedConfiguration,
			});
		});
	});

	return {
		...dashboard,
		reports: newReports,
	};
}
