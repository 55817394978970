import ActionTypes from '../../constants/ActionTypes';
import { Template } from './model';
import produce from 'immer';
import sortBy from 'lodash/sortBy';

interface ReducerState {
	pending?: boolean;
	error?: boolean;
	data?: Template[];
	loaded?: boolean;
}

export default function reducer(state: ReducerState = {}, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.GetDSTemplatesPending: {
			return {
				...state,
				pending: true,
				error: false,
			};
		}
		case ActionTypes.GetDSTemplatesFulfilled: {
			let { data, configData } = action;
			data = data.filter((d: any) => !!d.templateConfigurationId);
			data = data.map((d: any) => {
				const config = configData.find((c: any) => c.templateConfigurationId === d.templateConfigurationId);
				const { templateName } = config || {};
				return {
					...d,
					templateName,
				};
			});
			data = sortBy(data, d => d.templateName);
			return {
				...state,
				pending: false,
				data,
				loaded: true,
			};
		}
		case ActionTypes.GetDSTemplatesRejected: {
			return {
				...state,
				pending: false,
				error: true,
			};
		}
		case ActionTypes.SaveTemplateFulfilled: {
			const { template, templateName } = action;
			template.templateName = templateName;
			return produce(state, draftState => {
				if (!draftState.data) {
					draftState.data = [];
				}
				const index = draftState.data.findIndex(d => d.templateId === template.templateId);
				if (index >= 0) {
					draftState.data[index] = template;
				} else {
					draftState.data.push(template);
				}
			});
		}
		default:
			return state;
	}
}
