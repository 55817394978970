import { Switch, Route, Redirect } from 'react-router-dom';

import Users from './users/Users';
import Billing from './billing/Billing';
import Groups from './groups/Groups';
import RolesPermissions from './rolespermissions/RolesPermissions';
import Integrations from './integrations/Integrations';
import Settings from './settings/Settings';
import UsersHeader from './AdminHeader/UsersHeader';
import GroupsHeader from './AdminHeader/GroupsHeader';
import RolesHeader from './AdminHeader/RolesHeader';
import NotFound from '../routes/NotFound';
import { adminTabs } from './AdminTabs';
import hasPermission from '../lib/hasPermission';
import { useAppSelector } from '../store/hooks';
import EmptyHeader from './AdminHeader/EmptyHeader';

interface AdminRoutesProps {
	area: 'content' | 'header';
}

const AdminRoutes = ({ area }: AdminRoutesProps) => {
	const { staticPermissions } = useAppSelector(state => state.auth);
	const { allGroups } = useAppSelector(state => state.admin.groups);

	const getRedirectRoute = () => {
		const filteredTabs = adminTabs.filter(tab => {
			if (tab.key === 'groups') {
				if (!allGroups || allGroups.length === 0) {
					return false;
				}
			}

			return hasPermission(staticPermissions, tab.actions);
		});

		return `/admin/${filteredTabs[0]?.key}` || '/admin/users';
	};

	const redirectRoute = getRedirectRoute();

	if (area === 'content') {
		return (
			<Switch>
				<Route exact path="/admin">
					<Redirect to={redirectRoute} />
				</Route>
				<Route path="/admin/users" exact component={Users} />
				<Route path="/admin/groups" exact component={Groups} />
				<Route path="/admin/roles" exact component={RolesPermissions} />
				<Route path="/admin/integrations" exact component={Integrations} />
				<Route path="/admin/payment" exact component={Billing} />
				<Route path="/admin/settings" exact component={Settings} />
				<Route component={NotFound} />
			</Switch>
		);
	} else if (area === 'header') {
		return (
			<Switch>
				<Route exact path="/admin">
					<Redirect to={redirectRoute} />
				</Route>
				<Route path="/admin/users" exact component={UsersHeader} />
				<Route path="/admin/groups" exact component={GroupsHeader} />
				<Route path="/admin/roles" exact component={RolesHeader} />
				<Route path="/admin/integrations" exact component={EmptyHeader} />
				<Route path="/admin/payment" exact component={EmptyHeader} />
				<Route path="/admin/settings" exact component={EmptyHeader} />
				<Route component={NotFound} />
			</Switch>
		);
	}

	return null;
};

export default AdminRoutes;
