import Stack from './Stack';

const HeaderWrapper = ({ children }: any) => {
	return (
		<div
			style={{
				marginRight: '2rem',
				width: '100%',
			}}
		>
			<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
				{children}
			</Stack>
		</div>
	);
};

export default HeaderWrapper;
