import React from 'react';
import { ChartProps } from '../types';
import { getSVG } from './utils';

const Metric = ({ type, count = 1 }: ChartProps) => {
	const MetricChart = getSVG(type, count);
	return (
		<svg width="100%" height="100%" viewBox="0 0 304 86" fill="none" xmlns="http://www.w3.org/2000/svg">
			<svg
				width="100%"
				height="100%"
				viewBox="-10 8 304 51"
				fill="none"
				overflow="visible"
				xmlns="http://www.w3.org/2000/svg"
			>
				{MetricChart}
			</svg>
		</svg>
	);
};

export default Metric;
