import React, { ChangeEvent, useState } from 'react';
import Accordion from '../Accordion';
import TextField from '../../common/TextField';
import produce from 'immer';

export default function InnerVariableEdit({
	mainVariable,
	propName,
	setConfigurationVariables,
	index,
	innerIndex,
}: any) {
	const [expanded, setExpanded] = useState(!mainVariable.originalName);

	function handleAnalysisNameChange(e: ChangeEvent<HTMLInputElement>) {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index][propName][innerIndex].analysisName = e.target.value;
			})
		);
	}

	function handleOriginalNameChange(e: ChangeEvent<HTMLInputElement>) {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index][propName][innerIndex].originalName = e.target.value;
			})
		);
	}

	function handleColumnNameChange(e: ChangeEvent<HTMLInputElement>) {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index][propName][innerIndex].columnName = e.target.value;
			})
		);
	}

	function handleRemove() {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index][propName].splice(innerIndex, 1);
			})
		);
	}

	const showAnalysisName = !['mainVariables', 'controlVariables'].includes(propName);

	return (
		<div>
			<Accordion
				label={mainVariable.originalName}
				expanded={expanded}
				setExpanded={setExpanded}
				remove={handleRemove}
			>
				<div className="ds-label">Original name</div>
				<div className="ds-input">
					<TextField value={mainVariable.originalName || ''} onChange={handleOriginalNameChange} />
				</div>
				<div className="ds-label">Column name</div>
				<div className="ds-input">
					<TextField value={mainVariable.columnName || ''} onChange={handleColumnNameChange} />
				</div>
				{showAnalysisName && (
					<>
						<div className="ds-label">Analysis name</div>
						<div className="ds-input">
							<TextField value={mainVariable.analysisName || ''} onChange={handleAnalysisNameChange} />
						</div>
					</>
				)}
			</Accordion>
		</div>
	);
}
