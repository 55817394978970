import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import Urls from '../../constants/Urls';
import { AsyncThunkConfig } from '../../store/store';
import { resetChat } from '../chat/reducer';
import { deleteConversation } from './reducer';

export const setChatConversationMetadata = createAsyncThunk<any, { conversationId: string; metadata: any }>(
	'ai/conversations/setChatConversationMetadata',
	async ({ conversationId, metadata }) => {
		const response = await axios.put(`${Urls.AiInsightsApi}chat/conversations/metadata`, {
			conversation_id: conversationId,
			metadata,
		});
		return response.data;
	}
);

export const getAllChatConversationMetadata = createAsyncThunk<any, void>(
	'ai/conversations/getAllChatConversationMetadata/all',
	async () => {
		const response = await axios.post(`${Urls.AiInsightsApi}chat/conversations/metadata/all`);
		return response.data;
	}
);

export const getChatConversationMetadata = createAsyncThunk<any, { conversationId: string }>(
	'ai/conversations/getChatConversationMetadata',
	async ({ conversationId }) => {
		const response = await axios.post(`${Urls.AiInsightsApi}chat/conversations/metadata`, {
			conversation_id: conversationId,
		});
		return response.data;
	}
);

export const removeChatConversationMetadata = createAsyncThunk<any, { conversationId: string }>(
	'ai/conversations/removeChatConversationMetadata',
	async ({ conversationId }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}chat/conversations/metadata`, {
			data: { conversation_id: conversationId },
		});
		return response.data;
	}
);

export const getChatConversationsList = createAsyncThunk<any, void>('ai/chat/getConversationsList', async () => {
	const response = await axios.post(`${Urls.AiInsightsApi}chat/conversations`);
	return response.data;
});

export const deleteChatConversation = createAsyncThunk<any, string, AsyncThunkConfig>(
	'ai/chat/deleteConversation',
	async (conversationId, { dispatch }) => {
		const response = await axios.delete(`${Urls.AiInsightsApi}chat/conversations`, {
			data: { conversation_id: conversationId },
		});
		dispatch(deleteConversation(conversationId));
		dispatch(resetChat());
		return response.data;
	}
);
