import moment from 'moment';
import PeriodTypes from '../constants/PeriodTypes';
import Interval from '../constants/Interval';

export default function getPeriod(time: any, periodType: string, axis?: boolean) {
	const periodTypeLower = periodType.toLowerCase();
	switch (periodTypeLower) {
		case PeriodTypes.Yearly:
		case Interval.Yearly.toLowerCase():
			return axis ? moment.utc(time).format('YY') : moment.utc(time).format('YYYY');
		case PeriodTypes.Quarterly:
		case Interval.Quarterly.toLowerCase():
			return axis ? moment.utc(time).format('[Q]Q-YY') : moment.utc(time).format('[Q]Q YYYY');
		case PeriodTypes.Monthly:
		case Interval.Monthly.toLowerCase():
			return axis ? moment.utc(time).format('MMM-YY') : moment.utc(time).format('MMM YYYY');
	}
}
