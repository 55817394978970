import Menu from 'antd/lib/menu';
import { Fragment } from 'react';

import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';

interface MoreMenuProps {
    handleMoreMenuSelect: any;
    handleExportAsCSV: any;
    handleExport: any;
    moreKeys: any;
}

const MoreMenu = ({
    handleExport,
    handleExportAsCSV,
    handleMoreMenuSelect,
    moreKeys
}: MoreMenuProps) => {
    const exportAllowed = usePermissions({
        actions: [RbacActions['Explore/View'], RbacActions['Explore/Generate']]
    });

    return (
        <Menu
            className="widget-menu"
            selectedKeys={moreKeys}
            onSelect={handleMoreMenuSelect}
        >
            {exportAllowed && (
                <Fragment>
                    <Menu.Item key="csv" onClick={handleExportAsCSV}>
                        Export as CSV
                    </Menu.Item>
                    <Menu.Item key="image" onClick={handleExport}>
                        Export as Image
                    </Menu.Item>
                </Fragment>
            )}
        </Menu>
    );
};

export default MoreMenu;
