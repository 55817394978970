import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchSurveyTemplates } from './slice';
import ContentWrapper from '../../components/ContentWrapper';
import WithLoadingStates from '../../../common/WithLoadingStates';
import Grid from '../../../common/Grid';
import { Survey } from '../../../datastudio/survey/template/types';
import Stack from '../../../common/Stack';
import { useHistory } from 'react-router';
import InfoCard from '../../../common/InfoCard';
import SurveyTemplateDefault from '../../../icons/SurveyTemplateDefault';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';

const SurveyTemplates = () => {
	const { data, loaded, error } = useAppSelector(state => state.surveyTemplates.list);
	const [hoverIndex, setHoverIndex] = useState(-1);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!loaded) {
			dispatch(fetchSurveyTemplates());
		}
	}, [loaded]);

	const isLoading = !loaded && !error;
	const hasError = !!error;
	const isEmpty = loaded && data.length === 0 && !hasError;

	return (
		<WithPermissions actions={[RbacActions['Campaign/Edit']]} showMessage={true}>
			<ContentWrapper>
				<WithLoadingStates isLoading={isLoading} isEmpty={isEmpty} isError={hasError}>
					<Grid base={3} gap="1.6rem">
						{data
							.filter((surveyTemplate: Survey) => surveyTemplate.isPublished)
							.map((surveyTemplate: Survey, index: number) => (
								<Grid.Item key={surveyTemplate.globalSurveyId}>
									<Card
										survey={surveyTemplate}
										hoverIndex={hoverIndex}
										setHoverIndex={setHoverIndex}
										index={index}
									/>
								</Grid.Item>
							))}
					</Grid>
				</WithLoadingStates>
			</ContentWrapper>
		</WithPermissions>
	);
};

const Card = ({
	survey,
	hoverIndex,
	setHoverIndex,
	index,
	selectionExists,
}: {
	survey: any;
	hoverIndex: number;
	setHoverIndex: any;
	index: number;
	selectionExists?: boolean;
}) => {
	const history = useHistory();
	const uiElementsAsObj = survey.meta.uiElements.reduce((obj: any, uiElement: any) => {
		obj[uiElement.key] = uiElement?.value || null;
		return obj;
	}, {} as { [key: string]: string | null });

	return (
		<InfoCard
			onClick={() => history.push(`/self-id/survey-templates/view/${survey.globalSurveyId}`)}
			state={selectionExists ? 'selectionExists' : hoverIndex !== -1 ? 'hoverExists' : 'default'}
			isHovering={hoverIndex === index}
			onMouseEnter={() => setHoverIndex(index)}
			onMouseLeave={() => setHoverIndex(-1)}
		>
			<div
				style={{
					width: '100%',
					aspectRatio: '3/1',
					height: 'auto',
					position: 'relative',
					backgroundColor: 'var(--color-shade-4)',
				}}
			>
				{uiElementsAsObj.imageUrl ? (
					<img
						style={{
							position: 'absolute',
							objectFit: 'cover',
							width: '100%',
							height: '100%',
						}}
						src={uiElementsAsObj.imageUrl}
						alt="survey template image"
					/>
				) : (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							color: 'var(--color-ui-75)',
						}}
					>
						<SurveyTemplateDefault width={80} height={80} />
					</div>
				)}
			</div>
			<Stack
				gap=".8rem"
				style={{
					padding: '1.6rem',
				}}
			>
				<h3 className="font-subtitle">{survey.title}</h3>
				<span
					style={{
						color: 'var(--color-ui-50',
					}}
				>
					{survey.description}
				</span>
			</Stack>
		</InfoCard>
	);
};

export default SurveyTemplates;
