import { useState } from 'react';
import { selectCurrentTime as selectCurrentTimeInReport } from '../dashboard/view/actions';

import { selectCurrentTime } from '../explore/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Events from './Events';
import Legend from './Legend';
import LineChart from './LineChart';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';
import XAxis from './XAxis';

export default function LineChartWrapper({
	reportId,
	dashboardId,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	dashboardId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
	[key: string]: any;
}) {
	const dispatch = useAppDispatch();
	const { dynamicPermissions } = useAppSelector(state => state.auth);

	const [innerChartOffsets, setInnerChartOffsets] = useState({
		left: 0,
		right: 0,
	});

	const {
		analysisName,
		analysisType,
		chartType,
		columnProps,
		currentIndex,
		data,
		dates,
		eventIds,
		events,
		indexType,
		interval,
		lifecycle,
		metricOverlay,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayChartType,
		overlayIndexType,
		overlayYAxisType,
		yAxisType,
	} = useAppSelector(state => getChartData(state, reportId));

	const overlayColumnProps = metricOverlay?.columnProps ?? [];
	const overlayData = metricOverlay?.data ?? [];
	const overlayAnalysisName = metricOverlay?.analysisName;
	const mergedData = overlayChartType === chartType && !!overlayData ? [...data, ...overlayData] : data;

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);
	const dataOverlayProp = getDynamicDataProp(overlayYAxisType, overlayIndexType, overlayColumnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	function handleSelectCurrentTime(currentIndex: number) {
		if (reportId) {
			dispatch(selectCurrentTimeInReport(reportId, currentIndex));
		} else {
			dispatch(selectCurrentTime(currentIndex));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={data?.map((d: any) => ({
					...d,
					analysisName: analysisName,
				}))}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				overlayData={overlayData?.map((d: any) => ({
					...d,
					analysisName: overlayAnalysisName,
				}))}
				selection={selection}
				status={status}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]', reportId && '!h-full')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<LineChart
									analysisType={analysisType}
									columnProps={columnProps}
									currentIndex={currentIndex}
									data={mergedData}
									dataOverlayProp={dataOverlayProp}
									dataProp={dataProp}
									dates={dates}
									employeeCountHidden={employeeCountHidden}
									height={height}
									hoveredItem={hoveredItem}
									indexType={indexType}
									interval={interval}
									lifecycle={lifecycle}
									metricOverlayLifecycle={metricOverlayLifecycle}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									overlayAnalysisType={overlayAnalysisType}
									overlayIndexType={overlayIndexType}
									overlayYAxisType={overlayYAxisType}
									reportId={reportId || 'explore-chart'}
									selectCurrentTime={handleSelectCurrentTime}
									selection={selection}
									setInnerChartOffsets={setInnerChartOffsets}
									width={width}
									yAxisType={yAxisType}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{eventIds?.length > 0 && events?.length > 0 && dates?.length > 1 && (
					<Events
						events={events.filter((event: any) => eventIds.includes(event.eventId))}
						dates={dates}
						interval={interval}
						analysisType={analysisType}
					/>
				)}
			</ScrollableChartArea>
			<XAxis reportId={reportId} innerChartOffsets={innerChartOffsets} />
		</div>
	);
}

//
