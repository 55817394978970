import { deleteBenchmark } from '../../benchmark/actions';
import { closeDeleteModal } from '../../common/actions';
import DeleteModal from '../../common/DeleteModal';
import WithPermissions from '../../common/WithPermissions';
import mixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export default function DeleteBenchmarkModal({ benchmarkToEdit }: any) {
	const dispatch = useAppDispatch();
	const { isOpen, pending } = useAppSelector<any>(state => state.deleteModal);

	function handleBenchmarkDeleteConfirm() {
		track(mixPanel.Events.ExploreDeleteBenchmarkConfirm, {
			'Benchmark Name': benchmarkToEdit.benchmarkName,
		});
		dispatch(deleteBenchmark(benchmarkToEdit.benchmarkId));
	}

	function handleBenchmarkDeleteCancel() {
		track(mixPanel.Events.ExploreDeleteBenchmarkCancel, {
			'Benchmark Name': benchmarkToEdit.benchmarkName,
		});
		dispatch(closeDeleteModal());
	}

	return (
		<WithPermissions actions={[RbacActions['Benchmarks/Edit']]}>
			<DeleteModal
				isOpen={isOpen}
				onCancel={handleBenchmarkDeleteCancel}
				onAction={handleBenchmarkDeleteConfirm}
				title="Delete Benchmark"
				pending={pending}
			>
				<p>Click Delete to permanently delete this benchmark.</p>
			</DeleteModal>
		</WithPermissions>
	);
}
