import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { Survey, SurveysState } from '../../../datastudio/survey/template/types';

export const fetchSurveyTemplates = createAsyncThunk<SurveysState, void, AsyncThunkConfig>(
	'survey/fetchSurveys',
	async (_, { getState }) => {
		const { enterpriseId } = getState().account;
		let pageToken;
		let allData: Survey[] = [];
		do {
			const response: any = await axios.post(`${Urls.SurveyStudioApi}enterprise/surveys`, {
				enterpriseId,
				pageToken,
			});
			if (response.data.surveys?.length) {
				allData = [...allData, ...response.data.surveys];
			}
			pageToken = response.data.pageToken;
		} while (pageToken);

		const data: Survey[] = [];
		const archived: Survey[] = [];

		allData.forEach(d => {
			const uiElem = d.meta.uiElements.find(u => u.key === 'archive');
			if (uiElem?.value === 'true') {
				archived.push(d);
			} else {
				data.push(d);
			}
		});
		return { data, archived };
	}
);

export const slice = createSlice({
	name: 'surveyTemplates',
	initialState: { data: [], archived: [] } as SurveysState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSurveyTemplates.fulfilled, (state, action) => {
			const { data } = action.payload;
			state.data = data;
			state.pending = false;
			state.loaded = true;
		});
		builder.addCase(fetchSurveyTemplates.pending, (state, action) => {
			state.pending = true;
			state.error = false;
		});
		builder.addCase(fetchSurveyTemplates.rejected, (state, action) => {
			state.error = true;
			state.pending = false;
		});
	},
});

export default slice.reducer;
