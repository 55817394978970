interface FormatValueProps {
	value: number | string;
	unit: '%' | 'x' | 'number' | 'integer' | 'day' | 'week' | 'month' | 'year';
}

export default function formatValue(props: FormatValueProps) {
	const { value, unit } = props;

	const coercedValue = typeof value === 'string' ? parseFloat(value) : value;

	switch (unit) {
		case '%':
			return `${coercedValue.toFixed(1)}%`;
		case 'x':
			return `${coercedValue.toFixed(1)}x`;
		case 'number':
			return `${coercedValue.toFixed(1)}`;
		case 'integer':
			return `${coercedValue.toFixed(0)}`;
		case 'day':
			if (coercedValue.toFixed(0) === '1') {
				return `${coercedValue.toFixed(0)} day`;
			}
			return `${coercedValue.toFixed(0)} days`;
		case 'week':
			if (coercedValue.toFixed(0) === '1') {
				return `${coercedValue.toFixed(0)} week`;
			}
			return `${coercedValue.toFixed(0)} weeks`;
		case 'month':
			if (coercedValue.toFixed(0) === '1') {
				return `${coercedValue.toFixed(0)} month`;
			}
			return `${coercedValue.toFixed(0)} months`;
		case 'year':
			if (coercedValue.toFixed(0) === '1') {
				return `${coercedValue.toFixed(0)} year`;
			}
			return `${coercedValue.toFixed(0)} years`;
		default:
			return value;
	}
}
