import React, { useEffect, useState } from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import HMore from '../icons/HMore';
import Popover from '../common/Popover';
import MoreMenu from './MoreMenu';
import SortableHead from '../common/SortableHead';
import Constants from '../constants/Constants';
import { track } from '../lib/segment';
import MixPanel from '../constants/MixPanel';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';

interface ListProps {
	dashboards: any[];
	navigateToDashboard: any;
	role: string;
}

interface MoreMenuWrapperProps {
	dashboardId: string;
	dashboardName: string;
	role: string;
}

function MoreMenuWrapper(props: MoreMenuWrapperProps) {
	const { dashboardId, dashboardName, role } = props;
	const [moreOpen, setMoreOpen] = useState(false);

	return (
		<Popover
			placement="bottomRight"
			visible={moreOpen}
			onVisibleChange={() => {
				setMoreOpen(!moreOpen);
			}}
			content={
				<MoreMenu
					dashboardId={dashboardId}
					dashboardName={dashboardName}
					onClose={() => setMoreOpen(false)}
					role={role}
					source="All"
				/>
			}
			overlayClassName="popover--no-padding"
		>
			<HMore className="icon" />
		</Popover>
	);
}

const defaultSortCriteria: {
	columnName: string;
	ascending: boolean;
	sortFn?: any;
} = {
	columnName: 'dateAdded',
	ascending: false,
};

export default function List(props: ListProps) {
	const { dashboards, navigateToDashboard, role } = props;
	const [sortCriteria, setSortCriteria] = useState(defaultSortCriteria);
	const [data, setData] = useState(dashboards);

	useEffect(() => {
		const sortedData = orderBy(
			dashboards,
			[sortCriteria.sortFn || sortCriteria.columnName],
			[sortCriteria.ascending ? 'asc' : 'desc']
		);
		setData(sortedData);
	}, [dashboards, sortCriteria]);

	function handleSort(columnName: string, sortFn: any) {
		let ascending = true;
		if (columnName === sortCriteria.columnName) {
			ascending = !sortCriteria.ascending;
		}
		setSortCriteria({
			columnName,
			ascending,
			sortFn,
		});
	}

	function handleRowClick(dashboardId: string, dashboardName: string) {
		track(MixPanel.Events.MyDashboardsAllDashboardClick, {
			'Dashboard Name': dashboardName,
		});
		navigateToDashboard(dashboardId);
	}

	return (
		<table>
			<thead>
				<tr className="dashboards__row dashboards__row--header">
					<SortableHead
						label="Name"
						columnName="name"
						sortFn={d => d.name.trim().toLowerCase()}
						sortCriteria={sortCriteria}
						onSort={handleSort}
					/>
					<SortableHead
						label="Created by"
						columnName="createdByName"
						sortCriteria={sortCriteria}
						onSort={handleSort}
					/>
					<SortableHead
						label="Created on"
						columnName="dateAdded"
						sortCriteria={sortCriteria}
						onSort={handleSort}
					/>
					<SortableHead
						label="Updated on"
						columnName="lastUpdatedAt"
						sortCriteria={sortCriteria}
						onSort={handleSort}
					/>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{data.map((d, i) => (
					<tr
						className={i % 2 === 0 ? 'dashboards__row  dashboards__row--odd' : 'dashboards__row'}
						key={d.dashboardId}
						onClick={() => handleRowClick(d.dashboardId, d.name)}
						data-test={`dashboard-list-${d.name}`}
					>
						<td>{d.name}</td>
						<td>{d.createdByName}</td>
						<td>{moment.utc(d.dateAdded).format(Constants.DateFormat)}</td>
						<td>{moment.utc(d.lastUpdatedAt).format(Constants.DateFormat)}</td>
						<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
							<td onClick={e => e.stopPropagation()}>
								<MoreMenuWrapper dashboardId={d.dashboardId} dashboardName={d.name} role={role} />
							</td>
						</WithPermissions>
					</tr>
				))}
			</tbody>
		</table>
	);
}
