import React from 'react';
import { connect } from 'react-redux';
import { setInfoModalOpen } from './actions';

interface EmptyStateProps {
	setInfoModalOpen: typeof setInfoModalOpen;
}

const EmptyState = ({ setInfoModalOpen }: EmptyStateProps) => {
	return (
		<div className="timeline__no-events" onClick={() => setInfoModalOpen(true)}>
			<div>Click “Add Event” to add an event to your Timeline.</div>
			<div>
				For more information on Timeline and Events,{' '}
				<span onClick={() => setInfoModalOpen(true)}> click here</span>.
			</div>
		</div>
	);
};

const dispatchProps = {
	setInfoModalOpen,
};

export default connect(null, dispatchProps)(EmptyState);
