import axios from 'axios';
import { replace } from 'connected-react-router';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';

import { addMessage } from '../../common/actions';
import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import Constants from '../../constants/Constants';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { getReportDef } from '../../store/actionUtils';
import { navigate } from '../filter/actions';

export function addReportToDashboard(reportName, dashboardId, config = {}) {
	return (dispatch, getState) => {
		const dashboard = getState().dashboards[dashboardId];
		if (!dashboard) return;

		dispatch({ type: ActionTypes.SaveReportPending });
		const { enterpriseId } = getState().account;

		let rowIndex = 0;
		const columnIndex = 0;

		dashboard.reports?.forEach((report, index) => {
			const currentRowIndex = report.configuration.find(c => c.name === 'rowIndex')?.value;
			if (currentRowIndex > rowIndex) {
				rowIndex = parseInt(currentRowIndex);
			}
		});

		rowIndex = rowIndex + 1;

		const rowHeight = 500;

		const {
			analysisType,
			analysisName,
			subAnalyses,
			mainSegments,
			filterSegment,
			breakdownSegment,
			attributes,
			interval,
			intervalCount,
			startDate,
			endDate,
			showSubtable,
			overlayAnalysisName,
			overlayAnalysisType,
			overlayChartType,
			overlayColumnProps,
			overlayDisplay,
			overlayFilterNames,
			overlayFilterSegment,
			overlayIndexType,
			overlayMainNames,
			overlayMainSegments,
			overlayYAxisType,
			overlayAnalysisDisplayName,
		} = getState().explore.filter;

		const { benchmark, eventIds, yAxisType, indexType } = getState().explore.chart;
		let { chartType } = getState().explore.chart;
		const subtableChartType = getState().explore.subtable.subtableChartType;

		const { employeeAttribute, rowFilter, columnFilter, isDetailSection } = config;
		let { date } = config;
		if (analysisType === AnalysisTypes.Arithmetic) {
			date = moment.utc(getState().explore.filter.date, Constants.ApiDateFormat);
		}
		if (breakdownSegment) {
			({ date } = getState().explore.subtable);
			date = moment.utc(date, Constants.ApiDateFormat);
		}
		if (date) {
			date = date.toISOString();
		}

		const isSubtable = !!employeeAttribute || (breakdownSegment && breakdownSegment.values.length > 1);
		if (isSubtable) {
			if (isDetailSection) {
				chartType = getState().explore.detail.dynamic.sections.find(
					ds => ds.employeeAttribute === employeeAttribute
				).chartType;
			} else {
				chartType = getState().explore.subtable.subtableChartType;
			}
		}

		const params = {
			analysisName,
			subAnalyses,
			mainSegments,
			filterSegment,
			breakdownSegment,
			attributes,
			chartType,
			interval,
			intervalCount,
			startDate,
			endDate,
			employeeAttribute,
			rowFilter,
			columnFilter,
			date,
			reportName,
			rowIndex,
			columnIndex,
			rowHeight,
			benchmark,
			eventIds,
			yAxisType,
			indexType,
			subtableChartType,
			showSubtable,
			overlayAnalysisName,
			overlayAnalysisType,
			overlayAnalysisDisplayName,
			overlayChartType,
			overlayColumnProps,
			overlayDisplay,
			overlayFilterNames,
			overlayFilterSegment,
			overlayIndexType,
			overlayMainNames,
			overlayMainSegments,
			overlayYAxisType,
		};

		const report = getReportDef(params);

		return axios
			.put(`${Urls.DashboardsApi}report`, {
				enterpriseId,
				dashboardId,
				report,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.SaveReportFulfilled,
					dashboardId,
					report,
				});
				dispatch(
					addMessage(
						<span>
							{`Report added to ${dashboard.name}. View in `}
							<Link to={`/dashboard/${dashboardId}`}>Dashboard</Link>
						</span>
					)
				);
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveReportRejected,
				});
			});
	};
}

export function createDashboardAndAddReport(dashboardName, reportName, config) {
	return (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		const { uid, firstName, lastName } = getState().auth.userInfo;

		dispatch({
			type: ActionTypes.CreateDashboardPending,
		});

		const dashboard = {
			enterpriseId,
			dashboardId: uuid.v4(),
			name: dashboardName,
			reports: [],
			createdById: uid,
			createdByName: `${firstName} ${lastName}`,
			dateAdded: moment.utc().toISOString(),
			lastUpdatedAt: moment.utc().toISOString(),
		};

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, { dashboard })
			.then(() => {
				dispatch({
					type: ActionTypes.CreateDashboardFulfilled,
					dashboard,
				});
				return dispatch(addReportToDashboard(reportName, dashboard.dashboardId, config));
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.CreateDashboardRejected,
				});
			});
	};
}

export function selectInterval(interval, history) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SelectIntervalInExplore,
			interval,
		});
		dispatch(navigate());
	};
}

export function selectIntervalRange(startDate, endDate, history) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SelectIntervalRangeInExplore,
			startDate,
			endDate,
		});
		dispatch(navigate());
	};
}

export function selectIntervalCount(intervalCount, history) {
	return dispatch => {
		dispatch({
			type: ActionTypes.SelectIntervalCountInExplore,
			intervalCount,
		});
		dispatch(navigate());
	};
}

export function selectDate(date) {
	return (dispatch, getState) => {
		const { state, search } = getState().router.location;
		const { showSubtable } = getState().explore.filter;
		const queryObject = qs.parse(search, {
			ignoreQueryPrefix: true,
			arrayLimit: 10000,
		});

		queryObject.filter.date = date;
		queryObject.filter.showSubtable = showSubtable;

		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: state?.source || 'explore' }));

		dispatch({
			type: ActionTypes.SelectDateInExplore,
			date,
		});
	};
}
