import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { getAudiences } from '../list/actions';
import { mapHireDateValue } from '../list/utils';
import {
	CreateDynamicAudienceRequest,
	SaveStaticAudienceRequest,
	UpdateDynamicAudienceRequest,
	ArchiveAudienceRequest,
} from './types';
import { Audience, AudienceType } from '../types';
import { cloneDeep } from 'lodash';

export const createDynamicAudience = createAsyncThunk<any, void, AsyncThunkConfig>(
	'audiences/dynamic/create',
	async (_, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { title, description, segments } = getState().audiences.edit;

		const hireDateFilter = segments.find(seg => seg.column === 'primary.hireDate');

		const payload: CreateDynamicAudienceRequest = {
			title,
			description,
			enterpriseId,
			criteria: {
				predicates: segments
					.filter(segment => segment.column !== 'primary.hireDate')
					.map(segment => ({
						column: segment.column,
						terms: segment.values,
					})),
				dateColumn: !!hireDateFilter ? 'primary.hireDate' : '',
				endDate: '0',
				startDate: mapHireDateValue(hireDateFilter?.values[0]),
			},
		};

		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/dynamic/create`, payload);
		dispatch(getAudiences());
		return response.data;
	}
);

export const updateDynamicAudience = createAsyncThunk<any, void, AsyncThunkConfig>(
	'audiences/dynamic/update',
	async (_, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { title, description, segments, audienceId } = getState().audiences.edit;
		const payload: UpdateDynamicAudienceRequest = {
			audienceId: audienceId as string,
			enterpriseId,
			criteria: {
				predicates: segments.map(segment => ({
					column: segment.column,
					terms: segment.values,
				})),
			},
			title: title as string,
			description,
		};
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/dynamic/update`, payload);

		dispatch(getAudiences());
		return response.data;
	}
);

export const appendStaticAudience = createAsyncThunk<any, void, AsyncThunkConfig>(
	'audiences/static/append',
	async (_, { getState, dispatch }) => {
		const payload: any = {};
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/static/append`, payload);
		dispatch(getAudiences());
		return response.data;
	}
);

export const createStaticAudience = createAsyncThunk<Audience, SaveStaticAudienceRequest, AsyncThunkConfig>(
	'audiences/static/create',
	async (audience, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/static/create`, {
			...audience,
			enterpriseId,
		});
		dispatch(getAudiences());
		return response.data.audience;
	}
);

export const overwriteStaticAudience = createAsyncThunk<Audience, SaveStaticAudienceRequest, AsyncThunkConfig>(
	'audiences/static/overwrite',
	async (audience, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/static/overwrite`, {
			...audience,
			enterpriseId,
		});
		dispatch(getAudiences());
		return response.data.audience;
	}
);

export const updateStaticAudience = createAsyncThunk<Audience, SaveStaticAudienceRequest, AsyncThunkConfig>(
	'audiences/static/update',
	async (audience, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/static/update`, {
			...audience,
			enterpriseId,
		});
		dispatch(getAudiences());
		return response.data.audience;
	}
);

export const archiveAudience = createAsyncThunk<any, ArchiveAudienceRequest, AsyncThunkConfig>(
	'audiences/archive',
	async (payload, { getState, dispatch }) => {
		const { shouldArchive, audience } = payload;
		const isStatic = audience.type === AudienceType.STATIC;
		const url = isStatic
			? `${Urls.SurveyStudioApi}audiences/static/update`
			: `${Urls.SurveyStudioApi}audiences/dynamic/update`;
		const archivePayload: any = isStatic
			? {
					audienceId: audience.audienceId,
					title: audience.title,
					description: audience.description,
					fileName: audience.filePath,
					uiElements: cloneDeep(audience.meta.uiElements),
			  }
			: {
					audienceId: audience.audienceId,
					enterpriseId: audience.enterpriseId,
					criteria: audience.criteria,
					title: audience.title,
					description: audience.description,
					uiElements: cloneDeep(audience.meta.uiElements),
			  };

		const uiElement = archivePayload.uiElements.find((u: any) => u.key === 'archive');
		if (!uiElement) {
			archivePayload.uiElements.push({
				key: 'archive',
				value: shouldArchive ? 'true' : 'false',
			});
		} else {
			uiElement.value = shouldArchive ? 'true' : 'false';
		}

		const response = await axios.put(url, archivePayload);

		dispatch(getAudiences());

		return response.data;
	}
);
