import { useState } from 'react';
import { LayoutRow } from './DashboardLayout';
import Droppable from './Droppable';

export default function RowSizeHandle({
	handleMouseDown,
	row,
	isDragging,
	disabled,
}: {
	handleMouseDown: (rowId: string, event: any) => void;
	row: LayoutRow;
	isDragging: boolean;
	disabled?: boolean;
}) {
	const [horizonalPosition, setHorizonalPosition] = useState(0);
	const [isHovering, setIsHovering] = useState(false);

	return (
		<Droppable id={`newRow_${row.rowId}`} direction="horizontal">
			<div
				onMouseDown={event => {
					if (!disabled) {
						handleMouseDown(row.rowId, event);
					}
				}}
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
				onMouseMove={event => {
					setHorizonalPosition(event.clientX);
				}}
				className={`relative h-[20px] mx-[10px] w-avail`}
				style={{
					cursor: disabled ? 'not-allowed' : 'row-resize',
				}}
				data-name="row-resizer"
			>
				<div
					data-name="row-resizer-handle"
					className={`w-[40px] h-[5px] rounded-full bg-ui-100 top-1/2 absolute left-0 ${(isHovering || isDragging) && !disabled ? 'opacity-100 visible' : 'opacity-0 invisible'
						} `}
					style={{
						transform: `translate3d(${horizonalPosition - 50}px, -50%, 0)`,
					}}
				></div>
			</div>
		</Droppable>
	);
}
