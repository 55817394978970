import cn from '../lib/cn';
import { useAppDispatch } from '../store/hooks';
import ScrollableChartArea from './ScrollableChartArea';
import { RootState } from '../store/store';
import Subtable from '../explore/subtable/Subtable';

function chartDataSelector(state: RootState) {
	// Add conditional logic for explore vs dashboard.
	return {
		...state.explore.chart,
		overlayChartType: state.explore.filter.overlayChartType,
	};
}

const SubtableWrapper = ({ onHover, onHoverEnd, onToggle, hoveredItem, selection, status }: any) => {
	const dispatch = useAppDispatch();

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full')}>
						<div className="explore-chart__main">
							<Subtable
								onHover={onHover}
								onHoverEnd={onHoverEnd}
								hoveredItem={hoveredItem}
								selection={selection}
								status={status}
								onToggle={onToggle}
							/>
						</div>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default SubtableWrapper;
