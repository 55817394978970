import * as Tabs from '@radix-ui/react-tabs';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import Privacy from '../Privacy';
import Audience from '../Audience';
import Storage from '../Storage';
import Messaging from '../Messaging';
import Stack from '../../../../../common/Stack';
import { setStep } from '../../slice';
import Step from './Step';
import Disclaimer from '../Disclaimer';

const TabsWrapper = () => {
	const { selectedIndex, steps } = useAppSelector(state => state.snapshotSurveys.edit);
	const dispatch = useAppDispatch();

	return (
		<Stack flexDirection="row" gap="4.8rem">
			<Tabs.Root
				defaultValue="tab0"
				value={`tab${selectedIndex}`}
				onValueChange={value => dispatch(setStep(+value.slice(-1)))}
				asChild
			>
				<Stack
					gap="3.2rem"
					style={{
						flex: 3,
					}}
				>
					<Tabs.List asChild>
						<Stack
							flexDirection="row"
							alignItems="center"
							style={{
								borderRadius: '9999px',
								background: 'var(--color-shade-4)',
								width: 'fit-content',
								overflow: 'hidden',
							}}
						>
							{steps.map((step, index) => (
								<Step
									key={step.label}
									selectedIndex={selectedIndex}
									index={index}
									label={step.label}
									validated={step.validated}
									disabled={step.disabled}
									isLast={index === steps.length - 1}
								/>
							))}
						</Stack>
					</Tabs.List>
					<Tabs.Content value={`tab0`}>
						<Privacy />
					</Tabs.Content>
					<Tabs.Content value={`tab1`}>
						<Audience />
					</Tabs.Content>
					<Tabs.Content value={`tab2`}>
						<Storage />
					</Tabs.Content>
					<Tabs.Content value={`tab3`}>
						<Messaging />
					</Tabs.Content>
				</Stack>
			</Tabs.Root>
			{selectedIndex === 3 && (
				<div
					style={{
						flex: 1,
					}}
				>
					<Disclaimer />
				</div>
			)}
		</Stack>
	);
};

export default TabsWrapper;
