import ActionTypes from '../constants/ActionTypes';
import { replace, goBack } from 'connected-react-router';

export function closeMessage() {
	return {
		type: ActionTypes.CloseMessage,
	};
}

export function addMessage(textOrNode: any, error?: boolean) {
	if (typeof textOrNode === 'string') {
		return {
			type: ActionTypes.AddMessage,
			text: textOrNode,
			error,
		};
	} else {
		return {
			type: ActionTypes.AddMessage,
			node: textOrNode,
			error,
		};
	}
}

export function showInfo(category: string, analysisType: string, singleSegment: boolean) {
	return {
		type: ActionTypes.ShowInfo,
		category,
		analysisType,
		singleSegment,
	};
}

export function closeInfo() {
	return {
		type: ActionTypes.CloseInfo,
	};
}

export function replaceRoute(redirectUrl: string) {
	return (dispatch: any) => {
		dispatch(replace(redirectUrl));
	};
}

export function back() {
	return (dispatch: any) => {
		dispatch(goBack());
	};
}

export function setScrollPosition(scrollPosition: number) {
	return {
		type: ActionTypes.SetScrollPosition,
		scrollPosition,
	};
}

export function openDeleteModal() {
	return {
		type: ActionTypes.OpenDeleteModal,
	};
}

export function closeDeleteModal() {
	return {
		type: ActionTypes.CloseDeleteModal,
	};
}
