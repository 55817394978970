import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Stack from '../../common/Stack';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import TopLeftAction from '../../layout/TopLeftAction';
import { useAppSelector } from '../../store/hooks';
import { capitalize } from '../utils';

interface HeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const GoalHeader = (props: HeaderProps) => {
	const {
		location: { state },
	} = props;
	const goal = useAppSelector(state => state.measure.goal?.goal);

	return (
		<WithPermissions actions={[RbacActions['Goals/View']]}>
			<Stack gap="2rem" flexDirection="row" alignItems="center">
				<TopLeftAction scrollPos={state?.scrollPos} />
				<div className="font-title">{capitalize(goal?.goalName || '')}</div>
			</Stack>
		</WithPermissions>
	);
};
export default withRouter(GoalHeader);
