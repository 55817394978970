import { replace } from 'connected-react-router';
import qs from 'qs';

const updateUrlIfNeeded = (store: any) => {
	const filter = store.getState().explore.filter;
	const chart = store.getState().explore.chart;
	const subtable = store.getState().explore.subtable;
	const dashboardReport = store.getState().explore.dashboardReport;
};

const exploreMiddleware = (store: any) => (next: any) => (action: any) => {
	const { pathname } = store.getState().router.location;
	//
	// if (pathname === '/explore' && action.type !== '@@router/LOCATION_CHANGE') {
	//   setTimeout(() => updateUrlIfNeeded(store), 0);
	// }

	return next(action);
};

export default exploreMiddleware;
