import { useAppSelector } from '../../store/hooks';
import { CSSTransition } from 'react-transition-group';

export default function EditModeEmptyState() {
	const { dashboardName, title } = useAppSelector(state => state.explore.dashboardReport);
	const isEmptyState = useAppSelector(
		state => !state.explore.filter.analysisName && state.explore.filter.mainSegments.length === 0
	);

	const text = !!title
		? `Start by choosing an Analysis to edit the ${title} report`
		: `Start by choosing an Analysis to create your report for the ${dashboardName} dashboard`;

	return (
		<div className="explore__empty-state-text">
			<CSSTransition in={isEmptyState} timeout={200} classNames="fade" mountOnEnter unmountOnExit>
				<span>{text}</span>
			</CSSTransition>
		</div>
	);
}
