import { useState } from 'react';
import Grid from '../../../../common/Grid';
import InfoCard from '../../../../common/InfoCard';
import Stack from '../../../../common/Stack';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { privacyOptions } from '../../constants';
import { setPrivacy } from '../slice';
import { IconConfig } from '../types';
import Handbook from './Handbook';
import IllustrationCard from './IllustrationCard';
import InfoModal from './InfoModal';

const Privacy = () => {
	const { privacy, isPublished } = useAppSelector(state => state.snapshotSurveys.edit);
	const [hoverIndex, setHoverIndex] = useState(-1);
	const [openHandbook, setOpenHandbook] = useState(false);
	const dispatch = useAppDispatch();

	return (
		<Stack gap="3.2rem" style={{ minHeight: '50rem' }}>
			<span style={{ color: 'var(--color-ui-75)' }}>Choose the privacy configuration for your campaign.</span>
			<Grid base={3} gap="1.6rem">
				{privacyOptions.map((option, index) => (
					<Grid.Item key={option.id}>
						<Card
							{...option}
							selected={privacy === option.id}
							selectionExists={!!privacy}
							onClick={() => dispatch(setPrivacy(option.id))}
							hoverIndex={hoverIndex}
							setHoverIndex={setHoverIndex}
							index={index}
							readOnly={isPublished}
							recommended={index === 1}
							iconConfig={{
								icon: option.icon,
								color: option.color,
								backgroundColor: option.backgroundColor,
								secondaryColor: option.secondaryColor,
								width: option.width,
								height: option.height,
							}}
						/>
					</Grid.Item>
				))}
			</Grid>
			<Grid base={1}>
				<Grid.Item>
					<IllustrationCard illustrationConfig={{ gender: 'FEMALE' }} onClick={() => setOpenHandbook(true)} />
				</Grid.Item>
			</Grid>

			<InfoModal onClose={() => setOpenHandbook(false)} isOpen={openHandbook} width={744}>
				<Handbook stage="privacy" onClose={() => setOpenHandbook(false)} />
			</InfoModal>
		</Stack>
	);
};

const Card = ({
	label,
	description,
	selected,
	color,
	icon: Icon,
	onClick,
	hoverIndex,
	setHoverIndex,
	index,
	readOnly,
	selectionExists,
	iconConfig,
	recommended,
}: {
	label: string;
	description: string;
	selected: boolean;
	color: string;
	icon: any;
	iconConfig: IconConfig;
	onClick: () => void;
	hoverIndex: number;
	setHoverIndex: any;
	index: number;
	readOnly?: boolean;
	selectionExists?: boolean;
	recommended?: boolean;
}) => {
	return (
		<InfoCard
			onClick={onClick}
			state={
				selectionExists
					? 'selectionExists'
					: hoverIndex !== -1
					? 'hoverExists'
					: readOnly
					? 'readOnly'
					: 'default'
			}
			onMouseEnter={() => setHoverIndex(index)}
			onMouseLeave={() => setHoverIndex(-1)}
			isHovering={hoverIndex === index}
			readOnly={readOnly}
			selected={selected}
			style={{
				userSelect: 'none',
			}}
		>
			<div
				style={{
					width: '100%',
					aspectRatio: '3/1',
					height: 'auto',
					position: 'relative',
					background:
						selectionExists && !selected ? 'var(--color-ui-10)' : iconConfig?.backgroundColor || color,
				}}
			>
				{recommended && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							borderRadius: '2rem',
							backgroundColor: '#11111280',
							padding: '.4rem 1.2rem',
							margin: '1rem',
							zIndex: 1,
						}}
					>
						Recommended
					</div>
				)}
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<Icon
						fill={selectionExists && !selected ? 'var(--color-ui-30)' : iconConfig?.color || undefined}
						secondaryFill={
							selectionExists && !selected
								? 'var(--color-ui-10)'
								: iconConfig?.secondaryColor || undefined
						}
						width={iconConfig?.width || undefined}
						height={iconConfig?.height || undefined}
					/>
				</div>
			</div>
			<Stack
				gap=".8rem"
				style={{
					padding: '1.6rem',
				}}
			>
				<h3>{label}</h3>
				<span
					style={{
						color: 'var(--color-ui-50)',
					}}
				>
					{description}
				</span>
			</Stack>
		</InfoCard>
	);
};

export default Privacy;
