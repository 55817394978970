import { Segment } from '../edit/types';

const segments: Segment[] = [
	{
		column: 'primary.gender',
		name: 'Gender',
		values: [],
	},
	{
		column: 'primary.ethnicity',
		name: 'Ethnicity',
		values: [],
	},
	{
		column: 'primary.childDeptName',
		name: 'Department',
		values: [],
	},
	{
		column: 'primary.location',
		name: 'Location',
		values: [],
	},
	{
		column: 'primary.jobTitleName',
		name: 'Job Title',
		values: [],
	},
	{
		column: 'primary.employeeType',
		name: 'Employee Type',
		values: [],
	},
	{
		column: 'primary.parentDeptName',
		name: 'Business Unit',
		values: [],
	},
];

export default segments;
