export enum units {
	'%' = '%',
	'x' = 'x',
	'number' = 'number',
	'integer' = 'integer',
	'day' = 'day',
	'week' = 'week',
	'month' = 'month',
	'year' = 'year',
}

export enum appModes {
	'explore' = 'explore',
	'editor' = 'editor',
	'timeline' = 'timeline',
	'measure' = 'measure',
	'collect' = 'collect',
	'admin' = 'admin',
	'dashboard' = 'dashboard',
	'dashboards' = 'dashboards',
}

export enum Intervals {
	Month = 'Month',
	Quarter = 'Quarter',
	Year = 'Year',
}

export enum YAxisType {
	Count = 'count',
	Percentage = 'percentage',
}

export enum IndexType {
	Indexed = 'indexed',
	Percentage = 'percentage',
}

export interface ISegment {
	html: string;
	text: string;
	tokens: {
		text: string;
		color: string;
	}[];
}
export interface ISegmentData {
	active: boolean;
	selected: boolean;
	color: string;
	label: string;
	segment: ISegment;
	series: any[];
	isEqualityIndex?: boolean;
	isAttritionIndex?: boolean;
	pointInTime: {
		date: Date;
		subAnalyses: {
			label: string;
			value: string;
			unit: any;
		}[];
	};
	navProps?: any;
}

export interface IReportData {
	data: ISegmentData[];
}

export interface ISubAnalysisField {
	[key: string]: {
		calculated: boolean;
		order: string;
		source: string;
		unit: units;
	};
}

export interface ISubAnalysisMapping {
	[key: string]: {
		config: {
			first: string;
			second: string;
			rate: string;
		};
	};
}

export interface KeyValuePair {
	[key: string]: string;
}

export interface TypedKeyValuePair<T> {
	[key: string]: T;
}

export interface AttributeValue {
	value: string;
	text: string;
	checked?: boolean;
}

export interface Attribute {
	attributeName: string;
	attributeValues: AttributeValue[];
	disabled?: boolean;
	removed?: boolean;
	active?: boolean;
	checked?: boolean;
}

export interface SegmentFilter {
	fieldName: string;
	fieldValues: string[];
}

export interface Segment {
	name: string;
	values: string[];
}

export interface FilterSegment {
	name: string;
	value?: string;
	values?: string[];
}

export interface SegmentName {
	name: string;
	disabled?: boolean;
}

export interface Field {
	field: string;
	values: string[];
}

export interface Group {
	groupKey: string;
	groupLabel: string;
	groupValues: string[];
}

export interface Fields {
	accountFields: Field[];
	customGroups: Group[];
}

export interface Labels {
	departments: {
		departmentId: string;
		departmentName: string;
		employeesCount: number;
	}[];
	jobTitles: {
		jobTitleId: string;
		jobTitleName: string;
	}[];
	ageRanges: string[];
}

export interface AnalysisName {
	name: string;
	checked?: boolean;
	leaf?: boolean;
	isFunnel?: boolean;
	isSubAnalysis?: boolean;
	source?: string;
	children?: AnalysisName[];
	parent?: AnalysisName;
	isLeaf?: boolean;
}

export type TimeInterval = keyof typeof Intervals;

declare global {
	var google: {
		accounts: {
			oauth2: {
				initTokenClient: ({
					client_id,
					scope,
					callback,
				}: {
					client_id: string;
					scope: string;
					callback: string | ((response: any) => void);
				}) => any;
			};
		};
	};
}
