import ActionTypes from '../constants/ActionTypes';
import { TabName } from './AddEvent/types';
import { IEvent } from './types';

export interface SortCriteria {
	column: string;
	ascending: boolean;
}

const defaultSortCriteria = {
	column: 'createdAt',
	ascending: false,
};

interface ITimeline {
	events: IEvent[];
	showAddEventModal: boolean;
	addEventModalTab: TabName;
	pending: boolean;
	error: any;
	sortCriteria: typeof defaultSortCriteria;
	infoModalOpen: boolean;
	toEdit?: any;
	toDelete?: any;
	eventToScrollTo?: any;
}

const initialState: ITimeline = {
	events: [],
	showAddEventModal: false,
	pending: false,
	error: null,
	addEventModalTab: 'Event Details',
	sortCriteria: defaultSortCriteria,
	infoModalOpen: false,
};

export default function timelineReducer(
	state: ITimeline = initialState,
	action: { type: string; payload: any }
): ITimeline {
	switch (action.type) {
		case ActionTypes.GetEventsPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.GetEventsFulfilled: {
			const events = action.payload;
			return {
				...state,
				events,
			};
		}
		case ActionTypes.GetEventsRejected: {
			return {
				...state,
				error: action.payload,
			};
		}
		case ActionTypes.SetEditEventPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.SetEditEventFulfilled: {
			const toEdit = action.payload;
			return {
				...state,
				toEdit,
			};
		}
		case ActionTypes.SetEditEventRejected: {
			return {
				...state,
				error: action.payload,
			};
		}
		case ActionTypes.EditEventPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.SetDeleteEvent: {
			const toDelete = action.payload;
			return {
				...state,
				toDelete,
			};
		}
		case ActionTypes.EditEventFulfilled: {
			const newEvents = action.payload;
			return {
				...state,
				events: newEvents,
				toEdit: undefined,
			};
		}
		case ActionTypes.EditEventRejected: {
			return {
				...state,
				error: action.payload,
				toEdit: undefined,
			};
		}
		case ActionTypes.DeleteEventPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.DeleteEventFulfilled: {
			const newEvents = action.payload;
			return {
				...state,
				events: newEvents,
				toDelete: undefined,
			};
		}
		case ActionTypes.DeleteEventRejected: {
			return {
				...state,
				error: action.payload,
				toDelete: undefined,
			};
		}
		case ActionTypes.AddEventPending: {
			return {
				...state,
				pending: true,
			};
		}
		case ActionTypes.AddEventFulfilled: {
			return {
				...state,
				events: [...state.events, action.payload],
			};
		}
		case ActionTypes.AddEventRejected: {
			return {
				...state,
				error: action.payload,
			};
		}
		case ActionTypes.ShowAddEventModal: {
			return {
				...state,
				showAddEventModal: true,
			};
		}
		case ActionTypes.CloseAddEventModal: {
			return {
				...state,
				showAddEventModal: false,
				toEdit: undefined,
			};
		}
		case ActionTypes.ShowAddEventTab: {
			return {
				...state,
				addEventModalTab: action.payload,
			};
		}
		case ActionTypes.SetEventToScrollTo: {
			return {
				...state,
				eventToScrollTo: action.payload,
			};
		}
		case ActionTypes.RemoveEventToScrollTo: {
			return {
				...state,
				eventToScrollTo: undefined,
			};
		}
		case ActionTypes.SetTimelineInfoModalOpen: {
			return {
				...state,
				infoModalOpen: action.payload,
			};
		}
		default: {
			return state;
		}
	}
}
