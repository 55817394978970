import React, { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import Overall from './Overall';
import Chart from './Chart';
import FieldNames from './FieldNames';
import FieldValues from './FieldValues';
import { CollectSummary, CollectAttribute } from './model';

interface CardProps {
	title: string;
	summary: CollectSummary;
	attributes: CollectAttribute[];
}

const defaultSortCriteria = {
	columnName: 'fieldName',
	ascending: true,
};

export default function Card({ title, summary, attributes }: CardProps) {
	const [sortCriteria, setSortCriteria] = useState(defaultSortCriteria);
	const [sortedAttributes, setSortedAttributes] = useState(attributes);

	useEffect(() => {
		const sortedData = orderBy(attributes, [sortCriteria.columnName], [sortCriteria.ascending ? 'asc' : 'desc']);
		setSortedAttributes(sortedData);
	}, [attributes, sortCriteria]);

	function handleSort(columnName: string) {
		let ascending = true;
		if (columnName === sortCriteria.columnName) {
			ascending = !sortCriteria.ascending;
		}
		setSortCriteria({
			columnName,
			ascending,
		});
	}

	return (
		<div className="collect-card">
			<div className="collect-card__title">{title}</div>
			<div className="collect-card__content">
				<Overall completion={summary.completionPercentage} />
				<Chart attributes={sortedAttributes} />
				<FieldNames attributes={sortedAttributes} sortCriteria={sortCriteria} onSort={handleSort} />
				<FieldValues attributes={sortedAttributes} sortCriteria={sortCriteria} onSort={handleSort} />
			</div>
		</div>
	);
}
