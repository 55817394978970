import { combineReducers } from 'redux';
import aiInsightsReducer from './aiInsights/reducer';
import chartReducer from './chart/reducer';
import detailReducer from './detail/reducer';
import filterReducer from './filter/reducer';
import searchesReducer from './searches/reducer';
import subtableReducer from './subtable/reducer';
import dashboardReportReducer from './dashboardReport/reducer';

export default combineReducers({
	filter: filterReducer,
	chart: chartReducer,
	subtable: subtableReducer,
	detail: detailReducer,
	searches: searchesReducer,
	aiInsights: aiInsightsReducer,
	dashboardReport: dashboardReportReducer,
});
