import { Menu } from 'antd';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import MixPanel from '../../../constants/MixPanel';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setToDelete } from '../../roles/reducer';

interface RolesMoreItemsProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	roleId: string;
}

const RolesMoreItems = ({ open, setOpen, roleId }: RolesMoreItemsProps) => {
	const dispatch = useAppDispatch();

	const { roles } = useAppSelector(state => state.admin);
	const history = useHistory();

	const handleEdit = () => {
		const role = roles.allRoles.find(role => role.id === roleId);
		if (!role) {
			console.warn("Role not found, can't edit");
			return;
		}
		track(MixPanel.Events.AdminRolesPermissionsClickEditRole, {
			id: role?.id,
			name: role?.name,
		});
		history.push(`/admin/edit-role/${role.id}`);
	};

	const handleDuplicate = () => {
		const role = roles.allRoles.find(role => role.id === roleId);
		if (!role) return;

		const initialName = `Copy of ${role?.name}`;

		track(MixPanel.Events.AdminRolesPermissionsClickDuplicateRole, {
			oldId: role?.id,
			oldName: role?.name,
			initialName: `Copy of ${role?.name}`,
		});
		history.push(`/admin/duplicate-role?copyRoleId=${role.id}&initialName=${initialName}`);
	};

	return (
		<Menu
			onMouseLeave={() => {
				if (setOpen) setOpen(false);
			}}
			className="widget-menu dashboards-menu"
			selectedKeys={[]}
		>
			<Fragment>
				<Menu.Item
					key="edit-modal"
					onClick={() => {
						handleEdit();
						setOpen(false);
					}}
				>
					Edit
				</Menu.Item>
				<Menu.Item
					key="duplicate"
					onClick={() => {
						handleDuplicate();
						setOpen(false);
					}}
				>
					Duplicate
				</Menu.Item>
				<Menu.Item
					key="delete-modal"
					onClick={() => {
						dispatch(setToDelete(roleId));
						setOpen(false);
					}}
				>
					Delete
				</Menu.Item>
			</Fragment>
		</Menu>
	);
};

export default RolesMoreItems;
