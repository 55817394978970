import qs from 'qs';
import ActionTypes from '../constants/ActionTypes';
import getTopLeftAction from '../lib/getTopLeftAction';
import modeSelector from '../lib/modeSelector';

function isIE() {
	const ua = window.navigator.userAgent;
	return ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
}

interface ReducerState {
	ie: boolean;
	mode: string;
	commentAccess: any;
	accountLoaded?: boolean;
	actorLoaded?: boolean;
	analysisLoaded?: boolean;
	eventsLoaded?: boolean;
	enterpriseIdCompleted?: boolean;
	winked?: boolean;
	lastRouterAction?: any;
	showInfoModal?: boolean;
	info?: any;
	scrollPosition?: number;
	topLeftAction: {
		type: 'default' | 'back' | 'exit';
		navigateTo?: string;
	};
}

export default function appStateReducer(
	state: ReducerState = {
		ie: isIE(),
		commentAccess: {},
		mode: '',
		topLeftAction: { type: 'default' },
	},
	action: any
): ReducerState {
	switch (action.type) {
		case ActionTypes.ChangeLocation:
			const { pathname, search } = action.payload.location;
			const query = qs.parse(search, {
				ignoreQueryPrefix: true,
			});

			if (query.preview) {
				sessionStorage.setItem('preview', query.preview as string);
			}

			const topLeftAction = getTopLeftAction(pathname, search);

			return {
				...state,
				lastRouterAction: action.payload.action,
				mode: modeSelector(pathname),
				topLeftAction,
			};
		case ActionTypes.SetTopLeftAction: {
			return {
				...state,
				topLeftAction: action.payload,
			};
		}
		case ActionTypes.OpenInfoModal:
			return {
				...state,
				showInfoModal: true,
				info: action.info,
			};
		case ActionTypes.CloseInfoModal:
			return {
				...state,
				showInfoModal: false,
			};
		case ActionTypes.SetScrollPosition:
			return {
				...state,
				scrollPosition: action.scrollPosition,
			};
		case ActionTypes.GetAccountFulfilled:
		case ActionTypes.GetAccountRejected:
			return {
				...state,
				accountLoaded: true,
			};
		case ActionTypes.GetActorInfoFulfilled:
		case ActionTypes.GetActorInfoRejected:
			return {
				...state,
				actorLoaded: true,
			};
		case ActionTypes.GetEventsRejected:
		case ActionTypes.GetEventsFulfilled:
			return {
				...state,
				eventsLoaded: true,
			};
		case ActionTypes.SetActorInfoFulfilled:
		case ActionTypes.SetActorInfoRejected:
		case ActionTypes.GetAnalysisInfoFulfilled:
		case ActionTypes.GetAnalysisInfoRejected:
			return {
				...state,
				analysisLoaded: true,
			};
		case ActionTypes.SignOutFulfilled:
			return {
				...state,
				accountLoaded: false,
				actorLoaded: false,
				analysisLoaded: false,
				eventsLoaded: false,
			};
		case ActionTypes.SetWinked:
			return {
				...state,
				winked: true,
			};
		case ActionTypes.SetEnterpriseId:
			return {
				...state,
				enterpriseIdCompleted: false,
			};
		case ActionTypes.SetEnterpriseIdComplete: {
			return {
				...state,
				enterpriseIdCompleted: true,
			};
		}
		default:
			return state;
	}
}
