import FileSaver from 'file-saver';
import { Base64 } from 'js-base64';
import getPeriod from '../../lib/getPeriod';
import getFormattedValue from '../../lib/getFormattedValue';
import CanvasFonts from '../../constants/CanvasFonts';
import loadImage from '../../lib/loadImage';
import { getComputedCssValue, getCssVariable, addStyle } from '../../lib/customColors';
import truncateHeader from '../../lib/truncateHeader';
import ChartTypes from '../../constants/ChartTypes';
import setupExportViewport from '../../lib/setupExportViewport';
import removeAnimations from '../../lib/removeAnimations';
import { toPng } from 'html-to-image';

let chartWidth = 1000;
let chartHeight;
const xPadding = 16;
const padding = 24;
const yChart = 2 * padding + 24;
const rowHeight = 28;
const yText = 19;
const colWidth = 135;

function drawTable(context, lifecycle, analysisType, columnProps, data, currentIndex, svgWidth, svgHeight) {
	const yTable = 3 * padding + 36 + svgHeight;
	context.fillStyle = getComputedCssValue('--color-ui-100');
	context.font = CanvasFonts.Regular14;
	context.fillText('Segment', xPadding, yTable + yText);
	context.fillStyle = getComputedCssValue('--color-ui-50');

	columnProps.forEach((columnProp, i) => {
		context.fillText(truncateHeader(columnProp.header, 18), xPadding + (i + 2) * colWidth, yTable + yText);
	});
	data.forEach((dataItem, index) => {
		const odd = index % 2 === 0;
		if (odd) {
			context.fillStyle = getComputedCssValue('--color-shade-3');
			context.fillRect(0, yTable + rowHeight + index * rowHeight, chartWidth, rowHeight);
		}
		if (dataItem.isEqualityIndex) {
			context.fillStyle = getComputedCssValue('--color-ui-100');
			context.fillText('Equity Index', xPadding, yTable + rowHeight * (index + 1) + yText);
		} else if (dataItem.isAttritionIndex) {
			context.fillStyle = getComputedCssValue('--color-ui-100');
			context.fillText('Attrition Index', xPadding, yTable + rowHeight * (index + 1) + yText);
		} else {
			let xStart = xPadding;
			dataItem.segment.tokens.forEach(s => {
				if (typeof s === 'object') {
					context.beginPath();
					context.arc(xStart + 2, yTable + rowHeight * (index + 1) + rowHeight / 2, 2, 0, 2 * Math.PI);
					context.fillStyle = getComputedCssValue(getCssVariable(s.color));
					context.fill();

					context.fillStyle = getComputedCssValue('--color-ui-100');
					context.fillText(s.text, xStart + 16, yTable + rowHeight * (index + 1) + yText);
					xStart += context.measureText(s.text).width + 21;
				} else {
					context.fillStyle = getComputedCssValue('--color-ui-100');
					context.fillText(s, xStart, yTable + rowHeight * (index + 1) + yText);
					xStart += context.measureText(s).width + 5;
				}
			});
		}
		context.fillStyle = getComputedCssValue('--color-ui-100');
		const currentItem = dataItem.series[currentIndex];

		columnProps.forEach((columnProp, i) => {
			let val = currentItem[columnProp.source];
			if (typeof val === 'undefined') {
				// val is undefined for employees in equity index / attrition index
				val = '';
			}
			if (i === 0) {
				val = getFormattedValue(analysisType, val);
			}
			context.fillText(val, xPadding + (i + 2) * colWidth, yTable + rowHeight * (index + 1) + yText);
		});
	});
}

export default function exportChart({
	lifecycle,
	analysisType,
	columnProps,
	data,
	dates,
	currentIndex,
	interval,
	chartType,
	nodeClass = '.explore__content',
	cb,
}) {
	if (
		chartType === ChartTypes.ArithmeticTable ||
		chartType === ChartTypes.Funnel ||
		chartType === ChartTypes.Stats ||
		chartType === ChartTypes.Line ||
		chartType === ChartTypes.Bar ||
		chartType === ChartTypes.Pie ||
		chartType === ChartTypes.HorizontalBar
	) {
		const { node, viewport } = setupExportViewport(nodeClass);

		if (!node) {
			console.warn('No node found for export');
			return;
		}

		// adjust styles for export
		Array.from(node.children).forEach(child => {
			child.style.marginLeft = '0px';
			child.style.marginRight = '0px';
		});

		// make it static
		removeAnimations(node);

		switch (chartType) {
			case ChartTypes.ArithmeticTable: {
				let overflow = 0;

				const rightTable = node.querySelector('.generic-table__right-table');
				const innerTable = rightTable.querySelector('table');

				if (innerTable.offsetWidth > rightTable.offsetWidth) {
					overflow = innerTable.offsetWidth - rightTable.offsetWidth;
				}

				node.style.width = node.offsetWidth + overflow + 'px';
				break;
			}
			case ChartTypes.Funnel: {
				let overflow = 0;
				const graphContainer = node.querySelector('[data-export-type="funnel"]');

				const startingHeight =
					getComputedStyle(graphContainer).getPropertyValue('--chartHeight').replace('px', '') * 1;

				if (graphContainer.scrollHeight > graphContainer.clientHeight) {
					overflow = graphContainer.scrollHeight - graphContainer.clientHeight;
				}

				node.style.width = '1200px';
				graphContainer.style = `--chartWidth: ${1200 - 32}px;--chartHeight: ${
					startingHeight + overflow
				}px;height: ${startingHeight + overflow}px;`;
				break;
			}

			case ChartTypes.Line: {
				node.style.width = node.querySelector('[data-export-type="linechart"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.Bar: {
				node.style.width = node.querySelector('[data-export-type="barchart"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.Pie: {
				node.style.width = node.querySelector('[data-export-type="piechart"]').width.baseVal.value + 'px';
				break;
			}
			case ChartTypes.HorizontalBar: {
				node.style.width = '1200px';
				break;
			}

			default:
				break;
		}

		const exludeClasses = [
			'chart-header__icons',
			'comment-bar',
			'scroll',
			'explore__detail',
			'explore-interval-section',
			'explore-settings-panel',
			'explore-chart-header',
		];

		toPng(node, {
			filter: node => (node.classList && exludeClasses.some(c => node.classList.contains(c)) ? false : true),
		}).then(blob => {
			FileSaver.saveAs(blob, 'chart.png');

			// clean up
			viewport.removeChild(node);
		});
	} else {
		const svgElem = document.getElementById('explore-chart');
		let clonedSvg = svgElem.cloneNode(true);

		const canvas = document.createElement('canvas');
		let svgWidth = parseInt(svgElem.getAttribute('width'));
		chartWidth = Math.max(svgWidth, chartWidth);
		canvas.width = chartWidth;
		const svgHeight = parseInt(svgElem.getAttribute('height'));
		const benchmarkElem = document.getElementById('explore-chart-benchmark');
		let clonedBenchmark;

		let benchmarkWidth;
		if (benchmarkElem) {
			clonedBenchmark = benchmarkElem.cloneNode(true);
			benchmarkWidth = parseInt(benchmarkElem.getAttribute('width'));
			const xScale = (chartWidth - xPadding) / (svgWidth + benchmarkWidth);
			svgWidth = xScale * svgWidth;
			benchmarkWidth = xScale * benchmarkWidth;
			chartHeight = svgHeight * xScale;
		} else {
			chartHeight = (svgHeight / svgWidth) * chartWidth;
		}
		canvas.height = yChart + chartHeight + padding * 3 + rowHeight * (data.length + 1);

		const context = canvas.getContext('2d');
		context.fillStyle = getComputedCssValue('--color-shade-h3');
		context.fillRect(0, 0, canvas.width, canvas.height);

		context.fillStyle = getComputedCssValue('--color-ui-100');
		context.font = CanvasFonts.Medium24;
		context.fillText(lifecycle, xPadding, padding + 24);

		let displaySegmentInHeader = false;
		let segment;
		const segmentData = data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
		if (segmentData.length === 1) {
			displaySegmentInHeader = true;
			segment = segmentData[0].segment;
		}

		let xPos = xPadding + context.measureText(lifecycle).width;
		if (displaySegmentInHeader) {
			context.fillText(' - ', xPos, padding + 24);
			xPos += context.measureText(' - ').width;
			segment.tokens.forEach(token => {
				if (typeof token === 'object') {
					context.fillStyle = getComputedCssValue(getCssVariable(token.color));
					context.fillText(token.text, xPos, padding + 24);
					xPos += context.measureText(token.text + ' ').width;
				} else {
					context.fillStyle = getComputedCssValue('--color-ui-100');
					context.fillText(token, xPos, padding + 24);
					xPos += context.measureText(token + ' ').width;
				}
			});
		}

		drawTable(context, lifecycle, analysisType, columnProps, data, currentIndex, chartWidth, chartHeight);

		addStyle(svgElem.childNodes, clonedSvg.childNodes);

		const serializer = new XMLSerializer();
		const svgString = serializer.serializeToString(clonedSvg);
		loadImage('data:image/svg+xml;base64,' + Base64.encode(svgString))
			.then(img => {
				context.drawImage(img, 0, yChart, svgWidth, chartHeight);
				if (benchmarkElem) {
					addStyle(benchmarkElem.childNodes, clonedBenchmark.childNodes);
					const benchmarkString = serializer.serializeToString(clonedBenchmark);
					return loadImage('data:image/svg+xml;base64,' + Base64.encode(benchmarkString));
				}
			})
			.then(img => {
				if (benchmarkElem) {
					context.drawImage(img, svgWidth + xPadding, yChart, benchmarkWidth, chartHeight);
				}
				return loadImage('/images/watermark.svg');
			})
			.then(img => {
				context.drawImage(img, canvas.width - padding - img.width, canvas.height - padding - img.height);
				canvas.toBlob(blob => {
					const period = getPeriod(dates[currentIndex], interval);
					let fileName = lifecycle;
					if (displaySegmentInHeader) {
						fileName += ' - ' + segment.text;
					}
					FileSaver.saveAs(blob, `${fileName} ${period}.png`);
					if (cb) {
						cb();
					}
				});
			});
	}
}
