import React, { useEffect } from 'react';
import Logos from '../../constants/Logos';
import Textbar from './Textbar';
import { fetchIntegrations } from './actions';
import Loader from '../../lottie/graph-loader.json';
import LottieAnimation from '../../common/LottieAnimation';
import WithPermissions from '../../common/WithPermissions';
import Sync from '../AdminHeader/Sync';
import RbacActions from '../../constants/RbacActions';
import callIfAllowed from '../../lib/callIfAllowed';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

function Integrations() {
	const dispatch = useAppDispatch();
	const { staticPermissions } = useAppSelector(state => state.auth);
	const { allGroups } = useAppSelector(state => state.admin.groups);
	const { hr, sso } = useAppSelector(state => state.admin.integrations);

	useEffect(() => {
		callIfAllowed({
			actions: [RbacActions['Integrations/View']],
			cb: () => dispatch(fetchIntegrations()),
			staticPermissions,
		});
	}, []);

	return (
		<WithPermissions actions={[RbacActions['Integrations/View']]} showMessage={true}>
			<div className="integrations">
				<div className="integrations__list">
					<div className="integrations__hr">
						<h3 className="integrations__hr__header">HR Sources</h3>
						{hr.pending ? (
							<div className="integrations__pending">
								<LottieAnimation
									autoplay={true}
									loop={true}
									width={60}
									height={60}
									animation={Loader}
								></LottieAnimation>
							</div>
						) : (
							hr.data.map(i => (
								<div key={i.integrationName} className="integration integration--hr">
									<div className="integration__logo">
										<img src={Logos[i.integrationName]} alt={i.integrationName} />
									</div>
									<div className="integration__label">Name</div>
									<div className="integration__value">{i.integrationName}</div>
									<div className="integration__label">Date connected</div>
									<div className="integration__value">{i.dateConnected}</div>
									<div className="integration__label">Last data sync</div>
									<div className="integration__value">{i.lastDataSync}</div>
								</div>
							))
						)}
					</div>
					<div className="integrations__sso">
						<h3
							style={{
								color: 'var(--color-ui-100)',
							}}
						>
							SSO
						</h3>
						{sso.data.map(ssoItem => (
							<div key={ssoItem.id} className="integration integration--sso">
								<div className="integration__label">ID provider</div>
								<div className="integration__value">{ssoItem.name}</div>
								<div className="integration__label">Domain</div>
								<div className="integration__value">{ssoItem.domain}</div>
							</div>
						))}
						{sso.data.length === 0 && (
							<div className="integrations__empty">
								Please contact your customer support representative to configure your single sign-on
								setup.
							</div>
						)}
					</div>
					<div className="integrations__sso">
						<h3
							style={{
								color: 'var(--color-ui-100)',
							}}
						>
							Users & Groups
						</h3>
						{!allGroups || allGroups.length === 0 ? (
							<div
								style={{
									padding: '1.5rem 0',
								}}
							>
								Contact your customer support representative to set up your identity provider
								integration
							</div>
						) : (
							<React.Fragment>
								{sso.data.map(ssoItem => (
									<div key={ssoItem.id} className="integration integration--sso">
										<div className="integration__label">ID provider</div>
										<div className="integration__value">{ssoItem.name}</div>
										<div className="integration__label">Domain</div>
										<div className="integration__value">{ssoItem.domain}</div>
										<div className="integration__button">
											<Sync />
										</div>
									</div>
								))}
							</React.Fragment>
						)}
						{sso.data.length === 0 && (
							<div className="integrations__empty">
								Please contact your customer support representative to configure your single sign-on
								setup.
							</div>
						)}
					</div>
				</div>
				<div className="integrations__copy">
					<Textbar />
				</div>
			</div>
		</WithPermissions>
	);
}

export default Integrations;
