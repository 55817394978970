import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/md';

import OpenText from '../common/OpenText';
import { track } from '../lib/segment';
import MixPanel from '../constants/MixPanel';
import HeaderWrapper from '../common/HeaderWrapper';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import SecondaryHeader from './SecondaryHeader';
import { setSearchText, showCreateDashboardModal, showDashboardListView, showDashboardTilesView } from './actions';

function Header({
	searchText,
	setSearchText,
	showCreateDashboardModal,
	showDashboardListView,
	showDashboardTilesView,
}) {
	function handleClose() {
		track(MixPanel.Events.MyDashboardsSearchClear);
	}

	return (
		<WithPermissions
			actions={[
				RbacActions['Dashboards/ViewAll'],
				RbacActions['Dashboards/Edit'],
				RbacActions['Dashboards/ViewShared'],
			]}
		>
			<HeaderWrapper>
				<div className="search-header">
					<OpenText
						value={searchText}
						isSearch={true}
						onChange={setSearchText}
						placeholder="Search"
						autoFocus={false}
						icon={<MdSearch className="search-header__icon" size={20} color="rgba(242, 242, 242, 0.5)" />}
						paddingLeft={24}
						showCloseAlways
						onClose={handleClose}
						style={{ maxWidth: 'calc(50vw - 3.5rem)' }}
					/>
				</div>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<SecondaryHeader
						showCreateDashboardModal={showCreateDashboardModal}
						showDashboardListView={showDashboardListView}
						showDashboardTilesView={showDashboardTilesView}
					/>
				</WithPermissions>
			</HeaderWrapper>
		</WithPermissions>
	);
}

Header.propTypes = {
	searchText: PropTypes.string,
	setSearchText: PropTypes.func,
	showCreateDashboardModal: PropTypes.func,
	showDashboardListView: PropTypes.func,
	showDashboardTilesView: PropTypes.func,
};

Header.defaultProps = {};

function mapState(state) {
	let searchText = state.myDashboards.searchText;
	if (state.router.location.pathname === '/shared') {
		searchText = state.shared.searchText;
	}

	return {
		searchText,
	};
}

const dispatchProps = {
	setSearchText,
	showCreateDashboardModal,
	showDashboardListView,
	showDashboardTilesView,
};

export default connect(mapState, dispatchProps)(Header);
