import { SVGProps, useContext, useEffect, useRef } from 'react';

import { InsightsContext } from '.';
import MixPanel from '../../constants/MixPanel';
import cn from '../../lib/cn';
import { track } from '../../lib/segment';

export default function ResizeHandle() {
	const ref = useRef<any>();
	const { setModalWidth, setModalHeight, modalRef, isMinimized, modalMaxHeight } = useContext(InsightsContext);

	useEffect(() => {
		if (!ref.current || !modalRef.current || isMinimized) return;

		let isResizing = false;

		const handleMouseDown = (e: any) => {
			isResizing = true;
			document.body.classList.add('no-select');

			const initialMouseX = e.clientX;
			const initialMouseY = e.clientY;

			const initialWidth = modalRef?.current.offsetWidth;
			const initialHeight = modalRef?.current.offsetHeight;

			function resize(e: any) {
				if (isResizing) {
					const newWidth = initialWidth - (e.clientX - initialMouseX);
					const newHeight = initialHeight + (e.clientY - initialMouseY);

					setModalWidth(Math.max(340, Math.min(newWidth, 800)));
					setModalHeight(Math.min(modalMaxHeight, newHeight));
				}
			}

			function stopResize() {
				isResizing = false;
				track(MixPanel.Events.AIModalClickResizeModal);
				document.body.classList.remove('no-select');
				window.removeEventListener('mousemove', resize);
				window.removeEventListener('mouseup', stopResize);
			}

			window.addEventListener('mousemove', resize);
			window.addEventListener('mouseup', stopResize);
		};

		ref.current.addEventListener('mousedown', handleMouseDown);

		return () => {
			ref.current?.removeEventListener('mousedown', handleMouseDown);
		};
	}, [isMinimized, modalMaxHeight]);

	return (
		<div
			ref={ref}
			className={cn('absolute bottom-0 left-0 px-[1.6rem] py-[.8rem]', isMinimized && 'hidden')}
			style={{
				cursor: 'sw-resize',
			}}
		>
			<Rectangle />
		</div>
	);
}

const Rectangle = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} viewBox="0 0 8 8" fill="none" {...props}>
		<path fill="currentColor" d="m0 0 4 4 4 4H0V0Z" />
	</svg>
);
