import { Category } from '../../../datastudio/survey/category/types';
import { ReminderEmail, Survey } from '../list/types';

export interface Step {
	label: string;
	validated: boolean;
	disabled: boolean;
}

export enum CadenceRunType {
	'one_time' = 'ONE_TIME',
	'n_days' = 'EVERY_N_DAYS',
	'weekly' = 'WEEKLY',
	'monthly' = 'MONTHLY',
	'quarterly' = 'QUARTERLY',
	'yearly' = 'YEARLY',
}

export enum Privacy {
	'anonymous' = 'ANONYMOUS',
	'dandi' = 'DANDI_ONLY',
	'dandi&client' = 'DANDI_AND_CLIENT',
}

export enum Storage {
	'london' = 'LONDON',
	'frankfurt' = 'FRANKFURT',
	'iowa' = 'IOWA',
}

// rename questions -> globalQuestionIds
export interface ReconstructedCategory extends Omit<Category, 'questions'> {
	globalQuestionIds: Category['questions'];
}

export interface ISnapshotSurveyEditState {
	pending?: boolean;
	finished?: boolean;
	error?: string;
	selectedIndex: number;
	isBeginning: boolean;
	isEnd: boolean;
	validated: boolean;
	emailMappingId?: string;
	steps: Step[];
	distributionType?: 'link' | 'email';
	mode: 'create' | 'edit';
	title: string;
	description: string;
	enterpriseId: string;
	globalSurveyId: string;
	surveyId?: string;
	audienceId?: string;
	isPublished?: boolean;
	link: string;
	password: string;
	cadenceRunType?: CadenceRunType;
	cadenceRunEachNDays?: number;
	privacy?: Privacy;
	storage?: Storage;
	endDate?: string;
	score: number;
	categories: ReconstructedCategory[];
	emailSubject: string;
	emailTitle?: string;
	emailBody?: string;
	welcomeSubject: string;
	welcomeBody?: string;
	thankYouSubject: string;
	thankYouBody?: string;
	thankImage?: string;
	questionOrder?: string;
	reminderEmailSubject?: string;
	reminderEmailTitle?: string;
	reminderEmailBody?: string;
}

export interface ISnapshotSurveyCreateProps {
	title: string;
	description: string;
	enterpriseId: string;
	globalSurveyId: string;
	audienceId?: string;
	cadenceRunType: CadenceRunType;
	cadenceRunEachNDays?: number;
	emailMappingId?: string;
	privacy: Privacy;
	storage: Storage;
	endDate: string;
	categories: ReconstructedCategory[];
	uiElements: any[];
	isPrivate: boolean;
	isPublished: boolean;
}

export interface ISnapshotSurveyUpdateProps {
	surveyIdOrGlobalSurveyId: string;
	title: string;
	description: string;
	enterpriseId: string;
	audienceId?: string;
	cadenceRunType: CadenceRunType;
	cadenceRunEachNDays?: number;
	emailMappingId?: string;
	privacy: Privacy;
	storage: Storage;
	endDate: string;
	categories: ReconstructedCategory[];
	uiElements: any[];
	isPrivate: boolean;
	isPublished: boolean;
}

export interface ISnapshotSurveyDeleteProps {
	surveyIdOrGlobalSurveyId: string;
	enterpriseId: string;
}

export interface ISnapshotSurveyPublishProps {
	surveyIdOrGlobalSurveyId: string;
	enterpriseId?: string;
	isPublished: boolean;
}

export interface ISendTestEmailsProps {
	surveyId: string;
	enterpriseId: string;
	emails: string[];
}

export interface IconConfig {
	icon: any;
	color: string;
	backgroundColor: string;
	secondaryColor?: string;
	width?: number;
	height?: number;
}

export interface ISnapshotSurveyArchiveProps {
	survey: Survey;
	shouldArchive: boolean;
}

export interface ISnapshotSurveyUpdateWithReminderProps {
	survey: Survey;
	reminderEmail: ReminderEmail;
}
