import { CadenceRunType, Privacy, Storage } from './edit/types';

import AnonymousPerson from '../../icons/AnonymousPerson';
import Link from '../../icons/Chain';
import Document from '../../icons/Document';
import Mail from '../../icons/Mail';
import Shield from '../../icons/Shield';

export const privacyOptions = [
	{
		label: 'Anonymous to all',
		description: 'No personally identifiable information (PII) is captured.',
		id: Privacy.anonymous,
		color: '#FF9133',
		backgroundColor: '#FFE99A',
		secondaryColor: '#FFE99A',
		icon: AnonymousPerson,
		width: 88,
		height: 88,
	},
	{
		label: 'Anonymous to employer',
		description: 'PII is captured, but is only readable by Dandi.',
		id: Privacy.dandi,
		color: '#C050F5',
		backgroundColor: '#F9D2FF',
		width: 88,
		height: 88,
		icon: Shield,
	},

	{
		label: 'Readable by employer',
		description: `Both Dandi and the employer are able to view PII.`,
		id: Privacy['dandi&client'],
		color: '#20B049',
		backgroundColor: '#BCFF9C',
		width: 88,
		height: 88,
		icon: Document,
	},
];

export const storageOptions = [
	{
		title: 'US',
		description: 'Employee responses are stored in the United States.',
		id: Storage.iowa,
	},
	{
		title: 'EU',
		description: 'Employee responses are stored in the European Union.',
		id: Storage.frankfurt,
	},
	{
		title: 'UK',
		description: 'Employee responses are stored in the United Kingdom.',
		id: Storage.london,
	},
];

export const distributionOptions = [
	{
		title: 'Distribute campaign via email',
		description: 'Dandi will email the campaign to every member of your created audience.',
		id: 'email',
		dependencies: [Privacy.dandi, Privacy['dandi&client']],
		iconConfig: {
			icon: Mail,
			color: '#335AFF',
			backgroundColor: '#A4F0F4',
			width: 56,
			height: 56,
		},
	},
	{
		title: 'Distribute campaign via link',
		description: 'Dandi will generate a single URL for your campaign, which you can easily share.',
		id: 'link',
		dependencies: [Privacy.dandi, Privacy['dandi&client'], Privacy.anonymous],
		iconConfig: {
			icon: Link,
			color: '#B0197D',
			backgroundColor: '#FD8ED7',
			width: 58,
			height: 58,
		},
	},
];

export const cadenceOptions = [
	{
		id: CadenceRunType.one_time,
		label: 'One time',
	},
	{
		id: CadenceRunType.weekly,
		label: 'Weekly',
	},
	{
		id: CadenceRunType.monthly,
		label: 'Monthly',
	},
	{
		id: CadenceRunType.quarterly,
		label: 'Quarterly',
	},
	{
		id: CadenceRunType.yearly,
		label: 'Yearly',
	},
	{
		id: CadenceRunType.n_days,
		label: 'Custom',
	},
];
