const chartColors = [
	'var(--color-graph-1)',
	'var(--color-graph-2)',
	'var(--color-graph-3)',
	'var(--color-graph-4)',
	'var(--color-graph-5)',
	'var(--color-graph-6)',
	'var(--color-graph-7)',
	'var(--color-graph-8)',
	'var(--color-graph-9)',
	'var(--color-graph-10)',
	'var(--color-graph-11)',
	'var(--color-graph-12)',
	'var(--color-graph-13)',
	'var(--color-graph-14)',
	'var(--color-graph-15)',
	'var(--color-graph-16)',
];

export default chartColors;
