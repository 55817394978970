import React from 'react';
import Stack from '../../../../common/Stack';
import TextArea from '../../../../common/TextArea';
import TextField from '../../../../common/TextField';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MessageBox, MessageBoxArea } from '../../../../common/MessageBox';
import {
	setEmailBody,
	setEmailTitle,
	setEmailSubject,
	setThankYouBody,
	setThankYouSubject,
	setWelcomeBody,
	setWelcomeSubject,
} from '../slice';

const Messaging = () => {
	const {
		emailSubject,
		emailTitle,
		emailBody,
		welcomeSubject,
		welcomeBody,
		thankYouSubject,
		thankYouBody,
		distributionType,
	} = useAppSelector(state => state.snapshotSurveys.edit);
	const dispatch = useAppDispatch();

	return (
		<Stack gap="3.2rem">
			{distributionType === 'email' && (
				<React.Fragment>
					<Stack gap="1.6rem">
						<div
							style={{
								paddingLeft: '1.6rem',
							}}
						>
							Email message
						</div>
						<MessageBox
							label="Subject"
							value={emailSubject}
							onChange={e => dispatch(setEmailSubject(e.target.value))}
						/>
						<MessageBoxArea
							label="Title"
							value={emailTitle || ''}
							onChange={e => dispatch(setEmailTitle(e.target.value))}
						/>
						<MessageBoxArea
							label="Text (optional)"
							value={emailBody || ''}
							onChange={e => dispatch(setEmailBody(e.target.value))}
						/>
					</Stack>
					<Divider />
				</React.Fragment>
			)}
			<Stack gap="1.6rem">
				<div
					style={{
						paddingLeft: '1.6rem',
					}}
				>
					Welcome message
				</div>
				<MessageBox
					label="Subject"
					value={welcomeSubject}
					onChange={e => dispatch(setWelcomeSubject(e.target.value))}
				/>
				<MessageBoxArea
					label="Text (optional)"
					value={welcomeBody || ''}
					onChange={e => dispatch(setWelcomeBody(e.target.value))}
				/>
			</Stack>
			<Divider />
			<Stack gap="1.6rem">
				<div
					style={{
						paddingLeft: '1.6rem',
					}}
				>
					Thank you message
				</div>
				<MessageBox
					label="Subject"
					value={thankYouSubject}
					onChange={e => dispatch(setThankYouSubject(e.target.value))}
				/>
				<MessageBoxArea
					label="Text (optional)"
					value={thankYouBody || ''}
					onChange={e => dispatch(setThankYouBody(e.target.value))}
				/>
			</Stack>
		</Stack>
	);
};

const Divider = () => {
	return (
		<div
			style={{
				width: '100%',
				height: '1px',
				backgroundColor: 'var(--color-ui-10)',
			}}
		/>
	);
};

export default Messaging;
