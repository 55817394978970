import React from 'react';
import PropTypes from 'prop-types';
import cn from '../lib/cn';

export default function ListItem({ type, onClick, activated, children, className }: any) {
	return (
		<div
			onClick={onClick}
			className={cn('list-item', `list-item--${type}`, activated && `list-item--${type}--activated`, className)}
		>
			{children}
		</div>
	);
}

ListItem.propTypes = {
	onClick: PropTypes.func,
	type: PropTypes.string,
	activated: PropTypes.bool,
	children: PropTypes.node,
};
