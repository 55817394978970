import { forwardRef, useRef } from 'react';
import './styles.scss';

const InputField = forwardRef(
	(
		{
			type,
			value,
			onChange,
			...rest
		}: {
			type: string;
			value: number | string;
			onChange: any;
			[key: string]: any;
		},
		ref: any
	) => {
		const ref_ = ref || useRef<HTMLInputElement>(null);
		const valueAsString = String(value);
		return (
			<input
				ref={ref_}
				className="campaign-input-field"
				type={type}
				value={valueAsString}
				onChange={onChange}
				{...rest}
			/>
		);
	}
);

export default InputField;
