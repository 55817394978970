import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import LottieAnimation from '../../common/LottieAnimation';
import ScrollArrows from '../../common/ScrollArrows';
import useTableScroll from '../../common/useTableScroll';
import Loader from '../../lottie/graph-loader.json';
import AttrTable from './AttrTable';
import ValueTable from './ValueTable';

export default function Table(props) {
	const {
		lifecycle,
		analysisType,
		employeeAttribute,
		attributeName,
		employeeAttributes,
		summary,
		groupSummary,
		showGroupSummary,
		pending,
		emptyState,
		navigateToDetail,
		expanded,
		toggleExpansion,
		onSort,
		sortCriteria,
		onHover,
		onHoverEnd,
		onToggle,
		hoveredItem,
		selection,
		status,
		data,
		diversityAttributes,
		disableLinks,
		columnProps,
	} = props;

	const [showLeft, showRight, paddingTop, containerRef, tableRef] = useTableScroll(!pending && !emptyState, expanded);
	const headerRef = useRef();
	const [headerHeight, setHeaderHeight] = useState();
	const tableActive = !!hoveredItem || !!selection?.length;

	useEffect(() => {
		if (headerRef.current) {
			setHeaderHeight(parseInt(headerRef.current.offsetHeight));
		}
	}, [headerRef.current]);

	if (pending) {
		return (
			<div className="attr-table__spinner">
				<LottieAnimation
					animation={Loader}
					autoplay={true}
					loop={true}
					width={60}
					height={60}
				></LottieAnimation>
			</div>
		);
	}

	return (
		<div
			className={showLeft || showRight ? 'attr-table attr-table--extended' : 'attr-table'}
			style={{ visibility: headerHeight ? 'visible' : 'hidden' }}
			ref={containerRef}
		>
			<div className="attr-table__left">
				<AttrTable
					lifecycle={lifecycle}
					analysisType={analysisType}
					data={data}
					attributeName={attributeName}
					employeeAttributes={employeeAttributes}
					sortCriteria={sortCriteria}
					onSort={onSort}
					showGroupSummary={showGroupSummary}
					onHover={onHover}
					onHoverEnd={onHoverEnd}
					onToggle={onToggle}
					hoveredItem={hoveredItem}
					selection={selection}
					status={status}
					headerHeight={headerHeight}
					tableActive={tableActive}
				/>
			</div>
			<div className="attr-table__right" ref={tableRef}>
				<ValueTable
					data={data}
					summary={summary}
					groupSummary={groupSummary}
					lifecycle={lifecycle}
					analysisType={analysisType}
					employeeAttribute={employeeAttribute}
					attributeName={attributeName}
					employeeAttributes={employeeAttributes}
					diversityAttributes={diversityAttributes}
					sortCriteria={sortCriteria}
					onSort={onSort}
					navigateToDetail={navigateToDetail}
					showGroupSummary={showGroupSummary}
					expanded={expanded}
					toggleExpansion={toggleExpansion}
					headerRef={headerRef}
					headerHeight={headerHeight}
					disableLinks={disableLinks}
					tableActive={tableActive}
					columnProps={columnProps}
					hoveredItem={hoveredItem}
					selection={selection}
					status={status}
				/>
			</div>
			<ScrollArrows
				showLeft={showLeft}
				showRight={showRight}
				paddingTop={paddingTop}
				scrollRef={tableRef}
				scrollWidth={145}
			/>
		</div>
	);
}

Table.defaultProps = {
	navigateToDetail: () => {},
	toggleExpansion: () => {},
	onSort: () => {},
	onHover: () => {},
	onHoverEnd: () => {},
	onToggle: () => {},
};

Table.propTypes = {
	lifecycle: PropTypes.string,
	analysisType: PropTypes.string,
	employeeAttribute: PropTypes.string,
	attributeName: PropTypes.string,
	employeeAttributes: PropTypes.array,
	diversityAttributes: PropTypes.array,
	data: PropTypes.array,
	summary: PropTypes.object,
	rowFilter: PropTypes.array,
	columnFilter: PropTypes.array,
	pending: PropTypes.bool,
	emptyState: PropTypes.bool,
	navigateToDetail: PropTypes.func,
	expanded: PropTypes.bool,
	toggleExpansion: PropTypes.func,
	employeesAvailable: PropTypes.bool,
	showGroupSummary: PropTypes.bool,
	groupSummary: PropTypes.object,
	onSort: PropTypes.func,
	sortCriteria: PropTypes.object,
	disableLinks: PropTypes.bool,
	onHover: PropTypes.func,
	onHoverEnd: PropTypes.func,
	onToggle: PropTypes.func,
	hoveredItem: PropTypes.string,
	selection: PropTypes.array,
	status: PropTypes.string,
	columnProps: PropTypes.array,
};
