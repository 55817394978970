import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M12 10H9V0H7v10H4l4 4 4-4ZM0 16v2h16v-2H0Z" fill="currentColor" />
	</svg>
);

export default SvgComponent;
