import { Menu } from 'antd';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { showDeleteSearchModal, showEditSearchModal } from '../../../../actions';

interface MoreMenuProps {
	reportId: string;
	showDeleteSearchModal: (reportId: string) => void;
	showEditSearchModal: (reportId: string) => void;
	setMoreOpen?: (open: boolean) => void;
}

const MoreMenu = ({ reportId, showDeleteSearchModal, showEditSearchModal, setMoreOpen }: MoreMenuProps) => {
	const handleDelete = (e: any) => {
		e.domEvent.stopPropagation();
		showDeleteSearchModal(reportId);
	};

	const handleEdit = (e: any) => {
		e.domEvent.stopPropagation();
		showEditSearchModal(reportId);
	};

	return (
		<Menu
			onMouseLeave={() => {
				if (setMoreOpen) {
					setMoreOpen(false);
				}
			}}
			className="widget-menu dashboards-menu"
			selectedKeys={[]}
		>
			<Fragment>
				<Menu.Item key="edit-modal" onClick={handleEdit}>
					Rename
				</Menu.Item>
				<Menu.Item key="delete-modal" onClick={handleDelete}>
					Delete
				</Menu.Item>
			</Fragment>
		</Menu>
	);
};

const dispatchProps = {
	showDeleteSearchModal,
	showEditSearchModal,
};

export default connect(null, dispatchProps)(MoreMenu);
