import cn from '../lib/cn';

export default function ResizeOverlay({ isDragging }: { isDragging: boolean }) {
	return (
		<div
			className={cn(
				'fixed top-0 left-0 w-screen h-screen bg-transparent z-[9999]',
				isDragging ? 'pointer-events-all' : 'pointer-events-none'
			)}
		/>
	);
}
