import { UniqueIdentifier } from '@dnd-kit/core';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { ImperativePanelGroupHandle, Panel, PanelGroup } from 'react-resizable-panels';
import ErrorBoundary from '../common/ErrorBoundary';
import WithPermissions from '../common/WithPermissions';
import ChartTypes from '../constants/ChartTypes';
import mixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { addDefaultTextReport } from '../editor/buttons/actions';
import InsightsReportIcon from '../icons/InsightsReport';
import AddIcon from '../icons/Plus';
import TextReportIcon from '../icons/TextReport';
import ValidCheckIcon from '../icons/ValidCheck';
import cn from '../lib/cn';
import { track } from '../lib/segment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import ColumnResizer from './ColumnResizer';
import { DashboardLayoutContext, LayoutRow } from './DashboardLayout';
import DndColumn from './DndColumn';
import HelperGrid from './HelperGrid';
import { selectReport } from './aiInsights/reducer';
import { navigateToExplore } from './view/actions';
import ChartWrapper from './view/ChartWrapper';
import getOptions from './view/getOptions';

type ContainerProps = {
	id: string;
	items: UniqueIdentifier[];
	rowIndex: number;
	isLocked: boolean;
};

const TOTAL_COLUMNS = 12;

function generateOptions(configuration: any) {
	if (!configuration) {
		return;
	}
	let chartType = 'Line';
	let length = 2;
	const options = getOptions(configuration);
	if (configuration && configuration.length) {
		const chartTypeConfig = configuration.find((c: any) => c.name === 'chartType');
		if (chartTypeConfig) {
			chartType = chartTypeConfig.value;
		}
		const lengthConfig = configuration.find((c: any) => c.name === 'length');
		if (lengthConfig) {
			length = parseInt(lengthConfig.value);
		}
	}

	return {
		chartType,
		length,
		options: { ...options },
	};
}

function mapFromCurrentSizes(globalLayoutState: LayoutRow[], rowId: string) {
	const newSizes = new Map();

	const row = globalLayoutState.find((r: LayoutRow) => r.rowId === rowId);

	if (!row) {
		return newSizes;
	}

	row.columns.forEach((col: any) => {
		newSizes.set(col.id, col.columnWidthPerc);
	});

	return newSizes;
}

export default function Row({ id, items = [], rowIndex, isLocked }: ContainerProps) {
	const columnSize = 100 / TOTAL_COLUMNS;
	const { filteredReports, dashboard, globalLayoutState, setGlobalLayoutState, setShouldAutosave } =
		useContext(DashboardLayoutContext);

	const [isDragging, setIsDragging] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { hoverReportIds, hoverMode } = useAppSelector(state => state.dashboard.aiInsights);

	const panelGroupRef = useRef<ImperativePanelGroupHandle>(null);
	const itemsLengthRef = useRef<number>(0);

	const layoutMapRef = useRef<Map<UniqueIdentifier, number>>(mapFromCurrentSizes(globalLayoutState, id));

	const onLayout = (sizes: number[]) => {
		const layoutMap = layoutMapRef.current;
		items.forEach((item, index) => {
			const size = sizes[index];
			layoutMap.set(item, size);
		});

		setGlobalLayoutState((currentRows: LayoutRow[]) => {
			return currentRows.map((row: LayoutRow) => {
				if (row.rowId === id) {
					return {
						...row,
						columns: row.columns.map((col: any) => {
							return {
								...col,
								columnWidthPerc: layoutMap.get(col.id),
							};
						}),
					};
				} else {
					return row;
				}
			});
		});
	};

	useEffect(() => {
		const layoutMap = layoutMapRef.current;
		if (layoutMap.size === 0) {
			itemsLengthRef.current = items.length;
			return; // Initial render
		}

		const panelGroup = panelGroupRef.current;
		if (panelGroup) {
			const sizes = items.map(item => layoutMap.get(item)!);
			panelGroup.setLayout(sizes);
			// }
		}
	}, [items]);

	useEffect(() => {
		if (!isDragging) {
			const layoutMap = layoutMapRef.current;
			const panelGroup = panelGroupRef.current;

			if (panelGroup) {
				let sizes = items.map(item => layoutMap.get(item)!);
				let snappedGrid = [];

				if (sizes.length === 4) {
					snappedGrid = [25, 25, 25, 25];
				}

				let excessWidth = 0;

				sizes.map(size => {
					if (size < 25) {
						excessWidth += 25 - size;
						return 25;
					}
				});

				const adjustableWidth = 100 - excessWidth;

				if (excessWidth > 0) {
					const scaleFactor = (adjustableWidth - excessWidth) / adjustableWidth;

					sizes = sizes.map(size => {
						if (size > 25) {
							return Math.max(25, Math.round(size * scaleFactor));
						}
						return size;
					});
				}

				snappedGrid = sizes.map(size => Math.max(Math.round(size / columnSize) * columnSize, 25));
				panelGroup.setLayout(snappedGrid);
			}
		}
	}, [isDragging, items]);

	return (
		<div
			className={cn('w-full h-full flex flex-col relative')}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<HelperGrid isDragging={isDragging} />
			<PanelGroup
				direction="horizontal"
				onLayout={onLayout}
				ref={panelGroupRef}
				style={{ overflow: 'initial' }}
				id={id}
			>
				{items.map((item, index) => {
					const report = filteredReports.find((report: any) => report.reportId === item);

					const chartOptions = generateOptions(report?.configuration);

					return (
						<Fragment key={item}>
							<Panel
								id={`${item}`}
								order={index}
								minSize={25}
								defaultSize={layoutMapRef?.current?.get(item)}
								style={{
									overflow: 'initial',
									minWidth: 0,
									zIndex: 0,
								}}
							>
								<DndColumn
									id={item}
									draggingFullContainer={chartOptions?.chartType === ChartTypes.Text}
									disabled={isLocked}
								>
									<ErrorBoundary className="grid__error">
										{report?.reportId ? (
											<DimmerOverlay
												active={hoverMode && !hoverReportIds.includes(report.reportId)}
											>
												<SelectionOverlay
													reportId={report.reportId}
													disabled={
														chartOptions?.chartType === ChartTypes.Text ||
														chartOptions?.chartType === ChartTypes.InsightsText
													}
												>
													<ChartWrapper
														dashboard={dashboard}
														dashboardReport={report}
														length={length}
														colWidth={columnSize}
														isLocked={isLocked}
													/>
												</SelectionOverlay>
											</DimmerOverlay>
										) : (
											<div />
										)}
									</ErrorBoundary>
								</DndColumn>
							</Panel>
							{index !== items.length - 1 && (
								<ColumnResizer
									disabled={isLocked}
									onDragging={(dragging: boolean) => {
										setIsDragging(dragging);
										if (dragging) {
											setShouldAutosave(true);
										}
									}}
									id={`${id}-${item}-resizer`}
								/>
							)}
						</Fragment>
					);
				})}
			</PanelGroup>
			{items.length < 4 && !isLocked && (
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<AddReport rowIndex={rowIndex} isVisible={isHovered} />
				</WithPermissions>
			)}
		</div>
	);
}

const DimmerOverlay = ({ children, active }: any) => {
	return (
		<div
			className={cn(
				'absolute inset-0',
				active ? 'opacity-10' : 'opacity-100',
				'transition-opacity duration-300 linear'
			)}
		>
			{children}
		</div>
	);
};

const SelectionOverlay = ({
	children,
	reportId,
	disabled,
}: {
	children: React.ReactNode;
	reportId: string;
	disabled?: boolean;
}) => {
	const dispatch = useAppDispatch();
	const { reportSelectMode, reportIds } = useAppSelector(state => state.dashboard.aiInsights);

	const isSelected = reportIds.includes(reportId);
	const isDisabled = disabled || !reportSelectMode;

	return (
		<div
			className={cn(
				reportSelectMode ? 'block' : 'contents pointer-events-none',
				'relative  w-full h-full ',
				disabled && reportSelectMode ? 'cursor-not-allowed' : reportSelectMode ? 'cursor-pointer' : ''
			)}
			onClick={() => (isDisabled ? null : dispatch(selectReport(reportId)))}
		>
			<div className={cn(reportSelectMode ? 'block' : 'contents', 'absolute inset-0 pointer-events-auto')}>
				<div
					className={cn(
						'absolute inset-0 w-full h-full flex items-center justify-center z-[100]',
						'after:content-[""] after:absolute after:inset-0 after:bg-shade-h3 after:opacity-75 after:z-[0]',
						reportSelectMode ? 'flex' : 'hidden'
					)}
				>
					<div className={cn('z-[10]', isDisabled && 'hidden')}>
						{isSelected ? (
							<div className={cn(' w-[36px] h-[36px] text-graph-1')}>
								<ValidCheckIcon width="100%" height="100%" />
							</div>
						) : (
							<div
								className={cn(
									'bg-shade-h4  rounded-[20px] h-[36px] px-[12px] flex items-center text-ui-75'
								)}
							>
								Select report
							</div>
						)}
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};

const AddReport = ({ rowIndex, isVisible }: { rowIndex: number; isVisible?: boolean }) => {
	const { dashboard } = useContext(DashboardLayoutContext);
	const ref = useRef<HTMLDivElement | null>(null);
	const [isActive, setIsActive] = useState(false);
	const dispatch = useAppDispatch();

	function handleAdd() {
		track(mixPanel.Events.EditDashboardAddReportClick, {
			'Dashboard Name': dashboard?.name,
		});
		dispatch(
			navigateToExplore({
				dashboardId: dashboard?.dashboardId,
				reportId: null,
				editMode: true,
				rowIndex,
			})
		);
		setIsActive(false);
	}

	function handleAddText() {
		track(mixPanel.Events.DashboardEditDashboard, {
			'Dashboard Name': dashboard?.name,
		});
		dispatch(addDefaultTextReport(dashboard?.dashboardId, rowIndex));
		setIsActive(false);
	}

	const handleClickOutside = (event: any) => {
		if (!ref.current) return;

		if (!ref.current.contains(event.target)) {
			setIsActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isActive]);

	return (
		<div
			ref={ref}
			className={cn(
				isVisible ? 'visible opacity-100' : 'invisible opacity-0',
				'absolute top-1/2 -translate-y-1/2 right-0 w-[48px] h-[48px] flex items-center justify-center rounded-full translate-x-1/2 ',
				'cursor-pointer',
				isActive
					? 'bg-ui-100 text-ui-100-inverted shadow-default'
					: 'bg-transparent text-ui-100 hover:text-ui-100 hover:bg-shade-h5 hover:shadow-default'
			)}
			onClick={() => {
				setIsActive(!isActive);
			}}
		>
			<AddIcon />
			<div
				className={cn(
					'absolute top-0  left-[-10px] -translate-x-full shadow-default',
					'flex flex-col  items-start w-fit',
					'rounded-[2rem] overflow-hidden bg-shade-h4',
					isActive ? 'opacity-100 visible' : 'opacity-0 invisible',
					'transition-all duration-200'
				)}
			>
				<div
					onClick={handleAdd}
					className={cn(
						'flex gap-4 items-center w-full px-[1.6rem] py-[1rem] ',
						'cursor-pointer',
						'hover:bg-shade-h5'
					)}
				>
					<div className="flex-shrink-0">
						<InsightsReportIcon />
					</div>
					<div className={cn('flex flex-col gap-0 items-start ')}>
						<div className="text-ui-100 whitespace-nowrap">Report</div>
					</div>
				</div>

				<div
					onClick={handleAddText}
					className={cn(
						'flex gap-4 items-center',
						'cursor-pointer w-full px-[1.6rem] py-[1rem] ',
						'hover:bg-shade-h5'
					)}
				>
					<div className="flex-shrink-0">
						<TextReportIcon />
					</div>
					<div className={cn('flex flex-col gap-0 items-start')}>
						<div className="text-ui-100   whitespace-nowrap">Text</div>
					</div>
				</div>
			</div>
		</div>
	);
};
