import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface RadioButtonProps {
	componentType?: string;
	checked?: boolean;
	onClick?: any;
	children?: ReactNode;
	disabled?: boolean;
	[x: string]: any;
}

function RadioButton({ componentType, checked, onClick, children, disabled, ...props }: RadioButtonProps) {
	function handleChange() {
		// To prevent warning in console!
		// Parent's onClick does the state change!
	}

	return (
		<span
			className={classNames('radio-button', `radio-button--${componentType}`, {
				[`radio-button--${componentType}--checked`]: checked,
				[`radio-button--${componentType}--disabled`]: disabled,
			})}
			onClick={onClick}
			{...props}
		>
			<input
				className={classNames(`radio-button--${componentType}__input`, {})}
				type="radio"
				checked={checked}
				onChange={handleChange}
			/>
			<span
				className={classNames(`radio-button--${componentType}__circle`, {
					[`radio-button--${componentType}--checked__circle`]: checked,
				})}
			/>
			<span className={`radio-button--${componentType}__label`}>{children}</span>
		</span>
	);
}

RadioButton.defaultProps = {
	componentType: 'default',
	checked: false,
	onClick: () => {},
};

export default RadioButton;
