import { Modal } from 'antd';
import React from 'react';
import Button from '../../../common/Button';
import OpenText from '../../../common/OpenText';
import ButtonTypes from '../../../constants/ButtonTypes';
import sanitize from '../../../lib/sanitize';

interface EditSearchProps {
	isOpen?: boolean;
	onSubmit?: (name: string) => void;
	onCancel?: () => void;
	oldName?: string;
}

const EditSearch = ({ isOpen, onSubmit, onCancel, oldName = '' }: EditSearchProps) => {
	const [name, setName] = React.useState(oldName);

	React.useEffect(() => {
		if (isOpen) {
			setName(oldName);
		}
	}, [isOpen]);

	const handleNameChange = (name: string) => {
		setName(name);
	};

	const handleSave = (e: any) => {
		e?.preventDefault();
		const templateName = sanitize(name.trim());
		onSubmit?.(templateName);
	};

	const handleClose = () => {
		setName('');
	};

	return (
		<Modal
			visible={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
			destroyOnClose
		>
			<div className="modal">
				<form onSubmit={handleSave}>
					<div className="modal__header">
						<div className="modal__title">Edit Search Name</div>
					</div>
					<div className="modal__content">
						<OpenText
							value={name}
							onChange={handleNameChange}
							placeholder="Name the search"
							onClose={handleClose}
						/>
					</div>
					<div className="modal__footer">
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
							Cancel
						</Button>
						<Button type="submit" onClick={handleSave} disabled={!name}>
							Rename
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default EditSearch;
