import { ChartType } from '../types';
import {
	Funnel,
	HorizontalBar,
	IndexBar,
	IndexBarGrouped,
	Line,
	Metric,
	Pie,
	StackedBar,
	StackedBarGrouped,
	Table,
	VerticalBar,
	VerticalBarGrouped,
} from './Charts';

export const getChartFromType = (type: ChartType) => {
	switch (type) {
		case 'pie':
			return Pie as React.FC;
		case 'line':
			return Line;
		case 'horizontal-bar':
		case 'horizontalbar':
			return HorizontalBar;
		case 'bar':
		case 'vertical-bar':
		case 'verticalbar':
			return VerticalBar;
		case 'vertical-bar-grouped':
		case 'verticalbargrouped':
			return VerticalBarGrouped;
		case 'index-bar':
		case 'indexbar':
			return IndexBar;
		case 'index-bar-grouped':
		case 'indexbargrouped':
			return IndexBarGrouped;
		case 'matrix':
		case 'arithmetic-table':
		case 'arithmetictable':
		case 'table':
			return Table;
		case 'stacked-bar':
		case 'stackedbar':
			return StackedBar;
		case 'stacked-bar-grouped':
		case 'stackedbargrouped':
			return StackedBarGrouped;
		case 'metric':
		case 'stats':
			return Metric;
		case 'funnel':
		case 'funnel-center':
		case 'funnelcenter':
			return Funnel;
		case 'funnel-left':
		case 'funnelleft':
			return Funnel;
		default:
			return Pie;
	}
};
