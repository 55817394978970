import { createContext, useEffect, useRef, useState } from 'react';
import { resetChat } from '../../ai/chat/reducer';
import { setAiModalOpen } from '../../ai/modal/reducer';
import MixPanel from '../../constants/MixPanel';
import cn from '../../lib/cn';
import { track } from '../../lib/segment';
import { useAppDispatch } from '../../store/hooks';
import DraggableModal from './DraggableModal';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ResizeHandle from './ResizeHandle';
import Views from './Views';

interface AiModalContextProps {
	modalHeight: number;
	modalWidth: number;
	modalMaxHeight: number;
	setModalHeight: (height: number) => void;
	setModalWidth: (width: number) => void;
	modalRef: any;
	isMinimized?: boolean;
}
// ----------------------

export const AiModalContext = createContext({
	modalHeight: 0,
	modalWidth: 0,
	setModalHeight: () => {},
	setModalWidth: () => {},
	modalRef: null,
	modalMaxHeight: 0,
} as AiModalContextProps);

export default function AiModal({}: {}) {
	const dispatch = useAppDispatch();
	const modalRef = useRef<any>();
	const [modalHeight, setModalHeight] = useState(0);
	const [modalWidth, setModalWidth] = useState(700);
	const [modalMaxHeight, setModalMaxHeight] = useState(0);

	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [isMinimized, setIsMinimized] = useState(false);

	// Close on Esc
	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				dispatch(setAiModalOpen(false));
				dispatch(resetChat());
			}
		};

		document.addEventListener('keydown', handleEsc);

		return () => {
			document.removeEventListener('keydown', handleEsc);
		};
	}, []);

	useEffect(() => {
		if (modalHeight === 0 || modalHeight > modalMaxHeight) {
			track(MixPanel.Events.AIModalClickResizeModal);
			setModalHeight(modalMaxHeight);
		}
	}, [modalMaxHeight, modalHeight]);

	useEffect(() => {
		const handleResize = () => {
			if (!modalRef.current) return;
			track(MixPanel.Events.AIModalClickResizeModal);

			const TOP_MARGIN = 72;
			const BOTTOM_MARGIN = 16;
			const dynamicHeight = window.innerHeight - TOP_MARGIN - BOTTOM_MARGIN;
			setModalMaxHeight(dynamicHeight);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleSetPosition = (position: { x: number; y: number }) => {
		track(MixPanel.Events.AIModalClickMoveModal);
		setPosition(position);
	};

	return (
		<Modal setPosition={handleSetPosition}>
			<DraggableModal position={position} setPosition={handleSetPosition}>
				{({ attributes, listeners, setActivatorNodeRef }: any) => (
					<AiModalContext.Provider
						value={{
							modalHeight,
							modalWidth,
							setModalHeight,
							setModalWidth,
							modalRef,
							isMinimized,
							modalMaxHeight,
						}}
					>
						<div
							ref={modalRef}
							className={cn(
								'relative flex flex-col gap-[2rem] p-[1.6rem] bg-shade-h1',
								'rounded-[2rem] overflow-auto shadow-lg'
							)}
							style={{
								pointerEvents: 'all',
								height: isMinimized ? '65px' : modalHeight,
								width: modalWidth,
							}}
						>
							<ModalHeader
								setActivatorNodeRef={setActivatorNodeRef}
								setIsMinimized={setIsMinimized}
								{...attributes}
								{...listeners}
							/>
							{!isMinimized && <Views />}
							<ResizeHandle />
						</div>
					</AiModalContext.Provider>
				)}
			</DraggableModal>
		</Modal>
	);
}
