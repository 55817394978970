import { useContext } from 'react';

import Button from '../../common/Button';
import WithPermissions from '../../common/WithPermissions';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { track } from '../../lib/segment';
import { AdminHeaderContext } from './AdminHeader';
import Header from './Header';

const RolesHeader = () => {
	const { createRoleRoute } = useContext(AdminHeaderContext);

	const handleCreateRole = () => {
		track(MixPanel.Events.AdminRolesPermissionsClickCreateRole);
		createRoleRoute();
	};

	return (
		<Header>
			<Header.Title></Header.Title>
			<WithPermissions actions={[RbacActions['Roles/Edit']]}>
				<Header.Buttons>
					<Button onClick={handleCreateRole}>Create Role</Button>
				</Header.Buttons>
			</WithPermissions>
		</Header>
	);
};

export default RolesHeader;
