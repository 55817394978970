import ChartContainer from '../../common/ChartContainer';
import LottieAnimation from '../../common/LottieAnimation';
import Loader from '../../lottie/graph-loader.json';

export default function EmptyState({
	generatePending,
	generateError,
	generateInvalidVariation,
	generateNoData,
	generated,
	children,
}: {
	generatePending?: boolean;
	generateError?: boolean;
	generateInvalidVariation?: boolean;
	generateNoData?: boolean;
	generated?: boolean;
	children?: any;
}) {
	if (generatePending) {
		return (
			<ChartContainer className="h-[20rem]  flex justify-center items-center mt-[64px] ">
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<LottieAnimation
						autoplay={true}
						loop={true}
						width={60}
						height={60}
						animation={Loader}
					></LottieAnimation>
				</div>
			</ChartContainer>
		);
	}

	if (generateError) {
		return (
			<ChartContainer className="h-[20rem]  flex justify-center items-center mt-[64px] ">
				<h3>
					Sorry, you are not authorized to view this report. Your administrator controls these permissions for
					your account.
				</h3>
			</ChartContainer>
		);
	} else if (generateInvalidVariation) {
		return (
			<ChartContainer className="h-[20rem] flex justify-center items-center mt-[64px] ">
				<h3>
					This report has not yet been activated by your company.
					<br />
					<a href="mailto: support@itsdandi.com" target="__blank">
						Contact
					</a>{' '}
					your Dandi Customer Success Representative to turn it on.
				</h3>
			</ChartContainer>
		);
	} else if (generateNoData) {
		return (
			<ChartContainer className="h-[20rem]  flex justify-center items-center mt-[64px] ">
				<h3>Sorry! There is no data for this graph.</h3>
			</ChartContainer>
		);
	} else if (!generated) {
		return (
			<ChartContainer className="h-[20rem]  flex justify-center items-center mt-[64px] ">
				<h3>
					Select a segment and optionally choose a filter or breakdown. Then click Generate to create your
					report.
				</h3>
			</ChartContainer>
		);
	}

	return <>{children} </>;
}
