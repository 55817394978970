import * as Tooltip from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { setScrollPosition } from '../../common/actions';
import Constants from '../../constants/Constants';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { getDashboards, getSharedDashboards, showDuplicateModal, showShareModal } from '../../dashboards/actions';
import usePermissions from '../../hooks/usePermissions';
import Warning from '../../icons/Warning';
import TopLeftAction from '../../layout/TopLeftAction';
import cn from '../../lib/cn';
import { page } from '../../lib/segment';
import { useAppSelector } from '../../store/hooks';
import SecondaryHeader from './SecondaryHeader';
import { getReports, setSearchText } from './actions';
import './styles.scss';

function DashboardHeader(props: any) {
	const {
		searchText,
		dashboard,
		getDashboards,
		getSharedDashboards,
		getReports,
		showShareModal,
		showDuplicateModal,
		reports,
		enterpriseName,
		scrollPosition,
		setScrollPosition,
	} = props;
	const {
		location: { state },
	} = props;
	const { hasHiddenReports } = useAppSelector(state => state.dashboard.view);
	const tokenClientRef = useRef();

	const hasViewAllPermissions = usePermissions({
		actions: [RbacActions['Dashboards/ViewAll']],
	});
	useEffect(() => {
		page(MixPanel.Pages.Dashboard);

		if (!window.gapi) {
			return;
		}

		gapi.load('client', () => {
			gapi.client.init({
				apiKey: Constants.SlidesConfig.ApiKey,
				discoveryDocs: Constants.SlidesConfig.DiscoveryDocs,
			});
		});

		if (!google) {
			return;
		}

		tokenClientRef.current = google.accounts.oauth2.initTokenClient({
			client_id: Constants.SlidesConfig.ClientId,
			scope: Constants.SlidesConfig.Scopes,
			callback: '',
		});
	}, [window.gapi]);

	useEffect(() => {
		if (scrollPosition >= 0) {
			setTimeout(() => window.scrollTo(0, scrollPosition), 0);
			setScrollPosition(-1);
		}
	}, [scrollPosition]);

	useEffect(() => {
		if (!dashboard) {
			if (hasViewAllPermissions) {
				getDashboards();
			} else {
				getSharedDashboards();
			}
		} else {
			getReports(dashboard.reports);
		}
	}, [dashboard, hasViewAllPermissions]);

	if (!dashboard) {
		return null;
	}

	return (
		<div className="dashboard-header">
			<div className={cn('flex item-center gap-[1.6rem]')}>
				<DashboardTitle name={dashboard.name} scrollPos={state?.scrollPos} />
				{hasHiddenReports && (
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<div className={cn('text-fail flex item-center')}>
									<Warning />
								</div>
							</Tooltip.Trigger>
							<Tooltip.Portal>
								<Tooltip.Content
									side="bottom"
									sideOffset={16}
									className={cn(
										'bg-shade-h3 rounded-lg font-micro-text p-[.8rem] w-[20rem] max-w-[20rem] shadow-lg ',
										'z-[9999] '
									)}
								>
									{`Based on your permissions, some reports in this dashboard are hidden. You will not be able to edit rows that contain hidden reports.`}
								</Tooltip.Content>
							</Tooltip.Portal>
						</Tooltip.Root>
					</Tooltip.Provider>
				)}
			</div>
			<SecondaryHeader
				showShareModal={showShareModal}
				showDuplicateModal={showDuplicateModal}
				dashboard={dashboard}
				searchText={searchText}
				reports={reports}
				enterpriseName={enterpriseName}
				tokenClientRef={tokenClientRef}
			/>
		</div>
	);
}

const DashboardTitle = ({ name, scrollPos }: { name: string; scrollPos?: number }) => {
	const { reportSelectMode, reportIds } = useAppSelector(state => state.dashboard.aiInsights);
	return (
		<div className="dashboard-header__title">
			<TopLeftAction scrollPos={scrollPos} />
			{reportSelectMode ? (reportIds.length > 0 ? `${reportIds.length} reports` : 'Select reports') : name}
		</div>
	);
};

DashboardHeader.propTypes = {
	searchText: PropTypes.string,
	setSearchText: PropTypes.func,
};

function mapState(state: any, ownProps: any) {
	const { dashboardId } = ownProps.match.params;
	if (!dashboardId) {
		return {
			navigationError: true,
			dashboard: {},
		};
	} else {
		const dashboard = state.dashboards[dashboardId];

		return {
			dashboard,
			reports: state.reports,
			enterpriseName: state.account.enterpriseName,
			scrollPosition: state.appState.scrollPosition,
			searchText: state.reports.searchText,
		};
	}
}

const dispatchProps = {
	setSearchText,
	getDashboards,
	getSharedDashboards,
	getReports,
	showShareModal,
	showDuplicateModal,
	setScrollPosition,
};

export default connect(mapState, dispatchProps)(DashboardHeader);
