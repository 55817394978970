import { ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import AdminHeader from '../admin/AdminHeader';
import CreateRoleHeader from '../admin/rolespermissions/CreateRole/CreateRoleHeader';
import EditRoleHeader from '../admin/rolespermissions/EditRole/EditRoleHeader';
import CollectHeader from '../collect/CollectHeader';
import Divider from '../common/Divider';
import DashboardHeader from '../dashboard/view/DashboardHeader';
import DashboardsHeader from '../dashboards/Header';
import SurveyCategoriesHeader from '../datastudio/survey/category/CategoriesHeader';
import SurveyCategoryEditHeader from '../datastudio/survey/category/CategoryEditHeader';
import SurveyQuestionEditHeader from '../datastudio/survey/question/QuestionEditHeader';
import SurveyQuestionsHeader from '../datastudio/survey/question/QuestionsHeader';
import SurveyTemplateQuestionsHeader from '../datastudio/survey/template/QuestionsHeader';
import SurveyTemplateEditHeader from '../datastudio/survey/template/TemplateEditHeader';
import SurveyTemplatesHeaderDataStudio from '../datastudio/survey/template/TemplatesHeader';
import ExploreHeader from '../explore/ExploreHeader';
import cn from '../lib/cn';
import shouldShowBanner from '../lib/shouldShowBanner';
import GoalHeader from '../measure/GoalHeader';
import MeasureHeader from '../measure/MeasureHeader';
import CampaignEditHeader from '../self-id/campaigns/edit/Header';
import CampaignHeader from '../self-id/campaigns/view/Header';
import SelfIdHeader from '../self-id/components/SelfIdHeader';
import SurveyTemplatesHeader from '../self-id/survey-templates/list/Header';
import SurveyTemplateHeader from '../self-id/survey-templates/view/Header';
import TimelineHeader from '../timeline/TimelineHeader';

interface TopBarProps extends RouteComponentProps {
	TopMenu: ComponentType;
	aiLayout?: boolean;
}

function TopBar({ TopMenu, aiLayout }: TopBarProps) {
	return (
		<div
			id="header"
			className={cn('fixed  left-0 right-0 z-[1100]')}
			style={{
				top: shouldShowBanner() ? '4rem' : '0',
			}}
		>
			<div className={cn('topbar', 'transition-all duration-300 ease-in-out', !aiLayout && 'bg-shade-h2')}>
				<div
					className={cn('topbar__container', 'flex flex-wrap flex-col justify-between')}
					style={{
						height: aiLayout ? '60px' : '120px',
					}}
				>
					<div className={cn('topbar__menu')}>
						<TopMenu />
					</div>
					{!aiLayout && (
						<>
							<Divider style={{ marginTop: '58px', zIndex: '800' }} />
							<div className="topbar__title">
								<Switch>
									<Route path="/explore" component={ExploreHeader} />
									<Route path="/self-id/campaigns/view/:id" component={CampaignHeader} />
									<Route path="/self-id/campaigns/edit/:id" component={CampaignEditHeader} />
									<Route path="/self-id/campaigns/create" component={CampaignEditHeader} />

									<Route
										path="/self-id/audiences/view/:id"
										component={() => <div>View Audience</div>}
									/>
									<Route path="/self-id/survey-templates/view/:id" component={SurveyTemplateHeader} />
									<Route path="/self-id/survey-templates" component={SurveyTemplatesHeader} />

									<Route path="/self-id/audiences" component={SelfIdHeader} />
									<Route path="/self-id/campaigns" component={SelfIdHeader} />
									<Route path="/timeline" component={TimelineHeader} />
									<Route path="/measure/:goalId" component={GoalHeader} />
									<Route path="/measure" component={MeasureHeader} />
									<Route path="/dashboard/:dashboardId" component={DashboardHeader} />
									<Route path="/admin/create-role" component={CreateRoleHeader} />
									<Route path="/admin/edit-role/:roleId" component={EditRoleHeader} />
									<Route path="/admin/duplicate-role" component={CreateRoleHeader} />
									<Route path="/admin" component={AdminHeader} />
									<Route path="/collect" component={CollectHeader} />
									<Route path="/datastudio/survey/questions" component={SurveyQuestionsHeader} />
									<Route
										path="/datastudio/survey/question/edit"
										component={SurveyQuestionEditHeader}
									/>
									<Route
										path="/datastudio/survey/template/edit"
										component={SurveyTemplateEditHeader}
									/>
									<Route
										path="/datastudio/survey/templates"
										component={SurveyTemplatesHeaderDataStudio}
									/>
									<Route path="/datastudio/survey/categories" component={SurveyCategoriesHeader} />
									<Route
										path="/datastudio/survey/category/edit"
										component={SurveyCategoryEditHeader}
									/>
									<Route
										path="/datastudio/survey/template/questions"
										component={SurveyTemplateQuestionsHeader}
									/>
									<Route path="/" exact component={DashboardsHeader} />
								</Switch>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default withRouter(TopBar);
