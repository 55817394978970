import Checkbox from 'antd/lib/checkbox';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import LongText from '../common/LongText';
import LottieAnimation from '../common/LottieAnimation';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import Edit from '../icons/Edit';
import Loader from '../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchBenchmarks } from './actions';
import { Benchmark } from './model';

interface BenchmarkMenuProps {
	benchmark?: Benchmark;
	onBenchmarkCreate: () => void;
	onBenchmarkEdit: (benchmark: Benchmark) => void;
	onBenchmarkSelect: (benchmark: Benchmark) => void;
	onBenchmarkRemove: () => void;
	showPie?: boolean;
	onShowPieChange: (value: boolean) => void;
}

function BenchmarkMenu(props: BenchmarkMenuProps) {
	const {
		benchmark,
		onBenchmarkCreate,
		onBenchmarkEdit,
		onBenchmarkSelect,
		onBenchmarkRemove,
		showPie,
		onShowPieChange,
	} = props;
	const dispatch = useAppDispatch();
	const { data, pending, loaded } = useAppSelector(state => state.benchmarks);

	const [activeBenchmarkId, setActiveBenchmarkId] = useState<string>();

	useEffect(() => {
		if (data?.length === 0 && !loaded) {
			dispatch(fetchBenchmarks());
		}
	}, [data]);

	return (
		<div
			className={classNames('benchmark-menu', {
				'benchmark-menu--empty': pending,
			})}
		>
			{pending ? (
				<LottieAnimation
					autoplay={true}
					loop={true}
					width={60}
					height={60}
					animation={Loader}
				></LottieAnimation>
			) : (
				<Fragment>
					<div className="benchmark-menu__left">
						<WithPermissions actions={[RbacActions['Benchmarks/Edit']]}>
							<div className="benchmark-menu__create" onClick={onBenchmarkCreate}>
								Create new benchmark
							</div>
						</WithPermissions>
						{!!data?.length && (
							<Fragment>
								<div className="benchmark-menu__separator"></div>
								<div className="benchmark-menu__title">Saved templates</div>
								<ul className="benchmark-menu__list">
									{data.map(b => (
										<li
											key={b.benchmarkId}
											onClick={() => onBenchmarkSelect(b)}
											onMouseOver={() => setActiveBenchmarkId(b.benchmarkId)}
											onMouseLeave={() => {
												setActiveBenchmarkId(undefined);
											}}
										>
											<LongText
												text={b.benchmarkName}
												tooltipClassName="benchmark-menu__tooltip"
												refreshKey={b.benchmarkId === activeBenchmarkId}
											/>
											{activeBenchmarkId === b.benchmarkId && (
												<WithPermissions actions={[RbacActions['Benchmarks/Edit']]}>
													<Edit
														className="icon"
														onClick={(e: any) => {
															e.stopPropagation();
															onBenchmarkEdit(b);
														}}
													/>
												</WithPermissions>
											)}
										</li>
									))}
								</ul>
							</Fragment>
						)}
					</div>
					{benchmark && (
						<div className="benchmark-menu__right">
							<div className="benchmark-menu__option">
								<Checkbox checked={showPie} onChange={e => onShowPieChange(e.target.checked)}>
									Pie chart visible
								</Checkbox>
							</div>
							<div className="benchmark-menu__button" onClick={onBenchmarkRemove}>
								Hide benchmark
							</div>
							<WithPermissions actions={[RbacActions['Benchmarks/Edit']]}>
								<div className="benchmark-menu__button" onClick={() => onBenchmarkEdit(benchmark)}>
									Edit benchmark
								</div>
							</WithPermissions>
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
}

BenchmarkMenu.defaultProps = {
	data: [],
};

export default BenchmarkMenu;
