import * as Sentry from '@sentry/react';

const sentryMiddleware = (store: any) => (next: any) => (action: any) => {
	if (action.type.endsWith('/rejected')) {
		const state = store.getState();
		const { enterpriseId } = state.account;
		const { uid } = state.auth.userInfo;

		Sentry.captureException(action.error, {
			extra: {
				actionType: action.type,
			},
			user: {
				uid,
				enterpriseId,
			},
		});
	}

	return next(action);
};

export default sentryMiddleware;
