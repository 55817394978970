import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ContentWrapper from '../../components/ContentWrapper';
import { copyTemplate } from './slice';
import { useEffect } from 'react';
import { fetchSurveyTemplates } from '../../survey-templates/list/slice';
import { Survey } from '../../../datastudio/survey/template/types';
import Tabs from './Components/Tabs';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';

const CampaignCreate = () => {
	const dispatch = useAppDispatch();
	const { data, loaded, error } = useAppSelector(state => state.surveyTemplates.list);
	const { templateId } = useAppSelector(state => state.router.location.query);

	useEffect(() => {
		if (!loaded && !error) {
			dispatch(fetchSurveyTemplates());
		}
	}, [loaded, error]);

	useEffect(() => {
		const surveyTemplate = data.find((survey: Survey) => survey.globalSurveyId === templateId);

		if (!surveyTemplate) return;

		dispatch(copyTemplate(surveyTemplate));
	}, [data, templateId]);

	return (
		<WithPermissions actions={[RbacActions['Campaign/Edit']]} showMessage={true}>
			<ContentWrapper style={{ margin: '3.2rem 2rem 1.6rem' }}>
				<Tabs />
			</ContentWrapper>
		</WithPermissions>
	);
};

export default CampaignCreate;
