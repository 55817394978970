import { createSlice } from '@reduxjs/toolkit';
import constants from '../constants/Constants';

const initialState = {
	title: constants.DefaultReportName,
	custom: false,
};

export const titleSlice = createSlice({
	name: 'title',
	initialState,
	reducers: {
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setCustom: (state, action) => {
			state.custom = action.payload;
		},
	},
});

export const { setTitle, setCustom } = titleSlice.actions;
export default titleSlice.reducer;
