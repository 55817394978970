import firebase from '../lib/firebase';
import ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import axios from 'axios';
import Urls from '../constants/Urls';
import LSKeys from '../constants/LSKeys';
import resizePhoto from '../lib/resizePhoto';
import { push, goBack } from 'connected-react-router';
import {
	getAccount,
	getFields,
	getActorInfo,
	getAnalysisInfo,
	getAnalysisTree,
	getFeatureFlags,
} from '../layout/actions';
import { getActors } from '../admin/users/actions';
import qs from 'qs';
import MixPanel from '../constants/MixPanel';
import { identify, track } from '../lib/segment';
import errorHandler from '../lib/errorHandler';
import Constants from '../constants/Constants';
import { getEvents } from '../timeline/actions';
import { getTrackers } from '../measure/actions';
import { getGoals } from '../measure/actions'; // TODO: Update to use redux toolkit
import { getRoles } from '../admin/roles/actions';
import { getTemplates } from '../templates/actions';
import hasPermission from '../lib/hasPermission';
import RbacActions from '../constants/RbacActions';
import callIfAllowed from '../lib/callIfAllowed';
import { addMessage } from '../common/actions';

export function identifyOnLogin(newUser) {
	return (dispatch, getState) => {
		const { userInfo, role } = getState().auth;
		const { uid, email, firstName, lastName, creationTime, disableExploreAccess } = userInfo;
		const { enterpriseId, enterpriseName } = getState().account;
		identify(uid, {
			email: email,
			firstName: firstName,
			lastName: lastName,
			companyId: enterpriseId,
			companyName: enterpriseName,
			createdAt: moment(creationTime).toISOString(),
			language: 'EN',
			role,
			disableExploreAccess,
		});
		track(MixPanel.Events.LogIn);
		if (newUser) {
			track(MixPanel.Events.LoginNewUser);
		}
		dispatch({
			type: ActionTypes.IdentifyOnLogin,
		});
	};
}

export function setEnterpriseId(enterpriseId, redirect) {
	return (dispatch, getState) => {
		localStorage.setItem(LSKeys.EnterpriseId, enterpriseId);
		dispatch({
			type: ActionTypes.SetEnterpriseId,
			enterpriseId,
		});
		if (redirect) {
			const query = qs.parse(getState().router.location.search, {
				ignoreQueryPrefix: true,
			});
			if (query.redirectUrl) {
				dispatch(push(query.redirectUrl));
			} else {
				dispatch(push('/'));
			}

			return dispatch(getAuthorizationToken());
		}
	};
}

function getActorEnterprises(actorId, enterpriseId) {
	return axios.post(`${Urls.AccountApi}actor`, {}).then(response => {
		const { enterprises, enterprisesInfo } = response.data;
		if (enterprises.length === 0 && enterpriseId) {
			return axios
				.put(`${Urls.AccountApi}sso/link`, {
					actorId,
					enterpriseId,
				})
				.then(() => {
					return getActorEnterprises();
				});
		}
		return {
			enterprises,
			enterprisesInfo,
			newUser: !actorId && !enterpriseId,
		};
	});
}

function processLoginResponse(response, redirectUrl, setEnterprise, enterpriseIdForNewUser) {
	return dispatch => {
		const { uid, metadata } = response.user;
		const { creationTime } = metadata;
		const userInfo = {
			uid,
			creationTime,
		};
		let redirectToAccounts;

		return getActorEnterprises(uid, enterpriseIdForNewUser)
			.then(returnValue => {
				const { enterprises, enterprisesInfo, newUser } = returnValue;
				if (setEnterprise) {
					if (enterprises.length === 1) {
						const enterpriseId = enterprises[0];
						dispatch(setEnterpriseId(enterpriseId));
					} else {
						const enterpriseId = localStorage.getItem(LSKeys.EnterpriseId);
						if (enterpriseId) {
							const found = enterprises.some(e => e === enterpriseId);
							if (!found) {
								localStorage.removeItem(LSKeys.EnterpriseId);
								redirectToAccounts = true;
							} else {
								dispatch(setEnterpriseId(enterpriseId));
							}
						} else {
							redirectToAccounts = true;
						}
					}
				}
				localStorage.setItem(LSKeys.Enterprises, JSON.stringify(enterprisesInfo));
				dispatch({
					type: ActionTypes.SetEnterprises,
					enterprises: enterprisesInfo,
				});

				if (redirectToAccounts) {
					dispatch(push(`/accounts?redirectUrl=${encodeURIComponent(redirectUrl)}`));
					dispatch({
						type: ActionTypes.LoginFulfilled,
						userInfo,
					});
				} else {
					return dispatch(getAuthorizationToken()).then(() => {
						dispatch({
							type: ActionTypes.LoginFulfilled,
							userInfo,
						});
						dispatch(identifyOnLogin(newUser));
					});
				}
			})
			.then(() => {
				return true;
			});
	};
}

export function login(username, password, redirectUrl = '/', setEnterprise = true) {
	return dispatch => {
		dispatch({
			type: ActionTypes.LoginPending,
		});
		return firebase
			.auth()
			.signInWithEmailAndPassword(username, password)
			.then(response => {
				return dispatch(processLoginResponse(response, redirectUrl, setEnterprise));
			})
			.catch(error => {
				errorHandler.report(error);
				console.log(error);
				dispatch({
					type: ActionTypes.LoginRejected,
					error,
				});
				return false;
			});
	};
}

export function didLogin() {
	return async (dispatch, getState) => {
		// when user logins via sso or password, don't trigger this flow.
		const { loginPending } = getState().auth;
		if (loginPending) return;

		const enterprisesRaw = localStorage.getItem(LSKeys.Enterprises);
		if (enterprisesRaw) {
			const enterprises = JSON.parse(enterprisesRaw);
			dispatch({
				type: ActionTypes.SetEnterprises,
				enterprises,
			});
		}
		const enterpriseId = localStorage.getItem(LSKeys.EnterpriseId);
		if (enterpriseId) {
			dispatch({
				type: ActionTypes.SetEnterpriseId,
				enterpriseId,
			});
		}

		const {
			uid,
			email,
			metadata: { creationTime },
		} = firebase.auth().currentUser;
		const userInfo = {
			uid,
			email,
			creationTime,
		};
		dispatch({
			type: ActionTypes.LoginFulfilled,
			userInfo,
		});

		dispatch(getAuthorizationToken());
	};
}

export function getAuthorizationToken() {
	return async (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		dispatch({
			type: ActionTypes.GetAuthorizationTokenPending,
		});

		return axios
			.post(`${Urls.RbacApi}authorization/token`, enterpriseId)
			.then(async response => {
				localStorage.setItem('rbacToken', response.data.token);

				dispatch({
					type: ActionTypes.GetAuthorizationTokenFulfilled,
				});

				await dispatch(getAllNecessaryData());
			})
			.catch(error => {
				dispatch({
					type: ActionTypes.GetAuthorizationTokenRejected,
					error: error.message,
				});
			});
	};
}

export function getAllNecessaryData() {
	return (dispatch, getState) =>
		dispatch(getActorInfo()).then(async () => {
			const { staticPermissions } = getState().auth;

			await Promise.all([
				dispatch(getAccount()),
				dispatch(getFields()),
				dispatch(getAnalysisInfo()),
				dispatch(getAnalysisTree()),
				dispatch(getFeatureFlags()),
				callIfAllowed({
					actions: [RbacActions['Users/View']],
					cb: () => dispatch(getActors()),
					staticPermissions,
				}),
				callIfAllowed({
					actions: [RbacActions['Events/View']],
					cb: () => {
						dispatch(getEvents());
						dispatch(getTrackers());
					},
					staticPermissions,
				}),
				callIfAllowed({
					actions: [RbacActions['Goals/View']],
					cb: () => dispatch(getGoals()),
					staticPermissions,
				}),
				callIfAllowed({
					actions: [RbacActions['Roles/View']],
					cb: () => dispatch(getRoles()),
					staticPermissions,
				}),
			]).then(() => {
				//getActorInfo populates firstName, lastName
				dispatch(getTemplates());
				dispatch(identifyOnLogin());
			});
		});
}

export function resetLoginError() {
	return {
		type: ActionTypes.ResetLoginError,
	};
}

export function completeSetup(email, tempPassword, newPassword, firstName, lastName, image, lightMode) {
	return (dispatch, getState) => {
		dispatch({ type: ActionTypes.CompleteSetupPending });
		return dispatch(login(email, tempPassword, null, true))
			.then(success => {
				if (!success) {
					return Promise.reject();
				}
				return dispatch(createPassword(newPassword));
			})
			.then(() => {
				if (image) {
					return dispatch(uploadPhoto(email, image));
				}
				return Promise.resolve();
			})
			.then(imageURL => {
				return dispatch(updateActor(email, firstName, lastName, imageURL, lightMode));
			})
			.then(() => {
				const { userInfo, role } = getState().auth;
				const { uid, creationTime, disableExploreAccess } = userInfo;
				const { enterpriseId, enterpriseName } = getState().account;
				identify(uid, {
					email,
					firstName,
					lastName,
					companyId: enterpriseId,
					companyName: enterpriseName,
					createdAt: moment(creationTime).toISOString(),
					language: 'EN',
					role,
					disableExploreAccess,
				});
				track(MixPanel.Events.LoginNewUser);
				track(MixPanel.Events.LogIn);
				dispatch(push('/'));
				dispatch({ type: ActionTypes.CompleteSetupFulfilled });
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({ type: ActionTypes.CompleteSetupRejected });
			});
	};
}

export function createPassword(newPassword) {
	return dispatch => {
		dispatch({ type: ActionTypes.CreatePasswordPending });
		const user = firebase.auth().currentUser;
		return user.updatePassword(newPassword).then(() => {
			dispatch({ type: ActionTypes.CreatePasswordFulfilled });
		});
	};
}

export function changePassword(email, password, newPassword) {
	return dispatch => {
		const user = firebase.auth().currentUser;
		const credential = firebase.auth.EmailAuthProvider.credential(email, password);
		dispatch({ type: ActionTypes.ChangePasswordPending });
		return user
			.reauthenticateWithCredential(credential)
			.then(() => {
				return user.updatePassword(newPassword);
			})
			.then(() => {
				dispatch(goBack());
				dispatch({ type: ActionTypes.ChangePasswordFulfilled });
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({ type: ActionTypes.ChangePasswordRejected });
			});
	};
}

// actorId and disableExploreAcess is optional
// for admin to set this for viewer role
export function updateActor(email, firstName, lastName, imageURL, lightMode, disableExploreAccess, actorId) {
	return dispatch => {
		dispatch({ type: ActionTypes.UpdateActorPending });
		return axios
			.put(`${Urls.AccountApi}actor/info`, {
				email,
				firstName,
				lastName,
				profilePictureURL: imageURL,
				requiresPasswordChange: false,
				lightMode,
				actorId,
				disableExploreAccess,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.UpdateActorFulfilled,
					email,
					firstName,
					lastName,
					profilePictureURL: imageURL,
					actorId,
					disableExploreAccess,
					lightMode,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateActorRejected,
				});
			});
	};
}

export function uploadPhoto(email, image) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.UploadPhotoPending,
		});

		image = await resizePhoto(image, 80, 80);
		const photosRef = firebase.storage().ref().child('user-photos').child(email);
		return photosRef.putString(image, 'data_url').then(() => {
			return photosRef.getDownloadURL().then(downloadURL => {
				const imageURL = downloadURL;
				dispatch({
					type: ActionTypes.UploadPhotoFulfilled,
					imageURL,
				});
				return imageURL;
			});
		});
	};
}

export function clientSignOut() {
	return {
		type: ActionTypes.SignOutFulfilled,
	};
}

export function completeEdit(email, firstName, lastName, image, imageUrl, lightMode, redirectUrl) {
	return (dispatch, getState) => {
		dispatch({ type: ActionTypes.CompleteEditPending });
		const promise = image ? dispatch(uploadPhoto(email, image)) : Promise.resolve(imageUrl);
		promise
			.then(imageUrl => {
				const { disableExploreAccess } = getState().auth.userInfo;
				return dispatch(updateActor(email, firstName, lastName, imageUrl, lightMode, disableExploreAccess));
			})
			.then(() => {
				if (redirectUrl) {
					dispatch(push(redirectUrl));
				} else {
					dispatch(goBack());
				}
				dispatch({ type: ActionTypes.CompleteEditFulfilled });
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({ type: ActionTypes.CompleteEditRejected });
			});
	};
}

export function sendResetEmail(email) {
	return dispatch => {
		dispatch({ type: ActionTypes.SendResetEmailPending });
		const lsItem = localStorage.getItem('reset-email-ts');
		if (lsItem) {
			const diff = Date.now() - Number(lsItem);
			if (diff < 60000) {
				return dispatch(addMessage('Please try again after a minute', true));
			}
		}
		localStorage.setItem('reset-email-ts', Date.now().toString());
		return firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(() => {
				dispatch({ type: ActionTypes.SendResetEmailFulfilled });
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({ type: ActionTypes.SendResetEmailRejected });
			});
	};
}

export function showForgot() {
	return dispatch => {
		dispatch(push('/forgot'));
	};
}

function ssoLogin(ssoId, redirectUrl, enterpriseId) {
	return dispatch => {
		dispatch({
			type: ActionTypes.LoginPending,
		});
		track(MixPanel.Events.SSOShowProviderPopup, {
			Provider: ssoId,
		});
		const provider =
			ssoId.split('.')[0] === 'oidc'
				? new firebase.auth.OAuthProvider(ssoId)
				: new firebase.auth.SAMLAuthProvider(ssoId);
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(response => {
				return dispatch(processLoginResponse(response, redirectUrl, true, enterpriseId));
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.LoginRejected,
					error,
				});
				return false;
			});
	};
}

export function getSSOOptions(email, redirectUrl = '/') {
	return dispatch => {
		dispatch({ type: ActionTypes.GetSSOOptionsPending });

		const domain = email.split('@')[1];

		return axios
			.post(`${Urls.AccountApi}sso/domain?key=${Constants.FirebaseApiKey}`, {
				domain,
			})
			.then(response => {
				const { sso, enterpriseId } = response.data;
				dispatch({
					type: ActionTypes.GetSSOOptionsFulfilled,
					sso,
				});

				if (sso.length > 0) {
					return dispatch(ssoLogin(sso[0].id, redirectUrl, enterpriseId));
				} else {
					dispatch(push(`/password?email=${email}&redirectUrl=${redirectUrl}`));
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetSSOOptionsRejected,
				});
			});
	};
}

export function setLightMode(lightMode) {
	return {
		type: ActionTypes.SetLightMode,
		lightMode,
	};
}
