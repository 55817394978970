import cn from '../../lib/cn';

const LoadingSkeleton: React.FC = () => {
	return (
		<div className={cn('flex flex-col gap-[.8rem] items-start w-full')}>
			<SkeletonLine className="w-full" />
			<SkeletonLine className="w-[60%]" delay={150} />
			<SkeletonLine className="w-[80%]" delay={300} />
		</div>
	);
};

const SkeletonLine: React.FC<{ className?: string; delay?: number }> = ({ className, delay }) => {
	return (
		<div
			className={cn('h-[10px] rounded-[.4rem] origin-left animate-skeleton scale-x-0', className)}
			style={{
				backgroundImage:
					'linear-gradient(270deg,  var(--color-ui-10) 0%, var(--color-ui-10) 30%, var(--color-ui-30) 50%,  var(--color-ui-10) 70%,  var(--color-ui-10) 100%)',
				backgroundSize: '200% 100%',
				animationDelay: `${delay || 0}ms`,
			}}
		/>
	);
};

export default LoadingSkeleton;
