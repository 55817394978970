import cn from '../../lib/cn';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Button from '../ButtonV2';
import ButtonOld from '../Button';
import MessageBubble from './MessageBubble';
import InsightsReportIcon from '../../icons/InsightsReport';
import CloseIcon from '../../icons/Close';
import { selectReport } from '../../dashboard/aiInsights/reducer';
import ButtonTypes from '../../constants/ButtonTypes';
import { InsightsContext } from '.';
import { useContext, useEffect, useRef } from 'react';
import GradientButton from '../GradientButton';

export default function ReportSelect({ ...rest }: any) {
	const scrollToRef = useRef<any>(null);
	const { handleReportSelectCancel, handleGenerateAiInsights } = useContext(InsightsContext);
	const dispatch = useAppDispatch();
	const { reportIds, reportSelectMode, pending, loaded } = useAppSelector(state => state.dashboard.aiInsights);
	const selectedReports = useAppSelector(state =>
		state.dashboard.view.reports?.filter((r: any) => reportIds?.includes(r.reportId))
	);

	useEffect(() => {
		if (scrollToRef.current) {
			scrollToRef.current.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}, [selectedReports]);

	if (!selectedReports?.length || pending || loaded) {
		return null;
	}

	return (
		<div className={cn('flex flex-col w-avail')} ref={scrollToRef}>
			<MessageBubble {...rest} animate={false}>
				<div className={cn('flex flex-col items-start gap-[1.6rem] ')}>
					<div>Generate insights from these reports:</div>
					<div className={cn('flex items-center flex-wrap gap-[.8rem]')}>
						{selectedReports?.map((r: any, i: number) => (
							<Button
								key={i}
								variant="secondary"
								onClick={() => dispatch(selectReport(r.reportId))}
								className={cn('text-ui-100', 'pl-0')}
							>
								<div className={cn('flex items-center gap-[.8rem]')}>
									<InsightsReportIcon
										className={cn('w-[3.5rem] h-[3.5rem] rounded-full  flex-shrink-0')}
									/>
									<span>{r.name}</span>
									<CloseIcon className={cn('flex-shrink-0 w-[1.5rem] h-[1.5rem] text-ui-50')} />
								</div>
							</Button>
						))}
					</div>
				</div>
			</MessageBubble>
			{reportSelectMode && (
				<div className={cn('flex items-center gap-[.8rem] justify-end w-full')}>
					<ButtonOld onClick={handleReportSelectCancel} componentType={ButtonTypes.type.SECONDARY}>
						Cancel
					</ButtonOld>
					<GradientButton disabled={!selectedReports?.length} onClick={handleGenerateAiInsights}>
						<div className={cn('flex items-center gap-[.8rem]')}>Generate insights</div>
					</GradientButton>
				</div>
			)}
		</div>
	);
}
