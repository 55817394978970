import { uniq } from 'lodash';
import * as uuid from 'uuid';
import stripSpaces from '../../lib/stripSpaces';
import { IStateAnalysisPermission } from './models';

function constructDynamicPermissionsCondition({
	propertyPath,
	propertyType = 'empty',
	fields,
}: {
	propertyPath: string;
	propertyType?: 'string' | 'array' | 'empty';
	fields: string[];
}) {
	const uniqueFields = uniq(fields);
	let should: 'contains' | '' | '==' = '';
	let to: any = {};

	// it's empty
	if (uniqueFields.length === 0) {
		to = {
			Right: [''],
		};
		return {
			propertyPath,
			propertyType,
			should,
			to,
		};
	}

	if (uniqueFields.length === 1) {
		const field = uniqueFields[0];
		should = 'contains';

		if (field === '*') {
			to = {
				Left: field,
			};
		} else {
			to = {
				Right: [field],
			};
		}
	} else {
		should = 'contains';
		to = {
			Right: [...uniqueFields],
		};
	}

	return {
		propertyPath,
		propertyType,
		should,
		to,
	};
}

export default function constructDynamicPermissionsForPayload({
	analysisPermissions,
	hideEmployeeCount,
}: {
	analysisPermissions: IStateAnalysisPermission[];
	hideEmployeeCount: boolean;
	attributes: any[];
}) {
	const dynamicPermissions: any[] = [];

	const createPermission = ({
		additionalPermissions,
		analysises,
		field4,
		field4Values,
		field5,
		field5Values,
		segmentField,
		segmentFieldValues,
	}: {
		additionalPermissions: any[];
		analysises: any[];
		field4: any;
		field4Values: any;
		field5: any;
		field5Values: any;
		segmentField: any;
		segmentFieldValues: any;
	}) => {
		const conditions: any[] = [
			...additionalPermissions,
			constructDynamicPermissionsCondition({
				propertyPath: '$.analysisName',
				fields: analysises.map((a: any) => a.analysisName),
				propertyType: 'string',
			}),
		];

		if (field4) {
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.field4.fieldName',
					fields: field4,
					propertyType: field4.length ? 'string' : 'empty',
				})
			);
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.field4.fieldValues',
					fields: field4Values,
					propertyType: field4Values.length ? 'array' : 'empty',
				})
			);
		}

		if (field5) {
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.field5.fieldName',
					fields: field5,
					propertyType: field5.length ? 'string' : 'empty',
				})
			);
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.field5.fieldValues',
					fields: field5Values,
					propertyType: field5Values.length ? 'array' : 'empty',
				})
			);
		}

		if (segmentField) {
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.segmentField.fieldName',
					fields: segmentField,
					propertyType: segmentField.length ? 'string' : 'empty',
				})
			);
			conditions.push(
				constructDynamicPermissionsCondition({
					propertyPath: '$.segmentField.fieldValues',
					fields: segmentFieldValues,
					propertyType: segmentFieldValues.length ? 'array' : 'empty',
				})
			);
		}

		return {
			id: uuid.v4(),
			mask: hideEmployeeCount
				? [
						'$.values.percentageResults.[*].percentageMeta.includedCount',
						'$.values.percentageResults.[*].percentageMeta.totalIncludedCount',
						'$.values.percentageResults.[*].percentageMeta.totalExcludedCount',
						'$.values.percentageResults.[*].percentageMeta.excludedCount',
						'$.values.percentageResults.[*].percentageMeta.totalCount',
						'$.values.percentageResults.[*].percentageMeta.globalTotalCount',
						'$.values.percentageResults.[*].percentageMeta.globalIncludedCount',
						'$.values.percentageResults.[*].percentageMeta.globalExcludedCount',
						'$.values.indexResults[*].indexMeta.totalCount',
						'$.values.indexResults[*].indexMeta.includedCount',
						'$.values.indexResults[*].indexMeta.excludedCount',
						'$.values.linearRegressionResults[*].compMeta.totalCount',
				  ]
				: [''],
			resource: 'post:/explore',
			conditions,
		};
	};

	analysisPermissions.forEach((p: IStateAnalysisPermission) => {
		const { analysises, segments, filters, breakdowns } = p;

		const createPermissionsSet = (additionalPermissions: any[]) => {
			const filtersAny = filters.some(f => f.name === '*');
			const breakdownsEmpty = breakdowns.length === 0;
			const filtersEmpty = filters.length === 0;
			const filtersEmptyWithBreakdown = filtersEmpty && !breakdownsEmpty;

			let field4: any = [];
			let field4Secondary: any = [];
			let field4Values: any = [];
			let field4SecondaryValues: any = [];
			let field5: any = [];
			let field5Secondary: any = [];
			let field5Values: any = [];
			let field5SecondaryValues: any = [];
			let segmentField: any = [];
			let segmentFieldSecondary: any = [];
			let segmentFieldValues: any = [];
			let segmentFieldSecondaryValues: any = [];

			if (!filtersEmpty) {
				const filtersValues = filtersAny ? ['*'] : filters.map(f => f.values).flat();
				field4 = filters.map((f: any) => stripSpaces(f.name));
				field4Values = filtersValues;
				field4Secondary = filters.map((f: any) => stripSpaces(f.name));
				field4SecondaryValues = filtersValues;
			}
			if (filtersEmptyWithBreakdown) {
				field4 = breakdowns.map((f: any) => stripSpaces(f.name));
				field4Values = ['*'];
				segmentFieldSecondary = breakdowns.map((f: any) => stripSpaces(f.name));
				segmentFieldSecondaryValues = ['*'];
			}

			if (!filtersEmpty && !breakdownsEmpty) {
				const filtersValues = filtersAny ? ['*'] : filters.map(f => f.values).flat();
				field4 = filters.map((f: any) => stripSpaces(f.name));
				field4Values = filtersValues;
				field4Secondary = filters.map((f: any) => stripSpaces(f.name));
				field4SecondaryValues = filtersValues;
				field5 = breakdowns.map((f: any) => stripSpaces(f.name));
				field5Values = ['*'];
				field5Secondary = null;
				field5SecondaryValues = null;
				segmentField = null;
				segmentFieldValues = null;
				segmentFieldSecondary = breakdowns.map((f: any) => stripSpaces(f.name));
				segmentFieldSecondaryValues = ['*'];
			}

			// We need to in order to accomodate certain combinations between for example field5 / segmentField.
			return [
				createPermission({
					additionalPermissions,
					analysises,
					field4,
					field4Values,
					field5,
					field5Values,
					segmentField,
					segmentFieldValues,
				}),
				createPermission({
					additionalPermissions,
					analysises,
					field4: field4Secondary,
					field4Values: field4SecondaryValues,
					field5: field5Secondary,
					field5Values: field5SecondaryValues,
					segmentField: segmentFieldSecondary,
					segmentFieldValues: segmentFieldSecondaryValues,
				}),
			];
		};

		const segmentsAny = segments.some(s => s.name === '*');

		// 1 segment

		dynamicPermissions.push(
			...createPermissionsSet([
				constructDynamicPermissionsCondition({
					propertyPath: '$.field1.fieldName',
					fields: segments.map((s: any) => stripSpaces(s.name)),
					propertyType: 'string',
				}),
				constructDynamicPermissionsCondition({
					propertyPath: '$.field1.fieldValues',
					fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
					propertyType: 'array',
				}),
				...(!segmentsAny
					? [
							constructDynamicPermissionsCondition({
								propertyPath: '$.field2.fieldName',
								fields: [],
							}),
							constructDynamicPermissionsCondition({
								propertyPath: '$.field3.fieldName',
								fields: [],
							}),
					  ]
					: []),
			])
		);

		// 2 segments

		if (segments.length > 1) {
			dynamicPermissions.push(
				...createPermissionsSet([
					constructDynamicPermissionsCondition({
						propertyPath: '$.field1.fieldName',
						fields: segments.map((s: any) => stripSpaces(s.name)),
						propertyType: 'string',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field1.fieldValues',
						fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
						propertyType: 'array',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field2.fieldName',
						fields: segments.map((s: any) => stripSpaces(s.name)),
						propertyType: 'string',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field2.fieldValues',
						fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
						propertyType: 'array',
					}),
					...(!segmentsAny
						? [
								constructDynamicPermissionsCondition({
									propertyPath: '$.field3.fieldName',
									fields: [],
								}),
						  ]
						: []),
				])
			);
		}

		// 2+ segments

		if (segments.length > 2) {
			dynamicPermissions.push(
				...createPermissionsSet([
					constructDynamicPermissionsCondition({
						propertyPath: '$.field1.fieldName',
						fields: segments.map((s: any) => stripSpaces(s.name)),
						propertyType: 'string',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field1.fieldValues',
						fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
						propertyType: 'array',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field2.fieldName',
						fields: segments.map((s: any) => stripSpaces(s.name)),
						propertyType: 'string',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field2.fieldValues',
						fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
						propertyType: 'array',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field3.fieldName',
						fields: segments.map((s: any) => stripSpaces(s.name)),
						propertyType: 'string',
					}),
					constructDynamicPermissionsCondition({
						propertyPath: '$.field3.fieldValues',
						fields: segmentsAny ? ['*'] : segments.map((s: any) => s.values).flat(),
						propertyType: 'array',
					}),
				])
			);
		}
	});

	return dynamicPermissions;
}
