import axios from 'axios';
import { goBack } from 'connected-react-router';
import moment from 'moment';

import { setScrollPosition } from '../../common/actions';
import ActionTypes from '../../constants/ActionTypes';
import mixPanel from '../../constants/MixPanel';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { track } from '../../lib/segment';
import { closeDeleteReportModal } from '../view/actions';

export function moveGraph(from: any, to: any) {
	return (dispatch: any, getState: any) => {
		const { name, reports } = getState().dashboard.view;
		const report = reports.find((r: any) => r.reportId === from.id);
		if (report) {
			track(mixPanel.Events.EditDashboardMoveReport, {
				'Dashboard Name': name,
				'Report Name': report.name,
			});
		}
		dispatch({
			type: ActionTypes.MoveGraph,
			from,
			to,
		});
	};
}

export function removeRow(row: number) {
	return {
		type: ActionTypes.RemoveRow,
		row,
	};
}

export function moveGraphInNewRow(item: any, newRow: any) {
	return {
		type: ActionTypes.MoveGraphInNewRow,
		item,
		newRow,
	};
}

export function incrementLength(reportId: string) {
	return {
		type: ActionTypes.IncrementLength,
		reportId,
	};
}

export function resize(reportId: string, length: number) {
	return (dispatch: any, getState: any) => {
		const { name, reports } = getState().dashboard.view;
		const report = reports.find((r: any) => r.reportId === reportId);
		if (report) {
			track(mixPanel.Events.EditDashboardResizeReport, {
				'Dashboard Name': name,
				'Report Name': report.name,
			});
		}
		dispatch({
			type: ActionTypes.Resize,
			reportId,
			length,
		});
	};
}

export function setDashboardName(name: string) {
	return {
		type: ActionTypes.SetDashboardName,
		name,
	};
}

export function updateDashboard(goBackAfter: boolean = true) {
	return async (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		const { dashboardId, name } = getState().dashboard.view;

		const { reports, dateAdded, createdById, createdByName } = getState().dashboards[dashboardId];

		dispatch({
			type: ActionTypes.UpdateDashboardPending,
		});

		const dashboard = {
			enterpriseId,
			dashboardId,
			name,
			reports,
			dateAdded,
			createdById,
			createdByName,
			lastUpdatedAt: moment.utc().toISOString(),
		};

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, { dashboard })
			.then(() => {
				dispatch({
					type: ActionTypes.UpdateDashboardFulfilled,
					dashboard,
				});
				if (goBackAfter) {
					dispatch(setScrollPosition(window.pageYOffset));
					dispatch(goBack());
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateDashboardRejected,
				});
			});
	};
}

export function deleteReportLocal() {
	return (dispatch: any, getState: any) => {
		const reportId = getState().reports.deleteReportId;
		dispatch({
			type: ActionTypes.DeleteReportLocal,
			reportId,
		});
		dispatch(closeDeleteReportModal());
	};
}

export function setHasHiddenReports(hasHiddenReports: boolean) {
	return {
		type: ActionTypes.SetHasHiddenReports,
		hasHiddenReports,
	};
}

export function setNotifyAboutHiddenReports(notifyAboutHiddenReports: boolean) {
	return {
		type: ActionTypes.SetNotifyAboutHiddenReports,
		notifyAboutHiddenReports,
	};
}

export function setDashboard(dashboard: any) {
	return {
		type: ActionTypes.SetViewDashboard,
		dashboard,
	};
}
