import { StaticContext, useParams } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Stack from '../../../../common/Stack';
import { Survey } from '../../../../datastudio/survey/template/types';
import TopLeftAction from '../../../../layout/TopLeftAction';
import { useAppSelector } from '../../../../store/hooks';

interface HeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const Header = (props: HeaderProps) => {
	const {
		location: { state },
	} = props;
	const { data } = useAppSelector(state => state.surveyTemplates.list);

	const params = useParams<any>();

	const survey = data.find((survey: Survey) => survey.globalSurveyId === params.id);

	if (!survey) {
		return null;
	}

	return (
		<Stack
			flexDirection="row"
			alignItems="center"
			gap="2rem"
			style={{
				width: '100%',
				paddingRight: '1.6rem',
			}}
		>
			<TopLeftAction scrollPos={state?.scrollPos} />
			<div className="nav__header__heading">
				<h3 className="font-title">{survey.title}</h3>
			</div>
		</Stack>
	);
};

export default withRouter(Header);
