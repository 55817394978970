import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Dropdown from '../../../../common/DropdownV2';

import FilterButton from './FilterButton';
import cn from '../../../../lib/cn';

export default function FilterDropdown({
	children,
	isOpen,
	setIsOpen,
	label,
	placeholder,
	disabled,
	isSecondary,
	allowCancel,
	onClearSelection,
	onClick,
}) {
	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', handleKeyDown);
			window.addEventListener('mousedown', handleClickOutside);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				window.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [isOpen]);

	const containerRef = useRef();

	function handleClick() {
		setIsOpen(!isOpen);
	}

	function handleKeyDown(e) {
		if (e.keyCode === 27) {
			setIsOpen(false);
		}
	}

	function handleClickOutside(e) {
		if (isOpen) {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				e.stopPropagation();
				setIsOpen(false);
			}
		}
	}

	return (
		<div style={{ display: 'flex', flexShrink: '1', minWidth: '0' }}>
			<Dropdown
				onClick={onClick}
				disabled={disabled}
				label={label}
				placeholder={placeholder}
				buttonLarge={true}
				data-test={`dropdown-${placeholder}`}
				ref={containerRef}
				isOpen={isOpen}
				onClearSelection={onClearSelection}
			>
				<div className={cn('flex items-start')}>{children}</div>
			</Dropdown>
		</div>
	);
}

FilterDropdown.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	children: PropTypes.any,
	disabled: PropTypes.bool,
	isSecondary: PropTypes.bool,
	allowCancel: PropTypes.bool,
	onClearSelection: PropTypes.func,
	onClick: PropTypes.func,
};
