import Menu from 'antd/lib/menu';
import { push } from 'connected-react-router';
import { useState } from 'react';
import { useParams } from 'react-router';

import MixPanel from '../constants/MixPanel';
import { track } from '../lib/segment';
import { useAppDispatch } from '../store/hooks';
import EditConversationNameModal from './EditConversationNameModal';
import { deleteConversationId } from './chat/reducer';
import { deleteChatConversation } from './conversations/actions';

interface ConversationMenuProps {
	conversationId: string;
	conversationName: string;
	isModal?: boolean;
	onClose: () => void;
}

function ConversationMenu({ conversationId, conversationName, onClose, isModal }: ConversationMenuProps) {
	const dispatch = useAppDispatch();
	const { conversationId: currentId } = useParams<any>();
	const [editNameModalOpen, setEditNameModalOpen] = useState(false);

	function handleEdit() {
		track(isModal ? MixPanel.Events.AIChatModalClickHistoryRename : MixPanel.Events.AIChatPageClickHistoryRename);
		setEditNameModalOpen(true);
		onClose();
	}

	function handleDelete() {
		track(isModal ? MixPanel.Events.AIChatModalClickHistoryDelete : MixPanel.Events.AIChatPageClickHistoryDelete);
		dispatch(deleteChatConversation(conversationId));
		if (conversationId === currentId) {
			!isModal && dispatch(push(`/ai`));
			dispatch(deleteConversationId());
		}
		onClose();
	}

	return (
		<Menu className="widget-menu dashboards-menu" selectedKeys={[]}>
			<Menu.Item onClick={handleEdit}>Rename</Menu.Item>
			<Menu.Item onClick={handleDelete}>Delete</Menu.Item>
			<EditConversationNameModal
				isOpen={editNameModalOpen}
				onClose={() => setEditNameModalOpen(false)}
				conversationId={conversationId}
				conversationName={conversationName}
			/>
		</Menu>
	);
}

export default ConversationMenu;
