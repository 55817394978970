import { useReducer } from 'react';
import reducer from './reducer';
import { DropdownState } from './types';

const initialState: DropdownState = {
	parentId: '',
	initialized: false,
	multiLevel: false,
	label: undefined,
	level1: {
		typeId: '',
		type: 'single',
	},
	level2: {
		typeId: '',
		type: 'single',
	},
	isOpen: false,
	activeId: undefined,
	data: [],
	status: 'idle',
	lookup: {},
	reduxAction: undefined,
};

export default function useDropdown(defaultState: any) {
	// @ts-ignore
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		...defaultState,
	});

	return [state, dispatch];
}
