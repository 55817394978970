import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import debounce from 'lodash/debounce';

import Divider from '../common/Divider';
import LottieAnimation from '../common/LottieAnimation';
import WithPermissions from '../common/WithPermissions';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';
import { page, track } from '../lib/segment';
import Loader from '../lottie/graph-loader.json';
import {
	closeDeleteDashboardModal,
	deleteDashboard,
	getDashboards,
	getSharedDashboards,
	navigateToDashboard,
	showCreateDashboardModal,
} from './actions';
import Card from './Card';
import DeleteDashboardModal from './DeleteDashboardModal';
import EmptySection from './EmptySection';
import List from './List';
import ShareModal from './ShareModal';
import './styles.scss';

const CARD_GAP = 16;
const DASHBOARDS_SCROLL_POSITION = 'DASHBOARDS_SCROLL_POSITION';

function Dashboards({
	dashboards,
	pending,
	showDelete,
	showTiles,
	getDashboards,
	getSharedDashboards,
	navigateToDashboard,
	showCreateDashboardModal,
	closeDeleteDashboardModal,
	deleteDashboard,
	deletePending,
	searchText,
	role,
	enterpriseIdCompleted,
}) {
	const contentRef = useRef();
	const [width, setWidth] = useState(0);
	const [scrollValue, setScrollValue] = useState(0);
	// to prevent empty state flickers
	const fetchingRef = useRef(false);
	const hasViewAllPermissions = usePermissions({
		actions: [RbacActions['Dashboards/ViewAll']],
	});
	const isEmptyState = !pending && fetchingRef.current && dashboards.length === 0;

	function handleResize() {
		if (contentRef.current) {
			const updatedWidth = (contentRef.current.clientWidth - 2 * CARD_GAP) / 4;
			setWidth(updatedWidth);
		}
	}

	useEffect(() => {
		return () => {
			sessionStorage.setItem(DASHBOARDS_SCROLL_POSITION, scrollValue.toString());
		};
	}, [scrollValue]);

	useEffect(() => {
		if (pending || !fetchingRef || !fetchingRef.current) return;
		const onScroll = e => {
			setScrollValue(e.target.documentElement.scrollTop);
		};
		const scrollPosition = sessionStorage.getItem(DASHBOARDS_SCROLL_POSITION);
		if (scrollPosition) {
			window.removeEventListener('scroll', onScroll);
			setScrollValue(parseFloat(scrollPosition));
			window.scrollTo(0, parseFloat(scrollPosition));
			sessionStorage.removeItem(DASHBOARDS_SCROLL_POSITION);
			setTimeout(() => {
				window.addEventListener('scroll', onScroll);
			}, 300);
		} else {
			window.addEventListener('scroll', onScroll);
		}

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [pending, fetchingRef.current]);

	useEffect(() => {
		page(MixPanel.Pages.Dashboards);
		if (dashboards.length === 0) {
			if (hasViewAllPermissions) {
				getDashboards();
			} else {
				getSharedDashboards();
			}
		}
		fetchingRef.current = true;
		handleResize();
		const handleResizeDebounced = debounce(handleResize, 200, {
			leading: true,
		});
		window.addEventListener('resize', handleResizeDebounced);
		return () => {
			window.removeEventListener('resize', handleResizeDebounced);
		};
	}, [hasViewAllPermissions]);

	useEffect(() => {
		if (enterpriseIdCompleted) {
			if (hasViewAllPermissions) {
				getDashboards();
			} else {
				getSharedDashboards();
			}
		}
	}, [enterpriseIdCompleted, hasViewAllPermissions]);

	function handleCardClick(dashboardId, dashboardName) {
		track(MixPanel.Events.MyDashboardsRecentDashboardClick, {
			'Dashboard Name': dashboardName,
		});
		navigateToDashboard(dashboardId);
	}

	const filteredDashboards = searchText
		? dashboards.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()))
		: dashboards;

	return (
		<WithPermissions
			actions={[RbacActions['Dashboards/ViewAll'], RbacActions['Dashboards/ViewShared']]}
			showMessage={true}
		>
			<div className="dashboards">
				<div className="dashboards__content">
					<div className="dashboards__cards" ref={contentRef} />
					{filteredDashboards.length > 0 && (
						<div className="dashboards__section">
							<div className="dashboards__section__title">All Dashboards</div>
							<Divider></Divider>
							{showTiles ? (
								!!width && (
									<div className="dashboards__cards">
										{filteredDashboards.map((d, id) => (
											<Card
												key={id}
												dashboard={d}
												width={width}
												onClick={() => handleCardClick(d.dashboardId, d.name)}
												role={role}
											/>
										))}
									</div>
								)
							) : (
								<div className="dashboards__list">
									<List
										dashboards={filteredDashboards}
										navigateToDashboard={navigateToDashboard}
										role={role}
									/>
								</div>
							)}
						</div>
					)}
					{isEmptyState && (
						<EmptySection width={width} showCreateDashboardModal={showCreateDashboardModal} role={role} />
					)}
				</div>
				{pending && filteredDashboards.length === 0 && (
					<div className="dashboards__empty">
						<LottieAnimation
							animation={Loader}
							autoplay={true}
							loop={true}
							width={100}
							height={100}
						></LottieAnimation>
					</div>
				)}
				{!pending && searchText && !isEmptyState && filteredDashboards.length === 0 && (
					<div className="dashboards__empty dashboards__empty--search">
						Sorry, no dashboards match your search query
					</div>
				)}
				<CSSTransition in={isEmptyState} timeout={200} classNames="fade" mountOnEnter unmountOnExit>
					<div className="dashboards__empty-state-image">
						<img src="/images/empty-state-bars.svg" />
					</div>
				</CSSTransition>
				<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
					<DeleteDashboardModal
						isOpen={showDelete}
						onCancel={closeDeleteDashboardModal}
						onAction={deleteDashboard}
						actionPending={deletePending}
					/>
				</WithPermissions>
				<WithPermissions actions={[RbacActions['Dashboards/Share']]}>
					<ShareModal />
				</WithPermissions>
			</div>
		</WithPermissions>
	);
}

Dashboards.defaultProps = {
	dashboards: [],
};

function mapState(state) {
	const dashboards = state.myDashboards.data.map(d => {
		return state.dashboards[d];
	});

	return {
		pending: state.myDashboards.pending,
		dashboards,
		showDelete: state.dashboards.showDelete,
		showTiles: state.dashboards.showTiles,
		deletePending: state.dashboards.deletePending,
		searchText: state.myDashboards.searchText,
		role: state.auth.role,
		enterpriseIdCompleted: state.appState.enterpriseIdCompleted,
	};
}

const dispatchProps = {
	getDashboards,
	getSharedDashboards,
	navigateToDashboard,
	showCreateDashboardModal,
	closeDeleteDashboardModal,
	deleteDashboard,
};

export default connect(mapState, dispatchProps)(Dashboards);
