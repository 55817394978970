import orderBy from 'lodash/orderBy';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { Category, CategoryState } from './types';

export const fetchCategories = createAsyncThunk<CategoryState, void, AsyncThunkConfig>(
	'survey/fetchCategories',
	async () => {
		let pageToken;
		let allData: Category[] = [];
		do {
			const response: any = await axios.post(`${Urls.SurveyStudioApi}categories`, {
				pageToken,
			});
			allData = [...allData, ...response.data.categories];
			pageToken = response.data.pageToken;
		} while (pageToken);

		const data: Category[] = [];
		const archived: Category[] = [];
		allData.forEach(cat => {
			const archiveElem = cat.meta.uiElements.find(u => u.key === 'archive');
			if (archiveElem?.value === 'true') {
				archived.push(cat);
			} else {
				data.push(cat);
			}
		});

		return { data, archived };
	}
);

export const categoriesSlice = createSlice({
	name: 'auth',
	initialState: { data: [], archived: [] } as CategoryState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchCategories.fulfilled, (state, action) => {
			const { data, archived } = action.payload;
			state.data = data;
			state.archived = archived;
			state.pending = false;
			state.loaded = true;
		});
		builder.addCase(fetchCategories.pending, (state, action) => {
			state.pending = true;
			state.error = false;
		});
		builder.addCase(fetchCategories.rejected, (state, action) => {
			state.error = true;
			state.pending = false;
		});
	},
});

export default categoriesSlice.reducer;
