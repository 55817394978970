import * as uuid from 'uuid';
import errorHandler from '../../lib/errorHandler';
import { generateCoverSlideTemplate, addRowSlide, deleteTempImagesFromFirebase } from '../../lib/exportUtils';
import { getGlobalLayoutState } from '../helpers';
import { getReportsByRowAdjusted } from './exportPpt';

const COLUMNS = 12;
const WIDTH = 720;
const HEIGHT = 405;
const MARGIN_LEFT = 30;
const MARGIN_TOP = 45;
const PADDING = 16;
const GUTTERS = MARGIN_LEFT * 2;
const COLUMN_GAP = 20;
const TITLE_HEIGHT = 36;
const HALF_BODY_WIDTH = (WIDTH - 2 * MARGIN_LEFT - PADDING) / 2;
const BODY_HEIGHT = HEIGHT - (2 * MARGIN_TOP + TITLE_HEIGHT + 2 * PADDING);
const MAX_WIDTH = WIDTH - GUTTERS;
const COLUMN_WIDTH_PERC = 100 / COLUMNS;
const COLUMN_WIDTH = (MAX_WIDTH - (COLUMNS - 1) * COLUMN_GAP) / COLUMNS;
const FONT_FAMILY = 'Arial';
const FONT_XSMALL = 10;
const FONT_SMALL = 13;
const FONT_MEDIUM = 18;
const FONT_LARGE = 24;
const FONT_XLARGE = 32;

const PARAMS = {
	WIDTH,
	HEIGHT,
	MARGIN_LEFT,
	MARGIN_TOP,
	PADDING,
	TITLE_HEIGHT,
	HALF_BODY_WIDTH,
	BODY_HEIGHT,
	FONT_FAMILY,
	FONT_XSMALL,
	FONT_LARGE,
	FONT_MEDIUM,
	FONT_XLARGE,
	FONT_SMALL,
	COLUMN_GAP,
	COLUMN_WIDTH,
	COLUMNS,
	COLUMN_WIDTH_PERC,
};

function getCoverSlideRequests(dashboardName, enterpriseName, slideIdToDelete) {
	const slideId = uuid.v4();
	const lineId = uuid.v4();
	const yearId = uuid.v4();
	const confidentialId = uuid.v4();
	const nameId = uuid.v4();
	const enterpriseId = uuid.v4();
	const dateId = uuid.v4();

	const imageWidth = 801;
	const imageHeight = 351;
	const IMAGE_SCALE = HALF_BODY_WIDTH / imageWidth;
	const RESIZED_IMAGE_HEIGHT = IMAGE_SCALE * imageHeight;

	return generateCoverSlideTemplate(dashboardName, enterpriseName, {
		RESIZED_IMAGE_HEIGHT,
		IMAGE_SCALE,
		insertionIndex: 0,
		clientType: 'gapi',
		slideId,
		lineId,
		yearId,
		confidentialId,
		nameId,
		enterpriseId,
		dateId,
		slideIdToDelete,
		...PARAMS,
	});
}

export default async function exportSlides(dashboard, reports, enterpriseName) {
	try {
		const gApiResponse = await gapi.client.slides.presentations.create({
			title: dashboard.name,
		});

		const { presentationId, slides } = gApiResponse.result;

		const coverSlideRequests = getCoverSlideRequests(dashboard.name, enterpriseName, slides[0].objectId);

		const isLayoutV2 = dashboard.reports.some(r =>
			r.configuration.find(c => c?.name === 'layoutVersion' && c?.value === 'v2')
		);

		const reportsByRow = getGlobalLayoutState(dashboard.reports, reports, isLayoutV2);
		const reportsByRowAdjusted = getReportsByRowAdjusted(reportsByRow, dashboard.reports);

		const allPresentationRequests = await Promise.all(
			reportsByRowAdjusted.map(async (row, i) => {
				return await addRowSlide({
					client: gapi.client.slides.presentations,
					presentationId,
					clientType: 'gapi',
					reports,
					dashboard,
					row,
					enterpriseName,
					pageNumber: i + 2,
					PARAMS,
				});
			})
		);

		await gapi.client.slides.presentations.batchUpdate({
			presentationId,
			requests: [...allPresentationRequests.reverse().flat(), ...coverSlideRequests],
		});

		deleteTempImagesFromFirebase(dashboard.reports.map(r => r.reportId));

		window.open(`https://docs.google.com/presentation/d/${presentationId}/edit`, '_blank', 'noopener,noreferrer');
	} catch (err) {
		console.log(JSON.stringify(err));
		errorHandler.report(err);
	}
}
