import { TypedKeyValuePair } from '../types';

const momentIntervalKeys: TypedKeyValuePair<any> = {
	year: 'y',
	quarter: 'Q',
	month: 'M',
};

export function getMomentIntervalKey(interval: string) {
	return momentIntervalKeys[interval.toLowerCase()];
}
