import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
		<path
			fill="currentColor"
			d="m17 18.667-3.5-3.438a8.099 8.099 0 0 1-1.646.448c-.611.105-1.23.157-1.854.157-2.028 0-3.868-.566-5.52-1.698-1.654-1.132-2.869-2.65-3.647-4.552A9.454 9.454 0 0 1 1.99 7.469 11.842 11.842 0 0 1 3.792 5.5L1.167 2.875l.875-.896 15.77 15.771-.812.917Zm-7-5.542c.194 0 .403-.017.625-.052.222-.034.41-.087.562-.156l-4.52-4.52a2.55 2.55 0 0 0-.157.562 3.795 3.795 0 0 0-.052.625c0 1 .347 1.84 1.042 2.52a3.445 3.445 0 0 0 2.5 1.021Zm5.792.834-2.688-2.688c.139-.222.247-.482.323-.781.076-.299.115-.6.115-.906 0-.986-.344-1.823-1.032-2.51-.687-.688-1.524-1.032-2.51-1.032-.306 0-.604.035-.896.104-.292.07-.555.18-.792.333L6.021 4.167c.486-.222 1.107-.417 1.864-.583.757-.167 1.497-.25 2.22-.25 1.985 0 3.801.566 5.447 1.698 1.646 1.132 2.85 2.649 3.615 4.552a10.296 10.296 0 0 1-1.396 2.437c-.57.736-1.23 1.382-1.98 1.938Zm-3.605-3.604L9.23 7.396c.403-.153.82-.184 1.25-.094.43.09.806.289 1.125.594.32.32.542.677.667 1.073.125.396.097.858-.084 1.386Z"
		/>
	</svg>
);
export default SvgComponent;
