import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={132} height={168} viewBox="0 0 132 168" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M14.776 168c-4.104 0-7.593-1.433-10.466-4.298C1.437 160.836 0 157.357 0 153.263V70.737c0-4.094 1.437-7.573 4.31-10.439C7.183 57.433 10.672 56 14.776 56h14.776V36.35c0-10.151 3.53-18.748 10.59-25.789C47.202 3.521 55.82 0 66 0c10.18 0 18.799 3.52 25.858 10.561 7.06 7.041 10.59 15.638 10.59 25.79V56h14.776c4.104 0 7.593 1.433 10.466 4.298 2.873 2.866 4.31 6.345 4.31 10.439v82.526c0 4.094-1.437 7.573-4.31 10.439-2.873 2.865-6.362 4.298-10.466 4.298H14.776Zm0-6.877h102.448c2.298 0 4.186-.737 5.664-2.211 1.478-1.473 2.216-3.356 2.216-5.649V70.737c0-2.293-.738-4.176-2.216-5.65-1.478-1.473-3.366-2.21-5.664-2.21H14.776c-2.298 0-4.186.737-5.664 2.21-1.478 1.474-2.216 3.357-2.216 5.65v82.526c0 2.293.738 4.176 2.216 5.649 1.478 1.474 3.366 2.211 5.664 2.211ZM66 125.263c3.776 0 6.937-1.269 9.481-3.807 2.545-2.538 3.818-5.69 3.818-9.456 0-3.766-1.273-6.918-3.818-9.456-2.544-2.538-5.705-3.807-9.481-3.807s-6.937 1.269-9.481 3.807c-2.545 2.538-3.817 5.69-3.817 9.456 0 3.766 1.272 6.918 3.817 9.456 2.544 2.538 5.705 3.807 9.481 3.807ZM36.448 56h59.104V36.35c0-8.186-2.873-15.145-8.62-20.876C81.188 9.743 74.21 6.877 66 6.877c-8.209 0-15.187 2.866-20.933 8.597-5.746 5.73-8.62 12.69-8.62 20.877V56Z"
		/>
	</svg>
);
export default SvgComponent;
