import pptxgen from 'pptxgenjs';
import ChartTypes from '../../constants/ChartTypes';
import { fromPPI, generateCoverSlideTemplate, addRowSlide } from '../../lib/exportUtils';
import { getGlobalLayoutState } from '../helpers';

const COLUMNS = 12;
const WIDTH = 10;
const HEIGHT = 5.625;
const MARGIN_LEFT = 0.417;
const MARGIN_TOP = 0.625;
const GUTTERS = MARGIN_LEFT * 2;
const COLUMN_GAP = fromPPI(20);
const MAX_WIDTH = WIDTH - GUTTERS;
const COLUMN_WIDTH_PERC = 100 / COLUMNS;
const COLUMN_WIDTH = (MAX_WIDTH - (COLUMNS - 1) * COLUMN_GAP) / COLUMNS;
const HALF_BODY_WIDTH = (WIDTH - 2 * MARGIN_LEFT - fromPPI(20)) / 2;
const BODY_HEIGHT = HEIGHT - 0.5 - (2 * MARGIN_TOP + fromPPI(40));
const FONT_FAMILY = 'Arial';
const FONT_XSMALL = 10;
const FONT_SMALL = 13;
const FONT_MEDIUM = 18;
const FONT_LARGE = 24;
const FONT_XLARGE = 32;

const PARAMS = {
	WIDTH,
	HEIGHT,
	MARGIN_LEFT,
	MARGIN_TOP,
	GUTTERS,
	MAX_WIDTH,
	COLUMN_WIDTH,
	HALF_BODY_WIDTH,
	BODY_HEIGHT,
	COLUMN_GAP,
	FONT_FAMILY,
	FONT_XSMALL,
	FONT_SMALL,
	FONT_MEDIUM,
	FONT_LARGE,
	FONT_XLARGE,
	COLUMNS,
	COLUMN_WIDTH_PERC,
};

function addCoverSlide(ppt, dashboardName, enterpriseName) {
	const coverSlide = ppt.addSlide();
	const imageWidth = 801;
	const imageHeight = 351;
	const RESIZED_IMAGE_HEIGHT = (imageHeight / imageWidth) * PARAMS.HALF_BODY_WIDTH;

	generateCoverSlideTemplate(dashboardName, enterpriseName, {
		client: ppt,
		clientType: 'pptxgen',
		slide: coverSlide,
		RESIZED_IMAGE_HEIGHT,
		...PARAMS,
	});
}

export function getReportsByRowAdjusted(reportsByRow, reports) {
	const rows = [];

	reportsByRow.forEach(row => {
		const textReports = row.columns.filter(col => {
			const report = reports.find(r => r.reportId === col.id);
			const chartType = report?.configuration.find(c => c.name === 'chartType')?.value;

			if (chartType === ChartTypes.Text || chartType === ChartTypes.InsightsText) {
				return true;
			}

			return false;
		});

		if (textReports.length > 0 && row.columns.length > 1) {
			const regularReports = row.columns.filter(col => !textReports.map(r => r.id).includes(col.id));

			rows.push({
				columns: regularReports,
			});

			textReports.forEach(r => {
				rows.push({
					columns: [r],
				});
			});
		} else {
			rows.push({
				...row,
				columns: row.columns,
			});
		}

		return;
	});

	return rows.map(row => {
		const isNotHundred = row.columns.reduce((sum, item) => sum + item.columnWidthPerc, 0) !== 100;

		if (isNotHundred) {
			return {
				...row,
				columns: row.columns.map(col => ({
					...col,
					columnWidthPerc: 100 / row.columns.length,
				})),
			};
		}

		return row;
	});
}

export default async function exportPpt(dashboard, reports, enterpriseName) {
	const ppt = new pptxgen();
	addCoverSlide(ppt, dashboard.name, enterpriseName);

	const isLayoutV2 = dashboard.reports.some(r =>
		r.configuration.find(c => c?.name === 'layoutVersion' && c?.value === 'v2')
	);

	const reportsByRow = getGlobalLayoutState(dashboard.reports, reports, isLayoutV2);
	const reportsByRowAdjusted = getReportsByRowAdjusted(reportsByRow, dashboard.reports);

	try {
		await Promise.all(
			reportsByRowAdjusted.map(async (row, i) => {
				return await addRowSlide({
					client: ppt,
					clientType: 'pptxgen',
					reports,
					row,
					enterpriseName,
					pageNumber: i + 2,
					dashboard,
					PARAMS,
				});
			})
		);

		ppt.writeFile({ fileName: `${dashboard.name}.pptx` });
	} catch (e) {
		console.log(e);
	}
}
