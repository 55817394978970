import PropTypes from 'prop-types';
import SortableHead from '../../common/SortableHead';
import AnalysisTypes from '../../constants/AnalysisTypes';

export default function AttrTable(props) {
	const {
		lifecycle,
		analysisType,
		attributeName,
		sortCriteria,
		onSort,
		data,
		showGroupSummary,
		onHover,
		onHoverEnd,
		onToggle,
		hoveredItem,
		selection,
		status,
		headerHeight,
		tableActive,
	} = props;

	function getSummaryLabel() {
		if (analysisType === AnalysisTypes.Percentage && lifecycle !== 'Retention') {
			return 'Total';
		}
		return 'Average';
	}

	const handleToggle = (...args) => {
		onToggle(...args);
	};

	return (
		<table className="attr-table__label-table">
			<thead>
				<tr style={{ height: headerHeight }}>
					<th className="attr-table__divider" style={{ cursor: 'default' }} />
				</tr>
				<tr>
					<SortableHead
						label={attributeName}
						columnName="label"
						sortCriteria={sortCriteria}
						onSort={onSort}
						className="attr-table__divider"
					/>
				</tr>
			</thead>
			<tbody>
				{data.map(d => {
					const isActive = d.label === hoveredItem;
					const isSelected = selection.includes(d.label);
					return (
						<tr
							key={d.id}
							className={isActive || isSelected ? 'attr-table__selected' : ''}
							style={{
								cursor: 'pointer',
								opacity: tableActive ? (isActive || isSelected ? 1 : 0.24) : 1,
							}}
							onMouseOver={() => onHover(d.label)}
							onMouseOut={() => onHoverEnd(d.label)}
							onClick={() => handleToggle(d.label)}
						>
							<td className="attr-table__divider">{d.label}</td>
						</tr>
					);
				})}
				{data.length > 0 && showGroupSummary && (
					<tr className="attr-table__bold">
						<td className="attr-table__divider">Filtered {getSummaryLabel()}</td>
					</tr>
				)}
				{data.length > 0 && (
					<tr className="attr-table__bold">
						<td className="attr-table__divider">{getSummaryLabel()}</td>
					</tr>
				)}
			</tbody>
		</table>
	);
}

AttrTable.propTypes = {
	attributeName: PropTypes.string,
	data: PropTypes.array,
	sortCriteria: PropTypes.object,
	onSort: PropTypes.func,
	lifecycle: PropTypes.string,
	analysisType: PropTypes.string,
	showGroupSummary: PropTypes.bool,
	onHover: PropTypes.func,
	onHoverEnd: PropTypes.func,
	onToggle: PropTypes.func,
	hoveredItem: PropTypes.string,
	selection: PropTypes.array,
	status: PropTypes.string,
	headerHeight: PropTypes.number,
	tableActive: PropTypes.bool,
};
